<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>
    <dx-input
      :id="`scip-${item.id}`"
      :value="centsString"
      :label="translatedLabel"
      type="text"
      :disabled="item.readonly"
      :error="translatedError"
      @input="update"
      @complete="complete"
    />
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import { DxInput } from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback } from '../../utils'

function isValidNumber(number) {
  return typeof number === 'number' && !Number.isNaN(number)
}

function formatCents(cents) {
  if (!isValidNumber(cents)) {
    return ''
  }

  const isFractions = cents % 100 !== 0
  return (cents / 100).toLocaleString('de-CH', {
    minimumFractionDigits: isFractions ? 2 : 0,
    maximumFractionDigits: isFractions ? 2 : 0,
  })
}

function parseCents(valueString) {
  if (!valueString || valueString.trim() === '') {
    return null
  }

  const parsedValue = parseFloat(
    valueString
      .trim()
      .replace(',', '.')
      .replace(/[^0-9.]/g, ''),
  )

  if (!isValidNumber(parsedValue)) {
    return null
  }

  return Math.round(parsedValue * 100)
}

export default {
  name: 'DXMoneyAdapter',
  components: {
    DxDescriptionLabel,
    DxInput,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  data() {
    return {
      centsString: formatCents(this.value?.valueCents),
    }
  },

  computed: {
    valueFormatted() {
      return formatCents(this.value?.valueCents)
    },

    translatedError() {
      return this.error
        ? this.t(
            `errors.money.${
              this.value === null ? 'requiredMoney' : this.error.type
            }`,
            {
              ...this.error.params,
              label: this.translatedLabel,
            },
          )
        : null
    },
  },

  methods: {
    update(centsString) {
      this.centsString = centsString
    },

    complete() {
      const valueCents = parseCents(this.centsString)
      this.$emit(
        'input',
        valueCents !== null ? { valueCents, currency: 'CHF' } : null,
      )
      this.centsString = formatCents(valueCents)

      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>
