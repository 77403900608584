<template>
  <div>
    <div
      :class="cssClasses()"
      @dragover="doDrag"
      @dragleave="stopDrag"
      @dragend="stopDrag"
      @drop="stopDrag"
    >
      <dx-loading-overlay v-if="uploading" />
      <template v-else>
        <button
          v-if="showCloseButton"
          class="btn-blank icon-orange-cross icon-orange-cross--position"
          @click="closeRequestDocItem"
        >
          <span class="visually-hidden">
            {{ $t('abort') }}
          </span>
        </button>
        <!-- Request Docs -->
        <template v-if="type === 'requestDocs'">
          <label v-if="hasLabel()" class="d-block mb-3">
            {{ label }}
          </label>
          <dx-select
            v-model="requestDocType"
            :label="$t('documentType')"
            :options="docTypes"
            class="mb-4"
          />
          <dx-input v-model="requestDocName" :label="$t('documentName')" />
        </template>

        <!-- Upload docs with type -->
        <template v-else>
          <label
            v-if="hasLabel()"
            :class="['d-block', { 'font-weight-bold': boldLabel }]"
          >
            {{ label }}
          </label>
          <dx-select
            v-if="type === 'uploadDocsWithType'"
            v-model="docType"
            :label="$t('documentType')"
            :options="docTypes"
            class="mb-4"
          />

          <dx-download-list
            item-icon="doc"
            :items-deletable="true"
            :item-linkable="false"
            class="pb-2"
            :items="allFiles"
            :delete-callback="removeFile"
          />

          <div class="d-flex justify-content-between">
            <file-upload
              ref="upload"
              :input-id="`${label}-${Math.random() * 1000}-input`"
              :drop="true"
              multiple
              :size="fileSizeLimit"
              class="drag-area d-md-flex align-items-center justify-content-center"
              tabindex="-1"
              @input-file="sendFile"
            >
              <div class="icon icon-upload">
                {{ $t('dragDocument') }}
              </div>
            </file-upload>

            <input
              :id="fileUploadId"
              multiple
              type="file"
              tabindex="0"
              @change="onFileUpload"
            />
            <label :for="fileUploadId" class="search-docs-btn">
              <span class="search-docs-btn-text">
                {{ $t('searchDocuments') }}
              </span>
            </label>
          </div>

          <div v-if="showUploadButton" class="text-right mt-4">
            <dx-button
              class="d-inline-block"
              :text="$t(btnText)"
              :small="true"
              @click="uploadFiles"
            />
          </div>
        </template>

        <div v-if="error" class="error-container">
          {{ error }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import {
  DxButton,
  DxInput,
  DxSelect,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxDownloadList from '~/components/DX-DownloadList'
import {
  SET_PENDING_UPLOADS,
  RESET_PENDING_UPLOADS,
} from '~/domains/application/__store__/mutations'
import uploadFilesMutation from './__gql__/mutations/filesUploadMutation.gql'

export default {
  name: 'DxUpload',
  components: {
    FileUpload,
    DxLoadingOverlay,
    DxSelect,
    DxInput,
    DxButton,
    DxDownloadList,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'upload',
    },
    onUploadSuccess: {
      type: Function,
      default: () => null,
    },
    onUploadError: {
      type: Function,
      default: () => null,
    },
    onUploadRemove: {
      type: Function,
      default: () => null,
    },
    getRequestedDoc: {
      type: Function,
      default: () => null,
    },
    initialFiles: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    docTypes: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'uploadDocs',
    },
    removeSpacing: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showUploadButton: {
      type: Boolean,
      default: true,
    },
    boldLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      docType: null,
      requestDocType: null,
      requestDocName: '',
      files: [],
      uploading: false,
      fileSizeLimit: 120 * 1000000,
      filetypeBlacklist: ['exe', 'js', 'bat', 'app', 'csh'],
      dragOver: false,
      uploadedFiles: this.initialFiles,
    }
  },
  computed: {
    fileUploadId() {
      return `fileUpload${this.label}`
    },
    allFiles() {
      return [...this.uploadedFiles, ...this.files]
    },
    totalSize() {
      let total = 0
      for (let i = 0; i < this.files.length; i += 1) {
        const { size } = this.files[i]
        total += size
      }
      return total
    },
  },
  mounted() {
    this.$store.commit(RESET_PENDING_UPLOADS)
  },
  methods: {
    cssClasses() {
      return {
        'drag-active': this.dragOver,
        'upload-wrap': true,
        'theme-light': this.theme === 'light',
        'upload-wrap--no-spacing': this.removeSpacing,
      }
    },
    doDrag() {
      this.dragOver = true
    },
    stopDrag() {
      this.dragOver = false
    },
    closeRequestDocItem() {
      this.$emit('closeUploadItem')
    },
    hasLabel() {
      return this.label.length > 0
    },
    addFile(fileObj, id) {
      const file = {
        file: fileObj,
        name: fileObj.name,
        size: fileObj.size,
        id,
      }
      this.files.push(file)
      this.$store.commit(SET_PENDING_UPLOADS, 1)
      this.$emit('input', this.files)
    },
    async sendFile(event) {
      if (this.totalSize + event.file.size > this.fileSizeLimit) {
        return this.$notification.error({ messageKey: 'file.sizeLimit' })
      }
      for (let i = 0; i < this.filetypeBlacklist.length; i += 1) {
        const blacklistType = this.filetypeBlacklist[i]
        if (blacklistType === event.file.name.split('.').pop()) {
          return this.$notification.error({ messageKey: 'file.fileType' })
        }
      }
      const duplicate = this.files.find((file) => file.name === event.file.name)
      // TODO: @REF: show duplicate dialog
      if (duplicate) {
        this.removeFile(duplicate)
      }
      return this.addFile(event.file, event.id)
    },
    async uploadFiles() {
      if (!this.files.length) {
        return true
      }
      this.uploading = true
      try {
        const result = await this.$apolloProvider.clients.upload.mutate({
          mutation: uploadFilesMutation,
          variables: {
            files: this.files.map(({ file }) => file),
          },
        })
        if (result && result.data && result.data.uploadFiles) {
          result.data.uploadFiles.forEach((id, index) => {
            const file = {
              name: this.files[index].name,
              id,
              kind: this.docType || 'misc',
            }
            this.onUploadSuccess(file)
          })
        }
        return true
      } catch (e) {
        this.$notification.error({ messageKey: 'dxUpload.error' })
        this.onUploadError(this.files)
        return false
      } finally {
        this.$store.commit(SET_PENDING_UPLOADS, this.files.length * -1)
        this.uploading = false
        this.files = []
        this.$emit('input', this.files)
        this.docType = null
      }
    },
    removeFile({ id }) {
      if (this.files.some((f) => f.id === id)) {
        this.$store.commit(SET_PENDING_UPLOADS, -1)
      }
      this.uploadedFiles = this.uploadedFiles.filter((f) => f.id !== id)
      this.files = this.files.filter((f) => f.id !== id)
      this.onUploadRemove(id)
      this.$emit('input', this.files)
    },
    onFileUpload(e) {
      if (e && e.srcElement && this.$refs.upload) {
        this.$refs.upload.addInputFile(e.srcElement)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
