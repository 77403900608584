<template>
  <div class="content-bg-color">
    <div class="container-fluid content-pad">
      <div class="row">
        <div class="col">
          <dx-back-text-button
            :text="$t('toDashboard')"
            :route="localePath('PartnerPortal')"
            class="outer-container-spacing"
          />
        </div>
      </div>
      <dx-tariff-container :tariff="activeTariff" class="mt-5">
        <!-- FORM -->
        <template slot="content">
          <div class="row align-items-center">
            <div class="col-12 col-md-8 offset-md-2">
              <p>{{ $t('brokerOffer.answerQuestions') }}</p>
              <dx-private-basic-questions
                v-if="tariffType === 'private'"
                ref="privateIntroForm"
                editable
                :is-broker-offer="true"
                @change="
                  () => {
                    hasChanged = true
                  }
                "
              />
              <dx-business-basic-questions
                v-else-if="tariffType === 'business'"
                ref="businessIntroForm"
                editable
                :is-broker-offer="true"
                @change="
                  () => {
                    hasChanged = true
                  }
                "
              />
              <dx-business-move-basic-questions
                v-else-if="tariffType === 'b-move'"
                ref="businessMoveIntroForm"
                editable
                :is-broker-offer="true"
                @change="
                  () => {
                    hasChanged = true
                  }
                "
              />
              <nuxt-link
                v-else
                :to="{ path: localePath('PartnerPortal') }"
                class="secondary-link"
              >
                {{ $t('offer.chooseProduct') }}
              </nuxt-link>
            </div>

            <div class="col-12 text-right py-md-4 px-md-5 mt-3">
              <dx-button
                :text="$t('offer.calculatePremium')"
                class="dx-button--position"
                @click="saveForm"
              />
            </div>
          </div>
        </template>
      </dx-tariff-container>

      <!-- PRICE BOX -->
      <dx-loading-overlay v-if="loading" />
      <div
        v-else-if="!hasError && !hasChanged && premia.insurable !== null"
        class="premium-wrap shadow-container mt-5"
      >
        <div class="row">
          <div class="col-12 premium-head text-center py-5">
            <h2 class="mb-0">
              {{ $t('brokerOffer.yearlyPremium') }}
            </h2>
          </div>
        </div>
        <div class="row mt-4">
          <div
            v-if="premia.insurable && premia.totalPremium.value"
            class="col-12 col-md-8 offset-md-2 py-3"
          >
            <div
              v-for="(premium, index) in premiaComponents"
              :key="`premium-${index}`"
              class="row"
            >
              <div class="col-6 col-md-4">
                <h5>
                  {{ $t(`offer.premiumLabel.${premium.label}`) }}
                </h5>
              </div>
              <div class="col-6 col-md-8">
                {{
                  `${premium.amount.currency || 'CHF'} ${formatCurrency(
                    premium.amount.value,
                  )}`
                }}
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6 col-md-4">
                <h5 class="mb-0">
                  {{ $t('offer.total') }}
                </h5>
                <p>
                  {{ $t('offer.tax') }}
                </p>
              </div>
              <div class="col-6 col-md-8">
                {{ premia.totalPremium.currency || 'CHF' }}
                <span class="big-price">
                  {{ formatCurrency(premia.totalPremium.value) }}
                </span>
              </div>
            </div>
          </div>
          <div v-else class="col-12 col-md-8 offset-md-2 py-3">
            {{ $t('brokerOffer.uninsurable') }}
          </div>
          <div class="col-12 text-right py-md-4 px-md-5 mt-3">
            <dx-button
              :text="$t('offer.nextStep')"
              class="dx-button--position"
              @click="goForward"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import querystring from 'querystring'
import {
  DxButton,
  DxBackTextButton,
} from '@sumcumo/dextra-frontend-component-lib-old'
import Cookies from 'js-cookie'
import DxTariffContainer from '~/components/DX-TariffContainer'
import DxPrivateBasicQuestions from '~/components/offer/DX-PrivateBasicQuestions'
import DxBusinessBasicQuestions from '~/components/offer/DX-BusinessBasicQuestions'
import DxBusinessMoveBasicQuestions from '~/components/offer/DX-BusinessMoveBasicQuestions'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import scrollFirstErrorIntoView, {
  scrollIntoView,
} from '~/javascripts/helper/scollIntoView'
import { trackPageRoute } from '~/javascripts/helper/webtrekk'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import { formatCurrency } from '~/javascripts/utils'

export default {
  name: 'DxOfferIntro',
  components: {
    DxTariffContainer,
    DxPrivateBasicQuestions,
    DxBusinessBasicQuestions,
    DxBusinessMoveBasicQuestions,
    DxLoadingOverlay,
    DxButton,
    DxBackTextButton,
  },
  mixins: [validationErrorsMixin],
  props: {
    product: {
      type: String,
      default: 'p-private',
    },
  },
  data() {
    return {
      hasError: false,
      hasChanged: false,
      scrollIntoView,
      formatCurrency,
    }
  },
  head() {
    return {
      meta: [
        {
          name: 'robots',
          content: 'noindex,follow',
        },
      ],
    }
  },
  computed: {
    loading() {
      return this.$store.state.application.loading
    },
    activeTariff() {
      return this.$store.state.brokerOffer.activeTariff
    },
    premia() {
      return this.$store.state.brokerOffer.premia
    },
    premiaComponents() {
      return this.premia.components.filter((cmp) => cmp.amount.value > 0)
    },
    tariffType() {
      return this.$store.getters.GET_ACTIVE_BROKER_TARIFF_TYPE
    },
    formRef() {
      let ref = 'businessMoveIntroForm'
      if (this.tariffType === 'private') {
        ref = 'privateIntroForm'
      } else if (this.tariffType === 'business') {
        ref = 'businessIntroForm'
      }
      return ref
    },
  },
  mounted() {
    const { reset, pid } = this.$route.query
    if (reset) {
      this.$store.commit('RESET_STORE_BROKER_OFFER')
      this.$store.commit('SET_ACTIVE_BROKER_TARIFF', this.product)
      Vue.nextTick(() => this.$refs[this.formRef].resetForm())

      // delete reset query so user can reload page without deleting again
      const query = { ...this.$route.query }
      delete query.reset
      const queryString = querystring.encode(query)
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(
        {},
        null,
        queryString ? `?${queryString}` : window.location.pathname,
      )
    } else if (this.activeTariff !== this.product) {
      // Restore common basic questions data if switching between business products
      if (this.activeTariff === 'b-move' || this.product === 'b-move') {
        const previousBasicQuestions =
          this.$store.state.brokerOffer[
            this.activeTariff === 'b-move'
              ? 'businessMoveBasicQuestions'
              : 'businessBasicQuestions'
          ]
        const {
          startOfInsurance,
          branchOfIndustry,
          secondaryEmployment,
          secondaryEmploymentDescription,
        } = previousBasicQuestions
        const mutation =
          this.product === 'b-move'
            ? 'SET_BROKER_BUSINESS_MOVE_BASIC_QUESTIONS'
            : 'SET_BROKER_BUSINESS_BASIC_QUESTIONS'
        this.$store.commit(mutation, {
          startOfInsurance,
          branchOfIndustry,
          secondaryEmployment,
          secondaryEmploymentDescription,
        })
      }
      this.$store.commit('SET_ACTIVE_BROKER_TARIFF', this.product)
      Vue.nextTick(() => this.$refs[this.formRef].resetForm())
      this.hasChanged = true
    }
    if (pid) {
      Cookies.set(
        'broker',
        {
          broker_id: this.$route.query.pid,
          origin: this.$route.query.o,
        },
        { expires: 30, domain: process.env.DEXTRA_COOKIE_DOMAIN },
      )
    }
  },
  methods: {
    async saveForm() {
      const valid = await this.$refs[this.formRef].validateForm()
      if (valid) {
        const result = await this.calcPremia()
        if (result) {
          this.$refs[this.formRef].saveForm()
          setTimeout(() => {
            this.scrollIntoView(document.querySelector('.premium-wrap'))
          }, 10)
          const contentIdPrefix =
            this.activeTariff[0] === 'p'
              ? 'privatrechtsschutz'
              : 'unternehmensrechtsschutz'
          trackPageRoute({
            metaData: {
              trackingId: `dextra.${contentIdPrefix}.${this.activeTariff}.antragsstrecke.jahrespraemie`,
            },
            locale: this.$i18n.locale,
            tariffType: this.tariffType,
          })
        } else {
          scrollFirstErrorIntoView()
        }
      } else {
        scrollFirstErrorIntoView()
      }
    },
    async calcPremia() {
      const actionKey = this.activeTariff.split('-').join('').toUpperCase()
      const action = `CALC_BROKER_${actionKey}_PREMIA`
      const payload = this.$refs[this.formRef].formData
      const result = await this.$store.dispatch(action, payload)
      if (result) {
        const errors = result.validationErrors
        if (errors.length) {
          this.hasError = true
          errors.forEach((err) => {
            this.$refs[this.formRef].$validator.errors.add(
              this.getServerValidationError(err),
            )
          })
          return false
        }
        this.hasError = false
        this.hasChanged = false
        return true
      }
      // OR NETWORK ERROR:
      this.hasError = true
      this.$notification.error({ messageKey: 'notifications.error.message' })
      return false
    },
    async goForward() {
      this.$store.commit('SET_BROKER_STEP', 2)
      this.$router.push({ path: this.localePath('BrokerOffer-Summary') })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
