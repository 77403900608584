/* eslint-disable no-param-reassign,no-unused-vars */
import getDefaultState from './initialState'

export const RESET_STORE_AUTHENTICATION = 'RESET_STORE_AUTHENTICATION'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_ROLES = 'SET_ROLES'
export const SET_PORTALS = 'SET_PORTALS'
export const SET_CURRENT_PORTAL = 'SET_CURRENT_PORTAL'

const getValidUntilFromToken = (jwt) => {
  const base64URL = jwt.split('.')[1]
  const base64 = base64URL.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64)).exp
}

export default {
  [RESET_STORE_AUTHENTICATION](state) {
    Object.assign(state, getDefaultState())
  },
  [SET_TOKEN](state, { token }) {
    state.token = {
      ...token,
      validUntil: token.jwt ? getValidUntilFromToken(token.jwt) : null,
    }
  },
  [SET_ROLES](state, roles) {
    state.roles = roles
  },
  [SET_PORTALS](state, portals) {
    state.portals = portals
  },
  [SET_CURRENT_PORTAL](state, portal) {
    state.currentPortal = portal
  },
}
