<template>
  <dx-broker-offer-intro product="b-move" />
</template>

<script>
import DxBrokerOfferIntro from '~/domains/brokerOffer/components/DX-BrokerOfferIntro'

export default {
  name: 'BrokerOfferBMoveIntro',
  components: {
    DxBrokerOfferIntro,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/unternehmensrechtsschutz/b-move/antragsstrecke/',
      fr: '/portailpartenaires/protection-juridique-entreprise/b-move/formulaire-de-demande/',
    },
  },
}
</script>
