<template>
  <div>
    <h5 v-if="hasComponents()" class="mb-3">
      {{ $t('lawyerPortal.requestDocuments') }}
    </h5>
    <dx-upload
      v-for="count in compAmount"
      :key="count"
      ref="docRequest"
      v-validate.disable="'required'"
      class="mb-3"
      :error="errors.first('missingAttachments', `documentRequest_${count}`)"
      data-vv-name="missingAttachments"
      data-vv-value-path="requestDocName"
      :data-vv-scope="`documentRequest_${count}`"
      :doc-types="docTypes"
      type="requestDocs"
      :show-close-button="count === compAmount"
      @closeUploadItem="subComp"
    />
    <template>
      <div class="text-right">
        <button class="btn-blank show-more-link plus-icon" @click="addComp">
          {{
            hasComponents()
              ? $t('lawyerPortal.requestFurtherDocuments')
              : $t('lawyerPortal.requestDocuments')
          }}
        </button>
      </div>
      <div v-if="hasComponents()" class="mt-4 text-right">
        <dx-button
          class="d-inline-block"
          :text="$t('request')"
          :small="true"
          @click="sendRequests"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxUpload from '~/components/DX-Upload/'

export default {
  name: 'DxRequestMulti',
  components: {
    DxUpload,
    DxButton,
  },
  props: {
    docTypes: {
      type: Array,
      default: () => [],
    },
    onRequestComplete: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      compAmount: 1,
    }
  },
  methods: {
    addComp() {
      this.compAmount += 1
    },
    subComp() {
      this.compAmount -= 1
    },
    hasComponents() {
      return this.compAmount > 0
    },
    cancelRequests() {
      this.compAmount = 0
    },
    async sendRequests() {
      const valid = await this.$validator.validate()
      if (valid) {
        const requestComps = this.$refs.docRequest
        const requests = await requestComps.map(
          ({ requestDocType, requestDocName }, idx) =>
            this.onRequestComplete({
              type: requestDocType || 'misc',
              title: requestDocName,
              final: idx === this.compAmount - 1,
            }),
        )

        requests.forEach((success) => {
          if (success) {
            this.subComp()
          }
        })
      }
    },
  },
}
</script>
