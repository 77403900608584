import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { LOGIN_PATH } from '~/middleware/auth'

export default ({ store, app }) => {
  const uri = `${process.env.DEXTRA_API_URL}/api`
  const httpLink = new HttpLink({ uri })

  const middlewareLink = new ApolloLink((operation, forward) => {
    const headers = {
      'x-client-version': process.env.APP_VERSION,
      'x-language': app ? app.i18n.locale : 'de',
    }
    if (store.state.authentication.token.jwt) {
      headers.authorization = `Bearer ${store.state.authentication.token.jwt}`
    }
    operation.setContext({ headers })

    return forward(operation)
  })

  const errorLink = onError((error) => {
    const { graphQLErrors } = error
    if (Array.isArray(graphQLErrors) && graphQLErrors.length > 0) {
      let reason = null
      switch (graphQLErrors[0].code) {
        case 3:
          reason = 'sessionExpired'
          break
        case 5:
          reason = 'gmAPIDown'
          break
        default:
          break
      }

      if (reason) {
        // eslint-disable-next-line no-underscore-dangle,no-undef
        $nuxt._router.push({
          path: store.app.localePath(LOGIN_PATH),
          query: { logout: true, reason },
        })
      } else if (graphQLErrors[0].message === 'Authentication error') {
        console.error('Authentication error')
        // eslint-disable-next-line no-undef,no-underscore-dangle
        $nuxt._router.push(
          // eslint-disable-line no-undef, no-underscore-dangle
          {
            path: store.app.localePath(LOGIN_PATH),
            query: { logout: true },
          },
        )
      }
    }
  })

  const cache = new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (
        object.__typename // eslint-disable-line no-undef
      ) {
        case 'Claim':
        case 'Contracts':
          return (
            `${store.state.authentication.currentPortal}_${object.id}` || null
          ) // cache only for currentPortal
        default:
          return defaultDataIdFromObject(object) // fall back to default handling
      }
    },
  })

  const link = ApolloLink.from([middlewareLink, errorLink, httpLink])

  return {
    link,
    cache,
    defaultHttpLink: false,
  }
}
