<template>
  <ul>
    <template v-for="entry in entries">
      <template
        v-if="
          isEmpty(getEntryValue(entry)) && entry.__typename !== 'ContentItem'
        "
      ></template>

      <template v-else-if="entry.__typename === 'ContentItem'">
        <template v-if="entry.richText === '$variant'">
          <li :key="`${entry.id}-ContentItemVariant`">
            {{ t('variantName') }}
          </li>
        </template>
        <template v-else>
          <li :key="`${entry.id}-ContentItem`">
            {{ formContext.renderHtml(entry.richText || '') }}
          </li>
        </template>
      </template>

      <template v-else-if="entry.__typename === 'ZipCityField'">
        <zip-city-field-entry
          v-if="getEntryValue(entry).city && getEntryValue(entry).zip"
          :key="`${entry.id}-ZipCityField`"
          :entry-value="getEntryValue(entry)"
          :form-context="formContext"
          :entry="entry"
        />
      </template>

      <template v-else-if="entry.__typename === 'LocationsField'">
        <locations-field-entry
          :key="`${entry.id}-LocationsField`"
          :label="getEntryLabel(entry)"
          :locations="entry.value"
          :translation="t('countries')"
        />
      </template>

      <template v-else-if="entry.__typename === 'GroupField'">
        <group-field-entry
          :key="`${entry.id}-GroupField`"
          :form-context="formContext"
          :label="getEntryLabel(entry)"
          :elements="entry.value"
          :descendents="entry.descendents"
        />
      </template>

      <template v-else-if="entry.__typename === 'ModuleSelectionField'">
        <li
          v-for="module in getSelectedModules(entry)"
          :key="module.key"
          class="module"
        >
          {{ module.label }}
        </li>
      </template>

      <key-value-entry
        v-else
        :key="`${entry.id}-KeyValue`"
        :label="getEntryLabel(entry)"
        :value="`${getEntryValue(entry)}`"
      />
    </template>
  </ul>
</template>

<script>
import format from 'date-fns/format'
import { toRefs } from '@vue/composition-api'

import ZipCityFieldEntry from '@/components/DX-ScipSales/adapters/DX-SummaryAdapter/entries/ZipCityFieldEntry'
import GroupFieldEntry from '@/components/DX-ScipSales/adapters/DX-SummaryAdapter/entries/GroupFieldEntry'
import KeyValueEntry from '@/components/DX-ScipSales/adapters/DX-SummaryAdapter/entries/KeyValueEntry'
import LocationsFieldEntry from '@/components/DX-ScipSales/adapters/DX-SummaryAdapter/entries/LocationsFieldEntry'

import {
  useScipTranslationWithFallback,
  isEmpty,
} from '@/components/DX-ScipSales/utils'

function renderMoney(value) {
  if (!value) {
    return ''
  }
  // return depending on currency
  if (value.currency === 'CHF') {
    return new Intl.NumberFormat('de-CH', {
      style: 'currency',
      currency: value.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value.valueCents / 100)
  }
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: value.currency,
  }).format(value.valueCents / 100)
}

export default {
  name: 'SummaryItemEntries',

  components: {
    ZipCityFieldEntry,
    GroupFieldEntry,
    KeyValueEntry,
    LocationsFieldEntry,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    entries: {
      type: Array,
      default: () => [],
    },
    selectedModules: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
      isEmpty,
    }
  },
  methods: {
    translationKeyPrefixForEntry(item) {
      if (!item.source) {
        return ''
      }

      if (item.source.kind === 'attribute') {
        return `attributes.${item.source.moduleKey}.${item.source.attributeKey}`
      }

      return `${item.source.moduleKey}.${item.source.attributeKey}`
    },

    getEntryLabel(entry) {
      if (!entry.source) {
        return ''
      }

      const legacyKey = `attributes.${entry.source.moduleKey}.${entry.source.attributeKey}.label`
      return this.te(legacyKey) ? this.t(legacyKey) : entry.label
    },

    // eslint-disable-next-line complexity, max-statements
    getEntryValue(entry) {
      const { __typename, value } = entry

      // eslint-disable-next-line no-underscore-dangle
      if (__typename === 'DateField') {
        return value
          ? format(new Date(value), entry.pattern || 'dd.MM.yyyy')
          : ''
      }

      // eslint-disable-next-line no-underscore-dangle
      if (__typename === 'ZipCityField') {
        return value
      }

      // eslint-disable-next-line no-underscore-dangle
      if (__typename === 'ContentItem') {
        if (entry.richText === '$variant') {
          return this.t('variantName')
        }

        return this.formContext.renderHtml(value || '')
      }

      // eslint-disable-next-line no-underscore-dangle
      if (__typename === 'MoneyField') {
        return renderMoney(value) || ''
      }
      if (typeof value === 'boolean') {
        return value ? this.t('yes') : this.t('no')
      }
      if (entry.options) {
        const option = entry.options.find(
          (candidate) => candidate.value === value,
        )
        if (!option) {
          return value
        }

        const prefix = this.translationKeyPrefixForEntry(entry)
        if (this.te(`${prefix}.options.${option.value}`)) {
          return this.t(`${prefix}.options.${option.value}`)
        }

        return option.name
      }

      if (Array.isArray(value)) {
        return value.join(', ')
      }

      return value || ''
    },

    getSelectedModules(entry) {
      return entry.modules
        .filter((module) => this.selectedModules.includes(module.key))
        .map((module) => ({
          key: module.key,
          label: this.t(`modules.${module.key}.label`),
        }))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

ul {
  padding-left: 0;
  list-style: none;

  li {
    color: $black-clr;
    font-size: rem(16);
    line-height: rem(24);

    &.module {
      margin-bottom: rem(6);
      font-family: $font-family-heavy;
    }
  }
}

::v-deep dl {
  margin: 0;
  flex-wrap: wrap;
}

::v-deep dt {
  float: left;
  clear: left;
  margin-right: 7.5px;
  font-family: $font-family-heavy;
}

::v-deep dd {
  font-family: $font-family-roman;
}

::v-deep ol {
  padding-left: rem(20);

  li {
    margin-top: rem(8);
    & + li {
      margin-top: rem(12);
    }

    color: $black-clr;
    font-family: $font-family-heavy;

    dl {
      margin-top: rem(4);

      dd {
        margin-bottom: rem(4);
      }
    }
  }
}
</style>
