<template>
  <div data-integrationtest="mvp-invitation-list">
    <h5 class="mb-4">
      {{ formatInvitationHeadline(contractData) }}
    </h5>
    <div class="row mb-1">
      <div
        v-for="(invitation, index) in contractData.invitations"
        :key="index"
        class="col-12 col-md-6"
      >
        <dx-delete-item
          :headline="`${invitation.firstName} ${invitation.lastName}`"
          :on-confirm-delete="
            () => deleteInvitation(contractData, invitation.id)
          "
          :loading="loadingId === invitation.id"
          class="mb-3"
          data-integrationtest="mvp-invitation-item"
        >
          <template slot="list-item">
            <li class="break-word">
              {{ invitation.email }}
            </li>
            <li class="small-font mt-2">
              {{ getExpiryDate(invitation.expiredAt) }}
            </li>
          </template>
        </dx-delete-item>
      </div>
    </div>
  </div>
</template>

<script>
import DxDeleteItem from '~/components/DX-DeleteItem'
import deleteInvitationMutation from '~/domains/contract/__gql__/mutations/deleteInvitationMutation.gql'
import dateMixin from '~/javascripts/mixins/dateMixin'

export default {
  name: 'DxInvitations',
  components: {
    DxDeleteItem,
  },
  mixins: [dateMixin],
  props: {
    contractData: {
      type: Object,
      default: () => ({
        invitations: [],
      }),
    },
    onSuccess: {
      type: Function,
      default() {
        return null
      },
    },
    onError: {
      type: Function,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      loadingId: null,
    }
  },
  methods: {
    async deleteInvitation(currentContract, invId) {
      const variables = {
        contract: {
          insuranceCompany: currentContract.insuranceCompany,
          id: currentContract.id,
        },
        invitationId: invId,
      }
      this.loadingId = invId
      try {
        await this.$apollo.mutate({
          mutation: deleteInvitationMutation,
          variables,
        })

        const invitations = this.contractData.invitations.filter(
          (ip) => ip.id !== invId,
        )
        this.contractData.invitations = invitations

        this.$notification.success({ messageKey: 'deleteInvitedPersonSuccess' })
        this.onSuccess()
      } catch (error) {
        this.$notification.error({ messageKey: 'deleteInvitedPersonError' })
        this.onError()
      } finally {
        this.loadingId = null
      }
    },
    formatInvitationHeadline(contract) {
      const translationKey =
        contract.invitations.length > 1 ? 'pendingInvites' : 'pendingInvite'
      return this.$t(`policies.${translationKey}`)
    },
    getExpiryDate(expiredAt) {
      return `${this.$t('invitation.validTo')}: ${this.getFormattedDate(
        expiredAt,
        true,
      )}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
