<template>
  <div>
    <ul v-if="Array.isArray(notes) && notes.length > 0" class="neutral mb-3">
      <li v-for="note in notes" :key="note.id" class="note p-4">
        <button
          v-if="!readOnly"
          type="button"
          class="btn-blank edit-delete"
          @click="deletePrivateNote(note.id)"
        >
          <img
            :alt="$t('delete')"
            src="~assets/images/icons/delete-small.svg"
          />
        </button>
        <button
          v-if="!readOnly"
          type="button"
          class="btn-blank edit-delete"
          @click="toggleNoteEdit(note.id)"
        >
          <img :alt="$t('edit')" src="~assets/images/icons/modify-small.svg" />
        </button>
        <h5>{{ getFormattedDate(note.sent_at) }}</h5>
        <p
          v-if="!isEditModeActive(note.id)"
          class="mb-0"
          v-html="simpleFormat(note.text)"
        ></p>
        <template v-if="isEditModeActive(note.id)">
          <dx-textarea
            v-model="note.newText"
            v-validate.disable="'required'"
            :label="$t('lawyerPortal.editNoteLabel')"
            :error="errors.first('missingMessageBody')"
            class="mt-5 mb-2"
            data-vv-name="missingMessageBody"
          />

          <div class="text-right mb-4">
            <button
              type="button"
              class="btn-blank secondary-link d-md-inline-block mr-md-4"
              @click="cancelNoteEdit(note)"
            >
              {{ $t('abort') }}
            </button>
            <dx-button
              :small="true"
              :text="$t('damage.saveNote')"
              class="d-md-inline-block"
              type="button"
              @click="saveNote(note)"
            />
          </div>
        </template>
      </li>
    </ul>
    <button
      v-if="!addNote && !readOnly"
      type="button"
      class="show-more-link plus-icon btn-blank"
      @click="toggleAddNote"
    >
      {{ $t('damage.notesAction') }}
    </button>
    <template v-if="addNote">
      <div class="content-bg-color mb-3 p-3 p-md-5">
        <h4 class="mb-4">
          {{ $t('damage.createNote') }}
        </h4>
        <div class="row">
          <div class="col-12">
            <dx-textarea
              v-model="newNote"
              v-validate.disable="'required'"
              :label="$t('damage.notesHeading')"
              :error="errors.first('missingMessageBody')"
              class="mb-4"
              data-vv-name="missingMessageBody"
            />
          </div>
        </div>
      </div>
      <div class="row py-3 pb-md-0 pt-md-1">
        <div class="col text-center text-md-right pt-2">
          <button
            class="btn-blank d-inline-block secondary-link mb-3 mr-md-4 mb-md-0"
            @click="toggleAddNote"
          >
            {{ $t('abort') }}
          </button>
          <dx-button
            :small="true"
            :text="$t('damage.saveNote')"
            class="d-md-inline-block"
            type="button"
            @click="addNewNote"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  DxButton,
  DxTextarea,
} from '@sumcumo/dextra-frontend-component-lib-old'
import dateMixin from '~/javascripts/mixins/dateMixin'
import simpleFormat from '../../../../../javascripts/helper/simpleFormat.js'

export default {
  name: 'DxLawyerClaimNotes',
  components: {
    DxTextarea,
    DxButton,
  },
  mixins: [dateMixin],
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    addPrivateNote: {
      type: Function,
      default: () => null,
    },
    deletePrivateNote: {
      type: Function,
      default: () => null,
    },
    savePrivateNote: {
      type: Function,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addNote: false,
      newNote: '',
      editNote: [],
    }
  },
  methods: {
    async addNewNote() {
      const valid = await this.$validator.validateAll()
      if (valid) {
        const result = await this.addPrivateNote(this.newNote)
        if (result) {
          this.toggleAddNote()
          this.newNote = ''
        }
      }
    },
    async saveNote(note) {
      const valid = await this.$validator.validateAll()
      if (valid) {
        const result = await this.savePrivateNote(note)
        if (result) {
          this.toggleNoteEdit(note.id)
        }
      }
    },
    toggleAddNote() {
      this.addNote = !this.addNote
    },
    toggleNoteEdit(noteId) {
      if (this.isEditModeActive(noteId)) {
        this.editNote = [...this.editNote.filter((id) => id !== noteId)]
      } else {
        this.editNote = [noteId, ...this.editNote]
      }
    },
    cancelNoteEdit(note) {
      this.toggleNoteEdit(note.id)
    },
    isEditModeActive(noteId) {
      return this.editNote.some((id) => id === noteId)
    },
    simpleFormat: simpleFormat.simpleFormat,
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
