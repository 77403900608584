<script>
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export default {
  methods: {
    getFormattedDate(inputDate) {
      const input = parseISO(inputDate)
      return inputDate ? format(input, 'dd.MM.yyyy') : ''
    },
    getFormattedDateTime(isoString) {
      return isoString ? format(parseISO(isoString), 'dd.MM.yyyy hh:mm') : ''
    },
  },
}
</script>
