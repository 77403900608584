/* eslint-disable no-param-reassign, no-unused-vars */
export default {
  GET_ACTIVE_BROKER_TARIFF_TYPE(state) {
    let type
    switch (state.activeTariff) {
      case 'p-private':
      case 'p-move':
      case 'p-combi':
        type = 'private'
        break
      case 'b-business':
      case 'small-business':
        type = 'business'
        break
      default:
        type = 'b-move'
        break
    }
    return type
  },
  GET_BROKER_OFFER_INTRO_PATH(state) {
    const product = state.activeTariff
    const introPathParts = product.split('-')
    const introPath = introPathParts
      .map((part) =>
        [String.prototype.toUpperCase.call(part[0]), part.slice(1)].join(''),
      )
      .join('-')
    return `BrokerOffer-Intro-${introPath}`
  },
}
