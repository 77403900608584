<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>

    <label
      v-if="item.options && item.options.length"
      class="selection radioSelection"
    >
      <dx-description-label v-if="translatedLabel">
        {{ translatedLabel }}
      </dx-description-label>
      <dx-radio-buttons
        :id="`scip-${item.id}`"
        :options="mappedRadioOptions"
        :disabled="item.readonly"
        :error="translatedError"
        :value="internalValue"
        :white-bg="true"
        @change="radioSelected"
      />
    </label>

    <dx-input
      v-else
      :id="`scip-${item.id}`"
      :value="internalValue"
      :label="translatedLabel"
      type="date"
      :disabled="item.readonly"
      :error="translatedError"
      @input="update"
      @complete="complete"
      @enter="complete"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { toRefs } from '@vue/composition-api'

import { DxInput, DxRadioButtons } from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback, isEmpty } from '../../utils'

function toHumanDate(dateOrString) {
  try {
    if (!dateOrString) {
      return null
    }

    let date
    if (typeof dateOrString === 'string') {
      date = parse(dateOrString, 'yyyy-MM-dd', new Date())
    } else {
      date = dateOrString
    }

    if (Number.isNaN(date)) {
      return null
    }

    return format(date, 'dd.MM.yyyy')
  } catch (error) {
    return null
  }
}

function toScipDate(dateOrString) {
  try {
    if (!dateOrString) {
      return null
    }

    let date
    if (typeof dateOrString === 'string') {
      date = parse(dateOrString, 'dd.MM.yyyy', new Date())
    } else {
      date = dateOrString
    }

    if (Number.isNaN(date)) {
      return null
    }

    return format(date, 'yyyy-MM-dd')
  } catch (error) {
    return null
  }
}

export default {
  name: 'DXDateInputAdapter',
  components: {
    DxDescriptionLabel,
    DxInput,
    DxRadioButtons,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: [Date, String],
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  data() {
    return {
      internalValue: toHumanDate(this.value),
    }
  },

  computed: {
    mappedRadioOptions() {
      if (!this.item.options) {
        return []
      }
      return this.item.options.map((item) => ({
        name: toHumanDate(item),
        value: toHumanDate(item),
      }))
    },

    translatedError() {
      return this.error
        ? this.t(
            `errors.date.${isEmpty(this.value) ? 'required' : this.error.type}`,
            {
              ...this.error.params,
              label: this.translatedLabel,
              min: toHumanDate(this.error.params.min),
              max: toHumanDate(this.error.params.max),
            },
          )
        : null
    },
  },

  watch: {
    value: {
      handler(newValue) {
        this.internalValue = toHumanDate(newValue)
      },
    },
  },

  methods: {
    update(internalValue) {
      this.internalValue = internalValue
    },

    complete() {
      const value = toScipDate(this.internalValue)
      this.$emit('input', value)
      this.internalValue = toHumanDate(value)
      this.$emit('validate', { dataKey: this.item.dataKey })
    },

    radioSelected(date) {
      const value = toScipDate(date)
      this.$emit('input', value)
      this.internalValue = toHumanDate(value)
      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.label {
  display: block;
  color: $black-clr;
  font-family: $font-family-heavy;
  font-size: rem(20);
  line-height: rem(28);
}
</style>
