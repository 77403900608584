<template>
  <div>
    <dx-link-container
      v-for="item in items"
      :key="item.id"
      :route-name="{
        path: localePath('LawyerPortal-Damage'),
        query: { claimId: item.id },
      }"
      class="mb-3"
    >
      <template slot="content">
        <div class="d-md-flex justify-content-start align-items-center">
          <div class="flex-item">
            {{ item.number }}
          </div>
          <div class="flex-item">
            {{ item.insuredPerson.fullName }}
          </div>
        </div>
      </template>
    </dx-link-container>
  </div>
</template>

<script>
import DxLinkContainer from '~/components/DX-LinkContainer/index'

import personMixin from '~/javascripts/mixins/personMixin'

export default {
  name: 'DxClosedDamages',
  components: {
    DxLinkContainer,
  },
  mixins: [personMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
