import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _51f3ad2e = () => interopDefault(import('../src/pages/OfferIframe/index.vue' /* webpackChunkName: "pages/OfferIframe/index" */))
const _6d2cef18 = () => interopDefault(import('../src/pages/LawyerPortal/index.vue' /* webpackChunkName: "pages/LawyerPortal/index" */))
const _455aa203 = () => interopDefault(import('../src/pages/dev/index.vue' /* webpackChunkName: "pages/dev/index" */))
const _cc619e06 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5fce3c04 = () => interopDefault(import('../src/pages/GroupeMutuel/index.vue' /* webpackChunkName: "pages/GroupeMutuel/index" */))
const _00dfc8d2 = () => interopDefault(import('../src/pages/SelfService/index.vue' /* webpackChunkName: "pages/SelfService/index" */))
const _1663b377 = () => interopDefault(import('../src/pages/Login/index.vue' /* webpackChunkName: "pages/Login/index" */))
const _29fe2a38 = () => interopDefault(import('../src/pages/SelfService/OfflineRegistration/index.vue' /* webpackChunkName: "pages/SelfService/OfflineRegistration/index" */))
const _6d745abc = () => interopDefault(import('../src/pages/PartnerPortal/index.vue' /* webpackChunkName: "pages/PartnerPortal/index" */))
const _75beb353 = () => interopDefault(import('../src/pages/SelfService/NewPasswort/index.vue' /* webpackChunkName: "pages/SelfService/NewPasswort/index" */))
const _6024fe10 = () => interopDefault(import('../src/pages/SelectPortal/index.vue' /* webpackChunkName: "pages/SelectPortal/index" */))
const _663017d4 = () => interopDefault(import('../src/pages/PwReset/index.vue' /* webpackChunkName: "pages/PwReset/index" */))
const _0f8fbdfe = () => interopDefault(import('../src/pages/Registration/index.vue' /* webpackChunkName: "pages/Registration/index" */))
const _1951c263 = () => interopDefault(import('../src/pages/ConflictSolver/SingleAccount/index.vue' /* webpackChunkName: "pages/ConflictSolver/SingleAccount/index" */))
const _314e56d1 = () => interopDefault(import('../src/pages/ConflictSolver/Unsolvable/index.vue' /* webpackChunkName: "pages/ConflictSolver/Unsolvable/index" */))
const _ef10e8a8 = () => interopDefault(import('../src/pages/ConflictSolver/MultiAccount/index.vue' /* webpackChunkName: "pages/ConflictSolver/MultiAccount/index" */))
const _00e053bc = () => interopDefault(import('../src/pages/LawyerPortal/UserData/index.vue' /* webpackChunkName: "pages/LawyerPortal/UserData/index" */))
const _7682ab7c = () => interopDefault(import('../src/pages/LawyerPortal/Damage/index.vue' /* webpackChunkName: "pages/LawyerPortal/Damage/index" */))
const _7a766b9e = () => interopDefault(import('../src/pages/LawyerPortal/ClosedDamageCases/index.vue' /* webpackChunkName: "pages/LawyerPortal/ClosedDamageCases/index" */))
const _462b37bb = () => interopDefault(import('../src/pages/LawyerPortal/RejectDamage/index.vue' /* webpackChunkName: "pages/LawyerPortal/RejectDamage/index" */))
const _052ff968 = () => interopDefault(import('../src/pages/LawyerPortal/NewDamageCase/index.vue' /* webpackChunkName: "pages/LawyerPortal/NewDamageCase/index" */))
const _3360db24 = () => interopDefault(import('../src/pages/LawyerPortal/TermsOfService/index.vue' /* webpackChunkName: "pages/LawyerPortal/TermsOfService/index" */))
const _15ef9d5d = () => interopDefault(import('../src/pages/LawyerPortal/Mailbox/index.vue' /* webpackChunkName: "pages/LawyerPortal/Mailbox/index" */))
const _9bf93274 = () => interopDefault(import('../src/pages/LawyerPortal/Registration/index.vue' /* webpackChunkName: "pages/LawyerPortal/Registration/index" */))
const _50924894 = () => interopDefault(import('../src/pages/LawyerPortal/RegistrationInfo/index.vue' /* webpackChunkName: "pages/LawyerPortal/RegistrationInfo/index" */))
const _3c6d4883 = () => interopDefault(import('../src/pages/SelfService/UserData/index.vue' /* webpackChunkName: "pages/SelfService/UserData/index" */))
const _3ea3c606 = () => interopDefault(import('../src/pages/SelfService/ClaimOverview/index.vue' /* webpackChunkName: "pages/SelfService/ClaimOverview/index" */))
const _33ba2fd8 = () => interopDefault(import('../src/pages/SelfService/Invoices/index.vue' /* webpackChunkName: "pages/SelfService/Invoices/index" */))
const _5059268e = () => interopDefault(import('../src/pages/SelfService/ReportDamage/MyInfo/index.vue' /* webpackChunkName: "pages/SelfService/ReportDamage/MyInfo/index" */))
const _a665e842 = () => interopDefault(import('../src/pages/PartnerPortal/ClosedPolicies/index.vue' /* webpackChunkName: "pages/PartnerPortal/ClosedPolicies/index" */))
const _afbdd404 = () => interopDefault(import('../src/pages/BrokerOffer/Offer/index.vue' /* webpackChunkName: "pages/BrokerOffer/Offer/index" */))
const _e5456450 = () => interopDefault(import('../src/pages/PartnerPortal/UserData/index.vue' /* webpackChunkName: "pages/PartnerPortal/UserData/index" */))
const _472681cd = () => interopDefault(import('../src/pages/BrokerOffer/OfferSpecial/gemeinde.vue' /* webpackChunkName: "pages/BrokerOffer/OfferSpecial/gemeinde" */))
const _3a84e178 = () => interopDefault(import('../src/pages/PartnerPortal/OpenOffers/index.vue' /* webpackChunkName: "pages/PartnerPortal/OpenOffers/index" */))
const _24dd5e4d = () => interopDefault(import('../src/pages/PartnerPortal/ReportDamage/Info/index.vue' /* webpackChunkName: "pages/PartnerPortal/ReportDamage/Info/index" */))
const _10733c95 = () => interopDefault(import('../src/pages/PartnerPortal/DamageManagement/index.vue' /* webpackChunkName: "pages/PartnerPortal/DamageManagement/index" */))
const _2842137c = () => interopDefault(import('../src/pages/PartnerPortal/Registration/index.vue' /* webpackChunkName: "pages/PartnerPortal/Registration/index" */))
const _7148aa71 = () => interopDefault(import('../src/pages/Requests/AcceptShareRequest/index.vue' /* webpackChunkName: "pages/Requests/AcceptShareRequest/index" */))
const _22a94dc8 = () => interopDefault(import('../src/pages/Requests/RejectShareRequest/index.vue' /* webpackChunkName: "pages/Requests/RejectShareRequest/index" */))
const _1715ada8 = () => interopDefault(import('../src/pages/BrokerOffer/ChangeTariff/index.vue' /* webpackChunkName: "pages/BrokerOffer/ChangeTariff/index" */))
const _47734908 = () => interopDefault(import('../src/pages/BrokerOffer/Summary/index.vue' /* webpackChunkName: "pages/BrokerOffer/Summary/index" */))
const _ea56466e = () => interopDefault(import('../src/pages/BrokerOffer/ThankYou/index.vue' /* webpackChunkName: "pages/BrokerOffer/ThankYou/index" */))
const _44481ae9 = () => interopDefault(import('../src/pages/SelfService/ReportDamage/ThankYou/index.vue' /* webpackChunkName: "pages/SelfService/ReportDamage/ThankYou/index" */))
const _400dfd90 = () => interopDefault(import('../src/pages/SelfService/ReportDamage/DocumentUpload/index.vue' /* webpackChunkName: "pages/SelfService/ReportDamage/DocumentUpload/index" */))
const _0aa8cea8 = () => interopDefault(import('../src/pages/SelfService/ReportDamage/Deadline/index.vue' /* webpackChunkName: "pages/SelfService/ReportDamage/Deadline/index" */))
const _453d2dbe = () => interopDefault(import('../src/pages/SelfService/ReportDamage/Counterparty/index.vue' /* webpackChunkName: "pages/SelfService/ReportDamage/Counterparty/index" */))
const _e887a16e = () => interopDefault(import('../src/pages/SelfService/ReportDamage/Facts/index.vue' /* webpackChunkName: "pages/SelfService/ReportDamage/Facts/index" */))
const _4cec27b4 = () => interopDefault(import('../src/pages/PartnerPortal/ReportDamage/ThankYou/index.vue' /* webpackChunkName: "pages/PartnerPortal/ReportDamage/ThankYou/index" */))
const _09ebf3ca = () => interopDefault(import('../src/pages/PartnerPortal/ReportDamage/DocumentUpload/index.vue' /* webpackChunkName: "pages/PartnerPortal/ReportDamage/DocumentUpload/index" */))
const _034fa577 = () => interopDefault(import('../src/pages/PartnerPortal/ReportDamage/Deadline/index.vue' /* webpackChunkName: "pages/PartnerPortal/ReportDamage/Deadline/index" */))
const _1027dd09 = () => interopDefault(import('../src/pages/PartnerPortal/ReportDamage/Counterparty/index.vue' /* webpackChunkName: "pages/PartnerPortal/ReportDamage/Counterparty/index" */))
const _58194e5e = () => interopDefault(import('../src/pages/PartnerPortal/ReportDamage/Facts/index.vue' /* webpackChunkName: "pages/PartnerPortal/ReportDamage/Facts/index" */))
const _1991d604 = () => interopDefault(import('../src/pages/PartnerPortal/Registration/ThankYou/index.vue' /* webpackChunkName: "pages/PartnerPortal/Registration/ThankYou/index" */))
const _ada2677c = () => interopDefault(import('../src/pages/BrokerOffer/Intro/B-Business/index.vue' /* webpackChunkName: "pages/BrokerOffer/Intro/B-Business/index" */))
const _64ca1ada = () => interopDefault(import('../src/pages/BrokerOffer/Intro/B-Move/index.vue' /* webpackChunkName: "pages/BrokerOffer/Intro/B-Move/index" */))
const _e3e522ac = () => interopDefault(import('../src/pages/BrokerOffer/Intro/P-Combi/index.vue' /* webpackChunkName: "pages/BrokerOffer/Intro/P-Combi/index" */))
const _239514c5 = () => interopDefault(import('../src/pages/BrokerOffer/Intro/P-Move/index.vue' /* webpackChunkName: "pages/BrokerOffer/Intro/P-Move/index" */))
const _30e6ff25 = () => interopDefault(import('../src/pages/BrokerOffer/Intro/P-Private/index.vue' /* webpackChunkName: "pages/BrokerOffer/Intro/P-Private/index" */))
const _bd5ef506 = () => interopDefault(import('../src/pages/BrokerOffer/Intro/Small-Business/index.vue' /* webpackChunkName: "pages/BrokerOffer/Intro/Small-Business/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/antragsstrecke-iframe",
    component: _51f3ad2e,
    name: "OfferIframe___de"
  }, {
    path: "/anwaltsportal",
    component: _6d2cef18,
    name: "LawyerPortal___de"
  }, {
    path: "/dev",
    component: _455aa203,
    name: "dev___de"
  }, {
    path: "/fr",
    component: _cc619e06,
    name: "index___fr"
  }, {
    path: "/groupemutuel",
    component: _5fce3c04,
    name: "GroupeMutuel___de"
  }, {
    path: "/it",
    component: _cc619e06,
    name: "index___it"
  }, {
    path: "/kundencenter",
    component: _00dfc8d2,
    name: "SelfService___de"
  }, {
    path: "/login",
    component: _1663b377,
    name: "Login___de"
  }, {
    path: "/offline-registrierung",
    component: _29fe2a38,
    name: "SelfService-OfflineRegistration___de"
  }, {
    path: "/partnerportal",
    component: _6d745abc,
    name: "PartnerPortal___de"
  }, {
    path: "/passwort-festlegen",
    component: _75beb353,
    name: "SelfService-NewPasswort___de"
  }, {
    path: "/portal-auswaehlen",
    component: _6024fe10,
    name: "SelectPortal___de"
  }, {
    path: "/pw-zuruecksetzen",
    component: _663017d4,
    name: "PwReset___de"
  }, {
    path: "/registration",
    component: _0f8fbdfe,
    name: "Registration___de"
  }, {
    path: "/ueberarbeiten-zugangsdaten",
    component: _1951c263,
    name: "ConflictSolver-SingleAccount___de"
  }, {
    path: "/ueberarbeiten-zugangsdaten-dextra",
    component: _314e56d1,
    name: "ConflictSolver-Unsolvable___de"
  }, {
    path: "/ueberarbeiten-zugangsdaten-multi",
    component: _ef10e8a8,
    name: "ConflictSolver-MultiAccount___de"
  }, {
    path: "/anwaltsportal/benutzerdaten",
    component: _00e053bc,
    name: "LawyerPortal-UserData___de"
  }, {
    path: "/anwaltsportal/faelle",
    component: _7682ab7c,
    name: "LawyerPortal-Damage___de"
  }, {
    path: "/anwaltsportal/geschlossene-rechtsfaelle",
    component: _7a766b9e,
    name: "LawyerPortal-ClosedDamageCases___de"
  }, {
    path: "/anwaltsportal/mandat-ablehnen",
    component: _462b37bb,
    name: "LawyerPortal-RejectDamage___de"
  }, {
    path: "/anwaltsportal/neuer-rechtsfall",
    component: _052ff968,
    name: "LawyerPortal-NewDamageCase___de"
  }, {
    path: "/anwaltsportal/nutzungsbedingungen",
    component: _3360db24,
    name: "LawyerPortal-TermsOfService___de"
  }, {
    path: "/anwaltsportal/postfach",
    component: _15ef9d5d,
    name: "LawyerPortal-Mailbox___de"
  }, {
    path: "/anwaltsportal/registrierung",
    component: _9bf93274,
    name: "LawyerPortal-Registration___de"
  }, {
    path: "/anwaltsportal/registrierung-info",
    component: _50924894,
    name: "LawyerPortal-RegistrationInfo___de"
  }, {
    path: "/fr/centre-clients",
    component: _00dfc8d2,
    name: "SelfService___fr"
  }, {
    path: "/fr/connexion",
    component: _1663b377,
    name: "Login___fr"
  }, {
    path: "/fr/dev",
    component: _455aa203,
    name: "dev___fr"
  }, {
    path: "/fr/formulaire-de-demande-iframe",
    component: _51f3ad2e,
    name: "OfferIframe___fr"
  }, {
    path: "/fr/groupemutuel",
    component: _5fce3c04,
    name: "GroupeMutuel___fr"
  }, {
    path: "/fr/inscription",
    component: _0f8fbdfe,
    name: "Registration___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _663017d4,
    name: "PwReset___fr"
  }, {
    path: "/fr/offline-inscription",
    component: _29fe2a38,
    name: "SelfService-OfflineRegistration___fr"
  }, {
    path: "/fr/portail-avocat",
    component: _6d2cef18,
    name: "LawyerPortal___fr"
  }, {
    path: "/fr/portail-sélectionner",
    component: _6024fe10,
    name: "SelectPortal___fr"
  }, {
    path: "/fr/portailpartenaires",
    component: _6d745abc,
    name: "PartnerPortal___fr"
  }, {
    path: "/fr/reviser-donnees-d-acces",
    component: _1951c263,
    name: "ConflictSolver-SingleAccount___fr"
  }, {
    path: "/fr/reviser-donnees-d-acces-dextra",
    component: _314e56d1,
    name: "ConflictSolver-Unsolvable___fr"
  }, {
    path: "/fr/reviser-donnees-d-acces-multi",
    component: _ef10e8a8,
    name: "ConflictSolver-MultiAccount___fr"
  }, {
    path: "/fr/setmotdepasse",
    component: _75beb353,
    name: "SelfService-NewPasswort___fr"
  }, {
    path: "/it/accesso",
    component: _1663b377,
    name: "Login___it"
  }, {
    path: "/it/centro-clienti",
    component: _00dfc8d2,
    name: "SelfService___it"
  }, {
    path: "/it/dev",
    component: _455aa203,
    name: "dev___it"
  }, {
    path: "/it/groupemutuel",
    component: _5fce3c04,
    name: "GroupeMutuel___it"
  }, {
    path: "/it/impostare-password",
    component: _75beb353,
    name: "SelfService-NewPasswort___it"
  }, {
    path: "/it/OfferIframe",
    component: _51f3ad2e,
    name: "OfferIframe___it"
  }, {
    path: "/it/portale -seleziona",
    component: _6024fe10,
    name: "SelectPortal___it"
  }, {
    path: "/it/portale-procuratori",
    component: _6d2cef18,
    name: "LawyerPortal___it"
  }, {
    path: "/it/portaledeipartner",
    component: _6d745abc,
    name: "PartnerPortal___it"
  }, {
    path: "/it/Registration",
    component: _0f8fbdfe,
    name: "Registration___it"
  }, {
    path: "/it/reimposta-password",
    component: _663017d4,
    name: "PwReset___it"
  }, {
    path: "/it/rivedere-dati-d-accesso",
    component: _1951c263,
    name: "ConflictSolver-SingleAccount___it"
  }, {
    path: "/it/rivedere-dati-d-accesso-dextra",
    component: _314e56d1,
    name: "ConflictSolver-Unsolvable___it"
  }, {
    path: "/it/rivedere-dati-d-accesso-multi",
    component: _ef10e8a8,
    name: "ConflictSolver-MultiAccount___it"
  }, {
    path: "/kundencenter/benutzerdaten",
    component: _3c6d4883,
    name: "SelfService-UserData___de"
  }, {
    path: "/kundencenter/fallverwaltung",
    component: _3ea3c606,
    name: "SelfService-ClaimOverview___de"
  }, {
    path: "/kundencenter/rechnungen",
    component: _33ba2fd8,
    name: "SelfService-Invoices___de"
  }, {
    path: "/kundencenter/rechtsfall-melden",
    component: _5059268e,
    name: "SelfService-ReportDamage-MyInfo___de"
  }, {
    path: "/partnerportal/abgeschlossene-policen",
    component: _a665e842,
    name: "PartnerPortal-ClosedPolicies___de"
  }, {
    path: "/partnerportal/antragsstrecke",
    component: _afbdd404,
    name: "BrokerOffer-Offer___de"
  }, {
    path: "/partnerportal/benutzerdaten",
    component: _e5456450,
    name: "PartnerPortal-UserData___de"
  }, {
    path: "/partnerportal/gemeinde",
    component: _472681cd,
    name: "BrokerOffer-OfferSpecial-gemeinde___de"
  }, {
    path: "/partnerportal/pendente-offerten",
    component: _3a84e178,
    name: "PartnerPortal-OpenOffers___de"
  }, {
    path: "/partnerportal/rechtsfall-melden",
    component: _24dd5e4d,
    name: "PartnerPortal-ReportDamage-Info___de"
  }, {
    path: "/partnerportal/rechtsfallverwaltung",
    component: _10733c95,
    name: "PartnerPortal-DamageManagement___de"
  }, {
    path: "/partnerportal/registrieren",
    component: _2842137c,
    name: "PartnerPortal-Registration___de"
  }, {
    path: "/requests/accept",
    component: _7148aa71,
    name: "Requests-AcceptShareRequest___de"
  }, {
    path: "/requests/reject",
    component: _22a94dc8,
    name: "Requests-RejectShareRequest___de"
  }, {
    path: "/fr/centre-clients/cas-juridique-administration",
    component: _3ea3c606,
    name: "SelfService-ClaimOverview___fr"
  }, {
    path: "/fr/centre-clients/cas-juridique-rapport",
    component: _5059268e,
    name: "SelfService-ReportDamage-MyInfo___fr"
  }, {
    path: "/fr/centre-clients/donnees-utilisateur",
    component: _3c6d4883,
    name: "SelfService-UserData___fr"
  }, {
    path: "/fr/centre-clients/factures",
    component: _33ba2fd8,
    name: "SelfService-Invoices___fr"
  }, {
    path: "/fr/portail-avocat/boite-aux-lettres",
    component: _15ef9d5d,
    name: "LawyerPortal-Mailbox___fr"
  }, {
    path: "/fr/portail-avocat/cas-juridique",
    component: _7682ab7c,
    name: "LawyerPortal-Damage___fr"
  }, {
    path: "/fr/portail-avocat/conditions-d-utilisation",
    component: _3360db24,
    name: "LawyerPortal-TermsOfService___fr"
  }, {
    path: "/fr/portail-avocat/donnees-utilisateur",
    component: _00e053bc,
    name: "LawyerPortal-UserData___fr"
  }, {
    path: "/fr/portail-avocat/inscription",
    component: _9bf93274,
    name: "LawyerPortal-Registration___fr"
  }, {
    path: "/fr/portail-avocat/inscription-info",
    component: _50924894,
    name: "LawyerPortal-RegistrationInfo___fr"
  }, {
    path: "/fr/portail-avocat/nouveau-cas-juridique",
    component: _052ff968,
    name: "LawyerPortal-NewDamageCase___fr"
  }, {
    path: "/fr/portail-avocat/refuser-un-mandat",
    component: _462b37bb,
    name: "LawyerPortal-RejectDamage___fr"
  }, {
    path: "/fr/portail-avocat/revendications-fermees",
    component: _7a766b9e,
    name: "LawyerPortal-ClosedDamageCases___fr"
  }, {
    path: "/fr/portaildespartenaires/politiques-conclues",
    component: _a665e842,
    name: "PartnerPortal-ClosedPolicies___fr"
  }, {
    path: "/fr/portailpartenaires/cas-juridique-rapport",
    component: _24dd5e4d,
    name: "PartnerPortal-ReportDamage-Info___fr"
  }, {
    path: "/fr/portailpartenaires/donnees-utilisateur",
    component: _e5456450,
    name: "PartnerPortal-UserData___fr"
  }, {
    path: "/fr/portailpartenaires/enregistrer",
    component: _2842137c,
    name: "PartnerPortal-Registration___fr"
  }, {
    path: "/fr/portailpartenaires/formulaire-de-demande",
    component: _afbdd404,
    name: "BrokerOffer-Offer___fr"
  }, {
    path: "/fr/portailpartenaires/gestion-des-dossiers",
    component: _10733c95,
    name: "PartnerPortal-DamageManagement___fr"
  }, {
    path: "/fr/portailpartenaires/offres-en-attente",
    component: _3a84e178,
    name: "PartnerPortal-OpenOffers___fr"
  }, {
    path: "/fr/protection-juridique-formulaire-de-demande/modifier-le-tarif",
    component: _1715ada8,
    name: "BrokerOffer-ChangeTariff___fr"
  }, {
    path: "/fr/requests/accept",
    component: _7148aa71,
    name: "Requests-AcceptShareRequest___fr"
  }, {
    path: "/fr/requests/reject",
    component: _22a94dc8,
    name: "Requests-RejectShareRequest___fr"
  }, {
    path: "/it/BrokerOffer/ChangeTariff",
    component: _1715ada8,
    name: "BrokerOffer-ChangeTariff___it"
  }, {
    path: "/it/BrokerOffer/Offer",
    component: _afbdd404,
    name: "BrokerOffer-Offer___it"
  }, {
    path: "/it/BrokerOffer/Summary",
    component: _47734908,
    name: "BrokerOffer-Summary___it"
  }, {
    path: "/it/BrokerOffer/ThankYou",
    component: _ea56466e,
    name: "BrokerOffer-ThankYou___it"
  }, {
    path: "/it/centro-clienti/fatture",
    component: _33ba2fd8,
    name: "SelfService-Invoices___it"
  }, {
    path: "/it/centro-clienti/gestione-dei-casi",
    component: _3ea3c606,
    name: "SelfService-ClaimOverview___it"
  }, {
    path: "/it/centro-clienti/informazioni-utente",
    component: _3c6d4883,
    name: "SelfService-UserData___it"
  }, {
    path: "/it/cliente/segnalare-caso-giuridico",
    component: _5059268e,
    name: "SelfService-ReportDamage-MyInfo___it"
  }, {
    path: "/it/portale-procuratori/caso-giuridico",
    component: _7682ab7c,
    name: "LawyerPortal-Damage___it"
  }, {
    path: "/it/portale-procuratori/condizioni-di-utilizzo",
    component: _3360db24,
    name: "LawyerPortal-TermsOfService___it"
  }, {
    path: "/it/portale-procuratori/crediti-chiusi",
    component: _7a766b9e,
    name: "LawyerPortal-ClosedDamageCases___it"
  }, {
    path: "/it/portale-procuratori/informazioni-utente",
    component: _00e053bc,
    name: "LawyerPortal-UserData___it"
  }, {
    path: "/it/portale-procuratori/nuove-perdite",
    component: _052ff968,
    name: "LawyerPortal-NewDamageCase___it"
  }, {
    path: "/it/portale-procuratori/registrazione",
    component: _9bf93274,
    name: "LawyerPortal-Registration___it"
  }, {
    path: "/it/portale-procuratori/registrazione-info",
    component: _50924894,
    name: "LawyerPortal-RegistrationInfo___it"
  }, {
    path: "/it/portale-procuratori/rifiutare-un-mandato",
    component: _462b37bb,
    name: "LawyerPortal-RejectDamage___it"
  }, {
    path: "/it/portale-procuratori/scatola-po",
    component: _15ef9d5d,
    name: "LawyerPortal-Mailbox___it"
  }, {
    path: "/it/portaledeipartner/gestione-del-caso",
    component: _10733c95,
    name: "PartnerPortal-DamageManagement___it"
  }, {
    path: "/it/portaledeipartner/informazioni-utente",
    component: _e5456450,
    name: "PartnerPortal-UserData___it"
  }, {
    path: "/it/portaledeipartner/offerte-in-corso",
    component: _3a84e178,
    name: "PartnerPortal-OpenOffers___it"
  }, {
    path: "/it/portaledeipartner/politiche-concluse",
    component: _a665e842,
    name: "PartnerPortal-ClosedPolicies___it"
  }, {
    path: "/it/portaledeipartner/registro",
    component: _2842137c,
    name: "PartnerPortal-Registration___it"
  }, {
    path: "/it/portaledeipartner/segnalare-caso-giuridico",
    component: _24dd5e4d,
    name: "PartnerPortal-ReportDamage-Info___it"
  }, {
    path: "/it/requests/accept",
    component: _7148aa71,
    name: "Requests-AcceptShareRequest___it"
  }, {
    path: "/it/requests/reject",
    component: _22a94dc8,
    name: "Requests-RejectShareRequest___it"
  }, {
    path: "/it/SelfService/OfflineRegistration",
    component: _29fe2a38,
    name: "SelfService-OfflineRegistration___it"
  }, {
    path: "/kundencenter/rechtsfall-melden/danke",
    component: _44481ae9,
    name: "SelfService-ReportDamage-ThankYou___de"
  }, {
    path: "/kundencenter/rechtsfall-melden/dokumentenupload",
    component: _400dfd90,
    name: "SelfService-ReportDamage-DocumentUpload___de"
  }, {
    path: "/kundencenter/rechtsfall-melden/frist",
    component: _0aa8cea8,
    name: "SelfService-ReportDamage-Deadline___de"
  }, {
    path: "/kundencenter/rechtsfall-melden/gegenpartei",
    component: _453d2dbe,
    name: "SelfService-ReportDamage-Counterparty___de"
  }, {
    path: "/kundencenter/rechtsfall-melden/sachverhalt",
    component: _e887a16e,
    name: "SelfService-ReportDamage-Facts___de"
  }, {
    path: "/partnerportal/rechtsfall-melden/danke",
    component: _4cec27b4,
    name: "PartnerPortal-ReportDamage-ThankYou___de"
  }, {
    path: "/partnerportal/rechtsfall-melden/dokumentenupload",
    component: _09ebf3ca,
    name: "PartnerPortal-ReportDamage-DocumentUpload___de"
  }, {
    path: "/partnerportal/rechtsfall-melden/frist",
    component: _034fa577,
    name: "PartnerPortal-ReportDamage-Deadline___de"
  }, {
    path: "/partnerportal/rechtsfall-melden/gegenpartei",
    component: _1027dd09,
    name: "PartnerPortal-ReportDamage-Counterparty___de"
  }, {
    path: "/partnerportal/rechtsfall-melden/sachverhalt",
    component: _58194e5e,
    name: "PartnerPortal-ReportDamage-Facts___de"
  }, {
    path: "/partnerportal/rechtsschutz-antragsstrecke/tarif-aendern",
    component: _1715ada8,
    name: "BrokerOffer-ChangeTariff___de"
  }, {
    path: "/partnerportal/rechtsschutz-antragsstrecke/willkommen-bei-dextra",
    component: _ea56466e,
    name: "BrokerOffer-ThankYou___de"
  }, {
    path: "/partnerportal/rechtsschutz-antragsstrecke/zusammenfassung",
    component: _47734908,
    name: "BrokerOffer-Summary___de"
  }, {
    path: "/partnerportal/registrieren/danke",
    component: _1991d604,
    name: "PartnerPortal-Registration-ThankYou___de"
  }, {
    path: "/fr/BrokerOffer/OfferSpecial/gemeinde",
    component: _472681cd,
    name: "BrokerOffer-OfferSpecial-gemeinde___fr"
  }, {
    path: "/fr/centre-clients/cas-juridique-rapport/contrepartie",
    component: _453d2dbe,
    name: "SelfService-ReportDamage-Counterparty___fr"
  }, {
    path: "/fr/centre-clients/cas-juridique-rapport/date-limite",
    component: _0aa8cea8,
    name: "SelfService-ReportDamage-Deadline___fr"
  }, {
    path: "/fr/centre-clients/cas-juridique-rapport/merci",
    component: _44481ae9,
    name: "SelfService-ReportDamage-ThankYou___fr"
  }, {
    path: "/fr/centre-clients/cas-juridique-rapport/realites",
    component: _e887a16e,
    name: "SelfService-ReportDamage-Facts___fr"
  }, {
    path: "/fr/centre-clients/cas-juridique-rapport/telecharger-un-document",
    component: _400dfd90,
    name: "SelfService-ReportDamage-DocumentUpload___fr"
  }, {
    path: "/fr/portailpartenaires/cas-juridique-rapport/contrepartie",
    component: _1027dd09,
    name: "PartnerPortal-ReportDamage-Counterparty___fr"
  }, {
    path: "/fr/portailpartenaires/cas-juridique-rapport/date-limite",
    component: _034fa577,
    name: "PartnerPortal-ReportDamage-Deadline___fr"
  }, {
    path: "/fr/portailpartenaires/cas-juridique-rapport/merci",
    component: _4cec27b4,
    name: "PartnerPortal-ReportDamage-ThankYou___fr"
  }, {
    path: "/fr/portailpartenaires/cas-juridique-rapport/realites",
    component: _58194e5e,
    name: "PartnerPortal-ReportDamage-Facts___fr"
  }, {
    path: "/fr/portailpartenaires/cas-juridique-rapport/telecharger-un-document",
    component: _09ebf3ca,
    name: "PartnerPortal-ReportDamage-DocumentUpload___fr"
  }, {
    path: "/fr/portailpartenaires/enregistrer/merci",
    component: _1991d604,
    name: "PartnerPortal-Registration-ThankYou___fr"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-formulaire-de-demande/bienvenue-chez-dextra",
    component: _ea56466e,
    name: "BrokerOffer-ThankYou___fr"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-formulaire-de-demande/resume",
    component: _47734908,
    name: "BrokerOffer-Summary___fr"
  }, {
    path: "/it/BrokerOffer/Intro/B-Business",
    component: _ada2677c,
    name: "BrokerOffer-Intro-B-Business___it"
  }, {
    path: "/it/BrokerOffer/Intro/B-Move",
    component: _64ca1ada,
    name: "BrokerOffer-Intro-B-Move___it"
  }, {
    path: "/it/BrokerOffer/Intro/P-Combi",
    component: _e3e522ac,
    name: "BrokerOffer-Intro-P-Combi___it"
  }, {
    path: "/it/BrokerOffer/Intro/P-Move",
    component: _239514c5,
    name: "BrokerOffer-Intro-P-Move___it"
  }, {
    path: "/it/BrokerOffer/Intro/P-Private",
    component: _30e6ff25,
    name: "BrokerOffer-Intro-P-Private___it"
  }, {
    path: "/it/BrokerOffer/Intro/Small-Business",
    component: _bd5ef506,
    name: "BrokerOffer-Intro-Small-Business___it"
  }, {
    path: "/it/BrokerOffer/OfferSpecial/gemeinde",
    component: _472681cd,
    name: "BrokerOffer-OfferSpecial-gemeinde___it"
  }, {
    path: "/it/cliente/segnalare-caso-giuridico/carica-documento",
    component: _400dfd90,
    name: "SelfService-ReportDamage-DocumentUpload___it"
  }, {
    path: "/it/cliente/segnalare-caso-giuridico/controparte",
    component: _453d2dbe,
    name: "SelfService-ReportDamage-Counterparty___it"
  }, {
    path: "/it/cliente/segnalare-caso-giuridico/fatti",
    component: _e887a16e,
    name: "SelfService-ReportDamage-Facts___it"
  }, {
    path: "/it/cliente/segnalare-caso-giuridico/grazie",
    component: _44481ae9,
    name: "SelfService-ReportDamage-ThankYou___it"
  }, {
    path: "/it/cliente/segnalare-caso-giuridico/scadenza",
    component: _0aa8cea8,
    name: "SelfService-ReportDamage-Deadline___it"
  }, {
    path: "/it/portaledeipartner/registro/grazie",
    component: _1991d604,
    name: "PartnerPortal-Registration-ThankYou___it"
  }, {
    path: "/it/portaledeipartner/segnalare-caso-giuridico/carica-documento",
    component: _09ebf3ca,
    name: "PartnerPortal-ReportDamage-DocumentUpload___it"
  }, {
    path: "/it/portaledeipartner/segnalare-caso-giuridico/controparte",
    component: _1027dd09,
    name: "PartnerPortal-ReportDamage-Counterparty___it"
  }, {
    path: "/it/portaledeipartner/segnalare-caso-giuridico/fatti",
    component: _58194e5e,
    name: "PartnerPortal-ReportDamage-Facts___it"
  }, {
    path: "/it/portaledeipartner/segnalare-caso-giuridico/grazie",
    component: _4cec27b4,
    name: "PartnerPortal-ReportDamage-ThankYou___it"
  }, {
    path: "/it/portaledeipartner/segnalare-caso-giuridico/scadenza",
    component: _034fa577,
    name: "PartnerPortal-ReportDamage-Deadline___it"
  }, {
    path: "/partnerportal/privatrechtsschutz/p-kombi/antragsstrecke",
    component: _e3e522ac,
    name: "BrokerOffer-Intro-P-Combi___de"
  }, {
    path: "/partnerportal/privatrechtsschutz/p-move/antragsstrecke",
    component: _239514c5,
    name: "BrokerOffer-Intro-P-Move___de"
  }, {
    path: "/partnerportal/privatrechtsschutz/p-private/antragsstrecke",
    component: _30e6ff25,
    name: "BrokerOffer-Intro-P-Private___de"
  }, {
    path: "/partnerportal/unternehmensrechtsschutz/b-business/antragsstrecke",
    component: _ada2677c,
    name: "BrokerOffer-Intro-B-Business___de"
  }, {
    path: "/partnerportal/unternehmensrechtsschutz/b-move/antragsstrecke",
    component: _64ca1ada,
    name: "BrokerOffer-Intro-B-Move___de"
  }, {
    path: "/partnerportal/unternehmensrechtsschutz/small-business/antragsstrecke",
    component: _bd5ef506,
    name: "BrokerOffer-Intro-Small-Business___de"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-entreprise/b-business/formulaire-de-demande",
    component: _ada2677c,
    name: "BrokerOffer-Intro-B-Business___fr"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-entreprise/b-move/formulaire-de-demande",
    component: _64ca1ada,
    name: "BrokerOffer-Intro-B-Move___fr"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-entreprise/small-business/formulaire-de-demande",
    component: _bd5ef506,
    name: "BrokerOffer-Intro-Small-Business___fr"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-privee/p-combi/formulaire-de-demande",
    component: _e3e522ac,
    name: "BrokerOffer-Intro-P-Combi___fr"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-privee/p-move/formulaire-de-demande",
    component: _239514c5,
    name: "BrokerOffer-Intro-P-Move___fr"
  }, {
    path: "/fr/portailpartenaires/protection-juridique-privee/p-private/formulaire-de-demande",
    component: _30e6ff25,
    name: "BrokerOffer-Intro-P-Private___fr"
  }, {
    path: "/",
    component: _cc619e06,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
