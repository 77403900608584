<template>
  <div>
    <dx-scip-sales
      :product-key="key"
      :variant-key="variantKey"
      :form-key="formKey"
      :dex-id="contractId"
    />
  </div>
</template>

<script>
import DxScipSales from '~/components/DX-ScipSales/form'

export default {
  name: 'BrokerOfferScipSales',
  components: {
    DxScipSales,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/antragsstrecke/',
      fr: '/portailpartenaires/formulaire-de-demande/',
    },
  },

  computed: {
    key() {
      return this.$route.query.key || ''
    },
    variantKey() {
      return this.$route.query.variantKey || ''
    },

    formKey() {
      if (this.key === 'private' || this.key === 'business') {
        // Old products
        return undefined
      }

      return `${this.variantKey}_broker`
    },

    contractId() {
      return (
        {
          contractId: this.$route.query.contractId,
          key: this.key,
          variantKey: this.variantKey,
          versionNumber: this.$route.query.versionNumber || null,
        } || null
      )
    },
  },
}
</script>
