<template>
  <div>
    <div v-for="language in LANGUAGES" :key="language">
      <dx-radio-check
        :id="`id_${language}`"
        :label="$t(language)"
        :value="language"
        :checked="hasLanguage(language)"
        :error="errors.has('languages')"
        name="checkbox_group_1"
        type="checkbox"
        @input="updateLanguage(language)"
      />
      <dx-selection-buttons
        v-if="hasLanguage(language)"
        :id="language"
        :buttons="LANGUAGE_SKILLS"
        :name="language"
        :active="languageLevel(language)"
        translation-key="lawyerPortal.languageSkill"
        class="mb-4"
        @input="(level) => updateLanguage(language, level)"
      />
    </div>
    <dx-input
      v-model="customLanguage.language"
      v-validate.disable="'required'"
      :label="$t('moreLanguages')"
      :error="errors.first('language', 'customLanguage')"
      class="mt-5 mb-2"
      data-vv-scope="customLanguage"
      data-vv-name="language"
    />

    <dx-selection-buttons
      id="morelangs"
      :buttons="LANGUAGE_SKILLS"
      :active="customLanguage.level"
      translation-key="lawyerPortal.languageSkill"
      name="more_langs"
      @input="(level) => (customLanguage.level = level)"
    />

    <div class="text-right">
      <button
        type="button"
        class="secondary-link btn-blank"
        @click="addCustomLanguage()"
      >
        {{ $t('addLanguage') }}
      </button>
    </div>
    <div v-if="errors.first('languages')" class="error">
      {{ errors.first('languages') }}
    </div>
    <dx-simple-delete-list
      :items="
        languageSkills.filter((ls) => !LANGUAGES.some((l) => l === ls.language))
      "
      :get-translated-label="
        (ls) =>
          `${ls.language} (${$t('lawyerPortal.languageSkill.' + ls.level)})`
      "
      :on-delete-click="
        (cL) =>
          updateLanguageSkills({
            languageSkills: languageSkills.filter(
              (cl) => cl.language !== cL.language,
            ),
          })
      "
      item-key="language"
    />
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioCheck,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxSelectionButtons from '~/components/DX-SelectionButtons'
import DxSimpleDeleteList from '~/components/DX-SimpleDeleteList'

import { LANGUAGES, LANGUAGE_SKILLS } from '~/domains/externalLawyer/constants'

export default {
  name: 'DxLanguageSkills',
  components: {
    DxInput,
    DxSimpleDeleteList,
    DxSelectionButtons,
    DxRadioCheck,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    skills: {
      type: Array,
      default: () => [],
    },
    updateLanguageSkills: {
      type: Function,
      default: () => null,
    },
    clearErrors: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      customLanguage: {
        language: '',
        level: LANGUAGE_SKILLS[0],
      },
    }
  },
  computed: {
    LANGUAGE_SKILLS: () => LANGUAGE_SKILLS,
    LANGUAGES: () => LANGUAGES,
    languageSkills() {
      return [...this.skills]
    },
  },
  methods: {
    hasLanguage(language) {
      return this.languageSkills.some((l) => l.language === language)
    },
    updateLanguage(language, level = null) {
      if (this.hasLanguage(language)) {
        if (level) {
          this.updateLanguageSkills({
            languageSkills: [
              ...this.languageSkills.filter((l) => l.language !== language),
              { language, level },
            ],
          })
        } else {
          this.updateLanguageSkills({
            languageSkills: [
              ...this.languageSkills.filter((l) => l.language !== language),
            ],
          })
        }
      } else {
        this.updateLanguageSkills({
          languageSkills: [
            ...this.languageSkills,
            {
              language,
              level: LANGUAGE_SKILLS[0],
            },
          ],
        })
      }
    },
    languageLevel(language) {
      return this.languageSkills.find((l) => l.language === language).level
    },
    async addCustomLanguage() {
      this.clearErrors()
      const valid = await this.$validator.validateAll('customLanguage')
      if (valid) {
        const { language, level } = this.customLanguage
        if (this.hasLanguage(language)) {
          this.updateLanguageSkills({
            languageSkills: [
              ...this.languageSkills.filter((l) => l.language !== language),
              { language, level },
            ],
          })
        } else {
          this.updateLanguageSkills({
            languageSkills: [...this.languageSkills, { language, level }],
          })
        }
        this.customLanguage = {
          language: '',
          level: this.LANGUAGE_SKILLS[0],
        }
      }
    },
  },
}
</script>
