<template>
  <div>
    <dx-radio-list
      v-model="personalData.salutation"
      v-validate="'required'"
      :options="salutationData"
      :error="errors.first('salutation')"
      :label="$t('salutation')"
      name="salutation"
      class="mb-4"
      data-vv-name="salutation"
      @input="
        (value) => updatePersonalData({ salutation: value ? value : null })
      "
    />

    <dx-select
      :value="personalData.academicDegree"
      :options="makeDataMap('title', TITLE)"
      :label="$t('title')"
      :error="$validator.errors.first('title')"
      class="mb-4"
      @input="
        (value) => updatePersonalData({ academicDegree: value ? value : null })
      "
    />

    <dx-input
      v-model="personalData.firstName"
      v-validate="'required|min:2'"
      :error="errors.first('firstName')"
      :label="$t('firstName')"
      class="mb-4"
      data-vv-name="firstName"
      autocomplete="given-name"
      @input="
        (value) => updatePersonalData({ firstName: value ? value : null })
      "
    />

    <dx-input
      v-model="personalData.lastName"
      v-validate="'required|min:2'"
      :error="errors.first('lastName')"
      :label="$t('lastName')"
      class="mb-4"
      data-vv-name="lastName"
      autocomplete="family-name"
      @input="(value) => updatePersonalData({ lastName: value ? value : null })"
    />

    <dx-input
      v-model="personalData.companyName"
      :label="$t('company')"
      class="mb-4"
      autocomplete="organization"
      @input="
        (value) => updatePersonalData({ companyName: value ? value : null })
      "
    />

    <div class="d-flex mb-4">
      <dx-input
        v-model="personalData.address.street"
        v-validate="{ required: isStreetRequired, min: 2 }"
        :label="$t('street')"
        :error="errors.first('street')"
        class="mr-3 w-75"
        data-vv-name="street"
        autocomplete="address-line1"
        @input="
          (value) => updatePersonalData({ address: personalData.address })
        "
      />
      <dx-input
        v-model="personalData.address.number"
        v-validate="{ required: isNumberRequired, min: 1 }"
        :label="$t('houseNumber')"
        :error="errors.first('streetNumber')"
        class="w-40"
        data-vv-name="streetNumber"
        autocomplete="address-line2"
        @input="
          (value) => updatePersonalData({ address: personalData.address })
        "
      />
    </div>

    <dx-input
      v-model="personalData.address.additional"
      v-validate="{ required: isAdditionalRequired }"
      :label="$t('additional')"
      :error="errors.first('additional')"
      data-vv-name="additional"
      class="mb-4"
      @input="(value) => updatePersonalData({ address: personalData.address })"
    />

    <dx-zip-to-city-input
      :zip-input="personalData.address.zip"
      :city-input="personalData.address.city"
      :required="true"
      @update:city="(city) => (personalData.address.city = city)"
      @update:zip="(zip) => (personalData.address.zip = zip)"
      @input="(value) => updatePersonalData({ address: personalData.address })"
    />

    <dx-input
      v-model="personalData.dateOfBirth"
      :label="$t('dateofbirth')"
      type="date"
      class="mb-4"
      @input="
        (value) => updatePersonalData({ dateOfBirth: value ? value : null })
      "
    />

    <dx-select
      v-model="personalData.locale"
      :options="contactLanguageOptions()"
      :label="$t('lawyerPortal.contactLanguage.label')"
      :error="$validator.errors.first('contactLanguage')"
      data-vv-name="contactLanguage"
      class="mb-4"
    />
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
  DxSelect,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxZipToCityInput from '~/components/DX-ZipToCityInput'
import { TITLE, CONTACT_LANGUAGES } from '~/domains/externalLawyer/constants'

export default {
  name: 'DxPersonalData',
  components: {
    DxInput,
    DxZipToCityInput,
    DxRadioList,
    DxSelect,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    personalData: {
      type: Object,
      default: () => ({
        address: {},
      }),
    },
    updatePersonalData: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      salutationData: [
        {
          text: this.$t('male'),
          id: 'MALE',
        },
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
      ],
    }
  },
  computed: {
    TITLE: () => TITLE,

    isStreetRequired() {
      return !this.personalData.address.additional
    },
    isNumberRequired() {
      return this.isStreetRequired
    },
    isAdditionalRequired() {
      if (
        !this.personalData.address.street ||
        !this.personalData.address.number
      ) {
        return true
      }

      return (
        this.personalData.address.street.length < 2 &&
        this.personalData.address.number.length < 1
      )
    },
  },
  methods: {
    contactLanguageOptions() {
      const options = CONTACT_LANGUAGES.map((lang) => ({
        label: this.$t(`lawyerPortal.contactLanguage.${lang}`),
        value: lang,
      }))

      return [...options]
    },

    makeDataMap(translationKey, ITEMS, FILTER_ITEMS = []) {
      const defaultOption = {
        label: this.$t('selectOption'),
        value: null,
      }
      const itemMap = ITEMS.map((item) => ({
        label: this.$t(`lawyerPortal.${translationKey}.${item}`),
        value: item,
      })).filter((item) => !FILTER_ITEMS.some((fi) => fi === item.value))
      return [defaultOption, ...itemMap]
    },
  },
}
</script>
