<template>
  <dx-loading-overlay v-if="loading" />
  <div v-else-if="!error">
    <h3 class="mb-3">
      {{ $t('damageCheck.new') }}
    </h3>
    <p v-if="!vnEqualsVp()">
      {{ getNameWithSalutation(claim.policyholder) }}
    </p>
    <dx-party-counterparty :claim="claim" />

    <div v-if="!moreDetails" class="text-center text-md-right mb-4">
      <button
        class="secondary-link btn-blank mr-4"
        type="submit"
        @click.prevent="reportConflictOfInterests"
      >
        {{ $t('damageCheck.conflictOfInterest') }}
      </button>
      <dx-button
        :text="$t('damageCheck.detailsRequest')"
        class="d-md-inline-block"
        @click="showMoreDetails"
      />
    </div>

    <div v-if="moreDetails">
      <div class="row">
        <div class="col-12 col-md mb-4">
          <div class="content-block h-100">
            <h4>{{ $t('damageCheck.deadline') }}</h4>
            <p>
              {{
                claim.deadline
                  ? getFormattedDate(claim.deadline)
                  : $t('noContent')
              }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md mb-4">
          <div class="content-block h-100">
            <h4>{{ $t('damageCheck.instance') }}</h4>
            <p>
              {{
                claim.targetInstance
                  ? $t(`lawyerPortal.targetInstance.${claim.targetInstance}`)
                  : $t('noContent')
              }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md mb-4">
          <div class="content-block h-100">
            <h4>{{ $t('damageCheck.rate') }}</h4>
            <p>CHF {{ claim.hourlyRate.value }} / Std.</p>
            <h4>{{ $t('damageCheck.rateLimit') }}</h4>
            <p class="mb-0">CHF {{ claim.paymentLimit.value }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md col-12 mb-4">
          <div class="content-block h-100">
            <h4>{{ $t('damageCheck.lawClassification') }}</h4>
            <p>{{ $t(`areaOfLaw.${claim.areaOfLaw}`) }}</p>
            <h4>{{ $t('damageCheck.lawClassificationSub') }}</h4>
            <p class="mb-0">
              {{ $t(`subareaOfLaw.${claim.subareaOfLaw}`) }}
            </p>
          </div>
        </div>
        <div
          v-if="claim.documents && claim.documents.length > 0"
          class="col-md col-12 mb-4"
        >
          <div class="content-block h-100">
            <dx-download-list
              :items="claim.documents"
              :headline="$t('documents')"
              tag-type="h4"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col mb-4">
          <div class="content-block">
            <h4>{{ $t('damageCheck.caseDescription') }}</h4>
            <p>{{ claim.facts || $t('noContent') }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mb-4">
          <div class="content-block">
            <h4>{{ $t('damageCheck.aims') }}</h4>
            <p>{{ claim.introduction || $t('noContent') }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mb-4">
          <div class="content-block">
            <h4>{{ $t('damageCheck.comments') }}</h4>
            <p>{{ claim.externalLawyerNote || $t('noContent') }}</p>
          </div>
        </div>
      </div>

      <div class="text-center text-md-right mb-4">
        <button
          class="secondary-link btn-blank mr-4"
          type="submit"
          @click="goToRejectionPage"
        >
          {{ $t('damageCheck.caseReject') }}
        </button>
        <dx-button
          :text="$t('damageCheck.caseAccept')"
          class="d-md-inline-block"
          type="submit"
          @click="acceptInvitation"
        />
      </div>
    </div>
  </div>
</template>

<script>
import isPast from 'date-fns/isPast'

import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxDownloadList from '~/components/DX-DownloadList'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxPartyCounterparty from '~/domains/contract/claim/components/DX-Claim/Dx-PartyCounterParty'

import openClaimsQuery from '~/domains/externalLawyer/__gql__/queries/openClaims.gql'
import invitationExternalLawyerForTokenQuery from '~/domains/externalLawyer/__gql__/queries/invitationExternalLawyerForToken.gql'
import rejectInvitationMutation from '~/domains/externalLawyer/__gql__/mutations/rejectInvitationExternalLawyer.gql'
import acceptInvitationMutation from '~/domains/externalLawyer/__gql__/mutations/acceptInvitationExternalLawyer.gql'
import { INVITATION_REJECTION_REASON } from '~/domains/externalLawyer/constants'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import dateMixin from '~/javascripts/mixins/dateMixin'
import personMixin from '~/javascripts/mixins/personMixin'

export default {
  name: 'DxDamageRequest',
  components: {
    DxButton,
    DxDownloadList,
    DxLoadingOverlay,
    DxPartyCounterparty,
  },
  mixins: [loadingMixin, dateMixin, personMixin],
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      invitationExternalLawyerForToken: {},
      loading: true,
      notFound: false,
      moreDetails: false,
      claim: {
        insuredPerson: {
          address: {},
        },
        policyholder: {
          address: {},
        },
        counterparty: {
          address: {},
        },
        documents: [],
        hourlyRate: {},
      },
    }
  },
  computed: {
    isExpired() {
      if (!this.claim.expiredAt) {
        return false
      }

      if (this.claim.status === 'EXPIRED') {
        return true
      }

      return isPast(this.claim.expiredAt)
    },
  },
  methods: {
    vnEqualsVp() {
      return this.claim.insuredPerson.id === this.claim.policyholder.id
    },
    async reportConflictOfInterests() {
      const variables = {
        invitationId: Number(this.invitationExternalLawyerForToken.id),
        reason: INVITATION_REJECTION_REASON[0],
        comment: '',
      }
      try {
        this.setGlobalLoading(true)
        await this.$apollo.mutate({
          mutation: rejectInvitationMutation,
          variables,
        })
        this.$notification.success({
          messageKey: 'lawyerPortal.rejectInvitation.success',
        })
        this.$router.push({ path: this.localePath('LawyerPortal') })
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.rejectInvitation.error',
        })
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async acceptInvitation() {
      try {
        this.setGlobalLoading(true)
        await this.$apollo.mutate({
          mutation: acceptInvitationMutation,
          variables: {
            invitationId: Number(this.invitationExternalLawyerForToken.id),
          },
          refetchQueries: [
            {
              query: openClaimsQuery,
            },
          ],
        })
        this.$router.push({ path: this.localePath('LawyerPortal') })
        this.$notification.success({
          messageKey: 'lawyerPortal.acceptInvitation.success',
        })
      } catch (error) {
        console.error(error)
        this.$notification.error({
          messageKey: 'lawyerPortal.acceptInvitation.error',
        })
      } finally {
        this.setGlobalLoading(false)
      }
    },
    showMoreDetails() {
      this.moreDetails = !this.moreDetails
    },
    goToRejectionPage() {
      const params = {
        invitationId: this.invitationExternalLawyerForToken.id,
        claim: this.claim,
      }
      this.$router.push({
        name: `LawyerPortal-RejectDamage___${this.$i18n.locale}`,
        params,
      })
    },
  },
  apollo: {
    invitationExternalLawyerForToken: {
      query: invitationExternalLawyerForTokenQuery,
      variables() {
        return {
          token: this.token,
          withClaim: true,
        }
      },
      result({ data }) {
        if (!data.invitationExternalLawyerForToken) {
          return
        }
        this.claim = {
          ...data.invitationExternalLawyerForToken.claim,
        }
        this.error = true
        switch (data.invitationExternalLawyerForToken.status) {
          case 'EXPIRED':
            this.$notification.error({
              messageKey: 'loginPage.invitationError.lawyer.expired',
            })
            break
          case 'ACCEPTED':
            this.$notification.error({
              messageKey: 'loginPage.invitationError.lawyer.accepted',
            })
            break
          case 'CANCELED':
            this.$notification.error({
              messageKey: 'loginPage.invitationError.lawyer.canceled',
            })
            break
          case 'REJECTED':
            this.$notification.error({
              messageKey: 'loginPage.invitationError.lawyer.rejected',
            })
            break
          default:
            this.error = false
            break
        }
        this.loading = false
      },
      error(_error) {
        this.loading = false
        this.error = true
        this.$notification.error({
          messageKey: 'loginPage.invitationError.lawyer.invalid',
        })
      },
    },
  },
}
</script>
