<template>
  <div>
    <dx-table
      :header-columns="headerColumnsAppeals"
      :data-rows="formattedRequests"
      class="mb-md-3"
    />
    <div v-if="addAppeal">
      <div class="content-bg-color mb-3 p-3 p-md-5">
        <h4 class="mb-4">
          {{ $t('damage.appealSetup') }}
        </h4>
        <div class="row pt-1">
          <div class="col">
            <dx-input
              :label="$t('damage.appealFrom')"
              :value="
                $t(`lawyerPortal.targetInstance.${currentTargetInstance}`)
              "
              :disabled="true"
            />
          </div>
          <div class="col">
            <dx-select
              v-model="selectedNextTargetInstance"
              v-validate.disable="'required'"
              :error="errors.first('requiredSelection')"
              :label="$t('damage.appealTo')"
              :options="getNextTargetInstanceOptions()"
              data-vv-name="requiredSelection"
            />
          </div>
        </div>
        <div class="row pt-3">
          <div class="col">
            <dx-textarea
              v-model="nextTargetInstanceComment"
              v-validate.disable="'required'"
              :error="errors.first('missingMessageBody')"
              :active-autofocus="true"
              :label="$t('remarks')"
              data-vv-name="missingMessageBody"
            />
          </div>
        </div>
        <div class="row pt-3">
          <div class="col">
            <dx-upload
              ref="upload"
              :label="$t('docUploadHead')"
              :on-upload-success="(file) => onUploadSuccess(file)"
              :remove-spacing="true"
              :remove-cancel-button="true"
              :show-upload-button="false"
            />
          </div>
        </div>
      </div>
      <div class="row py-3 pb-md-0 pt-md-1">
        <div class="col text-center text-md-right pt-2">
          <button
            class="btn-blank d-inline-block secondary-link mb-3 mr-md-4 mb-md-0"
            @click="openAppeal"
          >
            {{ $t('abort') }}
          </button>
          <dx-button
            :text="$t('damage.appealSave')"
            :small="true"
            class="d-md-inline-block"
            type="submit"
            @click="saveNewAppeal"
          />
        </div>
      </div>
    </div>
    <button
      v-if="!addAppeal && !openRequestPresent && !readOnly"
      type="button"
      class="show-more-link plus-icon btn-blank mb-4"
      @click="openAppeal"
    >
      {{ $t('damage.appealsAction') }}
    </button>
  </div>
</template>

<script>
import {
  DxButton,
  DxSelect,
  DxTextarea,
  DxInput,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxTable from '~/components/DX-Table'
import DxUpload from '~/components/DX-Upload'
import dateMixin from '~/javascripts/mixins/dateMixin'

import { TARGET_INSTANCE } from '~/domains/externalLawyer/constants'

export default {
  name: 'DxLawyerClaimAppeals',
  components: {
    DxInput,
    DxButton,
    DxSelect,
    DxTextarea,
    DxTable,
    DxUpload,
  },
  mixins: [dateMixin],
  props: {
    targetInstanceRequests: {
      type: Array,
      default: () => [],
    },
    currentTargetInstance: {
      type: String,
      default: null,
    },
    createTargetInstanceRequest: {
      type: Function,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addAppeal: false,
      headerColumnsAppeals: [
        this.$t('damage.reportTime'),
        this.$t('damage.appealFrom'),
        this.$t('damage.appealTo'),
        this.$t('damage.status.label'),
        this.$t('date'),
      ],
      selectedNextTargetInstance: null,
      nextTargetInstanceComment: null,
      attachments: [],
    }
  },
  computed: {
    openRequestPresent() {
      return this.targetInstanceRequests.some((ir) => ir.status === 'OPEN')
    },
    formattedRequests() {
      return this.targetInstanceRequests.map((instance) => [
        this.getFormattedDate(instance.createdAt, true),
        this.$t(
          `lawyerPortal.targetInstance.${instance.previousTargetInstance}`,
        ),
        this.$t(`lawyerPortal.targetInstance.${instance.nextTargetInstance}`),
        this.$t(`lawyerPortal.targetInstanceStatus.${instance.status}`),
        this.getFormattedDate(instance.resolvedAt, true),
      ])
    },
  },
  methods: {
    openAppeal() {
      this.addAppeal = !this.addAppeal
    },
    getNextTargetInstanceOptions() {
      const currentIndex = TARGET_INSTANCE.indexOf(this.currentTargetInstance)
      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
      }
      const formatedOptions = TARGET_INSTANCE.map((instance, index) => {
        if (currentIndex < index) {
          return {
            value: instance,
            label: this.$t(`lawyerPortal.targetInstance.${instance}`),
          }
        }
        return null
      }).filter((ti) => ti)
      return [defaultOption, ...formatedOptions]
    },
    async saveNewAppeal() {
      const valid = await this.$validator.validateAll()
      if (valid) {
        const uploaded = await this.$refs.upload.uploadFiles()
        if (uploaded) {
          const nextInstance = {
            nextTargetInstance: this.selectedNextTargetInstance,
            comment: this.nextTargetInstanceComment,
            uploadIds: [...this.attachments],
          }
          const result = await this.createTargetInstanceRequest(nextInstance)
          if (result) {
            this.selectedNextTargetInstance = null
            this.comment = null
            this.openAppeal()
          }
        }
      }
    },
    onUploadSuccess({ id }) {
      this.attachments = [id, ...this.attachments]
    },
  },
}
</script>
