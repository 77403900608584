<template>
  <div>
    <h5 class="mb-3">
      {{ $t('damageItems') }}
    </h5>
    <p v-if="isBroker" class="my-4" data-testid="subscriptionCloseClaimText">
      {{ subscriptionCloseClaimText }}
      <nuxt-link
        :to="localePath('PartnerPortal-UserData')"
        class="secondary-link ml-1"
      >
        {{ $t('partnerPortal.damageManagement.edit') }}
      </nuxt-link>
    </p>
    <div v-if="claims.length > 0" data-testid="claimsList">
      <dx-claim-excerpt
        v-for="(claim, index) in claims"
        :key="claim + index"
        :claim="claim"
        :is-broker="isBroker"
      />
    </div>
    <div v-else>
      <div class="damage-item mb-3 px-4 py-3 position-relative text-center">
        <p class="mt-3" data-testid="noDamageItems">
          {{ $t('noDamageItems') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DxClaimExcerpt from './DX-ClaimExcerpt'

export default {
  name: 'DxClaimExcerptList',
  components: {
    DxClaimExcerpt,
  },
  props: {
    claims: {
      type: Array,
      default() {
        return []
      },
    },
    isBroker: {
      type: Boolean,
      default: false,
    },
    subscribedCloseClaim: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    subscriptionCloseClaimText() {
      return this.subscribedCloseClaim
        ? this.$t('partnerPortal.damageManagement.subscribedCloseClaim')
        : this.$t('partnerPortal.damageManagement.notSubscribedCloseClaim')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
