import Cookies from 'js-cookie'

export function handleScipSalesPreview($route) {
  const productKey = $route.query.applicationFormProduct
  if (productKey) {
    const version = parseInt($route.query.applicationFormVersion, 10)
    if (productKey && !Number.isNaN(version) && version > 0) {
      Cookies.set(
        'applicationFormPreview',
        JSON.stringify({ productKey, version }),
      )
    } else {
      Cookies.set('applicationFormPreview', '', -1)
    }
  }
}
