<template>
  <div class="d-print-none">
    <dx-desktop-navigation
      :nav-items="navItems"
      :account-subnav-data="accountSubnavData"
      :is-sticky="isSticky"
      class="d-none d-lg-block"
    />

    <dx-mobile-navigation
      :nav-items="navItems"
      :account-subnav-data="accountSubnavData"
      :is-sticky="isSticky"
      class="d-lg-none"
    >
      <template slot="MobileNavTop">
        <slot name="MobileNavTop" />
      </template>

      <template slot="MobileNavBottom">
        <slot name="MobileNavBottom" />
      </template>
    </dx-mobile-navigation>
  </div>
</template>

<script>
import DxDesktopNavigation from './DX-Desktop-Navigation'
import DxMobileNavigation from './DX-Mobile-Navigation'

export default {
  name: 'DxNavigation',
  components: {
    DxDesktopNavigation,
    DxMobileNavigation,
  },
  props: {
    navItems: {
      type: Array,
      default() {
        return []
      },
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
