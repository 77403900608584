<template>
  <div>
    <section>
      <notifications ref="notifications" />
      <div class="scip-sales">
        <nuxt-link
          v-if="channel !== 'employee'"
          class="backlink"
          :to="localePath('/partnerportal')"
        >
          <chevron-left-icon :width="20" :height="20" />
          <span>{{ $t('toDashboard') }}</span>
        </nuxt-link>

        <application-form
          :current-step-id="state.stepId"
          :item-components="itemComponents"
          :product="product"
          :policy-id="dexId.policyId ? JSON.stringify(dexId) : null"
          :quote-id="dexId.contractId ? JSON.stringify(dexId) : null"
          :amendment-id="
            dexId.policyId && dexId.type === 'edit'
              ? JSON.stringify({ ...dexId, amendmentId: dexId.policyId })
              : null
          "
          :language="$i18n.locale"
          :channel="channel"
          :form-key="formKey"
          @ready="onFormReady"
          @goToStep="goToStep"
          @error="onError"
          @saved="onSaved"
          @submit="onSubmit"
        >
          <template #loadingIndicator>
            <div class="loadingOverlay">
              <loading-spinner />
            </div>
          </template>

          <template #header="{ formContext, steps, current }">
            <form-header
              :form-context="formContext"
              :steps="steps"
              :current="current"
            />
          </template>

          <template
            #formNavigation="{
              formContext,
              isFirstStep,
              isLastStep,
              isPriceLoading,
              onPrevious,
              onSave,
            }"
          >
            <form-navigation
              :form-context="formContext"
              :channel="channel"
              :is-first-step="isFirstStep"
              :is-last-step="isLastStep"
              :is-price-loading="isPriceLoading"
              :dex-id="dexId"
              @previous="onPrevious"
              @save="onSave"
            />
          </template>

          <template #tariff="formContext">
            <form-tariff-box
              ref="tariffBox"
              :form-context="formContext"
              :tariff="formContext.tariff"
              :payment-interval="formContext.data['misc.payment_interval']"
            />
          </template>
        </application-form>
      </div>
    </section>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {
  getCurrentInstance,
  defineComponent,
  reactive,
  computed,
  ref,
  toRefs,
  provide,
} from '@vue/composition-api'
import {
  ApplicationForm,
  provideApplicationFormContext,
} from '@scip-sales/application-form'

import {
  LoadingSpinner,
  Notifications,
} from '@sumcumo/dextra-frontend-component-lib'

import DxContentItemAdapter from '@/components/DX-ScipSales/adapters/DX-ContentItemAdapter'
import DxDownloadAdapter from '@/components/DX-ScipSales/adapters/DX-DownloadAdapter'
import DxTextAdapter from '@/components/DX-ScipSales/adapters/DX-TextAdapter'
import DxTextAreaAdapter from '@/components/DX-ScipSales/adapters/DX-TextAreaAdapter'
import DxNumberAdapter from '@/components/DX-ScipSales/adapters/DX-NumberAdapter'
import DxMoneyAdapter from '@/components/DX-ScipSales/adapters/DX-MoneyAdapter'
import DXDateInputAdapter from '@/components/DX-ScipSales/adapters/DX-DateInputAdapter'
import DxBooleanAdapter from '@/components/DX-ScipSales/adapters/DX-BooleanAdapter'
import DxSelectAdapter from '@/components/DX-ScipSales/adapters/DX-SelectAdapter'
import DxRadioGroupAdapter from '@/components/DX-ScipSales/adapters/DX-RadioGroupAdapter'
import DxGroupAdapter from '@/components/DX-ScipSales/adapters/DX-GroupAdapter'
import DxZipCityAdapter from '@/components/DX-ScipSales/adapters/DX-ZipCityAdapter'
import DxLocationsFieldAdapter from '@/components/DX-ScipSales/adapters/DX-LocationsFieldAdapter'
import DxUploadAdapter from '@/components/DX-ScipSales/adapters/DX-UploadAdapter'
import DxModuleSelectionAdapter from '@/components/DX-ScipSales/adapters/DX-ModuleSelectionAdapter'
import DxGlobalErrorMessageAdapter from '@/components/DX-ScipSales/adapters/DX-GlobalErrorMessageAdapter'
import DxSummaryAdapter from '@/components/DX-ScipSales/adapters/DX-SummaryAdapter'

import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon'

import productTranslationsQueryGql from '@/domains/application/__gql__/queries/productTranslations.gql'

import scrollFirstErrorIntoView from '@/javascripts/helper/scollIntoView'

import FormHeader from './Header'
import FormNavigation from './Navigation'
import FormTariffBox from './TariffBox'

import { useScipTranslationWithFallback } from '../utils'

// eslint-disable-next-line vue/require-direct-export
export default defineComponent({
  name: 'DxScipSalesForm',
  components: {
    Notifications,
    LoadingSpinner,
    ApplicationForm,
    ChevronLeftIcon,

    FormHeader,
    FormNavigation,
    FormTariffBox,
  },
  props: {
    productKey: {
      type: String,
      required: true,
    },
    variantKey: {
      type: String,
      required: true,
    },
    formKey: {
      type: String,
      default: undefined,
    },
    dexId: {
      type: Object,
      default: () => ({}),
    },
    authToken: {
      type: String,
      default: null,
    },
    channel: {
      type: String,
      default: 'broker',
    },
  },

  // eslint-disable-next-line max-statements, max-lines-per-function
  setup(props) {
    const vue = getCurrentInstance()
    const { $nextTick, $route, $i18n, $apolloProvider } = vue
    const { t, te } = useScipTranslationWithFallback(toRefs(props))

    const uri =
      process.client && window.localStorage.getItem('@scip-sales/url')
        ? window.localStorage.getItem('@scip-sales/url')
        : process.env.DEXTRA_SCIPSALES_URI
    provideApplicationFormContext({
      uri,
      getAuthToken,
    })

    const state = reactive({
      stepId: undefined,
      ready: false,
      versionFromScipSales: null,
    })

    const version = computed(() => {
      let previewProduct
      const productVersion = Number($route.query.versionNumber) || null

      try {
        previewProduct = JSON.parse(Cookies.get('applicationFormPreview'))
      } catch (error) {
        // No preview mode
      }
      return previewProduct && previewProduct.productKey === props.productKey
        ? previewProduct.version
        : productVersion
    })

    const product = computed(() => ({
      productKey: props.productKey,
      variantKey: props.variantKey,
      versionNumber: version.value,
    }))

    provide('applicationFormProduct', {
      productKey: product.value.productKey,
      variantKey: product.value.variantKey,
      versionNumber: product.value.versionNumber,
    })

    function getAuthToken() {
      if (props.authToken) {
        return props.authToken
      }

      try {
        const { authentication } = JSON.parse(localStorage.getItem('dextra'))
        return authentication.token.jwt
      } catch {
        return null
      }
    }

    const getNextStepId = (steps, current) => {
      const currentIndex = steps.findIndex((step) => step.stepId === current)
      if (steps[currentIndex + 1]) {
        return steps[currentIndex + 1].stepId
      }

      return null
    }

    async function fetchLocalesForProduct() {
      const { data } = await $apolloProvider.clients.cms.query({
        query: productTranslationsQueryGql,
        variables: {
          productKey: product.value.productKey,
          productVariantKey: product.value.variantKey,
          productVersionNumber: String(
            product.value.versionNumber || state.versionFromScipSales,
          ),
          locales: [$i18n.locale],
        },
        fetchPolicy: 'no-cache',
      })

      if (data?.productTranslations?.[0]) {
        const { translations } = data.productTranslations[0]
        $i18n.mergeLocaleMessage($i18n.locale, translations)
      }
    }

    const notifications = ref(null)

    const onFormReady = async ({ versionNumber }) => {
      state.versionFromScipSales = versionNumber
      await fetchLocalesForProduct()
      state.ready = true
    }

    const goToStep = ({ stepId: newId }) => {
      state.stepId = newId
    }

    const onError = (errors) => {
      $nextTick(() => {
        scrollFirstErrorIntoView()
      })

      /**
       * available props on error
       * source: 'submit' | 'tariff' | 'save'
       * message: string | null
       * attributeKey?: string
       * moduleKey?: string | null
       */

      // While GQL has a `required` code sent, the middleware drops this.
      // so I can just check whether there are validation errors on save.
      // I'll just assume these are missing required fields for now :shrug:
      const hasSaveErrors = errors.some(
        (error) =>
          error.message === 'ValidationError' && error.source === 'save',
      )
      // there'll be a scip feature soon to support JC translations outside the formcontext but till the release this should do:
      notifications.value.addNotification({
        type: 'error',
        html: t(
          // eslint-disable-next-line no-nested-ternary
          hasSaveErrors
            ? $i18n.locale === 'de'
              ? 'Bitte füllen sie alle notwendigen Felder aus.'
              : 'Veuillez remplir tous les champs obligatoires.'
            : $i18n.locale === 'de'
            ? 'Ups, da ist etwas schief gelaufen'
            : 'Oups, quelque chose a mal tourné.',
        ),
        timer: 6000,
      })
    }

    function onSubmit(id) {
      if (props.channel === 'employee') {
        window.parent.postMessage(
          // id is an object not a literal value
          JSON.stringify({ contractid: id, errors: 0 }),
          '*',
        )
      } else {
        this.$router.push(this.localePath('PartnerPortal-ClosedPolicies'))
      }
    }

    function onSaved(id) {
      if (props.channel === 'employee') {
        window.parent.postMessage(
          // id is an object not a literal value
          JSON.stringify({ contractid: id, errors: 0 }),
          '*',
        )
      } else {
        this.$router.push(this.localePath('PartnerPortal-OpenOffers'))
      }
    }

    return {
      t,
      te,
      itemComponents: {
        ContentItem: DxContentItemAdapter,
        DownloadItem: DxDownloadAdapter,
        TextField: DxTextAdapter,
        TextAreaField: DxTextAreaAdapter,
        NumberField: DxNumberAdapter,
        MoneyField: DxMoneyAdapter,
        DateField: DXDateInputAdapter,
        BooleanField: DxBooleanAdapter,
        SelectField: DxSelectAdapter,
        RadioGroupField: DxRadioGroupAdapter,
        GroupField: DxGroupAdapter,
        ZipCityField: DxZipCityAdapter,
        UploadField: DxUploadAdapter,
        LocationsField: DxLocationsFieldAdapter,
        ModuleSelectionField: DxModuleSelectionAdapter,
        GlobalErrorMessage: DxGlobalErrorMessageAdapter,
        SummaryItem: DxSummaryAdapter,
      },
      state,
      product,
      notifications,
      fetchLocalesForProduct,
      getNextStepId,
      getAuthToken,
      goToStep,
      onFormReady,
      onError,
      onSaved,
      onSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

$font-family-old: 'Roboto', sans-serif;
$font-family-headlines: $font-family-old;
$font-family-heavy: $font-family-old;
$font-family: $font-family-old;

// part of the form header should be outside of the
// boxshadow-ed container. So we translate that part
// per transform upwards and drag the rest of the
// form upwards per negative margin.
// Fancy (okay, ugly…) shit, right?
// Similar crimes happen at the end of the form.
$magicShiftMobile: rem(60);
$magicShift: rem(80);
$magicShiftBottom: rem(100);

.scip-sales {
  position: relative;
  padding-top: rem(40) + $magicShiftMobile;
  padding-bottom: rem(120);
  width: 100vw;
  min-height: 85vh;
  background: $color-ghost-white;

  @include media-breakpoint-up(lg) {
    padding-top: rem(40) + $magicShift;
    padding-bottom: rem(80) + $magicShiftBottom;
  }

  ::v-deep .loadingOverlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: rgba(#fff, 0.5);
    z-index: 1337;
  }

  .backlink {
    display: flex;
    margin: 0 auto;
    padding: 0 rem(15);
    width: 90vw;
    align-items: center;
    transform: translateY(#{$magicShiftMobile * -1.15});

    @include media-breakpoint-up(lg) {
      width: clamp(#{rem(692)}, 90vw, #{rem(860)});
      transform: translateY(#{$magicShift * -1.15});
    }

    @include media-breakpoint-up(xl) {
      width: clamp(#{rem(440)}, 90vw, #{rem(568)});
      transform: translateY(#{$magicShift * -1.15});
    }
  }
}

.scip-sales > div {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 90vw;

  @include media-breakpoint-up(lg) {
    display: grid;
    padding: 0;
    max-width: none;
    grid-column-gap: rem(32);
    grid-template-columns:
      1fr minmax(rem(440), rem(568))
      minmax(rem(220), rem(290)) 1fr;
    align-items: start;
  }
  @include media-breakpoint-up(xl) {
    display: grid;
    margin: 0;
    padding: 0;
    grid-column-gap: rem(32);
    grid-template-columns:
      1fr minmax(rem(220), rem(280)) minmax(rem(440), rem(568))
      minmax(rem(220), rem(310)) 1fr;
    align-items: start;
  }
}
.scip-sales ::v-deep .tariffWrapper {
  grid-column: 3;

  @include media-breakpoint-up(xl) {
    grid-column: 4;
  }

  .totalPrice {
    display: grid;
    align-items: baseline;
  }
}

.scip-sales ::v-deep form {
  grid-column: 2;

  @include media-breakpoint-up(xl) {
    grid-column: 3;
  }

  > div {
    display: grid;
    max-width: 100%;
    grid-gap: rem(20);
    grid-template-columns: 1fr;
    background: #fff;
    word-break: break-word;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(48) * -1;
    }

    > div,
    > label,
    > dl {
      padding-right: rem(20);
      padding-left: rem(20);

      @include media-breakpoint-up(lg) {
        padding-right: rem(64);
        padding-left: rem(64);
      }
    }

    > :nth-last-child(2) {
      @include media-breakpoint-up(lg) {
        margin-bottom: $magicShiftBottom * -1;
      }
    }
    > div:last-of-type {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: rem(12) rem(20);
      width: 100%;
      background: #fff;
      z-index: 10;

      @include media-breakpoint-up(lg) {
        position: static;
        background: transparent;
        box-shadow: none;
        transform: translateY($magicShiftBottom);
      }
    }

    .rich-text {
      h2 {
        font-family: 'Inter Heavy', sans-serif;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      h3 {
        font-family: 'Inter Heavy', sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}

.scip-sales .loadingSpinner {
  margin: 0 auto;

  // stylelint-disable-next-line
  > div {
    grid-column: 3;
  }
}
</style>
