<template>
  <div>
    <!-- READ ONLY -->
    <template v-if="!editable">
      <div class="row">
        <div class="col-12 col-md-10 mb-4 row">
          <p class="col-12 mb-2">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.questions.q1'
                  : 'offer.questions.q1',
              )
            }}
          </p>
          <p class="col-12 font-weight-bold">
            {{ displayBooleanValue(formData.recentExistingOrExpectedLawsuit) }}
            <span v-if="formData.recentExistingOrExpectedLawsuitAdditional">
              {{ `- ${formData.recentExistingOrExpectedLawsuitAdditional}` }}
            </span>
          </p>
        </div>
        <div class="col-12 col-md-10 mb-4 row">
          <p class="col-12 mb-2">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.questions.q2'
                  : 'offer.questions.q2',
              )
            }}
          </p>
          <p class="col-12 font-weight-bold">
            {{ displayBooleanValue(formData.impairedAbilityToWork) }}
            <span v-if="formData.impairedAbilityToWorkAdditional">
              {{ `- ${formData.impairedAbilityToWorkAdditional}` }}
            </span>
          </p>
        </div>
        <div class="col-12 col-md-10 mb-4 row">
          <p class="col-12 mb-2">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.questions.q3'
                  : 'offer.questions.q3',
              )
            }}
          </p>
          <p class="col-12 font-weight-bold">
            {{
              displayBooleanValue(formData.contractTerminatedByInsuranceCompany)
            }}
            <span
              v-if="formData.contractTerminatedByInsuranceCompanyAdditional"
            >
              {{
                `- ${formData.contractTerminatedByInsuranceCompanyAdditional}`
              }}
            </span>
          </p>
        </div>
        <div class="col-12 col-md-10 row">
          <p class="col-12 mb-2">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.questions.individualRequests'
                  : 'offer.questions.individualRequests',
              )
            }}
          </p>
          <p class="col-12 font-weight-bold">
            {{ formData.individualAdditionalText || '-' }}
          </p>
        </div>
      </div>
    </template>

    <!-- EDIT MODE -->
    <form v-else @change="change">
      <dx-radio-list
        v-model="formData.recentExistingOrExpectedLawsuit"
        v-validate.disable="{
          required: isRequired,
        }"
        :options="radioOptions"
        :error="errors.first('recentExistingOrExpectedLawsuit')"
        :label="
          $t(isBrokerOffer ? 'brokerOffer.questions.q1' : 'offer.questions.q1')
        "
        name="recentExistingOrExpectedLawsuit"
        class="mt-5 mb-3"
      />

      <dx-textarea
        v-if="formData.recentExistingOrExpectedLawsuit"
        v-model="formData.recentExistingOrExpectedLawsuitAdditional"
        v-validate.disable="{
          required: formData.recentExistingOrExpectedLawsuit,
        }"
        :error="errors.first('recentExistingOrExpectedLawsuitAdditional')"
        :label="$t('offer.questions.q1Additional')"
        class="mt-2"
        data-vv-name="recentExistingOrExpectedLawsuitAdditional"
      />

      <dx-radio-list
        v-model="formData.impairedAbilityToWork"
        v-validate.disable="{
          required: isRequired,
        }"
        :options="radioOptions"
        :error="errors.first('impairedAbilityToWork')"
        :label="
          $t(isBrokerOffer ? 'brokerOffer.questions.q2' : 'offer.questions.q2')
        "
        name="impairedAbilityToWork"
        class="mt-5 mb-3"
      />

      <dx-textarea
        v-if="formData.impairedAbilityToWork"
        v-model="formData.impairedAbilityToWorkAdditional"
        v-validate.disable="{
          required: formData.impairedAbilityToWork,
        }"
        :error="errors.first('impairedAbilityToWorkAdditional')"
        :label="$t('offer.questions.q2Additional')"
        class="mt-2"
        data-vv-name="impairedAbilityToWorkAdditional"
      />

      <dx-radio-list
        v-model="formData.contractTerminatedByInsuranceCompany"
        v-validate.disable="{
          required: isRequired,
        }"
        :options="radioOptions"
        :error="errors.first('contractTerminatedByInsuranceCompany')"
        :label="
          $t(isBrokerOffer ? 'brokerOffer.questions.q3' : 'offer.questions.q3')
        "
        name="contractTerminatedByInsuranceCompany"
        class="mt-5 mb-3"
      />

      <dx-textarea
        v-if="formData.contractTerminatedByInsuranceCompany"
        v-model="formData.contractTerminatedByInsuranceCompanyAdditional"
        v-validate.disable="{
          required: formData.contractTerminatedByInsuranceCompany,
        }"
        :error="errors.first('contractTerminatedByInsuranceCompanyAdditional')"
        :label="$t('offer.questions.q3Additional')"
        class="mt-2"
        data-vv-name="contractTerminatedByInsuranceCompanyAdditional"
      />

      <p class="mt-5 mb-2">
        {{
          $t(
            isBrokerOffer
              ? 'brokerOffer.questions.individualRequests'
              : 'offer.questions.individualRequests',
          )
        }}
      </p>

      <button
        class="btn-blank plus-icon text-uppercase show-more-link"
        @click.stop.prevent="toggleTextarea"
      >
        {{ $t('offer.questions.individualRequestsBtn') }}
      </button>

      <dx-textarea
        v-if="showTextarea"
        v-model="formData.individualAdditionalText"
        class="mt-2"
      />
    </form>
  </div>
</template>

<script>
import {
  DxTextarea,
  DxRadioList,
} from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'OfferQuestionsForm',
  components: {
    DxRadioList,
    DxTextarea,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    isBrokerOffer: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formData: {
        ...JSON.parse(
          JSON.stringify(
            this.$store.state[this.isBrokerOffer ? 'brokerOffer' : 'offer']
              .assessmentQuestions,
          ),
        ),
      },
      radioOptions: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
      showTextarea:
        !!this.$store.state[this.isBrokerOffer ? 'brokerOffer' : 'offer']
          .assessmentQuestions.individualAdditionalText,
    }
  },
  computed: {
    storedOffer() {
      return this.isBrokerOffer
        ? this.$store.state.brokerOffer
        : this.$store.state.offer
    },
  },
  watch: {
    'formData.recentExistingOrExpectedLawsuit': function resetAdditional(val) {
      if (!val) {
        this.formData.recentExistingOrExpectedLawsuitAdditional = ''
      }
    },
    'formData.impairedAbilityToWork': function resetAdditional(val) {
      if (!val) {
        this.formData.impairedAbilityToWorkAdditional = ''
      }
    },
    'formData.contractTerminatedByInsuranceCompany': function resetAdditional(
      val,
    ) {
      if (!val) {
        this.formData.contractTerminatedByInsuranceCompanyAdditional = ''
      }
    },
  },
  methods: {
    toggleTextarea() {
      this.showTextarea = !this.showTextarea
    },
    async saveForm(noValidation) {
      const mutation = this.isBrokerOffer
        ? 'SET_BROKER_ASSESSMENT_QUESTIONS'
        : 'SET_ASSESSMENT_QUESTIONS'
      if (noValidation) {
        this.$store.commit(mutation, this.formData)
        return true
      }
      const result = await this.$validator.validateAll()
      if (result) {
        this.$store.commit(mutation, this.formData)
        return true
      }
      return false
    },
    resetForm() {
      this.formData = {
        ...JSON.parse(JSON.stringify(this.storedOffer.assessmentQuestions)),
      }
    },
    change() {
      this.$emit('change')
    },
    displayBooleanValue(value) {
      if (value) {
        return this.$t('yes')
      }
      if (value === false) {
        return this.$t('no')
      }
      return '-'
    },
  },
}
</script>
