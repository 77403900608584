<template>
  <div class="selection">
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>
    <div v-if="item.asRadios">
      <dx-radio-buttons
        :id="`scip-${item.id}`"
        :label="translatedLabel"
        :options="translatedOptions"
        :value="value"
        :error="translatedError"
        @change="updateRadio"
        @blur="onBlur"
      />
    </div>
    <div v-else>
      <dx-radio-check
        :id="`scip-${item.id}`"
        :label="translatedLabel"
        :name="item.id"
        :checked="value"
        :value="value"
        :disabled="item.readonly"
        :error="translatedError"
        type="checkbox"
        @input="updateCheckbox"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import {
  DxRadioButtons,
  DxRadioCheck,
} from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DxBooleanAdapter',
  components: {
    DxDescriptionLabel,
    DxRadioButtons,
    DxRadioCheck,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  computed: {
    translatedOptions() {
      return [
        { value: true, name: this.t('yes') },
        { value: false, name: this.t('no') },
      ]
    },

    translatedError() {
      if (!this.error) {
        return null
      }

      const errorOverrideKey = `${this.translationKeyPrefix}.error`
      if (this.te(errorOverrideKey)) {
        return this.t(errorOverrideKey)
      }

      const errorKey = `errors.boolean.${
        this.value === null ? 'required' : this.error.type
      }`
      const params = { ...this.error.params, label: this.translatedLabel }
      return this.t(errorKey, params)
    },
  },

  methods: {
    updateRadio(newValue) {
      this.$emit('input', newValue)
    },

    updateCheckbox() {
      // Currently, SCIP Sales requires a 'null' for deselected checkboxes
      // Also, do not use the checkbox-emitted value, as it is the old value, not the new one
      this.$emit('input', this.value ? null : true)
    },

    onBlur() {
      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.selection {
  ::v-deep {
    .cmp-checkbox::after {
      border-color: $color-turquoise-surf !important;
    }
    .button {
      display: flex;
      border-color: $color-turquoise-surf;
      justify-content: center;
      align-items: center;
      background: $white-clr;
    }
    .radioInput ~ .text {
      border: 1px solid $color-turquoise-surf;
      border-radius: rem(20);
      padding: rem(12) rem(16);
      min-width: rem(96);
      background: $white-clr;
      color: $color-turquoise-surf;
      line-height: 1;
    }
    .buttonChecked,
    .button:hover,
    .button:active,
    .button:focus {
      .text {
        background: $color-turquoise-surf;
        color: $white-clr;
      }
    }
  }

  ::v-deep .button span.text {
    font-family: $font-family;
  }
}
</style>
