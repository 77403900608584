<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>
    <dx-input
      :id="`scip-${item.id}`"
      :value="value"
      :label="translatedLabel"
      :type="type"
      :disabled="item.readonly"
      :error="translatedError"
      @input="update"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import { DxInput } from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback, isEmpty } from '../../utils'

export default {
  name: 'DXTextAdapter',
  components: {
    DxDescriptionLabel,
    DxInput,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  data() {
    return {
      formatValid: null,
    }
  },

  computed: {
    translatedError() {
      if (!this.error) {
        return null
      }

      let key
      if (this.error.type === 'matches') {
        key = `errors.text.matches.${this.item.contentType}`
      } else if (this.error.type === 'dayMonth') {
        key = `errors.text.dayMonth.${this.formatValid ? 'date' : 'format'}`
      } else if (isEmpty(this.value)) {
        key = `errors.text.required`
      } else {
        key = `errors.text.${this.error.type}`
      }

      return this.t(key, {
        ...this.error.params,
        label: this.translatedLabel,
      })
    },

    type() {
      return this.item.contentType === 'day_month' ? 'daymonth' : 'text'
    },
  },

  methods: {
    update(newValue) {
      this.$emit('input', newValue)
    },

    onBlur() {
      this.formatValid = this.value?.match(/^[0-9]{1,2}\.[0-9]{1,2}\.$/)
      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>
