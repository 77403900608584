<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>
    <dx-input
      :id="`scip-${item.id}`"
      :value="value"
      :label="translatedLabel"
      :type="type"
      :disabled="item.readonly"
      :error="translatedError"
      @input="update"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import { DxInput } from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DXNumberAdapter',
  components: {
    DxDescriptionLabel,
    DxInput,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  computed: {
    translatedError() {
      return this.error
        ? this.t(
            `errors.number.${
              this.value === null ? 'required' : this.error.type
            }`,
            {
              ...this.error.params,
              label: this.translatedLabel,
            },
          )
        : null
    },
  },

  methods: {
    update(newValue) {
      this.$emit('input', Number(newValue))
    },

    onBlur() {
      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>
