import Cookies from 'js-cookie'
import { RESET_STORE_AUTHENTICATION } from '~/domains/authentication/__store__/mutations'
import { RESET_STORE_DAMAGEREPORT } from '~/domains/contract/claim/__store__/mutations'
import { RESET_STORE_APPLICATION } from './mutations'

export const RESET_STORE = 'RESET_STORE'

export default {
  [RESET_STORE]({ commit }) {
    // eslint-disable-line no-unused-vars
    commit(RESET_STORE_AUTHENTICATION)
    commit(RESET_STORE_DAMAGEREPORT)
    commit(RESET_STORE_APPLICATION)
    localStorage.removeItem('dextra')
    Cookies.remove('loginExpires', {
      path: '/',
      domain: process.env.DEXTRA_COOKIE_DOMAIN,
    })
  },
}
