<template>
  <span class="label">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'DxDescriptionLabel',
}
</script>

<style lang="scss" scoped>
.label {
  display: block;
  margin-bottom: rem(8);
  color: $color-paynes-grey;
  font-family: Roboto, sans-serif;
  font-size: rem(16);
  line-height: rem(21);

  &:empty {
    margin: 0;
  }
}
</style>
