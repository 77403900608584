<template><div /></template>
<script>
export default {
  name: 'Index',
  nuxtI18n: {
    paths: {
      de: '/',
      fr: '/',
      it: '/',
    },
  },
}
</script>
