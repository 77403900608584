<template>
  <div class="container-fluid">
    <dx-back-text-button
      :text="$t('backToSelfService')"
      :route="localePath('SelfService')"
      class="mt-3"
    />
    <div v-if="!loading" class="content-wrap-medium content-pad">
      <div class="mb-4">
        <div class="mb-5 text-md-center">
          <h2>
            {{ $t('invoices.headline') }}
          </h2>
          <p class="md-3 mb-md-5" v-html="$t('invoices.subHeadline')" />
        </div>

        <div class="row">
          <div class="col col-12 col-md-6">
            <dx-download-list
              :items="invoices"
              :headline="$t('invoice')"
              tag-type="h4"
              class="mb-5"
            />
          </div>
          <div class="col col-12 col-md-6">
            <dx-download-list
              :items="documents"
              :headline="$t('documents')"
              tag-type="h4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxBackTextButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxDownloadList from '~/components/DX-DownloadList'
import contractsDocumentsQuery from '~/domains/contract/__gql__/queries/contractsDocuments.gql'
import sortDocuments from '~/javascripts/mixins/sortDocuments'

export default {
  name: 'SelfServiceInvoices',
  components: {
    DxDownloadList,
    DxBackTextButton,
  },
  mixins: [sortDocuments],
  nuxtI18n: {
    paths: {
      de: '/kundencenter/rechnungen',
      fr: '/centre-clients/factures',
      it: '/centro-clienti/fatture',
    },
  },
  data() {
    return {
      contractId: Number.parseInt(this.$route.query.contract, 10),
      contracts: [],
      loading: true,
    }
  },
  computed: {
    contract() {
      return (this.contracts || []).find(
        (contract) =>
          parseInt(contract.id, 10) === parseInt(this.contractId, 10),
      )
    },
    documents() {
      return this.getOtherDocuments(this.contract)
    },
    invoices() {
      return this.getInvoices(this.contract)
    },
  },
  apollo: {
    contracts: {
      query: contractsDocumentsQuery,
      variables: {},
      result() {
        this.loading = false
      },
    },
  },
}
</script>
