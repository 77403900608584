<template>
  <svg
    :height="height"
    :width="width"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="m0 0h24v24h-24z" />
      <path
        d="m19.7782104 4.22182684c4.2957613 4.29576127 4.2957613 11.26058906-.0000036 15.55630996-4.2957649 4.2957649-11.26059269 4.2957649-15.55631358 0-4.2957649-4.2957649-4.2957649-11.26059269 0-15.55631358 4.29576489-4.2957649 11.26059268-4.2957649 15.55631718.00000362zm-14.14210724 1.41421356c-3.51471148 3.51467549-3.51471148 9.2131677.00000724 12.7278864 3.51467549 3.5147115 9.2131677 3.5147115 12.7278864-.0000072 3.5147115-3.5146755 3.5147115-9.21316771-.0000036-12.72788282-3.5146791-3.5147151-9.21317131-3.5147151-12.72789004.00000362zm6.36389684 5.3639596c.5522847 0 1 .4477153 1 1v4c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-4c0-.5522847.4477153-1 1-1zm0-4c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1-1-.44771525-1-1 .4477153-1 1-1z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon',

  props: {
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
  },
}
</script>
