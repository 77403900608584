<template>
  <div
    :class="{ 'no-focus-outline': !isTab }"
    @keydown.tab="keydownTab"
    @keyup.esc="keyEscTabbing"
  >
    <dx-navigation is-sticky :account-subnav-data="navData" />
    <dx-notifications />
    <dx-info-bar />
    <div class="main-wrap">
      <nuxt />
    </div>
    <dx-footer />
    <dx-loading-overlay :global-loader="true" :disabled="!loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DxNavigation from '~/components/DX-Navigation'
import DxFooter from '~/components/DX-Footer'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxInfoBar from '~/components/DX-InfoBar'
import { GET_NEW_TOKEN } from '~/domains/authentication/__store__/actions'
import jwtTools from '~/javascripts/helper/jwtTools'
import { keyCodes } from '~/javascripts/utils'
import { handleScipSalesPreview } from '~/javascripts/scipSales'

export default {
  name: 'Default',
  components: {
    DxNavigation,
    DxFooter,
    DxLoadingOverlay,
    DxInfoBar,
  },
  data() {
    return {
      isTab: false,
      keepAliveInterval: null,
      counter: 0,
    }
  },
  head() {
    return {
      title: this.$t('pageTitle'),
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.application.loading,
      termsOfServiceAccepted: (state) =>
        state.application.currentLawyer &&
        state.application.currentLawyer.termsOfServiceAccepted,
    }),
    navData() {
      // LAWYER
      if (this.$store.getters.isExternalLawyer) {
        const accountNavigation = {
          path: this.localePath('LawyerPortal'),
          name: this.$t('lawyerPortal.myLawyerPortal'),
          subNav: [
            {
              path: this.localePath('LawyerPortal'),
              name: this.$t('toMainPage'),
              hash: '',
            },
            {
              path: this.localePath('LawyerPortal-UserData'),
              name: this.$t('lawyerPortal.myUserData'),
              hash: '',
            },
          ],
        }
        if (this.termsOfServiceAccepted) {
          accountNavigation.subNav.push({
            path: this.localePath('LawyerPortal-TermsOfService'),
            name: this.$t('lawyerPortal.termsOfService'),
            hash: '',
          })
        }
        return [accountNavigation]
      }

      // BROKER
      if (this.$store.getters.isBroker) {
        const accountNavigation = {
          path: this.localePath('PartnerPortal'),
          name: this.$t('partnerPortal.myPortal'),
          subNav: [
            {
              path: this.localePath('PartnerPortal'),
              name: this.$t('toMainPage'),
              hash: '',
            },
            {
              path: this.localePath('PartnerPortal-UserData'),
              name: this.$t('userData.userDataHeading'),
              hash: '',
            },
          ],
        }
        return [accountNavigation]
      }

      // SELFSERVICE
      const accountNavigation = {
        path: this.localePath('SelfService'),
        name: this.$t('mySelfService'),
        subNav: [
          {
            path: this.localePath('SelfService'),
            name: this.$t('toMainPage'),
            hash: '',
          },
          {
            path: this.localePath('SelfService-UserData'),
            name: this.$t('userData.userDataHeading'),
            hash: '',
          },
          {
            path: this.localePath('SelfService'),
            name: this.$t('policies.headline'),
            hash: '#policies',
          },
        ],
      }
      return [accountNavigation]
    },
  },
  watch: {
    '$i18n.locale': {
      async handler() {
        await this.$apolloProvider.clients.cms.reFetchObservableQueries()
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('keyup', (event) => {
      this.keyupHandler(event)
    })

    window.addEventListener('click', (_event) => {
      this.$publish('body-clicked')
    })

    handleScipSalesPreview(this.$route)
  },
  mounted() {
    if (!this.keepAliveInterval) {
      // Interval set to 20 minutes
      this.keepAliveInterval = setInterval(this.keepAliveQuery, 1200000)
    }
  },
  destroyed() {
    this.clearKeepAlive()
  },
  methods: {
    async keepAliveQuery() {
      const { jwt } = this.$store.state.authentication.token
      const { logout } = this.$route.query
      // max auto keep alive of 36 x 20 min = 12h
      if (this.counter < 35) {
        this.counter += 1
        if (jwt && jwtTools.isValid(jwt) && !logout) {
          await this.$store.dispatch(GET_NEW_TOKEN, {
            apollo: this.$apollo.provider.defaultClient,
          })
        }
      } else {
        this.clearKeepAlive()
      }
    },
    clearKeepAlive() {
      if (this.keepAliveInterval) {
        clearInterval(this.keepAliveInterval)
      }
    },
    keyupHandler(event) {
      if (event.keyCode === keyCodes.esc) {
        this.$publish('key-ESC')
      }
    },
    keydownTab() {
      this.isTab = true
    },
    keyEscTabbing() {
      this.isTab = false
    },
  },
}
</script>
