<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>

    <dl v-if="hasOneOption" class="selection singleOption">
      <dt>{{ translatedLabel }}</dt>
      <dd>{{ mappedOptions[1].name }}</dd>
    </dl>
    <dx-select
      v-else
      :value="value"
      :label="translatedLabel"
      :options="mappedOptions"
      :disabled="false"
      :error="translatedError"
      class="selection"
      @input="update"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import { DxSelect } from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DXSelectAdapter',
  components: {
    DxDescriptionLabel,
    DxSelect,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))

    return {
      ...translation,
    }
  },

  computed: {
    mappedRadioError() {
      return this.error || ''
    },

    mappedOptions() {
      const caption = {
        label: this.t('selectOption'),
        caption: true,
      }
      const options = this.mapOptions(this.item)
      return [caption, ...options]
    },

    hasOneOption() {
      return this.item.options.length === 1
    },

    hideRadioLabel() {
      const hideLabelList = ['globalRiskAttributes', 'riskQuestions']
      return hideLabelList.includes(this.$route.params?.stepId || '')
    },

    translatedError() {
      return this.error
        ? this.t(
            `errors.selectRadio.${
              this.value === null ? 'required' : this.error.type
            }`,
            {
              ...this.error.params,
              label: this.translatedLabel,
            },
          )
        : null
    },

    translatedErrorOrEmpty() {
      return this.translatedError || ''
    },
  },

  watch: {
    hasOneOption: {
      handler(val) {
        if (val === true && this.item && this.item.options) {
          this.update(this.item.options[0].value)
        }
      },
      immediate: true,
    },
  },

  methods: {
    update(newValue) {
      this.$emit('input', newValue)
    },

    onBlur() {
      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

$font-family-old: 'Roboto', sans-serif;

.selection {
  display: block;
  width: 100%;

  ::v-deep {
    .button {
      display: flex;
      border-color: $color-turquoise-surf;
      justify-content: center;
      align-items: center;
      background: $white-clr;
    }
    .radioInput ~ .text {
      border: 1px solid $color-turquoise-surf;
      border-radius: rem(20);
      padding: rem(12) rem(16);
      min-width: rem(96);
      background: $white-clr;
      color: $color-turquoise-surf;
      line-height: 1;
    }
    .buttonChecked,
    .button:hover,
    .button:active,
    .button:focus {
      .text {
        background: $color-turquoise-surf;
        color: $white-clr;
      }
    }
  }
}

.singleOption {
  margin-bottom: 0;
  color: $black-clr;
  font-family: $font-family;
  font-size: rem(16);
  line-height: rem(24);

  dt {
    margin-bottom: 0;
  }

  dd {
    margin-bottom: 0;
    font-family: $font-family-heavy;
  }
}

.descriptionText {
  color: $black-clr;
  font-family: $font-family-heavy;
  font-size: rem(20);
  line-height: rem(28);
}

::v-deep .button span.text {
  font-family: $font-family;
}
</style>
