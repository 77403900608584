<template>
  <dx-deadline-form
    next-step-path="PartnerPortal-ReportDamage-Counterparty"
    previous-step-path="PartnerPortal-ReportDamage-DocumentUpload"
    :is-broker="true"
  />
</template>

<script>
import DxDeadlineForm from '~/components/reportDamage/DX-DeadlineForm'

export default {
  name: 'PartnerReportDamageDeadline',
  components: {
    DxDeadlineForm,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsfall-melden/frist',
      fr: '/portailpartenaires/cas-juridique-rapport/date-limite',
      it: '/portaledeipartner/segnalare-caso-giuridico/scadenza',
    },
  },
}
</script>
