<template>
  <li>
    <ol>
      <li v-for="(element, elementIndex) in elements" :key="elementIndex">
        {{ label }}:
        <dl>
          <template v-for="(descendent, descendentIndex) in descendents">
            <template v-if="descendent.options">
              <dt :key="`${descendentIndex}-dt`">
                {{ descendent.label }}
              </dt>
              <dd :key="`${descendentIndex}-dd`">
                {{ findOptionNameByElement(descendent, element) }}
              </dd>
            </template>
            <template
              v-else-if="
                !isEmpty(element[descendent.dataKey]) &&
                typeof element[descendent.dataKey] === 'string'
              "
            >
              <dt :key="`${descendentIndex}-dt`">{{ descendent.label }}</dt>
              <dd :key="`${descendentIndex}-dd`">
                {{ element[descendent.dataKey] }}
              </dd>
            </template>
            <template v-else-if="element[descendent.dataKey].zip">
              <dt :key="`${descendentIndex}-dt`">
                {{ formContext.t('zipCity.zip') }}:
              </dt>
              <dd :key="`${descendentIndex}-dd`">
                {{ element[descendent.dataKey].zip }}
              </dd>
              <dt :key="`${descendentIndex}-dt`">
                {{ formContext.t('zipCity.city') }}:
              </dt>
              <dd :key="`${descendentIndex}-dd`">
                {{ element[descendent.dataKey].city }}
              </dd>
            </template>
          </template>
        </dl>
      </li>
    </ol>
  </li>
</template>

<script>
import { isEmpty } from '@/components/DX-ScipSales/utils'

export default {
  name: 'GroupFieldEntry',
  props: {
    formContext: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    elements: {
      type: Array,
      required: true,
    },
    descendents: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      isEmpty,
    }
  },
  methods: {
    findOptionNameByElement(descendent, element) {
      return descendent.options.find(
        (options) => options.value === element[descendent.dataKey],
      )?.name
    },
  },
}
</script>
