import Vuex from 'vuex'
import Vue from 'vue'

import modules from '~/store/modules'

Vue.use(Vuex)

const getters = {}
const mutations = {}
const actions = {}
const state = {}

export function createMocks(
  custom = {
    modules: {},
    getters: {},
    mutations: {},
    actions: {},
    state: {},
  },
) {
  const mockModules = { ...modules, ...custom.modules }
  const mockGetters = { ...getters, ...custom.getters }
  const mockMutations = { ...mutations, ...custom.mutations }
  const mockActions = { ...actions, ...custom.actions }
  const mockState = { ...state, ...custom.state }

  return {
    modules: mockModules,
    getters: mockGetters,
    mutations: mockMutations,
    actions: mockActions,
    state: mockState,
    store: new Vuex.Store({
      modules: mockModules,
      getters: mockGetters,
      mutations: mockMutations,
      actions: mockActions,
      state: mockState,
    }),
  }
}

export const { store } = createMocks()
