export default function ({ store, redirect, route }) {
  const routeMatchesPartnerPortalIt = /PartnerPortal.*___it/
  const routeMatchesOfferIt = /Offer.*___it/
  const isPartnerPortalItPage = routeMatchesPartnerPortalIt.test(route.name)
  const isOfferItPage = routeMatchesOfferIt.test(route.name)

  if (isPartnerPortalItPage || isOfferItPage) {
    redirect(
      store.app.localePath(
        store.app.getRouteBaseName(route),
        store.app.i18n.defaultLocale,
      ),
    )
  }
}
