import format from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'
import dateFnsSubYears from 'date-fns/subYears'
import isAfter from 'date-fns/isAfter'
import addYears from 'date-fns/addYears'
import subDays from 'date-fns/subDays'

function fixDateForValidation(date, raw) {
  let fixedDate = date
  let fullDate = ''
  if (date.includes('.')) {
    const dateParts = date.split('.')
    const year = parseInt(dateParts[2], 10)
    const month = parseInt(dateParts[0], 10) - 1 // -1 because new Date() uses  the month-Index [0(JAN)-11(DEZ)]
    const day = parseInt(dateParts[1], 10)

    fullDate = new Date(year, month, day)
    fixedDate = format(fullDate, 'yyyy-MM-dd')
  } else if (date.includes('-')) {
    fullDate = new Date(date)
  }
  return raw ? fullDate : fixedDate
}

export function inTheFuture(date) {
  return isFuture(fixDateForValidation(date, true))
}

// we need to change dd.MM.yyyy format to MM.dd.yyyy format in order for fixDateForValidation() to work correctly
export function inTheFutureGermanFormat(date) {
  let dateUsFormat = date
  if (date.includes('.')) {
    const dateParts = date.split('.')
    dateUsFormat = `${dateParts[1]}.${dateParts[0]}.${dateParts[2]}`
  }
  return inTheFuture(dateUsFormat)
}

export function inThePast(date) {
  return isPast(fixDateForValidation(date, true))
}

export function inThePastGermanFormat(date) {
  let dateUsFormat = date
  if (date.includes('.')) {
    const dateParts = date.split('.')
    dateUsFormat = `${dateParts[1]}.${dateParts[0]}.${dateParts[2]}`
  }
  return inThePast(dateUsFormat)
}

export function isAdult(date) {
  const eighteenYearAgo = dateFnsSubYears(new Date(), 18)
  return fixDateForValidation(date, true) <= eighteenYearAgo
}

export function atMostOneYearInFuture(date) {
  return !isAfter(fixDateForValidation(date, true), addYears(new Date(), 1))
}

export function atMostOneWeekInPast(date) {
  return isAfter(fixDateForValidation(date, true), subDays(new Date(), 8))
}

export default {
  inTheFuture,
  inTheFutureGermanFormat,
  inThePast,
  inThePastGermanFormat,
  isAdult,
  atMostOneYearInFuture,
  atMostOneWeekInPast,
}
