const middleware = {}

middleware['auth'] = require('../src/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['locale'] = require('../src/middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['offer'] = require('../src/middleware/offer.js')
middleware['offer'] = middleware['offer'].default || middleware['offer']

middleware['routerRules'] = require('../src/middleware/routerRules.js')
middleware['routerRules'] = middleware['routerRules'].default || middleware['routerRules']

middleware['tracking'] = require('../src/middleware/tracking.js')
middleware['tracking'] = middleware['tracking'].default || middleware['tracking']

export default middleware
