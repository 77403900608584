<template>
  <div>
    <dx-mailbox-compose
      v-if="!readOnly"
      :send-new-message="(newMessageText) => sendNewMessage(newMessageText)"
    />

    <div class="row">
      <div class="col-12">
        <dx-mailbox-item
          v-for="item in getMailDataReduced()"
          :key="item.id"
          :item="item"
          :link="buildItemLink(item)"
          :reply-heading="replyHeading"
          :can-reply="canReply(item.sender)"
          :reply="reply"
          :on-unread-item-click="onUnreadItemClick"
          :read-only="readOnly || isOwnMessage(item)"
          :show-status-flag="showStatusFlag"
        />
      </div>
    </div>
    <div v-if="mailData.length === 0">
      {{ $t('noMails') }}
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <button
          v-if="mailData.length > 5"
          type="button"
          class="btn-blank secondary-link pr-0 mt-3"
          @click="toggleShowMore"
        >
          {{ showMore ? $t('showLess') : $t('showMore') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DxMailboxItem from './DX-Mailbox-Item'
import DxMailboxCompose from './DX-Mailbox-Compose'

export default {
  name: 'DxMailbox',

  components: {
    DxMailboxItem,
    DxMailboxCompose,
  },
  props: {
    mailData: {
      type: Array,
      default() {
        return []
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    sendNewMessage: {
      type: Function,
      default: () => false,
    },
    buildItemLink: {
      type: Function,
      default: () => null,
    },
    replyHeading: {
      type: String,
      default: null,
    },
    canReply: {
      type: Function,
      default: () => true,
    },
    reply: {
      type: Function,
      default: () => false,
    },
    onUnreadItemClick: {
      type: Function,
      default: () => false,
    },
    showStatusFlag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    currentUserId() {
      if (this.$store.getters.isExternalLawyer) {
        return this.$store.state.application.currentLawyer.id
      }
      if (this.$store.getters.isBroker) {
        return this.$store.state.application.currentBroker.id
      }
      return this.$store.state.application.currentUser.id
    },
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore
    },
    getMailDataReduced() {
      return this.showMore ? this.mailData : this.mailData.slice(0, 5)
    },
    isOwnMessage(item) {
      const userId = this.currentUserId
      return item.sender.megadexId === userId || item.sender.id === userId
    },
  },
}
</script>
