<template>
  <div>
    <!-- READ ONLY -->
    <template v-if="!editable">
      <ul class="neutral">
        <li class="row">
          <span class="col-12 col-md-4 col-xl-3">{{ $t('adress') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{
              `${$t(formData.salutation.toLowerCase())} ${formData.firstName} ${
                formData.lastName
              }`
            }}
          </span>
        </li>
        <li class="font-weight-bold row">
          <span class="col-12 col-md-8 col-xl-9 offset-md-4 offset-xl-3">
            {{ `${formData.address.street} ${formData.address.number}` }}
          </span>
        </li>
        <li class="mb-2 font-weight-bold row">
          <span class="col-12 col-md-8 col-xl-9 offset-md-4 offset-xl-3">
            {{ `${formData.address.zip} ${formData.address.city}` }}
          </span>
        </li>
        <li v-if="formData.contactDetails.phone" class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">{{ $t('telephone') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.contactDetails.phone }}
          </span>
        </li>
        <li v-if="formData.contactDetails.mobile" class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">{{ $t('mobilePhone') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.contactDetails.mobile }}
          </span>
        </li>
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">{{ $t('email') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.email || '-' }}
          </span>
        </li>
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">{{ $t('dateofbirth') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.dateOfBirth || '-' }}
          </span>
        </li>
        <li v-if="!isBrokerOffer" class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.documentDeliveryMethod') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{
              $t(
                `offer.data.documentDeliveryMethods.${formData.documentDeliveryMethod}`,
              )
            }}
          </span>
        </li>
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.language') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ $t(formData.locale) }}
          </span>
        </li>
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.payment') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{
              $t(`policies.${brokerPolicyData.paymentInterval.toLowerCase()}`)
            }}
          </span>
        </li>
        <li v-if="brokerPolicyData.mainExpiry" class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.mainExpiry') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ brokerPolicyData.mainExpiry }}
          </span>
        </li>
      </ul>
    </template>

    <!-- EDIT MODE -->
    <form v-else @change="change">
      <dx-radio-list
        v-model="formData.salutation"
        v-validate.disable="'required'"
        :error="
          errors.first('gender') || errors.first('gender', 'policyholder')
        "
        :options="salutationOptions"
        name="salutation"
        data-vv-name="gender"
        class="my-4"
      />
      <dx-input
        v-model="formData.firstName"
        v-validate.disable="'required|min:2'"
        :error="
          errors.first('firstName') || errors.first('firstName', 'policyholder')
        "
        :label="$t('firstName')"
        data-vv-name="firstName"
        autocomplete="given-name"
        class="mb-4"
      />
      <dx-input
        v-model="formData.lastName"
        v-validate.disable="'required|min:2'"
        :error="
          errors.first('lastName') || errors.first('lastName', 'policyholder')
        "
        :label="$t('lastName')"
        data-vv-name="lastName"
        autocomplete="family-name"
        class="mb-4"
      />
      <dx-input
        v-if="fieldVisible(formData.address.street)"
        key="street"
        v-model="formData.address.street"
        v-validate.disable="'required|min:2'"
        :error="
          errors.first('street') ||
          errors.first('street', 'policyholder.address')
        "
        :label="$t('street')"
        class="mb-4"
        data-vv-name="street"
        autocomplete="address-line1"
      />
      <dx-input
        v-if="fieldVisible(formData.address.number)"
        key="streetNumber"
        v-model="formData.address.number"
        v-validate="{ required: false }"
        :error="
          errors.first('streetNumber') ||
          errors.first('streetNumber', 'policyholder.address')
        "
        :label="$t('houseNumber')"
        class="mb-4"
        data-vv-name="streetNumber"
        autocomplete="address-line2"
      />
      <dx-input
        v-if="fieldVisible(formData.address.postOfficeBox)"
        key="additional"
        v-model="formData.address.postOfficeBox"
        v-validate.disable="{ required: false }"
        :error="
          errors.first('additional') ||
          errors.first('additional', 'policyholder.address')
        "
        :label="$t('mailbox')"
        class="mb-4"
        data-vv-name="additional"
      />
      <dx-zip-to-city-input
        v-if="
          fieldVisible(formData.address.zip) ||
          fieldVisible(formData.address.city)
        "
        key="zipCity"
        :zip-input="formData.address.zip"
        :city-input="formData.address.city"
        required
        :additional-data-vv-scopes="['policyholder.address']"
        icon="orange-cross"
        class="mb-4"
        @update:city="(city) => (formData.address.city = city)"
        @update:zip="(zip) => (formData.address.zip = zip)"
      />
      <dx-input
        v-if="fieldVisible(formData.contactDetails.phone)"
        key="phone"
        v-model="formData.contactDetails.phone"
        v-validate.disable="
          formData.contactDetails.mobile &&
          formData.contactDetails.mobile.trim()
            ? ''
            : 'required|min:4'
        "
        :error="
          errors.first('mobileOrPhoneNumber') ||
          errors.first('mobileOrPhoneNumber', 'policyholder.contactDetails')
        "
        :label="$t('telephone')"
        class="mb-4"
        data-vv-name="mobileOrPhoneNumber"
        autocomplete="tel"
      />
      <dx-input
        v-if="fieldVisible(formData.contactDetails.mobile)"
        key="mobile"
        v-model="formData.contactDetails.mobile"
        v-validate.disable="
          formData.contactDetails.phone && formData.contactDetails.phone.trim()
            ? ''
            : 'required|min:4'
        "
        :error="
          errors.first('mobileOrPhoneNumber') ||
          errors.first('mobileOrPhoneNumber', 'policyholder.contactDetails')
        "
        :label="$t('mobilePhone')"
        class="mb-4"
        data-vv-name="mobileOrPhoneNumber"
        autocomplete="tel"
      />
      <dx-input
        v-if="
          fieldVisible(formData.email) ||
          fieldVisible(formData.emailConfirmation)
        "
        key="email"
        v-model.trim="formData.email"
        v-validate.disable="{
          required: !noEmail,
          email: !noEmail,
        }"
        :error="
          errors.first('invalidEmail') || errors.first('email', 'policyholder')
        "
        :label="$t('email')"
        :disabled="noEmail"
        class="mb-4"
        data-vv-name="invalidEmail"
        autocomplete="email"
      />
      <dx-input
        v-if="
          fieldVisible(formData.email) ||
          fieldVisible(formData.emailConfirmation)
        "
        key="emailRepeat"
        v-model.trim="formData.emailConfirmation"
        v-validate.disable="{
          required: !noEmail,
          match_strings: !noEmail ? formData.email : false,
        }"
        :label="$t('emailRepeat')"
        :error="
          errors.first('invalidEmailRepeat') ||
          errors.first('emailConfirmation', 'policyholder')
        "
        :disabled="noEmail"
        class="mb-4"
        data-vv-name="invalidEmailRepeat"
        autocomplete="email"
      />
      <dx-radio-check
        v-if="isBrokerOffer && fieldVisible(noEmail)"
        id="noEmail"
        key="noEmail"
        v-model="noEmail"
        v-validate.disable="(formData.email || '').trim() ? '' : 'required'"
        :label="$t('offer.data.noEmail')"
        no-background
        type="checkbox"
        name="noEmail"
        class="mb-4"
        :checked="noEmail"
        @input="noEmail = !noEmail"
      />
      <dx-input
        v-if="fieldVisible(formData.dateOfBirth)"
        key="dateOfBirth"
        v-model="formData.dateOfBirth"
        v-validate.disable="'required|isAdult|date_format:dd.MM.yyyy'"
        :error="
          errors.first('date') || errors.first('dateOfBirth', 'policyholder')
        "
        :label="$t('dateofbirth')"
        type="date"
        class="mb-5"
        data-vv-name="date"
      />

      <template v-if="!isBrokerOffer">
        <h4>{{ $t('offer.data.documentDeliveryMethod') }}</h4>
        <dx-radio-list
          key="documentDeliveryMethod"
          v-model="formData.documentDeliveryMethod"
          v-validate.disable="'required'"
          :error="errors.first('documentDeliveryMethod')"
          :options="deliveryOptions"
          name="documentDeliveryMethod"
          class="mb-5"
          data-vv-name="documentDeliveryMethod"
        />
      </template>

      <template v-if="!hideOptionalFields">
        <h4>{{ $t('offer.data.language') }}</h4>
        <dx-radio-list
          key="language"
          v-model="formData.locale"
          v-validate.disable="'required'"
          :error="errors.first('locale')"
          :options="langOptions"
          name="language"
          class="mb-5"
          data-vv-name="locale"
        />

        <h4>{{ $t('offer.data.payment') }}</h4>
        <dx-radio-list
          key="paymentInterval"
          v-model="brokerPolicyData.paymentInterval"
          v-validate.disable="'required'"
          :error="errors.first('paymentInterval')"
          :options="isBrokerOffer ? paymentOptionsBroker : paymentOptions"
          name="paymentInterval"
          class="mb-5"
          data-vv-name="paymentInterval"
        />

        <template v-if="isBrokerOffer">
          <h4>{{ $t('offer.data.mainExpiry') }}</h4>
          <dx-input
            key="mainExpiry"
            v-model="brokerPolicyData.mainExpiry"
            v-validate.disable="'required|mainExpiry|date_format:dd.MM.'"
            class="mb-5"
            :error="errors.first('mainExpiry')"
            data-vv-name="mainExpiry"
          />
        </template>
      </template>
    </form>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioCheck,
  DxRadioList,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxZipToCityInput from '~/components/DX-ZipToCityInput'

export default {
  name: 'PolicyHolderDataForm',
  components: {
    DxInput,
    DxRadioList,
    DxRadioCheck,
    DxZipToCityInput,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    isBrokerOffer: {
      type: Boolean,
      default: true,
    },
    hideOptionalFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        ...JSON.parse(
          JSON.stringify(
            this.$store.state[this.isBrokerOffer ? 'brokerOffer' : 'offer']
              .policyHolderData,
          ),
        ),
      },
      brokerPolicyData: {
        paymentInterval: this.isBrokerOffer
          ? this.$store.state.brokerOffer.brokerPolicyData.paymentInterval
          : 'YEARLY',
        mainExpiry: this.$store.state.brokerOffer.brokerPolicyData.mainExpiry,
      },
      noEmail: this.isBrokerOffer
        ? this.$store.state.brokerOffer.noEmail
        : false,
      salutationOptions: [
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
        {
          text: this.$t('male'),
          id: 'MALE',
        },
      ],
      deliveryOptions: [
        {
          text: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
          id: 'EMAIL',
        },
      ],
      langOptions: [
        {
          text: this.$t('DE'),
          id: 'DE',
        },
        {
          text: this.$t('FR'),
          id: 'FR',
        },
        {
          text: this.$t('IT'),
          id: 'IT',
        },
        {
          text: this.$t('EN'),
          id: 'EN',
        },
      ],
      paymentOptions: [
        {
          text: this.$t('offer.data.paymentYear'),
          id: 'YEARLY',
        },
      ],
      paymentOptionsBroker: [
        {
          text: this.$t('offer.data.paymentYear'),
          id: 'YEARLY',
        },
        {
          text: this.$t('offer.data.paymentHalf'),
          id: 'HALF_YEARLY',
        },
        {
          text: this.$t('offer.data.paymentQuarterly'),
          id: 'QUARTERLY',
        },
      ],
      invoiceOptions: [
        {
          text: this.$t('offer.data.draft.partner'),
          id: 'partner',
        },
        {
          text: this.$t('offer.data.draft.customer'),
          id: 'customer',
        },
      ],
    }
  },
  computed: {
    storedOffer() {
      return this.isBrokerOffer
        ? this.$store.state.brokerOffer
        : this.$store.state.offer
    },
  },
  watch: {
    noEmail(newValue) {
      if (this.isBrokerOffer) {
        if (newValue) {
          this.formData.email = ''
          this.formData.emailConfirmation = ''
        }
        // Immediately save in store as this has impact on other forms as well
        // (document delivery)
        this.$store.commit('SET_BROKER_POLICY_HOLDER_NO_EMAIL', newValue)
      }
    },
  },
  methods: {
    async saveForm(noValidation) {
      this.errors.clear()
      const result = noValidation || (await this.$validator.validateAll())
      if (result) {
        const mutation = this.isBrokerOffer
          ? 'SET_BROKER_POLICY_HOLDER_DATA'
          : 'SET_POLICY_HOLDER_DATA'
        this.$store.commit(mutation, this.formData)
        if (this.isBrokerOffer) {
          this.$store.commit(
            'SET_BROKER_POLICY_EXTRA_DATA',
            this.brokerPolicyData,
          )
        }
      }
      return result
    },
    async validateToSaveBrokerOffer() {
      this.errors.clear()
      const result = await this.$validator.validateAll([
        'gender',
        'firstName',
        'lastName',
        'mainExpiry',
        'locale',
      ])
      if (result) {
        this.$store.commit('SET_BROKER_POLICY_HOLDER_DATA', this.formData)
        this.$store.commit(
          'SET_BROKER_POLICY_EXTRA_DATA',
          this.brokerPolicyData,
        )
      }
      return result
    },
    resetForm() {
      this.formData = {
        ...JSON.parse(JSON.stringify(this.storedOffer.policyHolderData)),
      }
    },
    change() {
      this.$emit('change')
    },
    fieldVisible(field) {
      return !this.hideOptionalFields || !!field
    },
  },
}
</script>
