const navItems = [
  {
    title: 'legal',
    path: 'navLinksTariffs.privatLegal',
    items: [
      {
        title: 'partnerPortal.tariff.p-private.shorttext',
        path: 'navLinksTariffs.privatLegalPprivate',
        isWebsiteLink: true,
      },
      {
        title: 'partnerPortal.tariff.p-move.shorttext',
        path: 'navLinksTariffs.privatLegalPmove',
        isWebsiteLink: true,
      },
      {
        title: 'partnerPortal.tariff.p-combi.shorttext',
        path: 'navLinksTariffs.privatLegalPkombi',
        isWebsiteLink: true,
      },
      {
        title: 'partnerPortal.tariff.b-business.shorttext',
        path: 'navLinksTariffs.bbusiness',
        isWebsiteLink: true,
      },
      {
        title: 'partnerPortal.tariff.b-move.shorttext',
        path: 'navLinksTariffs.bmove',
        isWebsiteLink: true,
      },
      {
        title: 'partnerPortal.tariff.small-business.shorttext',
        path: 'navLinksTariffs.smallbusiness',
        isWebsiteLink: true,
      },
      {
        title: 'faq',
        path: 'navLinksTariffs.privatFaq',
        isWebsiteLink: true,
      },
      {
        title: 'downloads',
        path: 'navLinksTariffs.privatDownloads',
        isWebsiteLink: true,
      },
    ],
  },
  {
    title: 'car',
    path: 'navLinksTariffs.carPolicy',
    isWebsiteLink: true,
    items: [
      {
        title: 'offer.calculatePremium',
        path: 'navLinksTariffs.carRequest',
        isWebsiteLink: true,
      },
      {
        title: 'carInsuranceValue',
        path: 'navLinksTariffs.carProduct',
        isWebsiteLink: true,
      },
      {
        title: 'faq',
        path: 'navLinksTariffs.carProductFaq',
        isWebsiteLink: true,
      },
      {
        title: 'downloads',
        path: 'navLinksTariffs.carProductDownloads',
        isWebsiteLink: true,
      },
      {
        title: 'oldtimer',
        path: 'https://www.oldtimer-versichern.ch/?utm_source=dextra&utm_medium=navigation&utm_campaign=oldtimer',
        isExternalLink: true,
        hideForLocale: 'fr',
      },
      {
        title: 'autosense',
        path: 'https://autosense.ch/flexdrive',
        isExternalLink: true,
        hideForLocale: 'fr',
      },
      {
        title: 'Kasko2Go',
        path: 'https://www.kasko2go.com',
        isExternalLink: true,
        hideForLocale: 'fr',
      },
    ],
  },
  {
    title: 'moto',
    path: '',
    items: [
      {
        title: 'offer.calculatePremium',
        path: 'navLinksTariffs.motoRequest',
        isWebsiteLink: true,
      },
      {
        title: 'motoInsuranceValue',
        path: 'navLinksTariffs.motoProduct',
        isWebsiteLink: true,
      },
      {
        title: 'faq',
        path: 'navLinksTariffs.motoFaq',
        isWebsiteLink: true,
      },
      {
        title: 'downloads',
        path: 'navLinksTariffs.motoDownloads',
        isWebsiteLink: true,
      },
    ],
  },
  {
    title: 'about',
    path: '',
    items: [
      {
        title: 'companyNav',
        path: 'navLinksTariffs.company',
        isWebsiteLink: true,
      },
      {
        title: 'team',
        path: 'navLinksTariffs.team',
        isWebsiteLink: true,
      },
      {
        title: 'jobs',
        path: 'navLinksTariffs.jobs',
        isWebsiteLink: true,
      },
    ],
  },
  {
    title: 'service',
    path: '',
    items: [
      {
        title: 'contact',
        path: 'navLinksTariffs.contact',
        isWebsiteLink: true,
      },
      {
        title: 'reportDamage',
        path: 'navLinksTariffs.reportDamage',
        isWebsiteLink: true,
      },
      {
        title: 'legalAdvice',
        path: 'navLinksTariffs.reportDamage',
        isWebsiteLink: true,
      },
      {
        title: 'customerPortalTitle2',
        path: '/',
        isServiceLink: true,
      },
      {
        title: 'lawyerPortalTitle',
        path: '/',
        isServiceLink: true,
      },
      {
        title: 'partnerPortalTitle',
        path: '/',
        isServiceLink: true,
      },
    ],
  },
]

export default navItems
