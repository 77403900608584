<template>
  <div>
    <!-- READ ONLY -->
    <template v-if="!editable">
      <div class="row">
        <div class="col-12 col-md-10 mb-2 row">
          <p class="col-12 col-md-8 mb-0 mb-md-auto">
            {{ $t('offer.basic.insureDate') }}
          </p>
          <p class="col-12 col-md-4 font-weight-bold">
            {{ formData.startOfInsurance }}
          </p>
        </div>
        <div class="col-12 col-md-10 mb-2 row">
          <p class="col-12 col-md-8 mb-0 mb-md-auto">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.basic.insureWho'
                  : 'offer.basic.insureWho',
              )
            }}
          </p>
          <p class="col-12 col-md-4 font-weight-bold">
            {{
              formData.familyOption
                ? $t('offer.basic.personsHousehold')
                : $t('offer.basic.singlePerson')
            }}
          </p>
        </div>
        <template v-if="activeTariff !== 'p-move'">
          <div class="col-12 col-md-10 mb-2 row">
            <p class="col-12 col-md-8 mb-0 mb-md-auto">
              {{
                $t(
                  isBrokerOffer
                    ? 'brokerOffer.basic.apartmentsRentedInSwitzerland'
                    : 'offer.basic.apartmentsRentedInSwitzerland',
                )
              }}
            </p>
            <p class="col-12 col-md-4 font-weight-bold">
              {{
                formData.apartmentsRentedInSwitzerland ? $t('yes') : $t('no')
              }}
            </p>
          </div>
          <div
            v-if="formData.apartmentsRentedInSwitzerland"
            class="col-12 col-md-10 row"
          >
            <p class="col-12 col-md-8 mb-0 mb-md-auto">
              {{
                $t(
                  isBrokerOffer
                    ? 'brokerOffer.basic.numberOfApartmentsForRentInSwitzerland'
                    : 'offer.basic.numberOfApartmentsForRentInSwitzerland',
                )
              }}
            </p>
            <p class="col-12 col-md-4 font-weight-bold">
              {{ formData.numberOfApartmentsForRentInSwitzerland }}
            </p>
          </div>
        </template>
      </div>
    </template>

    <!-- EDIT MODE -->
    <form v-else @change="change">
      <div class="row mt-5">
        <div class="col-12 col-md-5">
          <dx-input
            v-model="formData.startOfInsurance"
            v-validate.disable="{
              required: true,
              atMostOneYearInFuture: true,
              atMostOneWeekInPast: isBrokerOffer,
            }"
            :label="$t('offer.basic.insureDate')"
            :error="errors.first('startOfInsurance')"
            :only-in-future="!isBrokerOffer"
            is-date-input
            type="date"
            data-vv-name="startOfInsurance"
            :min-date="isBrokerOffer ? new Date().fp_incr(-7) : null"
            :default-date="isBrokerOffer ? new Date().fp_incr(1) : null"
            @blur="change"
          />
        </div>
      </div>

      <!-- TWO -->
      <div class="row my-5">
        <div class="col-12">
          {{
            $t(
              isBrokerOffer
                ? 'brokerOffer.basic.insureWho'
                : 'offer.basic.insureWho',
            )
          }}
        </div>
        <div class="col-12">
          <dx-radio-list
            v-model="formData.familyOption"
            v-validate.disable="'required'"
            :options="familyOptions"
            :error="errors.first('familyOption')"
            name="familyOption"
            data-vv-name="familyOption"
          />
        </div>
      </div>

      <!-- THREE -->
      <div v-if="activeTariff !== 'p-move'" class="row">
        <div class="col-12">
          {{
            $t(
              isBrokerOffer
                ? 'brokerOffer.basic.apartmentsRentedInSwitzerland'
                : 'offer.basic.apartmentsRentedInSwitzerland',
            )
          }}
        </div>
        <div class="col-12">
          <dx-radio-list
            v-model="formData.apartmentsRentedInSwitzerland"
            v-validate.disable="'required'"
            :options="apartmentsRentedInSwitzerlandOptions"
            :error="errors.first('apartmentsRentedInSwitzerland')"
            name="apartmentsRentedInSwitzerland"
            data-vv-name="apartmentsRentedInSwitzerland"
          />
        </div>
      </div>

      <!-- FOUR -->
      <div
        v-if="
          activeTariff !== 'p-move' && formData.apartmentsRentedInSwitzerland
        "
        class="row mt-5"
      >
        <div class="col-12 col-md-5">
          <dx-select
            v-model="formData.numberOfApartmentsForRentInSwitzerland"
            :error="errors.first('numberOfApartmentsForRentInSwitzerland')"
            :label="
              $t(
                isBrokerOffer
                  ? 'brokerOffer.basic.numberOfApartmentsForRentInSwitzerland'
                  : 'offer.basic.numberOfApartmentsForRentInSwitzerland',
              )
            "
            :options="numberOfApartmentsForRentInSwitzerlandOptions"
            data-vv-name="numberOfApartmentsForRentInSwitzerland"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
  DxSelect,
} from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'PrivateBasicQuestions',
  components: {
    DxInput,
    DxRadioList,
    DxSelect,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    isBrokerOffer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formData: {
        ...JSON.parse(
          JSON.stringify(
            this.$store.state[this.isBrokerOffer ? 'brokerOffer' : 'offer']
              .privateBasicQuestions,
          ),
        ),
      },
      familyOptions: [
        { text: this.$t('offer.basic.personsHousehold'), id: true },
        { text: this.$t('offer.basic.singlePerson'), id: false },
      ],
      apartmentsRentedInSwitzerlandOptions: [
        { text: this.$t('yes'), id: true },
        { text: this.$t('no'), id: false },
      ],
      numberOfApartmentsForRentInSwitzerlandOptions: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
      ],
    }
  },
  computed: {
    storedOffer() {
      return this.isBrokerOffer
        ? this.$store.state.brokerOffer
        : this.$store.state.offer
    },
    activeTariff() {
      return this.storedOffer.activeTariff
    },
  },
  watch: {
    'formData.apartmentsRentedInSwitzerland': function setNumber(val) {
      const apartments = val ? '1' : '0'
      this.formData.numberOfApartmentsForRentInSwitzerland = apartments
    },
  },
  methods: {
    async validateForm() {
      const result = await this.$validator.validateAll()
      return result
    },
    async saveForm() {
      this.$store.commit(
        this.isBrokerOffer
          ? 'SET_BROKER_PRIVATE_BASIC_QUESTIONS'
          : 'SET_PRIVATE_BASIC_QUESTIONS',
        this.formData,
      )
      return true
    },
    resetForm() {
      this.formData = {
        ...JSON.parse(JSON.stringify(this.storedOffer.privateBasicQuestions)),
      }
    },
    change() {
      this.$emit('change')
    },
  },
}
</script>
