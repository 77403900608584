import acceptInsuredPersonInvitation from '~/domains/user/__gql__/mutations/acceptInsuredPersonInvitation.gql'
import profileQuery from '~/domains/user/__gql__/queries/profile.gql'
import profileLawyerQuery from '~/domains/externalLawyer/__gql__/queries/profileLawyer.gql'
import profileBrokerQuery from '~/domains/partnerPortal/__gql__/queries/profileBroker.gql'
import createLawyer from '~/domains/externalLawyer/__gql__/mutations/createLawyer.gql'
import authenticationQuery from '../../__gql__/queries/authentication.gql'
import gmJwtTokenQuery from '../../__gql__/queries/gmJwtToken.gql'
import changePortalQuery from '../../__gql__/queries/changePortal.gql'
import keepAliveQuery from '../../__gql__/queries/keepAlive.gql'
import conflictsQuery from '../../__gql__/queries/conflicts.gql'
import resolveConflictsMutation from '../../__gql__/mutations/resolveConflicts.gql'

export default {
  acceptInsuredPersonInvitation: async (apolloClient, variables) => {
    const result = await apolloClient.mutate({
      mutation: acceptInsuredPersonInvitation,
      variables,
    })
    return result.data
  },
  createLawyer: async (apolloClient, variables) => {
    const variablesWithoutTypeName = JSON.parse(
      JSON.stringify(variables, (k, v) => (k === '__typename' ? undefined : v)),
    )
    const result = await apolloClient.mutate({
      mutation: createLawyer,
      variables: variablesWithoutTypeName,
    })
    return result.data
  },
  login: async (apolloClient, variables) => {
    const result = await apolloClient.query({
      query: authenticationQuery,
      variables,
    })
    return result.data
  },
  loginGroupeMutuel: async (apolloClient, variables) => {
    const result = await apolloClient.query({
      query: gmJwtTokenQuery,
      variables,
    })
    return result.data.gmJwtToken
  },
  changePortal: async (apolloClient, variables) => {
    const result = await apolloClient.query({
      query: changePortalQuery,
      variables,
    })
    return result.data.changePortal
  },
  getProfile: async (apolloClient) => {
    const result = await apolloClient.query({
      query: profileQuery,
    })
    return result.data.profile
  },
  getProfileLawyer: async (apolloClient) => {
    const result = await apolloClient.query({
      query: profileLawyerQuery,
    })
    return result.data.profile
  },
  getProfileBroker: async (apolloClient) => {
    const result = await apolloClient.query({
      query: profileBrokerQuery,
    })
    return result.data.profile
  },
  keepAlive: async (apolloClient) => {
    const result = await apolloClient.query({
      query: keepAliveQuery,
      fetchPolicy: 'network-only',
    })
    return result.data.keepAlive
  },
  conflicts: async (apolloClient, variables) => {
    const result = await apolloClient.query({
      query: conflictsQuery,
      variables,
      fetchPolicy: 'network-only',
    })
    return result.data.conflicts
  },
  resolveConflicts: async (apolloClient, variables) => {
    const result = await apolloClient.mutate({
      mutation: resolveConflictsMutation,
      variables,
    })
    return result.data.resolveConflict
  },
}
