<template>
  <form @submit.prevent="submit">
    <dx-input
      id="firstName"
      v-model="gmCustomerData.firstName"
      :error="errors.first('firstName')"
      :label="$t('firstName')"
      data-vv-name="firstName"
      class="mb-4"
      autocomplete="given-name"
    />

    <dx-input
      id="lastName"
      v-model="gmCustomerData.lastName"
      v-validate.disable="'required'"
      :error="errors.first('lastName')"
      :label="$t('lastName')"
      data-vv-name="lastName"
      class="mb-4"
      autocomplete="family-name"
    />

    <dx-input
      id="policyNumber"
      v-model="gmCustomerData.customerNumber"
      :error="errors.first('policyNumber')"
      :label="$t('policyNumber')"
      data-vv-name="policyNumber"
      class="mb-4"
    />

    <dx-input
      id="dateOfBirth"
      v-model="gmCustomerData.dateOfBirth"
      :error="errors.first('dateOfBirth')"
      :label="$t('dateofbirth')"
      data-vv-name="dateOfBirth"
      is-date-input
      type="date"
      class="mb-4"
    />

    <dx-input
      id="email"
      v-model="gmCustomerData.email"
      v-validate.disable="'required|email'"
      :error="errors.first('invalidEmail')"
      :label="$t('email')"
      data-vv-name="invalidEmail"
      class="mb-4"
      autocomplete="email"
    />

    <dx-button
      id="loginGroupeMutuel"
      :text="$t('submit')"
      class="text-right"
      type="sumbit"
    />
  </form>
</template>

<script>
import { DxButton, DxInput } from '@sumcumo/dextra-frontend-component-lib-old'
import { LOGIN_GROUPE_MUTUEL } from '~/domains/authentication/__store__/actions'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'

export default {
  name: 'DxRegistrationGm',
  components: {
    DxButton,
    DxInput,
  },
  mixins: [loadingMixin, apolloErrorsMixin],
  data() {
    return {
      gmCustomerData: {
        firstName: '',
        lastName: '',
        customerNumber: null,
        email: '',
        dateOfBirth: '',
      },
    }
  },
  computed: {
    hasFirstName() {
      return !!this.gmCustomerData.firstName
    },
    hasLastName() {
      return !!this.gmCustomerData.lastName
    },
    hasDateOfBirth() {
      return !!this.gmCustomerData.dateOfBirth
    },
    hasEmail() {
      return !!this.gmCustomerData.email
    },
    validateGmCustomerData() {
      if (this.gmCustomerData.email) {
        return (
          !!(this.hasLastName && this.gmCustomerData.customerNumber) ||
          !!(this.hasFirstName && this.hasLastName && this.hasDateOfBirth)
        )
      }
      return false
    },
  },
  methods: {
    async submit() {
      const result = await this.$validator.validateAll()

      if (result) {
        const variables = {
          customerNumber: parseInt(this.gmCustomerData.customerNumber, 10),
          firstName: this.gmCustomerData.firstName,
          lastName: this.gmCustomerData.lastName,
          email: this.gmCustomerData.email,
          dateOfBirth: this.gmCustomerData.dateOfBirth,
        }
        if (this.validateGmCustomerData) {
          try {
            await this.$store.dispatch(LOGIN_GROUPE_MUTUEL, {
              variables,
              apollo: this.$apollo.provider.defaultClient,
            })
            this.$router.push(
              this.localePath('SelfService-ReportDamage-MyInfo'),
            )
          } catch (err) {
            const sanitizedErrors = this.handleApolloError(err)

            switch (sanitizedErrors.networkError.code) {
              case 1:
                this.$notification.error({
                  messageKey: 'groupeMutuel.roleError',
                })
                break
              case 6:
                this.$router.push({ path: this.localePath('Login') })
                this.$notification.info({
                  messageKey: 'groupeMutuel.existsError.msg',
                  headlineKey: 'groupeMutuel.existsError.head',
                })
                break
              default:
                this.$notification.error({ messageKey: 'groupeMutuel.error' })
                break
            }
          } finally {
            this.setGlobalLoading(false)
          }
        } else {
          this.$notification.info({ messageKey: 'groupeMutuel.infoError' })
        }
      }
    },
  },
}
</script>
