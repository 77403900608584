<template>
  <div>
    <h3 class="text-center">
      {{ $t('partnerPortal.downloadCenter') }}
    </h3>

    <div class="row">
      <div class="col-12 col-lg-6 offset-lg-3 mt-4 mb-2 mb-md-2">
        <dx-select
          :key="selectedLang"
          v-model="selectedLang"
          :label="$t('selectLanguage')"
          :options="availableLanguages"
          class="mb-4"
        />
      </div>
    </div>

    <dx-download-list
      v-for="(singleData, sectionKey, index) in files[selectedLang]"
      :key="sectionKey"
      tag-type="h4"
      :headline="$t(files.headlines[index])"
      :hide-all-if-empty="true"
      :items="singleData"
      external-url
      target="_blank"
      items-inline
      class="mt-5"
    />
  </div>
</template>

<script>
import { DxSelect } from '@sumcumo/dextra-frontend-component-lib-old'
import DxDownloadList from '~/components/DX-DownloadList'

const WEBSITE_URL = process.env.DEXTRA_WEBSITE_URL

export default {
  name: 'DownloadCenter',
  components: {
    DxDownloadList,
    DxSelect,
  },
  data() {
    return {
      selectedLang: this.$i18n.locale || 'de',
      availableLanguages: [
        { value: 'de', label: 'Deutsch' },
        { value: 'fr', label: 'Francais' },
        { value: 'it', label: 'Italiano' },
        { value: 'en', label: 'English' },
      ],
      files: {
        headlines: [
          'partnerPortal.downloads.conditions',
          'partnerPortal.downloads.factsheet',
          'partnerPortal.downloads.competition',
        ],
        de: {
          section1: [
            {
              name: 'AVB',
              url: `${WEBSITE_URL}/dokumente`,
            },
          ],
          section2: [
            {
              name: 'Factsheet',
              url: `${WEBSITE_URL}/dokumente`,
            },
          ],
          section3: [
            {
              name: 'Konkurrenzvergleich - Privatrechtsschutz',
              url: `${WEBSITE_URL}/assets/content/documents/Dextra_Konkurrenzvergleich_Privat.pdf`,
            },
            {
              name: 'Konkurrenzvergleich - Betriebsrechtsschutz',
              url: `${WEBSITE_URL}/assets/content/documents/Dextra_Konkurrenzvergleich_Business.pdf`,
            },
            {
              name: 'Konkurrenzvergleich - Medica',
              url: `${WEBSITE_URL}/assets/content/documents/Medica-Rechtsschutz-Konkurrenzvergleich_DE.pdf`,
            },
          ],
        },
        fr: {
          section1: [
            {
              name: 'CGA',
              url: `${WEBSITE_URL}/fr/documents`,
            },
          ],
          section2: [
            {
              name: 'Factsheet',
              url: `${WEBSITE_URL}/fr/documents`,
            },
          ],
          section3: [
            {
              name: 'Comparaison - Protection juridique privée',
              url: `${WEBSITE_URL}/assets/content/documents/Dextra_Comparaison_de_la_concurrence_privee.pdf`,
            },
            {
              name: 'Comparaison - Protection juridique entreprise',
              url: `${WEBSITE_URL}/assets/content/documents/Dextra_Comparaison_de_la_concurrence_entreprise.pdf`,
            },
            {
              name: 'Comparaison - Medica',
              url: `${WEBSITE_URL}/assets/content/documents/Medica-Rechtsschutz-Konkurrenzvergleich_FR.pdf`,
            },
          ],
        },
        it: {
          section1: [
            {
              name: 'CGA',
              url: `${WEBSITE_URL}/dokumente`,
            },
          ],
          section2: [
            {
              name: 'Factsheet',
              url: `${WEBSITE_URL}/dokumente`,
            },
          ],
          section3: [],
        },
        en: {
          section1: [
            {
              name: 'GTCI',
              url: `${WEBSITE_URL}/dokumente`,
            },
          ],
          section2: [
            {
              name: 'Factsheet',
              url: `${WEBSITE_URL}/dokumente`,
            },
          ],
          section3: [],
        },
      },
    }
  },
}
</script>
