<template>
  <div :class="cssClasses()" class="cmp-link-wrap">
    <a v-if="hasSubnav()" @click="openSubnav()">
      {{ $t(item.name) }}
      <span class="arrow" />
    </a>

    <a v-if="!hasSubnav() && !item.isExternal" :href="item.path">
      {{ $t(item.name) }}
    </a>

    <a
      v-if="!hasSubnav() && item.isExternal"
      :href="item.path"
      target="_blank"
      rel="noopener"
      @click="onClick"
    >
      {{ $t(item.name) }}
    </a>

    <ul v-if="isActiveSubnav" class="cmp-subnav-list">
      <li
        v-for="(subItem, idx) in getFilteredSubNav(item.subNav)"
        :key="`${subItem.name}_${idx}`"
        :class="subItem.class"
      >
        <a
          v-if="!subItem.isExternal && isLink(subItem.path)"
          :href="subItem.path"
        >
          {{ $t(subItem.name) }}
        </a>

        <a
          v-if="subItem.isExternal && isLink(subItem.path)"
          :href="subItem.path"
          target="_blank"
          rel="noopener"
          @click="onClick"
        >
          {{ $t(subItem.name) }}
        </a>

        <div v-if="!isLink(subItem.path)">
          {{ $t(subItem.name) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    linkName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActiveSubnav: false,
      outerLink: false,
      hideMobileSubnav: false,
    }
  },
  methods: {
    getFilteredSubNav(subNav) {
      return [...subNav].filter(this.navFilter)
    },
    navFilter(data) {
      const hideForLocale = data.hideForLocale === this.$i18n.locale
      const hideForEnv =
        data.hideForEnv && data.hideForEnv.includes(process.env.DEXTRA_ENV)
      return !hideForLocale && !hideForEnv
    },
    hasSubnav() {
      return this.item.subNav && this.item.subNav.length > 0
    },
    openSubnav() {
      this.isActiveSubnav = !this.isActiveSubnav
    },
    onClick() {
      this.$emit('click')
    },
    cssClasses() {
      return {
        'active-nav': this.isActiveSubnav,
        'has-subnav': this.hasSubnav(),
      }
    },
    isLink(path) {
      return path.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
