export default {
  Rechtschutz: {
    title: 'Rechtschutz',
    path: '/recht/',
    items: {
      TarifePrivat: {
        title: 'Tarife für Privatkunden',
        path: '',
      },
      TarifeUnternehmen: {
        title: 'Tarife für Unternehmen',
        path: '',
      },
      Bedarfsanalyse: {
        title: 'Bedarfsanalyse',
        path: '',
      },
      Faq: {
        title: 'FAQ',
        path: '',
      },
      Downloads: {
        title: 'Downloads',
        path: '',
      },
    },
  },
  Autoversicherung: {
    title: 'Autoversicherung',
    path: '',
    items: {
      Beitragsrechner: {
        title: 'Beitragsrechner',
        path: '',
      },
      Tarife: {
        title: 'Tarife',
        path: '',
      },
      Faq: {
        title: 'FAQ',
        path: '',
      },
      Downloads: {
        title: 'Downloads',
        path: '',
      },
    },
  },
  Unternehmen: {
    title: 'Unternehmen',
    path: '',
    items: {
      Rechtschutz: {
        title: 'Rechtschutz',
        path: '',
        subItems: {
          Team: {
            title: 'Das Team',
            path: '',
          },
          Jobs: {
            title: 'Jobs',
            path: '',
          },
          News: {
            title: 'News',
            path: '',
          },
        },
      },
      Autoversicherung: {
        title: 'Autoversicherung',
        path: '',
        subItems: {
          Team: {
            title: 'Das Team',
            path: '',
          },
          Jobs: {
            title: 'Jobs',
            path: '',
          },
          News: {
            title: 'News',
            path: '',
          },
        },
      },
    },
  },
  Service: {
    title: 'Service',
    path: '',
    items: {
      Kontakt: {
        title: 'Kontakt',
        path: '',
      },
      Rechtsberatung: {
        title: 'Rechtsberatung / Rechtsfall melden',
        path: '',
      },
      AutoSchaden: {
        title: 'Auto Schaden melden',
        path: '',
      },
      Kundenkonto: {
        title: 'Kundenkonto',
        path: '',
      },
    },
  },
}
