<template>
  <div>
    <dx-loading-overlay v-if="loading" />
    <template v-else>
      <div v-if="hasOwnContracts">
        <h3 class="mb-3 mb-md-4">
          {{ $t('policiesHead') }}
        </h3>
        <dx-contract
          v-for="(contract, index) in contracts"
          :key="index"
          :is-open="firstItemActive(index)"
          :is-own-contract="true"
          :contract="contract"
          class="pb-2"
          :load-contract-details="loadContracts"
        />
      </div>
      <div v-if="hasInsuringContracts">
        <h3 :class="{ 'mt-5': contracts.length !== 0 }" class="mb-3 mb-md-4">
          {{ $t('coInsuredPoliciesHead') }}
        </h3>
        <dx-contract
          v-for="(contract, index) in insuringContracts"
          :key="index"
          :contract="contract"
          class="mb-2"
          :load-contract-details="loadInsuringContracts"
        />
      </div>
    </template>
  </div>
</template>

<script>
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import contractsQuery from '~/domains/contract/__gql__/queries/contracts.gql'
import insuringContractsQuery from '~/domains/contract/__gql__/queries/insuringContracts.gql'

import { SET_HAS_CONTRACTS } from '~/domains/application/__store__/mutations'
import DxContract from '../DX-Contract'

export default {
  name: 'DxContractsOverview',
  components: {
    DxLoadingOverlay,
    DxContract,
  },
  mixins: [apolloErrorsMixin],
  data() {
    return {
      contracts: [],
      insuringContracts: [],
      contractsLoading: true,
      insuringContractsLoading: true,
      claims: [],
      insuringClaims: [],
      page: 1,
      perPage: 10,
    }
  },
  computed: {
    loading() {
      return this.contractsLoading || this.insuringContractsLoading
    },
    hasOwnContracts() {
      return this.contracts.length > 0
    },
    hasInsuringContracts() {
      return this.insuringContracts.length > 0
    },
    openClaims() {
      const openContractClaims = this.claims.filter(
        (claim) => claim.status === 'OPEN' || claim.status === 'NEW',
      )
      const openInsuringContractClaims = this.insuringClaims.filter(
        (claim) => claim.status === 'OPEN' || claim.status === 'NEW',
      )
      return [...openContractClaims, ...openInsuringContractClaims]
    },
  },
  methods: {
    firstItemActive(index) {
      return index === 0
    },
    async loadContracts() {
      try {
        await this.$apollo.queries.contracts.refetch()
      } catch (e) {
        console.error(e)
      }
    },
    async loadInsuringContracts() {
      try {
        await this.$apollo.queries.insuringContracts.refetch()
      } catch (e) {
        console.error(e)
      }
    },
  },
  apollo: {
    contracts: {
      query: contractsQuery,
      fetchPolicy: 'network-only',
      variables: {
        withDocuments: true,
        page: 1,
        perPage: 10,
      },
      result({ data }) {
        let claims = []
        // is done to order the contracts "by date"
        if (data && data.contracts) {
          this.contracts = data.contracts.map((contract) => {
            claims = [...claims, ...contract.claims]
            return { ...contract, insuring: true }
          })
        }
        this.claims = claims
        this.contractsLoading = false
        this.$emit('claimsLoaded', {
          claims: this.openClaims,
          loading: this.loading,
        })
        if (this.contracts.length > 0) {
          this.$store.commit(SET_HAS_CONTRACTS, true)
        }
      },
      error(error) {
        this.contractsLoading = false
        this.contracts = []
        this.$notification.error({ messageKey: 'notifications.error.message' })
        throw error
      },
    },
    insuringContracts: {
      query: insuringContractsQuery,
      fetchPolicy: 'network-only',
      result({ data }) {
        let insuringClaims = []
        // is done to order the insuringContracts "by date"
        if (data && data.insuringContracts) {
          this.insuringContracts = data.insuringContracts.map((contract) => {
            insuringClaims = [...insuringClaims, ...contract.claims]
            return contract
          })
        }
        this.insuringClaims = insuringClaims
        this.insuringContractsLoading = false
        this.$emit('claimsLoaded', {
          claims: this.openClaims,
          loading: this.loading,
        })
        if (this.insuringContracts.filter((ic) => ic.insuring).length > 0) {
          this.$store.commit(SET_HAS_CONTRACTS, true)
        }
      },
      error(err) {
        const sanitizedErrors = this.handleApolloError(err)
        this.insuringContractsLoading = false
        this.insuringContracts = []

        if (sanitizedErrors.networkError.code === 4) {
          this.$notification.error({ messageKey: 'invalidCustomerMail' })
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
        throw err
      },
    },
  },
}
</script>
