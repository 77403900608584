<template>
  <div>
    <dx-open-offer-list-item
      v-for="item in offers"
      :key="item.id"
      :offer="item"
      :load-offer="loadOffer"
      :reject-offer="rejectOffer"
      :reactivate-offer="reactivateOffer"
      :initially-open="initiallySelectedOffer === item.id"
    />

    <div v-if="notFound" class="content-block">
      {{ $t('partnerPortal.notFound') }}
    </div>
    <dx-loading-overlay v-if="isLoading" />
  </div>
</template>

<script>
import DxOpenOfferListItem from '~/domains/partnerPortal/components/DX-OpenOfferListItem'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'

export default {
  name: 'OffersList',
  components: {
    DxOpenOfferListItem,
    DxLoadingOverlay,
  },
  props: {
    offers: {
      type: Array,
      default() {
        return []
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadOffer: {
      type: Function,
      default: () => null,
    },
    rejectOffer: {
      type: Function,
      default: () => null,
    },
    reactivateOffer: {
      type: Function,
      default: () => null,
    },
    initiallySelectedOffer: {
      type: String,
      default: null,
    },
  },
  computed: {
    notFound() {
      return this.offers.length <= 0 && !this.isLoading
    },
  },
}
</script>
