<template>
  <transition name="fadeIn">
    <div v-if="hasTariff" class="tariffWrapper">
      <div :class="['detailsWrapper', detailsVisible && 'showDetails']">
        <div class="header">
          <h4 class="productHeadline">{{ t('variantName') }}</h4>
          <button class="closeDetailsButton" @click="toggleDetails">
            <close-icon :width="20" :height="20" />
          </button>
        </div>
        <dl v-if="selectedPremia" data-testid="tariffBoxPremias">
          <template v-for="premia in selectedPremia">
            <dt
              :key="`module-${premia.moduleKey}`"
              data-testid="tariffBoxPremiaLabel"
            >
              {{ moduleLabel(premia) }}
            </dt>
            <dd :key="`premium-${premia.moduleKey}`">
              {{ swissPrice(premia.premium.valueCents / 100) }}
            </dd>
          </template>
          <template v-if="discountRate">
            <dt>
              {{
                t(
                  `modules.${
                    isDiscountRecurring ? 'discountRecurring' : 'discountOnce'
                  }.label`,
                )
              }}
            </dt>
            <dd>{{ discountRate }}%</dd>
          </template>
        </dl>
      </div>
      <div v-if="tariff" class="total">
        <dl class="totalPrice" @click="toggleDetails">
          <dt>{{ paymentIntervalString }}</dt>
          <dd>
            {{ swissPrice(tariff.totalPremium.valueCents / 100) }}
          </dd>
        </dl>
        <p :class="['taxHint', detailsVisible && 'detailsVisible']">
          {{ t('tariff.taxHint') }}
        </p>
        <button
          v-show="!detailsVisible"
          class="openDetailsButton"
          @click="toggleDetails"
        >
          <chevron-down-icon :height="24" :width="24" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import ChevronDownIcon from '@/components/icons/ChevronDownIcon'
import CloseIcon from '@/components/icons/CloseIcon'

import { useScipTranslationWithFallback } from '../utils'

export default {
  name: 'DxScipSalesTariffBox',
  components: { ChevronDownIcon, CloseIcon },
  props: {
    formContext: {
      type: Object,
      required: true,
    },
    tariff: {
      type: Object,
      default: () => ({}),
    },
    paymentInterval: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  data() {
    return {
      detailsVisible: false,
    }
  },

  computed: {
    discountRate() {
      const onceRate =
        this.tariff && this.tariff.computedValues.discount_once_rate
      const recurringRate =
        this.tariff && this.tariff.computedValues.discount_recurring_rate
      const rate = onceRate || recurringRate || null
      return rate ? parseFloat(rate) : null
    },

    isDiscountRecurring() {
      const recurringRate =
        this.tariff && this.tariff.computedValues.discount_recurring_rate
      return Boolean(recurringRate)
    },

    selectedPremia() {
      if (!this.tariff || !this.tariff.premia) {
        return []
      }
      return this.sortPremia(
        this.tariff.premia.filter((premium) => premium.premium.valueCents > 0),
      )
    },

    hasTariff() {
      return this.tariff?.totalPremium?.valueCents > 0
    },

    paymentIntervalString() {
      return this.paymentInterval ? this.t(`${this.paymentInterval}`) : ''
    },
  },

  methods: {
    sortPremia(premia) {
      const stepItems = Object.values(
        this.formContext.schema?.steps ?? {},
      ).flatMap((step) => {
        if (step.itemIds) {
          return step.itemIds.flatMap(
            (itemId) => this.formContext.schema?.items[itemId],
          )
        }
        return []
      })

      const moduleKeys = this.findAllModuleSelectionFieldsModuleKeys(stepItems)

      const getPremiumIndex = (premium) => {
        const index = moduleKeys.indexOf(premium.moduleKey)
        return index >= 0 ? index : 99
      }
      const sortedPremia = [...premia]
      sortedPremia.sort((a, b) => getPremiumIndex(a) - getPremiumIndex(b))
      return sortedPremia
    },
    getItemsByIds(itemIds) {
      return itemIds.flatMap((itemId) => this.formContext.schema?.items[itemId])
    },

    findAllModuleSelectionFieldsModuleKeys(items) {
      return items.flatMap((item) => {
        if (item.__typename === 'ModuleSelectionField')
          return item.modules.map((module) => module.key)
        if (item.__typename === 'SummaryItem') {
          return this.findAllModuleSelectionFieldsModuleKeys(
            this.getItemsByIds(item.editableItemIds),
          )
        }
        return []
      })
    },

    toggleDetails() {
      this.detailsVisible = !this.detailsVisible
    },

    moduleLabel(premia) {
      const legacyKey = `modules.${premia.moduleKey}.label`
      // module.label is wrong, here are premia not modules
      const moduleLabel = Object.values(this.formContext.schema.items ?? {})
        .filter((item) => item.__typename === 'ModuleSelectionField')
        .flatMap((moduleSelectionField) => moduleSelectionField.modules)
        .find((module) => module.key === premia.moduleKey)?.label
      return this.te(legacyKey) ? this.t(legacyKey) : moduleLabel
    },

    swissPrice(number) {
      return new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
      }).format(number)
    },

    swissPriceNegative(number) {
      return new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
      })
        .formatToParts(number)
        .map(({ type, value }) => {
          switch (type) {
            case 'currency':
              return `${value} `
            default:
              return value
          }
        })
        .reduce((string, part) => string + part)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

$font-family-old: 'Roboto', sans-serif;

.tariffWrapper {
  display: grid;
  position: fixed;
  bottom: rem(64);
  left: 0;
  padding: rem(20) rem(40) rem(8);
  width: 100%;
  grid-gap: rem(24);
  background: #fff;
  color: $color-paynes-grey;
  z-index: 12;

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: rem(12);
    border: rem(2) dotted $color-paynes-grey;
    border-radius: rem(2);
    padding: rem(28) rem(20);
    background: transparent;
    z-index: 4;
  }
}

.header {
  display: flex;
  margin-bottom: rem(16);
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin-bottom: rem(24);
  }
}

.productHeadline {
  margin: 0;
  color: $color-indigo;
  font-family: $font-family-old;
  font-size: rem(24);
  line-height: rem(27);
}

dl {
  display: grid;
  margin: 0;
  grid-column-gap: rem(4);
  grid-template-columns: 1fr 1fr;
  color: $color-paynes-grey;

  @include media-breakpoint-up(lg) {
    grid-column-gap: rem(10);
    grid-template-columns: repeat(2, 1fr);
    font-size: rem(16);
    line-height: rem(24);
  }
}

.detailsWrapper {
  display: none;
  position: relative;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.showDetails {
  display: block;
}

.closeDetailsButton,
.openDetailsButton {
  position: absolute;
  right: rem(32) * -1;
  border: none;
  background: transparent;
  color: $color-turquoise-surf;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

dt {
  grid-column: 1;
  font-weight: normal;
  white-space: nowrap;
}

dd {
  margin: 0;
  grid-column: 2;
  justify-self: end;
  font-variant-numeric: tabular-nums;
}

.total {
  display: flex;
  position: relative;
  min-height: rem(32);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.totalPrice {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  dt {
    justify-self: end;
  }

  dd {
    color: $color-turquoise-surf;
    font-family: $font-family-old;
    font-weight: bold;
    font-size: clamp(#{rem(20)}, calc(0.4vw + 1rem), #{rem(30)});
    line-height: clamp(#{rem(24)}, calc(0.5vw + 1rem), #{rem(36)});
  }
}

.taxHint {
  display: none;
  min-width: 100%;
  text-align: right;

  &.detailsVisible {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    display: block;
    font-size: rem($font-size);
  }
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transform: translateY(0);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
