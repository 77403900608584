<template>
  <div class="d-flex">
    <div
      v-for="step in steps"
      :key="step"
      :class="{ active: activeStep == step }"
      class="step-container"
    >
      <span
        :class="['step', { interactive: isInteractive }]"
        @click="$emit('jump-to-step', step)"
      >
        {{ step }}
      </span>
      <span v-if="step != steps" class="dots" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progressbar',
  props: {
    steps: {
      type: Number,
      default: 1,
    },
    activeStep: {
      type: Number,
      default: 1,
    },
    isInteractive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
