/* eslint-disable no-param-reassign, no-unused-vars */
import getDefaultState from './initialState'

export const LOADING = 'LOADING'
export const RESET_STORE_APPLICATION = 'RESET_STORE_APPLICATION'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_HAS_CONTRACTS = 'SET_HAS_CONTRACTS'
export const SET_CURRENT_LAWYER = 'SET_CURRENT_LAWYER'
export const SET_CURRENT_BROKER = 'SET_CURRENT_BROKER'
export const SET_PENDING_UPLOADS = 'SET_PENDING_UPLOADS'
export const RESET_PENDING_UPLOADS = 'RESET_PENDING_UPLOADS'
export const SET_CURRENT_USER_USERNAME = 'SET_CURRENT_USER_USERNAME'

export default {
  [RESET_STORE_APPLICATION](state) {
    Object.assign(state, getDefaultState())
  },
  LOADING(state, bool) {
    state.loading = bool
  },
  [SET_PENDING_UPLOADS](state, count) {
    state.pendingUploads += count
  },
  [RESET_PENDING_UPLOADS](state) {
    state.pendingUploads = 0
  },
  [SET_HAS_CONTRACTS](state, hasContracts) {
    state.hasContracts = hasContracts
  },
  [SET_CURRENT_USER](state, { currentUser }) {
    const newCurrentUser = JSON.parse(JSON.stringify(currentUser))
    state.currentUser = { ...state.currentUser, ...newCurrentUser }
  },
  [SET_CURRENT_LAWYER](state, { currentLawyer }) {
    const newCurrentLawyer = JSON.parse(JSON.stringify(currentLawyer))
    state.currentLawyer = { ...state.currentLawyer, ...newCurrentLawyer }
    state.currentUser.username = currentLawyer.username
  },
  [SET_CURRENT_BROKER](state, { currentBroker }) {
    const newCurrentBroker = JSON.parse(JSON.stringify(currentBroker))
    state.currentBroker = { ...state.currentBroker, ...newCurrentBroker }
    state.currentUser.username = newCurrentBroker.username
  },
  [SET_CURRENT_USER_USERNAME](state, username) {
    state.currentUser.username = username
    if (state.currentLawyer.username) {
      state.currentLawyer.username = username
    }
    if (state.currentBroker.username) {
      state.currentBroker.username = username
    }
  },
}
