<script>
import { RESET_STORE_APPLICATION } from '~/domains/application/__store__/mutations'
import { RESET_STORE_DAMAGEREPORT } from '~/domains/contract/claim/__store__/mutations'
import { CHANGE_PORTAL } from '~/domains/authentication/__store__/actions'

export default {
  data() {
    return {
      portals: [
        {
          id: 'CUSTOMER_CENTER',
          name: 'customerPortalTitle',
          route: 'SelfService',
          className: 'customer',
        },
        {
          id: 'LAWYER_PORTAL',
          name: 'lawyerPortalTitle',
          route: 'LawyerPortal',
          className: 'lawyer',
        },
        {
          id: 'BROKER_PORTAL',
          name: 'partnerPortalTitle',
          route: 'PartnerPortal',
          className: 'partner',
        },
      ],
    }
  },
  computed: {
    availablePortals() {
      const portalAccess = this.$store.state.authentication.portals
      return this.portals.filter((portal) => portalAccess.includes(portal.id))
    },
    currentPortal() {
      return this.$store.state.authentication.currentPortal
    },
  },
  methods: {
    async enterPortal(portal) {
      if (this.currentPortal === portal) {
        return
      }

      this.$store.commit(RESET_STORE_APPLICATION)
      this.$store.commit(RESET_STORE_DAMAGEREPORT)

      try {
        await this.$store.dispatch(CHANGE_PORTAL, {
          variables: { portal },
          apollo: this.$apollo.provider.defaultClient,
        })

        if (this.$store.getters.isExternalLawyer) {
          this.$router.push({ path: this.localePath('LawyerPortal') })
        } else if (this.$store.getters.isBroker) {
          this.$router.push({ path: this.localePath('PartnerPortal') })
        } else {
          this.$router.push({ path: this.localePath('SelfService') })
        }
      } catch (error) {
        console.error(error)
        this.$router.push({
          path: 'Login',
          query: { logout: true, reason: 'loginError' },
        })
      } finally {
        this.setGlobalLoading(false)
      }
    },
  },
}
</script>
