import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

const isInViewport = (element) => {
  const bounding = element.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const scrollIntoView = async (element) => {
  if (element && !isInViewport(element)) {
    element.scrollIntoView()
    window.scrollBy(0, -150)
  }
}

const scrollFirstErrorIntoView = async () => {
  const element = document.querySelectorAll('.error-container')[0]
  await scrollIntoView(element)
}

export default scrollFirstErrorIntoView
