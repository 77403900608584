const jwtTools = {
  parseToken: (jwt) => {
    if (!jwt) {
      return {}
    }
    let tokenData = {}
    const tokenFragments = jwt.split('.')
    tokenData = {
      ...JSON.parse(window.atob(tokenFragments[0])),
      ...JSON.parse(window.atob(tokenFragments[1])),
    }
    return tokenData
  },
  getRoles: (jwt) => jwtTools.parseToken(jwt).roles || [],
  getPortals: (jwt) => jwtTools.parseToken(jwt).available_portals || [],
  getExpiryDate: (jwt) => jwtTools.parseToken(jwt).exp || null,
  isValid: (jwt) => {
    const exp = jwtTools.getExpiryDate(jwt)
    if (!exp || Number.isNaN(exp)) {
      return false
    }
    const to = exp * 1000
    const now = Date.now()
    return to - now > 0
  },
}

export default jwtTools
