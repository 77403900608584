<template>
  <div class="container-fluid">
    <div class="content-wrap-small content-pad">
      <div>
        <div class="mt-5 mb-4 mb-md-5">
          <h3>
            {{ $t('registerPage.welcomeText') }}
          </h3>
          <p>
            {{ $t('registerPage.welcomeTextSub') }}
          </p>
        </div>
        <h4>
          {{ $t('login') }}
        </h4>
        <form @submit.prevent>
          <dx-input
            v-model="personData.email"
            v-validate.disable="'required|email'"
            :label="$t('email')"
            :disabled="true"
            :error="errors.first('invalidEmail')"
            data-vv-name="invalidEmail"
            name="email"
            class="mb-4"
          />
          <dx-input
            v-model="personData.password"
            v-validate="`required|min:8`"
            :label="$t('password')"
            :error="errors.first('password')"
            data-vv-name="password"
            type="password"
            class="mb-4"
            autocomplete="current-password"
          />
          <dx-input
            v-model="personData.passwordRepeat"
            v-validate="`required|min:8|match_strings:${personData.password}`"
            :label="$t('passwordRepeat')"
            :error="errors.first('newPasswordRepeat')"
            data-vv-name="newPasswordRepeat"
            type="password"
            class="mb-4"
            autocomplete="new-password"
          />

          <h4>
            {{ $t('personalData') }}
          </h4>

          <dx-radio-list
            v-model="personData.salutation"
            v-validate.disable="'required'"
            :options="salutationData"
            :error="errors.first('salutation')"
            name="salutation"
            class="mb-4"
            data-vv-name="salutation"
          />
          <dx-input
            v-model="personData.firstName"
            v-validate.disable="'required|min:2'"
            :error="errors.first('firstName')"
            :label="$t('firstName')"
            class="mb-4"
            data-vv-name="firstName"
            autocomplete="given-name"
          />

          <dx-input
            v-model="personData.lastName"
            v-validate.disable="'required|min:2'"
            :error="errors.first('lastName')"
            :label="$t('lastName')"
            class="mb-4"
            data-vv-name="lastName"
            autocomplete="family-name"
          />

          <div class="d-flex mb-4">
            <dx-input
              v-model="personData.street"
              v-validate.disable="'required|min:2'"
              :label="$t('street')"
              :error="errors.first('street')"
              class="mr-3 w-75"
              data-vv-name="street"
              autocomplete="address-line1"
            />
            <dx-input
              v-model="personData.number"
              v-validate.disable="'required|min:1'"
              :label="$t('houseNumber')"
              :error="errors.first('streetNumber')"
              class="w-40"
              data-vv-name="streetNumber"
              autocomplete="address-line2"
            />
          </div>

          <dx-zip-to-city-input
            :zip-input="personData.zip"
            :city-input="personData.city"
            @update:city="(city) => (personData.city = city)"
            @update:zip="(zip) => (personData.zip = zip)"
          />

          <dx-input
            v-model="personData.dateOfBirth"
            v-validate.disable="'required|inThePastGermanFormat'"
            :error="errors.first('invalidDate')"
            :label="$t('dateofbirth')"
            data-vv-name="invalidDate"
            is-date-input
            type="date"
            class="mb-4"
          />

          <dx-input
            v-model="personData.phone"
            v-validate.disable="
              `${personData.mobile.trim() ? '' : 'required|min:4'}`
            "
            :error="errors.first('mobileOrPhoneNumber')"
            :label="$t('telephone')"
            data-vv-name="mobileOrPhoneNumber"
            class="mb-4"
            autocomplete="tel"
          />

          <dx-input
            v-model="personData.mobile"
            v-validate.disable="
              `${personData.phone.trim() ? '' : 'required|min:4'}`
            "
            :error="errors.first('mobileOrPhoneNumber')"
            :label="$t('mobilePhone')"
            data-vv-name="mobileOrPhoneNumber"
            class="mb-4"
            autocomplete="tel"
          />

          <div class="text-right">
            <dx-button
              :text="$t('submit')"
              class="secondary-link btn-blank"
              @click="sendFormData"
            >
              {{ $t('submit') }}
            </dx-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import isPast from 'date-fns/isPast'
import {
  DxButton,
  DxInput,
  DxRadioList,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxZipToCityInput from '~/components/DX-ZipToCityInput/index'
import { REGISTER_AND_ACCEPT_CONTRACT_INVITATION } from '~/domains/authentication/__store__/actions'
import { RESET_STORE } from '~/domains/application/__store__/actions'

import loadingMixin from '~/javascripts/mixins/loadingMixin'
import invitationForTokenQuery from '~/domains/user/__gql__/queries/invitationForToken.gql'

export default {
  name: 'DxRegistration',
  components: {
    DxInput,
    DxRadioList,
    DxButton,
    DxZipToCityInput,
  },
  mixins: [loadingMixin],
  props: {
    invitationToken: {
      type: String,
      default: 'no token provided',
    },
  },
  data() {
    return {
      personData: {
        salutation: 'MALE',
        dateOfBirth: '',
        mobile: '',
        phone: '',
      },
      salutationData: [
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
        {
          text: this.$t('male'),
          id: 'MALE',
        },
      ],
    }
  },
  created() {
    this.setGlobalLoading(true)
  },
  mounted() {
    this.$store.dispatch(RESET_STORE)
  },
  methods: {
    async sendFormData() {
      const collectValidation = await this.$validator.validateAll()
      if (collectValidation) {
        const profileData = {
          salutation: this.personData.salutation,
          firstName: this.personData.firstName,
          lastName: this.personData.lastName,
          dateOfBirth: this.personData.dateOfBirth,
          street: this.personData.street,
          number: this.personData.number,
          zip: this.personData.zip,
          city: this.personData.city,
          phone: this.personData.phone,
          mobile: this.personData.mobile,
          password: this.personData.password,
          passwordRepeat: this.personData.passwordRepeat,
          locale: this.$i18n.locale.toUpperCase(),
        }
        this.setGlobalLoading(true)
        try {
          const variables = {
            invitationToken: this.invitationToken,
            profileData,
          }

          await this.$store.dispatch(REGISTER_AND_ACCEPT_CONTRACT_INVITATION, {
            variables,
            contractId: this.personData.contract.id,
            apollo: this.$apollo.provider.defaultClient,
          })

          this.$notification.success({ messageKey: 'invitation.acceptSuccess' })
          this.$router.push(this.localePath('SelfService'))
        } catch (error) {
          this.$notification.error({ messageKey: 'invitation.acceptError' })
          console.error(error)
        } finally {
          this.setGlobalLoading(false)
        }
      }
    },
  },
  apollo: {
    invitationForToken: {
      query: invitationForTokenQuery,
      variables() {
        return {
          token: this.invitationToken,
        }
      },
      result({ data }) {
        this.setGlobalLoading(false)
        const { isUserKnown, expiredAt } = data.invitationForToken
        if (isUserKnown || isPast(expiredAt)) {
          this.$store.$router.push({
            path: this.localePath('Login'),
            query: { invitationToken: this.invitationToken },
          })
        } else {
          this.personData = {
            ...this.personData,
            ...data.invitationForToken,
            ...isUserKnown,
          }
        }
      },
      error(error) {
        this.setGlobalLoading(false)
        throw error
      },
    },
  },
}
</script>
