<template>
  <div class="zipCityWrapper">
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>

    <dx-input
      :id="`scip-${item.id}`"
      v-model="zip"
      :label="translatedZipLabel"
      :disabled="item.readonly"
      :error="translatedZipError"
      type="text"
      class="item"
      @input="onZipInput"
      @complete="onZipComplete"
      @enter="onZipComplete"
    />

    <dx-select
      v-if="citiesForZipCode.length >= 1"
      :value="city"
      :options="cityOptions"
      :label="translatedCityLabel"
      :error="translatedCityError"
      :disabled="citiesForZipCode.length === 1"
      class="item"
      @input="updateCity"
    />
    <dx-input
      v-else
      :value="city"
      :label="translatedCityLabel"
      :error="translatedCityError"
      :disabled="true"
      class="item"
      autocomplete="off"
    />
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import { DxInput, DxSelect } from '@sumcumo/dextra-frontend-component-lib'

import CitiesByZipQuery from '@/components/DX-ZipToCityInput/__gql__/queries/citiesByZip.gql'
import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DXZipCityAdapter',
  components: {
    DxDescriptionLabel,
    DxInput,
    DxSelect,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  data() {
    return {
      zip: this.value?.zip ?? '',
      city: this.value?.city ?? '',
      citiesForZipCode: [],
      isZipComplete: true,
      fetchingCities: 0,
      internalError: null,
    }
  },

  computed: {
    translatedZipLabel() {
      return this.te(`${this.translationKeyPrefix}.label.zip`)
        ? this.t(`${this.translationKeyPrefix}.label.zip`)
        : this.t('zipCity.zip')
    },

    translatedCityLabel() {
      return this.te(`${this.translationKeyPrefix}.label.city`)
        ? this.t(`${this.translationKeyPrefix}.label.city`)
        : this.t('zipCity.city')
    },

    cityOptions() {
      const defaultOption = {
        value: '',
        label: this.t('selectOption'),
        caption: true,
      }
      return [
        defaultOption,
        ...this.citiesForZipCode.map((city) => ({
          value: city.name,
          label: city.name,
        })),
      ]
    },

    translatedZipError() {
      if (this.internalError) {
        return this.internalError
      }

      if (this.error?.zip) {
        return this.t(`errors.zipCity.zip.${this.error.zip.type}`, {
          ...this.error.params,
          label: this.translatedZipLabel,
        })
      }

      return null
    },

    translatedCityError() {
      if (!this.error?.city) {
        return null
      }

      return this.t(`errors.zipCity.city.${this.error.city.type}`, {
        ...this.error.city.params,
        label: this.translatedCityLabel,
      })
    },
  },

  watch: {
    value: {
      handler(newValue) {
        this.city = newValue.city
        this.isZipComplete = true

        // Only update our internal values from external when there was no internal error
        // This would void the internal error, we expect user input instead
        if (this.zip !== newValue.zip && !this.internalError) {
          this.zip = newValue.zip
          this.fetchCities()
        }
      },
    },
  },

  async created() {
    await this.fetchCities()
  },

  methods: {
    // eslint-disable-next-line complexity, max-statements
    async fetchCities() {
      if (
        !this.zip ||
        this.zip.length < 4 ||
        Number.isNaN(this.zip) ||
        this.isLiechtenstein()
      ) {
        // This is no valid and supported zip: void the city
        this.citiesForZipCode = []
        await this.updateCity('')
        return
      }

      this.fetchingCities += 1
      let cities
      try {
        const { data } = await this.$apollo.query({
          query: CitiesByZipQuery,
          variables: {
            zips: [this.zip],
            locale: this.$i18n.locale.toUpperCase(),
          },
        })
        // eslint-disable-next-line prefer-destructuring
        cities = data.cities
      } catch (e) {
        console.error(e)
      }
      this.fetchingCities -= 1

      if (cities !== undefined) {
        this.citiesForZipCode = [...cities]
        if (cities.length <= 1) {
          await this.updateCity(cities[0] ? cities[0].name : '')
        }
      }
    },

    async updateCity(city) {
      if (this.city !== city) {
        this.city = city
        this.emitAndValidate()
      }
    },

    // eslint-disable-next-line complexity
    async emitAndValidate() {
      if (!this.isZipComplete) {
        // We are in the middle of an input, do not emit
        return
      }
      if (this.fetchingCities) {
        // We are loading the cities, emit without city
        this.$emit('input', { zip: this.zip, city: '' })
        return
      }

      if (this.isLiechtenstein()) {
        // Liechtenstein is not supported:
        // Internally, issue an error
        // Externally, emit an empty value to prevent submission
        this.internalError = this.t('errors.zipCity.zip.liechtenstein', {
          label: this.translatedZipLabel,
        })
        this.$emit('input', { zip: '', city: '' })
        return
      }

      if (this.zip && this.citiesForZipCode.length === 0) {
        // This zip is not valid:
        // Internally, issue an error
        // Externally, emit an empty value to prevent submission
        this.internalError = this.t('errors.zipCity.zip.invalid', {
          label: this.translatedZipLabel,
        })
        this.$emit('input', { zip: '', city: '' })
        return
      }

      // The zip is valid: emit
      this.internalError = null
      this.$emit('input', {
        zip: this.zip,
        city: this.city,
      })

      // Finally, issue a standard Scip-Sales validation,
      // but ensure the value is emitted before emitting validation
      await this.$nextTick()
      this.$emit('validate', { dataKey: this.item.dataKey, propKey: 'zip' })
      this.$emit('validate', { dataKey: this.item.dataKey, propKey: 'city' })
    },

    async onZipInput() {
      // Each zip user input issues that the zip is not complete, but update cities
      this.isZipComplete = false
      await this.fetchCities()
    },

    async onZipComplete() {
      // Blurring the zip field or pressing enter issues that zip is complete
      this.isZipComplete = true
      await this.emitAndValidate()
    },

    isLiechtenstein() {
      const liechtensteinZipCodes = this.t('liechtensteinZipCodes')
        .split(',')
        .map((zipCode) => zipCode.trim())
      return liechtensteinZipCodes.includes(this.zip)
    },
  },
}
</script>
<style lang="scss" scoped>
.item {
  margin-bottom: rem(16);

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
