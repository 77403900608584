<template>
  <div class="container-fluid">
    <div class="content-wrap-small content-pad">
      <div class="d-flex justify-content-center">
        <dx-progressbar
          :steps="5"
          :active-step="3"
          class="mb-5 w-100 dx-w-md-75"
        />
      </div>

      <div class="row justify-content-between">
        <div class="col-md-12">
          <h3>{{ $t('docUploadHead') }}</h3>
          <p>{{ $t('file.sizeLimit') }}</p>
          <p>{{ $t('docUploadSub') }}</p>
          <dx-upload
            v-for="(value, index) in $store.state.claim.attachmentTypes"
            :key="`${value}_${index}`"
            ref="upload"
            :label="getDocumentTranslation(value)"
            :on-upload-success="(file) => onUploadSuccess(file, value)"
            :on-upload-remove="(id) => onUploadRemove(id, value)"
            :initial-files="attachments[value]"
            :show-upload-button="false"
            class="mb-4"
          />
        </div>
      </div>

      <dx-bottom-bar
        :show-previous-button="true"
        class="mt-5"
        @back="uploadFiles(true)"
        @forward="uploadFiles"
      />
    </div>
  </div>
</template>

<script>
import DxProgressbar from '~/components/DX-Progressbar'
import DxUpload from '~/components/DX-Upload'
import DxBottomBar from '~/components/DX-BottomBar'
import { SET_REPORT } from '~/domains/contract/claim/__store__/mutations'

export default {
  name: 'DxDocumentUpload',
  components: {
    DxProgressbar,
    DxUpload,
    DxBottomBar,
  },
  props: {
    nextStepPath: {
      type: String,
      default: '',
    },
    previousStepPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      attachments: {},
      successCount: 0,
    }
  },
  created() {
    this.syncAttachments()
  },
  methods: {
    syncAttachments() {
      this.$store.state.claim.report.attachments.forEach((attachment) => {
        this.attachments[attachment.kind] = attachment.upload
      })
    },
    getDocumentTranslation(type) {
      return this.$t(`factDocumentLabel.${type}`)
    },
    async uploadFiles(backwards) {
      const results = []
      if (this.$refs.upload) {
        for (let i = 0; i < this.$refs.upload.length; i += 1) {
          const component = this.$refs.upload[i]
          results.push(component.uploadFiles())
        }
      }
      const success = await Promise.all(results)

      if (!success.includes(false)) {
        this.goToNextStep(backwards)
      }
    },
    onUploadRemove(id, kind) {
      if (this.attachments[kind]) {
        const filteredAttachments = this.attachments[kind].filter(
          (attachment) => attachment.id !== id,
        )
        if (filteredAttachments.length > 0) {
          this.attachments[kind] = filteredAttachments
        } else {
          delete this.attachments[kind]
        }
      }
    },
    onUploadSuccess({ id, name }, kind) {
      const existingAttachments = Array.from(this.attachments[kind] || [])
      existingAttachments.push({ id, name })
      this.attachments[kind] = existingAttachments
    },
    goToNextStep(backwards) {
      const attachments = []
      Object.entries(this.attachments).forEach(([kind, upload]) => {
        attachments.push({ kind, upload })
      })
      const report = {
        ...this.report,
        attachments,
      }
      this.$store.commit({
        type: SET_REPORT,
        report,
      })
      if (!backwards) {
        this.$router.push({ path: this.localePath(this.nextStepPath) })
      } else {
        this.$router.push({ path: this.localePath(this.previousStepPath) })
      }
    },
  },
}
</script>
