<template>
  <div class="wrapper">
    <navigation-startpage
      class="navigation-startpage-wrap"
      :external-logo-link="websiteUrl"
    >
      <template slot="locale-switch">
        <locale-switch
          :route-getter="localizedRouteGetter"
          :locales="availableLocales"
          :active-locale="$i18n.locale"
        />
      </template>
    </navigation-startpage>

    <dx-notifications />
    <main class="content">
      <nuxt />
    </main>
    <dx-footer is-small-theme :bottom-copy-links="localizedFooterItems">
      <locale-switch
        :route-getter="localizedRouteGetter"
        :locales="availableLocales"
        :active-locale="$i18n.locale"
      />
    </dx-footer>
    <dx-info-bar modula-style />
  </div>
</template>

<script>
import {
  DxFooter,
  LocaleSwitch,
  NavigationStartpage,
} from '@sumcumo/dextra-frontend-component-lib'

import DxInfoBar from '~/components/DX-InfoBar'
import { handleScipSalesPreview } from '~/javascripts/scipSales'

export default {
  name: 'ModulaStylePlain',
  components: {
    DxFooter,
    DxInfoBar,
    LocaleSwitch,
    NavigationStartpage,
  },
  data() {
    return {
      visibility: true,
      websiteUrl: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  computed: {
    availableLocales() {
      let { locales } = this.$i18n
      const isOfferPage = /Offer.*___[de|fr|it]/.test(this.$route.name)
      const isPartnerPortalPage = /PartnerPortal.*___[de|fr|it]/.test(
        this.$route.name,
      )
      if (isOfferPage || isPartnerPortalPage) {
        // Prevent switching to IT on partner portal and offer pages
        locales = this.$i18n.locales.filter((lang) => lang.code !== 'it')
      }
      return locales.map((l) => l.code)
    },
    footerBottomCopy() {
      return [
        {
          name: this.$t('navigation.imprint'),
          href: {
            de: `${process.env.DEXTRA_WEBSITE_URL}/impressum`,
            fr: `${process.env.DEXTRA_WEBSITE_URL}/fr/mentions-legales`,
            it: `${process.env.DEXTRA_WEBSITE_URL}/impressum`,
          },
        },
        {
          name: this.$t('navigation.privacy'),
          href: {
            de: `${process.env.DEXTRA_WEBSITE_URL}/datenschutz`,
            fr: `${process.env.DEXTRA_WEBSITE_URL}/fr/protection-des-donnees`,
            it: `${process.env.DEXTRA_WEBSITE_URL}/datenschutz-italienisch`,
          },
        },
        {
          name: this.$t('navigation.copytext'),
        },
      ]
    },
    localizedFooterItems() {
      return this.getFooterNavItems(this.$i18n.locale)
    },
  },
  created() {
    handleScipSalesPreview(this.$route)
  },
  methods: {
    localizedRouteGetter(locale) {
      const newRouteName = this.$route.name.split('_')[0]
      return { name: `${newRouteName}___${locale}`, query: this.$route.query }
    },
    getFooterNavItems(locale) {
      return this.footerBottomCopy.map((item) => {
        const link = item?.href?.[locale]
        const localizedItem = item
        if (link) {
          localizedItem.href = link
          localizedItem.externalLink = link
        }
        return localizedItem
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';

.wrapper {
  display: grid;
  margin: 0 auto;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: rem(60) 1fr auto;
  background: #f0f0f0;

  @include media-breakpoint-up(lg) {
    grid-template-rows: rem(120) 1fr rem(100);
  }

  main {
    grid-row: 2;
  }

  footer {
    grid-row: 3;
    background: transparent;
  }
}
.navigation-layout-wrap {
  position: relative;
}

@media (min-width: 1440px) {
  ::v-deep .navigation-wrap .nav-content {
    width: 1360px;
  }
}

::v-deep .navigation-layout-wrap .logo {
  background-image: url(map-deep-get($images, 'logo-dark', 'path')) !important;
}

::v-deep .navigation-layout-wrap .login-button {
  display: none;
}

@include media-breakpoint-down(xs) {
  ::v-deep .footer-wrap .footer-bottom .footer-bottom-copy {
    margin-bottom: rem(120);
  }
}
</style>
