<template>
  <div>
    <dx-accordion
      class="mb-2"
      :is-init-open="isOpen"
      :on-open="loadDetails"
      allow-text-select
    >
      <template slot="headline">
        <div v-if="template === 'partnerPortal'" class="row headline">
          <div class="col-12 col-md-3">
            {{ contract.policyholder.fullName }}
          </div>
          <div class="col-12 col-md-3">
            {{ contract.number }}
          </div>
          <div class="col-12 col-md-2">
            {{ contract.options || contract.productName }}
          </div>
          <div class="col-12 col-md-3">
            {{ formatPriceOrStatus(contract) }}
            <div v-if="brokerPolicyHasWarning" class="status-warning" />
          </div>
          <div class="col-12 col-md-1">
            <div
              :class="
                contract.policyholder.hasEbill ? 'icon-check' : 'icon-cross'
              "
            />
          </div>
        </div>
        <div v-else class="d-md-flex align-items-center">
          <div class="mr-4 font-weight-bold">
            {{ contract.insuring ? '' : $t('noLongerInsured') }}
            #{{ `${contract.id}  ${contract.productLabel}` }}
          </div>
        </div>
      </template>
      <template slot="content">
        <dx-policy-holder-options
          v-if="
            (template === 'partnerPortal' && brokerPolicyDetails.id) ||
            (isOwnContract && !isGm)
          "
          :template="template"
          :contract="
            template === 'partnerPortal' ? brokerPolicyDetails : contract
          "
          @details-saved="() => loadDetails(true)"
        />
        <dx-loading-overlay v-if="isLoading && !brokerPolicyDetails.id" />

        <div
          v-if="template !== 'partnerPortal' || brokerPolicyDetails.id"
          class="row"
        >
          <div class="col mt-4">
            <dx-claim-excerpt-list
              :claims="
                (template === 'partnerPortal' ? brokerPolicyDetails : contract)
                  .claims
              "
              :subscribed-close-claim="subscribedCloseClaim"
              :is-broker="template === 'partnerPortal'"
            />
          </div>
        </div>

        <div v-if="contract.insuring" class="row">
          <div class="col text-center">
            <dx-button
              :small="true"
              :text="$t('reportNewDamage')"
              :route-name="
                localePath({
                  name: 'SelfService-ReportDamage-MyInfo',
                  query: { contract: contract.id },
                })
              "
              theme="wine"
              icon="lightning"
              class="my-3 mb-md-4 mt-md-5"
            />
          </div>
        </div>
      </template>
    </dx-accordion>
  </div>
</template>

<script>
import {
  DxButton,
  DxAccordion,
} from '@sumcumo/dextra-frontend-component-lib-old'
import { mapGetters } from 'vuex'
import DxPolicyHolderOptions from '~/domains/contract/components/DX-Contract/DX-PolicyHolderOptions'
import DxClaimExcerptList from '~/domains/contract/claim/components/DX-ClaimExcerptList'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import formatOfferPriceMixin from '~/javascripts/mixins/formatOfferPriceMixin'

export default {
  name: 'DxContract',
  components: {
    DxAccordion,
    DxButton,
    DxClaimExcerptList,
    DxPolicyHolderOptions,
    DxLoadingOverlay,
  },
  mixins: [formatOfferPriceMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isOwnContract: {
      type: Boolean,
      default: false,
    },
    contract: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
    template: {
      type: String,
      default: '',
    },
    loadContractDetails: {
      type: Function,
      default: () => null,
    },
    subscribedCloseClaim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      brokerPolicyDetails: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['isGm']),
    brokerPolicyHasWarning() {
      return (
        this.contract.lastOpenReminder || this.contract.lastOpenCoverageLoss
      )
    },
  },
  methods: {
    async loadDetails(forceReload) {
      if (this.$store.getters.isBroker || forceReload) {
        this.isLoading = true
        try {
          const result = await this.loadContractDetails(this.contract.id)
          if (result) {
            this.brokerPolicyDetails = result
          }
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './index';
</style>
