<template>
  <ul v-if="items.length > 0" class="neutral mt-3 mb-5">
    <li
      v-for="item in items"
      :key="itemKey ? item[itemKey] : item"
      class="list-item delete-list"
    >
      {{ getTranslatedLabel(item) }}
      <div class="delete-item-wrap">
        <button
          type="button"
          class="btn-blank icon-orange-cross"
          @click="() => onDeleteClick(item)"
        >
          <span class="visually-hidden">
            {{ $t('delete') }}
          </span>
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DxSimpleDeleteList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: null,
    },
    getTranslatedLabel: {
      type: Function,
      default: () => null,
    },
    onDeleteClick: {
      type: Function,
      default: () => null,
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
