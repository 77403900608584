<template>
  <nav class="cmp-navigation-placebo">
    <div :class="cssClasses()" class="cmp-navigation-wrap">
      <div class="container-fluid cmp-navigation">
        <div class="row">
          <nuxt-link to="/" class="cmp-navigation-logo mr-4">
            <span class="visually-hidden">
              {{ $t('toMainPage') }}
            </span>
          </nuxt-link>

          <nav-side :account-subnav-data="accountSubnavData" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import NavSide from './NavSide'

export default {
  name: 'DxDesktopNavigation',
  components: {
    NavSide,
  },
  props: {
    navItems: {
      type: Array,
      default() {
        return []
      },
    },
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrolled: false,
      outerLink: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => this.scrollNav())
  },
  methods: {
    scrollNav() {
      this.scrolled = window.pageYOffset > 0
    },
    cssClasses() {
      return {
        active: this.scrolled && this.isSticky,
      }
    },
  },
  destroy() {
    window.removeEventListener('scroll', () => this.scrollNav())
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
