<template>
  <div class="w-100">
    <dx-lawyer-claim :claim-id="$route.query.claimId" />
    <dx-popup-modal ref="overlay">
      <h3>{{ $t('pendingDocuments.title') }}</h3>
      <p>
        {{ $t('pendingDocuments.text') }}
      </p>
      <div class="text-center">
        <dx-button
          type="button"
          class="my-4"
          :text="$t('pendingDocuments.button')"
          small
          @click="leaveAnyways"
        />
      </div>
    </dx-popup-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxLawyerClaim from '~/domains/externalLawyer/components/DX-LawyerClaim'
import pendingUploadsModalMixin from '~/javascripts/mixins/pendingUploadsModal'

export default {
  name: 'LawyerPortalDamage',
  components: {
    DxLawyerClaim,
    DxPopupModal,
    DxButton,
  },
  mixins: [pendingUploadsModalMixin],
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/faelle',
      fr: '/portail-avocat/cas-juridique',
      it: '/portale-procuratori/caso-giuridico',
    },
  },
}
</script>
