<template>
  <div class="container-fluid">
    <div class="content-wrap-small content-pad">
      <div class="d-flex justify-content-center">
        <dx-progressbar
          :steps="5"
          :active-step="2"
          class="mb-5 w-100 dx-w-md-75"
        />
      </div>
      <h3 class="mb-4">
        {{ $t('facts') }}
      </h3>
      <dx-select
        v-model="report.areaOfLaw"
        v-validate="'required'"
        :error="errors.first('fieldOfLaw')"
        :label="$t('itsAbout')"
        :options="mainFieldsOfLaw"
        class="mb-4"
        data-vv-name="fieldOfLaw"
        @input="setSubAreaOfLawOptions"
      />

      <dx-radio-list
        v-if="hasAreaOfLaw"
        v-model="report.subareaOfLaw"
        v-validate.disable="'required'"
        :options="subFieldsOfLaw"
        :error="errors.first('subFieldOfLaw')"
        name="subFieldOfLaw"
        data-vv-name="subFieldOfLaw"
        class="mb-4"
      />

      <dx-radio-list
        v-if="hasCarAccident"
        v-model="carAccidentSelection"
        v-validate.disable="'required'"
        :options="carAccidentOptions"
        :error="errors.first('requiredSelection')"
        name="requiredSelection"
        data-vv-name="requiredSelection"
        class="mb-4"
      />

      <dx-radio-list
        v-if="showRefundOptions"
        v-model="report.isFeesRefundDesired"
        :options="refundRadioData"
        :label="
          $t(isBroker ? 'refundHeadline.broker' : 'refundHeadline.customer')
        "
        name="refund"
        class="mb-4"
      />

      <div v-if="report.isFeesRefundDesired">
        <dx-input
          v-model="report.bankAccount.accountHolder"
          v-validate.disable="'required'"
          :error="errors.first('accountHolder')"
          :label="$t('accountOwner')"
          data-vv-name="accountHolder"
          class="mb-4"
        />
        <dx-iban-bic-input
          :iban="report.bankAccount.iban"
          :bic="report.bankAccount.bic"
          css-class="mb-4"
          @update:iban="(iban) => (report.bankAccount.iban = iban)"
          @update:bic="(bic) => (report.bankAccount.bic = bic)"
        />
      </div>

      <dx-textarea
        v-model="report.claim"
        :label="$t(isBroker ? 'goal.broker' : 'goal.customer')"
        class="mb-4"
      />

      <dx-textarea v-model="report.facts" :label="$t('factsDescription')" />

      <dx-bottom-bar
        :previous="localePath(previousStepPath)"
        :show-previous-button="true"
        class="mt-5"
        @forward="goToNextStep"
      />
    </div>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
  DxTextarea,
  DxSelect,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxProgressbar from '~/components/DX-Progressbar'
import DxBottomBar from '~/components/DX-BottomBar'
import DxIbanBicInput from '~/components/DX-IbanBicInput'
import {
  SET_ATTACHMENT_TYPES,
  SET_REPORT,
  SET_SELECTED_VALUES,
} from '~/domains/contract/claim/__store__/mutations'
import { documentTypeMap } from '~/domains/contract/claim/constants'
import { generalFieldsOfLaw, cyberFieldsOfLaw } from './fieldsOfLaw'

export default {
  name: 'DxFactsForm',
  components: {
    DxProgressbar,
    DxSelect,
    DxTextarea,
    DxBottomBar,
    DxIbanBicInput,
    DxRadioList,
    DxInput,
  },
  props: {
    isBroker: {
      type: Boolean,
      default: false,
    },
    nextStepPath: {
      type: String,
      default: '',
    },
    previousStepPath: {
      type: String,
      default: '',
    },
  },
  data() {
    const { areaOfLaw, subareaOfLaw } = this.$store.state.claim.selectedValues
    return {
      report: {
        ...this.$store.state.claim.report,
        areaOfLaw: areaOfLaw ? areaOfLaw.toLowerCase() : null,
        subareaOfLaw: subareaOfLaw ? subareaOfLaw.toLowerCase() : null,
      },
      refundRadioData: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
      subFieldsOfLaw:
        areaOfLaw !== null ? [] : this.getSubAreaOfLawOptions(areaOfLaw),
      carAccidentOptions: [],
      carAccidentSelection: null,
    }
  },
  computed: {
    fieldsOfLaw() {
      if (
        this.report.contract.productCode &&
        this.report.contract.productCode.startsWith('CYGA')
      ) {
        return cyberFieldsOfLaw
      }
      return generalFieldsOfLaw
    },
    mainFieldsOfLaw() {
      const mainArray = []
      mainArray.push({
        value: null,
        label: this.$t('selectOption'),
        caption: true,
      })
      Object.keys(this.fieldsOfLaw).forEach((field) => {
        mainArray.push({
          label: this.$t(`areasOfLawSelectable.${field}.${field}`),
          value: field,
        })
      })
      return mainArray
    },
    hasAreaOfLaw() {
      return this.report.areaOfLaw !== null
    },
    hasCarAccident() {
      return this.report.subareaOfLaw === 'car_accident'
    },
    showRefundOptions() {
      return this.report.subareaOfLaw === 'traffic_law_penal_procedure'
    },
  },
  watch: {
    'report.subareaOfLaw': function initCarOptions(val) {
      if (val === 'car_accident') {
        this.setCarAccidentOptions()
        this.carAccidentSelection =
          this.$store.state.claim.selectedValues.carAccidentSelection
      }
    },
    'showRefundOptions': function reset(val) {
      if (!val) {
        this.report.bankAccount.accountHolder = ''
        this.report.bankAccount.iban = ''
        this.report.bankAccount.bic = ''
        this.report.isFeesRefundDesired = false
      }
    },
  },
  mounted() {
    if (this.report.areaOfLaw !== null) {
      this.setSubAreaOfLawOptions()
    }
    if (this.report.subareaOfLaw === 'car_accident') {
      this.setCarAccidentOptions()
      this.carAccidentSelection =
        this.$store.state.claim.selectedValues.carAccidentSelection
    }
  },
  methods: {
    getSubAreaOfLawOptions(areaOfLaw) {
      return areaOfLaw
        ? Object.keys(this.fieldsOfLaw[areaOfLaw]).map((field) => ({
            text:
              this.$t(`areasOfLawSelectable.${areaOfLaw}.${field}`) || field,
            id: field,
          }))
        : []
    },
    setSubAreaOfLawOptions() {
      const { areaOfLaw, subareaOfLaw } = this.report
      this.subFieldsOfLaw = this.getSubAreaOfLawOptions(areaOfLaw)
      this.carAccidentOptions = []
      this.carAccidentSelection = null
      if (!this.subFieldsOfLaw.some((sfol) => sfol.id === subareaOfLaw)) {
        this.report.subareaOfLaw = null
      }
    },
    setCarAccidentOptions() {
      if (this.report.subareaOfLaw === 'car_accident') {
        const carAccidentArray = []
        Object.keys(this.fieldsOfLaw.vehicle_or_traffic.car_accident).forEach(
          (field) => {
            carAccidentArray.push({
              text:
                this.$t(
                  `areasOfLawSelectable.vehicle_or_traffic.car_accident_Options.${field}`,
                ) || field,
              id: field,
            })
          },
        )
        this.carAccidentOptions = carAccidentArray
      } else {
        this.carAccidentOptions = []
        this.carAccidentSelection = null
      }
    },
    setCarAccidentSelection() {
      if (this.hasCarAccident) {
        this.setCarAccidentOptions()
        this.carAccidentSelection =
          this.$store.state.claim.selectedValues.carAccidentSelection
      }
    },
    async goToNextStep() {
      const validated = await this.$validator.validateAll()
      if (validated) {
        const { areaOfLaw, subareaOfLaw } = this.report
        const isCarAccident = this.hasCarAccident
        const apiAreaOfLaw = isCarAccident
          ? this.fieldsOfLaw[areaOfLaw][subareaOfLaw][
              this.carAccidentSelection
            ].name.split('.')[0]
          : this.fieldsOfLaw[areaOfLaw][subareaOfLaw].name.split('.')[0]
        const apiSubAreaOfLaw = isCarAccident
          ? this.fieldsOfLaw[areaOfLaw][subareaOfLaw][
              this.carAccidentSelection
            ].name.split('.')[1]
          : this.fieldsOfLaw[areaOfLaw][subareaOfLaw].name.split('.')[1]
        const attachmentTypes =
          documentTypeMap[apiAreaOfLaw.toUpperCase()][
            apiSubAreaOfLaw.toUpperCase()
          ]

        const selectedValues = {
          carAccidentSelection: this.carAccidentSelection,
          areaOfLaw,
          subareaOfLaw,
        }

        this.$store.commit({
          type: SET_ATTACHMENT_TYPES,
          attachmentTypes,
        })
        this.$store.commit({
          type: SET_SELECTED_VALUES,
          selectedValues,
        })
        const report = {
          ...this.report,
          areaOfLaw: apiAreaOfLaw,
          subareaOfLaw: apiSubAreaOfLaw,
          bankAccount: this.report.bankAccount,
        }
        this.$store.commit({
          type: SET_REPORT,
          report,
        })
        this.$router.push({ path: this.localePath(this.nextStepPath) })
      }
    },
  },
}
</script>
