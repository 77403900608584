<template>
  <div class="mb-4 mb-md-0">
    <form
      data-integrationtest="mvp-invitation-form"
      @submit.prevent="sendInvitation"
    >
      <dx-input
        v-model="form.firstName"
        v-validate.disable="'required|min:2'"
        :error="errors.first('firstName')"
        :label="$t('firstName')"
        data-vv-name="firstName"
        class="mb-3"
      />

      <dx-input
        v-model="form.lastName"
        v-validate.disable="'required|min:2'"
        :error="errors.first('lastName')"
        :label="$t('lastName')"
        data-vv-name="lastName"
        class="mb-3"
      />

      <dx-input
        v-model="form.email"
        v-validate.disable="`required|email|is_email_assigned:${listedMails}`"
        :error="errors.first('invalidEmail')"
        :label="$t('email')"
        data-vv-name="invalidEmail"
        class="mb-3"
        name="email"
      />

      <div class="text-right">
        <button
          tabindex="0"
          class="secondary-link btn-blank mr-3"
          type="button"
          @click="onCancel"
        >
          {{ $t('abort') }}
        </button>

        <button
          tabindex="0"
          class="secondary-link btn-blank"
          type="submit"
          data-integrationtest="mvp-invitation-submit"
          @click.prevent="sendInvitation"
        >
          {{ $t('submit') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { DxInput } from '@sumcumo/dextra-frontend-component-lib-old'
import createInvitationMutation from '~/domains/contract/__gql__/mutations/createInvitation.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'DxInvitePerson',
  components: {
    DxInput,
  },
  mixins: [loadingMixin],
  props: {
    contractId: {
      type: String,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: () => null,
    },
    onCancel: {
      type: Function,
      default: () => null,
    },
    onError: {
      type: Function,
      default: () => null,
    },
    listedMails: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
      },
    }
  },
  methods: {
    clearForm() {
      this.form = {
        firstName: '',
        lastName: '',
        email: '',
      }
    },
    async sendInvitation() {
      const isValid = await this.$validator.validateAll()
      if (isValid) {
        const { firstName, lastName, email } = this.form
        const variables = {
          invitation: {
            contract: {
              id: this.contractId,
              insuranceCompany: 'DEXTRA',
            },
            firstName,
            lastName,
            email,
            locale: this.$i18n.locale.toUpperCase(),
          },
        }
        try {
          this.setGlobalLoading(true)
          await this.$apollo.mutate({
            mutation: createInvitationMutation,
            variables,
          })
          // TODO: Add success-hint https://jira.sumcumo.net/browse/DEXGM-905
          // TODO: pass this to userfeedback https://jira.sumcumo.net/browse/DEXGM-922
          this.clearForm()
          this.$notification.success({
            headlineKey: 'invitePersonSuccessHeadline',
            messageKey: 'invitePersonSuccessMessage',
          })
          this.onSuccess()
        } catch (error) {
          this.$notification.error({
            messageKey: 'invitePersonErrorMessage',
          })
          this.onError(error)
        } finally {
          this.setGlobalLoading(false)
        }
      }
    },
  },
}
</script>
