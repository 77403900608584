<template>
  <div class="w-100 content-bg-color">
    <div class="container-fluid mt-3 my-md-4 position-relative">
      <dx-back-text-button
        :text="$t('damageBacklog.overview')"
        :route="localePath('PartnerPortal')"
        class="link-back"
      />

      <dx-damage-pop-up
        :show="damagePopupOpened"
        :text="$t('damagePopUp.text')"
        :headline="$t('damagePopUp.headline')"
      />
    </div>
    <div class="container-fluid content-wrap-medium content-pad">
      <div class="row">
        <div class="col">
          <h3>{{ $t('partnerPortal.customerClosedPolicies') }}</h3>
        </div>
      </div>
      <div class="row flex-row-reverse my-5">
        <div class="col-12 col-md-5">
          <dx-input
            v-model="searchTerm"
            :placeholder="$t('damageBacklog.search')"
            has-icon="search"
            type="search"
          />
        </div>
        <div class="col-12">
          <dx-closed-policies-filter v-model="filterOption" />
        </div>
      </div>
      <div class="header-wrap pl-3 py-3">
        <div class="row">
          <div class="col-12 col-md-3">
            {{ $t('name') }}
          </div>
          <div class="col-12 col-md-3">
            {{ $t('policyNumber') }}
          </div>
          <div class="col-12 col-md-2">
            {{ $t('partnerPortal.product') }}
          </div>
          <div class="col-12 col-md-3">
            {{ $t('partnerPortal.grossPremium') }}
          </div>
          <div class="col-12 col-md-1">
            {{ $t('eBill') }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dx-contract
            v-for="(brokerPolicyHeader, index) in brokerPolicies"
            :key="index"
            :contract="brokerPolicyHeader"
            template="partnerPortal"
            :load-contract-details="loadContractDetails"
            :subscribed-close-claim="subscribedCloseClaim"
          />
          <dx-loading-overlay v-if="isLoading" />
          <div v-if="notFound" class="content-block">
            {{ $t('partnerPortal.notFound') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import {
  DxInput,
  DxBackTextButton,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxDamagePopUp from '~/components/DX-ReportDamagePopUp'
import DxContract from '~/domains/contract/components/DX-Contract'
import policiesQuery from '~/domains/partnerPortal/__gql__/queries/brokerPolicies.gql'
import brokerPolicyQuery from '~/domains/partnerPortal/__gql__/queries/brokerPolicy.gql'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxClosedPoliciesFilter from '~/domains/partnerPortal/components/DX-ClosedPoliciesFilter'

export default {
  name: 'ClosedPolicies',
  components: {
    DxContract,
    DxInput,
    DxBackTextButton,
    DxLoadingOverlay,
    DxClosedPoliciesFilter,
    DxDamagePopUp,
  },
  data() {
    return {
      filterOption: 'active',
      brokerPolicies: [],
      searchTerm: '',
      contractsLoading: true,
      chunkSize: 10,
      offset: 0,
      initialLoadingFinished: false,
      allPoliciesLoaded: false,
      isMounted: false,
      currentQueryId: 0,
    }
  },
  computed: {
    isLoading() {
      const brokerPoliciesQuery = this.$apollo.queries.brokerPolicies
      return (
        !this.initialLoadingFinished ||
        !brokerPoliciesQuery ||
        brokerPoliciesQuery.loading
      )
    },
    notFound() {
      return this.brokerPolicies
        ? this.brokerPolicies.length <= 0 && !this.isLoading
        : true
    },
    damagePopupOpened() {
      return this.$store.state.partnerPortal.showDamagePopup
    },
    subscribedCloseClaim() {
      const { subscriptions } = this.$store.state.application.currentBroker
      return subscriptions && subscriptions.includes('close_claim')
    },
  },
  watch: {
    searchTerm() {
      this.resetQuery()
    },
    filterOption() {
      this.resetQuery()
    },
  },
  mounted() {
    this.isMounted = true
    window.onscroll = this.throttledLoadMoreOnScroll
  },
  destroyed() {
    this.isMounted = false
    window.onscroll = null
    this.$store.commit('SET_DAMAGEPOPUP', false)
  },
  methods: {
    loadMoreOffersIfNecessary() {
      const footer = document.querySelector('footer')
      const footerHeight = footer !== null ? footer.scrollHeight : 0
      const visible = document.documentElement.clientHeight
      const pageHeight =
        document.documentElement.scrollHeight - footerHeight - 300
      const bottomOfWindow = visible + window.pageYOffset >= pageHeight
      if (bottomOfWindow) {
        this.onLoadMore()
      }
    },
    throttledLoadMoreOnScroll: throttle(async function callLoadMore() {
      this.loadMoreOffersIfNecessary()
    }, 200),
    async onLoadMore() {
      const brokerPoliciesQuery = this.$apollo.queries.brokerPolicies
      if (
        !this.isMounted ||
        this.allPoliciesLoaded ||
        !brokerPoliciesQuery ||
        brokerPoliciesQuery.loading
      ) {
        return
      }
      const offset = this.offset + this.chunkSize
      const queryId = this.currentQueryId

      const promise = brokerPoliciesQuery.fetchMore({
        variables: { offset },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const loadedPolicies =
            fetchMoreResult && fetchMoreResult.brokerPolicies
          this.allPoliciesLoaded =
            !loadedPolicies || loadedPolicies.length < this.chunkSize
          if (!loadedPolicies) {
            return previousResult
          }

          return {
            brokerPolicies: [
              ...previousResult.brokerPolicies,
              ...loadedPolicies,
            ],
          }
        },
      })
      if (promise) {
        this.offset = offset
        promise.catch((error) => {
          // Suppress error if not mounted: https://github.com/apollographql/apollo-client/issues/4114
          // or if variables (searchTerm/filterOption) have changed
          // https://github.com/Akryum/vue-apollo/blob/master/docs/guide/apollo/pagination.md
          if (this.isMounted && this.currentQueryId === queryId) {
            console.error(error)
          }
        })
      }
    },
    async loadContractDetails(id) {
      try {
        const result = await this.$apollo.query({
          query: brokerPolicyQuery,
          variables: {
            id: parseInt(id, 10),
          },
          fetchPolicy: 'network-only',
        })
        if (result && result.data && result.data.brokerPolicy) {
          return result.data.brokerPolicy
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    resetQuery() {
      if (this.$apollo.queries.brokerPolicies.observer) {
        this.currentQueryId =
          this.$apollo.queries.brokerPolicies.observer.queryId
      }
      this.$apollo.queries.brokerPolicies.stop()
      this.offset = 0
      this.allPoliciesLoaded = false
      this.$apollo.queries.brokerPolicies.start()
    },
  },
  apollo: {
    brokerPolicies: {
      query: policiesQuery,
      fetchPolicy: 'network-only',
      variables() {
        return {
          searchStr: this.searchTerm.length > 2 ? this.searchTerm : '',
          filter: this.filterOption.toUpperCase(),
          offset: 0,
          limit: this.chunkSize,
        }
      },
      update(data) {
        this.initialLoadingFinished = true
        const loadedPolicies = data && data.brokerPolicies
        if (loadedPolicies && loadedPolicies.length >= this.chunkSize) {
          this.loadMoreOffersIfNecessary()
        }
        return loadedPolicies || []
      },
      debounce: 500,
    },
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/abgeschlossene-policen',
      fr: '/portaildespartenaires/politiques-conclues',
      it: '/portaledeipartner/politiche-concluse',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
