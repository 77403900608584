<template>
  <div>
    <h3>{{ $t('documents') }}</h3>
    <div class="row pt-4">
      <div class="col-12 col-md-5">
        <h5 class="mb-0 mr-2 d-inline-block">
          {{ $t('requestedDocs') }}

          <dx-tooltip position="bottom-center">
            <template slot="trigger">
              <button type="button" class="btn-blank info-tooltip">
                <span class="visually-hidden">
                  {{ $t('delete') }}
                </span>
              </button>
            </template>
            <template slot="content">
              {{ $t('lawyerPortal.uploadTooltip') }}
            </template>
          </dx-tooltip>
        </h5>
        <div class="mb-4 mb-md-6">
          <dx-download-list
            :items="sortedMissingDocuments"
            :items-deletable="true"
            :item-linkable="false"
            :delete-callback="(item) => removeMissingDocument(item)"
            :toggle-list-items="true"
            show-status
            tag-type="h5"
          />
        </div>

        <dx-request-docs-multi
          class="mb-4"
          :doc-types="getMissingDocumentTypeOptions()"
          :on-request-complete="addMissingDocument"
        />
      </div>
      <div class="col-12 col-md-5 offset-md-2 mt-4 mt-md-0">
        <dx-download-list
          :headline="$t('documents')"
          :items="documents"
          :items-deletable="!readOnly"
          :delete-callback="(item) => removeDocument(item)"
          :toggle-list-items="true"
          tag-type="h5"
          :claim-id="claimId"
        />
        <div v-if="!readOnly" class="row mt-4">
          <div class="col-12">
            <h5 class="mb-3">
              {{ $t('uploadFiles') }}
            </h5>

            <dx-upload
              class="mb-4"
              type="uploadDocsWithType"
              :doc-types="getMissingDocumentTypeOptions()"
              :on-upload-success="(file) => saveDocuments([file])"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxDownloadList from '~/components/DX-DownloadList'
import DxRequestDocsMulti from '~/components/DX-Upload/DX-RequestDocsMulti'
import DxUpload from '~/components/DX-Upload'
import DxTooltip from '~/components/DX-Tooltip'

import { documentTypeMap } from '~/domains/contract/claim/constants'

export default {
  name: 'DxLawyerClaimDocuments',
  components: {
    DxDownloadList,
    DxRequestDocsMulti,
    DxUpload,
    DxTooltip,
  },
  props: {
    documents: {
      type: Array,
      required: true,
      default: () => [],
    },
    missingDocuments: {
      type: Array,
      required: true,
      default: () => [],
    },
    removeDocument: {
      type: Function,
      default: () => null,
    },
    createMissingDocument: {
      type: Function,
      default: () => null,
    },
    requestMissingDocuments: {
      type: Function,
      default: () => null,
    },
    removeMissingDocument: {
      type: Function,
      default: () => null,
    },
    saveDocuments: {
      type: Function,
      default: () => null,
    },
    areaOfLaw: {
      type: String,
      default: null,
    },
    subareaOfLaw: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    vpHasLogin: {
      type: Boolean,
      default: false,
    },
    claimId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      documentTitle: '',
      selectedType: null,
    }
  },
  computed: {
    sortedMissingDocuments() {
      return this.missingDocuments.slice(0).reverse()
    },
  },
  methods: {
    getMissingDocumentTypeOptions() {
      const types =
        (documentTypeMap[this.areaOfLaw] || {})[this.subareaOfLaw] || []
      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
      }
      return [
        defaultOption,
        ...types.map((type) => ({
          label: this.$t(`factDocumentLabel.${type}`),
          value: type,
        })),
      ]
    },
    async addMissingDocument({ type, title, final }) {
      const result = await this.createMissingDocument({
        type,
        title,
      })
      if (result) {
        if (final) {
          this.requestMissingDocuments()
        }
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
