/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

import modules from '~/store/modules'

Vue.use(Vuex)

export default function createStore() {
  const store = new Vuex.Store({
    modules,
  })

  return store
}
