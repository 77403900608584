<template>
  <ul class="col pr-4 cmp-navigation-list neutral">
    <li v-for="(item, idx) in filteredNavData" :key="`${item.name}_${idx}`">
      <a v-if="isLink(item.path) && !item.isExternal" :href="item.path">
        {{ $t(item.name) }}
      </a>

      <a
        v-if="isLink(item.path) && item.isExternal"
        :href="item.path"
        target="_blank"
        rel="noopener"
      >
        {{ $t(item.name) }}
      </a>

      <a v-if="!isLink(item.path)" class="no-link-item">
        {{ $t(item.name) }}
      </a>
      <div v-if="hasSubnav(item)" class="cmp-subnav">
        <ul class="cmp-subnav-list neutral">
          <li
            v-for="(subItem, idx2) in getFilteredSubNav(item.subNav)"
            :key="`${subItem.name}_${idx2}`"
            :class="subItem.class"
          >
            <a
              v-if="!subItem.isExternal && isLink(subItem.path)"
              :href="subItem.path"
            >
              {{ subItem.name }}
            </a>

            <div v-if="!isLink(subItem.path)" class="sub-nav-headline">
              {{ $t(subItem.name) }}
            </div>

            <a
              v-if="subItem.isExternal && isLink(subItem.path)"
              :href="subItem.path"
              target="_blank"
              rel="noopener"
            >
              {{ subItem.name }}
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavItems',
  props: {
    navData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    filteredNavData() {
      return [...this.navData].filter(this.navFilter)
    },
  },
  methods: {
    getFilteredSubNav(subNav) {
      return [...subNav].filter(this.navFilter)
    },
    hasSubnav(item) {
      return item.subNav.length > 0
    },
    isLink(path) {
      return path.length > 0
    },
    navFilter(data) {
      const hideForLocale = data.hideForLocale === this.$i18n.locale
      const hideForEnv =
        data.hideForEnv && data.hideForEnv.includes(process.env.DEXTRA_ENV)
      return !hideForLocale && !hideForEnv
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
