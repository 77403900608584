<template>
  <div class="container-fluid">
    <div class="mt-3 my-md-4">
      <nuxt-link :to="localePath('Login')" class="arrow-left overview">
        {{ $t('backToLogin') }}
      </nuxt-link>
    </div>
    <div class="container-fluid text-center">
      <div class="content-wrap-x-small content-pad">
        <div class="check-wrap" />
        <h2 class="mt-3 mb-4">
          {{ $t('registerPage.lawyerInfo.headline') }}
        </h2>
        <p>
          {{ $t('registerPage.lawyerInfo.text1') }}
        </p>
        <p v-html="$t('registerPage.lawyerInfo.text2')" />
        <p>
          {{ $t('registerPage.lawyerInfo.text3') }}
          <nuxt-link :to="localePath('PwReset')" class="mb-4 d-inline">
            {{ $t('loginPage.pwReset') }}
          </nuxt-link>
          .
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LawyerPortalRegistrationInfo',
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/registrierung-info',
      fr: '/portail-avocat/inscription-info',
      it: '/portale-procuratori/registrazione-info',
    },
  },
}
</script>
