<template>
  <svg
    :height="height"
    viewBox="0 0 24 24"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="m0 0h24v24h-24z" />
      <path
        d="m13.9999999 1.0000003c1.6568547 0 3 1.34314525 3 3l-.0009999.999 4.001.0009997c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1l-1.001-.0009997.0009999 13.0009997c0 1.6568545-1.3431459 3-3 3h-9.99999999c-1.65685449 0-3-1.343146-3-3l-.00099991-13.0009997-.999.0009997c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1l3.999-.0009997.00099991-.999c0-1.65685471 1.34314525-3 3-3zm-6.0179999 5.999h-1.983l.00099991 13.0009997c0 .5522847.44771518 1 1 1h9.99999999c.5522848 0 1-.4477152 1-1l-.0009999-13.0009997-1.9818386.00085571c-.005709.00009607-.0114296.00014429-.0171615.00014429l-.0179999-.001-7.66592274.00090212-.05023774.00061821-.26583961-.00052033zm2.018 3.0009997c.5522847 0 1 .4477153 1 1v6c0 .5522847-.4477153 1-1 1-.55228475 0-1-.4477153-1-1v-6c0-.5522847.44771525-1 1-1zm4 0c.5522847 0 1 .4477153 1 1v6c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-6c0-.5522847.4477153-1 1-1zm-.0000001-6.9999997h-3.99999999c-.55228527 0-1 .44771474-1 1l-.00099991.999h6l.0009999-.999c0-.55228525-.4477148-1-1-1z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TrashIcon',

  props: {
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
  },
}
</script>
