<template>
  <div class="container-fluid">
    <div class="mt-3 my-md-4">
      <dx-back-text-button
        :text="$t('damageBacklog.overview')"
        :route="localePath('LawyerPortal')"
        class="overview"
      />
    </div>
    <div class="content-wrap-small content-pad">
      <h3 class="mb-5">
        {{ $t('userData.userDataHeading') }}
      </h3>

      <dx-change-login-details />

      <!-- Personal Data -->
      <div class="row user-data-block">
        <div class="col">
          <dx-edit-data-toggle
            :callback="saveLawyerData"
            :cancel-callback="resetLawyer"
            :headline="$t('userData.personalData')"
            tag-type="h4"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <template v-if="!editMode">
                <ul class="neutral mb-0">
                  <li v-if="localLawyer.salutation">
                    {{ $t(localLawyer.salutation.toLowerCase()) }}
                  </li>
                  <li v-if="localLawyer.academicDegree">
                    {{ $t(`lawyerPortal.title.${localLawyer.academicDegree}`) }}
                  </li>
                  <li>
                    {{ `${localLawyer.firstName} ${localLawyer.lastName}` }}
                  </li>
                  <li v-if="localLawyer.companyName">
                    {{ localLawyer.companyName }}
                  </li>
                  <li>
                    {{
                      `${localLawyer.address.street} ${localLawyer.address.number}`
                    }}
                  </li>
                  <li v-if="localLawyer.address.additional">
                    {{ localLawyer.address.additional }}
                  </li>
                  <li v-if="localLawyer.address.zip">
                    {{
                      `${localLawyer.address.zip} ${localLawyer.address.city}`
                    }}
                  </li>
                </ul>
              </template>

              <template v-if="editMode">
                <dx-personal-data
                  :personal-data="localLawyer"
                  :update-personal-data="(data) => updateLocalLawyer(data)"
                />
              </template>
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>

      <!-- Contact Data -->
      <div class="row user-data-block">
        <div class="col">
          <dx-edit-data-toggle
            :callback="saveLawyerData"
            :cancel-callback="resetLawyer"
            :headline="$t('userData.contactData')"
            tag-type="h4"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <template v-if="!editMode">
                <ul class="neutral mb-0">
                  <li v-if="localLawyer.contactDetails.phone" class="mb-3">
                    <h5 class="mb-0">
                      {{ $t('telephone') }}
                    </h5>
                    {{ localLawyer.contactDetails.phone }}
                  </li>
                  <li v-if="localLawyer.contactDetails.mobile" class="mb-3">
                    {{ $t('mobilePhone') }}
                    {{ localLawyer.contactDetails.mobile }}
                  </li>
                  <li v-if="localLawyer.contactDetails.website">
                    <h5>{{ $t('website') }}</h5>
                    {{ localLawyer.contactDetails.website }}
                  </li>
                </ul>
              </template>

              <template v-if="editMode">
                <dx-access-details
                  :contact-details="localLawyer.contactDetails"
                />
              </template>
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>

      <!-- Employment Details -->
      <div class="row user-data-block">
        <div class="col">
          <dx-edit-data-toggle
            :callback="saveLawyerData"
            :cancel-callback="resetLawyer"
            :headline="$t('lawyerPortal.employmentDetails')"
            tag-type="h4"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <template v-if="!editMode">
                <h5 class="mb-0">{{ $t('lawyerPortal.jurisdictions') }}:</h5>
                <ul
                  v-if="localLawyer.areaOfLawSkills.length > 0"
                  class="neutral mb-0"
                >
                  <li
                    v-for="areaOfLawSkill in localLawyer.areaOfLawSkills"
                    :key="areaOfLawSkill"
                  >
                    {{ $t(`lawyerPortal.areaOfLawSkill.${areaOfLawSkill}`) }}
                  </li>
                </ul>
                <div class="mt-4">
                  <h5 class="mb-0">{{ $t('lawyerPortal.jobTitle') }}:</h5>
                  <ul
                    v-if="localLawyer.jobTitles.length > 0"
                    class="neutral mb-0"
                  >
                    <li
                      v-for="jobTitle in localLawyer.jobTitles"
                      :key="jobTitle"
                    >
                      {{ $t(`lawyerPortal.jobTitles.${jobTitle}`) }}
                    </li>
                  </ul>
                </div>
                <div class="mt-4">
                  <h5 class="mb-0">{{ $t('lawyerPortal.qualification') }}:</h5>
                  <ul
                    v-if="localLawyer.additionalSkills.length > 0"
                    class="neutral mb-0"
                  >
                    <li
                      v-for="additionalSkill in localLawyer.additionalSkills"
                      :key="additionalSkill.skill"
                    >
                      {{
                        $t(
                          `lawyerPortal.additionalSkill.${additionalSkill.skill}`,
                        )
                      }}
                      ({{ additionalSkill.sinceYear }})
                    </li>
                  </ul>
                </div>
                <div class="mt-4">
                  <h5 class="mb-0">
                    {{ $t('lawyerPortal.otherQualifications') }}:
                  </h5>
                  <ul
                    v-if="localLawyer.advancedTrainings.length > 0"
                    class="neutral mb-0"
                  >
                    <li
                      v-for="training in localLawyer.advancedTrainings"
                      :key="training.training"
                    >
                      {{
                        $t(`lawyerPortal.advancedTraining.${training.training}`)
                      }}
                      ({{ training.sinceYear }})
                    </li>
                  </ul>
                </div>
                <div class="mt-4">
                  <h5 class="mb-0">{{ $t('language') }}:</h5>
                  <ul
                    v-if="localLawyer.languageSkills.length > 0"
                    class="neutral mb-0"
                  >
                    <li
                      v-for="languageSkill in localLawyer.languageSkills"
                      :key="languageSkill.language"
                    >
                      {{ $t(languageSkill.language) }}
                      {{
                        $t(`lawyerPortal.languageSkill.${languageSkill.level}`)
                      }}
                    </li>
                  </ul>
                </div>
              </template>

              <template v-if="editMode">
                <dx-employment-details
                  :employment-details="localLawyer"
                  :update-employment-details="(data) => updateLocalLawyer(data)"
                  :clear-errors="clearErrors"
                />
                {{ $t('language') }}
                <div class="mt-4">
                  <dx-language-skills
                    :skills="localLawyer.languageSkills"
                    :update-language-skills="(data) => updateLocalLawyer(data)"
                    :clear-errors="clearErrors"
                  />
                </div>
              </template>
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>

      <!-- Payment Data -->
      <div class="row pt-5">
        <div class="col">
          <dx-edit-data-toggle
            ref="bankAccount"
            :callback="saveLawyerData"
            :cancel-callback="resetLawyer"
            :headline="$t('userData.paymentData')"
            tag-type="h4"
            :initially-editable="editForm === 'bankAccount'"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <template v-if="!editMode">
                <ul class="neutral mb-0">
                  <li>
                    <h5 class="mb-0">{{ $t('hourly') }}:</h5>
                    <p>
                      {{ localLawyer.hourlyRate.value || '' }}
                      {{ localLawyer.hourlyRate.currency || 'CHF' }}
                    </p>
                  </li>
                  <li class="mt-4">
                    <h5 class="mb-0">{{ $t('accountOwner') }}:</h5>
                    <p>{{ localLawyer.bankAccount.accountHolder || '' }}</p>
                  </li>
                  <li class="mt-4">
                    <h5 class="mb-0">{{ $t('iban') }}:</h5>
                    <p>{{ localLawyer.bankAccount.iban || '' }}</p>
                  </li>
                  <li class="mt-4">
                    <h5 class="mb-0">{{ $t('bic') }}:</h5>
                    <p>{{ localLawyer.bankAccount.bic || '' }}</p>
                  </li>
                </ul>
              </template>

              <template v-if="editMode">
                <dx-bank-details
                  :bank-details="localLawyer"
                  :update-bank-details="(data) => updateLocalLawyer(data)"
                  :clear-errors="clearErrors"
                />
              </template>
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxBackTextButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxEditDataToggle from '~/components/DX-EditDataToggle'
import DxPersonalData from '~/domains/externalLawyer/components/DX-PersonalData'
import DxAccessDetails from '~/domains/externalLawyer/components/DX-AccessDetails'
import DxEmploymentDetails from '~/domains/externalLawyer/components/DX-ExmploymentDetails'
import DxLanguageSkills from '~/domains/externalLawyer/components/DX-LanguageSkills'
import DxBankDetails from '~/domains/externalLawyer/components/DX-BankDetails'
import DxChangeLoginDetails from '~/domains/authentication/components/DX-ChangeLoginDetails'

import { SET_CURRENT_LAWYER } from '~/domains/application/__store__/mutations'
import updateLawyerMutation from '~/domains/externalLawyer/__gql__/mutations/updateLawyer.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import scrollFirstErrorIntoView, {
  scrollIntoView,
} from '~/javascripts/helper/scollIntoView'

export default {
  name: 'LawyerPortalUserdata',
  components: {
    DxLanguageSkills,
    DxEditDataToggle,
    DxPersonalData,
    DxAccessDetails,
    DxEmploymentDetails,
    DxBankDetails,
    DxChangeLoginDetails,
    DxBackTextButton,
  },
  mixins: [loadingMixin],
  data() {
    return {
      localLawyer: {
        ...JSON.parse(
          JSON.stringify(this.$store.state.application.currentLawyer),
        ),
        contactDetails: {
          ...this.$store.state.application.currentLawyer.contactDetails,
          email: this.$store.state.application.currentLawyer.email,
        },
        bankAccount:
          this.$store.state.application.currentLawyer.bankAccount || {},
      },
    }
  },
  computed: {
    editForm() {
      return this.$route.query.edit
    },
  },
  mounted() {
    if (this.editForm && this.$refs[this.editForm]) {
      scrollIntoView(this.$refs[this.editForm].$el)
    }
  },
  methods: {
    updateLocalLawyer(data) {
      this.localLawyer = { ...this.localLawyer, ...data }
    },
    async saveLawyerData() {
      await this.$validator.reset()
      let valid = await this.$validator.validateAll()
      if (this.localLawyer.languageSkills.length < 1) {
        this.$validator.errors.add({
          field: 'languages',
          msg: this.$t('lawyerPortal.missingLanguage'),
          rule: 'required',
        })
        valid = false
      }
      if (this.localLawyer.areaOfLawSkills.length < 1) {
        this.$validator.errors.add({
          field: 'areaOfLawSkillsEmpty',
          msg: this.$t('lawyerPortal.missingJurisdiction'),
          rule: 'required',
        })
        valid = false
      }
      if (this.localLawyer.jobTitles.length < 1) {
        this.$validator.errors.add({
          field: 'jobTitlesEmpty',
          msg: this.$t('lawyerPortal.missingJobTitle'),
          rule: 'required',
        })
        valid = false
      }
      if (valid) {
        // hourlyRate might be a string...
        this.localLawyer.hourlyRate.value = Number.parseFloat(
          this.localLawyer.hourlyRate.value,
        )

        // call mutation
        const currentLawyer = {
          ...JSON.parse(JSON.stringify(this.localLawyer)),
        }
        delete currentLawyer.id
        delete currentLawyer.email
        delete currentLawyer.fullName
        delete currentLawyer.username
        delete currentLawyer.claims
        delete currentLawyer.termsOfServiceAccepted

        try {
          this.setGlobalLoading(true)
          await this.$apollo.mutate({
            mutation: updateLawyerMutation,
            variables: {
              lawyerProfileData: JSON.parse(
                JSON.stringify(currentLawyer, (k, v) =>
                  k === '__typename' ? undefined : v,
                ),
              ),
            },
          })
          // update local lawyer
          this.$store.commit(SET_CURRENT_LAWYER, {
            currentLawyer: this.localLawyer,
          })
          this.$notification.success({
            messageKey: 'userData.addressMutationSuccess',
          })
          return true
        } catch (error) {
          console.error(error)
          if (
            error &&
            error.networkError &&
            error.networkError.result &&
            error.networkError.result.errors
          ) {
            error.networkError.result.errors.forEach((err) => {
              if (
                err.message ===
                '["Participant user bank datum iban ist nicht gültig"]'
              ) {
                this.$validator.errors.add({
                  field: 'ibanInput',
                  msg: this.$t('ibanInvalid'),
                  rule: 'server',
                })
              }
            })
          }
          this.$notification.error({
            messageKey: 'userData.addressMutationError',
          })
          return false
        } finally {
          this.setGlobalLoading(false)
        }
      } else {
        scrollFirstErrorIntoView()
        console.error(this.$validator.errors)
      }
      return false
    },
    clearErrors() {
      // clear all error scopes and default scope
      this.errors.clear()
      this.errors.clear('customLanguage')
      this.errors.clear('areaOfLawSkills')
      this.errors.clear('jobTitles')
      this.errors.clear('additionalSkill')
      this.errors.clear('advancedTraining')
    },
    resetLawyer() {
      this.defaultLawyer()
    },
    defaultLawyer() {
      const lawyerCopy = JSON.parse(
        JSON.stringify(this.$store.state.application.currentLawyer),
      )
      const lawyer = {
        ...lawyerCopy,
      }
      this.localLawyer = {
        ...lawyer,
        languageSkills: [
          ...lawyer.languageSkills.map((l) => ({
            language: l.language,
            level: l.level,
          })),
        ],
      }
    },
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/benutzerdaten',
      fr: '/portail-avocat/donnees-utilisateur',
      it: '/portale-procuratori/informazioni-utente',
    },
  },
}
</script>
