<template>
  <div :class="{ 'w-100': tabs }">
    <div v-if="tabs" class="lang-switch d-flex text-center mx-md-5 mb-5">
      <nuxt-link
        v-for="(locale, index) in availableLocales"
        :key="index"
        :to="switchLocalePath(locale.code)"
        :class="{ active: locale.code == $i18n.locale }"
        class="col lang"
      >
        {{ langtext[index] }}
      </nuxt-link>
    </div>

    <ul v-else class="mb-0">
      <li
        v-for="(locale, index) in availableLocales"
        :key="index"
        class="d-inline-block lang-item"
      >
        <a
          v-if="locale.code !== $i18n.locale"
          :exact="true"
          :class="{ disabled: locale.code == $i18n.locale }"
          :text="locale.code"
          class="d-block text-uppercase lang-link"
          tabindex="0"
          @click="changeLocale(locale.code)"
        >
          {{ locale.code }}
        </a>

        <span
          v-else
          :class="{ disabled: locale.code == $i18n.locale }"
          class="d-block text-uppercase lang-link"
        >
          {{ locale.code }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DxLangSwitcher',
  props: {
    tabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      langtext: ['DEUTSCH', 'FRANCAIS', 'ITALIANO'],
    }
  },
  computed: {
    availableLocales() {
      let { locales } = this.$i18n
      const isOfferPage = /Offer.*___[de|fr|it]/.test(this.$route.name)
      const isPartnerPortalPage = /PartnerPortal.*___[de|fr|it]/.test(
        this.$route.name,
      )
      if (isOfferPage || isPartnerPortalPage) {
        // Prevent switching to IT on partner portal and offer pages
        locales = this.$i18n.locales.filter((lang) => lang.code !== 'it')
      }
      return locales
    },
  },
  created() {
    this.$validator.localize(this.$i18n.locale)
  },
  methods: {
    changeLocale(locale) {
      this.$validator.localize(locale)
      const newRouteName = this.$route.name.split('_')[0]
      const newRoute = `${newRouteName}___${locale}`
      this.$store.$router.push({
        name: newRoute,
        params: { ...this.$route.params },
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
