<template>
  <div>
    <form @submit.prevent="submit">
      <dx-input
        v-model="email"
        v-validate.disable="'required|email'"
        :error="errors.first('invalidEmail')"
        :label="$t('emailAdress')"
        data-vv-name="invalidEmail"
        class="mb-4"
        inverted
        autocomplete="email"
      />
      <div class="text-right">
        <dx-button :text="$t('submit')" type="submit" />
      </div>
    </form>
  </div>
</template>

<script>
import { DxButton, DxInput } from '@sumcumo/dextra-frontend-component-lib'
import resetPasswordMutation from '~/domains/authentication/__gql__/mutations/resetPassword.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'DxPwReset',
  components: {
    DxButton,
    DxInput,
  },
  mixins: [loadingMixin],
  data() {
    return {
      email: null,
    }
  },
  methods: {
    async submit() {
      const result = await this.$validator.validateAll()
      if (result) {
        try {
          this.setGlobalLoading(true)
          const variables = { username: this.email }
          await this.$apollo.mutate({
            mutation: resetPasswordMutation,
            variables,
          })
          this.$notification.success({ messageKey: 'pwReset.success' })
          this.email = null
        } catch (error) {
          this.$notification.error({ messageKey: 'pwReset.error' })
        } finally {
          this.setGlobalLoading(false)
        }
      }
    },
  },
}
</script>
