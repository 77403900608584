<template>
  <form>
    <dx-input
      v-model="contactDetails.phone"
      v-validate.disable="
        `${
          contactDetails.mobile && contactDetails.mobile.trim()
            ? ''
            : 'required|min:4'
        }`
      "
      :label="$t('telephone')"
      :error="errors.first('mobileOrPhoneNumber')"
      class="mb-4"
      data-vv-name="mobileOrPhoneNumber"
      autocomplete="tel"
    />

    <dx-input
      v-model="contactDetails.mobile"
      v-validate.disable="
        `${
          contactDetails.phone && contactDetails.phone.trim()
            ? ''
            : 'required|min:4'
        }`
      "
      :label="$t('mobilePhone')"
      :error="errors.first('mobileOrPhoneNumber')"
      class="mb-4"
      data-vv-name="mobileOrPhoneNumber"
      autocomplete="tel"
    />

    <dx-input
      v-model="contactDetails.website"
      :label="$t('url')"
      class="mb-4"
    />
  </form>
</template>

<script>
import { DxInput } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'DxAccessDetails',
  components: {
    DxInput,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    contactDetails: {
      type: Object,
      default: () => ({}),
    },
    hasNewPwForms: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
