<template>
  <div class="container-fluid content-pad content-pad--big-top">
    <div class="content-wrap-x-small">
      <dx-progressbar :steps="2" :active-step="2" />
    </div>
    <div class="row mt-5">
      <div class="col-12 col-md-8">
        <h3>{{ $t('offer.summary.headline') }}</h3>

        <!-- DOWNLOADS ------------------------------------------------------------>
        <dx-download-list
          :items="downloads"
          external-url
          :headline="`${$t('offer.summary.headDocs')} ${$t(
            `partnerPortal.tariff.${activeTariff}.name`,
          )}`"
          class="my-5"
          tag-type="h5"
          target="_blank"
        />

        <!-- BASIC QUESTIONS ------------------------------------------------------------>
        <dx-edit-data-toggle
          ref="basicQuestionsToogle"
          :headline="$t('offer.summary.insuranceQuestions')"
          class="content-border-bottom pb-5"
          :callback="saveBasicQuestionsForm"
          :show-buttons="false"
          :class="{ 'has-errors': !validForms.basicQuestions }"
        >
          <template slot="data-dynamic" slot-scope="{ editMode }">
            <dx-private-basic-questions
              v-if="tariffType === 'private'"
              ref="privateIntroForm"
              :editable="editMode"
              is-broker-offer
              @change="debouncedCalcPremia"
            />
            <dx-business-basic-questions
              v-else-if="tariffType === 'business'"
              ref="businessIntroForm"
              :editable="editMode"
              is-broker-offer
              @change="debouncedCalcPremia"
            />
            <dx-business-move-basic-questions
              v-else-if="tariffType === 'b-move'"
              ref="businessMoveIntroForm"
              :editable="editMode"
              is-broker-offer
              @change="debouncedCalcPremia"
            />
            <nuxt-link
              v-else
              :to="{ path: localePath('PartnerPortal') }"
              class="secondary-link"
            >
              {{ $t('offer.chooseProduct') }}
            </nuxt-link>
          </template>
        </dx-edit-data-toggle>

        <dx-tabs
          v-if="!offerWasLoaded"
          v-model="formFilter"
          class="text-uppercase mt-5"
          :tabs-data="formFilterOptions"
        />

        <!-- POLICYHOLDER DATA ------------------------------------------------------------>
        <dx-edit-data-toggle
          ref="policyHolderDataToogle"
          :headline="$t('offer.summary.infoPolicyholder')"
          class="content-border-bottom pb-5 mt-5"
          :callback="() => $refs[formRefPolicyHolder].saveForm()"
          :show-buttons="false"
          :class="{ 'has-errors': !validForms.policyHolderData }"
          initially-editable
        >
          <template slot="data-dynamic" slot-scope="{ editMode }">
            <dx-policy-holder-data-form
              v-if="tariffType === 'private'"
              ref="policyHolderDataForm"
              :editable="editMode"
              is-broker-offer
              :hide-optional-fields="quickOfferMode"
            />

            <dx-business-policy-holder-data-form
              v-else
              ref="businessPolicyHolderDataForm"
              :editable="editMode"
              is-broker-offer
              :hide-optional-fields="quickOfferMode"
            />
          </template>
        </dx-edit-data-toggle>

        <!-- ASSESSMENT QUESTIONS ------------------------------------------------------------>
        <dx-edit-data-toggle
          v-show="!quickOfferMode"
          ref="answeredQuestionsToogle"
          data-integrationtest="assessmentQuestions"
          :headline="$t('offer.summary.answeredQuestions')"
          class="content-border-bottom pb-5 mt-5"
          tag-type="h4"
          :show-buttons="false"
          :class="{ 'has-errors': !validForms.answeredQuestions }"
        >
          <template slot="data-dynamic" slot-scope="{ editMode }">
            <dx-assessment-questions
              ref="assessmentQuestionsForm"
              :editable="editMode"
              is-broker-offer
              :is-required="assessmentQuestionsRequired"
            />
          </template>
        </dx-edit-data-toggle>

        <!-- DATA PROTECTION ------------------------------------------------------------>
        <div class="row pt-5">
          <div class="col-12">
            <h3 class="mb-5">
              {{ $t('dataProtection') }}
            </h3>
            <p>
              {{ $t('brokerOffer.dataProtection.part1a') }}
              <a
                class="a-underline"
                target="_blank"
                :href="`${websiteURL}${$t('dataProtectionUrl')}`"
              >
                {{ $t('brokerOffer.dataProtection.link_avb_text') }}
              </a>
              {{ $t('brokerOffer.dataProtection.part1b') }}
            </p>
            <p>{{ $t('brokerOffer.dataProtection.part2') }}</p>
            <ul>
              <li>{{ $t('offer.dataProtection.part3') }}</li>
              <li>{{ $t('offer.dataProtection.part4') }}</li>
              <li>{{ $t('offer.dataProtection.part5') }}</li>
              <li>{{ $t('offer.dataProtection.part6') }}</li>
              <li>{{ $t('offer.dataProtection.part7') }}</li>
              <li>{{ $t('offer.dataProtection.part8') }}</li>
              <li>{{ $t('offer.dataProtection.part9') }}</li>
            </ul>

            <dx-radio-check
              id="agreeDataProtection"
              v-model="agreeDataProtection"
              v-validate.disable="'required|is_true'"
              :checked="agreeDataProtection || false"
              no-background
              :label="
                $t('brokerOffer.summary.agreeDataProtection', {
                  link_avb: `<a class='a-underline' target='_blank' rel='noopener'
                         href='${getAvbFile}'>${$t(
                    'offer.summary.link_avb',
                  )}</a>`,
                })
              "
              :error="errors.first('agreeDataProtection')"
              type="checkbox"
              name="agreeDataProtection"
              class="mt-5 mb-5"
              data-vv-name="agreeDataProtection"
              @input="agreeDataProtection = !agreeDataProtection"
            />

            <dx-radio-check
              id="policyHolderMandate"
              v-model="policyHolderMandate"
              v-validate.disable="'required|is_true'"
              :checked="policyHolderMandate || false"
              no-background
              :label="$t('brokerOffer.summary.policyHolderMandate')"
              :error="errors.first('policyHolderMandate')"
              type="checkbox"
              name="policyHolderMandate"
              class="mb-5"
              data-vv-name="policyHolderMandate"
              @input="policyHolderMandate = !policyHolderMandate"
            />

            <template v-if="!quickOfferMode">
              <h4>{{ $t('brokerOffer.summary.brokerMandateUpload') }}</h4>
              <dx-upload
                ref="upload"
                :on-upload-success="(file) => onUploadSuccess(file)"
                remove-spacing
                remove-cancel-button
                :show-upload-button="false"
                class="mb-5"
                theme="light"
              />

              <h4>
                {{ $t('brokerOffer.summary.brokerDocumentDeliveryMethod') }}
              </h4>
              <dx-radio-list
                key="brokerDocumentDeliveryMethod"
                v-model="formData.brokerDocumentDeliveryMethod"
                v-validate.disable="'required'"
                :error="errors.first('brokerDocumentDeliveryMethod')"
                :options="deliveryOptionsBroker"
                name="brokerDocumentDeliveryMethod"
                class="mb-5"
                data-vv-name="brokerDocumentDeliveryMethod"
              />

              <h4>
                {{
                  $t('brokerOffer.summary.policyholderDocumentDeliveryMethod')
                }}
              </h4>
              <dx-radio-list
                key="policyholderDocumentDeliveryMethod"
                v-model="formData.policyholderDocumentDeliveryMethod"
                v-validate.disable="'required'"
                :error="errors.first('policyholderDocumentDeliveryMethod')"
                :options="deliveryOptionsPolicyholder"
                name="policyholderDocumentDeliveryMethod"
                data-vv-name="policyholderDocumentDeliveryMethod"
              />
            </template>
          </div>
        </div>

        <div
          v-if="premia.totalPremium.value"
          class="col-12 col-md-4 mt-5 p-3 d-block d-md-none dotted-border"
        >
          <div class="text-center">
            CHF
            <span class="big-price">
              {{ formatCurrency(premia.totalPremium.value) }}
            </span>
            <span class="d-block">
              {{ $t('offer.tax') }}
            </span>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 text-right">
            <button
              v-if="!quickOfferMode"
              class="btn-blank secondary-link d-md-inline-block"
              data-integrationtest="closeContract"
              @click="closeContract"
            >
              {{ $t('offer.summary.close') }}
            </button>
            <dx-button
              button-class="cmp-btn--forward"
              class="d-md-inline-block mb-3 mb-md-0 ml-md-4"
              data-integrationtest="saveOffer"
              :text="$t('offer.summary.saveOffer')"
              @click="saveOffer"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 d-none d-md-block">
        <dx-sticky-price-container
          :selected-tariff="activeTariff"
          :price="premia.totalPremium.value"
          is-broker-offer
          :on-tariff-change="changeTariff"
        />
      </div>
    </div>
    <dx-loading-overlay v-if="loading" global-loader />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  DxButton,
  DxRadioCheck,
  DxRadioList,
  DxTabs,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxProgressbar from '~/components/DX-Progressbar'
import DxDownloadList from '~/components/DX-DownloadList'
import DxEditDataToggle from '~/components/DX-EditDataToggle'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxUpload from '~/components/DX-Upload'
import DxPrivateBasicQuestions from '~/components/offer/DX-PrivateBasicQuestions'
import DxPolicyHolderDataForm from '~/components/offer/DX-PolicyHolderData'
import DxAssessmentQuestions from '~/components/offer/DX-AssessmentQuestions'
import DxBusinessBasicQuestions from '~/components/offer/DX-BusinessBasicQuestions'
import DxBusinessMoveBasicQuestions from '~/components/offer/DX-BusinessMoveBasicQuestions'
import DxBusinessPolicyHolderDataForm from '~/components/offer/DX-BusinessPolicyHolderData'
import DxStickyPriceContainer from '~/domains/partnerPortal/components/DX-StickyPriceContainer'
import scrollFirstErrorIntoView, {
  scrollIntoView,
} from '~/javascripts/helper/scollIntoView'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import { formatCurrency } from '~/javascripts/utils'

export default {
  name: 'BrokerOfferSummary',
  components: {
    DxProgressbar,
    DxDownloadList,
    DxEditDataToggle,
    DxRadioCheck,
    DxRadioList,
    DxButton,
    DxStickyPriceContainer,
    DxPrivateBasicQuestions,
    DxBusinessBasicQuestions,
    DxBusinessMoveBasicQuestions,
    DxPolicyHolderDataForm,
    DxBusinessPolicyHolderDataForm,
    DxAssessmentQuestions,
    DxLoadingOverlay,
    DxUpload,
    DxTabs,
  },
  mixins: [validationErrorsMixin],
  data() {
    return {
      assessmentQuestionsRequired: true,
      agreeDataProtection: !this.$store.state.brokerOffer.offerId ? null : true,
      activeTariff: this.$store.state.brokerOffer.activeTariff,
      radioOptions: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
      validForms: {
        basicQuestions: true,
        policyHolderData: true,
        answeredQuestions: true,
      },
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
      policyHolderMandate: !this.$store.state.brokerOffer.offerId ? null : true,
      formData: {
        brokerDocumentDeliveryMethod:
          this.$store.state.brokerOffer.brokerPolicyData
            .brokerDocumentDeliveryMethod,
        policyholderDocumentDeliveryMethod:
          this.$store.state.brokerOffer.brokerPolicyData
            .policyholderDocumentDeliveryMethod,
      },
      deliveryOptionsBroker: [
        {
          text: this.$t('offer.data.documentDeliveryMethods.DOWNLOAD'),
          id: 'DOWNLOAD',
        },
        {
          text: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
          id: 'EMAIL',
        },
        {
          text: this.$t('offer.data.documentDeliveryMethods.POST'),
          id: 'POST',
        },
      ],
      documents: [],
      formFilter: 'allFields',
      formFilterOptions: [
        {
          value: 'quickOffer',
          text: this.$t('brokerOffer.summary.quickOffer'),
          id: 'quickOffer',
        },
        {
          value: 'allFields',
          text: this.$t('brokerOffer.summary.allFields'),
          id: 'allFields',
        },
      ],
    }
  },
  computed: {
    premia() {
      return this.$store.state.brokerOffer.premia
    },
    tariffType() {
      return this.$store.getters.GET_ACTIVE_BROKER_TARIFF_TYPE
    },
    formRef() {
      let ref = 'businessMoveIntroForm'
      if (this.tariffType === 'private') {
        ref = 'privateIntroForm'
      } else if (this.tariffType === 'business') {
        ref = 'businessIntroForm'
      }
      return ref
    },
    formRefPolicyHolder() {
      return this.tariffType === 'private'
        ? 'policyHolderDataForm'
        : 'businessPolicyHolderDataForm'
    },
    getTariff() {
      switch (this.activeTariff[0]) {
        case 'p':
          return 'private'
        case 'b':
          return 'business'
        default:
          return 'smallBusiness'
      }
    },
    downloads() {
      const categories = Object.keys(this.$t('offer.downloads'))
      const downloads = []
      categories.forEach((category) => {
        const path = this.$t(
          `offer.downloads.${category}.${this.getTariff}.downloadFolder`,
        )
        const fileUrl = `${process.env.DEXTRA_WEBSITE_URL}/${path}/${this.$t(
          `offer.downloads.${category}.${this.getTariff}.file`,
        )}`
        downloads.push({
          filename: this.$t(
            `offer.downloads.${category}.${this.getTariff}.filename`,
          ),
          url: fileUrl,
        })
      })
      return downloads
    },
    getAvbFile() {
      const path = this.$t(
        `offer.downloads.avbData.${this.getTariff}.downloadFolder`,
      )
      return `${process.env.DEXTRA_WEBSITE_URL}/${path}/${this.$t(
        `offer.downloads.avbData.${this.getTariff}.file`,
      )}`
    },
    loading() {
      return this.$store.state.application.loading
    },
    deliveryOptionsPolicyholder() {
      const nonMailOptions = [
        {
          text: this.$t('offer.data.documentDeliveryMethods.POST'),
          id: 'POST',
        },
        {
          text: this.$t('offer.data.documentDeliveryMethods.BROKER'),
          id: 'BROKER',
        },
      ]
      if (this.$store.state.brokerOffer.noEmail) {
        return nonMailOptions
      }
      return [
        {
          text: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
          id: 'EMAIL',
        },
        ...nonMailOptions,
      ]
    },
    offerWasLoaded() {
      return !!this.$store.state.brokerOffer.offerId
    },
    quickOfferMode() {
      return this.formFilter === 'quickOffer'
    },
  },
  mounted() {
    this.calcPremia()
  },
  methods: {
    formatCurrency,
    async saveBasicQuestionsForm() {
      const valid = await this.$refs[this.formRef].validateForm()
      if (valid) {
        const result = await this.calcPremia()
        if (result) {
          this.$refs[this.formRef].saveForm()
          return result
        }
      }
      return false
    },
    resetBasicQuestionsForm() {
      this.$refs[this.formRef].resetForm()
      return true
    },
    async calcPremia() {
      const form = this.$refs[this.formRef]
      if (!form) {
        return false
      }
      form.$validator.reset()
      const actionKey = this.activeTariff.split('-').join('').toUpperCase()
      const action = `CALC_BROKER_${actionKey}_PREMIA`
      const payload = form.formData
      const result = await this.$store.dispatch(action, payload)
      if (result) {
        const errors = result.validationErrors
        if (errors.length) {
          if (!this.$refs.basicQuestionsToogle.editMode) {
            this.$refs.basicQuestionsToogle.toggleEditMode()
          }
          errors.forEach((err) => {
            form.$validator.errors.add(this.getServerValidationError(err))
          })
          return false
        }
        return true
      }
      // OR NETWORK ERROR:
      this.$notification.error({ messageKey: 'notifications.error.message' })
      return false
    },
    debouncedCalcPremia: debounce(async function callCalcPremia() {
      if (this.$refs && this.$refs[this.formRef]) {
        const valid = await this.$refs[this.formRef].validateForm()
        if (valid) {
          this.calcPremia()
        }
      }
    }, 1000),
    async saveAllForms(relaxedValidation = false) {
      const promises = []

      this.validForms.basicQuestions = true
      this.validForms.policyHolderData = true
      this.validForms.answeredQuestions = true

      if (this.$refs.basicQuestionsToogle.editMode) {
        promises.push(
          this.saveBasicQuestionsForm().then((result) => {
            this.validForms.basicQuestions = result
            return result
          }),
        )
      }

      if (this.$refs.policyHolderDataToogle.editMode) {
        const validation = relaxedValidation
          ? this.$refs[this.formRefPolicyHolder].validateToSaveBrokerOffer()
          : this.$refs[this.formRefPolicyHolder].saveForm()
        promises.push(
          validation.then((result) => {
            this.validForms.policyHolderData = result
            return result
          }),
        )
      }

      if (this.$refs.answeredQuestionsToogle.editMode) {
        promises.push(
          this.$refs.assessmentQuestionsForm.saveForm().then((result) => {
            this.validForms.answeredQuestions = result
            return result
          }),
        )
      }

      const filteredFields = relaxedValidation
        ? ['agreeDataProtection', 'policyHolderMandate']
        : null
      promises.push(
        this.$validator.validateAll(filteredFields).then((result) => {
          if (result) {
            this.$store.commit('SET_BROKER_POLICY_EXTRA_DATA', this.formData)
          }
          return result
        }),
      )

      return Promise.all(promises).then((values) =>
        values.every((value) => value),
      )
    },
    expandFormsAndScrollIntoView() {
      let scrolled = false
      if (!this.validForms.basicQuestions) {
        this.$refs.basicQuestionsToogle.editMode = true
        if (!scrolled) {
          scrollIntoView(this.$refs.basicQuestionsToogle.$el)
          scrolled = true
        }
      }
      if (!this.validForms.policyHolderData) {
        this.$refs.policyHolderDataToogle.editMode = true
        if (!scrolled) {
          scrollIntoView(this.$refs.policyHolderDataToogle.$el)
          scrolled = true
        }
      }
      if (!this.validForms.answeredQuestions) {
        this.$refs.answeredQuestionsToogle.editMode = true
        if (!scrolled) {
          scrollIntoView(this.$refs.answeredQuestionsToogle.$el)
          scrolled = true
        }
      }
      if (!scrolled) {
        if (!this.agreeDataProtection) {
          scrollIntoView(document.querySelector('#agreeDataProtection'))
        } else if (!this.policyHolderMandate) {
          scrollIntoView(document.querySelector('#policyHolderMandate'))
        } else {
          scrollFirstErrorIntoView()
        }
      }
    },
    handleValidationErrors(result) {
      const errors = result.validationErrors

      if (
        errors.find((error) => error.field.split('.')[0] === 'questionnaire')
      ) {
        this.validForms.basicQuestions = false
      }
      const policyholderErrors = errors.filter(
        (error) =>
          error.field.split('.')[0] === 'policyholder' ||
          error.field === 'mainExpiry',
      )
      if (policyholderErrors.length) {
        this.validForms.policyHolderData = false
        policyholderErrors.forEach((err) => {
          this.$refs[this.formRefPolicyHolder].$validator.errors.add(
            this.getServerValidationError(err),
          )
        })
      }
      if (
        errors.find((error) => error.field.split('.')[0] === 'riskQuestions')
      ) {
        this.validForms.answeredQuestions = false
      }

      this.expandFormsAndScrollIntoView()

      const errorKeys = []
      errors.forEach((err) => {
        if (!errorKeys.includes(err.key)) {
          this.$notification.error({
            messageKey: this.getValidationErrorMessageKey(err),
          })
          errorKeys.push(err.key)
        }
      })
    },
    async saveOffer() {
      this.errors.clear()
      this.assessmentQuestionsRequired = false
      this.$refs.policyHolderDataToogle.editMode = true
      await this.$nextTick()
      const hasNoClientErrors = await this.saveAllForms(true)
      if (hasNoClientErrors) {
        if (this.$refs.upload) {
          const uploaded = await this.$refs.upload.uploadFiles()
          if (!uploaded) {
            return
          }
        }
        const actionKey = this.activeTariff.split('-').join('').toUpperCase()
        const action = `SAVE_BROKER_${actionKey}_POLICY`
        const result = await this.$store.dispatch(action, {
          documents: this.documents,
        })
        if (result) {
          this.handleValidationErrors(result)

          if (result.success) {
            this.$store.commit('RESET_STORE_BROKER_OFFER')
            this.$router.push({
              path: this.localePath('PartnerPortal-OpenOffers'),
              query: { offerId: `${result.offerId}` },
            })
            this.$notification.success({
              messageKey: 'brokerOffer.summary.offerSaveSuccess',
            })
          }
        } else {
          // OR NETWORK ERROR:
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } else {
        this.expandFormsAndScrollIntoView()
      }
    },
    async closeContract() {
      this.errors.clear()
      // Make sure all potentially empty forms are opened before validation
      this.$refs.policyHolderDataToogle.editMode = true
      this.$refs.answeredQuestionsToogle.editMode = true
      this.assessmentQuestionsRequired = true
      await this.$nextTick()
      const hasNoClientErrors = await this.saveAllForms()
      if (hasNoClientErrors) {
        const uploaded = await this.$refs.upload.uploadFiles()
        if (!uploaded) {
          return
        }
        const actionKey = this.activeTariff.split('-').join('').toUpperCase()
        const action = `CREATE_BROKER_${actionKey}_POLICY`
        const result = await this.$store.dispatch(action, {
          documents: this.documents,
        })
        if (result) {
          this.handleValidationErrors(result)

          if (result.success) {
            // No tracking for broker closures yet
            this.$store.commit('SET_BROKER_STEP', 3)
            const query = result.underwriting
              ? { underwriting: `${result.underwriting}` }
              : {}
            this.$router.push({
              path: this.localePath('BrokerOffer-ThankYou'),
              query,
            })
          }
        } else {
          // OR NETWORK ERROR:
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } else {
        this.expandFormsAndScrollIntoView()
      }
    },
    onUploadSuccess({ id }) {
      this.documents = [id, ...this.documents]
    },
    async changeTariff() {
      // Save all form data without validation (except for basic questions)
      if (this.$refs.basicQuestionsToogle.editMode) {
        await this.saveBasicQuestionsForm()
      }
      if (this.$refs.policyHolderDataToogle.editMode) {
        this.$refs[this.formRefPolicyHolder].saveForm(true)
      }
      if (this.$refs.answeredQuestionsToogle.editMode) {
        this.$refs.assessmentQuestionsForm.saveForm(true)
      }
      this.$store.commit('SET_BROKER_POLICY_EXTRA_DATA', this.formData)
      this.$router.push({ path: this.localePath('BrokerOffer-ChangeTariff') })
    },
  },
  meta: {
    offerStep: 2,
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsschutz-antragsstrecke/zusammenfassung/',
      fr: '/portailpartenaires/protection-juridique-formulaire-de-demande/resume/',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
