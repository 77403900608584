<template>
  <div class="modalContentWrapper">
    <div
      v-for="block in contentBlocks"
      :key="block.id"
      :class="{ simpleContent: block.__typename === 'TeaserContent' }"
    >
      <inclusive-list
        v-if="block.__typename === 'ContentInclusiveList'"
        :headline="block.headline"
        :list="correctedList(block.list)"
      />
      <icon-and-paragraph
        v-else-if="block.__typename === 'ListEntry'"
        icon-style="checkmark"
        :headline="block.headline"
        :subline="block.text"
        inverted
      />
      <icon-and-paragraph
        v-else-if="block.__typename === 'ProductDetail'"
        icon-style="checkmark"
        :headline="block.headline"
        :subline="block.subline"
        :amount="block.price"
        :amount-additional="block.priceAdditional"
        inverted
      />
      <div v-else>
        <h4 v-if="block.headline">{{ block.headline }}</h4>
        <h5 v-if="block.subline || block.subline2">
          {{ block.subline }}{{ block.subline2 }}
        </h5>
        <div
          v-if="block.description && block.description.html"
          v-html="block.description.html"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  InclusiveList,
  IconAndParagraph,
} from '@sumcumo/dextra-frontend-component-lib'

export default {
  name: 'DxContentBlockRenderer',

  components: {
    IconAndParagraph,
    InclusiveList,
  },

  props: {
    contentBlocks: {
      type: Array,
      required: true,
    },
  },

  methods: {
    correctedList(list) {
      return list.map((item) => ({
        text: item,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.modalContentWrapper {
  display: grid;
  padding: rem(24);
  grid-gap: rem(16);
  grid-template-columns: 1fr;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
  }

  .simpleContent {
    grid-column: 1 / -1;
  }

  ::v-deep {
    > div {
      font-family: $font-family;
      @include media-breakpoint-down(lg) {
        font-size: rem($font-size-small);
        line-height: rem($font-line-height-small);
      }
      > div {
        margin-bottom: rem(24);
        padding: 0 !important;
      }
    }

    /*
      Headlines
    */
    h1,
    h2,
    h3,
    .h4-primary {
      font-family: $font-family-headlines;
      font-weight: 400;
    }

    .h1-secondary,
    a,
    h4,
    h5,
    h6 {
      font-family: $font-family-heavy;
    }

    h1 {
      font-size: rem(40);
      line-height: rem(40);

      @include media-breakpoint-up(lg) {
        font-size: rem(72);
        line-height: rem(72);
      }
    }

    h2 {
      font-size: rem(32);
      line-height: rem(36);

      @include media-breakpoint-up(lg) {
        font-size: rem(64);
        line-height: rem(68);
      }
    }

    h3,
    .h3 {
      font-size: rem(28);
      line-height: rem(32);

      @include media-breakpoint-up(lg) {
        font-size: rem(48);
        line-height: rem(48);
      }
    }

    h4 {
      font-size: rem(24);
      line-height: rem(32);

      @include media-breakpoint-up(lg) {
        font-size: rem(32);
        line-height: rem(32);
      }
    }

    h5 {
      font-size: rem(18);
      line-height: rem(20);

      @include media-breakpoint-up(lg) {
        font-size: rem(24);
        line-height: rem(28);
      }
    }

    h6 {
      font-size: rem(14);
      line-height: rem(14);

      @include media-breakpoint-up(lg) {
        font-size: rem(16);
        line-height: rem(24);
      }
    }

    /*
      Base a-tag styling
    */
    a {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: rem(-1);
        left: 0;
        width: 100%;
        height: rem(1);
        background-color: $black-clr;
      }

      &:hover {
        text-decoration: none;

        &::after {
          background-color: $neutral-clr-800;
        }
      }

      &.font-white {
        &::after {
          background-color: $white-clr;
        }

        &:hover {
          color: $neutral-clr-200;

          &::after {
            background-color: $neutral-clr-200;
          }
        }
      }

      &.font-small {
        &::after {
          bottom: rem(-2);
        }
      }
    }

    strong {
      font-family: $font-family-heavy;
    }
    .check-list li {
      position: relative;
      list-style: none;
      &::before {
        display: block;
        position: absolute;
        content: '';
        top: rem(-2);
        left: rem(-32);
        width: rem(32);
        height: rem(32);
        background-image: url(~@/assets/images/icons/checkmark-yellow.svg);
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
