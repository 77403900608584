<template>
  <div
    class="scip-sales-content-item"
    :class="{ 'info-box': type === 'infoBox' }"
  >
    <info-icon
      v-if="type === 'infoBox'"
      :height="22"
      :width="22"
      class="info-box-icon"
    />
    <div v-if="content">
      <h5 v-if="content.headline">{{ content.headline }}</h5>
      <div v-html="content.description.html" />
    </div>
    <div v-else class="rich-text" v-html="item.richText" />
  </div>
</template>

<script>
import { toRefs, toRef } from '@vue/composition-api'

import { defineCustomFormBuilderParams } from '@scip-sales/application-form'

import InfoIcon from '@/components/icons/InfoIcon'
import teaserContentBySlugQuery from '@/domains/application/__gql__/queries/teaserContentBySlug.gql'

import { useScipTranslationWithFallback } from '../../utils'

const { useCustomParameters } = defineCustomFormBuilderParams('ContentItem', {
  type: {
    label: 'Typ',
    type: 'select',
    options: [
      { text: 'Standard', value: 'standard' },
      { text: 'Info-Box', value: 'infoBox' },
    ],
  },
})

export default {
  name: 'DXContentItemAdapter',
  components: {
    InfoIcon,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    const { type } = useCustomParameters(toRef(props, 'item'))

    return {
      ...translation,
      type,
    }
  },

  data() {
    return {
      content: null,
    }
  },

  mounted() {
    this.fetchContent()
  },
  methods: {
    async fetchContent() {
      if (!this.item.richText?.match(/^graphcms:.*/)) {
        this.content = null
        return
      }

      const { data } = await this.$apolloProvider.clients.cms.query({
        query: teaserContentBySlugQuery,
        variables: {
          slug: this.item.richText.substring('graphcms:'.length),
          locales: [this.$i18n.locale],
        },
        fetchPolicy: 'no-cache',
      })

      if (data?.teaserContent) {
        this.content = data.teaserContent
      } else {
        this.content = null
      }
    },
  },
}
</script>

<style lang="scss">
.scip-sales-content-item {
  &.info-box {
    display: flex;
    border: 0.125rem solid #b3b5bd;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    align-items: flex-start;
    background-color: #f4f4f8;

    .info-box-icon {
      margin-right: 1rem;
      overflow: visible;
    }
  }

  ul {
    margin-bottom: 0;
    list-style: none;

    li {
      margin-bottom: 2rem;
    }
    li:last-of-type {
      margin-bottom: 0;
    }
  }

  p:empty {
    display: none;
  }
}
</style>
