var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dx-accordion',{staticClass:"mb-2",attrs:{"is-init-open":_vm.isOpen,"on-open":_vm.loadDetails,"allow-text-select":""}},[_c('template',{slot:"headline"},[(_vm.template === 'partnerPortal')?_c('div',{staticClass:"row headline"},[_c('div',{staticClass:"col-12 col-md-3"},[_vm._v("\n          "+_vm._s(_vm.contract.policyholder.fullName)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-3"},[_vm._v("\n          "+_vm._s(_vm.contract.number)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-2"},[_vm._v("\n          "+_vm._s(_vm.contract.options || _vm.contract.productName)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-3"},[_vm._v("\n          "+_vm._s(_vm.formatPriceOrStatus(_vm.contract))+"\n          "),(_vm.brokerPolicyHasWarning)?_c('div',{staticClass:"status-warning"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-1"},[_c('div',{class:_vm.contract.policyholder.hasEbill ? 'icon-check' : 'icon-cross'})])]):_c('div',{staticClass:"d-md-flex align-items-center"},[_c('div',{staticClass:"mr-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.contract.insuring ? '' : _vm.$t('noLongerInsured'))+"\n          #"+_vm._s(((_vm.contract.id) + "  " + (_vm.contract.productLabel)))+"\n        ")])])]),_vm._v(" "),_c('template',{slot:"content"},[(
          (_vm.template === 'partnerPortal' && _vm.brokerPolicyDetails.id) ||
          (_vm.isOwnContract && !_vm.isGm)
        )?_c('dx-policy-holder-options',{attrs:{"template":_vm.template,"contract":_vm.template === 'partnerPortal' ? _vm.brokerPolicyDetails : _vm.contract},on:{"details-saved":function () { return _vm.loadDetails(true); }}}):_vm._e(),_vm._v(" "),(_vm.isLoading && !_vm.brokerPolicyDetails.id)?_c('dx-loading-overlay'):_vm._e(),_vm._v(" "),(_vm.template !== 'partnerPortal' || _vm.brokerPolicyDetails.id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mt-4"},[_c('dx-claim-excerpt-list',{attrs:{"claims":(_vm.template === 'partnerPortal' ? _vm.brokerPolicyDetails : _vm.contract)
                .claims,"subscribed-close-claim":_vm.subscribedCloseClaim,"is-broker":_vm.template === 'partnerPortal'}})],1)]):_vm._e(),_vm._v(" "),(_vm.contract.insuring)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('dx-button',{staticClass:"my-3 mb-md-4 mt-md-5",attrs:{"small":true,"text":_vm.$t('reportNewDamage'),"route-name":_vm.localePath({
                name: 'SelfService-ReportDamage-MyInfo',
                query: { contract: _vm.contract.id },
              }),"theme":"wine","icon":"lightning"}})],1)]):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }