<template>
  <div class="container-fluid modulaStyles">
    <div class="content-wrap-small content-pad">
      <div class="mt-3 mt-md-1">
        <span class="arrow-left overview" @click="historyBack()">
          {{ $t('backToLogin') }}
        </span>
      </div>
    </div>
    <div class="content-wrap-small content-pad">
      <div class="mb-4 mb-md-5">
        <h2 class="headline">
          {{ $t('pwReset.headline') }}
        </h2>
        <p class="md-3 mb-md-5 subheadline">
          {{ $t('pwReset.subHeadline') }}
        </p>
      </div>
      <dx-pw-reset />
    </div>
  </div>
</template>

<script>
import DxPwReset from '~/domains/authentication/components/DX-PwReset'

export default {
  name: 'PwReset',
  meta: {
    isPublic: true,
  },
  components: {
    DxPwReset,
  },
  layout: 'modulaStylePlain',
  methods: {
    historyBack() {
      window.history.back()
    },
  },
  nuxtI18n: {
    paths: {
      de: '/pw-zuruecksetzen',
      fr: '/mot-de-passe-oublie',
      it: '/reimposta-password',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/styles/newModulaTypoStyles.scss';
</style>
