<template>
  <div class="dashboard-wrap">
    <dx-head-blue
      id="personal"
      :headline="$t('myAccount')"
      :button-text="$t('reportDamageLegalAdvice')"
      :route-name="'SelfService-ReportDamage-MyInfo'"
      :textblock="$t('myAccountSubheadline')"
      hide-text-on-mobile
      button-icon="lightning"
    />

    <!-- Claims & Policies Blocks -->
    <div class="container-fluid content-wrap-medium content-pad">
      <dx-claims-policies-blocks
        :has-claims="hasClaims"
        :has-contracts="hasContracts"
        :claims-loading="claimsLoading"
      />
    </div>

    <!-- Claims Container -->
    <div class="content-bg-color">
      <a id="claims" class="anchor-claims" />
      <div class="container-fluid content-wrap-medium content-pad">
        <dx-claims-overview
          :open-claims="claims"
          :claims-loading="claimsLoading"
        />
      </div>
    </div>

    <!-- Policies Container -->
    <div class="content-bg-color pb-5">
      <a id="policies" class="anchor-policies" />
      <div class="container-fluid content-wrap-medium">
        <dx-contracts-overview @claimsLoaded="handleClaims" />
      </div>
    </div>
  </div>
</template>

<script>
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxClaimsPoliciesBlocks from '~/domains/contract/components/DX-ClaimsPoliciesBlocks'
import DxContractsOverview from '~/domains/contract/components/DX-ContractsOverview'
import DxClaimsOverview from '~/domains/contract/components/DX-ClaimsOverview'

export default {
  name: 'SelfServiceStart',
  components: {
    DxHeadBlue,
    DxClaimsPoliciesBlocks,
    DxContractsOverview,
    DxClaimsOverview,
  },
  data() {
    return {
      claimsCount: 0,
      claimsLoading: true,
      claims: [],
    }
  },
  computed: {
    hasClaims() {
      return this.claimsCount > 0
    },
    hasContracts() {
      return this.$store.state.application.hasContracts
    },
  },
  methods: {
    handleClaims({ claims, loading }) {
      this.claimsLoading = loading
      this.claimsCount = claims.length
      this.claims = claims
    },
  },
  nuxtI18n: {
    paths: {
      de: '/kundencenter',
      fr: '/centre-clients',
      it: '/centro-clienti',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
