<template>
  <div>
    <!-- NON EDIT MODE -->
    <template v-if="!editable">
      <ul class="neutral mb-0">
        <li>************</li>
      </ul>
    </template>

    <!-- EDIT MODE -->
    <template v-if="editable">
      <div class="password-form">
        <dx-input v-model="email" class="hidden" type="email" disabled />
        <dx-input
          v-model="form.oldPassword"
          v-validate.disable="'required|min:8'"
          :label="$t('passwordOld')"
          :error="errors.first('oldPassword')"
          data-vv-name="oldPassword"
          type="password"
          class="mb-4"
          autocomplete="current-password"
        />

        <dx-input
          v-model="form.newPassword"
          v-validate.disable="
            `required|min:8|not_match_strings:${form.oldPassword}`
          "
          :label="$t('passwordChange')"
          :error="errors.first('newPassword')"
          data-vv-name="newPassword"
          type="password"
          class="mb-4"
          autocomplete="new-password"
        />

        <dx-input
          v-model="form.newPasswordRepeat"
          v-validate.disable="
            `required|min:8|match_strings:${form.newPassword}`
          "
          :label="$t('passwordRepeat')"
          :error="errors.first('newPasswordRepeat')"
          data-vv-name="newPasswordRepeat"
          type="password"
          autocomplete="new-password"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { DxInput } from '@sumcumo/dextra-frontend-component-lib-old'
import passwordChangeMutation from '~/domains/authentication/__gql__/mutations/changePassword.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'

export default {
  name: 'DxPasswordForm',
  components: {
    DxInput,
  },
  mixins: [loadingMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        oldPassword: null,
        newPassword: null,
        newPasswordRepeat: null,
      },
    }
  },
  computed: {
    email() {
      return (
        this.$store.state.application.currentUser &&
        this.$store.state.application.currentUser.username
      )
    },
  },
  methods: {
    async changePassword() {
      let result = false
      const validationResult = await this.$validator.validateAll()
      if (validationResult) {
        const variables = {
          oldPassword: this.form.oldPassword,
          newPassword: this.form.newPassword,
          newPasswordRepeat: this.form.newPasswordRepeat,
        }
        this.setGlobalLoading(true)
        try {
          const { data } = await this.$apollo.mutate({
            mutation: passwordChangeMutation,
            variables,
          })

          if (data.changePassword) {
            this.form = {
              oldPassword: null,
              newPassword: null,
              newPasswordRepeat: null,
            }
            this.$notification.success({ messageKey: 'passwordChangedSuccess' })
            result = true
          } else {
            this.$validator.errors.add({
              field: 'oldPassword',
              msg: this.$t('passwordInvalid'),
              rule: 'server',
            })
            scrollFirstErrorIntoView()
          }
        } catch (err) {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        } finally {
          this.setGlobalLoading(false)
        }
      } else {
        scrollFirstErrorIntoView()
      }
      return result
    },
  },
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
