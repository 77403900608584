<template>
  <div>
    <!-- NON EDIT MODE -->
    <div v-if="!editable" class="personal-data-form">
      <ul v-if="!loading" class="neutral">
        <li v-if="brokerData.companyName">
          {{ `${$t('company')} ${brokerData.companyName}` }}
        </li>
        <li v-if="formattedSalutation">
          {{ formattedSalutation }}
        </li>
        <li v-if="brokerData.address.street">
          {{ `${brokerData.address.street} ${brokerData.address.number}` }}
        </li>
        <li v-if="brokerData.address.zip">
          {{ `${brokerData.address.zip} ${brokerData.address.city}` }}
        </li>
        <li v-if="brokerData.contactDetails.phone" class="mt-3">
          <h5 class="mb-0">{{ $t('telephone') }}:</h5>
          {{ brokerData.contactDetails.phone }}
        </li>
        <li v-if="brokerData.contactDetails.mobile" class="mt-3">
          <h5 class="mb-0">{{ $t('mobilePhone') }}:</h5>
          {{ brokerData.contactDetails.mobile }}
        </li>
        <li class="mt-3">
          <h5 class="mb-0">{{ $t('finmaNr') }}:</h5>
          {{ brokerData.finmaId || '-' }}
        </li>
      </ul>
      <dx-loading-overlay v-if="loading" />
    </div>

    <!-- EDIT MODE -->
    <div v-else>
      <dx-radio-list
        v-if="isSinglePerson"
        v-model="brokerData.salutation"
        :options="salutationOptions"
        :disable-list="true"
        :label="$t('salutation')"
        name="personal_data_gender"
        class="mb-4"
      />

      <dx-input
        v-if="isCompany"
        v-model="brokerData.companyName"
        v-validate.disable="'required|min:2'"
        :label="$t('company')"
        class="mb-4"
        data-vv-name="companyName"
        :error="errors.first('companyName')"
      />

      <dx-input
        v-if="isSinglePerson"
        v-model="brokerData.firstName"
        v-validate.disable="'required|min:2'"
        :label="$t('firstName')"
        class="mb-4"
        autocomplete="given-name"
        data-vv-name="firstName"
        :error="errors.first('firstName')"
      />

      <dx-input
        v-if="isSinglePerson || isFamily"
        v-model="brokerData.lastName"
        v-validate.disable="'required|min:2'"
        :label="$t('lastName')"
        class="mb-4"
        autocomplete="family-name"
        data-vv-name="lastName"
        :error="errors.first('lastName')"
      />

      <div class="row">
        <div class="col-6 pr-0">
          <dx-input
            v-model="brokerData.address.street"
            v-validate.disable="'required|min:2'"
            :label="$t('street')"
            :error="errors.first('street')"
            data-vv-name="street"
            class="mb-4"
            autocomplete="address-line1"
          />
        </div>
        <div class="col-6">
          <dx-input
            v-model="brokerData.address.number"
            v-validate.disable="'required|min:1'"
            :error="errors.first('streetNumber')"
            :label="$t('houseNumber')"
            data-vv-name="streetNumber"
            class="mb-4"
            autocomplete="address-line2"
          />
        </div>
      </div>

      <dx-zip-to-city-input
        :zip-input="brokerData.address.zip"
        :city-input="brokerData.address.city"
        :required="true"
        icon="orange-cross"
        @update:city="setCity"
        @update:zip="setZip"
      />

      <div class="row">
        <div class="col-6 pr-0">
          <dx-input
            v-model="brokerData.contactDetails.phone"
            v-validate.disable="
              `${
                brokerData.contactDetails.mobile &&
                brokerData.contactDetails.mobile.trim()
                  ? ''
                  : 'required|min:4'
              }`
            "
            :label="$t('telephone')"
            :error="errors.first('mobileOrPhoneNumber')"
            class="mb-4"
            data-vv-name="mobileOrPhoneNumber"
          />
        </div>
        <div class="col-6">
          <dx-input
            v-model="brokerData.contactDetails.mobile"
            v-validate.disable="
              `${
                brokerData.contactDetails.phone &&
                brokerData.contactDetails.phone.trim()
                  ? ''
                  : 'required|min:4'
              }`
            "
            :label="$t('mobilePhone')"
            :error="errors.first('mobileOrPhoneNumber')"
            data-vv-name="mobileOrPhoneNumber"
            class="mb-4"
            autocomplete="tel"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 pr-0">
          <dx-input
            v-model="brokerData.finmaId"
            v-validate.disable="'required'"
            :label="$t('finmaNr')"
            :error="errors.first('finmaId')"
            data-vv-name="finmaId"
            class="mb-4"
          />
        </div>
      </div>

      <template v-if="isCompany">
        <h5 class="mb-4">
          {{ $t('contactPerson') }}
        </h5>
        <dx-radio-list
          v-model="brokerData.contactPerson.salutation"
          v-validate.disable="'required'"
          :options="contactPersonSalutationOptions"
          :label="$t('salutation')"
          name="contact_person_gender"
          class="mb-4"
          data-vv-scope="contactPerson"
          data-vv-name="salutation"
          :error="errors.first('salutation', 'contactPerson')"
        />

        <dx-input
          v-model="brokerData.contactPerson.firstName"
          v-validate.disable="'required|min:2'"
          :label="$t('firstName')"
          class="mb-4"
          autocomplete="given-name"
          data-vv-name="firstName"
          data-vv-scope="contactPerson"
          :error="errors.first('firstName', 'contactPerson')"
        />

        <dx-input
          v-model="brokerData.contactPerson.lastName"
          v-validate.disable="'required|min:2'"
          :label="$t('lastName')"
          class="mb-4"
          autocomplete="family-name"
          data-vv-name="lastName"
          data-vv-scope="contactPerson"
          :error="errors.first('lastName', 'contactPerson')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxZipToCityInput from '~/components/DX-ZipToCityInput'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import updateBrokerMutation from '~/domains/partnerPortal/__gql__/mutations/updateBrokerMutation.gql'
import profileQuery from '~/domains/partnerPortal/__gql__/queries/profileBroker.gql'

import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import dateMixin from '~/javascripts/mixins/dateMixin'

export default {
  name: 'DxPersonalDataForm',
  components: {
    DxZipToCityInput,
    DxInput,
    DxRadioList,
    DxLoadingOverlay,
  },
  mixins: [loadingMixin, dateMixin, validationErrorsMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    onBankAccountErrors: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      salutationOptions: [
        {
          text: this.$t('male'),
          id: 'MALE',
        },
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
        {
          text: this.$t('family'),
          id: 'FAMILY',
        },
        {
          text: this.$t('company'),
          id: 'COMPANY',
        },
      ],
      contactPersonSalutationOptions: [
        {
          text: this.$t('male'),
          id: 'MALE',
        },
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
      ],
      brokerData: {
        companyName: '',
        salutation: '',
        firstName: '',
        lastName: '',
        address: {
          street: '',
          number: '',
          zip: '',
          city: '',
        },
        contactDetails: {
          phone: '',
          mobile: '',
        },
        bankAccount: {
          iban: '',
          bic: '',
          accountHolder: '',
        },
        contactPerson: {
          salutation: '',
          firstName: '',
          lastName: '',
        },
        locale: '',
        finmaId: '',
      },
      loading: true,
      editableFields: [
        'firstName',
        'lastName',
        'companyName',
        'address',
        'contactDetails',
        'finmaId',
        'contactPerson',
      ],
    }
  },
  computed: {
    isCompany() {
      const salutation = (this.brokerData.salutation || '').toUpperCase()
      return salutation === this.$config.salutations.company
    },
    isFamily() {
      const salutation = (this.brokerData.salutation || '').toUpperCase()
      return salutation === this.$config.salutations.family
    },
    isSinglePerson() {
      const salutation = (this.brokerData.salutation || '').toUpperCase()
      return (
        salutation === this.$config.salutations.male ||
        salutation === this.$config.salutations.female
      )
    },
    formattedSalutation() {
      let salutationBlock = ''
      const { firstName, lastName, salutation } = this.brokerData

      if (this.isCompany) {
        salutationBlock = `${firstName || ''} ${lastName || ''}`
      } else if (this.isFamily) {
        salutationBlock = `${this.$t('family')} ${lastName || ''}`
      } else {
        salutationBlock = `${
          salutation ? this.$t(salutation.toLowerCase()) : ''
        } ${firstName || ''} ${lastName || ''}`
      }
      return salutationBlock
    },
  },
  methods: {
    editAddressCallback(bankAccount, deliveryMethods) {
      this.brokerData.bankAccount = { ...bankAccount }
      this.brokerData.deliveryMethods = { ...deliveryMethods }
      return this.updateBrokerMutationFunction()
    },
    cancelCallback() {
      this.loading = true
      return this.$apollo.queries.profile.refetch()
    },
    setZip(val) {
      this.brokerData.address.zip = val
    },
    setCity(val) {
      this.brokerData.address.city = val
    },
    async updateBrokerMutationFunction() {
      let result = false
      const isValid = await this.$validator.validate()
      if (isValid) {
        const broker = {
          salutation: this.brokerData.salutation,
          companyName: this.brokerData.companyName,
          firstName: this.brokerData.firstName,
          lastName: this.brokerData.lastName,
          address: this.brokerData.address,
          contactDetails: this.brokerData.contactDetails,
          bankAccount: this.brokerData.bankAccount,
          locale: this.$i18n.locale.toUpperCase(),
          finmaId: this.brokerData.finmaId,
        }
        if (this.isCompany) {
          broker.contactPerson = {
            ...this.brokerData.contactPerson,
            locale: this.$i18n.locale.toUpperCase(),
          }
          delete broker.contactPerson.id
        }
        try {
          this.setGlobalLoading(true)
          const { data } = await this.$apollo.mutate({
            mutation: updateBrokerMutation,
            variables: {
              broker,
            },
          })
          if (data && data.updateBroker) {
            const { validationErrors, success } = data.updateBroker
            if (validationErrors && validationErrors.length) {
              const bankAccountErrors = []
              validationErrors.forEach((err) => {
                if (err.field.indexOf('bankAccount.') === 0) {
                  bankAccountErrors.push(err)
                } else {
                  this.$validator.errors.add(this.getServerValidationError(err))
                }
              })
              if (bankAccountErrors.length) {
                this.onBankAccountErrors(bankAccountErrors)
              } else {
                scrollFirstErrorIntoView()
              }
            } else if (success) {
              this.finishUpdateMutation()
              result = true
            }
          }
        } catch (error) {
          this.$notification.error({
            messageKey: 'userData.addressMutationError',
          })
          result = false
        } finally {
          this.setGlobalLoading(false)
        }
      } else {
        scrollFirstErrorIntoView()
      }
      return result
    },
    async finishUpdateMutation() {
      const result = await this.$apollo.queries.profile.refetch()
      if (result) {
        this.setGlobalLoading(false)
        this.$notification.success({
          messageKey: 'userData.addressMutationSuccess',
        })
      }
    },
    migrateDataWithState(data) {
      const { broker, username } = data.profile
      const sanitizedData = JSON.parse(
        JSON.stringify(data, (k, v) => (k === '__typename' ? undefined : v)),
      )
      const newBrokerData = sanitizedData.profile.broker

      this.$store.commit('SET_CURRENT_BROKER', {
        currentBroker: { ...broker, username },
      })

      const keys = Object.keys(newBrokerData)
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        // in editmode only update fields that are not in scope of this form
        if (
          newBrokerData[key] &&
          newBrokerData[key] !== this.brokerData[key] &&
          (!this.editableFields.includes(key) || !this.editable)
        ) {
          this.brokerData[key] = newBrokerData[key]
        }
      }

      this.setGlobalLoading(false)
      this.loading = false
    },
    displayErrors(validationErrors) {
      validationErrors.forEach((err) => {
        this.$validator.errors.add(this.getServerValidationError(err))
      })
      scrollFirstErrorIntoView()
    },
  },
  apollo: {
    profile: {
      query: profileQuery,
      fetchPolicy: 'network-only',
      result({ data }) {
        this.migrateDataWithState(data)
      },
    },
  },
}
</script>
