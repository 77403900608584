<template>
  <div class="dx-radiogroupadapter">
    <dx-description-label v-if="translatedLabel">
      {{ translatedLabel }}
    </dx-description-label>
    <dx-radio-buttons
      :id="`scip-${item.id}`"
      :options="mappedRadioOptions"
      :value="value"
      :error="translatedError"
      @change="update"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import { DxRadioButtons } from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DXRadioGroupAdapter',
  components: {
    DxDescriptionLabel,
    DxRadioButtons,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))

    return {
      ...translation,
    }
  },

  computed: {
    mappedRadioOptions() {
      return this.mapOptions(this.item)
    },
    translatedError() {
      if (!this.error) {
        return null
      }

      const errorOverrideKey = `${this.translationKeyPrefix}.error`
      if (this.te(errorOverrideKey)) {
        return this.t(errorOverrideKey)
      }

      return this.error
        ? this.t(
            `errors.selectRadio.${
              this.value === null ? 'required' : this.error.type
            }`,
            {
              ...this.error.params,
              label: this.translatedLabel,
            },
          )
        : null
    },
  },

  methods: {
    update(newValue) {
      this.$emit('input', newValue)
    },

    onBlur() {
      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.dx-radiogroupadapter ::v-deep label.button span.text {
  font-family: Roboto, sans-serif;
}
</style>
