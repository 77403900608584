<template>
  <div class="container-fluid content-wrap-medium pt-2 pt-md-5">
    <a id="dextraMails" class="mailbox-anchors" />
    <div class="row pb-2 pb-md-5">
      <div class="col">
        <h3
          class="position-relative mb-2"
          @click="() => (inboxDextraOpen = !inboxDextraOpen)"
        >
          <span
            :class="{ 'mailbox-arrow--active': inboxDextraOpen }"
            class="mailbox-arrow"
          />
          {{ $t('partnerPortal.damageManagement.messagesFromDextra') }}
        </h3>
        <template v-if="inboxDextraOpen">
          <dx-mailbox
            :mail-data="insuredPersonDextraMessages"
            :read-only="claimLocked"
            :send-new-message="sendMessage"
            :reply="replyWithMessage"
            :on-unread-item-click="markAsRead"
            :show-status-flag="false"
          />
        </template>
        <template v-else>
          <div v-if="!messagesDextraLoading">
            {{
              Array.isArray(insuredPersonDextraMessages) &&
              insuredPersonDextraMessages.length > 0
                ? $t('noUnreadMails')
                : $t('noMails')
            }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DxMailbox from '~/partials/DX-Mailbox'

import markCommentAsRead from '~/domains/contract/claim/__gql__/mutations/markCommentAsRead.gql'
import replyMessageMutation from '~/domains/contract/claim/__gql__/mutations/replyMessage.gql'
import insuredPersonDextraMessagesQuery from '~/domains/contract/claim/__gql__/queries/insuredPersonDextraMessages.gql'
import createInsuredPersonDextraMessageMutation from '~/domains/contract/claim/__gql__/mutations/createInsuredPersonDextraMessage.gql'

export default {
  name: 'DxPartnerMailbox',
  components: {
    DxMailbox,
  },
  props: {
    claimId: {
      type: Number,
      default: null,
    },
    claimLocked: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inboxDextraOpen: true,
      messagesDextraLoading: true,
    }
  },
  methods: {
    async markAsRead(comment) {
      try {
        await this.$apollo.mutate({
          mutation: markCommentAsRead,
          variables: {
            commentId: Number(comment.id),
            claimId: Number(comment.claimId),
          },
        })
      } catch (error) {
        console.error(error)
      }
    },

    async sendMessage(text) {
      await this.$apollo.mutate({
        mutation: createInsuredPersonDextraMessageMutation,
        variables: {
          claimId: Number(this.claimId),
          message: {
            text,
          },
        },
      })

      this.$apollo.queries.insuredPersonDextraMessages.refetch()
    },

    async replyWithMessage(item, text) {
      await this.$apollo.mutate({
        mutation: replyMessageMutation,
        variables: {
          claimId: Number(item.claimId),
          messageId: item.id,
          message: {
            text,
          },
        },
      })

      this.$apollo.queries.insuredPersonDextraMessages.refetch()
    },
  },
  apollo: {
    insuredPersonDextraMessages: {
      query: insuredPersonDextraMessagesQuery,
      variables() {
        return {
          claimId: Number(this.claimId),
        }
      },
      result() {
        this.messagesDextraLoading = false
      },
      error() {
        this.hasErrors = true
        this.messagesDextraLoading = false
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
