<template>
  <div>
    <component
      :is="inputComponent"
      v-model="ibanInput"
      v-validate.disable="'required|validate_IBAN'"
      :error="errors.first('iban', dataVvScope)"
      :label="$t('iban')"
      :class="cssClass"
      inverted
      data-vv-name="iban"
      :data-vv-scope="dataVvScope"
      @input="fetchBic"
    />

    <component
      :is="inputComponent"
      v-if="!hideBic"
      v-model="bicInput"
      v-validate.disable="'required|validate_BIC'"
      :error="errors.first('bic', dataVvScope)"
      :label="$t('bic')"
      :class="cssClass"
      inverted
      data-vv-name="bic"
      :data-vv-scope="dataVvScope"
    />
  </div>
</template>

<script>
import { DxInput as DxInputOld } from '@sumcumo/dextra-frontend-component-lib-old'
import { DxInput } from '@sumcumo/dextra-frontend-component-lib'
import ibanValidator from '~/javascripts/validations/rules/IbanValidator'
import bicQuery from './__gql__/queries/bic.gql'

export default {
  name: 'DxIbanBicInput',
  components: {
    DxInput,
    DxInputOld,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    hideBic: {
      type: Boolean,
      default: false,
    },
    iban: {
      type: String,
      default: '',
    },
    bic: {
      type: String,
      default: '',
    },
    cssClass: {
      type: String,
      default: '',
    },
    dataVvScope: {
      type: String,
      default: null,
    },
    withModulaStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bicInput: this.bic,
      ibanInput: this.iban,
    }
  },
  computed: {
    inputComponent() {
      return this.withModulaStyle ? 'DxInput' : 'DxInputOld'
    },
  },
  watch: {
    ibanInput: function updateIban(val) {
      this.$emit('update:iban', this.formatValue(val))
    },
    bicInput: function updateBic(val) {
      this.$emit('update:bic', this.formatValue(val))
    },
  },
  mounted() {
    if ((this.bicInput || '') === '') {
      this.fetchBic()
    }
  },
  methods: {
    async fetchBic() {
      if (this.hideBic) {
        return
      }
      const iban = this.ibanInput || ''
      if (ibanValidator(iban)) {
        try {
          const result = await this.$apollo.query({
            query: bicQuery,
            variables: {
              iban: this.formatValue(iban),
            },
          })
          if ((result.data.bic || '') !== '') {
            this.$validator.errors.remove('bic')
            this.bicInput = result.data.bic
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    formatValue(value) {
      return value.replace(/ /g, '').toUpperCase()
    },
  },
}
</script>
