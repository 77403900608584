<script>
export default {
  methods: {
    getInvoices(contract) {
      const { invoice } = this.$config.fileTypes
      return contract && contract.documents
        ? contract.documents.filter((document) => document.fileType === invoice)
        : []
    },
    getOtherDocuments(contract) {
      const { policy, avb } = this.$config.fileTypes
      return contract && contract.documents
        ? contract.documents.filter((document) =>
            [policy, avb].includes(document.fileType),
          )
        : []
    },
  },
}
</script>
