var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-border-bottom pt-4 pb-5 mb-4"},[_c('div',{staticClass:"col-12"},[_c('h5',[_vm._v("\n        "+_vm._s(_vm.template === 'partnerPortal'
            ? _vm.$t('policies.policiesData')
            : _vm.$t('policies.mainPolicyHolder'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[(_vm.template === 'partnerPortal')?[_c('ul',{staticClass:"neutral"},[_c('li',[_vm._v("\n            "+_vm._s(_vm.$t('partnerPortal.policyClosedOn'))+"\n            "+_vm._s(_vm.getFormattedDate(_vm.contract.policedAt))+"\n          ")]),_vm._v(" "),_c('li',[_vm._v("\n            "+_vm._s(_vm.$t('partnerPortal.policyValidFrom'))+"\n            "+_vm._s(_vm.getFormattedDate(_vm.contract.startOfInsurance))+"\n          ")]),_vm._v(" "),_c('li',[_vm._v("\n            "+_vm._s(_vm.$t('partnerPortal.product'))+":\n            "+_vm._s(_vm.contract.options || _vm.contract.productName)+"\n          ")])])]:_c('ul',{staticClass:"neutral"},[(_vm.policyCompany)?_c('li',[_vm._v("\n          "+_vm._s(_vm.policyCompany)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.policyHolder)?_c('li',[_vm._v("\n          "+_vm._s(_vm.policyHolder)+"\n        ")]):_vm._e()])],2),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[(_vm.template === 'partnerPortal')?_c('ul',{staticClass:"neutral"},[_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('partnerPortal.grossPremium'))+":\n          "+_vm._s(_vm.formatPriceOrStatus(_vm.contract))+"\n        ")]),_vm._v(" "),(_vm.contract.paymentPeriodPaymentStatus)?_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('partnerPortal.paymentStatus.text'))+":\n          "+_vm._s(_vm.$t(
              ("partnerPortal.paymentStatus." + (_vm.contract.paymentPeriodPaymentStatus))
            ))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.contract.lastOpenReminder)?_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('partnerPortal.reminderStatus.text'))+":\n          "+_vm._s(_vm.$t(
              ("partnerPortal.reminderStatus." + (_vm.contract.lastOpenReminder.status))
            ))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.contract.lastOpenCoverageLoss)?_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('partnerPortal.coverageClosing'))+": ab\n          "+_vm._s(_vm.getFormattedDate(_vm.contract.lastOpenCoverageLoss.startDate))+"\n        ")]):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.template !== 'partnerPortal')?_c('div',{staticClass:"col-12 col-md-8"},[_c('ul',{staticClass:"neutral"},[_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('policies.insuranceStart', {
              start: _vm.getFormattedDate(_vm.contract.startOfInsurance),
            }))+"\n        ")]),_vm._v(" "),_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('policies.paymentPeriod', {
              interval: _vm.getFormattedPaymentInterval(_vm.contract.paymentInterval),
            }))+"\n        ")]),_vm._v(" "),_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('policies.choosenOptions', { label: _vm.contract.productLabel }))+"\n        ")]),_vm._v(" "),_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('policies.claimsCount', { count: _vm.contract.claimsCount }))+"\n        ")])])]):_vm._e()]),_vm._v(" "),(_vm.template !== 'partnerPortal' && _vm.hasContractModules(_vm.contract))?_c('div',{staticClass:"row content-border-bottom pt-4 pb-5 mb-4"},_vm._l((_vm.contract.contractModules),function(item){return _c('div',{key:item.name,staticClass:"col-12 mb-4"},[_c('h5',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_vm._l((item.fields),function(field){return _c('ul',{key:Object.keys(field)[0],staticClass:"neutral"},[_c('li',[_vm._v(_vm._s((_vm.key = Object.keys(field)[0]))+": "+_vm._s(field[_vm.key]))])])})],2)}),0):_vm._e(),_vm._v(" "),(_vm.isContractActive)?_c('div',{staticClass:"row content-border-bottom py-4 mb-4"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('dx-download-list',{staticClass:"mb-4",attrs:{"items":_vm.getInvoices(_vm.contract),"headline":_vm.$t('invoice'),"tag-type":"h5"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('dx-download-list',{attrs:{"items":_vm.getOtherDocuments(_vm.contract),"headline":_vm.$t('documents'),"tag-type":"h5"}}),_vm._v(" "),(_vm.showOlderDocs)?_c('nuxt-link',{staticClass:"d-block secondary-link my-4",attrs:{"to":_vm.localePath({
            name: 'SelfService-Invoices',
            query: { contract: _vm.contract.id },
          })}},[_vm._v("\n        "+_vm._s(_vm.$t('policies.showOlderDocs'))+"\n      ")]):_vm._e()],1),_vm._v(" "),(_vm.template === 'partnerPortal')?_c('div',{staticClass:"col-12 col-md-6"},[_c('dx-download-list',{staticClass:"mb-4",attrs:{"items":[
          {
            title: ("Rendement_" + (_vm.contract.number) + ".xlsx"),
            onClick: _vm.generateRendement,
          } ],"headline":_vm.$t('policies.rendement'),"tag-type":"h5"}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(
      _vm.template === 'partnerPortal' ||
      _vm.hasInsuredPersons(_vm.contract) ||
      _vm.inviteAllowed(_vm.contract)
    )?_c('div',{staticClass:"row pt-4"},[(_vm.template === 'partnerPortal')?[_c('div',{staticClass:"col-12 mb-5"},[_c('div',{staticClass:"grey-box p-3"},[_c('dx-personal-data',{attrs:{"contract":_vm.contract,"report-damage":_vm.reportDamage},on:{"details-saved":_vm.detailsSaved}})],1)])]:_vm._e(),_vm._v(" "),(
        _vm.hasInsuredPersons(_vm.contract) || (!_vm.isActive && _vm.inviteAllowed(_vm.contract))
      )?_c('div',{staticClass:"col-12 mb-4"},[(_vm.hasInsuredPersons(_vm.contract))?_c('dx-insured-persons',{attrs:{"contract-data":_vm.contract,"on-success":_vm.refreshInsuredPersons}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-right"},[_vm._v("\n         \n        "),(
            _vm.inviteAllowed(_vm.contract) &&
            _vm.showMoreInsuredPersonsLink &&
            _vm.hasMoreInsuredPersons(_vm.contract, 9)
          )?_c('a',{staticClass:"btn-blank secondary-link",attrs:{"tabindex":"0","data-integrationtest":"mvp-invitation-toggle"},on:{"click":_vm.loadMoreInsuredPersons}},[_vm._v("\n          "+_vm._s(_vm.$t('showMorePersons'))+"\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-12 col-md-6 mt-2"},[(!_vm.isActive && _vm.inviteAllowed(_vm.contract))?_c('button',{staticClass:"btn-blank show-more-link plus-icon",attrs:{"tabindex":"0","data-integrationtest":"mvp-invitation-toggle"},on:{"click":_vm.openForm}},[_vm._v("\n            "+_vm._s(_vm.$t('addPerson'))+"\n          ")]):_vm._e()])])],1):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.isActive)?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('dx-invite-person',{attrs:{"listed-mails":_vm.listUsedEmails(_vm.contract),"contract-id":_vm.contract.id,"on-success":_vm.refreshContracts,"on-cancel":_vm.openForm}})],1)]):_vm._e(),_vm._v(" "),(_vm.hasInvitations(_vm.contract))?_c('dx-invitations',{attrs:{"contract-data":_vm.contract}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }