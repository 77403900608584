<template>
  <div class="cmp-navigation-side">
    <div class="d-flex justify-content-end align-items-end h-100">
      <dx-lang-switcher class="lang-switcher--pos" />
      <div class="d-flex align-items-end text-right pt-2">
        <dx-button
          :text="$t('reportDamage')"
          small
          theme="wine"
          class="mr-3 mr-xl-5"
          icon="lightning"
          :external-link="`${websiteURL}${$t('reportDamageLink')}`"
        />
        <a
          :href="serviceLink"
          target="_blank"
          rel="noopener"
          class="text-right cmp-navigation-side-link d-inline-block"
        >
          {{ $t('login') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLangSwitcher from '~/components/DX-LangSwitcher'

export default {
  name: 'DxNavigation',
  components: {
    DxLangSwitcher,
    DxButton,
  },
  props: {
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      serviceLink: process.env.DEXTRA_SERVICE_URL,
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
