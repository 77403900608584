export const areaOfLaw = [
  'OTHER_CONTRACTS',
  'EMPLOYMENT_CONTRACT',
  'PROPERTY_CASUALTY_LAW',
  'LEASE_AGREEMENT',
  'NEIGHBOURHOOD_RIGHT',
  'PATIENT_LAW',
  'PRIVATE_INSURANCE_LAW',
  'LEGAL_AREA_NOT_COVERED',
  'SOCIAL_SECURITY_LAW',
  'CRIMINAL_AND_ADMINISTRATIVE_LAW',
  'CONTRACTS_RELATING_TO_VEHICLES',
  'OTHER_MATTERS_OF_LAW',
  'CYBER',
]

export const subAreaOfLaw = [
  'SALES_CONTRACT',
  'WORK_CONTRACT_WITH_CONSTRUCTION_WORKERS',
  'OTHER_AGREEMENTS',
  'TERMINATION',
  'JOB_REFERENCE_LETTER',
  'WAGE_CONFLICT',
  'ILLNESS_ACCIDENT',
  'OVERTIME',
  'OTHER_MATTERS',
  'REAL_ESTATE',
  'MOVABLE_OBJECTS',
  'INITIAL_RENT',
  'RENT_INCREASE',
  'RENT_REDUCTION',
  'UTILITY_COST',
  'TERMINATION_OF_CONTRACT',
  'EXTRAORDINARY_TERMINATION_OF_CONTRACT',
  'EXTENSION_OF_TENANCY_AGREEMENT',
  'DEMAND_FOR_PAYMENT',
  'DEFECTS_IN_THE_LEASED_PROPERTY',
  'OTHER_CAUSES',
  'SPACE_HEIGHTS',
  'IMMISSIONS',
  'COMPENSATION',
  'FEE_COSTS',
  'DIAGNOSTIC_ERROR',
  'MEDICAL_MALPRACTICE',
  'DAILY_BENEFITS_INSURANCE',
  'LIABILITY_INSURANCE',
  'PROPERTY_INSURANCE',
  'TRAVEL_INSURANCE',
  'OTHER_INSURANCES',
  'DATA_PROTECTION_LAW',
  'PERSONAL_LAW',
  'FAMILY_LAW',
  'INHERITANCE_LAW',
  'CORPORATE_LAW',
  'REAL_ESTATE_PURCHASE_SALE',
  'ADMINISTRATIVE_LAW',
  'CONSTRUCTION_LAW',
  'OTHER_AREAS_OF_LAW',
  'SOCIAL_SECURITY_LAW_IV',
  'UNEMPLOYMENT_INSURANCE',
  'SOCIAL_WELFARE_SYSTEM',
  'ADDITIONAL_BENEFIT',
  'MATERNITY',
  'HEALTH_AND_ACCIDENT_INSURANCE',
  'TRAFFIC_LAW_PENAL_PROCEDURE',
  'TRAFFIC_LAW_ADMINISTRATIVE_PROCEEDINGS',
  'NEGLIGENCE_OFFENCES',
  'ADJUSTMENT_ACQUITTAL',
  'OTHER_TRAFFIC_MATTERS',
  'LEASING',
  'CAR_PURCHASE_AGREEMENT',
  'CAR_LEASE',
  'CAR_REPAIR',
  'CAR_INSURANCE',
  'CAR_MISC',
  'WITHDRAWAL_OF_PERMITS',
  'OBJECTION_AGAINST_BUILDING_APPLICATION',
  'INTERNET_LEGAL_PROTECTION',
  'TECHNICAL_SUPPORT_INTERNET',
  'TRADEMARK_DESIGN_COPYRIGHT_LAW',
  'INDEMNITY',
  'TAX_LAW',
  'CONDOMINIUM_OWNERSHIP_LAW',
  'UNFAIR_COMPETITION_LAW',
  'PERFORMANCE_AUDIT_TARIFF_DISPUTES',
  'CYBER_EXTORTION',
  'SIMPLE_DATA_THEFT',
  'DATA_THEFT_WITH_LOSS_OF_MONEY',
  'CYBERMOBBING_OF_UNAUTHORISED_CONTENT',
  'LEGAL_CASE_ONLY',
  'MANAGEMENT_OF_DATA_AFTER_DEATH',
]

export const documentTypeMap = {
  EMPLOYMENT_CONTRACT: {
    TERMINATION: [
      'employment_contract',
      'rules_of_procedure',
      'termination',
      'misc',
      'correspondence',
    ],
    JOB_REFERENCE_LETTER: [
      'employment_contract',
      'rules_of_procedure',
      'testimony',
      'appraisal_interview',
      'written_warning',
      'misc',
      'correspondence',
    ],
    WAGE_CONFLICT: [
      'employment_contract',
      'rules_of_procedure',
      'wage_statement',
      'misc',
      'correspondence',
    ],
    ILLNESS_ACCIDENT: [
      'employment_contract',
      'rules_of_procedure',
      'doctor_testimony',
      'accident_protocoll',
      'misc',
      'correspondence',
    ],
    OVERTIME: [
      'employment_contract',
      'rules_of_procedure',
      'overtime_documents',
      'notations',
      'misc',
      'correspondence',
    ],
    OTHER_MATTERS: ['misc', 'correspondence'],
  },
  LEASE_AGREEMENT: {
    INITIAL_RENT: [
      'lease_agreement',
      'formular_message_rental_intrest',
      'specifications_rental_interest',
      'misc',
      'correspondence',
    ],
    RENT_INCREASE: [
      'lease_agreement',
      'earlier_rental_interest_changes',
      'rental_change_messages',
      'misc',
      'correspondence',
    ],
    RENT_REDUCTION: [
      'lease_agreement',
      'earlier_rental_interest_changes',
      'rental_change_messages',
      'decrease_demand',
      'misc',
      'correspondence',
    ],
    UTILITY_COST: [
      'lease_agreement',
      'billings',
      'supplement',
      'misc',
      'correspondence',
    ],
    TERMINATION_OF_CONTRACT: [
      'lease_agreement',
      'termination',
      'search_new_flat',
      'misc',
      'correspondence',
    ],
    EXTRAORDINARY_TERMINATION_OF_CONTRACT: [
      'lease_agreement',
      'termination',
      'search_new_flat',
      'misc',
      'correspondence',
    ],
    EXTENSION_OF_TENANCY_AGREEMENT: [
      'lease_agreement',
      'termination',
      'search_new_flat',
      'misc',
      'correspondence',
    ],
    DEMAND_FOR_PAYMENT: [
      'lease_agreement',
      'promotion_reason',
      'misc',
      'correspondence',
    ],
    DEFECTS_IN_THE_LEASED_PROPERTY: [
      'lease_agreement',
      'documentation_of_defects',
      'message_of_defect',
      'rejection',
      'misc',
      'correspondence',
    ],
    OTHER_CAUSES: ['misc', 'correspondence'],
  },
  CRIMINAL_AND_ADMINISTRATIVE_LAW: {
    TRAFFIC_LAW_PENAL_PROCEDURE: [
      'car_documents',
      'pentalty_order',
      'administrative_procedure',
      'police_report',
      'misc',
      'correspondence',
    ],
    TRAFFIC_LAW_ADMINISTRATIVE_PROCEEDINGS: [
      'car_documents',
      'pentalty_order',
      'administrative_procedure',
      'police_report',
      'misc',
      'correspondence',
    ],
    NEGLIGENCE_OFFENCES: ['disposal_judgment', 'misc', 'correspondence'],
    ADJUSTMENT_ACQUITTAL: ['disposal_judgment', 'misc', 'correspondence'],
    OTHER_TRAFFIC_MATTERS: ['misc', 'correspondence'],
  },
  CONTRACTS_RELATING_TO_VEHICLES: {
    LEASING: [
      'car_documents',
      'contract',
      'termination',
      'misc',
      'correspondence',
    ],
    CAR_PURCHASE_AGREEMENT: [
      'car_documents',
      'contract',
      'eventual_documentation_of_defect',
      'eventual_message_of_defect',
      'misc',
      'correspondence',
    ],
    CAR_LEASE: [
      'car_documents',
      'contract',
      'termination',
      'misc',
      'correspondence',
    ],
    CAR_REPAIR: [
      'car_documents',
      'order',
      'repair_report',
      'misc',
      'correspondence',
    ],
    CAR_INSURANCE: [
      'car_documents',
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
    ],
    CAR_MISC: ['car_documents', 'misc', 'correspondence'],
  },
  PRIVATE_INSURANCE_LAW: {
    DAILY_BENEFITS_INSURANCE: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    LIABILITY_INSURANCE: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    PROPERTY_INSURANCE: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    TRAVEL_INSURANCE: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    OTHER_INSURANCES: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
  },
  NEIGHBOURHOOD_RIGHT: {
    SPACE_HEIGHTS: [
      'blueprints',
      'documentation_of_situation',
      'building_application',
      'stwe_rules',
      'foundation_decision',
      'misc',
      'correspondence',
    ],
    IMMISSIONS: [
      'blueprints',
      'documentation_of_situation',
      'building_application',
      'stwe_rules',
      'foundation_decision',
      'misc',
      'correspondence',
    ],
  },
  OTHER_CONTRACTS: {
    SALES_CONTRACT: [
      'contract',
      'additions',
      'termination',
      'defect',
      'misc',
      'correspondence',
    ],
    OTHER_AGREEMENTS: [
      'contract',
      'additions',
      'termination',
      'defect',
      'misc',
      'correspondence',
    ],
    WORK_CONTRACT_WITH_CONSTRUCTION_WORKERS: [
      'contract',
      'additions',
      'termination',
      'defect',
    ],
  },
  PROPERTY_CASUALTY_LAW: {
    REAL_ESTATE: ['misc', 'correspondence'],
    MOVABLE_OBJECTS: ['misc', 'correspondence'],
  },
  PATIENT_LAW: {
    COMPENSATION: ['misc', 'correspondence'],
    FEE_COSTS: ['misc', 'correspondence'],
    DIAGNOSTIC_ERROR: ['misc', 'correspondence'],
    MEDICAL_MALPRACTICE: ['misc', 'correspondence'],
  },
  LEGAL_AREA_NOT_COVERED: {
    DATA_PROTECTION_LAW: ['misc', 'correspondence'],
    PERSONAL_LAW: ['misc', 'correspondence'],
    FAMILY_LAW: ['misc', 'correspondence'],
    INHERITANCE_LAW: ['misc', 'correspondence'],
    CORPORATE_LAW: ['misc', 'correspondence'],
    REAL_ESTATE_PURCHASE_SALE: ['misc', 'correspondence'],
    ADMINISTRATIVE_LAW: ['misc', 'correspondence'],
    CONSTRUCTION_LAW: [
      'blueprints',
      'documentation_of_situation',
      'building_application',
      'misc',
      'correspondence',
    ],
    OTHER_AREAS_OF_LAW: ['misc', 'correspondence'],
  },
  SOCIAL_SECURITY_LAW: {
    SOCIAL_SECURITY_LAW_IV: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    UNEMPLOYMENT_INSURANCE: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    SOCIAL_WELFARE_SYSTEM: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    ADDITIONAL_BENEFIT: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    MATERNITY: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
    HEALTH_AND_ACCIDENT_INSURANCE: [
      'policy',
      'general_policy_conditions',
      'insured_sum_refusal',
      'misc',
      'correspondence',
    ],
  },
  OTHER_MATTERS_OF_LAW: {
    WITHDRAWAL_OF_PERMITS: ['misc', 'correspondence'],
    OBJECTION_AGAINST_BUILDING_APPLICATION: [
      'blueprints',
      'documentation_of_situation',
      'building_application',
      'misc',
      'correspondence',
    ],
    INTERNET_LEGAL_PROTECTION: ['misc', 'correspondence'],
    TECHNICAL_SUPPORT_INTERNET: ['misc', 'correspondence'],
    TRADEMARK_DESIGN_COPYRIGHT_LAW: ['misc', 'correspondence'],
    INDEMNITY: ['misc', 'correspondence'],
    TAX_LAW: ['misc', 'correspondence'],
    CONDOMINIUM_OWNERSHIP_LAW: [
      'stwe_rules',
      'foundation_decision',
      'misc',
      'correspondence',
    ],
    UNFAIR_COMPETITION_LAW: ['misc', 'correspondence'],
    PERFORMANCE_AUDIT_TARIFF_DISPUTES: ['misc', 'correspondence'],
  },
  CYBER: {
    CYBER_EXTORTION: ['misc', 'correspondence'],
    SIMPLE_DATA_THEFT: ['misc', 'correspondence'],
    DATA_THEFT_WITH_LOSS_OF_MONEY: ['misc', 'correspondence'],
    CYBERMOBBING_OF_UNAUTHORISED_CONTENT: ['misc', 'correspondence'],
    LEGAL_CASE_ONLY: ['misc', 'correspondence'],
    MANAGEMENT_OF_DATA_AFTER_DEATH: ['misc', 'correspondence'],
  },
}
