<template>
  <div class="container-fluid content-wrap-small content-pad">
    <div class="row">
      <div class="col mb-3 text-center">
        <h2>{{ $t('tosPage.headline') }}</h2>
      </div>
    </div>

    <div class="row mt-5">
      <ol class="mb-0">
        <li
          v-for="(item, index) in $t('tosPage.items')"
          :key="`tositem-${index}`"
          v-html="item"
        />
      </ol>
    </div>

    <div v-if="isLoggedIn && !termsOfServiceAccepted" class="row mt-5">
      <div class="col">
        <div class="text-right">
          <dx-button
            :text="$t('tosPage.buttonAccept')"
            @click="sendFormData()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import { SET_CURRENT_LAWYER } from '~/domains/application/__store__/mutations'
import acceptTermsOfServiceMutation from '~/domains/externalLawyer/__gql__/mutations/acceptTermsOfService.gql'

export default {
  name: 'LawyerPortalTermsOfService',
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/nutzungsbedingungen',
      fr: '/portail-avocat/conditions-d-utilisation',
      it: '/portale-procuratori/condizioni-di-utilizzo',
    },
  },

  components: {
    DxButton,
  },

  mixins: [loadingMixin],

  data() {
    return {}
  },

  computed: {
    ...mapState({
      loading: (state) => state.application.loading,
      termsOfServiceAccepted: (state) =>
        state.application.currentLawyer.termsOfServiceAccepted,
      isLoggedIn: (state) =>
        !!state.application.currentLawyer.id ||
        !!state.application.currentUser.id,
    }),
  },

  methods: {
    async sendFormData() {
      try {
        this.setGlobalLoading(true)

        const response = await this.$apollo.mutate({
          mutation: acceptTermsOfServiceMutation,
          variables: {
            portal: 'LAWYER_PORTAL',
          },
        })

        this.$store.commit(SET_CURRENT_LAWYER, {
          currentLawyer: {
            termsOfServiceAccepted: response,
          },
        })

        if (response) {
          this.$router.push({ path: this.localePath('LawyerPortal') })
        }
      } catch (error) {
        this.$notification.error({ messageKey: 'notifications.error.message' })
      } finally {
        this.setGlobalLoading(false)
      }
    },
  },
}
</script>
