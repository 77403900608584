<template>
  <div class="cmp-navigation-placebo">
    <div :class="cssClasses()" class="cmp-navigation-wrap">
      <div class="container-fluid cmp-navigation">
        <div class="row">
          <nuxt-link to="/" class="col-md-2 cmp-navigation-logo">
            <span class="visually-hidden">
              {{ $t('toMainPage') }}
            </span>
          </nuxt-link>

          <div class="col">
            <a class="cmp-navigation-hamburger" @click="openNav">
              <span class="cmp-navigation-hamburger-icon" />
              <span class="visually-hidden">
                {{ $t('openNavigation') }}
              </span>
            </a>
          </div>
        </div>

        <div class="row cmp-navigation-container dotted-border-top">
          <div class="col-12 dotted-border-bottom">
            <div class="row py-4">
              <div class="col-12">
                <dx-lang-switcher
                  class="lang-switcher--pos text-right col-12"
                />
              </div>
              <div v-if="authenticated && allowPortalSwitch" class="col-12">
                <div
                  :class="['portal-switch', { active: isPortalSwitch }]"
                  @click="togglePortalSwitch"
                >
                  <div class="position-relative">
                    {{ $t('switchPortal') }}
                    <span
                      :class="[
                        'portal-switch-arrow',
                        { 'portal-switch-arrow--active': isPortalSwitch },
                      ]"
                    />
                  </div>
                  <div v-if="authenticated && isPortalSwitch" class="col-12">
                    <dx-portal-switch left class="pt-2 pb-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul
            class="cmp-navigation-list neutral dotted-border-bottom pb-3 mb-2"
          >
            <li v-for="item in navItems" :key="item.name">
              <mobile-navigation-link-item :item="item" @click="closeNav" />
            </li>
            <li>
              <a :href="`${websiteURL}${$t('nav.path1')}`" class="p-3">
                {{ $t('nav.item1') }}
              </a>
            </li>
          </ul>

          <ul
            v-if="authenticated && !isGmLimited && currentPortal"
            class="neutral"
          >
            <li v-for="item in accountSubnavData" :key="item.name">
              <mobile-navigation-link-item :item="item" @click="closeNav" />
            </li>
          </ul>

          <div class="col">
            <button
              v-if="authenticated"
              class="secondary-link font-weight-medium btn-blank nav-item--spacing"
              @click="logout()"
            >
              {{ $t('logout') }}
            </button>

            <nuxt-link
              v-if="!authenticated"
              :to="localePath('Login')"
              class="secondary-link font-weight-medium nav-item--spacing"
            >
              {{ $t('login') }}
            </nuxt-link>

            <dx-button
              v-if="
                currentPortal === 'CUSTOMER_CENTER' &&
                authenticated &&
                hasContracts
              "
              :text="$t('reportDamage')"
              icon="lightning"
              theme="wine"
              class="text-center mt-4 mb-5"
              small
              :route-name="localePath('SelfService-ReportDamage-MyInfo')"
              @click.native="closeNav"
            />

            <dx-button
              v-else-if="currentPortal === 'BROKER_PORTAL' && authenticated"
              :text="$t('reportDamage')"
              icon="lightning"
              theme="wine"
              class="text-center mt-4 mb-5"
              small
              :route-name="localePath('PartnerPortal-ClosedPolicies')"
              @click.native="openDamagePopup()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLangSwitcher from '~/components/DX-LangSwitcher'
import DxPortalSwitch from '~/components/DX-PortalSwitch'
import MobileNavigationLinkItem from './LinkItem'

export default {
  name: 'DxMobileNavigation',
  components: {
    MobileNavigationLinkItem,
    DxButton,
    DxLangSwitcher,
    DxPortalSwitch,
  },
  props: {
    navItems: {
      type: Array,
      default() {
        return []
      },
    },
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      isPortalSwitch: false,
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  computed: {
    authenticated() {
      return !!this.$store.state.authentication.token.jwt
    },
    hasContracts() {
      return this.$store.state.application.hasContracts
    },
    allowPortalSwitch() {
      return (
        this.currentPortal &&
        this.$store.state.authentication.portals.length > 1
      )
    },
    currentPortal() {
      return this.$store.state.authentication.currentPortal
    },
    ...mapGetters(['isGmLimited']),
  },
  watch: {
    $route() {
      this.isActive = false
    },
  },
  methods: {
    togglePortalSwitch() {
      this.isPortalSwitch = !this.isPortalSwitch
    },
    openNav() {
      this.isActive = !this.isActive
    },
    closeNav() {
      this.isActive = false
    },
    cssClasses() {
      return {
        'active': this.isActive,
        'is-sticky': this.isSticky,
      }
    },
    logout() {
      this.$router.push({
        path: this.localePath('Login'),
        query: { logout: true },
      })
    },
    openDamagePopup() {
      this.$store.commit('SET_DAMAGEPOPUP', true)
      this.isActive = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
