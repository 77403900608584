<template>
  <div class="text-center">
    <div class="content-wrap-medium content-pad">
      <h2 class="mb-4">
        {{ $t('reportDamage') }}
      </h2>
      <p>
        {{ $t('noInsuredContracts') }}
      </p>
      <dx-button
        :text="$t('toSelfService.customer')"
        :route-name="localePath('SelfService')"
      />
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'NoContractsMessage',
  components: {
    DxButton,
  },
}
</script>
