<template>
  <dx-info-form
    :contracts-loading="isLoading"
    :person="person"
    :contract-id="contractId"
    next-step-path="PartnerPortal-ReportDamage-Facts"
    :contracts="contracts"
    :is-broker="true"
  />
</template>

<script>
import DxInfoForm from '~/components/reportDamage/DX-InfoForm'
import brokerPolicyQuery from '~/domains/partnerPortal/__gql__/queries/brokerPolicy.gql'

export default {
  name: 'PartnerDamageReportInfo',
  components: {
    DxInfoForm,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsfall-melden',
      fr: '/portailpartenaires/cas-juridique-rapport',
      it: '/portaledeipartner/segnalare-caso-giuridico',
    },
  },
  data() {
    return {
      contractId:
        this.$route.query.contractId ||
        this.$store.state.claim.report.contract.id,
      personId: this.$route.query.personId,
      brokerPolicy: null,
    }
  },
  computed: {
    person() {
      const { brokerPolicy } = this
      if (!brokerPolicy) {
        return null
      }
      const persons = [brokerPolicy.policyholder].concat(
        brokerPolicy.insuredPersons || [],
      )
      return (
        persons.find((p) => p.dexId.toString() === this.personId) ||
        brokerPolicy.policyholder
      )
    },
    isLoading() {
      return (
        !this.$apollo.queries.brokerPolicy ||
        this.$apollo.queries.brokerPolicy.loading
      )
    },
    contracts() {
      return this.brokerPolicy ? [this.brokerPolicy] : []
    },
  },
  mounted() {
    if (!this.contractId) {
      this.$router.push({
        path: this.localePath('PartnerPortal-ClosedPolicies'),
        query: { damage: true },
      })
    }
  },
  apollo: {
    brokerPolicy: {
      query: brokerPolicyQuery,
      variables() {
        return {
          id: parseInt(this.contractId, 10),
        }
      },
    },
  },
}
</script>
