var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-bg-color"},[_c('div',{staticClass:"container-fluid content-pad"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('dx-back-text-button',{staticClass:"outer-container-spacing",attrs:{"text":_vm.$t('toDashboard'),"route":_vm.localePath('PartnerPortal')}})],1)]),_vm._v(" "),_c('dx-tariff-container',{staticClass:"mt-5",attrs:{"tariff":_vm.activeTariff}},[_c('template',{slot:"content"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-md-8 offset-md-2"},[_c('p',[_vm._v(_vm._s(_vm.$t('brokerOffer.answerQuestions')))]),_vm._v(" "),(_vm.tariffType === 'private')?_c('dx-private-basic-questions',{ref:"privateIntroForm",attrs:{"editable":"","is-broker-offer":true},on:{"change":function () {
                  _vm.hasChanged = true
                }}}):(_vm.tariffType === 'business')?_c('dx-business-basic-questions',{ref:"businessIntroForm",attrs:{"editable":"","is-broker-offer":true},on:{"change":function () {
                  _vm.hasChanged = true
                }}}):(_vm.tariffType === 'b-move')?_c('dx-business-move-basic-questions',{ref:"businessMoveIntroForm",attrs:{"editable":"","is-broker-offer":true},on:{"change":function () {
                  _vm.hasChanged = true
                }}}):_c('nuxt-link',{staticClass:"secondary-link",attrs:{"to":{ path: _vm.localePath('PartnerPortal') }}},[_vm._v("\n              "+_vm._s(_vm.$t('offer.chooseProduct'))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"col-12 text-right py-md-4 px-md-5 mt-3"},[_c('dx-button',{staticClass:"dx-button--position",attrs:{"text":_vm.$t('offer.calculatePremium')},on:{"click":_vm.saveForm}})],1)])])],2),_vm._v(" "),(_vm.loading)?_c('dx-loading-overlay'):(!_vm.hasError && !_vm.hasChanged && _vm.premia.insurable !== null)?_c('div',{staticClass:"premium-wrap shadow-container mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 premium-head text-center py-5"},[_c('h2',{staticClass:"mb-0"},[_vm._v("\n            "+_vm._s(_vm.$t('brokerOffer.yearlyPremium'))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[(_vm.premia.insurable && _vm.premia.totalPremium.value)?_c('div',{staticClass:"col-12 col-md-8 offset-md-2 py-3"},[_vm._l((_vm.premiaComponents),function(premium,index){return _c('div',{key:("premium-" + index),staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-4"},[_c('h5',[_vm._v("\n                "+_vm._s(_vm.$t(("offer.premiumLabel." + (premium.label))))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-8"},[_vm._v("\n              "+_vm._s(((premium.amount.currency || 'CHF') + " " + (_vm.formatCurrency(
                  premium.amount.value
                ))))+"\n            ")])])}),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-6 col-md-4"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('offer.total'))+"\n              ")]),_vm._v(" "),_c('p',[_vm._v("\n                "+_vm._s(_vm.$t('offer.tax'))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-8"},[_vm._v("\n              "+_vm._s(_vm.premia.totalPremium.currency || 'CHF')+"\n              "),_c('span',{staticClass:"big-price"},[_vm._v("\n                "+_vm._s(_vm.formatCurrency(_vm.premia.totalPremium.value))+"\n              ")])])])],2):_c('div',{staticClass:"col-12 col-md-8 offset-md-2 py-3"},[_vm._v("\n          "+_vm._s(_vm.$t('brokerOffer.uninsurable'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-12 text-right py-md-4 px-md-5 mt-3"},[_c('dx-button',{staticClass:"dx-button--position",attrs:{"text":_vm.$t('offer.nextStep')},on:{"click":_vm.goForward}})],1)])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }