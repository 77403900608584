import Vue from 'vue'

import DxNotifications from '~/components/DX-Notifications'
import NotificationType from '~/components/DX-Notifications/NotificationType'
import Events from './events'

const emitNotification = (attributes) =>
  Events.$emit('notification', attributes)

const buildAndEmitNotification = (
  template = null,
  messageKey = null,
  headlineKey = null,
  timer = null,
) => {
  const notification = {
    ...template,
    messageKey: messageKey || template.messageKey,
    headlineKey: headlineKey || template.headlineKey,
    timer: timer || template.timer,
  }
  emitNotification(notification)
}

export const NotificationActions = {
  success: ({ messageKey = null, headlineKey = null, timer = null }) => {
    buildAndEmitNotification(
      NotificationType.SUCCESS,
      messageKey,
      headlineKey,
      timer,
    )
  },
  error: ({ messageKey = null, headlineKey = null, timer = null }) => {
    buildAndEmitNotification(
      NotificationType.ERROR,
      messageKey,
      headlineKey,
      timer,
    )
  },
  warning: ({ messageKey = null, headlineKey = null, timer = null }) => {
    buildAndEmitNotification(
      NotificationType.WARNING,
      messageKey,
      headlineKey,
      timer,
    )
  },
  info: ({ messageKey = null, headlineKey = null, timer = null }) => {
    buildAndEmitNotification(
      NotificationType.INFO,
      messageKey,
      headlineKey,
      timer,
    )
  },
}

const NotificationPlugin = {
  install() {
    if (this.installed) {
      return
    }
    this.installed = true

    // register notification container
    Vue.component('DxNotifications', DxNotifications)

    // functions to send notifications of different types via this.$notification.X
    Vue.prototype.$notification = Object.assign(NotificationActions)
  },
}

Vue.use(NotificationPlugin)

export default NotificationPlugin
