<template>
  <div class="position-relative d-inline-block">
    <div v-if="showTooltip & showDeleteDialog" class="tooltip-wrap" />
    <div class="tooltip-trigger" @click="openTooltip">
      <slot name="trigger" />
    </div>
    <transition name="page">
      <div v-if="showTooltip" :class="cssClasses()" class="tooltip">
        <span
          v-if="!showDeleteDialog"
          class="closeIcon"
          @click="closeTooltip"
        />
        <slot name="content" />
        <div v-if="showDeleteDialog">
          <h5>{{ $t('deleteConfirmation') }}</h5>
          <div class="row mt-4">
            <div class="col text-left">
              <button
                class="btn-blank dialog-btn secondary-link pl-0"
                @click="closeTooltip"
              >
                {{ $t('abort') }}
              </button>
            </div>
            <div class="col text-right">
              <button
                class="btn-blank dialog-btn dialog-btn--delete"
                @click="deleteItem"
              >
                {{ $t('delete') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DxTooltip',
  props: {
    position: {
      type: String,
      default: 'top',
    },
    icon: {
      type: String,
      default: 'cross',
    },
    showDeleteDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
      pressedDelete: false,
    }
  },
  methods: {
    cssClasses() {
      return {
        'delete-item': this.showTooltip && this.showDeleteDialog,
        'top top--right': this.position === 'top-right',
        'top top--center': this.position === 'top-center',
        'top top--left': this.position === 'top-left',
        'bottom bottom--right': this.position === 'bottom-right',
        'bottom bottom--center': this.position === 'bottom-center',
        'bottom bottom--left': this.position === 'bottom-left',
        'left': this.position === 'left',
        'right': this.position === 'right',
      }
    },
    deleteItem() {
      this.pressedDelete = !this.pressedDelete

      if (this.pressedDelete) {
        this.$emit('deleteItem')
        this.closeTooltip()
      }
    },
    openTooltip() {
      if (this.showDeleteDialog) {
        this.showTooltip = true
      } else {
        this.showTooltip = !this.showTooltip
      }
    },
    closeTooltip() {
      this.showTooltip = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
