<template>
  <div
    :class="{ 'no-focus-outline': !isTab }"
    @keydown.tab="keydownTab"
    @keyup.esc="keyEscTabbing"
  >
    <dx-navigation is-sticky />
    <dx-notifications />
    <div class="main-wrap">
      <nuxt />
    </div>
    <dx-footer />
  </div>
</template>

<script>
import DxNavigation from '~/components/DX-Navigation-Website'
import DxFooter from '~/components/DX-Footer-Website'
import { keyCodes } from '~/javascripts/utils'
import { handleScipSalesPreview } from '~/javascripts/scipSales'

export default {
  name: 'Default',
  components: {
    DxNavigation,
    DxFooter,
  },
  data() {
    return {
      isTab: false,
    }
  },
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      title: this.$t('offer.meta.title', {
        product: this.sanitizedProductName,
      }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('offer.meta.description'),
        },
        ...i18nSeo.meta,
      ],
    }
  },
  computed: {
    sanitizedProductName() {
      const product = this.$store.state.offer.activeTariff
      return this.$t(`partnerPortal.tariff.${product}.name`)
    },
  },
  watch: {
    '$i18n.locale': {
      async handler() {
        await this.$apolloProvider.clients.cms.reFetchObservableQueries()
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('keyup', (event) => {
      this.keyupHandler(event)
    })

    window.addEventListener('click', (_event) => {
      this.$publish('body-clicked')
    })

    handleScipSalesPreview(this.$route)
  },
  methods: {
    keyupHandler(event) {
      if (event.keyCode === keyCodes.esc) {
        this.$publish('key-ESC')
      }
    },
    keydownTab() {
      this.isTab = true
    },
    keyEscTabbing() {
      this.isTab = false
    },
  },
}
</script>
