<template>
  <div class="w-100">
    <div class="align-self-center content-wrap-small content-pad text-center">
      <dx-error />
    </div>
  </div>
</template>

<script>
import DxError from '~/partials/DX-Error'

export default {
  name: 'Error',
  components: {
    DxError,
  },
}
</script>
