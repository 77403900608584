export default () => ({
  loading: false,
  hasContracts: false,
  pendingUploads: 0,
  currentUser: {
    username: null,
  },
  currentLawyer: {
    contactDetails: {},
    address: {},
    hourlyRate: {},
    bankAccount: {},
    areaOfLawSkills: [],
    jobTitles: [],
    additionalSkills: [],
    advancedTrainings: [],
    languageSkills: [],
    termsOfServiceAccepted: false,
    username: null,
  },
  currentBroker: {
    username: null,
  },
})
