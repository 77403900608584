export const GET_CURRENT_LAWYER = 'GET_CURRENT_LAWYER'
export const GET_CURRENT_BROKER = 'GET_CURRENT_BROKER'

export default {
  is_LOADING(state) {
    return state.loading
  },
  [GET_CURRENT_LAWYER](state) {
    return state.currentLawyer
  },
  [GET_CURRENT_BROKER](state) {
    return state.currentBroker
  },
}
