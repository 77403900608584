<template>
  <div>
    <template>
      <div
        class="container-fluid content-wrap-medium position-relative content-bg-color notification-settings-bar"
      >
        {{ $t('partnerPortal.damageManagement.subscriptionNewClaim') }}
        <dx-toggle-switch
          :checked="subscriptionActive('new_claim')"
          class="toggle-switch"
          @input="toggleSubscription('new_claim', $event)"
        />
      </div>

      <div
        class="container-fluid content-wrap-medium position-relative content-bg-color notification-settings-bar"
      >
        {{ $t('partnerPortal.damageManagement.subscriptionCloseClaim') }}
        <dx-toggle-switch
          :checked="subscriptionActive('close_claim')"
          class="toggle-switch"
          @input="toggleSubscription('close_claim', $event)"
        />
      </div>

      <div
        class="container-fluid content-wrap-medium position-relative content-bg-color notification-settings-bar"
      >
        {{ $t('partnerPortal.damageManagement.subscriptionEBillRegistration') }}
        <dx-toggle-switch
          :checked="subscriptionActive('ebill_registration')"
          class="toggle-switch"
          @input="toggleSubscription('ebill_registration', $event)"
        />
      </div>

      <div
        class="container-fluid content-wrap-medium position-relative content-bg-color notification-settings-bar"
      >
        {{ $t('partnerPortal.damageManagement.subscriptionLegalReminder') }}
        <dx-toggle-switch
          :checked="subscriptionActive('legal_reminder')"
          class="toggle-switch"
          @input="toggleSubscription('legal_reminder', $event)"
        />
      </div>

      <dx-change-login-details />

      <!-- Personal Data -->
      <div class="row user-data-block">
        <div class="col">
          <dx-edit-data-toggle
            ref="personalData"
            :headline="$t('userData.personalData')"
            tag-type="h4"
            :callback="savePersonalData"
            :cancel-callback="cancelPersonalData"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <dx-personal-data-form
                ref="personalDataForm"
                :editable="editMode"
                :on-bank-account-errors="onBankAccountErrors"
              />
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>

      <!-- Bank Data -->
      <div class="row user-data-block">
        <div class="col">
          <dx-edit-data-toggle
            ref="bankData"
            :headline="$t('userData.paymentData')"
            tag-type="h4"
            :callback="changeBankData"
            :cancel-callback="cancelBankData"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <dx-bank-data-form
                ref="bankDataForm"
                :editable="editMode"
                :on-general-errors="onGeneralErrors"
              />
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>

      <div class="row user-data-block last-item">
        <div class="col">
          <dx-edit-data-toggle
            :headline="$t('userData.deliveryMethods')"
            tag-type="h4"
            :callback="changeDeliveryMethods"
            :cancel-callback="cancelDeliveryMethods"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <dx-delivery-methods-form
                ref="deliveryMethodsForm"
                :editable="editMode"
                :on-general-errors="onGeneralErrors"
              />
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>
    </template>
    <dx-popup-modal ref="addressconfirmationModal">
      <h3>{{ $t('userData.addressChange') }}</h3>
      <p>
        {{ $t('userData.brokerAddressChangeWarning') }}
      </p>
      <div
        class="d-flex flex-wrap-reverse justify-content-center d-sm-block text-right"
      >
        <span
          class="abort-btn mt-2 mr-3 ml-3 mt-sm-0 secondary-link btn-blank"
          @click="closeAddressConfirmationModal"
        >
          {{ $t('abort') }}
        </span>

        <dx-button
          type="button"
          class="d-inline"
          :text="$t('userData.addressChangeConfirmation')"
          small
          @click="confirmAddressChange"
        />
      </div>
    </dx-popup-modal>
  </div>
</template>

<script>
import {
  DxToggleSwitch,
  DxPopupModal,
  DxButton,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxEditDataToggle from '~/components/DX-EditDataToggle'
import DxPersonalDataForm from '~/domains/partnerPortal/components/DX-PersonalDataForm'
import DxBankDataForm from '~/domains/partnerPortal/components/DX-BankDataForm'
import DxDeliveryMethodsForm from '~/domains/partnerPortal/components/DX-DeliveryMethodsForm'
import DxChangeLoginDetails from '~/domains/authentication/components/DX-ChangeLoginDetails'
import changeSubscriptionMutation from '~/domains/partnerPortal/__gql__/mutations/changeSubscription.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'PartnerPortalUserdata',
  components: {
    DxEditDataToggle,
    DxPersonalDataForm,
    DxBankDataForm,
    DxChangeLoginDetails,
    DxToggleSwitch,
    DxDeliveryMethodsForm,
    DxPopupModal,
    DxButton,
  },
  mixins: [loadingMixin],
  methods: {
    savePersonalData() {
      this.$refs.addressconfirmationModal.open()
    },
    closeAddressConfirmationModal() {
      this.$refs.addressconfirmationModal.close()
    },
    async confirmAddressChange() {
      this.$refs.addressconfirmationModal.close()
      const { bankAccount } = this.$refs.bankDataForm.brokerData
      const { deliveryMethods } = this.$refs.deliveryMethodsForm.brokerData
      const result = await this.$refs.personalDataForm.editAddressCallback(
        bankAccount,
        deliveryMethods,
      )
      return result
    },

    async changeBankData() {
      const { brokerData } = await this.$refs.personalDataForm
      const { deliveryMethods } = this.$refs.deliveryMethodsForm.brokerData
      const result = await this.$refs.bankDataForm.editDataCallback(
        brokerData,
        deliveryMethods,
      )
      return result
    },
    async changeDeliveryMethods() {
      const { brokerData } = await this.$refs.personalDataForm
      const { bankAccount } = this.$refs.bankDataForm.brokerData
      const result = await this.$refs.deliveryMethodsForm.editDataCallback(
        brokerData,
        bankAccount,
      )
      return result
    },

    async cancelPersonalData() {
      const result = await this.$refs.personalDataForm.cancelCallback()
      return result
    },

    async cancelDeliveryMethods() {
      const result = await this.$refs.deliveryMethodsForm.cancelCallback()
      return result
    },
    async cancelBankData() {
      const result = await this.$refs.bankDataForm.cancelCallback()
      return result
    },
    subscriptionActive(type) {
      const { subscriptions } = this.$store.state.application.currentBroker
      return subscriptions && subscriptions.includes(type)
    },
    toggleSubscription(type, activate) {
      this.toggleSubscriptionMutation(type, activate)
    },
    onGeneralErrors(errors) {
      if (!this.$refs.personalData.editMode) {
        this.$refs.personalData.toggleEditMode()
      }
      this.$refs.personalDataForm.displayErrors(errors)
    },
    onBankAccountErrors(errors) {
      if (!this.$refs.bankData.editMode) {
        this.$refs.bankData.toggleEditMode()
      }
      this.$refs.bankDataForm.displayErrors(errors)
    },
    async toggleSubscriptionMutation(type, activate) {
      try {
        this.setGlobalLoading(true)
        const { data } = await this.$apollo.mutate({
          mutation: changeSubscriptionMutation,
          variables: {
            type,
            activate,
          },
        })
        if (data && data.changeSubscription) {
          const updatedBroker = {
            ...this.$store.state.application.currentBroker,
            subscriptions: data.changeSubscription.subscriptions,
          }
          this.$store.commit('SET_CURRENT_BROKER', {
            currentBroker: updatedBroker,
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.setGlobalLoading(false)
      }
    },
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/benutzerdaten',
      fr: '/portailpartenaires/donnees-utilisateur',
      it: '/portaledeipartner/informazioni-utente',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
