<template>
  <div>
    <div v-if="loadId.loaded && jwt">
      <div class="safetyPadding">
        <dx-scip-sales
          v-if="product.key && product.variantKey"
          :product-key="product.key"
          :variant-key="product.variantKey"
          :form-key="formKey"
          :dex-id="loadId"
          :auth-token="jwt"
          channel="employee"
        />

        <div v-else class="content-bg-color">
          <div class="container-fluid content-pad">
            <dx-products is-iframe :force-product="forceProduct" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loadId.loaded" class="content-bg-color">
      <div class="container-fluid content-pad">
        <dx-single-notification type="warning">
          <span slot="headline">
            {{ $t('notifications.error.headline') }}
          </span>
          <div slot="message" class="text-center py-2">
            {{ $t('loginPage.errors.sessionExpired') }}
          </div>
        </dx-single-notification>
      </div>
    </div>
  </div>
</template>

<script>
import DxScipSales from '~/components/DX-ScipSales/form'
import DxProducts from '~/domains/partnerPortal/components/DX-Products'
import DxSingleNotification from '~/components/DX-Notifications/SingleNotification'

export default {
  name: 'IframeOfferScipSales',
  components: {
    DxScipSales,
    DxProducts,
    DxSingleNotification,
  },
  layout: 'empty',
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/antragsstrecke-iframe/',
      fr: '/formulaire-de-demande-iframe/',
    },
  },
  computed: {
    formKey() {
      if (this.product.key === 'private' || this.product.key === 'business') {
        // Old products
        return undefined
      }

      return `${this.product.variantKey}_employee`
    },
    loadId() {
      const {
        key,
        variantKey,
        versionNumber,
        type,
        forceProduct,
        policyid,
        contractid,
      } = this.$route.query
      const loadId = {
        key,
        variantKey,
        versionNumber,
        type,
        forceProduct,
        loaded: null,
      }
      if (policyid) {
        loadId.policyId = policyid
        loadId.loaded = true
      }
      if (contractid) {
        loadId.contractId = contractid
        loadId.loaded = true
      }
      if (!policyid && !contractid) {
        loadId.loaded = false
      }
      return loadId
    },

    jwt() {
      return this.$route.query.jwt || null
    },

    product() {
      const key = this.$route.query.key || null
      const variantKey = this.$route.query.variantKey || null

      return {
        key,
        variantKey,
      }
    },

    forceProduct() {
      return this.$route.query.forceProduct || null
    },
  },
}
</script>

<style lang="scss" scoped>
.safetyPadding {
  padding-bottom: rem(200);
  background: #f7f9fc;
}
</style>
