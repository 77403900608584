<template>
  <div>
    <div class="row content-border-bottom pt-4 pb-5 mb-4">
      <div class="col-12">
        <h5>
          {{
            template === 'partnerPortal'
              ? $t('policies.policiesData')
              : $t('policies.mainPolicyHolder')
          }}
        </h5>
      </div>

      <div class="col-12 col-md-6">
        <template v-if="template === 'partnerPortal'">
          <ul class="neutral">
            <li>
              {{ $t('partnerPortal.policyClosedOn') }}
              {{ getFormattedDate(contract.policedAt) }}
            </li>
            <li>
              {{ $t('partnerPortal.policyValidFrom') }}
              {{ getFormattedDate(contract.startOfInsurance) }}
            </li>
            <li>
              {{ $t('partnerPortal.product') }}:
              {{ contract.options || contract.productName }}
            </li>
          </ul>
        </template>

        <ul v-else class="neutral">
          <li v-if="policyCompany">
            {{ policyCompany }}
          </li>
          <li v-if="policyHolder">
            {{ policyHolder }}
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-6">
        <ul v-if="template === 'partnerPortal'" class="neutral">
          <li>
            {{ $t('partnerPortal.grossPremium') }}:
            {{ formatPriceOrStatus(contract) }}
          </li>
          <li v-if="contract.paymentPeriodPaymentStatus">
            {{ $t('partnerPortal.paymentStatus.text') }}:
            {{
              $t(
                `partnerPortal.paymentStatus.${contract.paymentPeriodPaymentStatus}`,
              )
            }}
          </li>
          <li v-if="contract.lastOpenReminder">
            {{ $t('partnerPortal.reminderStatus.text') }}:
            {{
              $t(
                `partnerPortal.reminderStatus.${contract.lastOpenReminder.status}`,
              )
            }}
          </li>
          <li v-if="contract.lastOpenCoverageLoss">
            {{ $t('partnerPortal.coverageClosing') }}: ab
            {{ getFormattedDate(contract.lastOpenCoverageLoss.startDate) }}
          </li>
        </ul>
      </div>

      <div v-if="template !== 'partnerPortal'" class="col-12 col-md-8">
        <ul class="neutral">
          <li>
            {{
              $t('policies.insuranceStart', {
                start: getFormattedDate(contract.startOfInsurance),
              })
            }}
          </li>
          <li>
            {{
              $t('policies.paymentPeriod', {
                interval: getFormattedPaymentInterval(contract.paymentInterval),
              })
            }}
          </li>
          <li>
            {{
              $t('policies.choosenOptions', { label: contract.productLabel })
            }}
          </li>
          <li>
            {{ $t('policies.claimsCount', { count: contract.claimsCount }) }}
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="template !== 'partnerPortal' && hasContractModules(contract)"
      class="row content-border-bottom pt-4 pb-5 mb-4"
    >
      <div
        v-for="item in contract.contractModules"
        :key="item.name"
        class="col-12 mb-4"
      >
        <h5>{{ item.name }}</h5>
        <ul
          v-for="field in item.fields"
          :key="Object.keys(field)[0]"
          class="neutral"
        >
          <li>{{ (key = Object.keys(field)[0]) }}: {{ field[key] }}</li>
        </ul>
      </div>
    </div>

    <!-- POLICIES Rechnungen -->
    <div v-if="isContractActive" class="row content-border-bottom py-4 mb-4">
      <div class="col-12 col-md-6">
        <dx-download-list
          :items="getInvoices(contract)"
          :headline="$t('invoice')"
          class="mb-4"
          tag-type="h5"
        />
      </div>

      <div class="col-12 col-md-6">
        <dx-download-list
          :items="getOtherDocuments(contract)"
          :headline="$t('documents')"
          tag-type="h5"
        />

        <nuxt-link
          v-if="showOlderDocs"
          :to="
            localePath({
              name: 'SelfService-Invoices',
              query: { contract: contract.id },
            })
          "
          class="d-block secondary-link my-4"
        >
          {{ $t('policies.showOlderDocs') }}
        </nuxt-link>
      </div>

      <div v-if="template === 'partnerPortal'" class="col-12 col-md-6">
        <dx-download-list
          :items="[
            {
              title: `Rendement_${contract.number}.xlsx`,
              onClick: generateRendement,
            },
          ]"
          :headline="$t('policies.rendement')"
          class="mb-4"
          tag-type="h5"
        />
      </div>
    </div>

    <div
      v-if="
        template === 'partnerPortal' ||
        hasInsuredPersons(contract) ||
        inviteAllowed(contract)
      "
      class="row pt-4"
    >
      <template v-if="template === 'partnerPortal'">
        <div class="col-12 mb-5">
          <div class="grey-box p-3">
            <dx-personal-data
              :contract="contract"
              :report-damage="reportDamage"
              @details-saved="detailsSaved"
            />
          </div>
        </div>
      </template>

      <div
        v-if="
          hasInsuredPersons(contract) || (!isActive && inviteAllowed(contract))
        "
        class="col-12 mb-4"
      >
        <dx-insured-persons
          v-if="hasInsuredPersons(contract)"
          :contract-data="contract"
          :on-success="refreshInsuredPersons"
        />
        <div class="text-right">
          &nbsp;
          <a
            v-if="
              inviteAllowed(contract) &&
              showMoreInsuredPersonsLink &&
              hasMoreInsuredPersons(contract, 9)
            "
            tabindex="0"
            class="btn-blank secondary-link"
            data-integrationtest="mvp-invitation-toggle"
            @click="loadMoreInsuredPersons"
          >
            {{ $t('showMorePersons') }}
          </a>
        </div>
        <div class="row mb-1">
          <div class="col-12 col-md-6 mt-2">
            <button
              v-if="!isActive && inviteAllowed(contract)"
              tabindex="0"
              class="btn-blank show-more-link plus-icon"
              data-integrationtest="mvp-invitation-toggle"
              @click="openForm"
            >
              {{ $t('addPerson') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isActive" class="row mb-4">
      <div class="col-12 col-md-6">
        <dx-invite-person
          :listed-mails="listUsedEmails(contract)"
          :contract-id="contract.id"
          :on-success="refreshContracts"
          :on-cancel="openForm"
        />
      </div>
    </div>

    <dx-invitations v-if="hasInvitations(contract)" :contract-data="contract" />
  </div>
</template>

<script>
import DxDownloadList from '~/components/DX-DownloadList'
import sortDocuments from '~/javascripts/mixins/sortDocuments'
import dateMixin from '~/javascripts/mixins/dateMixin'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import formatOfferPriceMixin from '~/javascripts/mixins/formatOfferPriceMixin'
import generateRendementQuery from '~/domains/partnerPortal/__gql__/queries/generateRendement.gql'
import { RESET_STORE_DAMAGEREPORT } from '~/domains/contract/claim/__store__/mutations'
import contractsInsuredPersonsQuery from '~/domains/contract/__gql__/queries/contractsInsuredPersons.gql'
import DxInvitations from '../DX-Invitations'
import DxPersonalData from './DX-PersonalData'
import DxInsuredPersons from './DX-InsuredPersons'
import DxInvitePerson from './DX-InvitePerson'

export default {
  name: 'DxPolicyHolderOptions',
  components: {
    DxInvitePerson,
    DxDownloadList,
    DxInsuredPersons,
    DxInvitations,
    DxPersonalData,
  },
  mixins: [dateMixin, formatOfferPriceMixin, sortDocuments, loadingMixin],
  props: {
    contract: {
      type: Object,
      default: () => ({
        startOfInsurance: null,
        paymentInterval: '',
      }),
    },
    onSuccess: {
      type: Function,
      default() {
        return null
      },
    },
    onError: {
      type: Function,
      default() {
        return null
      },
    },
    template: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showMoreInsuredPersonsLink: true,
      isActive: false,
      isUserFeedack: null,
      collapsed: false,
      page: 1,
      perPage: 10,
    }
  },
  computed: {
    showOlderDocs() {
      return this.template !== 'partnerPortal'
    },
    policyCompany() {
      const { companyName } = this.$store.state.application.currentUser
      return companyName ? `${this.$t('company')} ${companyName}` : null
    },
    policyHolder() {
      const { firstName, lastName } = this.$store.state.application.currentUser
      return `${firstName || ''} ${lastName || ''}`
    },
    isContractActive() {
      return (
        this.template !== 'partnerPortal' || this.contract.status === 'ACTIVE'
      )
    },
  },
  methods: {
    detailsSaved() {
      this.$emit('details-saved')
      this.page = 1
      this.showMoreInsuredPersonsLink = true
    },
    refreshContracts() {
      this.detailsSaved()
      if (this.isActive) {
        this.openForm()
      }
    },
    openForm() {
      this.isActive = !this.isActive
    },
    async refreshInsuredPersons() {
      this.setGlobalLoading(true)
      this.showMoreInsuredPersonsLink = false

      const { data } = await this.$apollo.query({
        query: contractsInsuredPersonsQuery,
        fetchPolicy: 'network-only',
        variables: {
          page: 1,
          perPage: this.page * this.perPage,
        },
      })

      const contractIndex = data.contracts.findIndex(
        (c) => c.id === this.contract.id,
      )
      this.contract.insuredPersons =
        data.contracts[contractIndex].insuredPersons

      if (data.contracts[contractIndex].insuredPersons.length >= 10) {
        this.showMoreInsuredPersonsLink = true
      }
      this.setGlobalLoading(false)
    },
    async loadMoreInsuredPersons() {
      this.page += 1
      this.showMoreInsuredPersonsLink = false

      const tmpInsuredPersons = this.contract.insuredPersons

      const { data } = await this.$apollo.query({
        query: contractsInsuredPersonsQuery,
        fetchPolicy: 'network-only',
        variables: {
          page: this.page,
          perPage: this.perPage,
        },
      })

      const contractIndex = data.contracts.findIndex(
        (c) => c.id === this.contract.id,
      )
      this.contract.insuredPersons = tmpInsuredPersons.concat(
        data.contracts[contractIndex].insuredPersons,
      )

      if (data.contracts[contractIndex].insuredPersons.length >= 10) {
        this.showMoreInsuredPersonsLink = true
      }
    },
    hasMoreInsuredPersons(contract, count) {
      return contract.insuredPersons && contract.insuredPersons.length > count
    },
    hasInvitations(contract) {
      return contract.invitations && contract.invitations.length > 0
    },
    hasInsuredPersons(contract) {
      return contract.insuredPersons && contract.insuredPersons.length > 0
    },
    hasContractModules(contract) {
      return contract.contractModules && contract.contractModules.length > 0
    },
    inviteAllowed(contract) {
      return this.isContractActive && (contract.householdCoverage || false)
    },
    listUsedEmails(contract) {
      const usedEmails = []
      usedEmails.push(this.$store.state.application.currentUser.username)
      if (contract) {
        if (contract.invitations) {
          contract.invitations.forEach((invitedPerson) => {
            usedEmails.push(invitedPerson.email)
          })
        }
        if (contract.insuredPersons) {
          contract.insuredPersons.forEach((insuredPerson) => {
            usedEmails.push(insuredPerson.email)
          })
        }
      }
      return usedEmails
    },
    getFormattedPaymentInterval(paymentInterval) {
      return this.$t(`policies.${paymentInterval.toLowerCase()}`)
    },
    async generateRendement() {
      try {
        this.setGlobalLoading(true)
        const { data } = await this.$apollo.query({
          query: generateRendementQuery,
          variables: {
            contractId: parseInt(this.contract.id, 10),
          },
        })
        if (data && data.generateRendement) {
          const api = process.env.DEXTRA_API_URL
          window.open(`${api}${data.generateRendement.url}`, '_self')
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } catch (error) {
        console.error(error)
        this.$notification.error({ messageKey: 'notifications.error.message' })
      } finally {
        this.setGlobalLoading(false)
      }
    },
    reportDamage(personId) {
      this.$store.commit(RESET_STORE_DAMAGEREPORT)
      const contractId = this.contract.id
      this.$router.push({
        path: this.localePath('PartnerPortal-ReportDamage-Info'),
        query: { contractId, personId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
