var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.editable)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-10 mb-2 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n          "+_vm._s(_vm.$t('offer.basic.insureDate'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.formData.startOfInsurance)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 mb-2 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n          "+_vm._s(_vm.$t('offer.basic.chooseBranch'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.translatedBranch)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 mb-2 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.basic.secondaryEmployment'
                : 'offer.basic.secondaryEmployment'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.formData.secondaryEmployment ? _vm.$t('yes') : _vm.$t('no'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 mb-2 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.basic.countStreetVehicles'
                : 'offer.basic.countStreetVehicles'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.formData.countStreetVehicles)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.basic.countWaterVehicles'
                : 'offer.basic.countWaterVehicles'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.formData.countWaterVehicles)+"\n        ")])])])]:_c('form',{on:{"change":_vm.change}},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
            required: true,
            atMostOneYearInFuture: true,
            atMostOneWeekInPast: _vm.isBrokerOffer,
          }),expression:"{\n            required: true,\n            atMostOneYearInFuture: true,\n            atMostOneWeekInPast: isBrokerOffer,\n          }",modifiers:{"disable":true}}],attrs:{"label":_vm.$t('offer.basic.insureDate'),"error":_vm.errors.first('startOfInsurance'),"only-in-future":!_vm.isBrokerOffer,"is-date-input":"","type":"date","data-vv-name":"startOfInsurance","min-date":_vm.isBrokerOffer ? new Date().fp_incr(-7) : null,"default-date":_vm.isBrokerOffer ? new Date().fp_incr(1) : null},on:{"blur":_vm.change},model:{value:(_vm.formData.startOfInsurance),callback:function ($$v) {_vm.$set(_vm.formData, "startOfInsurance", $$v)},expression:"formData.startOfInsurance"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_vm._v("\n        "+_vm._s(_vm.$t('offer.basic.chooseBranch'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('dx-select',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"error":_vm.errors.first('branchOfIndustry'),"options":_vm.getBranchOptions(),"data-vv-name":"branchOfIndustry"},model:{value:(_vm.formData.branchOfIndustry),callback:function ($$v) {_vm.$set(_vm.formData, "branchOfIndustry", $$v)},expression:"formData.branchOfIndustry"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_vm._v("\n        "+_vm._s(_vm.$t(
            _vm.isBrokerOffer
              ? 'brokerOffer.basic.secondaryEmployment'
              : 'offer.basic.secondaryEmployment'
          ))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('dx-radio-list',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"options":_vm.secondaryEmploymentOptions,"error":_vm.errors.first('secondaryEmployment'),"name":"secondaryEmployment","data-vv-name":"secondaryEmployment"},model:{value:(_vm.formData.secondaryEmployment),callback:function ($$v) {_vm.$set(_vm.formData, "secondaryEmployment", $$v)},expression:"formData.secondaryEmployment"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12"},[(_vm.formData.secondaryEmployment)?_c('dx-textarea',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
            required: _vm.formData.secondaryEmployment,
          }),expression:"{\n            required: formData.secondaryEmployment,\n          }",modifiers:{"disable":true}}],staticClass:"mt-2",attrs:{"error":_vm.errors.first('secondaryEmploymentDescription'),"label":_vm.$t('offer.basic.secondaryEmploymentDescription'),"data-vv-name":"secondaryEmploymentDescription"},model:{value:(_vm.formData.secondaryEmploymentDescription),callback:function ($$v) {_vm.$set(_vm.formData, "secondaryEmploymentDescription", $$v)},expression:"formData.secondaryEmploymentDescription"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
            required: true,
            numeric: true,
            min_value: _vm.formData.countWaterVehicles > 0 ? 0 : 1,
          }),expression:"{\n            required: true,\n            numeric: true,\n            min_value: formData.countWaterVehicles > 0 ? 0 : 1,\n          }",modifiers:{"disable":true}}],attrs:{"label":_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.basic.countStreetVehicles'
                : 'offer.basic.countStreetVehicles'
            ),"error":_vm.errors.first('countStreetVehicles'),"data-vv-name":"countStreetVehicles"},on:{"input":_vm.change},model:{value:(_vm.formData.countStreetVehicles),callback:function ($$v) {_vm.$set(_vm.formData, "countStreetVehicles", $$v)},expression:"formData.countStreetVehicles"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
            required: true,
            numeric: true,
            min_value: _vm.formData.countStreetVehicles > 0 ? 0 : 1,
          }),expression:"{\n            required: true,\n            numeric: true,\n            min_value: formData.countStreetVehicles > 0 ? 0 : 1,\n          }",modifiers:{"disable":true}}],attrs:{"label":_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.basic.countWaterVehicles'
                : 'offer.basic.countWaterVehicles'
            ),"error":_vm.errors.first('countWaterVehicles'),"data-vv-name":"countWaterVehicles"},on:{"input":_vm.change},model:{value:(_vm.formData.countWaterVehicles),callback:function ($$v) {_vm.$set(_vm.formData, "countWaterVehicles", $$v)},expression:"formData.countWaterVehicles"}})],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }