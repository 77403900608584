<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>

    <dx-upload
      :id="`scip-${item.id}`"
      :label="translatedLabel"
      :disabled="item.readonly"
      :error="translatedError"
      :show-upload-button="false"
      @input="onInput"
    />
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'
import DxUpload from '@/components/DX-Upload'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DXUploadAdapter',
  components: {
    DxDescriptionLabel,
    DxUpload,
  },
  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  computed: {
    translatedError() {
      if (!this.error) {
        return null
      }

      return this.$t(`errors.text.${this.error.type}`, {
        ...this.error.params,
        label: this.translatedLabel,
      })
    },
  },

  methods: {
    async onInput(files) {
      const uploads = await Promise.all(
        files.map(async (file) => {
          const dataBuffer = await file.file.arrayBuffer()
          const byteArray = new Uint8Array(dataBuffer)
          let binaryString = ''
          for (let i = 0; i < byteArray.length; i += 1) {
            binaryString += String.fromCharCode(byteArray[i])
          }
          return {
            fileName: file.file.name,
            mimeType: file.file.type,
            base64Data: btoa(binaryString),
          }
        }),
      )
      this.$emit('input', uploads)
    },
  },
}
</script>
