<template>
  <dx-broker-offer-intro product="p-private" />
</template>

<script>
import DxBrokerOfferIntro from '~/domains/brokerOffer/components/DX-BrokerOfferIntro'

export default {
  name: 'BrokerOfferPPrivateIntro',
  components: {
    DxBrokerOfferIntro,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/privatrechtsschutz/p-private/antragsstrecke/',
      fr: '/portailpartenaires/protection-juridique-privee/p-private/formulaire-de-demande/',
    },
  },
}
</script>
