<template>
  <div class="head-blue">
    <div class="py-5">
      <slot name="head-top-content" />
      <div class="container-fluid text-center my-3 px-3">
        <h2 v-if="headline">
          {{ headline }}
        </h2>
        <p
          v-if="textblock"
          :class="{
            'd-none d-md-block': hideTextOnMobile,
            'mb-4': buttonText,
          }"
          class="subheadline"
          v-html="textblock"
        />
        <dx-button
          v-if="reportDamageCustomer"
          :text="buttonText"
          :icon="buttonIcon"
          theme="wine"
          class="damage-button px-1 px-sm-0"
          @click="toDamageReport"
        />

        <dx-button
          v-else-if="reportDamageBroker"
          :text="buttonText"
          :icon="buttonIcon"
          theme="wine"
          class="damage-button px-1 px-sm-0"
          @click="toDamageReport"
        />
      </div>
      <div class="head-content">
        <slot name="head-content" />
      </div>
    </div>
  </div>
</template>

<script>
// import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
// import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old/dist/dextra-components.common'
import { RESET_STORE_DAMAGEREPORT } from '~/domains/contract/claim/__store__/mutations'

export default {
  name: 'DxHeadBlue',
  components: {
    DxButton,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    buttonRouteName: {
      type: String,
      default: null,
    },
    buttonIcon: {
      type: String,
      default: '',
    },
    headline: {
      type: String,
      default: '',
    },
    textblock: {
      type: String,
      default: '',
    },
    hideTextOnMobile: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: '',
    },
    routeQuery: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    hasContracts() {
      return this.$store.state.application.hasContracts
    },
    currentPortal() {
      return this.$store.state.authentication.currentPortal
    },
    reportDamageCustomer() {
      return (
        this.buttonText &&
        this.hasContracts &&
        this.currentPortal === 'CUSTOMER_CENTER'
      )
    },
    reportDamageBroker() {
      return this.buttonText && this.currentPortal === 'BROKER_PORTAL'
    },
  },
  methods: {
    toDamageReport() {
      this.$store.commit(RESET_STORE_DAMAGEREPORT)
      this.$store.commit('SET_DAMAGEPOPUP', true)
      this.$router.push({ path: this.localePath(this.routeName) })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
