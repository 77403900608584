<template>
  <div v-if="isShown" class="popup-container w-100">
    <div
      class="container-fluid container-fluid content-wrap-medium content-pad position-relative"
    >
      <div class="popup-wrap">
        <div class="popup-visual">
          <span class="headline-mobile d-md-none">
            {{ headline }}
          </span>
        </div>
        <div class="popup-content">
          <h4 class="d-none d-md-block">
            {{ headline }}
          </h4>
          <p class="mb-0">
            {{ text }}
          </p>
        </div>
        <button class="popup-close" @click="hidePopup">
          <span class="visually-hidden">
            {{ $t('close') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DxReportDamagePopUp',
  props: {
    headline: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShown: this.show,
    }
  },
  watch: {
    show(newValue) {
      if (newValue && !this.isShown) {
        this.isShown = newValue
      }
    },
  },
  methods: {
    hidePopup() {
      this.$store.commit('SET_DAMAGEPOPUP', false)
      this.isShown = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
