<template>
  <div class="container-fluid">
    <div class="content-wrap">
      <h1 class="mt-4">Willkommen zu Dextra</h1>
      <p>Dies ist die Startseite.</p>
      <p>Folgende Links sind verfügbar:</p>
      <ul>
        <li>
          <nuxt-link :to="localePath('Login')">Login</nuxt-link>
        </li>
        <li>
          <nuxt-link :to="localePath('SelfService-PwReset')">
            Passwort zurücksetzen
          </nuxt-link>
        </li>
        <li>
          <nuxt-link :to="localePath('SelfService')">Kundencenter</nuxt-link>
        </li>
        <!-- li>
          <nuxt-link :to="localePath('SelfService-Invoices')">
            Rechnungen und Dokumente
          </nuxt-link>
        </li -->
        <li>
          <nuxt-link :to="localePath('SelfService-ClaimOverview')">
            Rechtsfall verwalten
          </nuxt-link>
        </li>
        <li>
          <nuxt-link :to="localePath('SelfService-ReportDamage-MyInfo')">
            Rechtsfall melden
          </nuxt-link>
          <ol>
            <li>
              <nuxt-link :to="localePath('SelfService-ReportDamage-Facts')">
                Sachverhalt
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="localePath('SelfService-ReportDamage-DocumentUpload')"
              >
                Dokumentenupload
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('SelfService-ReportDamage-Deadline')">
                Frist
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="localePath('SelfService-ReportDamage-Counterparty')"
              >
                Gegenpartei
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('SelfService-ReportDamage-ThankYou')">
                Danke
              </nuxt-link>
            </li>
          </ol>
        </li>
        <li>
          <nuxt-link :to="localePath('LawyerPortal')">Anwaltsportal</nuxt-link>
          <ol>
            <li>
              <nuxt-link :to="localePath('LawyerPortal-ClosedDamageCases')">
                Geschlossene Rechtsfälle
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('LawyerPortal-Damage')">
                Rechtsfälle
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('LawyerPortal-Mailbox')">
                Postfach
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('LawyerPortal-NewDamageCase')">
                Neuer Rechtsfall
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('LawyerPortal-Registration')">
                Registrierung
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('LawyerPortal-UserData')">
                Nutzerdaten
              </nuxt-link>
            </li>
          </ol>
        </li>
        <li>
          <nuxt-link to="/playground">(Spielplatz)</nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Startpage',
  meta: {
    isPublic: true,
  },
}
</script>
