<script>
import acceptBrokerShareRequestMutation from '~/domains/contract/claim/__gql__/mutations/acceptBrokerShareRequestMutation.gql'
import createBrokerShareRequestMutation from '~/domains/contract/claim/__gql__/mutations/createBrokerShareRequestMutation.gql'
import rejectBrokerShareRequestMutation from '~/domains/contract/claim/__gql__/mutations/rejectBrokerShareRequestMutation.gql'
import createBrokerShareMutation from '~/domains/contract/claim/__gql__/mutations/createBrokerShareMutation.gql'
import revokeBrokerShareMutation from '~/domains/contract/claim/__gql__/mutations/revokeBrokerShareMutation.gql'
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'

export default {
  mixins: [apolloErrorsMixin],
  methods: {
    async createShareRequest(claimId) {
      let result
      this.setGlobalLoading(true)
      try {
        const { data } = await this.$apollo.mutate({
          mutation: createBrokerShareRequestMutation,
          variables: { claimId },
        })
        if (data && data.createBrokerShareRequest) {
          result = data.createBrokerShareRequest
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } catch (error) {
        console.error(error)
        this.$notification.error({ messageKey: 'notifications.error.message' })
      } finally {
        this.setGlobalLoading(false)
      }
      return result
    },
    async acceptShareRequest(token, claimId) {
      let result
      this.setGlobalLoading(true)
      try {
        const { data } = await this.$apollo.mutate({
          mutation: acceptBrokerShareRequestMutation,
          variables: { token, claimId },
        })
        if (data && data.acceptBrokerShareRequest) {
          result = data.acceptBrokerShareRequest
          this.$notification.success({
            messageKey: 'requests.claimShareAccepted',
          })
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } catch (error) {
        console.error(error)
        const sanitizedErrors = this.handleApolloError(error)
        if (sanitizedErrors.networkError.code === 15) {
          // SHARE_EXPIRED_ERROR
          this.$notification.error({
            messageKey: 'validationErrors.ERROR__SHARE_EXPIRED_ERROR',
          })
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } finally {
        this.setGlobalLoading(false)
      }
      return result
    },
    async rejectShareRequest(token, claimId) {
      let result
      this.setGlobalLoading(true)
      try {
        const { data } = await this.$apollo.mutate({
          mutation: rejectBrokerShareRequestMutation,
          variables: { token, claimId },
        })
        if (data && data.rejectBrokerShareRequest) {
          result = data.rejectBrokerShareRequest
          this.$notification.success({
            messageKey: 'requests.claimShareRejected',
          })
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } catch (error) {
        console.error(error)
        const sanitizedErrors = this.handleApolloError(error)
        if (sanitizedErrors.networkError.code === 15) {
          // SHARE_EXPIRED_ERROR
          this.$notification.error({
            messageKey: 'validationErrors.ERROR__SHARE_EXPIRED_ERROR',
          })
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } finally {
        this.setGlobalLoading(false)
      }
      return result
    },
    async createShare(claimId) {
      let result
      this.setGlobalLoading(true)
      try {
        const { data } = await this.$apollo.mutate({
          mutation: createBrokerShareMutation,
          variables: { claimId },
        })
        if (data && data.createShare) {
          result = data.createShare
          this.$notification.success({
            messageKey: 'requests.claimShareAccepted',
          })
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } catch (error) {
        console.error(error)
        this.$notification.error({ messageKey: 'notifications.error.message' })
      } finally {
        this.setGlobalLoading(false)
      }
      return result
    },
    async revokeShare(claimId, id) {
      let result
      this.setGlobalLoading(true)
      try {
        const { data } = await this.$apollo.mutate({
          mutation: revokeBrokerShareMutation,
          variables: { claimId, id },
        })
        if (data && data.revokeShare) {
          result = data.revokeShare
          this.$notification.success({
            messageKey: 'requests.claimShareRejected',
          })
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } catch (error) {
        console.error(error)
        this.$notification.error({ messageKey: 'notifications.error.message' })
      } finally {
        this.setGlobalLoading(false)
      }
      return result
    },
  },
}
</script>
