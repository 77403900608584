<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>

    <template v-if="hasToggle">
      <label class="label" :for="item.id">{{ translatedLabel }}</label>
      <button class="toggleButton" type="button" @click="toggleTextArea">
        <chevron-down-icon :class="['icon', !isOpen && 'iconTurned']" />
        <span>{{ t(`${translationKeyPrefix}.toggle`) }}</span>
      </button>

      <dx-textarea
        v-if="isOpen"
        :id="`scip-${item.id}`"
        :value="value"
        :disabled="item.readonly"
        :error="translatedError"
        @input="update"
        @blur="onBlur"
      />
    </template>

    <template v-else>
      <dx-textarea
        :id="`scip-${item.id}`"
        :value="value"
        :label="translatedLabel"
        :disabled="item.readonly"
        :error="translatedError"
        @input="update"
        @blur="onBlur"
      />
    </template>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import { DxTextarea } from '@sumcumo/dextra-frontend-component-lib'

import ChevronDownIcon from '@/components/icons/ChevronDownIcon'
import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'

import { useScipTranslationWithFallback, isEmpty } from '../../utils'

export default {
  name: 'DXTextAreaAdapter',
  components: {
    ChevronDownIcon,
    DxTextarea,
    DxDescriptionLabel,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    translatedError() {
      return this.error
        ? this.t(
            `errors.text.${isEmpty(this.value) ? 'required' : this.error.type}`,
            {
              ...this.error.params,
              label: this.translatedLabel,
            },
          )
        : null
    },

    hasToggle() {
      return this.item.source.attributeKey === 'extra-details'
    },
  },

  methods: {
    update(newValue) {
      this.$emit('input', newValue)
    },

    toggleTextArea() {
      this.isOpen = !this.isOpen
    },

    onBlur() {
      this.$emit('validate', { dataKey: this.item.dataKey })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';
/* .label {
  margin-bottom: rem(8);
  color: $black-clr;
  font-family: $font-family-heavy;
  font-size: rem(20);
  line-height: rem(28);
} */

.toggleButton {
  display: flex;
  margin-bottom: rem(16);
  border: none;
  padding: 0;
  background: transparent;
  font-family: $font-family-heavy;
  font-size: rem(16);
  line-height: rem(24);
  outline: none;
}

.icon {
  margin-right: rem(16);
  color: $functional-clr-info;
  transition: transform 200ms ease-in-out;
}

.iconTurned {
  transform: rotate(180deg);
}
</style>
