<template>
  <ul class="neutral delete-list p-3">
    <dx-loading-overlay v-if="loading" />
    <li v-if="!loading" class="font-weight-bold position-relative d-flex">
      <span class="delete-headline">
        {{ headline }}
      </span>

      <div class="delete-item-wrap">
        <dx-tooltip
          :show-delete-dialog="true"
          position="left"
          @deleteItem="ensureDelete"
        >
          <template slot="trigger">
            <button
              type="button"
              class="btn-blank delete-btn mt-1"
              data-integrationtest="btn-delete-item"
            >
              <span class="visually-hidden">
                {{ $t('delete') }}
              </span>
            </button>
          </template>
        </dx-tooltip>
      </div>
    </li>
    <slot v-if="!loading" name="list-item" />
  </ul>
</template>

<script>
import DxTooltip from '~/components/DX-Tooltip'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'

export default {
  name: 'DxDeleteItem',
  components: {
    DxTooltip,
    DxLoadingOverlay,
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
    onConfirmDelete: {
      type: Function,
      default() {
        return null
      },
    },
    confirmText: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ensureDelete() {
      this.onConfirmDelete()
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
