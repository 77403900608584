var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('h4',{staticClass:"icon mb-3"},[_vm._v("\n    "+_vm._s(_vm.$t('damageRequests'))+"\n  ")]),_vm._v(" "),(
      Array.isArray(_vm.externalLawyerInvitations) &&
      _vm.externalLawyerInvitations.length > 0
    )?_c('div',_vm._l((_vm.externalLawyerInvitations),function(invitation){return _c('dx-link-container',{key:invitation.id,staticClass:"mb-3",attrs:{"show-more-link":true,"is-big":true,"has-shadow":true,"route-name":{
        path: _vm.localePath('LawyerPortal-NewDamageCase'),
        query: {
          lawyerInvitationToken: invitation.token,
        },
      }}},[_c('template',{slot:"content"},[_c('div',{staticClass:"d-md-flex justify-content-start align-items-center"},[_c('div',{staticClass:"flex-item"},[_vm._v("\n            "+_vm._s(invitation.claim.insuredPerson.fullName)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"flex-item"},[_vm._v("\n            "+_vm._s(invitation.claim.number)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"flex-item"},[_vm._v("\n            "+_vm._s(_vm.$t('acceptUntil'))+"\n            "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("\n              "+_vm._s(_vm.getFormattedDateTime(invitation.expiredAt))+"\n            ")])])])])],2)}),1):_vm._e(),_vm._v(" "),(!_vm.loading && !_vm.externalLawyerInvitations.length)?_c('div',[_vm._v("\n    "+_vm._s(_vm.$t('lawyerPortal.noClaimInvitationsMessage'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('dx-loading-overlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }