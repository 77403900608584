<template>
  <div>
    <img
      :alt="$t('errorGeneric')"
      class="w-25"
      src="~assets/images/error.svg"
    />
    <h3 class="mt-4 mt-md-5 mb-4 mb-md-5">
      {{ $t('errorGeneric') }}
    </h3>
    <dx-button :text="$t('toMainPage')" route-name="/" />
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'DxError',
  components: {
    DxButton,
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
