<script>
export default {
  /* eslint-disable no-console */
  methods: {
    handleApolloError(error, debug) {
      const hasGraphQLError = error.graphQLErrors && error.graphQLErrors.length
      const hasNetworkError =
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors.length

      if (debug) {
        console.log('======== APOLLO ERROR ========')
        console.log(error)
        console.log('---------- GRAPHQL ----------')
        console.log(error.graphQLErrors)
        console.log('---------- NETWORK ----------')
        console.log(hasNetworkError && error.networkError.result.errors)
        console.log('==============================')
      }

      const graphQLError = {
        code: -1,
        message: '',
      }
      const networkError = {
        code: -1,
        message: '',
        path: [],
        name: '',
        conflict_id: '',
      }

      if (hasGraphQLError) {
        graphQLError.code = error.graphQLErrors[0].code || -1
        graphQLError.message = error.graphQLErrors[0].message || ''
      }

      if (hasNetworkError) {
        networkError.code = error.networkError.result.errors[0].code || -1
        networkError.message = error.networkError.result.errors[0].message || ''
        networkError.path = error.networkError.result.errors[0].path || []
        networkError.name = error.networkError.result.errors[0].name || ''
        networkError.conflict_id =
          error.networkError.result.errors[0].conflict_id || ''
      }

      return {
        graphQLError,
        networkError,
      }
    },
  },
}
</script>
