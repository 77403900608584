<template>
  <li>
    <dl>
      <dt>{{ getZipLabel() }}:</dt>
      <dd>{{ entryValue.zip }}</dd>

      <dt>{{ getCityLabel() }}:</dt>
      <dd>{{ entryValue.city }}</dd>
    </dl>
  </li>
</template>

<script>
import { toRefs } from '@vue/composition-api'
import { useScipTranslationWithFallback } from '@/components/DX-ScipSales/utils'

export default {
  name: 'ZipCityFieldEntry',
  props: {
    entryValue: {
      type: Object,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
    formContext: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },
  methods: {
    getZipLabel() {
      const legacyKey = `attributes.${this.entry.source.moduleKey}.${this.entry.source.attributeKey}.label.zip`
      return this.te(legacyKey) ? this.t(legacyKey) : this.t('zipCity.zip')
    },
    getCityLabel() {
      const legacyKey = `attributes.${this.entry.source.moduleKey}.${this.entry.source.attributeKey}.label.city`
      return this.te(legacyKey) ? this.t(legacyKey) : this.t('zipCity.city')
    },
  },
}
</script>
