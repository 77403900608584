import {
  LOADING,
  SET_CURRENT_USER,
  SET_CURRENT_LAWYER,
  SET_CURRENT_BROKER,
} from '~/domains/application/__store__/mutations'
import { RESET_STORE_DAMAGEREPORT } from '~/domains/contract/claim/__store__/mutations'
import { RESET_STORE } from '~/domains/application/__store__/actions'
import jwtTools from '~/javascripts/helper/jwtTools'
import writeLoginCookie from '~/javascripts/helper/loginCookie'
import {
  SET_ROLES,
  SET_TOKEN,
  SET_PORTALS,
  SET_CURRENT_PORTAL,
} from './mutations'
import AuthenticationService from './services'

export const LOGIN = 'LOGIN'
export const LOGIN_GROUPE_MUTUEL = 'LOGIN_GROUPE_MUTUEL'
export const CHANGE_PORTAL = 'CHANGE_PORTAL'
export const FAKE_LOGIN = 'FAKE_LOGIN'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_EXTERNAL_LAWYER = 'GET_PROFILE_EXTERNAL_LAWYER'
export const GET_PROFILE_BROKER = 'GET_PROFILE_BROKER'
export const REGISTER_AND_ACCEPT_CONTRACT_INVITATION =
  'REGISTER_AND_ACCEPT_CONTRACT_INVITATION'
export const CREATE_LAWYER = 'CREATE_LAWYER'
export const GET_CONFLICT_DETAILS = 'GET_CONFLICT_DETAILS'
export const RESOLVE_CONFLICTS = 'RESOLVE_CONFLICTS'
export const GET_NEW_TOKEN = 'GET_NEW_TOKEN'

export default {
  async [REGISTER_AND_ACCEPT_CONTRACT_INVITATION](context, payload) {
    context.commit(LOADING, true)
    // Create Profile and accept invitation
    const result = await AuthenticationService.acceptInsuredPersonInvitation(
      payload.apollo,
      payload.variables,
    )
    const token = {
      jwt: result.acceptInsuredPersonInvitation,
    }

    const portals = jwtTools.getPortals(token.jwt)
    const roles = jwtTools.getRoles(token.jwt)

    context.commit(SET_TOKEN, { token })
    context.commit(SET_ROLES, roles)
    context.commit(SET_PORTALS, portals)
    context.commit(SET_CURRENT_PORTAL, 'CUSTOMER_CENTER')

    return context.dispatch(GET_PROFILE, payload)
  },
  async [CREATE_LAWYER](context, payload) {
    context.commit(SET_CURRENT_LAWYER, {
      currentLawyer: { email: payload.variables.lawyerProfileData.email },
    })
    const result = await AuthenticationService.createLawyer(
      payload.apollo,
      payload.variables,
    )
    const token = {
      jwt: result.createLawyer,
    }

    const portals = jwtTools.getPortals(token.jwt)
    const roles = jwtTools.getRoles(token.jwt)

    context.commit(SET_TOKEN, { token })
    context.commit(SET_ROLES, roles)
    context.commit(SET_PORTALS, portals)
    context.commit(SET_CURRENT_PORTAL, 'LAWYER_PORTAL')

    context.commit(SET_CURRENT_LAWYER, {
      currentLawyer: { ...payload.variables.lawyerProfileData },
    })
    return context.dispatch(GET_PROFILE_EXTERNAL_LAWYER, payload)
  },
  async [LOGIN](context, payload) {
    context.commit(RESET_STORE_DAMAGEREPORT)
    context.commit(LOADING, true)

    let token

    if (payload.token) {
      token = {
        jwt: payload.token,
      }
    } else {
      const result = await AuthenticationService.login(
        payload.apollo,
        payload.variables,
      )
      token = {
        jwt: result.jwtToken,
      }
    }

    const roles = jwtTools.getRoles(token.jwt)
    const portals = jwtTools.getPortals(token.jwt)

    context.commit(SET_TOKEN, { token })
    context.commit(SET_PORTALS, portals)

    writeLoginCookie(token.jwt)

    // unambiguous access
    if (roles.length && portals.length < 2) {
      context.commit(SET_ROLES, roles)
      context.commit(SET_CURRENT_PORTAL, portals[0])
      if (context.getters.isExternalLawyer) {
        return context.dispatch(GET_PROFILE_EXTERNAL_LAWYER, payload)
      }
      if (context.getters.isBroker) {
        return context.dispatch(GET_PROFILE_BROKER, payload)
      }
      return context.dispatch(GET_PROFILE, payload)
    }
    return false
  },
  async [LOGIN_GROUPE_MUTUEL](context, payload) {
    context.commit(SET_CURRENT_USER, {
      currentUser: { email: payload.variables.email },
    })
    context.commit(LOADING, true)
    const gmToken = await AuthenticationService.loginGroupeMutuel(
      payload.apollo,
      payload.variables,
    )
    const token = {
      jwt: gmToken,
    }

    const roles = jwtTools.getRoles(token.jwt)

    context.commit(SET_TOKEN, { token })
    context.commit(SET_ROLES, roles)

    return context.dispatch(GET_PROFILE, payload)
  },
  async [CHANGE_PORTAL](context, payload) {
    context.commit(LOADING, true)
    const result = await AuthenticationService.changePortal(
      payload.apollo,
      payload.variables,
    )

    const token = {
      jwt: result,
    }

    const roles = jwtTools.getRoles(token.jwt)
    const portals = jwtTools.getPortals(token.jwt)

    context.commit(SET_TOKEN, { token })
    context.commit(SET_PORTALS, portals)
    context.commit(SET_ROLES, roles)
    context.commit(SET_CURRENT_PORTAL, payload.variables.portal)

    if (context.getters.isExternalLawyer) {
      return context.dispatch(GET_PROFILE_EXTERNAL_LAWYER, payload)
    }
    if (context.getters.isBroker) {
      return context.dispatch(GET_PROFILE_BROKER, payload)
    }
    return context.dispatch(GET_PROFILE, payload)
  },
  async [GET_NEW_TOKEN](context, payload) {
    const result = await AuthenticationService.keepAlive(payload.apollo)
    const token = {
      jwt: result,
    }

    writeLoginCookie(token.jwt)

    context.commit(SET_TOKEN, { token })
    return result
  },
  async [FAKE_LOGIN](context, payload) {
    context.commit(LOADING, true)
    const result = await AuthenticationService.keepAlive(payload.apollo)
    const token = {
      jwt: result,
    }

    await context.dispatch(RESET_STORE)
    context.commit(SET_TOKEN, { token })
    context.commit(SET_ROLES, payload.variables.roles)

    let initialPortal = 'CUSTOMER_CENTER'
    if (context.getters.isExternalLawyer) {
      initialPortal = 'LAWYER_PORTAL'
    } else if (context.getters.isBroker) {
      initialPortal = 'BROKER_PORTAL'
    }
    return context.dispatch(CHANGE_PORTAL, {
      variables: { portal: initialPortal },
      apollo: payload.apollo,
    })
  },
  async [GET_PROFILE](context, payload) {
    const profile = await AuthenticationService.getProfile(payload.apollo)
    context.commit(SET_CURRENT_USER, {
      currentUser: { ...profile.customer, username: profile.username },
    })
  },
  async [GET_PROFILE_EXTERNAL_LAWYER](context, payload) {
    const profile = await AuthenticationService.getProfileLawyer(payload.apollo)
    context.commit(SET_CURRENT_LAWYER, {
      currentLawyer: { ...profile.lawyer, username: profile.username },
    })
  },
  async [GET_PROFILE_BROKER](context, payload) {
    const profile = await AuthenticationService.getProfileBroker(payload.apollo)
    context.commit(SET_CURRENT_BROKER, {
      currentBroker: { ...profile.broker, username: profile.username },
    })
  },
  async [GET_CONFLICT_DETAILS](context, payload) {
    const conflicts = await AuthenticationService.conflicts(
      payload.apollo,
      payload.variables,
    )
    return conflicts
  },
  async [RESOLVE_CONFLICTS](context, payload) {
    context.commit(LOADING, true)
    const result = await AuthenticationService.resolveConflicts(
      payload.apollo,
      payload.variables,
    )
    context.commit(LOADING, false)
    return result
  },
}
