<template>
  <div>
    <!-- NON EDIT MODE -->
    <template v-if="!editable">
      <ul class="neutral mb-0">
        <li>
          {{ email }}
        </li>
      </ul>
    </template>

    <!-- EDIT MODE -->
    <template v-if="editable">
      <div class="password-form">
        <dx-input
          v-model.trim="form.email"
          v-validate.disable="'required|email'"
          :label="$t('email')"
          :error="errors.first('invalidEmail')"
          type="email"
          class="mb-4"
          data-vv-name="invalidEmail"
          name="email"
          autocomplete="email"
        />

        <dx-input
          v-model.trim="form.emailConfirmation"
          v-validate.disable="{
            required: true,
            match_strings: form.email,
          }"
          :label="$t('emailRepeat')"
          :error="errors.first('invalidEmailRepeat')"
          type="email"
          class="mb-4"
          data-vv-name="invalidEmailRepeat"
          name="emailRepeat"
          autocomplete="email"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { DxInput } from '@sumcumo/dextra-frontend-component-lib-old'
import emailChangeMutation from '~/domains/authentication/__gql__/mutations/changeEmail.gql'
import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'

export default {
  name: 'DxEmailForm',
  components: {
    DxInput,
  },
  mixins: [loadingMixin, apolloErrorsMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: null,
        emailConfirmation: null,
      },
    }
  },
  computed: {
    email() {
      return (
        this.$store.state.application.currentUser &&
        this.$store.state.application.currentUser.username
      )
    },
  },
  methods: {
    async changeEmail() {
      let result = false
      const validationResult = await this.$validator.validate()
      if (validationResult) {
        const variables = this.form
        this.setGlobalLoading(true)
        try {
          const { data } = await this.$apollo.mutate({
            mutation: emailChangeMutation,
            variables,
          })
          if (!data || !data.changeEmail) {
            this.$notification.error({
              messageKey: 'notifications.error.message',
            })
            return result
          }
          const { success, validationErrors } = data.changeEmail

          if (success) {
            this.$store.commit('SET_CURRENT_USER_USERNAME', this.form.email)
            this.form = {
              email: null,
              emailConfirmation: null,
            }
            this.$notification.success({ messageKey: 'emailChangedSuccess' })
            result = true
          } else {
            validationErrors.forEach((err) => {
              this.$validator.errors.add(this.getServerValidationError(err))
            })
            scrollFirstErrorIntoView()
          }
        } catch (error) {
          // eslint-disable-next-line no-shadow
          const sanitizedErrors = this.handleApolloError(error)

          if (sanitizedErrors.networkError.code === 14) {
            // USERNAME_ALREADY_EXISTS
            this.$notification.error({
              messageKey: 'validationErrors.ERROR__EMAIL_NOT_AVAILABLE',
            })
          } else {
            this.$notification.error({
              messageKey: 'notifications.error.message',
            })
          }
        } finally {
          this.setGlobalLoading(false)
        }
      } else {
        scrollFirstErrorIntoView()
      }
      return result
    },
  },
}
</script>
