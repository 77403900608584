<template>
  <div class="row">
    <div class="col-12 col-md mb-4">
      <div class="content-block counter-party-wrap icon-party party">
        <h4>{{ $t('damageCheck.party1') }}</h4>
        <ul class="neutral mb-0">
          <li>{{ getCompanyOrNameWithSalutation(claim.insuredPerson) }}</li>
          <li v-if="claim.insuredPerson.companyName">
            {{ getNameWithSalutation(claim.insuredPerson) }}
          </li>
          <li v-else />
          <li>{{ getStreetWithNumber(claim.insuredPerson) }}</li>
          <li>{{ getZipWithCity(claim.insuredPerson) }}</li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-md mb-4">
      <div class="content-block counter-party-wrap icon-party party-opposition">
        <h4>{{ $t('damageCheck.party2') }}</h4>
        <ul v-if="claim.counterparty" class="neutral">
          <li>{{ getCompanyOrNameWithSalutation(claim.counterparty) }}</li>
          <li v-if="claim.counterparty.companyName">
            {{ getNameWithSalutation(claim.counterparty) }}
          </li>
          <li>{{ getStreetWithNumber(claim.counterparty) }}</li>
          <li>{{ getZipWithCity(claim.counterparty) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import personMixin from '~/javascripts/mixins/personMixin'

export default {
  name: 'DxPartyCounterParty',
  mixins: [personMixin],
  props: {
    claim: {
      type: Object,
      required: true,
      default: () => ({
        insuredPerson: {
          address: {},
        },
        counterparty: {
          address: {},
        },
      }),
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
