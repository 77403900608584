<template>
  <div class="row">
    <dx-edit-data-toggle
      :headline="$t('policies.mainPolicyHolder')"
      :show="isContractActive"
      :callback="savePersonalData"
      class="col-12 col-md-6"
    >
      <template slot="data-dynamic" slot-scope="{ editMode }">
        <ul v-if="!editMode" class="neutral">
          <li>{{ formattedName }}</li>
          <li v-if="isCompany">
            {{ contract.policyholder.firstName }}
            {{ contract.policyholder.lastName }}
          </li>
          <li>
            {{ contract.policyholder.address.street }}
            {{ contract.policyholder.address.number }}
          </li>
          <li>
            {{ contract.policyholder.address.zip }}
            {{ contract.policyholder.address.city }}
          </li>
          <li v-if="contract.policyholder.contactDetails.phone" class="mt-3">
            {{ $t('telephone') }}:
            {{ contract.policyholder.contactDetails.phone }}
          </li>
          <li v-if="contract.policyholder.addressMutation" class="mt-3">
            {{ $t('willChangedAt') }}:
            {{
              getFormattedDate(contract.policyholder.addressMutation.validFrom)
            }}
          </li>
        </ul>
        <div v-if="editMode">
          <dx-radio-list
            v-if="isSinglePerson"
            v-model="contract.policyholder.salutation"
            :options="salutationOptions"
            disable-list
            :label="$t('salutation')"
            name="salutation"
            class="mb-3"
          />

          <dx-input
            v-if="isCompany"
            v-model="contract.policyholder.companyName"
            :label="$t('company')"
            disabled
            class="mb-3"
          />

          <dx-input
            v-if="isSinglePerson || isCompany"
            v-model="contract.policyholder.firstName"
            :label="$t('firstName')"
            disabled
            class="mb-3"
          />

          <dx-input
            v-model="contract.policyholder.lastName"
            disabled
            :label="$t('lastName')"
            class="mb-3"
          />

          <dx-input
            v-model="address.street"
            v-validate.disable="'required|min:2'"
            :error="errors.first('street')"
            :label="$t('street')"
            data-vv-name="street"
            class="mb-3"
          />

          <dx-input
            v-model="address.number"
            v-validate.disable="'required'"
            :error="errors.first('number')"
            :label="$t('houseNumber')"
            data-vv-name="number"
            class="mb-3"
          />

          <dx-zip-to-city-input
            :zip-input="address.zip"
            :city-input="address.city"
            required
            icon="orange-cross"
            @update:city="(city) => (address.city = city)"
            @update:zip="(zip) => (address.zip = zip)"
          />

          <dx-input
            v-model="address.validFrom"
            v-validate.disable="'required|inTheFutureGermanFormat'"
            :error="errors.first('invalidDate')"
            :label="$t('changedAt')"
            only-in-future
            type="date"
            class="mb-3"
            data-vv-name="invalidDate"
          />

          <dx-radio-list
            v-model="address.generateNewPolicy"
            :options="policyOptions"
            :label="$t('newPolicy')"
            name="regenerate_policy"
            class="mb-3"
          />
        </div>
      </template>
    </dx-edit-data-toggle>
    <dx-popup-modal ref="addressconfirmationModal">
      <h3>{{ $t('userData.addressChange') }}</h3>
      <p>
        {{ $t('userData.brokerCustomerAddressChangeWarning') }}
      </p>
      <div
        class="d-flex flex-wrap-reverse justify-content-center d-sm-block text-right"
      >
        <span
          class="abort-btn mt-2 mr-3 ml-3 mt-sm-0 secondary-link btn-blank"
          @click="closeAddressConfirmationModal"
        >
          {{ $t('abort') }}
        </span>

        <dx-button
          type="button"
          class="d-inline"
          :text="$t('userData.addressChangeConfirmation')"
          small
          @click="confirmAddressChange"
        />
      </div>
    </dx-popup-modal>
    <div class="col-12 col-md-6">
      <ul class="neutral mt-4 mt-md-0 pl-md-3">
        <li v-if="editEmail">
          <dx-add-email
            :on-success="toggleEmailForm"
            :on-cancel="toggleEmailForm"
            :dex-id="contract.policyholder.dexId"
            @email-saved="detailsSaved"
          />
        </li>
        <li v-else>
          {{ $t('email') }}:
          <button
            v-if="!hasEmail && isContractActive"
            tabindex="0"
            class="ml-2 btn-blank show-more-link plus-icon"
            @click="toggleEmailForm"
          >
            {{ $t('partnerPortal.addEmail') }}
          </button>
          <template v-else>
            {{ contract.policyholder.email || '-' }}
          </template>
        </li>
      </ul>
      <dx-button
        v-if="isContractActive"
        small
        :text="$t('reportNewDamage')"
        theme="wine"
        class="my-3 mb-md-4 mt-md-5 text-right text-md-center"
        @click="toDamageReport"
      />
    </div>
  </div>
</template>

<script>
import {
  DxInput,
  DxButton,
  DxRadioList,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxEditDataToggle from '~/components/DX-EditDataToggle'
import DxZipToCityInput from '~/components/DX-ZipToCityInput'
import createOrUpdateBrokerAddressMutation from '~/domains/partnerPortal/__gql__/mutations/createOrUpdateBrokerAddressMutation.gql'
import dateMixin from '~/javascripts/mixins/dateMixin'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'
import DxAddEmail from '../DX-AddEmail'

export default {
  name: 'DxPersonalData',
  components: {
    DxButton,
    DxEditDataToggle,
    DxInput,
    DxZipToCityInput,
    DxAddEmail,
    DxRadioList,
    DxPopupModal,
  },
  mixins: [dateMixin, loadingMixin, validationErrorsMixin],
  props: {
    contract: {
      type: Object,
      default: () => ({
        policyholder: { address: {}, contactDetails: {} },
      }),
    },
    reportDamage: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      editEmail: false,
      address: this.contract.policyholder.addressMutation || {
        ...this.contract.policyholder.address,
        validFrom: null,
        hash: null,
        generateNewPolicy: false,
      },
      salutationOptions: [
        {
          text: this.$t('male'),
          id: 'MALE',
        },
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
      ],
      policyOptions: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
    }
  },
  computed: {
    hasEmail() {
      return this.contract.policyholder.email
    },
    isContractActive() {
      return this.contract.status === 'ACTIVE'
    },
    isCompany() {
      return this.contract.policyholder.salutation === 'COMPANY'
    },
    isSinglePerson() {
      return (
        this.contract.policyholder.salutation === 'MALE' ||
        this.contract.policyholder.salutation === 'FEMALE'
      )
    },
    formattedName() {
      const { policyholder } = this.contract
      if (policyholder.companyName) {
        return `${this.$t('company')} ${policyholder.companyName || ''}`
      }
      if (policyholder.salutation === 'FAMILY') {
        return `${this.$t('family')} ${policyholder.lastName || ''}`
      }
      const salutation = policyholder.salutation
        ? this.$t(policyholder.salutation.toLowerCase())
        : ''
      return `${salutation} ${policyholder.fullName || ''}`
    },
  },
  watch: {
    contract(newContract) {
      this.address = newContract.policyholder.addressMutation || {
        ...newContract.policyholder.address,
        validFrom: null,
        hash: null,
        generateNewPolicy: false,
      }
    },
  },
  methods: {
    toggleEmailForm() {
      this.editEmail = !this.editEmail
    },
    detailsSaved() {
      this.$emit('details-saved')
    },
    async updateAddress() {
      const { street, number, zip, city, validFrom, generateNewPolicy, hash } =
        this.address
      const { data } = await this.$apollo.mutate({
        mutation: createOrUpdateBrokerAddressMutation,
        variables: {
          address: {
            street,
            number,
            zip,
            city,
            validFrom,
            generateNewPolicy,
            hash,
          },
          policyholderId: this.contract.policyholder.dexId,
        },
      })
      if (data && data.createOrUpdateBrokerAddressMutation) {
        const { success, validationErrors } =
          data.createOrUpdateBrokerAddressMutation
        if (validationErrors.length) {
          validationErrors.forEach((err) => {
            this.$validator.errors.add(this.getServerValidationError(err))
          })
          scrollFirstErrorIntoView()
        }
        return success
      }
      this.$notification.error({ messageKey: 'notifications.error.message' })
      return false
    },

    savePersonalData() {
      this.$refs.addressconfirmationModal.open()
    },
    closeAddressConfirmationModal() {
      this.$refs.addressconfirmationModal.close()
    },
    async confirmAddressChange() {
      this.closeAddressConfirmationModal()
      this.savingPersonalData()
    },
    async savingPersonalData() {
      let result = false
      const isValid = await this.$validator.validateAll()
      if (isValid) {
        try {
          this.setGlobalLoading(true)
          result = await this.updateAddress()
          if (result) {
            this.detailsSaved()
          }
        } catch (error) {
          console.error(error)
          result = false
        } finally {
          this.setGlobalLoading(false)
        }
      }
      return result
    },
    toDamageReport() {
      if (!this.hasEmail) {
        this.$notification.error({
          messageKey: 'reportDamageEmail.message',
          headlineKey: 'reportDamageEmail.headline',
        })
        this.editEmail = true
        if (this.$refs.addEmail) {
          this.$refs.addEmail.$validator.validateAll()
        }
      } else {
        this.reportDamage(this.contract.policyholder.dexId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../index';
</style>
