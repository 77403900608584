<script>
export default {
  methods: {
    getCompanyOrNameWithSalutation(sourceObject) {
      const { firstName, lastName, salutation, companyName } = sourceObject
      return companyName
        ? `${
            salutation && salutation === 'COMPANY'
              ? this.$t(salutation.toLowerCase())
              : ''
          } ${companyName}`
        : `${
            salutation && salutation !== 'COMPANY'
              ? this.$t(salutation.toLowerCase())
              : ''
          } ${firstName || ''} ${lastName || ''}`
    },
    getNameWithSalutation(sourceObject) {
      const { firstName, lastName, salutation } = sourceObject
      return `${
        salutation && salutation !== 'COMPANY'
          ? this.$t(salutation.toLowerCase())
          : ''
      } ${firstName || ''} ${lastName || ''}`
    },
    getStreetWithNumber(sourceObject) {
      if (!sourceObject.address) {
        return ''
      }
      const { street, number } = sourceObject.address
      return `${street || ''} ${number || ''}`
    },
    getZipWithCity(sourceObject) {
      if (!sourceObject.address) {
        return ''
      }
      const { city, zip } = sourceObject.address
      return `${zip || ''} ${city || ''}`
    },
  },
}
</script>
