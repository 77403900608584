<template>
  <form @input="updateDetails">
    <dx-input
      v-model="bankDetails.hourlyRate.value"
      v-validate.disable="'required|min:1'"
      :error="errors.first('hourlyRate')"
      :label="$t('hourly')"
      class="mb-4"
      data-vv-name="hourlyRate"
    />

    <dx-input
      v-model="bankDetails.bankAccount.accountHolder"
      v-validate.disable="'required'"
      :error="errors.first('accountHolder')"
      :label="$t('accountOwner')"
      class="mb-4"
      data-vv-name="accountHolder"
    />

    <dx-iban-bic-input
      :iban="bankDetails.bankAccount.iban"
      :bic="bankDetails.bankAccount.bic"
      css-class="mb-4"
      @update:iban="(iban) => (bankDetails.bankAccount.iban = iban)"
      @update:bic="(bic) => (bankDetails.bankAccount.bic = bic)"
    />
  </form>
</template>

<script>
import { DxInput } from '@sumcumo/dextra-frontend-component-lib-old'
import DxIbanBicInput from '~/components/DX-IbanBicInput'

export default {
  name: 'DxBankDetails',
  components: {
    DxInput,
    DxIbanBicInput,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    bankDetails: {
      type: Object,
      default: () => ({
        bankAccount: {
          accountHolder: '',
          iban: '',
          bic: '',
        },
        hourlyRate: {
          value: '',
        },
      }),
    },
    updateBankDetails: {
      type: Function,
      default: () => null,
    },
    clearErrors: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    updateDetails() {
      this.updateBankDetails({
        bankAccount: { ...this.bankDetails.bankAccount },
        hourlyRate: { ...this.bankDetails.hourlyRate },
      })
    },
  },
}
</script>
