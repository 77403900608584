<template>
  <dx-registration :invitation-token="$route.query.invitationToken" />
</template>

<script>
import DxRegistration from '~/domains/user/components/DX-Registration'

export default {
  name: 'Registration',
  meta: {
    isPublic: true,
  },
  components: {
    DxRegistration,
  },
  nuxtI18n: {
    paths: {
      de: '/registration',
      fr: '/inscription',
      if: '/registrazione',
    },
  },
}
</script>
