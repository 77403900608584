<template>
  <div>
    <h5 v-if="hasComponents()" class="mb-3">
      {{ $t('docUploadFurther') }}
    </h5>
    <dx-upload
      v-for="count in compAmount"
      :key="count"
      :doc-types="docTypes"
      class="mb-3"
      :theme="theme"
      :type="type"
      :show-close-button="count === compAmount"
      :on-upload-success="(file) => onUploadSuccess(file)"
      :on-upload-remove="(id) => onUploadRemove(id)"
      @closeUploadItem="subComp"
    />
    <div v-if="compAmount < maxComps" class="text-right">
      <button class="btn-blank show-more-link plus-icon" @click="addComp">
        {{ hasComponents() ? $t('docUploadFurther') : $t('docUploadFurther') }}
      </button>
    </div>
  </div>
</template>

<script>
import DxUpload from '~/components/DX-Upload/'

export default {
  name: 'DxUploadMulti',
  components: {
    DxUpload,
  },
  props: {
    docTypes: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: '',
    },
    remove: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    maxComps: {
      type: Number,
      default: 5,
    },
    onUploadSuccess: {
      type: Function,
      default: () => null,
    },
    onUploadRemove: {
      type: Function,
      default: () => null,
    },
    onUploadError: {
      type: Function,
      default: () => null,
    },
    initCompAmount: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      compAmount: this.initCompAmount,
    }
  },
  methods: {
    addComp() {
      this.compAmount += 1
    },
    subComp() {
      this.compAmount -= 1
    },
    hasComponents() {
      return this.compAmount > 0
    },
  },
}
</script>
