<template>
  <div class="w-100 content-bg-color">
    <div class="container-fluid mt-3 my-md-4">
      <dx-back-text-button
        :text="$t('damageBacklog.overview')"
        :route="localePath('PartnerPortal')"
        class="link-back"
      />
    </div>

    <div class="container-fluid content-wrap-medium content-pad">
      <div class="row">
        <div class="col">
          <h3>{{ $t('partnerPortal.customerPendingOffers') }}</h3>
        </div>
      </div>
      <div class="row flex-row-reverse my-5">
        <div class="col-12 col-md-8 col-lg-6">
          <dx-input
            v-model="searchTerm"
            :placeholder="$t('damageBacklog.search')"
            has-icon="search"
            type="search"
          />
        </div>
        <div class="col-12">
          <dx-open-offer-filter v-model="filterOption" />
        </div>
      </div>
      <div class="header-wrap pl-3 py-3">
        <div class="row">
          <div class="col-12 col-md-3">
            {{ $t('name') }}
          </div>
          <div class="col-12 col-md-3">
            {{ $t('partnerPortal.offerNr') }}
          </div>
          <div class="col-12 col-md-2">
            {{ $t('partnerPortal.product') }}
          </div>
          <div class="col-12 col-md-3">
            {{ $t('partnerPortal.grossPremium') }}
          </div>
          <div class="col-12 col-md-1">
            {{ $t('eBill') }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dx-open-offers-list
            :offers="brokerOffers"
            :is-loading="isLoading"
            :load-offer="loadOffer"
            :reject-offer="rejectOffer"
            :reactivate-offer="reactivateOffer"
            :initially-selected-offer="initiallySelectedOffer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import {
  DxInput,
  DxBackTextButton,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxOpenOffersList from '~/domains/partnerPortal/components/DX-OpenOffersList'
import DxOpenOfferFilter from '~/domains/partnerPortal/components/DX-OpenOfferFilter'
import offersQuery from '~/domains/partnerPortal/__gql__/queries/brokerOffers.gql'
import brokerOfferQuery from '~/domains/partnerPortal/__gql__/queries/brokerOffer.gql'
import declineOfferMutation from '~/domains/partnerPortal/__gql__/mutations/declineBrokerOfferMutation.gql'
import reactivateOfferMutation from '~/domains/partnerPortal/__gql__/mutations/reactivateOfferMutation.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'OpenOffers',
  components: {
    DxInput,
    DxBackTextButton,
    DxOpenOffersList,
    DxOpenOfferFilter,
  },
  mixins: [loadingMixin],
  data() {
    return {
      filterOption: this.$route.query.offerId ? 'all' : 'pending',
      searchTerm: (this.$route.query.offerId || '').toString(),
      brokerOffers: [],
      allOffersLoaded: false,
      chunkSize: 10,
      offset: 0,
      initialLoadingFinished: false,
      isMounted: false,
      currentQueryId: 0,
    }
  },
  computed: {
    isLoading() {
      const brokerOffersQuery = this.$apollo.queries.brokerOffers
      return (
        !this.initialLoadingFinished ||
        !brokerOffersQuery ||
        brokerOffersQuery.loading
      )
    },
    initiallySelectedOffer() {
      return (this.$route.query.offerId || '').toString()
    },
  },
  watch: {
    searchTerm(newValue) {
      this.resetQuery()
      // Clear offerId query if search term was changed
      if (
        this.$route.query.offerId &&
        this.$route.query.offerId.toString() !== newValue
      ) {
        // eslint-disable-next-line no-unused-vars
        const { offerId, ...query } = this.$route.query
        this.$router.replace({ query })
      }
    },
    filterOption() {
      this.resetQuery()
    },
  },
  mounted() {
    this.isMounted = true
    window.onscroll = this.throttledLoadMoreOnScroll
  },
  destroyed() {
    this.isMounted = false
    window.onscroll = null
  },
  methods: {
    loadMoreOffersIfNecessary() {
      const footer = document.querySelector('footer')
      const footerHeight = footer !== null ? footer.scrollHeight : 0
      const visible = document.documentElement.clientHeight
      const pageHeight =
        document.documentElement.scrollHeight - footerHeight - 300
      const bottomOfWindow = visible + window.pageYOffset >= pageHeight
      if (bottomOfWindow) {
        this.onLoadMore()
      }
    },
    throttledLoadMoreOnScroll: throttle(async function callLoadMore() {
      this.loadMoreOffersIfNecessary()
    }, 200),
    async onLoadMore() {
      const brokerOffersQuery = this.$apollo.queries.brokerOffers
      if (
        !this.isMounted ||
        this.allOffersLoaded ||
        !brokerOffersQuery ||
        brokerOffersQuery.loading
      ) {
        return
      }
      const offset = this.offset + this.chunkSize
      const queryId = this.currentQueryId
      const promise = brokerOffersQuery.fetchMore({
        variables: { offset },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const loadedOffers = fetchMoreResult && fetchMoreResult.brokerOffers
          this.allOffersLoaded =
            !loadedOffers || loadedOffers.length < this.chunkSize
          if (!loadedOffers) {
            return previousResult
          }
          return {
            brokerOffers: [...previousResult.brokerOffers, ...loadedOffers],
          }
        },
      })
      if (promise) {
        this.offset = offset
        promise.catch((error) => {
          // Suppress error if not mounted: https://github.com/apollographql/apollo-client/issues/4114
          // or if variables (searchTerm/filterOption) have changed
          // https://github.com/Akryum/vue-apollo/blob/master/docs/guide/apollo/pagination.md
          if (this.isMounted && this.currentQueryId === queryId) {
            console.error(error)
          }
        })
      }
    },
    async loadOffer(id) {
      try {
        const result = await this.$apollo.query({
          query: brokerOfferQuery,
          variables: {
            id: parseInt(id, 10),
          },
          fetchPolicy: 'no-cache',
        })
        if (result && result.data && result.data.brokerOffer) {
          return result.data.brokerOffer
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async rejectOffer(id, reason, onSuccess, reloadOffer) {
      try {
        const result = await this.$apollo.mutate({
          mutation: declineOfferMutation,
          variables: {
            contractId: parseInt(id, 10),
            rejectionCode: reason.toUpperCase(),
          },
        })
        if (result && result.data && result.data.declineOffer) {
          if (onSuccess) {
            onSuccess()
          }
          if (this.filterOption === 'all' && reloadOffer) {
            reloadOffer()
          } else if (this.$apollo.queries.brokerOffers) {
            this.$apollo.queries.brokerOffers.refetch()
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    async reactivateOffer(id, reloadOffer) {
      try {
        this.setGlobalLoading(true)
        const result = await this.$apollo.mutate({
          mutation: reactivateOfferMutation,
          variables: {
            contractId: parseInt(id, 10),
          },
        })
        if (result && result.data && result.data.reactivateOffer) {
          if (this.filterOption === 'rejected') {
            this.filterOption = 'pending'
          }
          reloadOffer()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setGlobalLoading(false)
      }
    },
    resetQuery() {
      if (this.$apollo.queries.brokerOffers.observer) {
        this.currentQueryId = this.$apollo.queries.brokerOffers.observer.queryId
      }
      this.$apollo.queries.brokerOffers.stop()
      this.offset = 0
      this.allOffersLoaded = false
      this.$apollo.queries.brokerOffers.start()
    },
  },
  apollo: {
    brokerOffers: {
      query: offersQuery,
      fetchPolicy: 'network-only',
      variables() {
        return {
          searchStr: this.searchTerm.length > 2 ? this.searchTerm : '',
          filter: this.filterOption.toUpperCase(),
          offset: 0,
          limit: this.chunkSize,
        }
      },
      update(data) {
        this.initialLoadingFinished = true
        const loadedOffers = data && data.brokerOffers
        if (loadedOffers && loadedOffers.length >= this.chunkSize) {
          this.loadMoreOffersIfNecessary()
        }
        return loadedOffers || []
      },
      debounce: 500,
    },
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/pendente-offerten',
      fr: '/portailpartenaires/offres-en-attente',
      it: '/portaledeipartner/offerte-in-corso',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
