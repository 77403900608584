<template>
  <div>
    <!-- READ ONLY -->
    <template v-if="!editable">
      <ul class="neutral">
        <li class="row">
          <span class="col-12 col-md-4 col-xl-3 d-none d-md-block">
            {{ $t('adress') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ `${$t('company')} ${formData.companyName}` }}
          </span>
        </li>
        <li class="row font-weight-bold">
          <span class="col-12 col-md-8 col-xl-9 offset-md-4 offset-xl-3">
            {{ `${formData.address.street} ${formData.address.number}` }}
          </span>
        </li>
        <li class="row font-weight-bold">
          <span class="col-12 col-md-8 col-xl-9 offset-md-4 offset-xl-3">
            {{ formData.address.postOfficeBox }}
          </span>
        </li>
        <li class="row mb-2 font-weight-bold">
          <span class="col-12 col-md-8 col-xl-9 offset-md-4 offset-xl-3">
            {{ `${formData.address.zip} ${formData.address.city}` }}
          </span>
        </li>
        <li v-if="formData.contactDetails.phone" class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">{{ $t('telephone') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.contactDetails.phone }}
          </span>
        </li>
        <li v-if="!isBrokerOffer" class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.documentDeliveryMethod') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{
              $t(
                `offer.data.documentDeliveryMethods.${formData.documentDeliveryMethod}`,
              )
            }}
          </span>
        </li>
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.payment') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{
              $t(`policies.${brokerPolicyData.paymentInterval.toLowerCase()}`)
            }}
          </span>
        </li>
        <li v-if="brokerPolicyData.mainExpiry" class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.mainExpiry') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ brokerPolicyData.mainExpiry }}
          </span>
        </li>
      </ul>
      <br />

      <h5 class="headline">
        {{ $t('offer.data.contactPerson') }}
      </h5>
      <ul class="neutral">
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3 d-none d-md-block">
            {{ $t('name') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{
              `${$t(formData.contactPerson.salutation.toLowerCase())} ${
                formData.contactPerson.firstName
              } ${formData.contactPerson.lastName}`
            }}
          </span>
        </li>
        <li
          v-if="formData.contactPerson.contactDetails.phone"
          class="row mb-2 mb-md-2"
        >
          <span class="col-12 col-md-4 col-xl-3">{{ $t('telephone') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.contactPerson.contactDetails.phone }}
          </span>
        </li>
        <li
          v-if="formData.contactPerson.contactDetails.mobile"
          class="row mb-2 mb-md-2"
        >
          <span class="col-12 col-md-4 col-xl-3">{{ $t('mobilePhone') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.contactPerson.contactDetails.mobile }}
          </span>
        </li>
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">{{ $t('email') }}:</span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ formData.contactPerson.email || '-' }}
          </span>
        </li>
        <li class="row mb-2 mb-md-2">
          <span class="col-12 col-md-4 col-xl-3">
            {{ $t('offer.data.language') }}:
          </span>
          <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
            {{ $t(formData.contactPerson.locale) }}
          </span>
        </li>
      </ul>
      <br />

      <div
        v-for="(location, index) in formData.locations"
        :key="index"
        class="mt-2"
      >
        <h5 class="headline">
          {{ `${$t('brokerOffer.data.location')} ${index + 1}` }}
        </h5>
        <ul class="neutral">
          <li class="row">
            <span class="col-12 col-md-4 col-xl-3 d-none d-md-block">
              {{ $t('adress') }}:
            </span>
            <span class="col-12 col-md-8 col-xl-9 font-weight-bold">
              {{ location.street }} {{ location.number }}
            </span>
          </li>
          <li class="font-weight-bold row">
            <span class="col-12 col-md-8 col-xl-9 offset-md-4 offset-xl-3">
              {{ location.zip }} {{ location.city }}
            </span>
          </li>
          <li class="font-weight-bold row">
            <span class="col-12 col-md-8 col-xl-9 offset-md-4 offset-xl-3">
              {{ $t(`countries.${location.country}`) }}
            </span>
          </li>
        </ul>
        <br />
      </div>
    </template>

    <!-- EDIT MODE -->
    <form v-else @change="change">
      <!-- BASIC INFORMATION -->
      <dx-input
        v-model="formData.companyName"
        v-validate.disable="'required|min:2'"
        :error="
          errors.first('companyName', 'businessBase') ||
          errors.first('companyName', 'policyholder')
        "
        :label="$t('company')"
        class="mb-4"
        data-vv-name="companyName"
        data-vv-scope="businessBase"
        autocomplete="organization"
      />
      <dx-input
        v-if="fieldVisible(formData.address.street)"
        key="street"
        v-model="formData.address.street"
        v-validate.disable="'required|min:2'"
        :error="
          errors.first('street', 'businessBase') ||
          errors.first('street', 'policyholder.address')
        "
        :label="$t('street')"
        class="mb-4"
        data-vv-name="street"
        data-vv-scope="businessBase"
        autocomplete="address-line1"
      />
      <dx-input
        v-if="fieldVisible(formData.address.number)"
        key="streetNumber"
        v-model="formData.address.number"
        v-validate="{ required: false }"
        :error="
          errors.first('streetNumber', 'businessBase') ||
          errors.first('streetNumber', 'policyholder.address')
        "
        :label="$t('houseNumber')"
        class="mb-4"
        data-vv-name="streetNumber"
        data-vv-scope="businessBase"
        autocomplete="address-line2"
      />
      <dx-input
        v-if="fieldVisible(formData.address.postOfficeBox)"
        key="additional"
        v-model="formData.address.postOfficeBox"
        v-validate.disable="{ required: false }"
        :error="
          errors.first('additional', 'businessBase') ||
          errors.first('additional', 'policyholder.address')
        "
        :label="$t('mailbox')"
        class="mb-4"
        data-vv-name="additional"
        data-vv-scope="businessBase"
      />
      <dx-zip-to-city-input
        v-if="
          fieldVisible(formData.address.zip) ||
          fieldVisible(formData.address.city)
        "
        key="zipCity"
        :zip-input="formData.address.zip"
        :city-input="formData.address.city"
        required
        icon="orange-cross"
        class="mb-4"
        data-vv-scope="businessBase"
        :additional-data-vv-scopes="['policyholder.address']"
        @update:city="(city) => (formData.address.city = city)"
        @update:zip="(zip) => (formData.address.zip = zip)"
      />
      <dx-input
        v-if="fieldVisible(formData.contactDetails.phone)"
        key="phone"
        v-model="formData.contactDetails.phone"
        v-validate.disable="'required|min:4'"
        :error="
          errors.first('telephone', 'businessBase') ||
          errors.first('telephone', 'policyholder.contactDetails')
        "
        :label="$t('telephone')"
        class="mb-4"
        data-vv-name="telephone"
        data-vv-scope="businessBase"
        autocomplete="tel"
      />

      <!-- CONTACT PERSON -->
      <h3>{{ $t('offer.data.contactPerson') }}</h3>
      <dx-radio-list
        key="salutation"
        v-model="formData.contactPerson.salutation"
        v-validate.disable="'required'"
        :error="
          errors.first('gender', 'contactPerson') ||
          errors.first('gender', 'policyholder.contactPerson')
        "
        :options="salutationOptions"
        name="salutation"
        class="my-4"
        data-vv-name="gender"
        data-vv-scope="contactPerson"
      />
      <dx-input
        key="firstName"
        v-model="formData.contactPerson.firstName"
        v-validate.disable="'required|min:2'"
        :error="
          errors.first('firstName', 'contactPerson') ||
          errors.first('firstName', 'policyholder.contactPerson')
        "
        :label="$t('firstName')"
        class="mb-4"
        data-vv-name="firstName"
        data-vv-scope="contactPerson"
        autocomplete="given-name"
      />
      <dx-input
        key="lastName"
        v-model="formData.contactPerson.lastName"
        v-validate.disable="'required|min:2'"
        :error="
          errors.first('lastName', 'contactPerson') ||
          errors.first('lastName', 'policyholder.contactPerson')
        "
        :label="$t('lastName')"
        class="mb-4"
        data-vv-name="lastName"
        data-vv-scope="contactPerson"
        autocomplete="family-name"
      />
      <!-- Address here only required for small-business -->
      <template v-if="activeTariff === 'small-business'">
        <dx-input
          v-if="fieldVisible(formData.contactPerson.address.street)"
          key="contactPerson-street"
          v-model="formData.contactPerson.address.street"
          v-validate.disable="'required|min:2'"
          :error="
            errors.first('street', 'contactPerson') ||
            errors.first('street', 'policyholder.contactPerson.address')
          "
          :label="$t('street')"
          class="mb-4"
          data-vv-name="street"
          data-vv-scope="contactPerson"
          autocomplete="address-line1"
        />
        <dx-input
          v-if="fieldVisible(formData.contactPerson.address.number)"
          key="contactPerson-streetNumber"
          v-model="formData.contactPerson.address.number"
          v-validate="{ required: false }"
          :error="
            errors.first('streetNumber', 'contactPerson') ||
            errors.first('streetNumber', 'policyholder.contactPerson.address')
          "
          :label="$t('houseNumber')"
          class="mb-4"
          data-vv-name="streetNumber"
          data-vv-scope="contactPerson"
          autocomplete="address-line2"
        />
        <dx-zip-to-city-input
          v-if="
            fieldVisible(formData.contactPerson.address.zip) ||
            fieldVisible(formData.contactPerson.address.city)
          "
          key="contactPerson-zipCity"
          :zip-input="formData.contactPerson.address.zip"
          :city-input="formData.contactPerson.address.city"
          required
          icon="orange-cross"
          class="mb-4"
          data-vv-scope="contactPerson"
          :additional-data-vv-scopes="['policyholder.contactPerson.address']"
          @update:city="(city) => (formData.contactPerson.address.city = city)"
          @update:zip="(zip) => (formData.contactPerson.address.zip = zip)"
        />
      </template>
      <dx-input
        v-if="fieldVisible(formData.contactPerson.contactDetails.phone)"
        key="contactPerson-phone"
        v-model="formData.contactPerson.contactDetails.phone"
        v-validate.disable="
          (formData.contactPerson.contactDetails.mobile || '').trim()
            ? ''
            : 'required|min:4'
        "
        :error="
          errors.first('mobileOrPhoneNumber', 'contactPerson') ||
          errors.first(
            'mobileOrPhoneNumber',
            'policyholder.contactPerson.contactDetails',
          )
        "
        :label="$t('telephone')"
        class="mb-4"
        data-vv-name="mobileOrPhoneNumber"
        data-vv-scope="contactPerson"
        autocomplete="tel"
      />
      <dx-input
        v-if="fieldVisible(formData.contactPerson.contactDetails.mobile)"
        key="contactPerson-mobile"
        v-model="formData.contactPerson.contactDetails.mobile"
        v-validate.disable="
          (formData.contactPerson.contactDetails.phone || '').trim()
            ? ''
            : 'required|min:4'
        "
        :error="
          errors.first('mobileOrPhoneNumber', 'contactPerson') ||
          errors.first(
            'mobileOrPhoneNumber',
            'policyholder.contactPerson.contactDetails',
          )
        "
        :label="$t('mobilePhone')"
        class="mb-4"
        data-vv-name="mobileOrPhoneNumber"
        data-vv-scope="contactPerson"
        autocomplete="tel"
      />
      <dx-input
        v-if="
          fieldVisible(formData.contactPerson.email) ||
          fieldVisible(formData.contactPerson.emailConfirmation)
        "
        key="email"
        v-model.trim="formData.contactPerson.email"
        v-validate.disable="{
          required: !noEmail,
          email: !noEmail,
        }"
        :error="
          errors.first('invalidEmail', 'contactPerson') ||
          errors.first('email', 'policyholder.contactPerson')
        "
        :label="$t('email')"
        :disabled="noEmail"
        class="mb-4"
        data-vv-name="invalidEmail"
        data-vv-scope="contactPerson"
        autocomplete="email"
      />
      <dx-input
        v-if="
          fieldVisible(formData.contactPerson.email) ||
          fieldVisible(formData.contactPerson.emailConfirmation)
        "
        key="emailRepeat"
        v-model.trim="formData.contactPerson.emailConfirmation"
        v-validate.disable="{
          required: !noEmail,
          match_strings: !noEmail ? formData.contactPerson.email : false,
        }"
        :label="$t('emailRepeat')"
        :error="
          errors.first('invalidEmailRepeat', 'contactPerson') ||
          errors.first('emailConfirmation', 'policyholder.contactPerson')
        "
        :disabled="noEmail"
        class="mb-4"
        data-vv-name="invalidEmailRepeat"
        data-vv-scope="contactPerson"
        autocomplete="email"
      />
      <dx-radio-check
        v-if="isBrokerOffer && fieldVisible(noEmail)"
        id="noEmail"
        key="noEmail"
        v-model="noEmail"
        v-validate.disable="
          (formData.contactPerson.email || '').trim() ? '' : 'required'
        "
        :label="$t('offer.data.noEmail')"
        no-background
        type="checkbox"
        name="noEmail"
        class="mb-4"
        data-vv-scope="contactPerson"
        :checked="noEmail"
        @input="noEmail = !noEmail"
      />

      <template v-if="!isBrokerOffer">
        <h4>{{ $t('offer.data.documentDeliveryMethod') }}</h4>
        <dx-radio-list
          key="documentDeliveryMethod"
          v-model="formData.documentDeliveryMethod"
          v-validate.disable="'required'"
          :error="errors.first('documentDeliveryMethod', 'businessBase')"
          :options="deliveryOptions"
          name="documentDeliveryMethod"
          class="mb-5"
          data-vv-name="documentDeliveryMethod"
          data-vv-scope="businessBase"
        />
      </template>

      <template v-if="!hideOptionalFields">
        <h4>{{ $t('offer.data.language') }}</h4>
        <dx-radio-list
          key="language"
          v-model="formData.contactPerson.locale"
          v-validate.disable="'required'"
          :error="
            errors.first('locale', 'contactPerson') ||
            errors.first('locale', 'policyholder.contactPerson')
          "
          :options="langOptions"
          name="language"
          class="mb-5"
          data-vv-name="locale"
          data-vv-scope="contactPerson"
        />

        <template v-if="isBrokerOffer">
          <h4>{{ $t('offer.data.payment') }}</h4>
          <dx-radio-list
            key="paymentInterval"
            v-model="brokerPolicyData.paymentInterval"
            v-validate.disable="'required'"
            :error="errors.first('paymentInterval')"
            :options="paymentOptionsBroker"
            name="paymentInterval"
            class="mb-5"
            data-vv-name="paymentInterval"
          />
          <h4>{{ $t('offer.data.mainExpiry') }}</h4>
          <dx-input
            key="mainExpiry"
            v-model="brokerPolicyData.mainExpiry"
            v-validate.disable="'required|mainExpiry|date_format:dd.MM.'"
            class="mb-5"
            :error="errors.first('mainExpiry')"
            data-vv-name="mainExpiry"
          />
        </template>
      </template>

      <!-- LOCATIONS -->
      <template v-if="fieldVisible(formData.locations.length)">
        <h3>{{ $t('brokerOffer.data.locations') }}</h3>
        <div
          v-for="(location, index) in formData.locations"
          :key="index"
          class="mt-2"
        >
          <h4>{{ `${$t('offer.data.location')} ${index + 1}` }}</h4>
          <div class="text-right">
            <button
              class="btn-blank show-more-link cross-icon"
              @click.prevent="subLocation(index)"
            >
              {{ $t('offer.data.deleteLocation') }}
            </button>
          </div>
          <dx-input
            v-if="fieldVisible(formData.locations[index].street)"
            :key="`location_${index}-street`"
            v-model="formData.locations[index].street"
            v-validate.disable="'required|min:2'"
            :error="errors.first('street', `location_${index}`)"
            :label="$t('street')"
            class="mb-4"
            data-vv-name="street"
            :data-vv-scope="`location_${index}`"
            autocomplete="address-line1"
          />
          <dx-input
            v-if="fieldVisible(formData.locations[index].number)"
            :key="`location_${index}-streetNumber`"
            v-model="formData.locations[index].number"
            v-validate="{ required: false }"
            :error="errors.first('streetNumber', `location_${index}`)"
            :label="$t('houseNumber')"
            class="mb-4"
            data-vv-name="streetNumber"
            :data-vv-scope="`location_${index}`"
            autocomplete="address-line2"
          />
          <dx-zip-to-city-input
            v-if="
              fieldVisible(formData.locations[index].zip) ||
              fieldVisible(formData.locations[index].city)
            "
            :key="`location_${index}-zipCity`"
            :zip-input="formData.locations[index].zip"
            :city-input="formData.locations[index].city"
            required
            icon="orange-cross"
            class="mb-4"
            :data-vv-scope="`location_${index}`"
            :max-length="20"
            validation="required|max:20"
            @update:city="(city) => (formData.locations[index].city = city)"
            @update:zip="(zip) => (formData.locations[index].zip = zip)"
          />
          <dx-select
            :key="`location_${index}-country`"
            v-model="formData.locations[index].country"
            v-validate.disable="'required'"
            class="mb-4"
            :label="$t('country')"
            :error="errors.first('country', `location_${index}`)"
            :options="countryOptions"
            data-vv-name="country"
            :data-vv-scope="`location_${index}`"
          />
        </div>
        <div v-if="formData.locations.length < maxLocations" class="text-left">
          <button
            class="btn-blank show-more-link plus-icon"
            @click.prevent="addLocation"
          >
            {{ $t('offer.data.addLocation') }}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
  DxRadioCheck,
  DxSelect,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxZipToCityInput from '~/components/DX-ZipToCityInput'
import { countryOptions } from '~/javascripts/utils'

export default {
  name: 'BusinessPolicyHolderDataForm',
  components: {
    DxInput,
    DxRadioList,
    DxRadioCheck,
    DxSelect,
    DxZipToCityInput,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    isBrokerOffer: {
      type: Boolean,
      default: true,
    },
    hideOptionalFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        ...JSON.parse(
          JSON.stringify(
            this.$store.state[this.isBrokerOffer ? 'brokerOffer' : 'offer']
              .businessPolicyHolderData,
          ),
        ),
      },
      brokerPolicyData: {
        paymentInterval: this.isBrokerOffer
          ? this.$store.state.brokerOffer.brokerPolicyData.paymentInterval
          : 'YEARLY',
        mainExpiry: this.$store.state.brokerOffer.brokerPolicyData.mainExpiry,
      },
      noEmail: this.isBrokerOffer
        ? this.$store.state.brokerOffer.noEmail
        : false,
      maxLocations: 99,
      salutationOptions: [
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
        {
          text: this.$t('male'),
          id: 'MALE',
        },
      ],
      deliveryOptions: [
        {
          text: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
          id: 'EMAIL',
        },
      ],
      langOptions: [
        {
          text: this.$t('DE'),
          id: 'DE',
        },
        {
          text: this.$t('FR'),
          id: 'FR',
        },
        {
          text: this.$t('IT'),
          id: 'IT',
        },
        {
          text: this.$t('EN'),
          id: 'EN',
        },
      ],
      paymentOptionsBroker: [
        {
          text: this.$t('offer.data.paymentYear'),
          id: 'YEARLY',
        },
        {
          text: this.$t('offer.data.paymentHalf'),
          id: 'HALF_YEARLY',
        },
        {
          text: this.$t('offer.data.paymentQuarterly'),
          id: 'QUARTERLY',
        },
      ],
    }
  },
  computed: {
    storedOffer() {
      return this.isBrokerOffer
        ? this.$store.state.brokerOffer
        : this.$store.state.offer
    },
    activeTariff() {
      return this.storedOffer.activeTariff
    },
    countryOptions() {
      return countryOptions(this.$t.bind(this))
    },
  },
  watch: {
    noEmail(newValue) {
      if (this.isBrokerOffer) {
        if (newValue) {
          this.formData.contactPerson.email = ''
          this.formData.contactPerson.emailConfirmation = ''
        }
        // Immediately save in store as this has impact on other forms as well
        // (document delivery)
        this.$store.commit('SET_BROKER_POLICY_HOLDER_NO_EMAIL', newValue)
      }
    },
  },
  methods: {
    addLocation() {
      this.formData.locations.push({
        street: '',
        number: '',
        zip: '',
        city: '',
      })
    },
    subLocation(index) {
      this.formData.locations.splice(index, 1)
    },
    async saveForm(noValidation) {
      this.errors.clear()
      const result = noValidation || (await this.$validator.validate())
      if (result) {
        const mutation = this.isBrokerOffer
          ? 'SET_BROKER_BUSINESS_POLICY_HOLDER_DATA'
          : 'SET_BUSINESS_POLICY_HOLDER_DATA'
        this.$store.commit(mutation, this.formData)
        if (this.isBrokerOffer) {
          this.$store.commit(
            'SET_BROKER_POLICY_EXTRA_DATA',
            this.brokerPolicyData,
          )
        }
      }
      return result
    },
    async validateToSaveBrokerOffer() {
      this.errors.clear()
      const minimalFields = [
        'companyName',
        'gender',
        'firstName',
        'lastName',
        'mainExpiry',
        'country',
        'locale',
      ]
      const result = await this.$validator.validateAll(minimalFields)
      if (result) {
        this.$store.commit(
          'SET_BROKER_BUSINESS_POLICY_HOLDER_DATA',
          this.formData,
        )
        this.$store.commit(
          'SET_BROKER_POLICY_EXTRA_DATA',
          this.brokerPolicyData,
        )
      }
      return result
    },
    resetForm() {
      this.formData = {
        ...JSON.parse(
          JSON.stringify(this.storedOffer.businessPolicyHolderData),
        ),
      }
    },
    change() {
      this.$emit('change')
    },
    fieldVisible(field) {
      return !this.hideOptionalFields || !!field
    },
  },
}
</script>
