import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate/dist/vee-validate.minimal'
import { Rules } from 'vee-validate'
import { dictionary } from '~/translations/customTranslations/validation_dictonary'

Validator.extend('required', Rules.required)
Validator.extend('alpha_num', Rules.alpha_num)
Validator.extend('email', Rules.email)
Validator.extend('digits', Rules.digits)
Validator.extend('min', Rules.min)
Validator.extend('max', Rules.max)
Validator.extend('min_value', Rules.min_value)
Validator.extend('max_value', Rules.max_value)
Validator.extend('decimal', Rules.decimal)
Validator.extend('numeric', Rules.numeric)
Validator.extend('date_format', Rules.date_format)
Validator.extend('date_between', Rules.date_between)
Validator.extend('before', Rules.before)
Validator.extend('after', Rules.after)
Validator.extend('in', Rules.included)

/* eslint-disable import/first */
import matchStringValidator from '~/javascripts/validations/rules/matchStringValidator'
import notMatchStringValidator from '~/javascripts/validations/rules/notMatchStringValidator'
import IbanValidator from '~/javascripts/validations/rules/IbanValidator'
import ZipValidator from '~/javascripts/validations/rules/ZipValidator'
import BicValidator from '~/javascripts/validations/rules/BicValidator'
import isEmailAssignedValidator from '~/javascripts/validations/rules/isEmailAssignedValidator'
import isTrueValidator from '~/javascripts/validations/rules/isTrueValidator'
import mainExpiryValidator from '~/javascripts/validations/rules/mainExpiryValidator'
import {
  inTheFuture,
  inTheFutureGermanFormat,
  inThePast,
  inThePastGermanFormat,
  isAdult,
  atMostOneYearInFuture,
  atMostOneWeekInPast,
} from '~/javascripts/validations/rules/dateValidator'

Validator.extend('match_strings', matchStringValidator)
Validator.extend('validate_IBAN', IbanValidator)
Validator.extend('validate_ZIP', ZipValidator)
Validator.extend('validate_BIC', BicValidator)
Validator.extend('not_match_strings', notMatchStringValidator)
Validator.extend('is_email_assigned', isEmailAssignedValidator)
Validator.extend('inTheFuture', inTheFuture)
Validator.extend('inTheFutureGermanFormat', inTheFutureGermanFormat)
Validator.extend('inThePast', inThePast)
Validator.extend('inThePastGermanFormat', inThePastGermanFormat)
Validator.extend('isAdult', isAdult)
Validator.extend('atMostOneYearInFuture', atMostOneYearInFuture)
Validator.extend('atMostOneWeekInPast', atMostOneWeekInPast)
Validator.extend('is_true', isTrueValidator)
Validator.extend('mainExpiry', mainExpiryValidator)

const config = {
  isSet: false,
  errorBagName: 'errors',
  fieldsBagName: 'fields',
  delay: 10,
  locale: 'de',
  dictionary: null,
  strict: true,
  enableAutoClasses: false,
  className: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'valid', // model is valid
    invalid: 'invalid', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty', // control has been interacted with
  },
}

Vue.use(VeeValidate, config)
Validator.localize(dictionary)
