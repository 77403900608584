export default {
  simpleFormat(messageText) {
    const varsimpleFormatRE1 = /\r\n?/g
    const varsimpleFormatRE2 = /\n\n+/g
    const varsimpleFormatRE3 = /([^\n]\n)(?=[^\n])/g

    let reformatText = messageText
    reformatText = reformatText.replace(varsimpleFormatRE1, '\n')
    reformatText = reformatText.replace(varsimpleFormatRE2, '</p>\n\n<p>')
    reformatText = reformatText.replace(varsimpleFormatRE3, '$1<br/>')
    return reformatText
  },
}
