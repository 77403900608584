<template>
  <div class="content-bg-color">
    <div class="container-fluid">
      <div class="mt-3 my-md-4">
        <nuxt-link :to="localePath('LawyerPortal')" class="arrow-left overview">
          {{ $t('back') }}
        </nuxt-link>
      </div>

      <div class="content-wrap-medium content-pad">
        <dx-damage-request
          v-if="$route.query.lawyerInvitationToken"
          :token="$route.query.lawyerInvitationToken"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DxDamageRequest from '~/domains/externalLawyer/components/Dx-DamageRequest'

export default {
  name: 'LawyerPortalNewDamageCase',
  components: {
    DxDamageRequest,
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/neuer-rechtsfall',
      fr: '/portail-avocat/nouveau-cas-juridique',
      it: '/portale-procuratori/nuove-perdite',
    },
  },
}
</script>
