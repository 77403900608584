<template>
  <div>
    <div v-if="editable">
      <div v-if="!loading">
        <dx-select
          v-for="deliveryMethodKey in deliveryMethodKeys"
          :key="deliveryMethodKey"
          :value="deliveryMethods[deliveryMethodKey]"
          :options="deliveryMethodsOptions[deliveryMethodKey]"
          :label="$t(`brokerData.deliveryMethods.${deliveryMethodKey}`)"
          class="mb-3"
          @input="
            (value) => (brokerData.deliveryMethods[deliveryMethodKey] = value)
          "
        />
      </div>
      <dx-loading-overlay v-if="loading" />
    </div>
    <div v-else>
      <ul
        v-for="deliveryMethodKey in deliveryMethodKeys"
        :key="deliveryMethodKey"
        class="neutral"
      >
        <li class="mb-3">
          <h5 class="mb-0">
            {{ $t(`brokerData.deliveryMethods.${deliveryMethodKey}`) }}:
          </h5>
          {{
            $t(
              `offer.data.documentDeliveryMethods.${deliveryMethods[deliveryMethodKey]}`,
            )
          }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DxSelect } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import updateBrokerMutation from '~/domains/partnerPortal/__gql__/mutations/updateBrokerMutation.gql'
import profileQuery from '~/domains/partnerPortal/__gql__/queries/profileBroker.gql'

import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'

export default {
  name: 'DxDeliveryMethodsForm',
  components: {
    DxSelect,
    DxLoadingOverlay,
  },
  mixins: [loadingMixin, validationErrorsMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    onGeneralErrors: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      deliveryMethodsOptions: {
        broker: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.DOWNLOAD'),
            value: 'DOWNLOAD',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
        person: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.BROKER'),
            value: 'BROKER',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
        brokerFirstContractInvoice: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.DOWNLOAD'),
            value: 'DOWNLOAD',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
        personFirstContractInvoice: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.BROKER'),
            value: 'BROKER',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
        brokerRenewalContractInvoice: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.DOWNLOAD'),
            value: 'DOWNLOAD',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
        personRenewalContractInvoice: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.BROKER'),
            value: 'BROKER',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
        brokerReminder: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.NO_DELIVERY'),
            value: 'NO_DELIVERY',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
        personReminder: [
          {
            label: this.$t('offer.data.documentDeliveryMethods.BROKER'),
            value: 'BROKER',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.EMAIL'),
            value: 'EMAIL',
          },
          {
            label: this.$t('offer.data.documentDeliveryMethods.POST'),
            value: 'POST',
          },
        ],
      },
      brokerData: {
        companyName: '',
        salutation: '',
        firstName: '',
        lastName: '',
        address: {
          street: '',
          number: '',
          zip: '',
          city: '',
        },
        contactDetails: {
          phone: '',
          mobile: '',
        },
        deliveryMethods: {
          broker: '',
          brokerFirstContractInvoice: '',
          brokerRenewalContractInvoice: '',
          brokerReminder: '',
          person: '',
          personFirstContractInvoice: '',
          personRenewalContractInvoice: '',
          personReminder: '',
        },
        bankAccount: {
          iban: '',
          bic: '',
          accountHolder: '',
        },
        contactPerson: {
          salutation: '',
          firstName: '',
          lastName: '',
        },
        locale: '',
        finmaId: '',
      },
      loading: true,
      editableFields: ['deliveryMethods'],
    }
  },
  computed: {
    deliveryMethodKeys() {
      const { deliveryMethods } = this.$store.state.application.currentBroker
      return Object.keys(deliveryMethods).filter((key) => key !== '__typename')
    },

    deliveryMethods() {
      return this.$store.state.application.currentBroker.deliveryMethods
    },
  },
  methods: {
    editDataCallback(personalData, bankAccount) {
      this.brokerData.companyName = personalData.companyName
      this.brokerData.firstName = personalData.firstName
      this.brokerData.lastName = personalData.lastName
      this.brokerData.address = { ...personalData.address }
      this.brokerData.contactDetails = { ...personalData.contactDetails }
      this.brokerData.finmaId = personalData.finmaId
      this.brokerData.bankAccount = { ...bankAccount }
      return this.updateBrokerMutationFunction()
    },
    cancelCallback() {
      this.loading = true
      return this.$apollo.queries.profile.refetch()
    },
    async updateBrokerMutationFunction() {
      let result = false
      const isValid = await this.$validator.validate()
      if (isValid) {
        const broker = {
          salutation: this.brokerData.salutation,
          companyName: this.brokerData.companyName,
          firstName: this.brokerData.firstName,
          lastName: this.brokerData.lastName,
          address: this.brokerData.address,
          contactDetails: this.brokerData.contactDetails,
          bankAccount: this.brokerData.bankAccount,
          locale: this.$i18n.locale.toUpperCase(),
          finmaId: this.brokerData.finmaId,
          deliveryMethods: this.brokerData.deliveryMethods,
        }
        if (this.brokerData.salutation === this.$config.salutations.company) {
          broker.contactPerson = {
            ...this.brokerData.contactPerson,
            locale: this.$i18n.locale.toUpperCase(),
          }
          delete broker.contactPerson.id
        }
        try {
          this.setGlobalLoading(true)
          const { data } = await this.$apollo.mutate({
            mutation: updateBrokerMutation,
            variables: {
              broker,
            },
          })
          if (data && data.updateBroker) {
            const { validationErrors, success } = data.updateBroker
            if (validationErrors && validationErrors.length) {
              const generalErrors = []
              validationErrors.forEach((err) => {
                if (err.field.indexOf('bankAccount.') === 0) {
                  this.$validator.errors.add(this.getServerValidationError(err))
                } else {
                  generalErrors.push(err)
                }
              })
              if (generalErrors.length) {
                this.onGeneralErrors(generalErrors)
              } else {
                scrollFirstErrorIntoView()
              }
            } else if (success) {
              this.finishUpdateMutation()
              result = true
            }
          }
        } catch (error) {
          this.$notification.error({
            messageKey: 'userData.addressMutationError',
          })
          result = false
        } finally {
          this.setGlobalLoading(false)
        }
      } else {
        scrollFirstErrorIntoView()
      }
      return result
    },
    async finishUpdateMutation() {
      const result = await this.$apollo.queries.profile.refetch()
      if (result) {
        this.setGlobalLoading(false)
        this.$notification.success({
          messageKey: 'userData.addressMutationSuccess',
        })
      }
    },
    migrateDataWithState(data) {
      const { broker, username } = data.profile
      const sanitizedData = JSON.parse(
        JSON.stringify(data, (k, v) => (k === '__typename' ? undefined : v)),
      )
      const newBrokerData = sanitizedData.profile.broker

      this.$store.commit('SET_CURRENT_BROKER', {
        currentBroker: { ...broker, username },
      })

      const keys = Object.keys(newBrokerData)
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        // in editmode only update fields that are not in scope of this form
        if (
          newBrokerData[key] &&
          newBrokerData[key] !== this.brokerData[key] &&
          (!this.editableFields.includes(key) || !this.editable)
        ) {
          this.brokerData[key] = newBrokerData[key]
        }
      }

      this.setGlobalLoading(false)
      this.loading = false
    },
    displayErrors(validationErrors) {
      validationErrors.forEach((err) => {
        this.$validator.errors.add(this.getServerValidationError(err))
      })
      scrollFirstErrorIntoView()
    },
  },
  apollo: {
    profile: {
      query: profileQuery,
      result({ data }) {
        this.migrateDataWithState(data)
      },
    },
  },
}
</script>
