<template>
  <div class="container-fluid modulaStyles">
    <div class="content-wrap-small content-pad">
      <div class="mt-3 mt-md-1">
        <span class="arrow-left overview" @click="historyBack()">
          {{ $t('backToLogin') }}
        </span>
      </div>
    </div>
    <div class="content-wrap-small content-pad">
      <div class="mb-4 mb-md-5">
        <h3 class="headline">
          {{ $t('partnerPortal.register.headline') }}
        </h3>
        <p class="subheadline">
          {{ $t('partnerPortal.register.sub') }}
        </p>
      </div>

      <form @submit.prevent>
        <dx-input
          v-model="formData.companyName"
          v-validate.disable="{ required: false }"
          :error="errors.first('companyName')"
          :label="$t('company')"
          inverted
          class="mb-4"
          data-vv-name="companyName"
          autocomplete="organization"
        />
        <dx-radio-buttons
          id="salutation"
          v-model="formData.salutation"
          v-validate.disable="'required'"
          :error="errors.first('salutation')"
          :options="salutationOptions"
          :label="$t('salutation')"
          name="salutation"
          data-vv-name="salutation"
          class="mb-4"
        />
        <dx-input
          v-model="formData.firstName"
          v-validate.disable="'required|min:2'"
          :error="errors.first('firstName')"
          :label="$t('firstName')"
          inverted
          data-vv-name="firstName"
          class="mb-4"
          autocomplete="given-name"
        />
        <dx-input
          v-model="formData.lastName"
          v-validate.disable="'required|min:2'"
          :error="errors.first('lastName')"
          :label="$t('lastName')"
          inverted
          data-vv-name="lastName"
          class="mb-4"
          autocomplete="family-name"
        />
        <dx-input
          v-model="formData.address.street"
          v-validate.disable="'required|min:2'"
          :error="errors.first('street', 'address')"
          :label="$t('street')"
          inverted
          class="mb-4"
          data-vv-name="street"
          autocomplete="address-line1"
          data-vv-scope="address"
        />
        <dx-input
          v-model="formData.address.number"
          v-validate.disable="'required'"
          :error="errors.first('number', 'address')"
          :label="$t('houseNumber')"
          inverted
          class="mb-4"
          data-vv-name="number"
          autocomplete="address-line2"
          data-vv-scope="address"
        />
        <dx-zip-to-city-input
          :zip-input="formData.address.zip"
          :city-input="formData.address.city"
          required
          :with-modula-style="true"
          class="mb-4"
          data-vv-scope="address"
          @update:city="(city) => (formData.address.city = city)"
          @update:zip="(zip) => (formData.address.zip = zip)"
        />
        <dx-input
          v-model="formData.finmaId"
          v-validate.disable="'required'"
          :error="errors.first('finmaId')"
          :label="$t('partnerPortal.finma')"
          inverted
          class="mb-4"
          data-vv-name="finmaId"
        />
        <dx-input
          v-model="formData.bankAccount.accountHolder"
          v-validate.disable="'required'"
          :error="errors.first('accountHolder', 'bankAccount')"
          :label="$t('accountOwner')"
          inverted
          class="mb-4"
          data-vv-name="accountHolder"
          data-vv-scope="bankAccount"
        />
        <dx-iban-bic-input
          :iban="formData.bankAccount.iban"
          :bic="formData.bankAccount.bic"
          :with-modula-style="true"
          css-class="mb-4"
          data-vv-scope="bankAccount"
          @update:iban="(iban) => (formData.bankAccount.iban = iban)"
          @update:bic="(bic) => (formData.bankAccount.bic = bic)"
        />
        <dx-input
          v-model="formData.contactDetails.phone"
          v-validate.disable="
            `${formData.contactDetails.mobile.trim() ? '' : 'required|min:4'}`
          "
          :error="errors.first('mobileOrPhoneNumber')"
          :label="$t('telephone')"
          inverted
          class="mb-4"
          data-vv-name="mobileOrPhoneNumber"
          autocomplete="tel"
        />
        <dx-input
          v-model="formData.contactDetails.mobile"
          v-validate.disable="
            `${formData.contactDetails.phone.trim() ? '' : 'required|min:4'}`
          "
          :error="errors.first('mobileOrPhoneNumber')"
          :label="$t('mobilePhone')"
          inverted
          class="mb-4"
          data-vv-name="mobileOrPhoneNumber"
          autocomplete="tel"
        />
        <dx-input
          v-model.trim="email"
          v-validate.disable="'required|email'"
          :label="$t('email')"
          inverted
          class="mb-4"
          autocomplete="email"
          :error="errors.first('email')"
          data-vv-name="email"
        />
        <dx-input
          v-model.trim="emailConfirmation"
          v-validate.disable="`required|match_strings:${email}`"
          :label="$t('emailRepeat')"
          inverted
          class="mb-4"
          autocomplete="email"
          :error="errors.first('emailConfirmation')"
          data-vv-name="emailConfirmation"
        />
        <p>{{ $t('partnerPortal.isSelfEmployed') }}</p>
        <dx-radio-buttons
          id="isSelfEmployed"
          v-model="formData.isSelfEmployed"
          v-validate.disable="'required'"
          :error="errors.first('isSelfEmployed')"
          :options="isSelfEmployedOptions"
          :label="$t('partnerPortal.isSelfEmployed')"
          name="isSelfEmployed"
          data-vv-name="isSelfEmployed"
          class="mb-4"
        />
        <dx-radio-check
          id="brokerAgreement"
          v-model="brokerAgreement"
          v-validate.disable="'required|is_true'"
          :label="
            $t('partnerPortal.register.agreement', { domain: offerDomain })
          "
          :error="errors.first('brokerAgreement')"
          inverted
          type="checkbox"
          name="brokerAgreement"
          class="mb-4"
          data-vv-name="brokerAgreement"
          @input="brokerAgreement = !brokerAgreement"
        />

        <div class="text-right">
          <dx-button :text="$t('register')" @click="registerBroker" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  DxButton,
  DxInput,
  DxRadioButtons,
  DxRadioCheck,
} from '@sumcumo/dextra-frontend-component-lib'
import DxIbanBicInput from '~/components/DX-IbanBicInput'
import DxZipToCityInput from '~/components/DX-ZipToCityInput'
import registerBrokerMutation from '~/domains/partnerPortal/__gql__/mutations/registerBrokerMutation.gql'
import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'PartnerRegistration',
  components: {
    DxInput,
    DxRadioButtons,
    DxRadioCheck,
    DxButton,
    DxZipToCityInput,
    DxIbanBicInput,
  },
  mixins: [loadingMixin, validationErrorsMixin],
  data() {
    return {
      formData: {
        companyName: '',
        salutation: null,
        firstName: '',
        lastName: '',
        address: {
          street: '',
          number: '',
          zip: '',
          city: '',
        },
        finmaId: '',
        bankAccount: {
          iban: '',
          bic: '',
          accountHolder: '',
        },
        contactDetails: {
          phone: '',
          mobile: '',
        },
        isSelfEmployed: '',
      },
      email: '',
      emailConfirmation: '',
      brokerAgreement: null,
      offerDomain: process.env.DEXTRA_OFFER_URL,
      salutationOptions: [
        {
          name: this.$t('male'),
          value: 'MALE',
        },
        {
          name: this.$t('female'),
          value: 'FEMALE',
        },
      ],
      isSelfEmployedOptions: [
        {
          name: this.$t('yes'),
          value: true,
        },
        {
          name: this.$t('no'),
          value: false,
        },
      ],
    }
  },
  methods: {
    historyBack() {
      window.history.back()
    },
    async registerBroker() {
      const isValid = await this.$validator.validate()
      if (!isValid) {
        scrollFirstErrorIntoView()
        return
      }
      // If companyName is given, sent personal data as contactPerson
      this.formData.companyName = this.formData.companyName.trim()
      const formData = { ...this.formData }
      if (formData.companyName.length) {
        formData.contactPerson = {
          salutation: this.formData.salutation,
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          locale: this.$i18n.locale.toUpperCase(),
        }
        formData.salutation = 'COMPANY'
        formData.firstName = ''
        formData.lastName = ''
      }

      const variables = {
        broker: {
          ...formData,
          locale: this.$i18n.locale.toUpperCase(),
          finmaId: this.formData.finmaId.trim().replace(/'/g, ''),
        },
        brokerAgreement: this.brokerAgreement,
        email: this.email,
        emailConfirmation: this.emailConfirmation,
      }
      try {
        this.setGlobalLoading(true)
        const { data } = await this.$apollo.mutate({
          mutation: registerBrokerMutation,
          variables,
        })
        if (data && data.registerBroker) {
          const { validationErrors, success } = data.registerBroker
          if (validationErrors && validationErrors.length) {
            validationErrors.forEach((err) => {
              this.$validator.errors.add(this.getServerValidationError(err))
            })
            scrollFirstErrorIntoView()
          } else if (success) {
            this.$router.push({
              path: this.localePath('PartnerPortal-Registration-ThankYou'),
            })
          }
          return
        }
        // OR NETWORK ERROR:
        this.$notification.error({ messageKey: 'notifications.error.message' })
      } catch (error) {
        console.error(error)
        this.$notification.error({ messageKey: 'notifications.error.message' })
      } finally {
        this.setGlobalLoading(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/styles/newModulaTypoStyles.scss';
</style>
