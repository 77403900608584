export const generalFieldsOfLaw = {
  employment: {
    termination: {
      name: 'employment_contract.termination',
    },
    job_reference_letter: {
      name: 'employment_contract.job_reference_letter',
    },
    wage_conflict: {
      name: 'employment_contract.wage_conflict',
    },
    illness_accident: {
      name: 'employment_contract.illness_accident',
    },
    overtime: {
      name: 'employment_contract.overtime',
    },
    other_matters: {
      name: 'employment_contract.other_matters',
    },
  },
  lease: {
    initial_rent: {
      name: 'lease_agreement.initial_rent',
    },
    rent_reduction: {
      name: 'lease_agreement.rent_reduction',
    },
    utility_cost: {
      name: 'lease_agreement.utility_cost',
    },
    extraordinary_termination_of_contract: {
      name: 'lease_agreement.extraordinary_termination_of_contract',
    },
    demand_for_payment: {
      name: 'lease_agreement.demand_for_payment',
    },
    defects_in_the_leased_property: {
      name: 'lease_agreement.defects_in_the_leased_property',
    },
    other_causes: {
      name: 'lease_agreement.other_causes',
    },
  },
  vehicle_or_traffic: {
    traffic_law_penal_procedure: {
      name: 'criminal_and_administrative_law.traffic_law_penal_procedure',
    },
    leasing: {
      name: 'contracts_relating_to_vehicles.leasing',
    },
    car_purchase_agreement: {
      name: 'contracts_relating_to_vehicles.car_purchase_agreement',
    },
    car_lease: {
      name: 'contracts_relating_to_vehicles.car_lease',
    },
    car_repair: {
      name: 'contracts_relating_to_vehicles.car_repair',
    },
    car_misc: {
      name: 'contracts_relating_to_vehicles.car_misc',
    },
    car_accident: {
      personal_damage: {
        name: 'private_insurance_law.liability_insurance',
      },
      property_damage: {
        name: 'private_insurance_law.liability_insurance',
      },
      insurance_issue: {
        name: 'private_insurance_law.liability_insurance',
      },
      pentalty_order: {
        name: 'criminal_and_administrative_law.traffic_law_penal_procedure',
      },
    },
  },
  other: {
    insurance_right: {
      name: 'private_insurance_law.liability_insurance',
    },
    neighbourhood_right: {
      name: 'neighbourhood_right.space_heights',
    },
    contract_right: {
      name: 'other_contracts.sales_contract',
    },
    other_right: {
      name: 'other_contracts.other_agreements',
    },
  },
}

export const cyberFieldsOfLaw = {
  cyber: {
    cyber_extortion: {
      name: 'cyber.cyber_extortion',
    },
    simple_data_theft: {
      name: 'cyber.simple_data_theft',
    },
    data_theft_with_loss_of_money: {
      name: 'cyber.data_theft_with_loss_of_money',
    },
    cybermobbing_of_unauthorised_content: {
      name: 'cyber.cybermobbing_of_unauthorised_content',
    },
    legal_case_only: {
      name: 'cyber.legal_case_only',
    },
    management_of_data_after_death: {
      name: 'cyber.management_of_data_after_death',
    },
  },
}
