<template>
  <div class="container-fluid">
    <div class="mt-3 my-md-4">
      <dx-back-text-button
        :text="$t('damageBacklog.overview')"
        :route="localePath('SelfService')"
        class="overview"
      />
    </div>
    <div class="content-wrap-medium content-pad">
      <h3 class="mb-5">
        {{ $t('userData.userDataHeading') }}
      </h3>

      <div
        class="container-fluid content-wrap-medium position-relative content-bg-color notification-settings-bar"
      >
        {{ $t('userData.noPaper') }}
        <div v-if="noPaperStatusLoading" class="loading-box">
          <dx-loading-overlay :small-loader="true" />
        </div>
        <dx-toggle-switch
          v-else
          ref="toggleSwitch"
          class="toggle-switch"
          :checked="noPaper"
          @input="toggleNoPaper"
        />
      </div>

      <dx-change-login-details />

      <div class="row user-data-block last-item">
        <div class="col">
          <dx-edit-data-toggle
            :headline="$t('userData.personalData')"
            tag-type="h4"
            :callback="savePersonalData"
            :cancel-callback="cancelPersonalData"
            :show="$store.getters.isDexPolicyholder"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <dx-personal-data-form
                ref="personalDataForm"
                :editable="editMode"
              />
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>

      <!-- TODO: Bank Details for Customer not yet available -->
      <div v-if="false" class="row user-data-block">
        <div class="col">
          <dx-edit-data-toggle
            :headline="$t('userData.paymentData')"
            tag-type="h4"
            :callback="() => {}"
          >
            <template slot="data-dynamic" slot-scope="{ editMode }">
              <dx-bank-details-form
                ref="bankDetailsForm"
                :editable="editMode"
              />
            </template>
          </dx-edit-data-toggle>
        </div>
      </div>
    </div>
    <dx-popup-modal ref="confirmationModal" @close="modalClosed">
      <h3>{{ $t('userData.noPaper') }}</h3>
      <p>
        {{ $t('userData.noPaperWarning') }}
      </p>
      <div
        class="d-flex flex-wrap-reverse justify-content-center d-sm-block text-right"
      >
        <span
          class="abort-btn mt-2 mr-3 ml-3 mt-sm-0 secondary-link btn-blank"
          @click="closeConfirmationModal"
        >
          {{ $t('abort') }}
        </span>

        <dx-button
          type="button"
          class="d-inline"
          :text="$t('agree')"
          small
          @click="confirmDeactivateNoPaper"
        />
      </div>
    </dx-popup-modal>
    <dx-popup-modal ref="addressconfirmationModal">
      <h3>{{ $t('userData.addressChange') }}</h3>
      <p>
        {{ $t('userData.customerAddressChangeWarning') }}
      </p>
      <div
        class="d-flex flex-wrap-reverse justify-content-center d-sm-block text-right"
      >
        <span
          class="abort-btn mt-2 mr-3 ml-3 mt-sm-0 secondary-link btn-blank"
          @click="closeAddressConfirmationModal"
        >
          {{ $t('abort') }}
        </span>

        <dx-button
          type="button"
          class="d-inline"
          :text="$t('userData.addressChangeConfirmation')"
          small
          @click="confirmAddressChange"
        />
      </div>
    </dx-popup-modal>
  </div>
</template>

<script>
import {
  DxBackTextButton,
  DxButton,
  DxToggleSwitch,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxEditDataToggle from '~/components/DX-EditDataToggle'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxChangeLoginDetails from '~/domains/authentication/components/DX-ChangeLoginDetails'
import DxPersonalDataForm from '~/domains/user/components/DX-PersonalDataForm'
import DxBankDetailsForm from '~/domains/user/components/DX-BankDetailsForm'
import changeNoPaperMutation from './__gql__/mutations/changeNoPaper.gql'

export default {
  name: 'DxUserPersonalData',
  components: {
    DxEditDataToggle,
    DxChangeLoginDetails,
    DxPersonalDataForm,
    DxBankDetailsForm,
    DxBackTextButton,
    DxToggleSwitch,
    DxPopupModal,
    DxButton,
    DxLoadingOverlay,
  },
  data() {
    return {
      noPaperStatusLoading: false,
    }
  },
  computed: {
    noPaper() {
      return this.$store.state.application.currentUser.noPaper
    },
  },
  methods: {
    cancelPersonalData() {
      return this.$refs.personalDataForm.cancelCallback()
    },
    toggleNoPaper() {
      if (this.noPaper) {
        this.openConfirmationModal()
      } else {
        this.changeNoPaper(true)
      }
    },
    openConfirmationModal() {
      this.noPaperStatusLoading = true
      this.$refs.confirmationModal.open()
    },
    closeConfirmationModal() {
      this.$refs.confirmationModal.close()
      this.noPaperStatusLoading = false
    },
    confirmDeactivateNoPaper() {
      this.$refs.confirmationModal.close()
      this.changeNoPaper(false)
    },
    savePersonalData() {
      this.$refs.addressconfirmationModal.open()
    },
    closeAddressConfirmationModal() {
      this.$refs.addressconfirmationModal.close()
    },
    confirmAddressChange() {
      return (
        this.$refs.addressconfirmationModal.close(),
        this.$refs.personalDataForm.editAddressCallback()
      )
    },
    modalClosed() {
      this.noPaperStatusLoading = false
    },
    async changeNoPaper(noPaper) {
      try {
        this.noPaperStatusLoading = true
        const { data } = await this.$apollo.mutate({
          mutation: changeNoPaperMutation,
          variables: {
            noPaper,
          },
        })
        if (data && data.changeNoPaper) {
          const updatedUser = {
            ...this.$store.state.application.currentUser,
            noPaper: data.changeNoPaper.noPaper,
          }
          this.$store.commit('SET_CURRENT_USER', { currentUser: updatedUser })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.noPaperStatusLoading = false
      }
    },
  },
  nuxtI18n: {
    paths: {
      de: '/kundencenter/benutzerdaten',
      fr: '/centre-clients/donnees-utilisateur',
      it: '/centro-clienti/informazioni-utente',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
