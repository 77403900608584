const nameToKeysMapping = {
  // Modula 1 Private
  modulaOnePrivateL: {
    displayName: 'Private L (2021)',
    productKey: 'private',
    variantKey: 'dex_l',
    targetableProductVariants: [
      'modulaOnePrivateXL',
      'modulaOnePrivateFlex',
      'modulaTwoPrivateL',
      'modulaTwoPrivateXL',
      'modulaTwoPrivateFlex',
    ],
  },
  modulaOnePrivateXL: {
    displayName: 'Private XL (2021)',
    productKey: 'private',
    variantKey: 'dex_xl',
    targetableProductVariants: [
      'modulaOnePrivateL',
      'modulaOnePrivateFlex',
      'modulaTwoPrivateL',
      'modulaTwoPrivateXL',
      'modulaTwoPrivateFlex',
    ],
  },
  modulaOnePrivateFlex: {
    displayName: 'Private Flex (2021)',
    productKey: 'private',
    variantKey: 'flex_dex',
    targetableProductVariants: [
      'modulaOnePrivateL',
      'modulaOnePrivateXL',
      'modulaTwoPrivateL',
      'modulaTwoPrivateXL',
      'modulaTwoPrivateFlex',
    ],
  },
  // Modula 1 business
  modulaOneBusinessL: {
    displayName: 'Business L (2021)',
    productKey: 'business',
    variantKey: 'business_l',
    targetableProductVariants: [
      'modulaOneBusinessXL',
      'modulaOneBusinessFlex',
      'modulaTwoBusinessL',
      'modulaTwoBusinessXL',
      'modulaTwoBusinessFlex',
    ],
  },
  modulaOneBusinessXL: {
    displayName: 'Business XL (2021)',
    productKey: 'business',
    variantKey: 'business_xl',
    targetableProductVariants: [
      'modulaOneBusinessL',
      'modulaOneBusinessFlex',
      'modulaTwoBusinessL',
      'modulaTwoBusinessXL',
      'modulaTwoBusinessFlex',
    ],
  },
  modulaOneBusinessFlex: {
    displayName: 'Business Flex (2021)',
    productKey: 'business',
    variantKey: 'flex_business',
    targetableProductVariants: [
      'modulaOneBusinessL',
      'modulaOneBusinessXL',
      'modulaTwoBusinessL',
      'modulaTwoBusinessXL',
      'modulaTwoBusinessFlex',
    ],
  },
  // Modula 2 Private
  modulaTwoPrivateL: {
    displayName: 'Paket L (2022)',
    productKey: 'private_complete',
    variantKey: 'paket_l',
    targetableProductVariants: ['modulaTwoPrivateXL', 'modulaTwoPrivateFlex'],
  },
  modulaTwoPrivateXL: {
    displayName: 'Paket XL (2022)',
    productKey: 'private_complete',
    variantKey: 'paket_xl',
    targetableProductVariants: ['modulaTwoPrivateL', 'modulaTwoPrivateFlex'],
  },
  modulaTwoPrivateFlex: {
    displayName: 'Flex (2022)',
    productKey: 'private_flex',
    variantKey: 'flex',
    targetableProductVariants: ['modulaTwoPrivateL', 'modulaTwoPrivateXL'],
  },
  // Modula 2 Business
  modulaTwoBusinessL: {
    displayName: 'Paket L (2022)',
    productKey: 'business_complete',
    variantKey: 'paket_l',
    targetableProductVariants: ['modulaTwoBusinessXL', 'modulaTwoBusinessFlex'],
  },
  modulaTwoBusinessXL: {
    displayName: 'Paket XL (2022)',
    productKey: 'business_complete',
    variantKey: 'paket_xl',
    targetableProductVariants: ['modulaTwoBusinessL', 'modulaTwoBusinessFlex'],
  },
  modulaTwoBusinessFlex: {
    displayName: 'Flex (2022)',
    productKey: 'business_flex',
    variantKey: 'flex',
    targetableProductVariants: ['modulaTwoBusinessL', 'modulaTwoBusinessXL'],
  },
  modulaTwoCommunity: {
    displayName: 'Gemeinde',
    productKey: 'gemeinde',
    variantKey: 'paket_gemeinden',
    targetableProductVariants: [],
  },
  modulaTwoHotelleriesuisse: {
    displayName: 'HotellerieSuisse',
    productKey: 'hotelleriesuisse',
    variantKey: 'hotelleriesuisse',
    targetableProductVariants: [],
  },
  modulaTwoMedicaPrivate: {
    displayName: 'Medica Private',
    productKey: 'medica_private',
    variantKey: 'medica_private',
    targetableProductVariants: [],
  },
  modulaTwoMedicaBusiness: {
    displayName: 'Medica Business',
    productKey: 'medica_business',
    variantKey: 'medica_business',
    targetableProductVariants: [],
  },
  modulaTwoViac: {
    displayName: 'VIAC',
    productKey: 'viac',
    variantKey: 'viac',
    targetableProductVariants: [],
  },
  modulaTwoVz: {
    displayName: 'VZ',
    productKey: 'vz',
    variantKey: 'vz',
    targetableProductVariants: [],
  },
  modulaTwoQb: {
    displayName: 'QB-Rechtsschutz',
    productKey: 'qb',
    variantKey: 'qb',
    targetableProductVariants: [],
  },
  modulaTwoLbv: {
    displayName: 'LBV',
    productKey: 'lbv',
    variantKey: 'lbv',
    targetableProductVariants: [],
  },
}

export default {
  methods: {
    getProductKeyAndVariantKeyByName(name) {
      return nameToKeysMapping[name]
    },
    getNameByProductKeyAndVariantKey(productKey, variantKey) {
      return Object.keys(nameToKeysMapping).find(
        (key) =>
          nameToKeysMapping[key].productKey === productKey &&
          nameToKeysMapping[key].variantKey === variantKey,
      )
    },
    getTargetableProductVariantsByName(name) {
      return nameToKeysMapping[name].targetableProductVariants
    },
  },
}
