<template>
  <div class="container-fluid">
    <dx-external-lawyer-registration
      :invitation-token="$route.query.invitationToken"
    />
  </div>
</template>

<script>
import DxExternalLawyerRegistration from '~/domains/externalLawyer/components/DX-ExternalLawyerRegistration'

export default {
  name: 'LawyerPortalRegistration',
  meta: {
    isPublic: true,
  },
  components: {
    DxExternalLawyerRegistration,
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/registrierung',
      fr: '/portail-avocat/inscription',
      it: '/portale-procuratori/registrazione',
    },
  },
}
</script>
