<template>
  <svg
    :height="height"
    viewBox="0 0 24 24"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="m0 0h24v24h-24z" />
      <path
        d="m12 .00000136c5.5228444 0 10 4.47715498 10 10.00000004 0 3.3716032-1.8024016 6.6760061-4.7740473 9.8127432-1.0189506 1.075559-2.1076997 2.0554332-3.1967759 2.9226605-.3817782.3040086-.7370031.5727044-1.0569675.8037898-.1949853.1408227-.3370639.2392253-.4175091.2928554-.3358994.2239329-.773501.2239329-1.1094004 0-.0804452-.0536301-.2225238-.1520327-.4175091-.2928554-.3199644-.2310854-.6751893-.4997812-1.05696748-.8037898-1.08907624-.8672273-2.17782532-1.8471015-3.1967759-2.9226605-2.97164575-3.1367371-4.77404732-6.44114-4.77404732-9.8127432 0-5.52284472 4.47715521-10.00000004 10-10.00000004zm0 2c-4.41827531 0-8 3.58172478-8 8.00000004 0 2.753394 1.57259843 5.6364911 4.22595268 8.437254.94979942 1.002566 1.97042532 1.9211293 2.99072412 2.7335895.2784943.2217639.5410117.4227654.7833232.6017719.2423115-.1790065.5048289-.380008.7833232-.6017719 1.0202988-.8124602 2.0409247-1.7310235 2.9907241-2.7335895 2.6533543-2.8007629 4.2259527-5.68386 4.2259527-8.437254 0-4.41827554-3.581725-8.00000004-8-8.00000004zm2.8284068 5.17157186c1.5620943 1.56209429 1.5620943 4.09475928 0 5.65685358s-4.0947593 1.5620943-5.65685358 0c-1.56209429-1.5620943-1.56209429-4.09475929 0-5.65685358 1.56209428-1.56209429 4.09475928-1.56209429 5.65685358 0zm-4.24264 1.41421356c-.78104573.78104571-.78104573 2.04738072 0 2.82842642.7810457.7810457 2.0473807.7810457 2.8284264 0s.7810457-2.04738071 0-2.82842642-2.0473807-.78104571-2.8284264 0z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MapPinIcon',

  props: {
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
  },
}
</script>
