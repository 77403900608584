<template>
  <div
    v-if="!disabled"
    :class="{ 'global-loader': globalLoader, [`theme-${theme}`]: isTheme }"
    class="loading-wrap d-flex justify-content-center"
  >
    <div
      :class="[smallLoader ? 'small-size' : 'default-size']"
      class="loading-indicator-wrap"
    >
      <div class="loading-indicator" />
    </div>
  </div>
</template>

<script>
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'DxLoadingOverlay',
  mixins: [loadingMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    globalLoader: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    smallLoader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isTheme() {
      return this.theme.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
