<script>
export default {
  methods: {
    formatPrice(offer) {
      if (offer.hasOpenUnderwriting) {
        return this.$t('partnerPortal.offerPriceUnderwriting')
      }
      if (!offer.priceBrutto) {
        return ''
      }
      const bruttoValue = parseFloat(offer.priceBrutto.value).toFixed(2)
      return `${offer.priceBrutto.currency} ${bruttoValue}`
    },
    formatPriceOrStatus(contract) {
      if (contract.status === 'ACTIVE' || contract.hasOpenUnderwriting) {
        return this.formatPrice(contract)
      }
      return this.$t(`policies.status.${contract.status}`)
    },
  },
}
</script>
