<template>
  <div
    class="container-fluid login-wrapper modulaStyles"
    :class="{ 'd-lg-flex justify-content-lg-between': isLegalCase }"
  >
    <div
      class="px-lg-4 login-form content-pad"
      :class="{ 'content-wrap-x-small content-pad': !isLegalCase }"
    >
      <div class="mb-4 mb-md-5">
        <h2 class="headline">
          {{
            isLegalCase
              ? $t('loginPage.legalCase.headline')
              : $t('loginPage.headline.general')
          }}
        </h2>
        <div v-if="isLegalCase">
          <p class="subheadline">
            {{ $t('loginPage.legalCase.subHeadline') }}
          </p>
          <p class="subheadline">
            {{ $t('loginPage.legalCase.intro') }}
            <strong class="strong">
              <nuxt-link :to="localePath('SelfService-OfflineRegistration')">
                {{ $t('loginPage.legalCase.register') }}
              </nuxt-link>
            </strong>
          </p>
        </div>
        <p v-else class="subheadline">
          {{ $t('loginPage.headline.subHeadline') }}
          <br />
          {{ $t('loginPage.subHeadline') }}
        </p>
      </div>
      <dx-login
        :invitation-token="$route.query.invitationToken"
        :lawyer-invitation-token="$route.query.lawyerInvitationToken"
        :remote-login-token="$route.query.remoteLoginToken"
        :is-legal-case="isLegalCase"
        class="pb-5"
      />
    </div>
    <div v-if="isLegalCase" class="sidebarItems">
      <text-icon-teaser
        v-if="isLegalCase"
        class="mutuel-logo"
        :headline="$t('loginPage.legalCase.groupeMutuel.headline')"
        :copy="$t('loginPage.legalCase.groupeMutuel.description')"
        icon="https://media.graphassets.com/2yk4QpeQdmdj7GAOtvrw"
        :linktext="$t('loginPage.legalCase.groupeMutuel.buttonText')"
        :external-link="$t('loginPage.legalCase.groupeMutuel.buttonLink')"
      />
      <text-icon-teaser
        v-if="isLegalCase"
        :headline="$t('loginPage.legalCase.car.headline')"
        :copy="$t('loginPage.legalCase.car.description')"
        icon="https://media.graphcms.com/NXDtCWWdRJiaHh9z539M"
        :linktext="$t('loginPage.legalCase.car.buttonText')"
        :external-link="$t('loginPage.legalCase.car.buttonLink')"
      />
    </div>
  </div>
</template>

<script>
import { TextIconTeaser } from '@sumcumo/dextra-frontend-component-lib'
import DxLogin from '~/domains/authentication/components/DX-Login'
import loginFakerMixin from '~/javascripts/mixins/loginFakerMixin'
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'
import { RESET_STORE } from '~/domains/application/__store__/actions'

export default {
  name: 'SelfServiceLogin',
  components: {
    DxLogin,
    TextIconTeaser,
  },
  mixins: [loginFakerMixin, apolloErrorsMixin],
  layout: 'modulaStylePlain',
  data() {
    return {
      env: process.env.DEXTRA_ENV,
    }
  },
  head() {
    const links = [
      {
        rel: 'canonical',
        // eslint-disable-next-line prettier/prettier
        href: `${process.env.DEXTRA_SERVICE_URL}${this.$route.path}`.replace(
          /\/$/,
          '',
        ),
      },
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${process.env.DEXTRA_SERVICE_URL}/login`,
      },
      {
        rel: 'alternate',
        hreflang: 'fr',
        href: `${process.env.DEXTRA_SERVICE_URL}/fr/connexion`,
      },
      {
        rel: 'alternate',
        hreflang: 'it',
        href: `${process.env.DEXTRA_SERVICE_URL}/it/accesso`,
      },
    ]
    return {
      link: links,
    }
  },
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/login',
      fr: '/connexion',
      it: '/accesso',
    },
  },
  computed: {
    isLegalCase() {
      return this.$route.query?.entry === 'rechtsfall'
    },
  },
  mounted() {
    const { logout, reason } = this.$route.query

    if (logout) {
      this.$store.dispatch(RESET_STORE)
      this.$apolloProvider.defaultClient.resetStore()
      switch (reason) {
        case 'sessionExpired':
          this.$notification.error({
            messageKey: 'loginPage.errors.sessionExpired',
          })
          break
        case 'loginError':
          this.$notification.error({ messageKey: 'loginPage.error' })
          break
        default:
          break
      }
    }

    this.setupLoginFaker()
    if (this.$route.query.token) {
      this.setToken(this.$route.query.token)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/styles/newModulaTypoStyles.scss';

.login-wrapper {
  @include media-breakpoint-up(xl) {
    margin: 0 auto;
    max-width: rem(1410);
  }
}

.login-form {
  max-width: rem(720);
}

.sidebarItems {
  float: right;
  margin-right: $layout-base-gutter * -1;
  min-width: rem(310);
  max-width: rem(310);
  color: $black-clr;

  @include media-breakpoint-up(lg) {
    margin-left: rem(30);
    min-width: rem(360);
    max-width: rem(360);
  }
}

.strong {
  > a {
    color: $white-clr;
  }
}

.mutuel-logo {
  ::v-deep {
    img {
      padding-top: rem(8);
    }
  }
}
</style>
