<template>
  <dx-facts-form
    next-step-path="SelfService-ReportDamage-DocumentUpload"
    previous-step-path="SelfService-ReportDamage-MyInfo"
  />
</template>

<script>
import DxFactsForm from '~/components/reportDamage/DX-FactsForm'

export default {
  name: 'ReportDamageFacts',
  components: {
    DxFactsForm,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/kundencenter/rechtsfall-melden/sachverhalt',
      fr: '/centre-clients/cas-juridique-rapport/realites',
      it: '/cliente/segnalare-caso-giuridico/fatti',
    },
  },
}
</script>
