<template>
  <div class="container-fluid content-wrap-medium content-pad">
    <div class="row">
      <div class="col">
        <h3>
          {{ $t('lawyerPortal.mandateHeadline') }}
        </h3>
        <p class="mb-0">
          {{ claim.insuredPerson.fullName }}
        </p>

        <div class="row pt-5">
          <div class="col-12 col-md-6">
            <dx-select
              v-model="selectedReason"
              v-validate.disable="'required'"
              :label="$t('lawyerPortal.mandateCancelationQuestion')"
              :error="errors.first('requiredSelection')"
              :options="rejectionReasonOptions()"
              class="mb-4"
              data-vv-name="requiredSelection"
            />
          </div>
        </div>

        <dx-textarea
          v-if="selectedReason === 'OTHER'"
          v-model="comment"
          v-validate.disable="'required'"
          :error="errors.first('missingMessageBody')"
          :label="$t('lawyerPortal.mandateRemarks')"
          class="mt-5"
          data-vv-name="missingMessageBody"
        />

        <div class="row">
          <div class="col text-center text-md-right">
            <dx-button
              :text="$t('abort')"
              class="d-md-inline-block my-4 mr-md-4"
              @click="$router.go(-1)"
            />
            <dx-button
              :text="$t('lawyerPortal.mandateReject')"
              class="d-md-inline-block"
              @click="reject"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxButton,
  DxTextarea,
  DxSelect,
} from '@sumcumo/dextra-frontend-component-lib-old'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import personMixin from '~/javascripts/mixins/personMixin'
import rejectInvitationMutation from '~/domains/externalLawyer/__gql__/mutations/rejectInvitationExternalLawyer.gql'

import { INVITATION_REJECTION_REASON } from '~/domains/externalLawyer/constants'

export default {
  name: 'LawyerPortalRejectDamage',
  components: {
    DxSelect,
    DxButton,
    DxTextarea,
  },
  mixins: [loadingMixin, personMixin],
  data() {
    const { invitationId, claim } = this.$route.params
    return {
      invitationId,
      claim,
      selectedReason: null,
      comment: '',
    }
  },
  methods: {
    rejectionReasonOptions() {
      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
        caption: true,
      }
      const reasons = INVITATION_REJECTION_REASON.map((reason) => ({
        value: reason,
        label: this.$t(`lawyerPortal.invitationRejectionReason.${reason}`),
      }))
      return [defaultOption, ...reasons]
    },
    async reject() {
      await this.$validator.reset()
      const valid = await this.$validator.validateAll()
      if (valid) {
        const variables = {
          invitationId: Number(this.invitationId),
          reason: this.selectedReason,
          comment: this.comment,
        }
        try {
          this.setGlobalLoading(true)
          await this.$apollo.mutate({
            mutation: rejectInvitationMutation,
            variables,
          })
          this.$notification.success({
            messageKey: 'lawyerPortal.rejectInvitation.success',
          })
          this.$router.push({ path: this.localePath('LawyerPortal') })
        } catch (error) {
          this.$notification.error({
            messageKey: 'lawyerPortal.rejectInvitation.error',
          })
        } finally {
          this.setGlobalLoading(false)
        }
      }
    },
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/mandat-ablehnen',
      fr: '/portail-avocat/refuser-un-mandat',
      it: '/portale-procuratori/rifiutare-un-mandato',
    },
  },
}
</script>
