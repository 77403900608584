<template>
  <dx-accordion
    class="mb-2"
    :on-open="loadDexOfferDetails"
    :is-init-open="initiallyOpen || !!dexOfferDetails"
    allow-text-select
  >
    <template slot="headline">
      <div class="row headline">
        <div class="col-12 col-md-3">
          {{ offer.policyholder.fullName }}
        </div>
        <div class="col-12 col-md-3">
          {{ offer.number }}
        </div>
        <span id="scip-sales-id" class="visually-hidden">
          {{ offer.id }}
        </span>
        <div class="col-12 col-md-2">
          {{ productName(offer) }}
        </div>
        <div class="col-12 col-md-3">
          {{ formatPrice(offer) }}
        </div>
        <div class="col-12 col-md-1">
          <div
            :class="offer.policyholder.hasEbill ? 'icon-check' : 'icon-cross'"
          />
        </div>
      </div>
    </template>

    <template slot="content">
      <dx-loading-overlay
        v-if="forceReload || (isLoading && !dexOfferDetails)"
      />
      <div v-if="!forceReload && dexOfferDetails">
        <div class="row">
          <div
            v-if="dexOfferDetails.editable"
            class="col mt-4 text-right edit-icons"
          >
            <button
              class="mr-2 d-inline-block btn-blank icon icon-edit"
              @click="loadFullOfferAndEdit()"
            >
              <span class="visually-hidden">
                {{ $t('updateRecords') }}
              </span>
            </button>

            <button
              class="d-inline-block btn-blank icon icon-delete"
              @click="openRejectionOverlay"
            >
              <span class="visually-hidden">
                {{ $t('partnerPortal.offerReject') }}
              </span>
            </button>
          </div>

          <dx-popup-modal ref="rejectionOverlay">
            <h3>{{ $t('partnerPortal.offerReject') }}</h3>
            <p>
              {{ $t('partnerPortal.offerRejectModalText') }}
              <dx-select
                v-model="rejectionReason"
                v-validate.disable="'required'"
                :options="offerRejectOptions"
                class="mt-2 mb-4"
                :error="errors.first('rejectionReason')"
                data-vv-name="rejectionReason"
              />
            </p>
            <div
              class="d-flex flex-wrap-reverse justify-content-center d-sm-block text-right"
            >
              <span
                class="abort-btn mt-2 mt-sm-0 secondary-link btn-blank"
                @click="closeRejectionOverlay"
              >
                {{ $t('abort') }}
              </span>

              <dx-button
                type="button"
                class="d-inline ml-sm-3"
                :text="$t('partnerPortal.offerReject')"
                small
                @click="confirmOfferRejection"
              />
            </div>
          </dx-popup-modal>
        </div>

        <dx-popup-modal v-if="isScipSales" ref="changeTariff">
          <h3>{{ $t('offer.changeTariff') }}</h3>

          <div class="row">
            <div
              v-for="productVariantName in possibleProductVariantSelection"
              :key="productVariantName"
              class="`col-12 change-tariff-modal-tariffs-entry"
              :class="{
                'col-lg-4': possibleProductVariantSelection.length > 2,
                'col-lg-6': possibleProductVariantSelection.length === 2,
                'col-lg-12': possibleProductVariantSelection.length === 1,
              }"
            >
              <h5>
                {{
                  getProductKeyAndVariantKeyByName(productVariantName)
                    .displayName
                }}
              </h5>
              <dx-button
                :small="true"
                :text="$t('offer.changeTariff')"
                @click="handleChangeProductVariant(productVariantName)"
              />
            </div>
          </div>
        </dx-popup-modal>

        <h5>{{ $t('partnerPortal.offerDetails') }}</h5>

        <div class="row">
          <div class="col-12 col-md-7">
            <ul class="neutral">
              <li>
                {{ $t('partnerPortal.offerCalc') }}:
                {{ getFormattedDate(dexOfferDetails.calculatedAt) }}
              </li>
              <li>
                {{ $t('partnerPortal.plannedInsuranceStart') }}:
                {{ getFormattedDate(dexOfferDetails.startOfInsurance) }}
              </li>
              <li>
                {{ $t('partnerPortal.product') }}:
                {{ productName(dexOfferDetails) }}
              </li>
              <li
                v-if="
                  !dexOfferDetails.hasOpenUnderwriting ||
                  dexOfferDetails.documents.length
                "
                class="mt-4"
              >
                <dx-download-list
                  :items="dexOfferDetails.documents"
                  :items-inline="true"
                  tag-type="h4"
                />
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-5">
            {{ $t('partnerPortal.grossPremium') }}:
            {{ formatPrice(dexOfferDetails) }}
          </div>
        </div>

        <div class="row flex-row-reverse mt-3">
          <div class="col">
            <div
              v-if="isScipSales && dexOfferDetails.acceptable"
              class="text-right mb-3"
            >
              <button
                type="button"
                class="abort-btn secondary-link btn-blank"
                @click="openChangeProductVariantOverlay"
              >
                {{ $t('offer.changeTariff') }}
              </button>
            </div>
            <dx-button
              v-if="dexOfferDetails.acceptable"
              :small="true"
              :text="$t('partnerPortal.acceptOffer')"
              class="text-right mb-3"
              @click="loadFullOfferAndEdit()"
            />
            <dx-button
              v-if="dexOfferDetails.reactivatable"
              :small="true"
              :text="$t('partnerPortal.reactivateOffer')"
              class="text-right mb-3"
              @click="reactivate"
            />

            <div
              v-if="dexOfferDetails.hasOpenUnderwriting"
              class="icon-underwriting mb-3"
            >
              {{ $t('partnerPortal.offerAtUnderwriting') }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </dx-accordion>
</template>

<script>
import {
  DxButton,
  DxAccordion,
  DxSelect,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib-old'
import { scrollIntoView } from '~/javascripts/helper/scollIntoView'
import DxDownloadList from '~/components/DX-DownloadList'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import formatOfferPriceMixin from '~/javascripts/mixins/formatOfferPriceMixin'
import dateMixin from '~/javascripts/mixins/dateMixin'

import TargetableProductVariantsMixin from './mapping/TargetableProductVariantsMixin'

export default {
  name: 'OfferListItem',
  components: {
    DxAccordion,
    DxButton,
    DxDownloadList,
    DxLoadingOverlay,
    DxPopupModal,
    DxSelect,
  },
  mixins: [dateMixin, formatOfferPriceMixin, TargetableProductVariantsMixin],
  props: {
    offer: {
      type: Object,
      default() {
        return {}
      },
    },
    loadOffer: {
      type: Function,
      default: () => null,
    },
    rejectOffer: {
      type: Function,
      default: () => null,
    },
    reactivateOffer: {
      type: Function,
      default: () => null,
    },
    initiallyOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dexOfferDetails: null,
      rejectionReason: null,
      isLoading: false,
      forceReload: false,
      allPossibleTariffs: null,
    }
  },
  computed: {
    isScipSales() {
      return this.dexOfferDetails.productCode === 'ssc2020'
    },
    possibleProductVariantSelection() {
      if (
        !this.dexOfferDetails.productVariantKey ||
        !this.dexOfferDetails.productKey
      )
        return []
      const name = this.getNameByProductKeyAndVariantKey(
        this.dexOfferDetails.productKey,
        this.dexOfferDetails.productVariantKey,
      )
      return this.getTargetableProductVariantsByName(name)
    },
    offerRejectOptions() {
      const defaultOption = {
        value: '',
        label: this.$t('selectOption'),
        caption: true,
      }

      const reasons = [
        'RESIGN',
        'PRODUCT_UNSUITABLE',
        'COMPETITOR_CHEAPER',
        'COMPETITOR_BETTER',
        'OFFER_WITHDRAWN',
      ].map((reason) => ({
        value: reason,
        label: this.$t(`partnerPortal.offerRejectionReason.${reason}`),
      }))

      return [defaultOption, ...reasons]
    },
  },
  mounted() {
    if (this.initiallyOpen) {
      scrollIntoView(this.$el)
    }
  },
  methods: {
    async handleChangeProductVariant(productVariantName) {
      this.$refs.changeTariff.close()
      const { productKey, variantKey } =
        this.getProductKeyAndVariantKeyByName(productVariantName)
      // TODO: fix as soon as Scip releases an API endpoint for it's product-infos!
      // not using this productVersionNumber since it's higher for modula 1 products than modula 2 products
      // and therefore would break.. instead using Scips error handling which will automatically fallback
      // to the newest active version!
      // const { productVersionNumber } = this.dexOfferDetails

      // open offer in SCIP-Sales
      this.$router.push({
        path: this.localePath('BrokerOffer-Offer'),
        query: {
          contractId: this.offer.id,
          key: productKey,
          variantKey,
          versionNumber: null,
        },
      })
    },
    async loadFullOfferAndEdit() {
      // TODO: like stated above this part needs to be rewritten as soon as Scip releases their API endpoint!
      this.isLoading = true
      try {
        const {
          productName,
          productCode,
          productKey,
          productVariantKey,
          productVersionNumber,
        } = this.dexOfferDetails

        if (productCode === 'ssc2020') {
          // open offer in SCIP-Sales
          this.$router.push({
            path: this.localePath('BrokerOffer-Offer'),
            query: {
              contractId: this.offer.id,
              key: productKey,
              variantKey: productVariantKey,
              versionNumber: productVersionNumber,
            },
          })
        } else {
          const result = await this.$store.dispatch('EDIT_OFFER', {
            id: this.offer.id,
            tariff: productName,
          })
          if (result) {
            this.$router.push({
              path: this.localePath('BrokerOffer-Summary'),
              query: { mode: 'edit' },
            })
          }
        }
      } finally {
        this.isLoading = false
      }
    },
    async loadDexOfferDetails() {
      this.isLoading = true
      try {
        const result = await this.loadOffer(this.offer.id)
        this.isLoading = false
        this.dexOfferDetails = result
      } finally {
        this.isLoading = false
        this.forceReload = false
      }
    },
    openRejectionOverlay() {
      this.rejectionReason = null
      this.$refs.rejectionOverlay.open()
    },
    getRelatedProductVariantsFromProductByVariantKey(product) {
      return product.variants.filter(
        (productVariant) =>
          productVariant.scipSalesVariantKey !==
          this.dexOfferDetails.variantKey,
      )
    },
    openChangeProductVariantOverlay() {
      this.$refs.changeTariff.open()
    },
    closeRejectionOverlay() {
      this.$refs.rejectionOverlay.close()
    },
    async confirmOfferRejection() {
      const valid = await this.$validator.validate()
      if (valid) {
        this.rejectOffer(
          this.offer.id,
          this.rejectionReason,
          this.closeRejectionOverlay,
          this.fullReload,
        )
      }
    },
    fullReload() {
      this.forceReload = true
      this.loadDexOfferDetails()
    },
    reactivate() {
      this.reactivateOffer(this.offer.id, this.fullReload)
    },
    productName(offer) {
      return offer.options || offer.productName
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
