<template>
  <div>
    <div class="col-12 col-md mb-3 mb-md-0">
      <dx-mail-notifications-block
        :headline="$t('mailFromCustomer')"
        :subheadline="$t('youGotMail')"
        :subheadline-no-mails="$t('noMails')"
        :mail-amount="
          externalLawyerInsuredPersonMessages.filter((m) => m.status !== 'READ')
            .length
        "
        :route-data="{
          name: localePath('LawyerPortal-Damage'),
          query: { claimId },
          hash: 'customerMails',
        }"
        icon-type="customer"
      />
    </div>
    <div class="col-12 col-md">
      <dx-mail-notifications-block
        :mail-amount="
          externalLawyerDextraMessages.filter((m) => m.status !== 'READ').length
        "
        :route-data="{
          name: localePath('LawyerPortal-Damage'),
          query: { claimId },
          hash: 'dextraMails',
        }"
        icon-type="home"
      />
    </div>
  </div>
</template>

<script>
import DxMailNotificationsBlock from '~/domains/externalLawyer/components/DX-MailNotificationsBlock'

export default {
  name: 'DxLawyerClaimMessages',
  components: {
    DxMailNotificationsBlock,
  },
  props: {
    claimId: {
      type: Number,
      default: null,
    },
    externalLawyerInsuredPersonMessages: {
      type: Array,
      default: () => [],
    },
    externalLawyerDextraMessages: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
