<template>
  <div class="w-100">
    <dx-claim
      :claim-id="claimId"
      :contract-id="contractId"
      :insurance-company="insuranceCompany"
    />
    <dx-popup-modal ref="overlay">
      <h3>{{ $t('pendingDocuments.title') }}</h3>
      <p>
        {{ $t('pendingDocuments.text') }}
      </p>
      <div class="text-center">
        <dx-button
          type="button"
          class="my-4"
          :text="$t('pendingDocuments.button')"
          small
          @click="leaveAnyways"
        />
      </div>
    </dx-popup-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxClaim from '~/domains/contract/claim/components/DX-Claim'
import pendingUploadsModalMixin from '~/javascripts/mixins/pendingUploadsModal'

export default {
  name: 'SelfServiceClaimOverview',
  components: {
    DxClaim,
    DxPopupModal,
    DxButton,
  },
  mixins: [pendingUploadsModalMixin],
  nuxtI18n: {
    paths: {
      de: '/kundencenter/fallverwaltung',
      fr: '/centre-clients/cas-juridique-administration',
      it: '/centro-clienti/gestione-dei-casi',
    },
  },
  computed: {
    claimId() {
      return Number(this.$route.query.id)
    },
    contractId() {
      return this.$route.query.contractId
    },
    insuranceCompany() {
      return this.$route.query.insuranceCompany
    },
  },
  mounted() {
    // cannot show claim without claimId or contractId
    if (!this.claimId || !this.contractId) {
      this.$router.push({ path: this.localePath('SelfService') })
    }
  },
}
</script>
