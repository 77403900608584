<template>
  <div>
    <dx-description-label v-if="item.headline">
      {{ item.headline }}
    </dx-description-label>

    <button
      v-if="item.infoText"
      class="infoPopupButton"
      type="button"
      @click="openInfoTextModal(module)"
    >
      <InfoIcon :height="22" :width="22" />
    </button>

    <div v-if="item.intro" class="rich-text" v-html="item.intro" />

    <div
      v-for="(element, index) in value"
      :key="element.id"
      :disabled="item.readonly"
      class="groupElement"
    >
      <div class="groupElementHeader">
        <dx-description-label>
          {{
            formContext.tc('group.elementHeader', value.length, {
              label: item.label,
              index: index + 1,
            })
          }}
        </dx-description-label>
        <button
          type="button"
          class="deleteButton"
          :title="t('remove')"
          @click="removeElement(index)"
        >
          <trash-icon :width="22" :height="22" />
        </button>
      </div>

      <slot
        name="element"
        :index="index"
        :element-value="element"
        :element-errors="Array.isArray(error) ? error[index] : {}"
      />
    </div>

    <button
      v-if="item.max === null || !value || value.length < item.max"
      type="button"
      class="addElement"
      @click="addElement"
    >
      <map-pin-icon />
      {{ formContext.t('group.add', { label: item.label }) }}
    </button>

    <div v-if="error && !Array.isArray(error)" class="error-container">
      {{ translatedError }}
    </div>
  </div>
</template>

<script>
import { toRefs, watchEffect } from '@vue/composition-api'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'
import TrashIcon from '@/components/icons/TrashIcon'
import MapPinIcon from '@/components/icons/MapPinIcon'
import { countryOptions } from '@/javascripts/utils'

import { useScipTranslationWithFallback, isEmpty } from '../../utils'

function deepClone(value) {
  if (!value || typeof value !== 'object') {
    return value
  }

  return JSON.parse(JSON.stringify(value))
}

export default {
  name: 'DxGroupAdapter',
  components: {
    DxDescriptionLabel,
    MapPinIcon,
    TrashIcon,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    error: {
      type: [Array, Object],
      default: null,
    },
  },

  setup(props, { emit }) {
    const translation = useScipTranslationWithFallback(toRefs(props))

    const { value } = toRefs(props)
    watchEffect(() => {
      emit('input', value.value)
    })

    const addElement = () => {
      const { initialElementValue } = props.item
      value.value.push(deepClone(initialElementValue))
    }

    const removeElement = (index) => {
      value.value.splice(index, 1)
    }

    return {
      ...translation,
      addElement,
      removeElement,
    }
  },

  computed: {
    translatedError() {
      if (!this.error) {
        return null
      }

      return this.tc(
        `errors.group.${isEmpty(this.value) ? 'required' : this.error.type}`,
        this.error.params.count,
        {
          ...this.error.params,
          label: this.translatedLabel,
        },
      )
    },

    countries() {
      return countryOptions(this.t.bind(this))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';

.groupElement {
  display: grid;
  margin-bottom: rem(32);
  grid-row-gap: rem(16);
  grid-template-columns: 1fr;
}

.groupElementHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteButton,
.addElement {
  border: none;
  background: transparent;
  color: $black-clr;
}

.addElement {
  display: flex;
  align-items: center;

  svg {
    margin-right: rem(8);
    color: $secondary-clr-alpha-dk;
  }
  span {
    position: relative;
    font-family: $font-family-heavy;
    font-size: rem($font-size-small);
    line-height: rem($font-line-height-xtra-small);

    @include media-breakpoint-up(lg) {
      font-size: rem($font-size);
      line-height: rem($font-line-height-small);
    }

    &::after {
      position: absolute;
      content: '';
      bottom: rem(-1);
      left: 0;
      width: 100%;
      height: rem(1);
      background-color: $black-clr;
    }
  }
}

.error-container {
  margin-top: rem(8);
  color: $functional-clr-error-dk;
}
</style>
