<template>
  <footer class="footer d-print-none">
    <div v-if="isLargeFooter" class="ext-footer">
      <div class="container-fluid">
        <div class="row">
          <div
            v-for="navItem in footerItems"
            :key="navItem.title"
            class="col-md-3 linkblock"
          >
            <h4 class="footer-item-headline">
              <a :href="navItem.path">
                {{ navItem.title }}
              </a>
            </h4>
            <ul>
              <li v-for="subnavItem in navItem.items" :key="subnavItem.title">
                <ul>
                  <li>
                    <h5 class="footer-subitem-headline">
                      <a :href="subnavItem.path">
                        {{ subnavItem.title }}
                      </a>
                    </h5>
                  </li>
                  <li
                    v-for="subItem in subnavItem.subItems"
                    :key="subItem.title"
                  >
                    <a :href="subItem.path">
                      {{ subItem.title }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="basic-footer">
      <div class="container-fluid">
        <div class="row position-relative">
          <div class="col footer-content">
            <div>
              <a
                :href="`${websiteURL}${$t('dataProtectionUrl')}`"
                target="_blank"
                rel="noopener"
                class="mb-0"
              >
                {{ $t('dataProtection') }}
              </a>
            </div>
            <div>
              <a
                :href="`${websiteURL}${$t('imprintUrl')}`"
                target="_blank"
                rel="noopener"
              >
                {{ $t('imprint') }}
              </a>
            </div>
            <div class="copy-text">
              &copy; Dextra Rechtsschutz AG - {{ getYear }}
            </div>
          </div>
          <div class="logo position-absolute" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterItems from './footerItems'

export default {
  name: 'DxFooter',
  props: {
    isLargeFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      footerItems: FooterItems,
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  computed: {
    getYear() {
      const year = new Date()
      return year.getFullYear()
    },
  },
  methods: {
    hasSubItems(item) {
      return Object.prototype.hasOwnProperty.call(item, 'subItems')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
