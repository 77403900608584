var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.editable)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-10 mb-2 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n          "+_vm._s(_vm.$t('offer.basic.insureDate'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.formData.startOfInsurance)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 mb-2 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.basic.insureWho'
                : 'offer.basic.insureWho'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.formData.familyOption
              ? _vm.$t('offer.basic.personsHousehold')
              : _vm.$t('offer.basic.singlePerson'))+"\n        ")])]),_vm._v(" "),(_vm.activeTariff !== 'p-move')?[_c('div',{staticClass:"col-12 col-md-10 mb-2 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n            "+_vm._s(_vm.$t(
                _vm.isBrokerOffer
                  ? 'brokerOffer.basic.apartmentsRentedInSwitzerland'
                  : 'offer.basic.apartmentsRentedInSwitzerland'
              ))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.formData.apartmentsRentedInSwitzerland ? _vm.$t('yes') : _vm.$t('no'))+"\n          ")])]),_vm._v(" "),(_vm.formData.apartmentsRentedInSwitzerland)?_c('div',{staticClass:"col-12 col-md-10 row"},[_c('p',{staticClass:"col-12 col-md-8 mb-0 mb-md-auto"},[_vm._v("\n            "+_vm._s(_vm.$t(
                _vm.isBrokerOffer
                  ? 'brokerOffer.basic.numberOfApartmentsForRentInSwitzerland'
                  : 'offer.basic.numberOfApartmentsForRentInSwitzerland'
              ))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"col-12 col-md-4 font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.formData.numberOfApartmentsForRentInSwitzerland)+"\n          ")])]):_vm._e()]:_vm._e()],2)]:_c('form',{on:{"change":_vm.change}},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
            required: true,
            atMostOneYearInFuture: true,
            atMostOneWeekInPast: _vm.isBrokerOffer,
          }),expression:"{\n            required: true,\n            atMostOneYearInFuture: true,\n            atMostOneWeekInPast: isBrokerOffer,\n          }",modifiers:{"disable":true}}],attrs:{"label":_vm.$t('offer.basic.insureDate'),"error":_vm.errors.first('startOfInsurance'),"only-in-future":!_vm.isBrokerOffer,"is-date-input":"","type":"date","data-vv-name":"startOfInsurance","min-date":_vm.isBrokerOffer ? new Date().fp_incr(-7) : null,"default-date":_vm.isBrokerOffer ? new Date().fp_incr(1) : null},on:{"blur":_vm.change},model:{value:(_vm.formData.startOfInsurance),callback:function ($$v) {_vm.$set(_vm.formData, "startOfInsurance", $$v)},expression:"formData.startOfInsurance"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col-12"},[_vm._v("\n        "+_vm._s(_vm.$t(
            _vm.isBrokerOffer
              ? 'brokerOffer.basic.insureWho'
              : 'offer.basic.insureWho'
          ))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('dx-radio-list',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"options":_vm.familyOptions,"error":_vm.errors.first('familyOption'),"name":"familyOption","data-vv-name":"familyOption"},model:{value:(_vm.formData.familyOption),callback:function ($$v) {_vm.$set(_vm.formData, "familyOption", $$v)},expression:"formData.familyOption"}})],1)]),_vm._v(" "),(_vm.activeTariff !== 'p-move')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v("\n        "+_vm._s(_vm.$t(
            _vm.isBrokerOffer
              ? 'brokerOffer.basic.apartmentsRentedInSwitzerland'
              : 'offer.basic.apartmentsRentedInSwitzerland'
          ))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('dx-radio-list',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"options":_vm.apartmentsRentedInSwitzerlandOptions,"error":_vm.errors.first('apartmentsRentedInSwitzerland'),"name":"apartmentsRentedInSwitzerland","data-vv-name":"apartmentsRentedInSwitzerland"},model:{value:(_vm.formData.apartmentsRentedInSwitzerland),callback:function ($$v) {_vm.$set(_vm.formData, "apartmentsRentedInSwitzerland", $$v)},expression:"formData.apartmentsRentedInSwitzerland"}})],1)]):_vm._e(),_vm._v(" "),(
        _vm.activeTariff !== 'p-move' && _vm.formData.apartmentsRentedInSwitzerland
      )?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('dx-select',{attrs:{"error":_vm.errors.first('numberOfApartmentsForRentInSwitzerland'),"label":_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.basic.numberOfApartmentsForRentInSwitzerland'
                : 'offer.basic.numberOfApartmentsForRentInSwitzerland'
            ),"options":_vm.numberOfApartmentsForRentInSwitzerlandOptions,"data-vv-name":"numberOfApartmentsForRentInSwitzerland"},model:{value:(_vm.formData.numberOfApartmentsForRentInSwitzerland),callback:function ($$v) {_vm.$set(_vm.formData, "numberOfApartmentsForRentInSwitzerland", $$v)},expression:"formData.numberOfApartmentsForRentInSwitzerland"}})],1)]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }