<template>
  <div>
    <h3 class="mb-5">
      {{ $t('damageItems') }}
    </h3>
    <h4>
      {{ $t('openDamagesOverview') }}
    </h4>
    <dx-loading-overlay v-if="loading" />
    <div v-else>
      <dx-link-container
        v-for="item in lawyerClaims"
        :key="item.id"
        :route-name="{
          path: localePath('LawyerPortal-Damage'),
          query: { claimId: item.id },
        }"
        class="mb-3"
      >
        <template slot="content">
          <div class="d-md-flex justify-content-start align-items-center">
            <div class="flex-item">
              {{ item.number }}
            </div>
            <div class="flex-item">
              {{ item.insuredPerson.fullName }}
            </div>
          </div>
        </template>
      </dx-link-container>
      <div
        v-if="!Array.isArray(lawyerClaims) || lawyerClaims.length === 0"
        class="mt-3"
      >
        {{ $t('lawyerPortal.noOpenClaims') }}
      </div>
      <div class="text-right">
        <router-link
          :to="localePath('LawyerPortal-ClosedDamageCases')"
          class="secondary-link"
        >
          {{ $t('closedDamages') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DxLinkContainer from '~/components/DX-LinkContainer'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'

import openClaimsQuery from '~/domains/externalLawyer/__gql__/queries/openClaims.gql'
import personMixin from '~/javascripts/mixins/personMixin'

export default {
  name: 'DxOpenDamages',
  components: {
    DxLinkContainer,
    DxLoadingOverlay,
  },
  mixins: [personMixin],
  data() {
    return {
      loading: true,
    }
  },
  apollo: {
    lawyerClaims: {
      query: openClaimsQuery,
      result() {
        this.loading = false
      },
      error(error) {
        this.loading = false
        throw error
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
