<template>
  <dx-counterparty-form
    next-step-path="PartnerPortal-ReportDamage-ThankYou"
    previous-step-path="PartnerPortal-ReportDamage-Deadline"
    :is-broker="true"
    create-claim-action="createBrokerLegalClaim"
  />
</template>

<script>
import DxCounterpartyForm from '~/components/reportDamage/DX-CounterpartyForm'

export default {
  name: 'PartnerReportDamageCounterparty',
  components: {
    DxCounterpartyForm,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsfall-melden/gegenpartei',
      fr: '/portailpartenaires/cas-juridique-rapport/contrepartie',
      it: '/portaledeipartner/segnalare-caso-giuridico/controparte',
    },
  },
}
</script>
