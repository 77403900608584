import Vue from 'vue'

/* eslint-disable no-param-reassign */
// state: the current state from the global storage
// initialState: the initial state as it comes freshly from the module
export function resetState(state, initialState) {
  // We don't expect to have a repaired state.
  // That's why we drop everything first.
  Object.keys(state).forEach((stateKey) => {
    delete state[stateKey]
  })
  // And then we initiate the state attribute by attribute
  // taking the reactivity into account
  Object.keys(initialState).forEach((stateKey) => {
    Vue.set(state, stateKey, initialState[stateKey])
  })

  // return the state for convenience
  return state
}

export default resetState
/* eslint-enable no-param-reassign */
