<template>
  <div>
    <div class="mt-5 my-md-4">
      <span class="arrow-left overview" @click="historyBack()">
        {{ $t('backToLogin') }}
      </span>
    </div>
    <div v-if="showForm" class="content-wrap-small content-pad">
      <div class="row">
        <div class="col mb-3 text-center">
          <h2>
            {{ $t('lawyerPortalRegister') }}
          </h2>
          <p>
            {{ $t('lawyerPortalRegisterSub') }}
          </p>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h3 class="mb-4">
            {{ $t('personalInfo') }}
          </h3>

          <dx-personal-data
            :personal-data="lawyer"
            :update-personal-data="(data) => updateLawyerData(data)"
          />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h3 class="mb-4">
            {{ $t('contactData') }}
          </h3>

          <dx-access-details :contact-details="lawyer.contactDetails" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h3 class="mb-4">
            {{ $t('loginDetails') }}
          </h3>
          <dx-input
            v-model="lawyer.email"
            :label="$t('email')"
            :disabled="true"
            type="text"
            class="mb-4"
          />
          <dx-input
            v-model="password"
            v-validate="`required|min:8`"
            :label="$t('password')"
            :error="errors.first('password')"
            data-vv-name="password"
            type="password"
            class="mb-4"
          />
          <dx-input
            v-model="passwordConfirmation"
            v-validate="`required|min:8|match_strings:${password}`"
            :label="$t('passwordRepeat')"
            :error="errors.first('newPasswordRepeat')"
            data-vv-name="newPasswordRepeat"
            type="password"
            class="mb-4"
          />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h3 class="mb-4">
            {{ $t('lawyerPortal.employmentDetails') }}
          </h3>

          <dx-employment-details
            :employment-details="lawyer"
            :update-employment-details="(data) => updateLawyerData(data)"
            :clear-errors="clearErrors"
          />

          <div class="mt-4">
            <p class="mb-1">
              {{ $t('language') }}
            </p>

            <dx-language-skills
              :skills="lawyer.languageSkills"
              :update-language-skills="(data) => updateLawyerData(data)"
              :clear-errors="clearErrors"
            />
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <h3 class="mb-4">
            {{ $t('bankdetails') }}
          </h3>

          <dx-bank-details
            :bank-details="lawyer"
            :update-bank-details="(data) => updateLawyerData(data)"
            :clear-errors="clearErrors"
          />

          <dx-radio-check
            id="agreement"
            :label="
              $t('lawyerPortal.acceptTerms', {
                linkTOS: localePath('LawyerPortal-TermsOfService'),
              })
            "
            :checked="termsAccepted"
            :error="errors.first('termsAccepted')"
            name="agreement_group_1"
            type="checkbox"
            value="agreement"
            class="mb-4"
            data-vv-name="termsAccepted"
            @input="termsAccepted = !termsAccepted"
          />

          <div class="text-right">
            <dx-button :text="$t('register')" @click="sendFormData()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isPast from 'date-fns/isPast'

import {
  DxButton,
  DxInput,
  DxRadioCheck,
} from '@sumcumo/dextra-frontend-component-lib-old'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'
import DxPersonalData from '~/domains/externalLawyer/components/DX-PersonalData'
import DxAccessDetails from '~/domains/externalLawyer/components/DX-AccessDetails'
import DxEmploymentDetails from '~/domains/externalLawyer/components/DX-ExmploymentDetails'
import DxLanguageSkills from '~/domains/externalLawyer/components/DX-LanguageSkills'
import DxBankDetails from '~/domains/externalLawyer/components/DX-BankDetails'
import { CREATE_LAWYER } from '~/domains/authentication/__store__/actions'

import invitationExternalLawyerForTokenQuery from '~/domains/externalLawyer/__gql__/queries/invitationExternalLawyerForToken.gql'
import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'

export default {
  name: 'DxExternalLawyerRegistration',
  components: {
    DxRadioCheck,
    DxInput,
    DxButton,
    DxPersonalData,
    DxAccessDetails,
    DxEmploymentDetails,
    DxLanguageSkills,
    DxBankDetails,
  },
  mixins: [loadingMixin, apolloErrorsMixin],
  props: {
    invitationToken: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showForm: true,
      lawyer: {
        email: '',
        salutation: 'MALE',
        dateOfBirth: '',
        address: {
          street: '',
          number: '',
          zip: '',
          city: '',
          additional: '',
        },
        contactDetails: {
          phone: '',
          mobile: '',
        },
        languageSkills: [],
        areaOfLawSkills: [],
        jobTitles: [],
        additionalSkills: [],
        advancedTrainings: [],
        bankAccount: {},
        hourlyRate: {},
        locale: this.$i18n.locale.toUpperCase(),
      },
      makeDataMap(translationKey, ITEMS, FILTER_ITEMS = []) {
        const defaultOption = {
          label: this.$t('selectOption'),
          value: null,
        }
        const itemMap = ITEMS.map((item) => ({
          label: this.$t(`lawyerPortal.${translationKey}.${item}`),
          value: item,
        })).filter((item) => !FILTER_ITEMS.some((fi) => fi === item.value))
        return [defaultOption, ...itemMap]
      },
      password: null,
      passwordConfirmation: null,
      termsAccepted: false,
    }
  },
  created() {
    this.setGlobalLoading(true)
  },
  mounted() {
    this.checkLawyerInvitationToken()
  },
  methods: {
    historyBack() {
      window.history.back()
    },
    async checkLawyerInvitationToken() {
      if (!this.invitationToken) {
        this.setGlobalLoading(false)
        this.$notification.error({ messageKey: 'invitation.invalidToken' })
        this.$router.push(this.localePath('Login'))
      }
    },
    updateLawyerData(data) {
      this.lawyer = { ...this.lawyer, ...data }
    },
    async sendFormData() {
      this.clearErrors()
      let valid = await this.$validator.validateAll()
      if (!this.termsAccepted) {
        this.$validator.errors.add({
          field: 'termsAccepted',
          msg: '',
          rule: 'required',
        })
        valid = false
      }
      if (this.lawyer.languageSkills.length < 1) {
        this.$validator.errors.add({
          field: 'languages',
          msg: this.$t('lawyerPortal.missingLanguage'),
          rule: 'required',
        })
        valid = false
      }
      if (this.lawyer.areaOfLawSkills.length < 1) {
        this.$validator.errors.add({
          field: 'areaOfLawSkillsEmpty',
          msg: this.$t('lawyerPortal.missingJurisdiction'),
          rule: 'required',
        })
        valid = false
      }
      if (this.lawyer.jobTitles.length < 1) {
        this.$validator.errors.add({
          field: 'jobTitlesEmpty',
          msg: this.$t('lawyerPortal.missingJobTitle'),
          rule: 'required',
        })
        valid = false
      }
      if (valid) {
        this.setGlobalLoading(true)
        const lawyer = {
          ...this.lawyer,
          hourlyRate: {
            currency: this.lawyer.hourlyRate.currency || 'CHF',
            value: Number.parseFloat(this.lawyer.hourlyRate.value),
          },
        }
        delete lawyer.email
        delete lawyer.fullName

        const variables = {
          lawyerProfileData: lawyer,
          token: this.invitationToken,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
        }
        try {
          await this.$store.dispatch(CREATE_LAWYER, {
            variables,
            apollo: this.$apollo.provider.defaultClient,
          })
          this.password = null
          this.passwordConfirmation = null
          this.dateOfBirth = null
          this.$router.push(this.localePath('LawyerPortal'))
          this.$notification.success({
            messageKey: 'lawyerPortal.registration.success',
          })
        } catch (error) {
          console.error(error)
          error.networkError.result.errors.forEach((err) => {
            if (err.message === '{"bank_datum.iban"=>["ist nicht gültig"]}') {
              this.$validator.errors.add({
                field: 'ibanInput',
                msg: this.$t('ibanInvalid'),
                rule: 'server',
              })
            } else {
              this.$notification.error({
                messageKey: 'lawyerPortal.registration.error',
              })
            }
          })
        } finally {
          this.setGlobalLoading(false)
        }
      } else {
        await scrollFirstErrorIntoView()
      }
    },
    clearErrors() {
      // clear all error scopes and default scope
      this.errors.clear()
      this.errors.clear('customLanguage')
      this.errors.clear('areaOfLawSkills')
      this.errors.clear('jobTitles')
      this.errors.clear('additionalSkill')
      this.errors.clear('advancedTraining')
    },
  },
  apollo: {
    invitationExternalLawyerForToken: {
      skip() {
        return !this.invitationToken
      },
      query: invitationExternalLawyerForTokenQuery,
      variables() {
        return {
          token: this.invitationToken,
          withClaim: false,
        }
      },
      result({ data }) {
        this.setGlobalLoading(false)
        if (data.invitationExternalLawyerForToken) {
          const { isUserKnown, expiredAt, status, lawyer } =
            data.invitationExternalLawyerForToken
          if (isUserKnown) {
            this.$notification.info({ messageKey: 'invitation.promptForLogin' })
            this.$router.push(this.localePath('Login'))
          } else if (isPast(expiredAt) || status !== 'PENDING') {
            this.$notification.error({ messageKey: 'invitation.invalidToken' })
            this.showForm = false
          } else {
            const address = {
              ...this.lawyer.address,
              ...lawyer.address,
            }

            this.lawyer = {
              ...this.lawyer,
              ...lawyer,
              salutation: lawyer.salutation || this.lawyer.salutation,
              address,
              contactDetails: {
                ...lawyer.contactDetails,
              },
              languageSkills: [
                ...lawyer.languageSkills.map((skill) => ({
                  language: skill.language.toUpperCase(),
                  level: skill.level.toUpperCase(),
                })),
              ],
              areaOfLawSkills: [...lawyer.areaOfLawSkills],
              jobTitles: [...lawyer.jobTitles],
              additionalSkills: [...lawyer.additionalSkills],
              advancedTrainings: [...lawyer.advancedTrainings],
              bankAccount: { ...lawyer.bankAccount },
              hourlyRate: { ...lawyer.hourlyRate },
              locale: lawyer.locale || this.$i18n.locale.toUpperCase() || 'DE',
            }
          }
        }
      },
      error(err) {
        const sanitizedErrors = this.handleApolloError(err, true)

        this.setGlobalLoading(false)
        this.showForm = false
        if (sanitizedErrors.graphQLError.message === '404 Not Found') {
          this.$notification.error({ messageKey: 'invitation.invalidToken' })
        } else {
          this.$notification.error({ messageKey: 'invitation.acceptError' })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'index';
</style>
