//  store-typing based on:
//  https://gitlab.sumcumo.net/dextra/dextra-proxy/blob/development/app/graphql/types/inputs/legal_claim_input.rb

export default () => ({
  attachmentTypes: [],
  selectedValues: {
    areaOfLaw: null,
    subareaOfLaw: null,
    carSection: null,
  },
  report: {
    contract: {
      id: '',
      insuranceCompany: '',
    },
    hasOtherInsurance: false,
    otherInsurance: '',
    areaOfLaw: '',
    subareaOfLaw: '',
    claim: '',
    facts: '',
    counterparty: {
      salutation: 'MALE',
      firstName: '',
      lastName: '',
      company: '',
      street: '',
      number: '',
      zip: '',
      city: '',
    },
    hasDeadline: false,
    deadline: '',
    language: '',
    isFeesRefundDesired: false,
    bankAccount: {
      iban: '',
      bic: '',
      accountHolder: '',
    },
    isConsultationRequired: false,
    attachments: [],
  },
  personId: '',
})
