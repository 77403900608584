export const PORTALS_ALL = 'PortalsAll'
export const PORTALS_LOGIN = 'PortalsLogin'
export const PORTALS_CUSTOMER = 'PortalsCustomer'
export const PORTALS_LAWYER = 'PortalsLawyer'
export const PORTALS_PARTNER = 'PortalsPartner'
export const PROJECT_SCOPE_ORDER = [
  PORTALS_ALL,
  PORTALS_LOGIN,
  PORTALS_CUSTOMER,
  PORTALS_LAWYER,
  PORTALS_PARTNER,
]

export const sortInfobarsByProjectScopeOrder = (infoBars) =>
  infoBars
    .slice()
    .sort(
      (infoBarA, infoBarB) =>
        PROJECT_SCOPE_ORDER.indexOf(infoBarA.projectScope) -
        PROJECT_SCOPE_ORDER.indexOf(infoBarB.projectScope),
    )
