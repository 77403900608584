<template>
  <div>
    <dx-loading-overlay v-if="claimsLoading" />
    <div v-else>
      <h3 class="mb-3 mb-md-4">
        {{ $t('openDamages') }}
      </h3>
      <div v-if="!openClaims.length">
        <span>{{ $t('noOpenDamages') }}</span>
      </div>
      <div v-for="claim in openClaims" :key="claim.id">
        <dx-link-container
          :route-name="{
            path: localePath('SelfService-ClaimOverview'),
            query: {
              id: claim.id,
              contractId: claim.contract.id,
              insuranceCompany: claim.contract.insuranceCompany,
            },
          }"
          class="mb-2"
        >
          <template slot="content">
            <span class="font-weight-bold">
              {{ claim.number }}
            </span>
          </template>
        </dx-link-container>
      </div>
    </div>
  </div>
</template>

<script>
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxLinkContainer from '~/components/DX-LinkContainer'

export default {
  name: 'DxClaimsOverview',
  components: {
    DxLoadingOverlay,
    DxLinkContainer,
  },
  props: {
    claimsLoading: {
      type: Boolean,
      default: true,
    },
    openClaims: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
