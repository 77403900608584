<template>
  <dx-accordion :remove-desktop-accordion="true">
    <template slot="headline">
      <h3 class="mb-0">
        {{ $t('chronic') }}
      </h3>
    </template>
    <template slot="content">
      <div
        v-for="(option, index) in chronicData.slice(0, getChronicDisplaySize())"
        :key="index"
        class="entry"
      >
        <p class="headline mb-0 pt-2 font-weight-bold">
          {{ $t(`damage.commentType.${option.type}`) }}
        </p>
        <p class="details mb-0" v-html="option.description" />
        <p class="date">
          {{ getFormattedDate(option.date) }}
        </p>
      </div>
      <button
        v-if="chronicData.length > reducedChronicleListSize"
        :title="showMore ? $t('showLess') : $t('showMore')"
        :class="btnCssClasses()"
        @click="toggleShowMore"
      >
        {{ showMore ? $t('showLess') : $t('showMore') }}
      </button>
    </template>
  </dx-accordion>
</template>

<script>
import { DxAccordion } from '@sumcumo/dextra-frontend-component-lib-old'
import dateMixin from '~/javascripts/mixins/dateMixin'

export default {
  name: 'DxChronic',
  components: {
    DxAccordion,
  },
  mixins: [dateMixin],
  props: {
    chronicData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showMore: false,
      reducedChronicleListSize: 5,
    }
  },
  methods: {
    btnCssClasses() {
      return {
        'btn-blank': true,
        'link': true,
        'mt-4': true,
        'ml-2': true,
        'plus-icon show-more-link': !this.showMore,
      }
    },
    toggleShowMore() {
      this.showMore = !this.showMore
    },
    getChronicDisplaySize() {
      return this.showMore
        ? this.chronicData.length
        : this.reducedChronicleListSize
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
