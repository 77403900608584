<template>
  <div class="damage-item mb-3 px-4 py-3 position-relative">
    <div class="d-flex justify-content-start">
      <div class="col mr-2 mb-2 p-0 font-weight-bold">
        {{ claim.number }}
      </div>
      <div :class="claim.status.toLowerCase()" class="status">
        {{ $t(`damage.status.${claim.status.toLowerCase()}`) }}
      </div>
    </div>
    <div v-if="claim.status !== 'NEW'">
      {{ $t(`damage.type.${claim.type}`) }}
    </div>
    <div :class="!isBroker || claim.status === 'NEW' ? 'pb-5' : 'pb-3'">
      {{ getFormattedDate(claim.eventDate) }}
    </div>

    <div v-if="isBroker && claim.status !== 'NEW'" class="row">
      <div class="col-12 col-md-6">
        <ul class="neutral">
          <li v-if="claim.insuredPerson">
            {{ $t('damage.insuredPerson') }}: {{ claim.insuredPerson.fullName }}
          </li>
          <li v-if="claim.costs">
            {{ $t('damage.costs') }}: {{ claim.costs.currency }}
            {{ formatCurrency(claim.costs.value) }}
          </li>
          <li v-if="claim.lawyer">
            {{ $t('damage.dextraLawyer') }}: {{ claim.lawyer.fullName }}
          </li>
          <li v-if="claim.externalLawyer">
            {{ $t('damage.externalLaywer') }}:
            {{ claim.externalLawyer.fullName }}
          </li>
        </ul>
      </div>
    </div>

    <nuxt-link
      :to="
        localePath({
          name: isBroker
            ? 'PartnerPortal-DamageManagement'
            : 'SelfService-ClaimOverview',
          query: {
            id: claim.id,
            contractId: claim.contract.id,
            insuranceCompany: claim.contract.insuranceCompany,
          },
        })
      "
      class="link secondary-link position-absolute pr-4 pb-3"
    >
      {{ $t('damageManage') }}
    </nuxt-link>
  </div>
</template>

<script>
import dateMixin from '~/javascripts/mixins/dateMixin'
import { formatCurrency } from '~/javascripts/utils'

export default {
  name: 'DxClaimExcerpt',
  mixins: [dateMixin],
  props: {
    claim: {
      type: Object,
      default() {
        return {}
      },
    },
    isBroker: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatCurrency,
  },
}
</script>

<style lang="scss" scoped>
@import '../index';
</style>
