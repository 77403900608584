<template>
  <svg
    :height="height"
    viewBox="0 0 24 24"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="m0 0h24v24h-24z" />
      <path
        d="m20.9999999 14c.5522848 0 1 .4477153 1 1v4c0 1.6568545-1.3431459 3-3 3h-13.99999999c-1.65685449 0-3-1.343146-3-3v-4c0-.5522847.44771525-1 1-1s1 .4477153 1 1v4c0 .5522847.44771518 1 1 1h13.99999999c.5522848 0 1-.4477152 1-1v-4c0-.5522847.4477154-1 1-1zm-8.9999999-12c.5522847 0 1 .44771525 1 1l-.0000001 9.585 3.2928933-3.29210678c.3905243-.39052429 1.0236893-.39052429 1.4142136 0s.3905243 1.02368928 0 1.41421358l-5 5c-.0284663.0284663-.0586409.0552244-.0903567.0801072l.0903567-.0801072c-.0353941.035394-.0727812.0675802-.1118002.0965586-.0241759.0178445-.0490316.0346159-.0746464.0502672-.0120471.0074605-.0242658.0145686-.0365981.0213962-.0197682.0108773-.0401707.0212946-.060963.0310174-.0170006.0079748-.0343035.0154574-.0517631.0224283-.0197568.0079098-.0395383.015103-.0595996.0216788-.0148851.004818-.0302731.009449-.0457434.0137016-.0215565.0060115-.0432469.0112053-.0651989.0156799-.0174188.003463-.0348451.0065371-.0523272.0091474-.0203525.0031119-.0414095.0055818-.0626559.0073875-.0178879.0014853-.03522.0025075-.0525642.0030809-.0108449.0003645-.0220243.0005494-.0332468.0005494l-.0322546-.0005171c-.0180118-.0005776-.0360109-.0016393-.0539762-.003185l.0862308.0037021c-.0506203 0-.1003621-.0037612-.1489612-.0110193-.0169878-.0026088-.0344141-.0056829-.0517646-.0092208-.0220203-.0043997-.0437107-.0095935-.0651138-.0154809-.0156237-.0043767-.0310117-.0090077-.0463029-.0140171-.0196552-.0063844-.0394367-.0135776-.058916-.0213659-.0177371-.0070924-.03504-.014575-.052163-.0225694-.0206699-.0097032-.0410724-.0201205-.0610555-.0312024-.0121174-.006623-.0243361-.0137311-.0364318-.0211197-.0255662-.0157232-.0504219-.0324946-.0744505-.050318-.0074437-.0054399-.0146831-.010971-.021863-.0166142-.0314436-.0247411-.0616182-.0514992-.0900845-.0799655l-4.99999998-5c-.39052429-.3905243-.39052429-1.02368929 0-1.41421358s1.02368927-.39052429 1.41421356 0l3.29289312 3.29210678.0000001-9.585c0-.55228475.4477153-1 1-1z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',

  props: {
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
  },
}
</script>
