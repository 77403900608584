const keyCodes = {
  esc: 27,
}

export default keyCodes
export { keyCodes }

export const dateToYear = (date) => date.split('.')[2]

export const formatCurrency = (amount) =>
  amount
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")

const topCountries = ['CH', 'DE', 'IT', 'FR']

export function countryOptions($t) {
  const selectOption = { value: null, label: $t('selectOption') }

  const countries = $t('countries')
  const topOptions = topCountries //
    .map((value) => ({ value, label: countries[value] }))

  const allOptions = Object.entries(countries) //
    .map(([value, label]) => ({ value, label }))
  allOptions.sort((a, b) => Intl.Collator().compare(a.label, b.label))

  return [selectOption, ...topOptions, ...allOptions]
}
