//  one finds these in the query profile
/*
  DEX_POLICYHOLDER
  DEX_INSURED_PERSON
  GM
  GM_LIMITED
*/
import jwtTools from '~/javascripts/helper/jwtTools'

export default {
  isLoggedIn(state) {
    return state.token.jwt && jwtTools.isValid(state.token.jwt)
  },
  hasValidToken(state) {
    return state.token.validUntil * 1000 - Date.now() > 0
  },
  hasNoRole(state) {
    return state.roles.length === 0
  },
  isDexPolicyholder(state) {
    return state.roles.includes('DEX_POLICYHOLDER')
  },
  isDexInsuredPerson(state) {
    return state.roles.includes('DEX_INSURED_PERSON')
  },
  isGm(state) {
    return state.roles.includes('GM')
  },
  isGmLimited(state) {
    return state.roles.includes('GM_LIMITED')
  },
  isGmCustomer(state) {
    return state.roles.includes('GM_LIMITED') || state.roles.includes('GM')
  },
  isExternalLawyer(state) {
    return state.roles.includes('EXTERNAL_LAWYER')
  },
  isBroker(state) {
    return state.roles.includes('BROKER')
  },
}
