<template>
  <div class="cmp-navigation-placebo">
    <div :class="cssClasses()" class="cmp-navigation-wrap">
      <div class="container-fluid cmp-navigation">
        <div class="row">
          <a :href="websiteURL" class="col-md-3 cmp-navigation-logo">
            <span class="visually-hidden">Go to startpage</span>
          </a>

          <a class="cmp-navigation-hamburger" @click="openNav">
            <span class="cmp-navigation-hamburger-icon" />
            <span class="visually-hidden">Toggle Navigation</span>
          </a>
        </div>
        <div class="row cmp-navigation-container dotted-border-top">
          <div class="col-12 dotted-border-bottom">
            <dx-lang-switcher class="lang-switcher-mobile--pos py-4" />
          </div>
          <ul
            class="cmp-navigation-list neutral dotted-border-bottom pb-3 mb-2"
          >
            <li
              v-for="(item, idx) in filteredNavData"
              :key="`${item.name}_${idx}`"
            >
              <mobile-navigation-link-item :item="item" @click="closeNav" />
            </li>
          </ul>

          <div class="col">
            <a
              :href="serviceLink"
              class="secondary-link font-weight-medium nav-item--spacing"
              target="_blank"
              rel="noopener"
            >
              {{ $t('login') }}
            </a>

            <dx-button
              small
              :text="$t('reportDamage')"
              theme="wine"
              icon="lightning"
              class="text-center mt-4 mb-5"
              :external-link="`${websiteURL}${$t('reportDamageLink')}`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLangSwitcher from '~/components/DX-LangSwitcher'
import MobileNavigationLinkItem from './LinkItem'

export default {
  name: 'DxMobileNavigation',
  components: {
    MobileNavigationLinkItem,
    DxButton,
    DxLangSwitcher,
  },
  props: {
    navItems: {
      type: Array,
      default() {
        return []
      },
    },
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isActive: false,
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
      serviceLink: process.env.DEXTRA_SERVICE_URL,
    }
  },
  computed: {
    filteredNavData() {
      return [...this.navItems].filter(this.navFilter)
    },
  },
  watch: {
    $route() {
      this.isActive = false
    },
  },
  methods: {
    openNav() {
      this.isActive = !this.isActive
    },
    closeNav() {
      this.isActive = false
    },
    navFilter(data) {
      const hideForLocale = data.hideForLocale === this.$i18n.locale
      const hideForEnv =
        data.hideForEnv && data.hideForEnv.includes(process.env.DEXTRA_ENV)
      return !hideForLocale && !hideForEnv
    },
    cssClasses() {
      return {
        'active-nav': this.isActive,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
