<template>
  <div>
    <div
      class="content-wrap-medium mb-3 pl-3 mb-md-0 pt-3 text-right damage-communication"
      :class="`status-${shareStatus}`"
    >
      {{ $t(`partnerPortal.damageManagement.statusTexts.${shareStatus}.text`) }}
      <button
        v-if="requestPossible"
        type="button"
        class="btn-blank text-underline"
        :disabled="!email"
        @click="openRequestOverlay"
      >
        {{
          $t(
            `partnerPortal.damageManagement.statusTexts.${shareStatus}.linkText`,
          )
        }}
      </button>

      <!-- eslint-disable -->
      <svg
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="33"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)">
            <ellipse
              class="icon-background-color"
              cx="18"
              cy="16.5"
              fill="#D9E3EE"
              rx="16"
              ry="15.5"
            />
            <ellipse
              cx="15.5"
              cy="15"
              stroke="#263047"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              rx="15.5"
              ry="15"
            />
          </g>
          <g fill-rule="nonzero" transform="translate(10 8)">
            <path
              class="icon-color"
              stroke="#FFF"
              stroke-linecap="square"
              d="M5.12518477 13.2804841l6.64806792-3.71526498M5.00184649 4.35896342l6.60861166 3.78500347"
            />
            <circle class="icon-color" cx="3" cy="3" r="3" fill="#FFF" />
            <circle class="icon-color" cx="3" cy="14" r="3" fill="#FFF" />
            <circle class="icon-color" cx="12" cy="9" r="3" fill="#FFF" />
          </g>
          <g fill-rule="nonzero" transform="translate(10 7)">
            <path
              class="icon-color"
              stroke="#00AFCF"
              stroke-linecap="square"
              d="M5.15811119 13.33821356l6.58221507-3.8307239M4.79793433 4.36637918l6.47249119 4.013335"
            />
            <circle class="icon-color" cx="3" cy="3" r="3" fill="#00AFCF" />
            <circle class="icon-color" cx="3" cy="14" r="3" fill="#00AFCF" />
            <circle class="icon-color" cx="12" cy="9" r="3" fill="#00AFCF" />
          </g>
        </g>
      </svg>
      <!-- eslint-enable -->

      <template v-if="shareStatus === 'none'">
        <dx-tooltip position="left" class="info-i">
          <template slot="trigger">
            <button type="button" class="btn-blank info-tooltip">
              <span class="visually-hidden">info</span>
            </button>
          </template>
          <template slot="content">
            {{ $t('partnerPortal.damageManagement.infoText') }}
          </template>
        </dx-tooltip>
      </template>
    </div>
    <dx-popup-modal ref="requestOverlay">
      <h3>{{ $t('partnerPortal.damageManagement.requestShare.headline') }}</h3>
      <p>
        {{ $t('partnerPortal.damageManagement.requestShare.text', { email }) }}
      </p>
      <div
        class="d-flex flex-wrap-reverse justify-content-center d-sm-block text-right"
      >
        <span
          class="abort-btn mt-2 mr-3 ml-3 mt-sm-0 secondary-link btn-blank"
          @click="closeRequestOverlay"
        >
          {{ $t('abort') }}
        </span>

        <dx-button
          type="button"
          class="d-inline"
          :text="$t('partnerPortal.damageManagement.requestShare.headline')"
          small
          @click="requestShare"
        />
      </div>
    </dx-popup-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxTooltip from '~/components/DX-Tooltip'

export default {
  name: 'DxDamageCommunicationStatus',
  components: {
    DxButton,
    DxTooltip,
    DxPopupModal,
  },
  props: {
    status: {
      type: String,
      default: null,
    },
    shareObjectStatus: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: '',
    },
    onShareRequest: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    shareStatus() {
      if (this.shareObjectStatus === 'ACTIVE') {
        return 'accepted'
      }

      if (
        ['REJECTED', 'EXPIRED', 'CANCELED', 'ACCEPTED'].indexOf(this.status) >=
        0
      ) {
        return 'rejected'
      }

      if (this.status === 'PENDING') {
        return 'pending'
      }

      return 'none'
    },
    requestPossible() {
      return this.shareStatus === 'none' || this.shareStatus === 'rejected'
    },
  },
  methods: {
    openRequestOverlay() {
      this.$refs.requestOverlay.open()
    },
    closeRequestOverlay() {
      this.$refs.requestOverlay.close()
    },
    requestShare() {
      this.onShareRequest()
      this.$refs.requestOverlay.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
