export default {
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$configEntryById = function configEntry(section, id) {
      let element = {}

      Object.keys(options.config[section]).forEach((key) => {
        const entry = options.config[section][key]
        if (entry.id === id) {
          element = { ...entry }
        }
      })

      return element
    }

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$config = { ...options.config }
  },
}
