<template>
  <div class="container-fluid text-center">
    <div class="content-wrap-x-small content-pad content-pad--big-top">
      <div class="check-wrap" />
      <h2 class="mt-3 mb-4">
        {{ $t('partnerPortal.register.thanksHeadline') }}
      </h2>
      <p class="mb-4">
        {{ $t('partnerPortal.register.thanksText') }}
      </p>
      <dx-button
        :text="$t('partnerPortal.register.toPartnerPortal')"
        :route-name="localePath('PartnerPortal')"
      />
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'PartnerPortalRegistrationThankYou',
  meta: {
    isPublic: true,
  },
  components: {
    DxButton,
  },
  layout: 'modulaStylePlain',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/registrieren/danke',
      fr: '/portailpartenaires/enregistrer/merci',
      it: '/portaledeipartner/registro/grazie',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
