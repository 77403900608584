<template>
  <div class="w-100">
    <dx-head-blue
      :headline="$t('partnerPortal.myPortal')"
      :textblock="$t('partnerPortal.headlineText')"
      hide-text-on-mobile
      class="mb-4 mb-md-5"
      button-icon="lightning"
      :button-text="$t('reportDamageLegalAdvice')"
      :route-name="'PartnerPortal-ClosedPolicies'"
    />

    <div class="container-fluid content-wrap-medium py-4 pt-md-3 pb-md-5">
      <dx-type-of-client />
    </div>

    <div class="content-bg-color">
      <div class="container-fluid content-pad">
        <dx-products />
      </div>
    </div>

    <div class="container-fluid content-wrap-medium content-pad">
      <dx-download-center />
    </div>
  </div>
</template>

<script>
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxProducts from '~/domains/partnerPortal/components/DX-Products'
import DxTypeOfClient from '~/domains/partnerPortal/components/DX-TypeOfClient'
import DxDownloadCenter from '~/domains/partnerPortal/components/DX-DownloadCenter'

export default {
  name: 'PartnerPortalStart',
  components: {
    DxHeadBlue,
    DxProducts,
    DxTypeOfClient,
    DxDownloadCenter,
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal',
      fr: '/portailpartenaires',
      it: '/portaledeipartner',
    },
  },
}
</script>
