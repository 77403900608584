var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-12 col-md mb-3 mb-md-0"},[_c('dx-mail-notifications-block',{attrs:{"headline":_vm.$t('mailFromCustomer'),"subheadline":_vm.$t('youGotMail'),"subheadline-no-mails":_vm.$t('noMails'),"mail-amount":_vm.externalLawyerInsuredPersonMessages.filter(function (m) { return m.status !== 'READ'; })
          .length,"route-data":{
        name: _vm.localePath('LawyerPortal-Damage'),
        query: { claimId: _vm.claimId },
        hash: 'customerMails',
      },"icon-type":"customer"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md"},[_c('dx-mail-notifications-block',{attrs:{"mail-amount":_vm.externalLawyerDextraMessages.filter(function (m) { return m.status !== 'READ'; }).length,"route-data":{
        name: _vm.localePath('LawyerPortal-Damage'),
        query: { claimId: _vm.claimId },
        hash: 'dextraMails',
      },"icon-type":"home"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }