<template>
  <div>
    <dx-scip-sales
      :product-key="key"
      :variant-key="variantKey"
      :version-number="versionNumber"
      :contract-id="contractId"
    />
  </div>
</template>

<script>
import DxScipSales from '~/components/DX-ScipSales/form'

export default {
  name: 'BrokerOfferScipSales',
  components: {
    DxScipSales,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/gemeinde/',
    },
  },

  computed: {
    key() {
      return this.$route.query.key || 'gemeinde'
    },
    variantKey() {
      return this.$route.query.variantKey || 'paket_gemeinden'
    },
    versionNumber() {
      return Number(this.$route.query.versionNumber) || 1
    },

    contractId() {
      return this.$route.query.contractId || null
    },
  },
}
</script>
