export const dictionary = {
  de: {
    messages: {
      required: 'Dies ist ein Pflichtfeld.',
      email: 'Die Mail muss im Mailformat vorliegen.',
      numeric: 'Sollte eine Zahl sein.',
      min: (field, params) => `Muss mindestens ${params[0]} Zeichen lang sein.`,
      max: (field, params) => `Darf maximal ${params[0]} Zeichen lang sein.`,
      digits: (field, params) => `Muss ${params[0]} Ziffern lang sein.`,
    },
    custom: {
      date: {
        required: 'Bitte geben Sie ein Datum an.',
        min: 'Bitte geben Sie ein Datum an.',
        isAdult: 'Sie müssen mindestens 18 Jahre alt sein.',
        date_format: 'Bitte geben Sie ein Datum im Format dd.mm.yyyy an.',
      },
      estimatedAnnualPayroll: {
        numeric:
          'Bitte geben Sie einen Betrag ohne Rappen, ohne Trennzeichen und ohne den Zusatz "CHF" ein.',
      },
      estimatedAnnualSales: {
        numeric:
          'Bitte geben Sie einen Betrag ohne Rappen, ohne Trennzeichen und ohne den Zusatz "CHF" ein.',
      },
      subFieldOfLaw: {
        required: 'Bitte wählen Sie ein Rechtsgebiet aus.',
      },
      fieldOfLaw: {
        required: 'Bitte geben Sie einen Sachverhalt an.',
      },
      requiredField: {
        required: 'Dies ist ein Pflichtfeld.',
      },
      requiredSelection: {
        required: 'Bitte treffen Sie eine Auswahl.',
      },
      gender: {
        required: 'Wählen Sie aus.',
      },
      companyName: {
        required: 'Geben Sie den Namen Ihrer Firma an.',
        min: 'Ihr Firmenname sollte wenigstens zwei Zeichen lang sein.',
      },
      firstName: {
        required: 'Geben Sie Ihren Vornamen an.',
        min: 'Ihr Vorname sollte wenigstens zwei Zeichen lang sein.',
      },
      lastName: {
        required: 'Geben Sie Ihren Nachnamen an.',
        min: 'Ihr Vorname sollte wenigstens zwei Zeichen lang sein.',
      },
      policyNumber: {
        required: 'Geben Sie eine Policennummer an.',
        min: 'Die Policenummer muss min 1 Zeichen lang sein.',
      },
      street: {
        required: 'Geben Sie eine Strasse an.',
        min: 'Die Strasse sollte mindestens zwei Buchstaben enthalten.',
      },
      streetNumber: {
        required: 'Geben Sie eine Hausnummer an.',
        min: 'Mindestens eine Stelle.',
      },
      additional: {
        required: 'Geben Sie ein Postfach an.',
      },
      zip: {
        required: 'Geben Sie eine PLZ an.',
        digits: 'Eine PLZ besteht aus 4 Zahlen.',
        validate_ZIP: 'Ungültige Schweizer Postleitzahl',
      },
      city: {
        required: 'Geben Sie eine Stadt an.',
        min: 'Mehr als drei Buchstaben lang.',
      },
      email: {
        required: 'Es wird eine E-Mail erwartet.',
        email: 'Die Mail muss im Mailformat vorliegen.',
        is_email_assigned: 'Diese Email wurde bereits eingeladen.',
      },
      invalidEmail: {
        required: 'Es wird eine E-Mail erwartet.',
        email: 'Die Mail muss im Mailformat vorliegen.',
        is_email_assigned: 'Diese Email wurde bereits eingeladen.',
      },
      invalidEmailRepeat: {
        required: 'Es wird eine E-Mail erwartet.',
        match_strings: 'Die E-Mail-Adressen stimmen nicht überein.',
      },
      emailConfirmation: {
        required: 'Es wird eine E-Mail erwartet.',
        match_strings: 'Die E-Mail-Adressen stimmen nicht überein.',
      },
      invalidDate: {
        required: 'Bitte geben Sie ein Datum an.',
        inTheFuture: 'Das Datum muss in der Zukunft liegen.',
        inTheFutureGermanFormat: 'Das Datum muss in der Zukunft liegen.',
        inThePast: 'Das Datum muss in der Vergangenheit liegen.',
        inThePastGermanFormat: 'Das Datum muss in der Vergangenheit liegen.',
      },
      password: {
        required: 'Zum Einloggen, geben Sie ein Passwort an.',
      },
      oldPassword: {
        required:
          'Zum Ändern Ihres Passworts geben Sie bitte Ihr altes Passwort an.',
        min: 'Ihr Passwort muss mindestens aus 8 Zeichen bestehen.',
      },
      newPassword: {
        required:
          'Zum Ändern Ihres Passworts geben Sie bitte ein neues Passwort an.',
        min: 'Ihr neues Passwort muss mindestens aus 8 Zeichen bestehen.',
        not_match_strings:
          'Das neue Passwort muss sich von Ihrem alten unterscheiden.',
      },
      newPasswordRepeat: {
        required:
          'Zum Ändern Ihres Passworts geben Sie bitte Ihr neues Passwort erneut an.',
        min: 'Ihr neues Passwort muss mindestens aus 8 Zeichen bestehen.',
        match_strings:
          'Die Eingabe muss mit Ihrem neuen Passwort übereinstimmen.',
      },
      passwordRepeat: {
        required: 'Geben Sie bitte Ihr neues Passwort erneut an.',
        min: 'Ihr neues Passwort muss mindestens aus 8 Zeichen bestehen.',
        match_strings:
          'Die Eingabe muss mit Ihrem neuen Passwort übereinstimmen.',
      },
      iban: {
        required: 'Bitte geben Sie die IBAN ein.',
        validate_IBAN: 'IBAN inkorrekt.',
      },
      bic: {
        required: 'Bitte geben Sie die BIC ein.',
        validate_BIC: 'BIC inkorrekt.',
      },
      otherInsurance: {
        required: 'Bitte geben Sie die Daten der zusätzlichen Versicherung an.',
      },
      missingMessageBody: {
        required: 'Bitte geben Sie einen Text an.',
      },
      mobileOrPhoneNumber: {
        required: 'Bitte geben Sie eine Telefon- oder Handy-Nummer an.',
      },
      language: {
        required: 'Bitte geben Sie eine Sprache an.',
      },
      accountHolder: {
        required: 'Bitte geben Sie einen Kontoinhaber an.',
      },
      documentType: {
        required: 'Bitte geben Sie einen Typ an.',
      },
      documentTitle: {
        required: 'Bitte geben Sie eine Art an.',
      },
      amount: {
        required: 'Bitte geben Sie einen Betrag an.',
        decimal: 'Bitte geben Sie maximal 2 Nachkommastellen an.',
        min_value: 'Bitte geben Sie einen Betrag > 0 an.',
      },
      kind: {
        required: 'Bitte wählen Sie eine Art aus.',
      },
      invoiceType: {
        required: 'Bitte geben Sie einen Typ an.',
      },
      outcome: {
        required: 'Bitte treffen Sie eine Auswahl.',
      },
      referenceNumber: {
        required: 'Bitte geben Sie eine Referenznummer an.',
      },
      missingAttachments: {
        required: 'Bitte laden Sie mindestens ein Dokument hoch.',
      },
      hourlyRate: {
        required: 'Dies ist ein Pflichtfeld.',
      },
      rejectionReason: {
        required: 'Bitte treffen Sie eine Auswahl.',
      },
      startOfInsurance: {
        required: 'Bitte geben Sie ein Datum an.',
        atMostOneYearInFuture:
          'Das Datum darf max. 1 Jahr in der Zukunft liegen.',
        atMostOneWeekInPast:
          'Das Datum darf max. 7 Tage in der Vergangenheit liegen.',
      },
      countStreetVehicles: {
        min_value: 'Bitte wählen Sie mindestens ein Fahrzeug aus.',
      },
      countWaterVehicles: {
        min_value: 'Bitte wählen Sie mindestens ein Fahrzeug aus.',
      },
      agreeDataProtection: {
        required: 'Dies ist ein Pflichtfeld.',
        is_true: 'Dies ist ein Pflichtfeld.',
      },
      policyHolderMandate: {
        required: 'Dies ist ein Pflichtfeld.',
        is_true: 'Dies ist ein Pflichtfeld.',
      },
      invoiceMethod: {
        required: 'Wählen Sie aus.',
      },
      mainExpiry: {
        required: 'Bitte geben Sie ein Datum an.',
        mainExpiry: 'Unmöglich an diesem Tag.',
        date_format: 'Bitte geben Sie ein gültiges Datum im Format dd.mm. an.',
      },
    },
  },
  fr: {
    messages: {
      required: 'Ce formulaire est obligatoire.',
      email: 'Le mail doit être au format mail.',
      numeric: 'Devrait être un nombre.',
      min: (field, params) =>
        `Doit contenir au minimum ${params[0]} caractères.`,
      max: (field, params) =>
        `Doit contenir au maximum ${params[0]} caractères.`,
      digits: (field, params) => `Doit comporter ${params[0]} chiffres.`,
    },
    custom: {
      date: {
        required: 'Veuillez entrer une date.',
        min: 'Veuillez entrer une date.',
        isAdult: 'Vous devez avoir 18 ans au minimum.',
        date_format: 'Date non valable (dd.mm.yyyy).',
      },
      estimatedAnnualPayroll: {
        numeric:
          'Veuillez indiquer le montant sans centimes, sans signes de séparation et sans "CHF".',
      },
      estimatedAnnualSales: {
        numeric:
          'Veuillez indiquer le montant sans centimes, sans signes de séparation et sans "CHF".',
      },
      subFieldOfLaw: {
        required: 'Veuillez sélectionner un domaine du droit.',
      },
      fieldOfLaw: {
        required: 'Veuillez entrer un fait.',
      },
      requiredField: {
        required: 'Ce formulaire est obligatoire.',
      },
      requiredSelection: {
        required: 'Veuillez faire une sélection.',
      },
      gender: {
        required: 'Sélectionnez.',
      },
      companyName: {
        required: 'Veuillez entrer le nom de votre société.',
        min: 'Le nom de votre entreprise doit comporter au moins deux caractères.',
      },
      firstName: {
        required: 'Veuillez entrer votre prénom.',
        min: 'Votre prénom doit comporter au moins deux caractères.',
      },
      lastName: {
        required: 'Veuillez entrer votre nom de famille.',
        min: 'Votre nom de famille doit comporter au moins deux caractères.',
      },
      policyNumber: {
        required: 'Veuillez entrer un numéro de police.',
        min: 'Le numéro de police doit comporter au moins 1 caractères.',
      },
      street: {
        required: 'Veuillez entrer une rue.',
        min: 'La rue devrait contenir au moins deux lettres.',
      },
      streetNumber: {
        required: 'Veuillez entrer un numéro de maison.',
        min: 'Au moins un travail.',
      },
      additional: {
        required: 'Veuillez indiquer votre case postale.',
      },
      zip: {
        required: 'Veuillez insérer un code postal.',
        min: 'Un code postal se compose de 4 chiffres.',
        max: 'Un code postal se compose de 4 chiffres.',
      },
      city: {
        required: 'Veuillez entrer une ville.',
        min: 'Plus de trois lettres de long.',
      },
      email: {
        required: 'Veuillez saisir votre adresse e-mail.',
        email: 'Le mail doit être au format mail.',
        is_email_assigned: 'Cet email a déjà été invité.',
      },
      invalidEmail: {
        required: 'Veuillez saisir votre adresse e-mail.',
        email: 'Le mail doit être au format mail.',
        is_email_assigned: 'Cet email a déjà été invité.',
      },
      invalidEmailRepeat: {
        required: 'Veuillez saisir votre adresse e-mail.',
        match_strings: 'Les adresses E-Mail doivent concorder.',
      },
      emailConfirmation: {
        required: 'Veuillez saisir votre adresse e-mail.',
        match_strings: 'Les adresses E-Mail doivent concorder.',
      },
      invalidDate: {
        required: 'Veuillez entrer une date.',
        inTheFuture: 'La date doit être dans le futur.',
        inTheFutureGermanFormat: 'La date doit être dans le futur.',
        inThePast: 'La date doit se situer dans le passé.',
        inThePastGermanFormat: 'La date doit se situer dans le passé.',
      },
      password: {
        required: 'Pour vous connecter, veuillez entrer un mot de passe.',
      },
      oldPassword: {
        required:
          'Pour changer votre mot de passe, veuillez entrer votre ancien mot de passe.',
        min: 'Votre mot de passe doit comporter au moins 8 caractères.',
      },
      newPassword: {
        required:
          'Pour changer votre mot de passe, veuillez entrer un nouveau mot de passe.',
        min: 'Votre nouveau mot de passe doit comporter au moins 8 caractères.',
        match_strings:
          'L’entrée doit correspondre à votre nouveau mot de passe.',
      },
      newPasswordRepeat: {
        required:
          'Pour changer votre mot de passe, veuillez entrer votre nouveau mot de passe.',
        min: 'Votre nouveau mot de passe doit comporter au moins 8 caractères.',
        confirm_unless_empty:
          'L’entrée doit correspondre à votre nouveau mot de passe.',
      },
      iban: {
        required: "Veuillez entrer l'IBAN.",
        validate_IBAN: 'IBAN incorrect.',
      },
      bic: {
        required: 'Veuillez entrer le BIC.',
        validate_BIC: 'BIC incorrect.',
      },
      otherInsurance: {
        required: 'Veuillez entrer les informations de l’assurance.',
      },
      missingMessageBody: {
        required: 'Veuillez entrer un texte.',
      },
      mobileOrPhoneNumber: {
        required:
          'Veuillez entrer un numéro de téléphone ou de téléphone portable.',
      },
      language: {
        required: 'Veuillez entrer une langue.',
      },
      accountHolder: {
        required: 'Veuillez entrer le nom d’un titulaire de compte.',
      },
      documentType: {
        required: 'Veuillez entrer un type.',
      },
      documentTitle: {
        required: 'Veuillez entrer une sorte.',
      },
      amount: {
        required: 'Veuillez entrer un montant.',
        decimal: 'Veuillez entrer un maximum de 2 décimales.',
        min_value: 'Veuillez entrer un montant > 0.',
      },
      kind: {
        required: 'Veuillez sélectionner une caractéristique.',
      },
      invoiceType: {
        required: 'Veuillez entrer un type.',
      },
      outcome: {
        required: 'Veuillez faire une sélection.',
      },
      referenceNumber: {
        required: 'Veuillez entrer un numéro de référence.',
      },
      missingAttachments: {
        required: 'Veuillez uploader au moins un document.',
      },
      hourlyRate: {
        required: 'Ce formulaire est obligatoire.',
      },
      rejectionReason: {
        required: 'Veuillez faire une sélection.',
      },
      startOfInsurance: {
        required: 'Veuillez entrer une date.',
        atMostOneYearInFuture:
          "Le début de l'assurance peut être un maximum de 1 an dans le futur.",
        atMostOneWeekInPast:
          "Le début de l'assurance peut être un maximum de 7 jours dans le passé.",
      },
      countStreetVehicles: {
        min_value: 'Veuillez choisir au moins un véhicule.',
      },
      countWaterVehicles: {
        min_value: 'Veuillez choisir au moins un véhicule.',
      },
      agreeDataProtection: {
        required: 'Ce formulaire est obligatoire.',
        is_true: 'Ce formulaire est obligatoire.',
      },
      policyHolderMandate: {
        required: 'Ce formulaire est obligatoire.',
        is_true: 'Ce formulaire est obligatoire.',
      },
      invoiceMethod: {
        required: 'Sélectionnez.',
      },
      mainExpiry: {
        required: 'Veuillez entrer une date.',
        mainExpiry: 'Impossible ce jour-là.',
        date_format: 'Date non valable (dd.mm.).',
      },
    },
  },
  it: {
    messages: {
      required: 'Questo è un settore obbligatorio.',
      email: 'La mail dev’essere in formato mail.',
      numeric: 'Dev’essere un numero.',
      min: (field, params) =>
        `!#Muss mindestens ${params[0]} Zeichen lang sein.`,
      max: (field, params) => `!#Darf maximal ${params[0]} Zeichen lang sein.`,
      digits: (field, params) => `!#Muss ${params[0]} Ziffern lang sein.`,
    },
    custom: {
      date: {
        required: 'Per favore inserisci una data.',
        min: 'Per favore inserisci una data.',
        isAdult: 'Vous devez avoir 18 ans au minimum.',
        date_format: '!#Bitte geben Sie ein Datum im Format dd.mm.yyyy an.',
      },
      estimatedAnnualPayroll: {
        numeric:
          'Indicare il montante senza centesimi senza separatori e senza "CHF".',
      },
      estimatedAnnualSales: {
        numeric:
          'Indicare il montante senza centesimi senza separatori e senza "CHF".',
      },
      subFieldOfLaw: {
        required: 'Seleziona un settore del diritto.',
      },
      fieldOfLaw: {
        required: 'Inserisci un fatto.',
      },
      requiredField: {
        required: 'Questo è un settore obbligatorio.',
      },
      requiredSelection: {
        required: 'Effettuare una selezione.',
      },
      gender: {
        required: 'Scegliere tra le seguenti opzioni.',
      },
      companyName: {
        required: 'Inserisci il nome della tua azienda.',
        min: 'Il nome della società deve essere lungo almeno due caratteri.',
      },
      firstName: {
        required: 'Inserisci il Suo nome.',
        min: 'Il Suo nome deve essere di almeno due caratteri.',
      },
      lastName: {
        required: 'Inserisci il Suo cognome.',
        min: 'Il Suo nome deve essere di almeno due caratteri.',
      },
      policyNumber: {
        required: 'Specificare un numero di polizza.',
        min: 'Il numero di polizza dev’essere di almeno 1 caratteri.',
      },
      street: {
        required: 'Inserisci una strada.',
        min: 'La strada deve contenere almeno due lettere.',
      },
      streetNumber: {
        required: 'Inserisci un numero civico.',
        min: 'Almeno una posizione.',
      },
      additional: {
        required: 'Inserire casella postale.',
      },
      zip: {
        required: 'Inserisci un codice postale.',
        digits: 'Un codice postale è composto da 4 numeri.',
        validate_ZIP: 'Code postal suisse non valide',
      },
      city: {
        required: 'Specificare una città.',
        min: 'Più di tre lettere.',
      },
      email: {
        required: "È prevista un'e-mail.",
        email: 'La mail dev’essere in formato mail.',
        is_email_assigned: 'Questa e-mail è già stata invitata.',
      },
      invalidEmail: {
        required: "È prevista un'e-mail.",
        email: 'La mail dev’essere in formato mail.',
        is_email_assigned: 'Questa e-mail è già stata invitata.',
      },
      invalidEmailRepeat: {
        required: "È prevista un'e-mail.",
        match_strings: '!# no match',
      },
      emailConfirmation: {
        required: "È prevista un'e-mail.",
        match_strings: '!# no match',
      },
      invalidDate: {
        required: 'Per favore inserisci una data.',
        inTheFuture: 'La data dev’essere in futuro.',
        inTheFutureGermanFormat: 'La data dev’essere in futuro.',
        inThePast: 'La data dev’essere nel passato.',
        inThePastGermanFormat: 'La data dev’essere nel passato.',
      },
      oldPassword: {
        required: 'Per modificare la password, inserire la vecchia password.',
        min: 'La password dev’essere composta da almeno 8 caratteri.',
      },
      newPassword: {
        required: 'Per modificare la password, inserisci una nuova password.',
        min: 'La password dev’essere composta da almeno 8 caratteri.',
        not_match_strings:
          'La nuova password dev’essere diversa da quella precedente.',
      },
      newPasswordRepeat: {
        required:
          'Per modificare la password, inserisci di nuovo la nuova password.',
        min: 'La nuova password dev’essere composta da almeno 8 caratteri.',
        match_strings: 'La voce deve corrispondere alla nuova password.',
      },
      iban: {
        required: "Inserire l'IBAN.",
        validate_IBAN: 'IBAN errato.',
      },
      bic: {
        required: 'Inserisci il BIC.',
        validate_BIC: 'BIC errato.',
      },
      otherInsurance: {
        required: "Inserire i dati dell'assicurazione complementare.",
      },
      missingMessageBody: {
        required: 'Inserisci un testo.',
      },
      mobileOrPhoneNumber: {
        required: 'Inserisci un numero di telefono o un numero di cellulare.',
      },
      language: {
        required: 'Inserisci una lingua.',
      },
      accountHolder: {
        required: 'Inserire il titolare di un conto.',
      },
      documentType: {
        required: 'Per favore inserisci un tipo.',
      },
      documentTitle: {
        required: 'Inserire una sorta.',
      },
      amount: {
        required: 'Inserisci un importo.',
        decimal: 'Si prega di inserire un massimo di 2 decimali.',
        min_value: 'Inserire un importo > 0.',
      },
      kind: {
        required: 'Si prega di scegliere una caratteristica.',
      },
      invoiceType: {
        required: 'Per favore inserisci un tipo.',
      },
      outcome: {
        required: 'Effettuare una selezione.',
      },
      referenceNumber: {
        required: 'Inserisci un numero di riferimento.',
      },
      missingAttachments: {
        required: 'Si prega di caricare almeno un documento.',
      },
      hourlyRate: {
        required: 'Questo è un settore obbligatorio.',
      },
      rejectionReason: {
        required: 'Effettuare una selezione.',
      },
      startOfInsurance: {
        required: 'Per favore inserisci una data.',
        atMostOneYearInFuture:
          '!#Das Datum darf max. 1 Jahr in der Zukunft liegen.',
        atMostOneWeekInPast:
          '!#Das Datum darf max. 7 Tage in der Vergangenheit liegen.',
      },
      countStreetVehicles: {
        min_value: '!#Bitte wählen Sie mindestens ein Fahrzeug aus.',
      },
      countWaterVehicles: {
        min_value: '!#Bitte wählen Sie mindestens ein Fahrzeug aus.',
      },
      agreeDataProtection: {
        required: 'Questo è un settore obbligatorio.',
        is_true: 'Questo è un settore obbligatorio.',
      },
      policyHolderMandate: {
        required: 'Questo è un settore obbligatorio.',
        is_true: 'Questo è un settore obbligatorio.',
      },
      invoiceMethod: {
        required: 'Scegliere tra le seguenti opzioni.',
      },
      mainExpiry: {
        required: 'Per favore inserisci una data.',
        mainExpiry: '!#Unmöglich an diesem Tag.',
        date_format: '!#Bitte geben Sie ein Datum im Format dd.mm. an.',
      },
    },
  },
}
