<template>
  <div ref="formHeader" class="formHeader">
    <div class="progressbar">
      <progress-indicator
        :steps-current="steps.findIndex((step) => step.id === current) + 1"
        :steps-total="steps.length"
      />
    </div>

    <div class="header">
      <h2 class="font-white formHeadline">{{ title }}</h2>
      <h3 v-if="nextStep" class="font-white formSubheadline">
        {{ t('nextStep') }}: {{ nextTitle }}
      </h3>
    </div>

    <div class="mainDescription">
      <h1>{{ t('variantName') }}</h1>
      <h2>{{ t('variantSubheadline') }}</h2>
      <p v-if="subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, computed } from '@vue/composition-api'

import ProgressIndicator from './ProgressIndicator'

import { useScipTranslationWithFallback } from '../utils'

// eslint-disable-next-line vue/require-direct-export
export default defineComponent({
  name: 'DxScipSalesHeader',
  components: {
    ProgressIndicator,
  },
  props: {
    formContext: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    current: {
      type: String,
      default: undefined,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    const { t, te } = translation

    const title = computed(() => {
      const key = `steps.${props.current}.headline`
      const step = props.steps.find(
        (candidate) => candidate.id === props.current,
      )
      return te(key) ? t(key) : step.title
    })

    const subtitle = computed(() => {
      const key = `steps.${props.current}.description.website`
      const step = props.steps.find(
        (candidate) => candidate.id === props.current,
      )
      return te(key) ? t(key) : step.subtitle
    })

    const nextStep = computed(() => {
      const currentIndex = props.steps.findIndex(
        (candidate) => candidate.id === props.current,
      )
      if (props.steps[currentIndex + 1]) {
        return props.steps[currentIndex + 1]
      }

      return null
    })

    const nextTitle = computed(() => {
      const nextStepValue = nextStep.value
      if (!nextStepValue) {
        return ''
      }
      const legacyKey = `steps.${nextStepValue.id}.headline`
      return te(legacyKey) ? t(legacyKey) : nextStepValue.title
    })

    return {
      ...translation,
      title,
      subtitle,
      nextStep,
      nextTitle,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

$font-family-old: 'Roboto', sans-serif;
$font-family-headlines: $font-family-old;
$font-family-heavy: $font-family-old;
$font-family: $font-family-old;

$magicShiftMobile: rem(60);
$magicShift: rem(80);

.formHeader {
  display: grid;
  margin: 0 rem(-20) $magicShiftMobile * -1;
  grid-row-gap: rem(32);
  grid-column-gap: rem(12);
  grid-template-columns: rem(66) 1fr;
  grid-template-rows: rem(48) auto;
  transform: translateY(#{$magicShiftMobile * -1});

  @include media-breakpoint-up(lg) {
    margin: 0 rem(-64) $magicShift * -1;
    grid-template-columns: rem(50) 1fr;
    transform: translateY(#{$magicShift * -1});
  }

  .progressbar {
    justify-self: end;
  }

  .header {
    align-self: center;

    .formHeadline,
    .formSubheadline {
      margin: 0;
    }

    .formHeadline {
      color: $color-indigo;
      font-family: $font-family-heavy;
      font-size: rem(24);
      line-height: rem(28);
    }

    .formSubheadline {
      color: $color-paynes-grey;
      font-family: $font-family;
      font-size: rem(12);
      line-height: rem(20);
    }
  }

  .mainDescription {
    padding: rem(24) rem(20) 0;
    grid-column: 1 / -1;
    background: white;

    @include media-breakpoint-up(lg) {
      padding: rem(32) rem(60) 0;
    }

    h1 {
      margin-bottom: rem(4);
      color: $color-indigo;
      font-family: $font-family-headlines;
      font-size: rem(40);
      line-height: rem(44);

      @include media-breakpoint-up(lg) {
        font-size: rem(55);
        line-height: rem(62);
      }
    }

    h2 {
      margin-bottom: 0;
      color: $color-indigo;
      font-family: $font-family-heavy;
      font-size: rem(16);
      line-height: rem(24);
    }

    p {
      margin-top: rem(39);
      margin-bottom: 0;
      color: $black-clr;
      font-family: $font-family;
      font-size: rem(20);
      line-height: rem(24);
    }
  }
}
</style>
