import Cookies from 'js-cookie'
import jwtTools from '~/javascripts/helper/jwtTools'

// We use this cookie to check login status on Dextra Website
const writeLoginCookie = (token) => {
  const expiryDate = jwtTools.getExpiryDate(token)
  Cookies.set('loginExpires', expiryDate, {
    expires: 1,
    path: '/',
    domain: process.env.DEXTRA_COOKIE_DOMAIN,
  })
}

export default writeLoginCookie
