<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <rect :width="width" :height="height" style="fill: none" />
    <path
      d="M6.7071,5.2929,12,10.585l5.2929-5.2921a1,1,0,1,1,1.4142,1.4142L13.415,12l5.2921,5.2929a1,1,0,0,1-1.4142,1.4142L12,13.415,6.7071,18.7071a1,1,0,0,1-1.4142-1.4142L10.585,12,5.2929,6.7071A1,1,0,0,1,6.7071,5.2929Z"
      transform="translate(0 0)"
      style="fill-rule: evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',

  props: {
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
  },
}
</script>
