<template><div /></template>
<script>
import claimShareMixin from '~/javascripts/mixins/claimShareMixin'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'AcceptShareRequest',
  mixins: [claimShareMixin, loadingMixin],
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/requests/accept',
      fr: '/requests/accept',
      it: '/requests/accept',
    },
  },
  mounted() {
    const query = { ...this.$route.query }
    const { token, claim_id: claimId } = query
    if (token && claimId) {
      delete query.token
      delete query.claim_id
      this.acceptShareRequest(token, parseInt(claimId, 10))
    }
    this.$router.replace({ path: '/', query }).catch((_) => {})
  },
}
</script>
