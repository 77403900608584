<template>
  <div class="row">
    <div class="col-12 col-md mb-3 mb-md-0">
      <dx-icon-box
        :route-data="{ name: claimsData.route, hash: claimsData.hash }"
        icon-type="claims"
      >
        <div v-if="claimsLoading" class="loading-box">
          <dx-loading-overlay />
        </div>
        <template v-else>
          <h4 class="mt-3">
            {{ $t('openDamages') }}
          </h4>
          <p>
            <template v-if="hasClaims">
              {{ $t('yourListOpenDamages') }}
            </template>

            <template v-else>
              {{ $t('noOpenDamages') }}
            </template>
          </p>
          <dx-button
            v-if="hasContracts"
            :small="true"
            :text="claimsData.text"
            class="d-md-inline-block my-md-4"
          />
        </template>
      </dx-icon-box>
    </div>
    <div class="col-12 col-md">
      <dx-icon-box
        :route-data="{ name: localePath('SelfService'), hash: 'policies' }"
        icon-type="policies"
      >
        <h4 class="mt-3">
          {{ $t('policiesHead') }}
        </h4>
        <p>
          {{ $t('yourPolicyList') }}
        </p>
        <dx-button
          :small="true"
          :text="$t('toPolicies')"
          class="d-md-inline-block my-md-4"
        />
      </dx-icon-box>
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxIconBox from '~/components/DX-IconBox'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'DxClaimsPoliciesBlocks',
  components: {
    DxLoadingOverlay,
    DxIconBox,
    DxButton,
  },
  mixins: [loadingMixin],
  props: {
    claimsLoading: {
      type: Boolean,
      default: true,
    },
    hasClaims: {
      type: Boolean,
      default: false,
    },
    hasContracts: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    claimsData() {
      if (this.hasClaims) {
        return {
          route: this.localePath('SelfService'),
          hash: 'claims',
          text: this.$t('toDamages'),
        }
      }
      if (this.hasContracts) {
        return {
          route: this.localePath('SelfService-ReportDamage-MyInfo'),
          hash: '',
          text: this.$t('reportDamageLegalAdvice'),
        }
      }

      return {
        route: '',
        hash: '',
        text: '',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
