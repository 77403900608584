<template>
  <div class="w-100">
    <dx-head-blue :headline="$t('myDamageOverview')" class="mb-4 mb-md-5">
      <template slot="head-top-content">
        <div class="mb-2 container-fluid">
          <dx-back-text-button
            :text="$t('back')"
            :route="localePath('PartnerPortal')"
            inverted
            class="link-back"
          />
        </div>
      </template>

      <template slot="head-content">
        <dx-loading-overlay v-if="loading" theme="light" />
        <div v-else-if="hasErrors" class="text-center">
          {{ $t('damage.accessDenied') }}
        </div>

        <div v-if="displayClaim" class="text-center">
          <p class="subheadline mb-0">
            {{ $t('policyNumber') }}: {{ claim.contract.id }}
          </p>
          <p class="subheadline mb-0">
            {{ $t('damage.number') }}: {{ claim.number }}
          </p>
          <p v-if="claim.lawyer" class="subheadline mb-0">
            {{ $t('damage.dextraLawyer') }}: {{ claim.lawyer.fullName }}
          </p>
          <p v-if="claim.externalLawyer" class="subheadline mb-0">
            {{ $t('damage.externalLaywer') }}:
            {{ claim.externalLawyer.fullName }}
          </p>
          <p class="subheadline mb-0">
            {{ $t('registerPage.headline.customer') }}:
            {{ claim.insuredPerson.fullName }}
          </p>
        </div>

        <div
          v-if="displayClaim && claim.costs && Number(claim.costs.value) > 0"
          class="content-wrap-small mt-4"
        >
          <div
            class="mx-3 mx-md-0 py-3 px-2 px-md-3 text-center damage-savings-box"
          >
            {{ $t('partnerPortal.damageManagement.dextraDamagePaid') }}
            <div class="ml-2 d-inline-DxPartnerMailboxblock">
              {{ claim.costs.currency }}
              <span class="price">{{ formatCurrency(claim.costs.value) }}</span>
            </div>
          </div>
        </div>
      </template>
    </dx-head-blue>

    <template v-if="displayClaim">
      <div
        v-if="!claimLocked"
        class="container-fluid content-wrap-medium position-relative content-bg-color notification-settings-bar"
      >
        {{ subscriptionCloseClaimText }}
        <nuxt-link
          :to="localePath('PartnerPortal-UserData')"
          class="secondary-link ml-1"
        >
          {{ $t('partnerPortal.damageManagement.edit') }}
        </nuxt-link>
      </div>
      <div class="mt-4">
        <dx-damage-communication-status
          v-if="!claimLocked"
          :email="customerEmail"
          :status="shareRequestStatus"
          :share-object-status="shareStatus"
          :on-share-request="onShareRequest"
        />
      </div>
      <div v-if="isShared" class="container-fluid mt-3 mb-4 mt-md-5">
        <div class="row">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <div class="shadow-container">
              <h4>{{ $t('damageCheck.caseDescription') }}</h4>
              <p>{{ claim.facts || $t('noContent') }}</p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="shadow-container">
              <h4>{{ $t('damageCheck.aims') }}</h4>
              <p>{{ claim.introduction || $t('noContent') }}</p>
            </div>
          </div>
        </div>
      </div>

      <dx-partner-mailbox
        v-if="isShared"
        :claim-id="claimId"
        :claim-locked="claimLocked"
      />
      <div class="content-bg-color my-md-5">
        <div class="content-wrap-medium py-md-5 px-0">
          <a id="documents" class="document-anchors" />
          <dx-loading-overlay v-if="loading" />
          <dx-document-upload
            v-if="!loading"
            :read-only="claimLocked"
            :docs-to-upload="claim.missingDocuments"
            :files-overview="sortedClaimDocuments"
            :on-upload-success="
              (fileId, doc) => addUploadedDocument(fileId, doc)
            "
            :broker-share="isShared"
            :no-documents-text="noDocumentText"
            :downloads-linkable="true"
          />
        </div>
      </div>
    </template>

    <div class="content-wrap-medium mb-5">
      <dx-loading-overlay v-if="loading" />
      <dx-chronic
        v-if="!loading && claim && claim.chronicle && claim.chronicle.length"
        :chronic-data="claim.chronicle"
      />
    </div>
  </div>
</template>

<script>
import { DxBackTextButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxChronic from '~/domains/contract/claim/components/DX-Claim/DX-Chronic'
import DxDocumentUpload from '~/domains/contract/claim/components/DX-Claim/DX-DocumentUpload'
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxDamageCommunicationStatus from '~/domains/partnerPortal/components/DX-DamageCommunicationStatus'
import DxPartnerMailbox from '~/domains/partnerPortal/components/DX-PartnerMailbox'

import uploadDocumentsMutation from '~/domains/contract/claim/__gql__/mutations/uploadDocuments.gql'
import uploadMissingDocumentsMutation from '~/domains/contract/claim/__gql__/mutations/uploadMissingDocuments.gql'
import brokerClaim from '~/domains/contract/claim/__gql__/queries/brokerClaim.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import claimShareMixin from '~/javascripts/mixins/claimShareMixin'
import { formatCurrency } from '~/javascripts/utils'

export default {
  name: 'DamageManagement',
  components: {
    DxBackTextButton,
    DxChronic,
    DxDocumentUpload,
    DxHeadBlue,
    DxLoadingOverlay,
    DxDamageCommunicationStatus,
    DxPartnerMailbox,
  },
  mixins: [claimShareMixin, loadingMixin],
  data() {
    return {
      claim: {
        contract: {},
        chronicle: [],
        documents: [],
        missingDocuments: [],
        costs: {},
        insuredPerson: {},
      },
      loading: true,
      hasErrors: false,
      formatCurrency,
    }
  },
  computed: {
    displayClaim() {
      return !this.loading && !this.hasErrors && !!this.claim
    },
    claimId() {
      return Number(this.$route.query.id)
    },
    claimLocked() {
      // claim should be read-only if its status is LOCKED or if any final, not rejected expense is present
      return this.claim && this.claim.status === 'LOCKED'
    },
    sortedClaimDocuments() {
      return this.isShared ? this.claim.documents.slice(0) : []
    },
    isShared() {
      return this.shareStatus === 'ACTIVE'
    },
    hasExternalLawyer() {
      return !!this.claim.externalLawyer
    },
    subscriptionCloseClaimText() {
      const { subscriptions } = this.$store.state.application.currentBroker
      const subscribedCloseClaim =
        subscriptions && subscriptions.includes('close_claim')
      return this.$t(
        subscribedCloseClaim
          ? 'partnerPortal.damageManagement.subscribedCloseClaim'
          : 'partnerPortal.damageManagement.notSubscribedCloseClaim',
      )
    },
    shareRequestStatus() {
      const { currentBrokerShareRequest } = this.claim
      const { status } = currentBrokerShareRequest || {}
      return status
    },
    shareStatus() {
      const { currentShare } = this.claim
      const { status } = currentShare || {}
      return status
    },
    customerEmail() {
      return this.claim.insuredPerson && this.claim.insuredPerson.email
    },
    noDocumentText() {
      if (!this.isShared) {
        if (this.claimLocked) {
          return this.$t(
            'partnerPortal.damageManagement.documentAccessDeniedClosedClaim',
          )
        }
        return this.$t('partnerPortal.damageManagement.documentAccessDenied')
      }

      return null
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash)
        if (el) {
          el.scrollIntoView()
        }
      }
    })
  },
  methods: {
    async addUploadedDocument(fileId, doc) {
      try {
        const queryVariables = {
          claimId: this.claimId,
          withMissingDocuments: true,
          locale: this.$i18n.locale.toUpperCase(),
        }
        const isFurtherDocument = doc.id === -1
        const kind = isFurtherDocument ? 'misc' : doc.kind
        await this.$apollo.mutate({
          mutation: isFurtherDocument
            ? uploadDocumentsMutation
            : uploadMissingDocumentsMutation,
          variables: {
            claimId: this.claimId,
            missingDocumentId: isFurtherDocument ? null : doc.id,
            uploadIds: isFurtherDocument ? null : [fileId],
            attachment: isFurtherDocument
              ? {
                  kind: 'misc',
                  uploadIds: [fileId],
                }
              : null,
          },
          update: (
            store,
            { data: { uploadMissingDocuments, uploadDocuments } },
          ) => {
            if (!this.isShared) {
              // Cache update not necessary if not authorized as documents are not visible
              return
            }
            const data = store.readQuery({
              query: brokerClaim,
              variables: queryVariables,
            })
            const claim = data.brokerClaim
            if (!isFurtherDocument) {
              claim.missingDocuments = claim.missingDocuments.filter(
                (d) => d.id !== doc.id,
              )
              claim.documents = [
                ...claim.documents,
                {
                  ...uploadMissingDocuments[0],
                  kind,
                },
              ]
            } else {
              claim.documents = [
                {
                  ...uploadDocuments[0],
                  kind,
                },
                ...claim.documents,
              ]
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: brokerClaim,
              variables: queryVariables,
              data,
            })
          },
        })
        this.$notification.success({ messageKey: 'dxUpload.success' })
      } catch (error) {
        this.$notification.error({ messageKey: 'dxUpload.error' })
      }
    },
    async onShareRequest() {
      const shareRequest = await this.createShareRequest(this.claimId)
      if (shareRequest) {
        this.claim.currentBrokerShareRequest = shareRequest
      }
    },
  },
  apollo: {
    claim: {
      query: brokerClaim,
      fetchPolicy: 'network-only',
      variables() {
        return {
          claimId: this.claimId,
          locale: this.$i18n.locale.toUpperCase(),
        }
      },
      update(data) {
        if (!data || !data.brokerClaim) {
          this.hasErrors = true
        }
        this.loading = false
        return data && data.brokerClaim
      },
    },
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsfallverwaltung',
      fr: '/portailpartenaires/gestion-des-dossiers',
      it: '/portaledeipartner/gestione-del-caso',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
