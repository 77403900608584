<template>
  <div :class="cssClasses()" class="tariff-wrap">
    <div
      :class="{
        [`icon--${tariff}`]: true,
        'tariff-header--spacing': theme !== 'dotted',
      }"
      class="tariff-header icon"
    >
      <p class="headline mb-0">
        {{ tariffs[tariff].name }}
      </p>
      <h2 class="subline">
        {{ tariffs[tariff].fullName }}
      </h2>
    </div>
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: 'TariffContainer',
  props: {
    tariff: {
      type: String,
      default: 'privat',
    },
    theme: {
      type: String,
      default: '',
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tariffs: {
        'p-private': {
          name: this.$t('partnerPortal.tariff.p-private.name'),
          fullName: this.$t('partnerPortal.privatLegalProtection'),
        },
        'p-move': {
          name: this.$t('partnerPortal.tariff.p-move.name'),
          fullName: this.$t('partnerPortal.trafficLawProtection'),
        },
        'p-combi': {
          name: this.$t('partnerPortal.tariff.p-combi.name'),
          fullName: this.$t('partnerPortal.combiProtection'),
        },
        'b-business': {
          name: this.$t('partnerPortal.tariff.b-business.name'),
          fullName: this.$t('partnerPortal.businessLegalProtection'),
        },
        'b-move': {
          name: this.$t('partnerPortal.tariff.b-move.name'),
          fullName: this.$t('partnerPortal.trafficLawProtectionBusiness'),
        },
        'small-business': {
          name: this.$t('partnerPortal.tariff.small-business.name'),
          fullName: this.$t('partnerPortal.smallBusinessProtection'),
        },
      },
    }
  },
  methods: {
    cssClasses() {
      return {
        'shadow-container': this.theme !== 'dotted',
        [`theme-${this.theme}`]: this.theme.length > 0,
        'sticky-price': this.isSticky,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
