export const TITLE = ['DR', 'PROF', 'PROFDR', 'LIC_IUR', 'MLAW', 'LLM']

export const JOB_TITLE = [
  'LAWYER',
  'INTERCEDER',
  'SOCIAL_INSURANCE_EXPERT',
  'TAX_EXPERT',
  'PATENT_ATTORNEY',
]

export const LANGUAGE_SKILLS = [
  'BASIC_KNOWLEDGE',
  'BASIC_KNOWLEDGE_PLUS',
  'BUSINESS_FLUENT',
  'MOTHER_TONGUE',
]

export const LANGUAGES = ['DE', 'FR', 'IT', 'EN']

export const AREA_OF_LAW_SKILL = [
  'EMPLOYMENT_LAW',
  'CONSTRUCTION_LAW',
  'REAL_ESTATE_LAW',
  'INHERITANCE_LAW',
  'FAMILY_LAW',
  'INSURANCE_LAW',
  'CRIMINAL_AND_ADMINISTRATIVE_LAW',
  'CONTRACT_LAW',
  'PROPERTY_CASUALTY_LAW',
  'TENANCY_LAW',
  'NEIGHBOURHOOD_RIGHT',
  'PATIENT_LAW',
  'SOCIAL_SECURITY_LAW',
  'TRAFFIC_LAW',
  'INTERNET_LAW',
  'TRADEMARK_DESIGN_COPYRIGHT_LAW',
  'TAX_LAW',
  'UNFAIR_COMPETITION_LAW',
  'CORPORATE_LAW',
]

export const ADDITIONAL_SKILL = [
  'MEDIATOR_SBA',
  'CONSTRUCTION_LAW_LAWYER',
  'EMPLOYMENT_LAW_LAWYER',
  'REAL_ESTATE_LAW_LAWYER',
  'INHERITANCE_LAW_LAWYER',
  'FAMILY_LAW_LAWYER',
  'INSURANCE_LAW_LAWYER',
  'CRIMINAL_LAW_LAWYER',
]

export const ADVANCED_TRAINING = [
  'CAS_EMPLOYMENT_LAW',
  'CAS_CONSTRUCTION_LAW',
  'CAS_SOCIAL_WELFARE_LAW',
  'CAS_SOCIAL_INSURANCE_LAW',
]

export const INVITATION_REJECTION_REASON = [
  'CONFLICT_OF_INTERESTS',
  'AREA_OF_LAW_NOT_SUITABLE',
  'NO_INTEREST',
  'POOR_CHANCES_OR_HOPELESSNESS',
  'NO_CAPACITY',
  'OTHER',
]

export const TARGET_INSTANCE = [
  'OUT_OF_COURT',
  'ARBITRATION',
  'FIRST_INSTANCE',
  'SECOND_INSTANCE',
  'COURT',
]

export const EXPENSE_TYPE = ['EXPERTISE', 'COURT', 'FEE', 'OTHER', 'RETAINER']

export const GM_EXPENSE_TYPE = [
  'GM_EXPERTISE',
  'GM_COURT',
  'GM_FEE',
  'GM_OTHER',
  'GM_RETAINER',
]

export const EXPENSE_INVOICE_TYPE = ['INTERIM', 'FINAL']

export const EXPENSE_OUTCOME_TYPE = [
  'BUY_OUT',
  'HOPELESSNESS',
  'COUNSEL',
  'WON',
  'IN_PART',
  'COMPARE',
  'LOST',
  'ASSUMPTION',
  'MANDATE_REVOKED',
]

export const EXPENSES_INQUIRY_STATUS = [
  'CREATED',
  'IN_REVIEW',
  'PARTIALLY_APPROVED',
  'REJECTED',
  'APPROVED',
]

export const CONTACT_LANGUAGES = ['DE', 'FR', 'IT']
