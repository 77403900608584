var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dx-select',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],staticClass:"mt-4 mb-2",attrs:{"options":_vm.makeDataMap(
        'areaOfLawSkill',
        _vm.AREA_OF_LAW_SKILL,
        _vm.localDetails.areaOfLawSkills
      ),"label":_vm.$t('lawyerPortal.jurisdiction'),"error":_vm.errors.first('requiredSelection', 'areaOfLawSkills') ||
      _vm.errors.first('areaOfLawSkillsEmpty'),"data-vv-scope":"areaOfLawSkills","data-vv-name":"requiredSelection"},on:{"input":_vm.validateAreaOfLawSkillAdd},model:{value:(_vm.areaOfLawSkill),callback:function ($$v) {_vm.areaOfLawSkill=$$v},expression:"areaOfLawSkill"}}),_vm._v(" "),_c('dx-simple-delete-list',{attrs:{"items":_vm.localDetails.areaOfLawSkills,"get-translated-label":function (skill) { return _vm.$t(("lawyerPortal.areaOfLawSkill." + skill)); },"on-delete-click":function (skill) { return _vm.updateEmploymentDetails({
          areaOfLawSkills: _vm.localDetails.areaOfLawSkills.filter(
            function (aols) { return aols !== skill; }
          ),
        }); }}}),_vm._v(" "),_c('dx-select',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],staticClass:"mt-4 mb-2",attrs:{"options":_vm.makeDataMap('jobTitles', _vm.JOB_TITLE, _vm.localDetails.jobTitles),"label":_vm.$t('lawyerPortal.jobTitle'),"error":_vm.errors.first('requiredSelection', 'jobTitles') ||
      _vm.errors.first('jobTitlesEmpty'),"data-vv-scope":"jobTitles","data-vv-name":"requiredSelection"},on:{"input":_vm.validateJobTitlesAdd},model:{value:(_vm.jobTitle),callback:function ($$v) {_vm.jobTitle=$$v},expression:"jobTitle"}}),_vm._v(" "),_c('dx-simple-delete-list',{attrs:{"items":_vm.localDetails.jobTitles,"get-translated-label":function (title) { return _vm.$t(("lawyerPortal.jobTitles." + title)); },"on-delete-click":function (title) { return _vm.updateEmploymentDetails({
          jobTitles: [].concat( _vm.localDetails.jobTitles.filter(function (jt) { return jt !== title; }) ),
        }); }}}),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-md-7 my-4 my-md-0"},[_c('dx-select',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"options":_vm.makeDataMap(
            'additionalSkill',
            _vm.ADDITIONAL_SKILL,
            _vm.localDetails.additionalSkills.map(function (as) { return as.skill; })
          ),"label":((_vm.$t('lawyerPortal.otherQualifications')) + " (optional)"),"error":_vm.errors.first('requiredSelection', 'additionalSkill'),"data-vv-scope":"additionalSkill","data-vv-name":"requiredSelection"},model:{value:(_vm.additionalSkill.skill),callback:function ($$v) {_vm.$set(_vm.additionalSkill, "skill", $$v)},expression:"additionalSkill.skill"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-5"},[_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required|inThePastGermanFormat'),expression:"'required|inThePastGermanFormat'",modifiers:{"disable":true}}],attrs:{"label":_vm.$t('lawyerPortal.sinceWhen'),"error":_vm.errors.first('invalidDate', 'additionalSkill'),"is-date-input":"","type":"date","data-vv-scope":"additionalSkill","data-vv-name":"invalidDate"},model:{value:(_vm.additionalSkill.sinceYear),callback:function ($$v) {_vm.$set(_vm.additionalSkill, "sinceYear", $$v)},expression:"additionalSkill.sinceYear"}})],1),_vm._v(" "),_c('div',{staticClass:"text-right col mt-2"},[_c('button',{staticClass:"secondary-link btn-blank",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.validateAdditionalQualificationAdd($event)}}},[_vm._v("\n        "+_vm._s(_vm.$t('lawyerPortal.addQualification'))+"\n      ")])])]),_vm._v(" "),_c('dx-simple-delete-list',{attrs:{"items":_vm.localDetails.additionalSkills,"get-translated-label":function (aSkill) { return _vm.$t(("lawyerPortal.additionalSkill." + (aSkill.skill))) +
        ' (' +
        aSkill.sinceYear +
        ')'; },"on-delete-click":function (aSkill) { return _vm.updateEmploymentDetails({
          additionalSkills: [].concat( _vm.localDetails.additionalSkills.filter(
              function (as) { return as.skill !== aSkill.skill; }
            ) ),
        }); },"item-key":"skill"}}),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-md-7 my-4 my-md-0"},[_c('dx-select',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"options":_vm.makeDataMap(
            'advancedTraining',
            _vm.ADVANCED_TRAINING,
            _vm.localDetails.advancedTrainings.map(function (at) { return at.training; })
          ),"label":((_vm.$t('lawyerPortal.advancedTrainings')) + " (optional)"),"error":_vm.errors.first('requiredSelection', 'advancedTraining'),"data-vv-scope":"advancedTraining","data-vv-name":"requiredSelection"},model:{value:(_vm.advancedTraining.training),callback:function ($$v) {_vm.$set(_vm.advancedTraining, "training", $$v)},expression:"advancedTraining.training"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-5"},[_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required|inThePastGermanFormat'),expression:"'required|inThePastGermanFormat'",modifiers:{"disable":true}}],attrs:{"label":_vm.$t('lawyerPortal.sinceWhen'),"error":_vm.errors.first('invalidDate', 'advancedTraining'),"is-date-input":"","type":"date","data-vv-scope":"advancedTraining","data-vv-name":"invalidDate"},model:{value:(_vm.advancedTraining.sinceYear),callback:function ($$v) {_vm.$set(_vm.advancedTraining, "sinceYear", $$v)},expression:"advancedTraining.sinceYear"}})],1),_vm._v(" "),_c('div',{staticClass:"text-right col mt-2"},[_c('button',{staticClass:"secondary-link btn-blank",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.validateAdvancedTrainingsAdd($event)}}},[_vm._v("\n        "+_vm._s(_vm.$t('lawyerPortal.advancedTrainingsAdd'))+"\n      ")])])]),_vm._v(" "),_c('dx-simple-delete-list',{attrs:{"items":_vm.localDetails.advancedTrainings,"get-translated-label":function (aT) { return _vm.$t(("lawyerPortal.advancedTraining." + (aT.training))) +
        ' (' +
        aT.sinceYear +
        ')'; },"on-delete-click":function (aT) { return _vm.updateEmploymentDetails({
          advancedTrainings: [].concat( _vm.localDetails.advancedTrainings.filter(
              function (at) { return at.training !== aT.training; }
            ) ),
        }); },"item-key":"training"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }