<template>
  <div v-if="!hideAllIfEmpty || items.length > 0">
    <component :is="tagType" class="mb-3 font-weight-bold">
      {{ `${headline} ${headline && itemCountInfo}` }}
    </component>

    <template v-if="items.length > 0">
      <ul :class="['neutral', { row: itemsInline }]">
        <li
          v-for="item in items.slice(0, visibleItemCount)"
          :key="item.id || item.name || item.filename"
          :class="[
            { 'col-12': itemsInline },
            { 'col-lg-4': items.length >= 3 && itemsInline },
            { 'col-md': items.length >= !3 && itemsInline },
            { 'pr-4': isDeletable(item) },
          ]"
          class="mb-3 position-relative"
        >
          <div v-if="!itemLinkable" :class="getIcon(item)" class="icon">
            {{ item.name || item.filename || item.title }}
          </div>

          <a
            v-else-if="item.onClick"
            :class="getIcon(item)"
            class="icon"
            href=""
            @click.prevent="item.onClick"
          >
            {{ item.name || item.filename || item.title }}
          </a>

          <a
            v-else
            :href="createURL(item.url)"
            :class="getIcon(item)"
            class="icon"
            :target="target"
            :rel="rel"
          >
            {{ item.name || item.filename || item.title }}
          </a>

          <a
            v-if="itemLinkable && hasPdfPreview(item)"
            class="icon small"
            href="#"
            @click.prevent="showPdfPreview(item)"
          >
            {{ $t('showPdfPreview.label') }}
          </a>

          <div v-if="isDeletable(item)" class="delete-item-wrap">
            <dx-tooltip
              :show-delete-dialog="true"
              position="left"
              @deleteItem="deleteCallback(item)"
            >
              <template slot="trigger">
                <button class="icon delete-doc-btn btn-blank" type="button">
                  <span class="visually-hidden">{{ $t('delete') }}</span>
                </button>
              </template>
            </dx-tooltip>
          </div>
        </li>
      </ul>

      <div
        v-if="toggleListItems && items.length > itemCountBreak"
        class="text-right"
      >
        <button
          class="btn-blank show-more-link plus-icon"
          @click="toggleShowAll"
        >
          {{
            items.length > visibleItemCount ? $t('showMore') : $t('showLess')
          }}
        </button>
      </div>
    </template>

    <template v-else>{{ noEntriesText || $t('noEntrys') }}</template>
  </div>
</template>

<script>
import DxTooltip from '~/components/DX-Tooltip'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import createPdfPreviewMutation from './__gql__/mutations/createPdfPreviewMutation.gql'

export default {
  name: 'DxDownloadList',
  components: {
    DxTooltip,
  },
  mixins: [loadingMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    headline: {
      type: String,
      default: '',
    },
    tagType: {
      type: String,
      default: 'p',
    },
    itemsDeletable: {
      type: Boolean,
      default: false,
    },
    hideAllIfEmpty: {
      type: Boolean,
      default: false,
    },
    deleteCallback: {
      type: Function,
      default: () => null,
    },
    itemsInline: {
      type: Boolean,
      default: false,
    },
    itemIcon: {
      type: String,
      default: 'download',
    },
    itemLinkable: {
      type: Boolean,
      default: true,
    },
    toggleListItems: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    externalUrl: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
    noEntriesText: {
      type: String,
      default: null,
    },
    claimId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showAll: false,
      itemCountBreak: 5,
    }
  },
  computed: {
    visibleItemCount() {
      return this.showAll || !this.toggleListItems
        ? this.items.length
        : this.itemCountBreak
    },
    itemCountInfo() {
      return this.items.length > this.itemCountBreak
        ? `(${this.items.length})`
        : ''
    },
    rel() {
      return this.target === '_blank' ? 'noopener' : ''
    },
  },
  methods: {
    createURL(url) {
      const uri = process.env.DEXTRA_API_URL
      return this.externalUrl ? url : `${uri}${url}`
    },
    toggleShowAll() {
      this.showAll = !this.showAll
    },
    getIcon(item) {
      const statusIcon = item.uploaded ? 'checkmark' : 'pending'
      const icon = this.showStatus ? statusIcon : this.itemIcon
      return `${icon}-icon`
    },
    isDeletable(item) {
      return this.showStatus && item.uploaded ? false : this.itemsDeletable
    },
    hasPdfPreview(item) {
      return item.pdfPreview && this.claimId
    },
    async showPdfPreview(item) {
      try {
        this.setGlobalLoading(true)

        const { data } = await this.$apollo.mutate({
          mutation: createPdfPreviewMutation,
          variables: {
            documentId: Number(item.id),
            claimId: Number(this.claimId),
          },
        })

        if (data && data.createPdfPreview && data.createPdfPreview.url) {
          const api = process.env.DEXTRA_API_URL
          window.open(`${api}${data.createPdfPreview.url}`, '_blank')
        } else {
          this.$notification.error({ messageKey: 'showPdfPreview.error' })
        }
      } catch (e) {
        this.$notification.error({ messageKey: 'showPdfPreview.error' })
      } finally {
        this.setGlobalLoading(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
