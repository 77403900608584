<template>
  <div
    :class="{ 'no-focus-outline': !isTab }"
    @keydown.tab="keydownTab"
    @keyup.esc="keyEscTabbing"
  >
    <dx-navigation :account-subnav-data="navData" />
    <dx-notifications />
    <div class="main-wrap">
      <nuxt />
    </div>
    <dx-footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DxNavigation from '~/components/DX-Navigation'
import DxFooter from '~/components/DX-Footer'
import { keyCodes } from '~/javascripts/utils'
import { handleScipSalesPreview } from '~/javascripts/scipSales'

export default {
  name: 'Default',
  components: {
    DxNavigation,
    DxFooter,
  },
  data() {
    return {
      isTab: false,
    }
  },
  head() {
    return {
      title: this.$t('pageTitle'),
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.application.loading,
      termsOfServiceAccepted: (state) =>
        state.application.currentLawyer &&
        state.application.currentLawyer.termsOfServiceAccepted,
    }),
    navData() {
      // LAWYER
      if (this.$store.getters.isExternalLawyer) {
        const accountNavigation = {
          path: this.localePath('LawyerPortal'),
          name: this.$t('lawyerPortal.myLawyerPortal'),
          subNav: [
            {
              path: this.localePath('LawyerPortal'),
              name: this.$t('toMainPage'),
              hash: '',
            },
            {
              path: this.localePath('LawyerPortal-UserData'),
              name: this.$t('lawyerPortal.myUserData'),
              hash: '',
            },
          ],
        }
        if (this.termsOfServiceAccepted) {
          accountNavigation.subNav.push({
            path: this.localePath('LawyerPortal-TermsOfService'),
            name: this.$t('lawyerPortal.termsOfService'),
            hash: '',
          })
        }
        return [accountNavigation]
      }

      // BROKER
      if (this.$store.getters.isBroker) {
        const accountNavigation = {
          path: this.localePath('PartnerPortal'),
          name: this.$t('partnerPortal.myPortal'),
          subNav: [
            {
              path: this.localePath('PartnerPortal'),
              name: this.$t('toMainPage'),
              hash: '',
            },
            {
              path: this.localePath('PartnerPortal-UserData'),
              name: this.$t('userData.userDataHeading'),
              hash: '',
            },
          ],
        }
        return [accountNavigation]
      }

      // SELFSERVICE
      const accountNavigation = {
        path: this.localePath('SelfService'),
        name: this.$t('mySelfService'),
        subNav: [
          {
            path: this.localePath('SelfService'),
            name: this.$t('toMainPage'),
            hash: '',
          },
          {
            path: this.localePath('SelfService-UserData'),
            name: this.$t('userData.userDataHeading'),
            hash: '',
          },
          {
            path: this.localePath('SelfService'),
            name: this.$t('policies.headline'),
            hash: '#policies',
          },
        ],
      }
      return [accountNavigation]
    },
  },
  watch: {
    '$i18n.locale': {
      async handler() {
        await this.$apolloProvider.clients.cms.reFetchObservableQueries()
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('keyup', (event) => {
      this.keyupHandler(event)
    })

    window.addEventListener('click', (_event) => {
      this.$publish('body-clicked')
    })

    handleScipSalesPreview(this.$route)
  },
  methods: {
    keyupHandler(event) {
      if (event.keyCode === keyCodes.esc) {
        this.$publish('key-ESC')
      }
    },
    keydownTab() {
      this.isTab = true
    },
    keyEscTabbing() {
      this.isTab = false
    },
  },
}
</script>
