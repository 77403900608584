<template>
  <svg
    :height="height"
    viewBox="0 0 24 24"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="m0 0h24v24h-24z" />
      <path
        d="m19.7781409 4.22182684c4.2957612 4.29576127 4.2957612 11.26058906-.0000037 15.55630996-4.2957649 4.2957649-11.26059263 4.2957649-15.55631353 0-4.29576489-4.2957649-4.29576489-11.26059269 0-15.55631358 4.2957649-4.2957649 11.26059263-4.2957649 15.55631723.00000362zm-14.14210729 1.41421356c-3.51471148 3.51467549-3.51471148 9.2131677.00000725 12.7278864 3.51467548 3.5147115 9.21316774 3.5147115 12.72788644-.0000072 3.5147115-3.5146755 3.5147115-9.21316771-.0000036-12.72788282-3.5146791-3.5147151-9.21317136-3.5147151-12.72789009.00000362zm6.36389689 9.3639596c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1c-.5522848 0-1-.4477153-1-1s.4477152-1 1-1zm0-8c.5522847 0 1 .44771525 1 1v4c0 .5522847-.4477153 1-1 1-.5522848 0-1-.4477153-1-1v-4c0-.55228475.4477152-1 1-1z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AlertCircleIcon',

  props: {
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
  },
}
</script>
