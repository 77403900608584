<template>
  <dx-new-password-form />
</template>

<script>
import DxNewPasswordForm from '~/domains/user/components/DX-NewPasswordForm'

export default {
  name: 'NewPassword',
  meta: {
    isPublic: true,
  },
  components: {
    DxNewPasswordForm,
  },
  layout: 'modulaStylePlain',
  nuxtI18n: {
    paths: {
      de: '/passwort-festlegen',
      fr: '/setmotdepasse',
      it: '/impostare-password',
    },
  },
}
</script>
