<template>
  <dx-loading-overlay v-if="loading" />
  <div v-else class="w-100">
    <dx-head-blue :headline="$t('damage.term')" class="mb-0">
      <template slot="head-top-content">
        <div class="mb-2 container-fluid">
          <dx-back-text-button
            :text="$t('back')"
            :route="localePath('LawyerPortal')"
            inverted
            class="link-back"
          />
        </div>
      </template>
      <template slot="head-content">
        <div v-if="hasErrors" class="text-center">
          {{ $t('damage.accessDenied') }}
        </div>

        <div v-if="!hasErrors" class="text-center">
          <p class="subheadline mb-0">
            {{ $t('damage.number') }}: {{ claim.number }}
          </p>
          <p class="subheadline">
            {{ getNameWithSalutation(claim.insuredPerson) }}
          </p>
        </div>
      </template>
    </dx-head-blue>

    <div v-if="!hasErrors" class="container-fluid">
      <dx-loading-overlay v-if="messagesDextraLoading || messagesIPLoading" />
      <dx-lawyer-claim-messages
        v-else
        :external-lawyer-dextra-messages="externalLawyerDextraMessages"
        :external-lawyer-insured-person-messages="
          externalLawyerInsuredPersonMessages
        "
        :claim-id="claim.id"
        class="row my-4 py-md-3"
      />
    </div>

    <!-- DATA AND FACTS -->
    <div v-if="!hasErrors" class="content-bg-color">
      <div class="container-fluid content-pad content-wrap-medium">
        <div class="row">
          <div class="col">
            <h3 class="mb-5">
              {{ $t('damage.facts') }}
            </h3>
          </div>
        </div>

        <dx-lawyer-claim-data :claim="claim" />

        <div v-if="claim.externalLawyerNote" class="row mb-4">
          <div class="col">
            <div class="content-block">
              <h4>{{ $t('damageCheck.comments') }}</h4>
              <p>{{ claim.externalLawyerNote || $t('noContent') }}</p>
            </div>
          </div>
        </div>

        <dx-party-counterparty :claim="claim" />

        <div class="row mb-4">
          <div class="col">
            <div class="content-block">
              <h4>{{ $t('damageCheck.caseDescription') }}</h4>
              <p>{{ claim.facts || $t('noContent') }}</p>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <div class="content-block">
              <h4>{{ $t('damageCheck.aims') }}</h4>
              <p>{{ claim.introduction || $t('noContent') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DOCUMENTS -->
    <div
      v-if="!hasErrors"
      class="container-fluid content-pad content-wrap-medium"
    >
      <div v-if="isCurrentlyAssigned" class="row mb-5">
        <a id="documents" class="document-anchors" />
        <div class="col">
          <dx-loading-overlay v-if="documentsLoading" />
          <dx-lawyer-claim-documents
            v-else
            :read-only="claimLocked"
            :documents="claim.documents"
            :missing-documents="claim.missingDocuments"
            :area-of-law="claim.areaOfLaw"
            :subarea-of-law="claim.subareaOfLaw"
            :remove-document="removeDocument"
            :create-missing-document="createMissingDocument"
            :request-missing-documents="sendMissingDocumentRequest"
            :remove-missing-document="deleteMissingDocumentRequest"
            :vp-has-login="claim.insuredPerson.login"
            :save-documents="saveDocuments"
            :claim-id="claim.id"
          />
        </div>
      </div>

      <!-- MAILBOX DEXTRA -->
      <a id="dextraMails" class="mailbox-anchors" />
      <div class="row mb-5 pb-4">
        <div class="col">
          <h3
            class="position-relative mb-3"
            @click="() => (inboxDextraOpen = !inboxDextraOpen)"
          >
            <span
              :class="{ 'mailbox-arrow--active': inboxDextraOpen }"
              class="mailbox-arrow"
            />
            {{ $t('lawyerPortal.messagesFromDextra') }}
          </h3>
          <template v-if="inboxDextraOpen">
            <dx-mailbox
              :mail-data="externalLawyerDextraMessages"
              :send-new-message="
                (messageText) => sendMessage(messageText, 'DEXTRA')
              "
              :reply="
                (item, messageText) =>
                  replyWithMessage(item, messageText, 'DEXTRA')
              "
              :on-unread-item-click="markAsRead"
              :show-status-flag="false"
            />
          </template>
          <template v-else>
            <div v-if="!messagesDextraLoading">
              {{
                Array.isArray(externalLawyerDextraMessages) &&
                externalLawyerDextraMessages.length > 0
                  ? $t('noUnreadMails')
                  : $t('noMails')
              }}
            </div>
          </template>
        </div>
      </div>

      <!-- MAILBOX CUSTOMER -->
      <a id="customerMails" class="mailbox-anchors" />
      <div class="row pb-5 mb-2">
        <div class="col">
          <h3
            class="position-relative mb-3"
            @click="() => (inboxInsuredPersonOpen = !inboxInsuredPersonOpen)"
          >
            <span
              :class="{ 'mailbox-arrow--active': inboxInsuredPersonOpen }"
              class="mailbox-arrow"
            />
            {{ $t('lawyerPortal.messagesFromCustomer') }}
          </h3>
          <template v-if="inboxInsuredPersonOpen">
            <dx-mailbox
              :mail-data="externalLawyerInsuredPersonMessages"
              :read-only="!canSendMessagesToIP"
              :send-new-message="
                (messageText) => sendMessage(messageText, 'IP')
              "
              :reply="
                (item, messageText) => replyWithMessage(item, messageText, 'IP')
              "
              :on-unread-item-click="markAsRead"
            />
          </template>
          <template v-else>
            <div v-if="!messagesIPLoading">
              {{
                Array.isArray(externalLawyerInsuredPersonMessages) &&
                externalLawyerInsuredPersonMessages.length > 0
                  ? $t('noUnreadMails')
                  : $t('noMails')
              }}
            </div>
          </template>
        </div>
      </div>

      <!-- FURTHER SECTION -->
      <div class="row mb-4">
        <div class="col">
          <h3>{{ $t('damage.more') }}</h3>
        </div>
      </div>

      <!-- NOTES -->
      <div class="row mb-5 pb-2">
        <div class="col">
          <h4 class="icon icon--notes">
            {{ $t('damage.notesHeading') }}
          </h4>
          <div class="alert alert-info mb-3 mt-2">
            {{ $t('lawyerPortal.internalNote.hint') }}
          </div>
          <dx-lawyer-claim-notes
            :notes="claim.privateNotes"
            :add-private-note="addPrivateNote"
            :delete-private-note="deletePrivateNote"
            :save-private-note="savePrivateNote"
            :read-only="claimLocked"
          />
        </div>
      </div>

      <!-- APPEALS -->
      <div class="row mb-5 pb-2">
        <div class="col">
          <h4 class="icon icon--appeals">
            {{ $t('damage.appealsHeading') }}
          </h4>
          <dx-lawyer-claim-appeals
            :target-instance-requests="claim.targetInstanceRequests"
            :current-target-instance="claim.targetInstance"
            :create-target-instance-request="createTargetInstanceRequest"
            :read-only="claimLocked"
          />
        </div>
      </div>

      <!-- CLOSURES -->
      <div class="row">
        <div class="col">
          <h4 class="icon icon--closures">
            {{ $t('damage.closuresHeading') }}
          </h4>
          <dx-lawyer-claim-closures
            :read-only="claimLocked && hasOpenFinalClosure"
            :save-closure="saveClosure"
            :is-gm="claim.contract.insuranceCompany == 'GROUPE_MUTUEL'"
            :expenses-inquiries="claim.expensesInquiries"
            :rate-limit="Number.parseFloat(claim.paymentLimit.value)"
            :allow-only-final-closure="claimLocked && !hasOpenFinalClosure"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxBackTextButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxMailbox from '~/partials/DX-Mailbox'

import DxPartyCounterparty from '~/domains/contract/claim/components/DX-Claim/Dx-PartyCounterParty'

import claimLawyerQuery from '~/domains/externalLawyer/__gql__/queries/claimLawyer.gql'
import deleteDocumentMutation from '~/domains/externalLawyer/__gql__/mutations/deleteDocument.gql'
import createMissingDocumentMutation from '~/domains/externalLawyer/__gql__/mutations/createMissingDocument.gql'
import sendMissingDocumentRequestMutation from '~/domains/externalLawyer/__gql__/mutations/sendMissingDocumentRequest.gql'
import deleteMissingDocumentRequestMutation from '~/domains/externalLawyer/__gql__/mutations/deleteMissingDocumentRequest.gql'
import uploadDocumentsMutation from '~/domains/contract/claim/__gql__/mutations/uploadDocuments.gql'
import createPrivateNoteMutation from '~/domains/externalLawyer/__gql__/mutations/createPrivateNote.gql'
import deletePrivateNoteMutation from '~/domains/externalLawyer/__gql__/mutations/deletePrivateNote.gql'
import updatePrivateNoteMutation from '~/domains/externalLawyer/__gql__/mutations/updatePrivateNote.gql'
import createTargetInstanceRequestMutation from '~/domains/externalLawyer/__gql__/mutations/createTargetInstanceRequest.gql'
import createExpensesInquiryMutation from '~/domains/externalLawyer/__gql__/mutations/createExpensesInquiry.gql'
import externalLawyerInsuredPersonMessagesQuery from '~/domains/externalLawyer/__gql__/queries/commentsInsuredPerson.gql'
import externalLawyerDextraMessagesQuery from '~/domains/externalLawyer/__gql__/queries/commentsDextra.gql'
import markCommentAsRead from '~/domains/contract/claim/__gql__/mutations/markCommentAsRead.gql'
import createExternalLawyerDextraMessagesMutation from '~/domains/externalLawyer/__gql__/mutations/createExternalLawyerDextraMessage.gql'
import createExternalLawyerInsuredPersonMessagesMutation from '~/domains/externalLawyer/__gql__/mutations/createExternalLawyerInsuredPersonMessage.gql'
import replyMessageMutation from '~/domains/contract/claim/__gql__/mutations/replyMessage.gql'

import loadingMixin from '~/javascripts/mixins/loadingMixin'
import personMixin from '~/javascripts/mixins/personMixin'
import DxLawyerClaimClosures from './DX-LawyerClaimClosures'
import DxLawyerClaimNotes from './DX-LawyerClaimNotes'
import DxLawyerClaimAppeals from './DX-LayerClaimAppeals'
import DxLawyerClaimDocuments from './DX-LawyerClaimDocuments'
import DxLawyerClaimData from './DX-LayerClaimData'
import DxLawyerClaimMessages from './DX-LayerClaimMessages'

export default {
  name: 'DxLawyerClaim',
  components: {
    DxLoadingOverlay,
    DxHeadBlue,
    DxLawyerClaimMessages,
    DxLawyerClaimData,
    DxPartyCounterparty,
    DxLawyerClaimDocuments,
    DxLawyerClaimAppeals,
    DxLawyerClaimNotes,
    DxLawyerClaimClosures,
    DxMailbox,
    DxBackTextButton,
  },
  mixins: [loadingMixin, personMixin],
  props: {
    claimId: {
      type: [String, Number],
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      documentsLoading: false,
      claim: {
        areaOfLaw: '',
        insuredPerson: {
          address: {},
        },
        policyholder: {
          address: {},
        },
        counterparty: {
          address: {},
        },
        documents: [],
        hourlyRate: {
          value: null,
        },
        lawyer: {},
        externalLawyer: {},
        privateNotes: [],
        targetInstanceRequests: [],
        expensesInquiries: [],
      },
      inboxDextraOpen: true,
      inboxInsuredPersonOpen: true,
      messagesDextraLoading: true,
      messagesIPLoading: true,
      hasErrors: false,
    }
  },
  computed: {
    claimLocked() {
      // claim should be read-only if its status is LOCKED or if any final, not rejected expense is present
      if (this.claim) {
        return (
          !this.isCurrentlyAssigned ||
          this.claim.status === 'LOCKED' ||
          this.hasOpenFinalClosure
        )
      }

      return true
    },
    isCurrentlyAssigned() {
      return (
        this.claim &&
        this.claim.externalLawyer &&
        this.claim.externalLawyer.id ===
          this.$store.state.application.currentLawyer.id
      )
    },

    canSendMessagesToIP() {
      return (
        !this.claimLocked &&
        this.claim &&
        this.claim.insuredPerson &&
        this.claim.insuredPerson.login
      )
    },

    hasOpenFinalClosure() {
      return this.claim.expensesInquiries.some(
        (i) =>
          i.invoiceType === 'FINAL' &&
          i.status !== 'REJECTED' &&
          i.status !== 'REVERTED',
      )
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash)
        if (el) {
          el.scrollIntoView()
        }
      }
    })
  },
  methods: {
    async removeDocument(document) {
      try {
        this.documentsLoading = true

        // remove document
        await this.$apollo.mutate({
          mutation: deleteDocumentMutation,
          variables: {
            claimId: Number(this.claimId),
            documentId: Number(document.id),
          },
        })

        // update lawyer claim
        const claimQueryVariables = {
          claimId: Number(this.claimId),
          withDetails: true,
        }
        const data = this.$apollo.provider.defaultClient.readQuery({
          query: claimLawyerQuery,
          variables: claimQueryVariables,
        })
        data.lawyerClaim.documents = data.lawyerClaim.documents.filter(
          (d) => d.id !== document.id,
        )
        this.$apollo.provider.defaultClient.writeQuery({
          query: claimLawyerQuery,
          variables: claimQueryVariables,
          data,
        })
        // update local claim
        this.claim.documents = [
          ...this.claim.documents.filter((d) => d.id !== document.id),
        ]

        this.$notification.success({
          messageKey: 'lawyerPortal.removeDocument.success',
        })
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.removeDocument.error',
        })
        throw error
      } finally {
        this.documentsLoading = false
      }
    },
    async createMissingDocument(doc) {
      try {
        this.setGlobalLoading(true)
        const missingDocument =
          await this.$apollo.provider.defaultClient.mutate({
            mutation: createMissingDocumentMutation,
            variables: {
              claimId: Number(this.claimId),
              missingDocument: {
                kind: doc.type,
                title: doc.title,
              },
            },
          })
        this.claim.missingDocuments = [
          ...this.claim.missingDocuments,
          missingDocument.data.createMissingDocument,
        ]
        this.$notification.success({
          messageKey: 'lawyerPortal.addMissingDocument.success',
        })
        return true
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.addMissingDocument.error',
        })
        throw error
      } finally {
        this.setGlobalLoading(false)
      }
    },

    async sendMissingDocumentRequest() {
      try {
        this.setGlobalLoading(true)
        const {
          data: { sendMissingDocumentRequest: success },
        } = await this.$apollo.provider.defaultClient.mutate({
          mutation: sendMissingDocumentRequestMutation,
          variables: {
            claimId: Number(this.claimId),
          },
        })

        if (success) {
          this.$notification.success({
            messageKey: 'lawyerPortal.requestMissingDocuments.success',
          })
        } else {
          this.$notification.error({
            messageKey: 'lawyerPortal.requestMissingDocuments.error',
          })
        }
        return success
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.requestMissingDocuments.error',
        })
        throw error
      } finally {
        this.setGlobalLoading(false)
      }
    },

    async deleteMissingDocumentRequest({ id }) {
      try {
        this.setGlobalLoading(true)
        const {
          data: { deleteMissingDocument },
        } = await this.$apollo.provider.defaultClient.mutate({
          mutation: deleteMissingDocumentRequestMutation,
          variables: {
            claimId: Number(this.claimId),
            missingDocumentId: id,
          },
        })
        if (deleteMissingDocument) {
          this.$apollo.queries.lawyerClaim.refetch()
          this.$notification.success({
            messageKey: 'lawyerPortal.removeDocument.success',
          })
        } else {
          this.$notification.error({
            messageKey: 'lawyerPortal.removeDocument.error',
          })
        }
        return deleteMissingDocument
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.removeDocument.error',
        })
        throw error
      } finally {
        this.setGlobalLoading(false)
      }
    },

    async saveDocuments(docArray) {
      // update lawyer claim
      const claimQueryVariables = {
        claimId: Number(this.claimId),
        withDetails: true,
      }
      try {
        await Promise.all(
          docArray.map(async (doc) => {
            await this.$apollo.mutate({
              mutation: uploadDocumentsMutation,
              variables: {
                claimId: Number(this.claimId),
                attachment: {
                  kind: doc.kind,
                  uploadIds: [doc.id],
                },
              },
              update: (store, { data: { uploadDocuments } }) => {
                const data = store.readQuery({
                  query: claimLawyerQuery,
                  variables: claimQueryVariables,
                })
                data.lawyerClaim.documents = [
                  { ...uploadDocuments[0] },
                  ...data.lawyerClaim.documents,
                ]
                // Write our data back to the cache.
                store.writeQuery({
                  query: claimLawyerQuery,
                  variables: claimQueryVariables,
                  data,
                })
              },
            })
          }),
        )
        this.$notification.success({ messageKey: 'savedDocs' })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async addPrivateNote(note) {
      this.setGlobalLoading(true)
      try {
        // update lawyer claim
        const claimQueryVariables = {
          claimId: Number(this.claimId),
          withDetails: true,
        }
        await this.$apollo.mutate({
          mutation: createPrivateNoteMutation,
          variables: {
            claimId: Number(this.claimId),
            privateNote: {
              text: note,
            },
          },
          update: (store, { data: { createPrivateNote } }) => {
            const data = store.readQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
            })
            data.lawyerClaim.privateNotes = [
              { ...createPrivateNote },
              ...data.lawyerClaim.privateNotes,
            ]
            // Write our data back to the cache.
            store.writeQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
              data,
            })
          },
        })
        this.$notification.success({
          messageKey: 'lawyerPortal.internalNote.addSuccess',
        })
        return true
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.internalNote.addError',
        })
        return false
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async deletePrivateNote(privateNoteId) {
      this.setGlobalLoading(true)
      try {
        // update lawyer claim
        const claimQueryVariables = {
          claimId: Number(this.claimId),
          withDetails: true,
        }
        await this.$apollo.mutate({
          mutation: deletePrivateNoteMutation,
          variables: {
            claimId: Number(this.claimId),
            privateNoteId,
          },
          update: (store) => {
            const data = store.readQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
            })
            data.lawyerClaim.privateNotes = [
              ...data.lawyerClaim.privateNotes.filter(
                (pn) => pn.id !== privateNoteId,
              ),
            ]
            // Write our data back to the cache.
            store.writeQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
              data,
            })
          },
        })
        this.$notification.success({
          messageKey: 'lawyerPortal.internalNote.deleteSuccess',
        })
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.internalNote.deleteError',
        })
        throw error
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async savePrivateNote(note) {
      this.setGlobalLoading(true)
      try {
        // update lawyer claim
        const claimQueryVariables = {
          claimId: Number(this.claimId),
          withDetails: true,
        }
        await this.$apollo.mutate({
          mutation: updatePrivateNoteMutation,
          variables: {
            claimId: Number(this.claimId),
            privateNote: {
              id: note.id,
              text: note.newText,
            },
          },
          update: (store, { data: { updatePrivateNote } }) => {
            const data = store.readQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
            })
            const index = data.lawyerClaim.privateNotes.findIndex(
              (n) => n.id === updatePrivateNote.id,
            )
            data.lawyerClaim.privateNotes[index] = { ...updatePrivateNote }
            // Write our data back to the cache.
            store.writeQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
              data,
            })
          },
        })
        this.$notification.success({
          messageKey: 'lawyerPortal.internalNote.addSuccess',
        })
        return true
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.internalNote.addError',
        })
        console.error(error)
        return false
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async createTargetInstanceRequest(newInstance) {
      this.setGlobalLoading(true)
      const claimQueryVariables = {
        claimId: Number(this.claimId),
        withDetails: true,
      }
      try {
        const variables = {
          claimId: Number(this.claimId),
          targetInstanceRequest: {
            ...newInstance,
            previousTargetInstance: this.claim.targetInstance,
          },
        }
        await this.$apollo.mutate({
          mutation: createTargetInstanceRequestMutation,
          variables,
          update: (store, { data: { createTargetInstanceRequest } }) => {
            const data = store.readQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
            })
            data.lawyerClaim.targetInstanceRequests = [
              { ...createTargetInstanceRequest },
              ...data.lawyerClaim.targetInstanceRequests,
            ]
            // Write our data back to the cache.
            store.writeQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
              data,
            })
            this.claim.targetInstanceRequests = [
              ...data.lawyerClaim.targetInstanceRequests,
            ]
            this.$notification.success({
              messageKey: 'lawyerPortal.targetInstanceAdd.success',
            })
          },
        })
        return true
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.targetInstanceAdd.error',
        })
        return false
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async saveClosure(closure) {
      this.setGlobalLoading(true)
      const claimQueryVariables = {
        claimId: Number(this.claimId),
        withDetails: true,
      }
      try {
        const variables = {
          claimId: Number(this.claimId),
          expensesInquiry: {
            ...closure,
            amount: {
              ...closure.amount,
              value: Number.parseFloat(closure.amount.value),
            },
          },
        }
        await this.$apollo.mutate({
          mutation: createExpensesInquiryMutation,
          variables,
          update: (store, { data: { createExpensesInquiry } }) => {
            const data = store.readQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
            })
            data.lawyerClaim.expensesInquiries = [
              { ...createExpensesInquiry },
              ...data.lawyerClaim.expensesInquiries,
            ]
            // Write our data back to the cache.
            store.writeQuery({
              query: claimLawyerQuery,
              variables: claimQueryVariables,
              data,
            })
            this.claim.expensesInquiries = [
              ...data.lawyerClaim.expensesInquiries,
            ]
            if (closure.invoiceType === 'FINAL') {
              this.$notification.success({
                messageKey: 'lawyerPortal.expensesInquiriesAdd.successFinal',
              })
            } else {
              this.$notification.success({
                messageKey: 'lawyerPortal.expensesInquiriesAdd.success',
              })
            }
          },
        })
        return true
      } catch (error) {
        this.$notification.error({
          messageKey: 'lawyerPortal.expensesInquiriesAdd.error',
        })
        return false
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async markAsRead(comment) {
      try {
        await this.$apollo.mutate({
          mutation: markCommentAsRead,
          variables: {
            commentId: Number(comment.id),
            claimId: Number(comment.claim_id),
          },
        })
      } catch (error) {
        console.error(error)
      }
    },
    async sendMessage(messageText, type) {
      const variables = {
        claimId: Number(this.claimId),
        message: {
          text: messageText,
        },
      }
      const isDextraMessage = type === 'DEXTRA'
      const result = await this.$apollo.mutate({
        mutation: isDextraMessage
          ? createExternalLawyerDextraMessagesMutation
          : createExternalLawyerInsuredPersonMessagesMutation,
        variables,
      })
      const {
        createExternalLawyerDextraMessage,
        createExternalLawyerInsuredPersonMessage,
      } = result.data
      const newMessage = isDextraMessage
        ? createExternalLawyerDextraMessage
        : createExternalLawyerInsuredPersonMessage
      this.addMessageToCache(newMessage, type)
    },
    async replyWithMessage(item, newMessageText, type) {
      const variables = {
        claimId: Number(item.claim_id),
        messageId: item.id,
        message: {
          text: newMessageText,
        },
        withClaim: true,
      }
      const result = await this.$apollo.mutate({
        mutation: replyMessageMutation,
        variables,
      })
      const newMessage = result.data.replyMessage
      this.addMessageToCache(newMessage, type)
      return true
    },
    addMessageToCache(message, type) {
      const isDextraMessage = type === 'DEXTRA'
      const variables = {
        claimId: Number(this.claimId),
        withDetails: true,
      }
      const query = isDextraMessage
        ? externalLawyerDextraMessagesQuery
        : externalLawyerInsuredPersonMessagesQuery
      const data = this.$apollo.provider.defaultClient.readQuery({
        query,
        variables,
      })
      if (isDextraMessage) {
        data.externalLawyerDextraMessages = [
          { ...message },
          ...data.externalLawyerDextraMessages,
        ]
      } else {
        data.externalLawyerInsuredPersonMessages = [
          { ...message },
          ...data.externalLawyerInsuredPersonMessages,
        ]
      }
      this.$apollo.provider.defaultClient.writeQuery({ query, data, variables })
    },
  },
  apollo: {
    lawyerClaim: {
      query: claimLawyerQuery,
      variables() {
        return {
          claimId: Number(this.claimId),
          withDetails: true,
          receivedOnly: true,
          filter: 'UNREAD',
        }
      },
      result({ data }) {
        if (data && data.lawyerClaim) {
          this.claim = {
            ...data.lawyerClaim,
            documents: [...data.lawyerClaim.documents],
            missingDocuments: [...data.lawyerClaim.missingDocuments],
            lawyer: { ...data.lawyerClaim.lawyer },
            privateNotes: [
              ...data.lawyerClaim.privateNotes.map((n) => ({
                ...n,
                newText: n.text,
              })),
            ],
          }
        }

        this.loading = false
      },
      error() {
        this.hasErrors = true
        this.loading = false
      },
    },
    externalLawyerInsuredPersonMessages: {
      query: externalLawyerInsuredPersonMessagesQuery,
      variables() {
        return {
          claimId: Number(this.claimId),
          withDetails: true,
        }
      },
      result() {
        this.messagesIPLoading = false
      },
      error() {
        this.hasErrors = true
        this.messagesIPLoading = false
      },
    },
    externalLawyerDextraMessages: {
      query: externalLawyerDextraMessagesQuery,
      variables() {
        return {
          claimId: Number(this.claimId),
          withDetails: true,
        }
      },
      result() {
        this.messagesDextraLoading = false
      },
      error() {
        this.hasErrors = true
        this.messagesDextraLoading = false
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
