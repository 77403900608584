<template>
  <dx-accordion
    :remove-desktop-accordion="true"
    wrapper-classes="transparent-dt transparent-mb-content"
  >
    <template slot="headline">
      <h3 class="mb-0">
        {{ $t('documents') }}
      </h3>
    </template>

    <template slot="content">
      <div class="row justify-content-between pt-3">
        <div v-if="!readOnly" class="col-12 col-md-6 mb-4 mb-md-0">
          <h5 class="font-weight-bold mb-2">
            {{ $t('requestedDocs') }}
          </h5>
          <div v-if="docsToUpload.length > 0">
            <p class="mb-4">
              {{ $t('file.sizeLimit') }}
            </p>
            <p class="mb-4">
              {{ $t('docUploadSub') }}
            </p>
            <dx-upload
              v-for="doc in docsToUpload"
              :key="doc.id"
              :label="doc.title"
              :on-upload-success="(file) => onUploadSuccess(file.id, doc)"
              :on-upload-remove="(id) => onUploadRemove(id, doc)"
              class="mb-4"
              theme="light"
              :remove-cancel-button="true"
              :bold-label="true"
            />
          </div>
          <template v-else>
            {{ noDocumentsText || $t('noEntrys') }}
          </template>
        </div>
        <div class="col-12 col-md-5 mb-4 mb-md-0">
          <div class="mb-4">
            <dx-download-list
              :headline="$t('uploadedDocs')"
              :items="filesOverview"
              tag-type="h5"
              :toggle-list-items="true"
              :no-entries-text="noDocumentsText"
              :item-linkable="downloadsLinkable"
              :claim-id="claimId"
            />
          </div>

          <div v-if="!readOnly" class="mt-5">
            <dx-upload-docs-multi
              :doc-types="docsToUpload"
              :on-upload-success="
                (uploadFile) => onUploadSuccess(uploadFile.id, { id: -1 })
              "
              :init-comp-amount="0"
              :max-comps="1"
              theme="light"
            />
          </div>
        </div>
      </div>
    </template>
  </dx-accordion>
</template>

<script>
import { DxAccordion } from '@sumcumo/dextra-frontend-component-lib-old'
import DxUploadDocsMulti from '~/components/DX-Upload/DX-UploadDocsMulti'
import DxUpload from '~/components/DX-Upload'
import DxDownloadList from '~/components/DX-DownloadList'
import { documentTypeMap } from '~/domains/contract/claim/constants'

export default {
  name: 'DxDocumentUpload',
  components: {
    DxAccordion,
    DxUploadDocsMulti,
    DxUpload,
    DxDownloadList,
  },
  props: {
    docsToUpload: {
      type: Array,
      default() {
        return []
      },
    },
    filesOverview: {
      type: Array,
      default() {
        return []
      },
    },
    onUploadSuccess: {
      type: Function,
      default: () => true,
    },
    onUploadRemove: {
      type: Function,
      default: () => true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    noDocumentsText: {
      type: String,
      default: null,
    },
    downloadsLinkable: {
      type: Boolean,
      default: true,
    },
    claimId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    fileKinds() {
      const kinds = this.filesOverview.map((f) => f.kind)
      return [...new Set([...kinds])]
    },
    getFilesForKind(kind) {
      const files = this.filesOverview.filter((f) => f.kind === kind)
      return files
    },
    getKindLabel(kind) {
      return this.$t(`factDocumentLabel.${kind}`)
    },
    getMissingDocumentTypeOptions() {
      const types = [...documentTypeMap[this.areaOfLaw][this.subareaOfLaw]]
      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
      }
      return [
        defaultOption,
        ...types.map((type) => ({
          label: this.$t(`factDocumentLabel.${type}`),
          value: type,
        })),
      ]
    },
  },
}
</script>
