<template>
  <div class="container-fluid">
    <dx-loading-overlay v-if="loading" />

    <dx-info-form
      v-else-if="hasTransformedContracts"
      :person="person"
      :invite-allowed-for-contract="inviteAllowedForContract"
      :contract-id="contractId"
      next-step-path="SelfService-ReportDamage-Facts"
      :on-send-invitation-success="onSendInvitationSuccess"
      :contracts="transformedContracts"
    />

    <no-contracts-message v-else />
  </div>
</template>

<script>
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxInfoForm from '~/components/reportDamage/DX-InfoForm'
import NoContractsMessage from '~/components/reportDamage/DX-NoContractsMessage'
import contractsQuery from '~/domains/contract/__gql__/queries/contracts.gql'
import insuringContractsQuery from '~/domains/contract/__gql__/queries/insuringContracts.gql'

export default {
  name: 'MyInfo',
  components: {
    DxLoadingOverlay,
    DxInfoForm,
    NoContractsMessage,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/kundencenter/rechtsfall-melden',
      fr: '/centre-clients/cas-juridique-rapport',
      it: '/cliente/segnalare-caso-giuridico',
    },
  },
  data() {
    return {
      contractId:
        this.$route.query.contract ||
        this.$store.state.claim.report.contract.id,
      contracts: [],
      insuringContracts: [],
      transformedContracts: [],
      contractsLoading: true,
      insuringContractsLoading: true,
    }
  },
  computed: {
    loading() {
      return (
        this.$store.state.application.loading ||
        this.contractsLoading ||
        this.insuringContractsLoading
      )
    },
    person() {
      return this.$store.state.application.currentUser
    },
    hasTransformedContracts() {
      return this.transformedContracts.length > 0
    },
  },
  methods: {
    async onSendInvitationSuccess() {
      await this.$apollo.queries.contracts.refetch()
      this.$router.push({
        path: this.localePath('SelfService'),
      })
    },
    inviteAllowedForContract(contract) {
      return (
        this.$store.getters.isDexPolicyholder &&
        !!contract &&
        contract.role === 'VN' &&
        contract.householdCoverage
      )
    },

    updateTransformedContracts(newContracts) {
      this.transformedContracts = [
        ...this.transformedContracts,
        ...newContracts,
      ]
    },

    hasInsuring(contract) {
      return contract.insuring
    },

    transformedContractsContains(newContract) {
      return this.transformedContracts.some(
        (transformedContract) =>
          !!transformedContract && transformedContract.id === newContract.id,
      )
    },

    addRole(contract, role) {
      return { ...contract, role }
    },
  },

  apollo: {
    contracts: {
      query: contractsQuery,
      variables: {
        withDocuments: false,
        page: 1,
        perPage: 10,
      },
      // TODO: Unclear usage of the block below, because triggering result() twice -> Ask @marten
      result({ data }) {
        const newContracts = data.contracts
          .filter(
            (newContract) => !this.transformedContractsContains(newContract),
          )
          .map((contract) => this.addRole(contract, 'VN'))

        this.updateTransformedContracts(newContracts)
        this.contractsLoading = false
      },
      error(error) {
        this.contractsLoading = false
        throw error
      },
    },
    insuringContracts: {
      query: insuringContractsQuery,
      // TODO: Unclear usage of the block below, because triggering result() twice -> Ask @marten
      result({ data }) {
        const newContracts = data.insuringContracts
          .filter((nc) => this.hasInsuring(nc))
          .filter(
            (newContract) => !this.transformedContractsContains(newContract),
          )
          .map((contract) => this.addRole(contract, 'VP'))

        this.updateTransformedContracts(newContracts)
        this.insuringContractsLoading = false
      },
      error(error) {
        this.insuringContractsLoading = false
        throw error
      },
    },
  },
}
</script>
