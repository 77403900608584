<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-100">
    <dx-head-blue
      :headline="$t('conflictSolver.multiAccount.headline')"
      class="mb-0"
    />
    <div class="content-bg-color mb-5 pt-4 pb-3">
      <div class="container-fluid content-wrap-medium intro-info">
        <h5 class="mb-1">
          {{ $t('conflictSolver.multiAccount.info.headline1') }}
        </h5>
        <p>
          {{ $t('conflictSolver.multiAccount.info.copy1') }}
        </p>
        <h5 class="mb-1">
          {{ $t('conflictSolver.multiAccount.info.headline2') }}
        </h5>
        <p>
          {{ $t('conflictSolver.multiAccount.info.copy2') }}
        </p>
      </div>
    </div>
    <div class="container-fluid content-wrap-medium">
      <div class="row">
        <div class="col-12">
          <h4>{{ $t('conflictSolver.multiAccount.headlineMail') }}</h4>
          <div class="mt-3 mb-5 pt-3">
            <div class="conflict-box p-3 d-inline">
              {{ getDuplicateMail() }}
            </div>
          </div>
          <h4 class="pt-5">
            {{ $t('conflictSolver.multiAccount.headlineSelectMail') }}
          </h4>
        </div>
      </div>
      <div class="row mt-3">
        <!-- ACCOUNT BOXES -->
        <div
          v-for="(account, index) in accounts"
          :key="index"
          ref="account"
          :class="[
            'col-12',
            'col-lg-6 mb-4',
            {
              [account.iconClass]: !accounts.some(
                (acc) => acc.conflictPortals.length > 1,
              ),
            },
          ]"
        >
          <h5>
            {{ `${$t('conflictSolver.multiAccount.account')} ${index + 1}` }}
          </h5>
          <div :class="['conflict-box', 'p-3', { error: account.hasErrors }]">
            <div
              :class="[
                'mt-3',
                'mb-3',
                'pb-2',
                {
                  'ml-5': !accounts.some(
                    (acc) => acc.conflictPortals.length > 1,
                  ),
                },
              ]"
            >
              <span class="conflict-box-headline display-inline-block">
                {{ $t('conflictSolver.multiAccount.yourDataAt') }}
                {{ getPortalName(index) }}
              </span>
              <dx-tooltip position="bottom-center" class="info-i">
                <template slot="trigger">
                  <button type="button" class="btn-blank info-tooltip">
                    <span class="visually-hidden">info</span>
                  </button>
                </template>
                <template slot="content">
                  {{ $t('conflictSolver.multiAccount.yourDataAtInfoText') }}
                </template>
              </dx-tooltip>
            </div>
            <ul class="neutral mb-5">
              <li>
                {{
                  `${
                    account.person.salutation
                      ? $t(account.person.salutation.toLowerCase())
                      : ''
                  }
                  ${account.person.firstName || account.person.companyName}
                  ${account.person.lastName || ''}`
                }}
              </li>
              <li>
                {{
                  `${account.person.address.street} ${account.person.address.number}`
                }}
              </li>
              <li>
                {{
                  `${account.person.address.zip} ${account.person.address.city}`
                }}
              </li>
            </ul>

            <h5>
              {{ $t('conflictSolver.multiAccount.headlineQuestionSelectMail') }}
            </h5>
            <div class="radio-check">
              <!-- EMAIL OPTIONS -->
              <div
                v-for="(portal, idx) in account.conflictPortals"
                :key="`${portal.portal}_${idx}`"
                class="cmp-radio-wrap"
              >
                <input
                  :id="`email_${index}_${idx}`"
                  v-model="newEmailOption[index]"
                  type="radio"
                  :name="`chooseEmail_${index}_${idx}`"
                  :disabled="
                    (newEmailOption.indexOf(portal.accesses[0].email) > -1 &&
                      newEmailOption.indexOf(portal.accesses[0].email) !==
                        index) ||
                    !portal.accesses[0].valid
                  "
                  :value="portal.accesses[0].email"
                  :tabindex="0"
                />
                <label
                  :key="`email_${index}_${idx}`"
                  :for="`email_${index}_${idx}`"
                  class="d-block checkbox"
                >
                  <span>{{ portal.accesses[0].email }}</span>
                </label>

                <div
                  v-if="newEmailOption[index] === portal.accesses[0].email"
                  class="p-3"
                >
                  <h5>
                    {{ $t('conflictSolver.multiAccount.setNewPassword') }}
                  </h5>
                  <dx-input
                    v-model.trim="account.formData.password"
                    v-validate.disable="`required|min:8`"
                    :error="errors.first('password', `account_${index}`)"
                    :label="$t('passwordChange')"
                    class="mb-4"
                    type="password"
                    data-vv-name="password"
                    :data-vv-scope="`account_${index}`"
                    autocomplete="new-password"
                  />
                  <dx-input
                    v-model.trim="account.formData.passwordConfirmation"
                    v-validate.disable="{
                      required: true,
                      min: 8,
                      match_strings: account.formData.password,
                    }"
                    :error="
                      errors.first('newPasswordRepeat', `account_${index}`)
                    "
                    :label="$t('passwordRepeat')"
                    type="password"
                    data-vv-name="newPasswordRepeat"
                    :data-vv-scope="`account_${index}`"
                    autocomplete="new-password"
                    @paste.native.prevent
                  />
                </div>
              </div>

              <div class="cmp-radio-wrap">
                <input
                  :id="`newEmail_${index}`"
                  v-model="newEmailOption[index]"
                  type="radio"
                  :name="`chooseEmail_${index}`"
                  :value="true"
                  :tabindex="0"
                />
                <label
                  :key="`newEmail_${index}`"
                  :for="`newEmail_${index}`"
                  class="d-block checkbox"
                >
                  <span>
                    {{ $t('conflictSolver.multiAccount.setNewEmail') }}
                  </span>
                </label>

                <div v-if="newEmailOption[index] === true" class="p-3">
                  <dx-input
                    v-model.trim="account.formData.email"
                    v-validate.disable="`required|email`"
                    :error="errors.first('invalidEmail', `account_${index}`)"
                    :label="$t('email')"
                    class="mb-4"
                    data-vv-name="invalidEmail"
                    :data-vv-scope="`account_${index}`"
                    autocomplete="email"
                  />
                  <dx-input
                    v-model.trim="account.formData.emailConfirmation"
                    v-validate.disable="{
                      required: true,
                      match_strings: account.formData.email,
                    }"
                    :error="
                      errors.first('invalidEmailRepeat', `account_${index}`)
                    "
                    :label="$t('emailRepeat')"
                    class="mb-4"
                    data-vv-name="invalidEmailRepeat"
                    :data-vv-scope="`account_${index}`"
                    autocomplete="email"
                    @paste.native.prevent
                  />
                  <dx-input
                    v-model.trim="account.formData.password"
                    v-validate.disable="`required|min:8`"
                    :error="errors.first('password', `account_${index}`)"
                    :label="$t('passwordChange')"
                    class="mb-4"
                    type="password"
                    data-vv-name="password"
                    :data-vv-scope="`account_${index}`"
                    autocomplete="new-password"
                  />
                  <dx-input
                    v-model.trim="account.formData.passwordConfirmation"
                    v-validate.disable="{
                      required: true,
                      min: 8,
                      match_strings: account.formData.password,
                    }"
                    :error="
                      errors.first('newPasswordRepeat', `account_${index}`)
                    "
                    :label="$t('passwordRepeat')"
                    type="password"
                    data-vv-name="newPasswordRepeat"
                    :data-vv-scope="`account_${index}`"
                    autocomplete="new-password"
                    @paste.native.prevent
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid content-wrap-medium">
      <div class="row">
        <div class="col-12 mt-3">
          <h5 v-html="$t('conflictSolver.multiAccount.headlineTextUs')" />
          <button
            v-if="!showTextArea"
            class="show-more-link plus-icon btn-blank"
            @click="toggleShowTextArea"
          >
            {{ $t('offer.questions.individualRequestsBtn') }}
          </button>
          <dx-textarea v-if="showTextArea" v-model="message" />
        </div>
      </div>
    </div>

    <div class="container-fluid content-wrap-medium">
      <div class="text-right mt-4">
        <dx-button :text="$t('save')" @click="save" />
      </div>

      <div class="mt-5 mb-5">
        <h4>{{ $t('conflictSolver.contact.headline') }}</h4>
        <span v-html="$t('conflictSolver.contact.copy', { contactUrl })" />
      </div>
    </div>

    <dx-loading-overlay v-if="loading" global-loader />
  </div>
</template>

<script>
import {
  DxButton,
  DxInput,
  DxTextarea,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxTooltip from '~/components/DX-Tooltip'
import { scrollIntoView } from '~/javascripts/helper/scollIntoView'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import { RESOLVE_CONFLICTS } from '~/domains/authentication/__store__/actions'

export default {
  name: 'MultiAccount',
  components: {
    DxButton,
    DxHeadBlue,
    DxInput,
    DxTextarea,
    DxLoadingOverlay,
    DxTooltip,
  },
  mixins: [validationErrorsMixin],
  data() {
    return {
      conflictId: '',
      accounts: [],
      newEmailOption: [],
      message: '',
      showTextArea: false,
      scrolled: false,
      websiteUrl: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  computed: {
    loading() {
      return this.$store.state.application.loading
    },
    contactUrl() {
      return `${this.websiteUrl}${this.$t('nav.path1')}`
    },
  },
  beforeMount() {
    const { accounts, conflictId } = this.$route.params
    if (accounts && accounts.length) {
      this.conflictId = conflictId
      this.handleAccounts(accounts)
    } else {
      this.$router.push(this.localePath('Login'))
    }
  },
  methods: {
    toggleShowTextArea() {
      this.showTextArea = !this.showTextArea
    },
    getDuplicateMail() {
      let duplicateMail = ''
      const emails = []
      this.accounts.some((account) =>
        account.conflictPortals.some((conflictPortal) => {
          const { email } = conflictPortal.accesses[0]
          const hit = emails.includes(email)
          emails.push(email)
          if (hit) {
            duplicateMail = email
          }
          return hit
        }),
      )
      return duplicateMail
    },
    handleAccounts(accounts) {
      for (let i = 0; i < accounts.length; i += 1) {
        const blueprint = {
          conflictPortals: [],
          iconClass: '',
          dexid: '',
          hasErrors: false,
          formData: {
            email: '',
            emailConfirmation: '',
            password: '',
            passwordConfirmation: '',
          },
          person: {
            salutation: '',
            firstName: '',
            lastName: '',
            companyName: '',
            address: {
              street: '',
              number: '',
              zip: '',
              city: '',
            },
          },
        }
        const { person, conflictPortals, dexId } = accounts[i]
        Object.assign(blueprint.person, person)
        blueprint.conflictPortals = conflictPortals
        blueprint.iconClass = conflictPortals[0].portal
          .toLowerCase()
          .split('_')
          .join('')
        blueprint.dexId = dexId
        this.accounts.push(blueprint)
        this.newEmailOption.push(null)
      }
    },
    async save() {
      const valid = await this.$validator.validate()
      if (valid && !this.newEmailOption.includes(null)) {
        const result = await this.$store.dispatch(RESOLVE_CONFLICTS, {
          variables: {
            conflictId: this.conflictId,
            conflictResolutions: this.accounts.map((account, idx) => ({
              email: account.formData.email || this.newEmailOption[idx],
              password: account.formData.password,
              passwordConfirmation: account.formData.passwordConfirmation,
              dexId: account.dexId,
            })),
            message: this.message,
          },
          apollo: this.$apollo.provider.defaultClient,
        })
        if (result) {
          const { success, validationErrors } = result
          let scrolled = false

          if (!success) {
            const errorKeys = []
            validationErrors.forEach((err) => {
              if (err.field) {
                const accountIdx = parseInt(err.field.split('.')[1], 10)
                if (!scrolled) {
                  this.accounts[accountIdx].hasErrors = true
                  scrollIntoView(this.$refs.account[accountIdx])
                  scrolled = true
                }
              }
              if (!errorKeys.includes(err.key)) {
                this.$notification.error({
                  messageKey: this.getValidationErrorMessageKey(err),
                })
                errorKeys.push(err.key)
              }
            })
          } else {
            this.$notification.success({ messageKey: 'passwordChangedSuccess' })
            this.$router.push({ path: this.localePath('Login') })
          }
        } else {
          this.$notification.error({ messageKey: 'pwReset.error' })
        }
      }
    },
    getPortalName(index) {
      let headline = ''
      this.accounts[index].conflictPortals.forEach((portal, idx) => {
        if (idx > 0) {
          headline += ` ${this.$t('and')} `
        }
        switch (portal.portal) {
          case 'LAWYER_PORTAL':
            headline += this.$t('lawyerPortalTitle')
            break
          case 'BROKER_PORTAL':
            headline += this.$t('partnerPortalTitle')
            break
          default:
            headline += this.$t('customerPortalTitle')
            break
        }
      })

      return headline
    },
  },
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/ueberarbeiten-zugangsdaten-multi',
      fr: '/reviser-donnees-d-acces-multi',
      it: '/rivedere-dati-d-accesso-multi',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../index';
</style>
