<template>
  <div class="downloadWrapper">
    <download-icon class="downloadIcon" />
    <a href="javascript://" @click="onClick">{{ translatedDownloadLabel }}</a>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import DownloadIcon from '@/components/icons/DownloadIcon'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DxDownloadAdapter',
  components: { DownloadIcon },
  inject: ['applicationFormProduct'],

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  computed: {
    translatedDownloadLabel() {
      const product = this.applicationFormProduct
      const key = `downloads.${this.item.documentTypeKey}_${product.productKey}_${product.variantKey}_${this.item.languageKey}`
      return this.te(key) ? this.t(key) : this.item.label
    },
  },

  methods: {
    async onClick() {
      const result = await this.formContext.getDocument(this.item)

      if (result.__typename !== 'ApplicationDocument') {
        return
      }

      const {
        file: { base64Data, fileName, mimeType },
      } = result
      const base64BinaryString = atob(base64Data)
      const base64Array = new Uint8Array(base64BinaryString.length)
      for (let i = 0; i < base64BinaryString.length; i += 1) {
        base64Array[i] = base64BinaryString.charCodeAt(i)
      }
      const blob = new Blob([base64Array], { type: mimeType })

      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url

      const fileNameOrDefault = fileName || this.item.fileName
      if (fileNameOrDefault) {
        a.download = fileNameOrDefault
      }

      a.addEventListener(
        'click',
        () => setTimeout(() => URL.revokeObjectURL(url), 150),
        false,
      )
      a.click()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.downloadWrapper {
  display: flex;
}
.downloadIcon {
  margin-right: rem(16);
  color: $functional-clr-info;
}
</style>
