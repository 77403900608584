<template>
  <div class="w-100">
    <dx-head-blue
      :headline="$t('lawyerPortal.welcomeLawyerHead')"
      :textblock="$t('lawyerPortal.welcomeLawyerHeadSub')"
      hide-text-on-mobile
      class="mb-5"
    />

    <div class="container-fluid pb-5">
      <div class="row">
        <div class="col">
          <dx-damage-requests class="pb-4 pt-md-3 pb-md-5" />
        </div>
      </div>

      <dx-unread-comments-indicator />
    </div>

    <div class="content-bg-color">
      <div class="container-fluid content-wrap-medium py-5">
        <dx-open-damages />
      </div>
    </div>
  </div>
</template>

<script>
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxOpenDamages from '~/domains/externalLawyer/components/DX-OpenDamages'
import DxDamageRequests from '~/domains/externalLawyer/components/DX-DamageRequests'
import DxUnreadCommentsIndicator from '~/domains/externalLawyer/components/DX-UnreadCommentsIndicator'

export default {
  name: 'LawyerPortalStart',
  components: {
    DxHeadBlue,
    DxOpenDamages,
    DxDamageRequests,
    DxUnreadCommentsIndicator,
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal',
      fr: '/portail-avocat',
      it: '/portale-procuratori',
    },
  },
}
</script>
