import format from 'date-fns/format'
import startOfTomorrow from 'date-fns/startOfTomorrow'

export default () => ({
  offerId: '',
  activeTariff: 'p-private',
  step: 1,
  premia: {
    insurable: null,
    components: [],
    totalPremium: {},
  },
  privateBasicQuestions: {
    startOfInsurance: format(startOfTomorrow(), 'dd.MM.yyyy'),
    familyOption: false,
    apartmentsRentedInSwitzerland: false,
    numberOfApartmentsForRentInSwitzerland: 0,
  },
  businessBasicQuestions: {
    startOfInsurance: format(startOfTomorrow(), 'dd.MM.yyyy'),
    branchOfIndustry: '',
    secondaryEmployment: false,
    secondaryEmploymentDescription: '',
    estimatedAnnualPayroll: '',
    estimatedAnnualSales: '',
  },
  businessMoveBasicQuestions: {
    startOfInsurance: format(startOfTomorrow(), 'dd.MM.yyyy'),
    branchOfIndustry: '',
    secondaryEmployment: false,
    secondaryEmploymentDescription: '',
    countStreetVehicles: '',
    countWaterVehicles: '',
  },
  policyHolderData: {
    salutation: null,
    firstName: '',
    lastName: '',
    email: '',
    emailConfirmation: '',
    dateOfBirth: null,
    address: {
      street: '',
      number: '',
      postOfficeBox: null,
      zip: '',
      city: '',
    },
    contactDetails: {
      phone: '',
      mobile: '',
    },
    locale: 'DE',
  },
  businessPolicyHolderData: {
    companyName: '',
    address: {
      street: '',
      number: '',
      postOfficeBox: null,
      zip: '',
      city: '',
    },
    contactDetails: {
      phone: '',
    },
    contactPerson: {
      salutation: null,
      firstName: '',
      lastName: '',
      address: {
        street: '',
        number: '',
        zip: '',
        city: '',
      },
      contactDetails: {
        phone: '',
        mobile: '',
      },
      email: '',
      emailConfirmation: '',
      locale: 'DE',
    },
    locations: [],
  },
  assessmentQuestions: {
    recentExistingOrExpectedLawsuit: null,
    recentExistingOrExpectedLawsuitAdditional: '',
    impairedAbilityToWork: null,
    impairedAbilityToWorkAdditional: '',
    contractTerminatedByInsuranceCompany: null,
    contractTerminatedByInsuranceCompanyAdditional: '',
    individualAdditionalText: '',
  },
  brokerPolicyData: {
    mainExpiry: '',
    brokerDocumentDeliveryMethod: null,
    policyholderDocumentDeliveryMethod: null,
    paymentInterval: 'YEARLY',
  },
  noEmail: false,
})
