var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.claimsLoading)?_c('dx-loading-overlay'):_c('div',[_c('h3',{staticClass:"mb-3 mb-md-4"},[_vm._v("\n      "+_vm._s(_vm.$t('openDamages'))+"\n    ")]),_vm._v(" "),(!_vm.openClaims.length)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('noOpenDamages')))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.openClaims),function(claim){return _c('div',{key:claim.id},[_c('dx-link-container',{staticClass:"mb-2",attrs:{"route-name":{
          path: _vm.localePath('SelfService-ClaimOverview'),
          query: {
            id: claim.id,
            contractId: claim.contract.id,
            insuranceCompany: claim.contract.insuranceCompany,
          },
        }}},[_c('template',{slot:"content"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("\n            "+_vm._s(claim.number)+"\n          ")])])],2)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }