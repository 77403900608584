import { trackPageRoute } from '~/javascripts/helper/webtrekk'

export default function ({ route, app }) {
  const metaDataWithTrackingId = route.meta.find((m) => m.trackingId)

  // webtrekk track route
  if (metaDataWithTrackingId) {
    trackPageRoute({
      metaData: metaDataWithTrackingId,
      locale: app.i18n.locale,
      tariffType: app.store.getters.GET_ACTIVE_TARIFF_TYPE,
    })
  }
}
