<template>
  <section class="summaryItem">
    <header class="header">
      <h2>{{ translatedLabel }}</h2>
      <button class="editButton" type="button" @click="toggleEdit">
        <chevron-up-icon v-if="isEditMode" :width="24" :height="24" />
        <edit-icon v-else :width="22" :height="22" />
      </button>
    </header>
    <div
      v-if="isEditMode"
      class="editForm"
      :class="{ 'community-insurance': isCommunityInsurance }"
    >
      <slot />
    </div>
    <div v-else>
      <summary-item-entries
        :form-context="formContext"
        :entries="usedSummaryItems"
        :selected-modules="formContext.selectedModules"
      />
    </div>
  </section>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import ChevronUpIcon from '@/components/icons/ChevronUpIcon'
import EditIcon from '@/components/icons/EditIcon'

import SummaryItemEntries from './SummaryItemEntries'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DXSummaryAdapter',
  components: {
    ChevronUpIcon,
    EditIcon,
    SummaryItemEntries,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
    summaryItems: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const translation = useScipTranslationWithFallback(toRefs(props))

    // Start with edit mode
    emit('editMode', true)

    return {
      ...translation,
    }
  },

  computed: {
    usedSummaryItems() {
      return this.item.shownItemIds.map((id) => this.summaryItems[id])
    },

    isCommunityInsurance() {
      return this.formContext.schema.product.productKey === 'gemeinde'
    },
  },

  methods: {
    toggleEdit() {
      this.$emit('editMode', !this.isEditMode)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.summaryItem {
  padding: rem(12) rem(20);

  @include media-breakpoint-up(lg) {
    padding: rem(12) rem(60);
  }

  p {
    margin: 0;

    strong {
      font-family: $font-family-heavy;
    }
  }

  ::v-deep {
    .moduleSelection {
      margin: 0;
      border-top: none;
    }

    .moduleItems > * {
      margin: 0;
    }
  }
}

.editForm {
  display: grid;
  grid-gap: rem(16);
  grid-template-columns: 1fr;

  ::v-deep .moduleSelection .label.cmp-checkbox {
    font-family: $font-family-heavy;
  }
}

// Only used in application process "Gemeinden"
.community-insurance .wrapper {
  ::v-deep .moduleItems > * {
    margin-left: rem(40);

    .cmp-radio-wrap {
      margin: 0;
    }

    label {
      padding-top: 0;
      padding-bottom: 0;
    }

    .cmp-checkbox {
      position: relative;
    }

    .cmp-checkbox::before {
      position: absolute;
      margin-top: rem(-11);
      width: rem(22);
      height: rem(22);
    }

    .cmp-checkbox::after {
      position: absolute;
      margin-top: rem(-12);
      left: rem(8);
    }
  }
}

.header {
  display: flex;
  margin-bottom: rem(12);
  border-bottom: 2px solid $black-clr;
  padding-bottom: rem(12);
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin-bottom: rem(16);
    padding-bottom: rem(16);
  }

  h2 {
    margin: 0;
    color: $black-clr;
    font-family: $font-family-heavy;
    font-size: rem(18);
    line-height: rem(20);

    @include media-breakpoint-up(lg) {
      font-size: rem(24);
      line-height: rem(28);
    }
  }
}

.editButton {
  margin-left: rem(16);
  border: none;
  padding: 0 rem(8);
  background: transparent;
  color: $color-turquoise-surf;
  outline: none;
}

::v-deep .modulesHeadlineWrap {
  padding-left: 0;

  h1 {
    color: $black-clr;
    font-family: $font-family-heavy;
    font-weight: 400;
    font-size: rem(16);
  }
}
</style>
