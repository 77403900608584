var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"damage-item mb-3 px-4 py-3 position-relative"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"col mr-2 mb-2 p-0 font-weight-bold"},[_vm._v("\n      "+_vm._s(_vm.claim.number)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"status",class:_vm.claim.status.toLowerCase()},[_vm._v("\n      "+_vm._s(_vm.$t(("damage.status." + (_vm.claim.status.toLowerCase()))))+"\n    ")])]),_vm._v(" "),(_vm.claim.status !== 'NEW')?_c('div',[_vm._v("\n    "+_vm._s(_vm.$t(("damage.type." + (_vm.claim.type))))+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{class:!_vm.isBroker || _vm.claim.status === 'NEW' ? 'pb-5' : 'pb-3'},[_vm._v("\n    "+_vm._s(_vm.getFormattedDate(_vm.claim.eventDate))+"\n  ")]),_vm._v(" "),(_vm.isBroker && _vm.claim.status !== 'NEW')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('ul',{staticClass:"neutral"},[(_vm.claim.insuredPerson)?_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('damage.insuredPerson'))+": "+_vm._s(_vm.claim.insuredPerson.fullName)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.claim.costs)?_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('damage.costs'))+": "+_vm._s(_vm.claim.costs.currency)+"\n          "+_vm._s(_vm.formatCurrency(_vm.claim.costs.value))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.claim.lawyer)?_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('damage.dextraLawyer'))+": "+_vm._s(_vm.claim.lawyer.fullName)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.claim.externalLawyer)?_c('li',[_vm._v("\n          "+_vm._s(_vm.$t('damage.externalLaywer'))+":\n          "+_vm._s(_vm.claim.externalLawyer.fullName)+"\n        ")]):_vm._e()])])]):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"link secondary-link position-absolute pr-4 pb-3",attrs:{"to":_vm.localePath({
        name: _vm.isBroker
          ? 'PartnerPortal-DamageManagement'
          : 'SelfService-ClaimOverview',
        query: {
          id: _vm.claim.id,
          contractId: _vm.claim.contract.id,
          insuranceCompany: _vm.claim.contract.insuranceCompany,
        },
      })}},[_vm._v("\n    "+_vm._s(_vm.$t('damageManage'))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }