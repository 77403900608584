<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-100">
    <dx-head-blue
      :headline="$t('conflictSolver.singleAccount.headline')"
      class="mb-0"
    />

    <div class="content-bg-color mb-5 pt-4 pb-3">
      <div class="container-fluid content-wrap-medium intro-info">
        <h5 class="mb-1">
          {{ $t('conflictSolver.singleAccount.info.headline1') }}
        </h5>
        <p>
          {{ $t('conflictSolver.singleAccount.info.copy1') }}
        </p>
        <h5 class="mb-1">
          {{ $t('conflictSolver.singleAccount.info.headline2') }}
        </h5>
        <p>
          {{ $t('conflictSolver.singleAccount.info.copy2') }}
        </p>
      </div>
    </div>

    <div class="container-fluid content-wrap-medium">
      <h4 class="mb-1">
        {{ $t('conflictSolver.singleAccount.yourData') }}
      </h4>
      <ul class="neutral">
        <li>
          {{
            `${$t(person.salutation.toLowerCase())} ${
              person.firstName || person.companyName
            } ${person.lastName || ''}`
          }}
        </li>
        <li>
          {{ `${person.address.street} ${person.address.number}` }}
        </li>
        <li>
          {{ `${person.address.zip} ${person.address.city}` }}
        </li>
      </ul>

      <h4 class="mb-3 mt-5 d-inline-block">
        {{ $t('conflictSolver.singleAccount.usedPortals') }}

        <dx-tooltip position="bottom-center" class="info-i">
          <template slot="trigger">
            <button type="button" class="btn-blank info-tooltip">
              <span class="visually-hidden">info</span>
            </button>
          </template>
          <template slot="content">
            {{ $t('conflictSolver.singleAccount.infoTextusedPortals') }}
          </template>
        </dx-tooltip>
      </h4>

      <div
        v-for="(portal, index) in conflictPortals"
        :key="`${portal.portal}_${index}`"
        class="conflict-box mb-3 p-3"
      >
        <div class="font-weight-bold mb-1">
          {{ getPortalName(portal.portal) }}
        </div>
        <p v-for="access in portal.accesses" :key="access.email" class="mb-0">
          {{ access.email }}
        </p>
      </div>

      <h4 class="mb-4 mt-5 d-inline-block">
        {{ $t('conflictSolver.singleAccount.headlineNewData') }}

        <dx-tooltip position="bottom-center" class="info-i">
          <template slot="trigger">
            <button type="button" class="btn-blank info-tooltip">
              <span class="visually-hidden">info</span>
            </button>
          </template>
          <template slot="content">
            {{ $t('conflictSolver.singleAccount.infoTextNewData') }}
          </template>
        </dx-tooltip>
      </h4>

      <form @submit.prevent="save">
        <dx-input
          v-model.trim="formData.email"
          v-validate.disable="`required|email`"
          :error="errors.first('invalidEmail')"
          :label="$t('email')"
          class="mb-4"
          data-vv-name="invalidEmail"
          autocomplete="email"
        />
        <dx-input
          v-model.trim="formData.emailConfirmation"
          v-validate.disable="{
            required: true,
            match_strings: formData.email,
          }"
          :error="errors.first('invalidEmailRepeat')"
          :label="$t('emailRepeat')"
          class="mb-4"
          data-vv-name="invalidEmailRepeat"
          autocomplete="email"
          @paste.native.prevent
        />
        <dx-input
          v-model.trim="formData.password"
          v-validate.disable="`required|min:8`"
          :error="errors.first('password')"
          :label="$t('passwordChange')"
          class="mb-4"
          type="password"
          data-vv-name="password"
          autocomplete="new-password"
        />
        <dx-input
          v-model.trim="formData.passwordConfirmation"
          v-validate.disable="{
            required: true,
            min: 8,
            match_strings: formData.password,
          }"
          :error="errors.first('newPasswordRepeat')"
          :label="$t('passwordRepeat')"
          type="password"
          data-vv-name="newPasswordRepeat"
          autocomplete="new-password"
          @paste.native.prevent
        />
        <div class="text-right mt-4">
          <dx-button type="submit" :text="$t('save')" />
        </div>
      </form>

      <div class="mt-5 mb-5">
        <h4>{{ $t('conflictSolver.contact.headline') }}</h4>
        <span v-html="$t('conflictSolver.contact.copy', { contactUrl })" />
      </div>
    </div>

    <dx-loading-overlay v-if="loading" global-loader />
  </div>
</template>

<script>
import { DxInput, DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxTooltip from '~/components/DX-Tooltip'
import DxHeadBlue from '~/components/DX-HeadBlue'
import { RESOLVE_CONFLICTS } from '~/domains/authentication/__store__/actions'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'

export default {
  name: 'SingleAccount',
  components: {
    DxHeadBlue,
    DxLoadingOverlay,
    DxInput,
    DxButton,
    DxTooltip,
  },
  mixins: [validationErrorsMixin],
  data() {
    return {
      formData: {
        email: '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
      },
      person: {
        salutation: '',
        firstName: '',
        lastName: '',
        companyName: '',
        address: {
          street: '',
          number: '',
          zip: '',
          city: '',
        },
      },
      conflictPortals: [],
      dexId: '',
      conflictId: '',
      websiteUrl: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  computed: {
    loading() {
      return this.$store.state.application.loading
    },
    contactUrl() {
      return `${this.websiteUrl}${this.$t('nav.path1')}`
    },
  },
  beforeMount() {
    const { accounts, conflictId } = this.$route.params
    if (accounts && accounts.length) {
      const { person, conflictPortals, dexId } = accounts[0]
      Object.assign(this.person, person)
      this.conflictPortals = conflictPortals
      this.dexId = dexId
      this.conflictId = conflictId
    } else {
      this.$router.push(this.localePath('Login'))
    }
  },
  methods: {
    async save() {
      const valid = await this.$validator.validateAll()

      if (valid) {
        const result = await this.$store.dispatch(RESOLVE_CONFLICTS, {
          variables: {
            conflictId: this.conflictId,
            conflictResolutions: {
              email: this.formData.email,
              password: this.formData.password,
              passwordConfirmation: this.formData.passwordConfirmation,
              dexId: this.dexId,
            },
          },
          apollo: this.$apollo.provider.defaultClient,
        })
        if (result) {
          const { success, validationErrors } = result

          if (!success) {
            const errorKeys = []
            validationErrors.forEach((err) => {
              if (!errorKeys.includes(err.key)) {
                this.$notification.error({
                  messageKey: this.getValidationErrorMessageKey(err),
                })
                errorKeys.push(err.key)
              }
            })
          } else {
            this.$notification.success({ messageKey: 'passwordChangedSuccess' })
            this.$router.push({ path: this.localePath('Login') })
          }
        } else {
          this.$notification.error({ messageKey: 'pwReset.error' })
        }
      }
    },
    getPortalName(id) {
      switch (id) {
        case 'LAWYER_PORTAL':
          return this.$t('lawyerPortalTitle')
        case 'BROKER_PORTAL':
          return this.$t('partnerPortalTitle')
        default:
          return this.$t('customerPortalTitle')
      }
    },
  },
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/ueberarbeiten-zugangsdaten',
      fr: '/reviser-donnees-d-acces',
      it: '/rivedere-dati-d-accesso',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../index';
</style>
