<template>
  <div class="content-block mb-4">
    <div class="row">
      <div class="col">
        <h4 class="mb-4">
          {{ $t('damage.data') }}
        </h4>
      </div>
    </div>

    <div class="row">
      <ul class="col-12 col-md-6 mb-0">
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damage.numberShort') }}
          </h5>
          <div class="col">
            {{ claim.number }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damage.status.label') }}
          </h5>
          <div class="col">
            {{ $t(`damage.status.${claim.status.toLowerCase()}`) }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damageCheck.instance') }}
          </h5>
          <div class="col">
            {{
              claim.targetInstance
                ? $t(`lawyerPortal.targetInstance.${claim.targetInstance}`)
                : $t('noContent')
            }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('hourly') }}
          </h5>
          <div class="col">
            {{ claim.hourlyRate && claim.hourlyRate.value }} CHF
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damageCheck.rateLimit') }}
          </h5>
          <div class="col">
            {{
              claim.paymentLimit
                ? `${claim.paymentLimit.value} CHF`
                : $t('noContent')
            }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damage.reimbursement') }}
          </h5>
          <div class="col">
            {{
              claim.targetInstance
                ? $t('lawyerPortal.committedFor', {
                    instance: $t(
                      `lawyerPortal.targetInstance.${claim.targetInstance}`,
                    ),
                  })
                : $t('noContent')
            }}
          </div>
        </li>
      </ul>

      <ul class="col-12 col-md-6 mb-0">
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('lawyerPortal.jurisdiction') }}
          </h5>
          <div class="col">
            {{
              claim.areaOfLaw &&
              $t(`areaOfLaw.${claim.areaOfLaw.toUpperCase()}`)
            }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damageCheck.deadline') }}
          </h5>
          <div class="col">
            {{
              claim.deadline
                ? getFormattedDate(claim.deadline)
                : $t('noContent')
            }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damage.date') }}
          </h5>
          <div class="col">
            {{ getFormattedDate(claim.eventDate) }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damage.reportTime') }}
          </h5>
          <div class="col">
            {{
              claim.createdAt
                ? getFormattedDate(claim.createdAt)
                : $t('noContent')
            }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damage.legalAdvisor') }}
          </h5>
          <div class="col">
            {{ claim.lawyer && claim.lawyer.fullName }}
          </div>
        </li>
        <li class="row mb-1">
          <h5 class="col">
            {{ $t('damage.legalAccess') }}
          </h5>
          <div class="col">
            {{
              claim.targetInstanceRequests.some((i) => i.status === 'OPEN')
                ? $t('yes')
                : $t('no')
            }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import personMixin from '~/javascripts/mixins/personMixin'
import dateMixin from '~/javascripts/mixins/dateMixin'

export default {
  name: 'DxLawyerClaimData',
  mixins: [personMixin, dateMixin],
  props: {
    claim: {
      type: Object,
      required: true,
      default: () => ({
        targetInstanceRequests: [],
        status: 'NEW',
      }),
    },
  },
}
</script>
