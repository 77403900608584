<template>
  <div :class="cssClasses()" class="cmp-link-wrap">
    <a v-if="hasSubnav()" @click="openSubnav()">
      {{ item.name }}
      <span class="arrow" />
    </a>

    <nuxt-link v-else :to="localePath(item.path)" @click.native="onClick">
      {{ item.name }}
    </nuxt-link>

    <ul v-if="isActiveSubnav" class="cmp-subnav-list">
      <li v-for="subItem in item.subNav" :key="subItem.name">
        <nuxt-link
          :to="{ path: subItem.path, hash: subItem.hash }"
          @click.native="onClick"
        >
          {{ subItem.name }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    linkName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActiveSubnav: false,
      outerLink: false,
      hideMobileSubnav: false,
    }
  },
  methods: {
    hasSubnav() {
      return this.item.subNav && this.item.subNav.length > 0
    },
    openSubnav() {
      this.isActiveSubnav = !this.isActiveSubnav
    },
    cssClasses() {
      return {
        'active': this.isActiveSubnav,
        'has-subnav': this.hasSubnav(),
      }
    },
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
