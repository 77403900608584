<template>
  <div class="d-md-flex justify-content-between w-100 mb-1">
    <div
      v-for="(btn, index) in buttons"
      :key="btn"
      class="col p-0 selection-wrap"
    >
      <input
        :id="`${id}_${index}`"
        :name="name"
        :checked="btn === active"
        type="radio"
        class="d-none selection-input"
      />
      <label
        :for="`${id}_${index}`"
        class="mb-2 selection-btn"
        @click="$emit('input', btn)"
      >
        {{ $t(`${translationKey ? translationKey + '.' + btn : btn}`) }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DxSelectionButtons',
  props: {
    buttons: {
      type: Array,
      default() {
        return []
      },
    },
    translationKey: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    active: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
