<template>
  <div class="w-100">
    <dx-head-blue :headline="$t('myDamageOverview')" class="mb-0 mb-md-4">
      <template slot="head-top-content">
        <div class="mb-2 container-fluid">
          <dx-back-text-button
            :text="$t('backToSelfService')"
            :route="localePath('SelfService')"
            inverted
            class="link-back"
          />
        </div>
      </template>

      <template slot="head-content">
        <dx-loading-overlay v-if="loading" theme="light" />

        <div v-if="hasErrors" class="text-center">
          {{ $t('damage.accessDenied') }}
        </div>

        <div v-if="!loading && !hasErrors" class="text-center">
          <p class="subheadline mb-0">
            {{ $t('policyNumber') }}: {{ claim.contract.id }}
          </p>
          <p class="subheadline mb-0">
            {{ $t('damage.number') }}: {{ claim.number }}
          </p>
          <h4 v-if="claim.lawyer" class="subheadline mt-3">
            {{ $t('damageoverview.dextraLawyer') }}:
            {{ claim.lawyer && claim.lawyer.fullName }}
          </h4>
        </div>
      </template>
    </dx-head-blue>

    <transition out-in name="fadeHeight">
      <div
        v-if="!hasErrors && claimNotifications.length > 0 && !claimLocked"
        class="notifications-wrap"
      >
        <div class="container-fluid">
          <dx-loading-overlay v-if="loading" />
          <dx-notifications
            v-if="!loading"
            :notifications="claimNotifications"
            :show-detail-link="false"
          />
        </div>
      </div>
    </transition>

    <div
      v-if="!hasErrors"
      class="container-fluid content-wrap-medium pt-2 pt-md-5"
    >
      <dx-loading-overlay v-if="loading" />
      <div v-else class="row">
        <h3 class="position-relative mb-2 col-12">
          {{ $t('damageoverview.claimOverview') }}
        </h3>
        <dx-claim-share-status
          v-if="brokerName"
          :shared="claimShared"
          :broker-name="brokerName"
          :on-toggle-share="onToggleShare"
          class="col-12 mb-2"
        />
        <div class="col-12 col-md-6 mb-4 mb-md-0">
          <div class="shadow-container">
            <h4>{{ $t('damageCheck.caseDescription') }}</h4>
            <p>{{ claim.facts || $t('noContent') }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="shadow-container">
            <h4>{{ $t('damageCheck.aims') }}</h4>
            <p>{{ claim.introduction || $t('noContent') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!hasErrors"
      class="container-fluid content-wrap-medium pt-2 pt-md-5"
    >
      <a id="customerMails" class="mailbox-anchors" />
      <div class="row pb-3 pb-md-5 mb-2">
        <div class="col">
          <h3
            class="position-relative mb-2"
            @click="() => (inboxInsuredPersonOpen = !inboxInsuredPersonOpen)"
          >
            <span
              :class="{ 'mailbox-arrow--active': inboxInsuredPersonOpen }"
              class="mailbox-arrow"
            />
            {{ $t('mailFromLaywer') }}
          </h3>
          <template v-if="inboxInsuredPersonOpen">
            <dx-mailbox
              :mail-data="externalLawyerInsuredPersonMessages"
              :read-only="!canSendMessagesToLawyer"
              :can-reply="(sender) => canReplyToLawyer(sender)"
              :send-new-message="
                (messageText) => sendMessage(messageText, 'IP')
              "
              :reply="
                (item, messageText) => replyWithMessage(item, messageText, 'IP')
              "
              :on-unread-item-click="markAsRead"
            />
          </template>
          <template v-else>
            <div v-if="!messagesIPLoading">
              {{
                Array.isArray(externalLawyerInsuredPersonMessages) &&
                externalLawyerInsuredPersonMessages.length > 0
                  ? $t('noUnreadMails')
                  : $t('noMails')
              }}
            </div>
          </template>
        </div>
      </div>

      <a id="dextraMails" class="mailbox-anchors" />
      <div class="row pb-2 pb-md-5">
        <div class="col">
          <h3
            class="position-relative mb-2"
            @click="() => (inboxDextraOpen = !inboxDextraOpen)"
          >
            <span
              :class="{ 'mailbox-arrow--active': inboxDextraOpen }"
              class="mailbox-arrow"
            />
            {{ $t('messagesFromDextra') }}
          </h3>
          <template v-if="inboxDextraOpen">
            <dx-mailbox
              :mail-data="insuredPersonDextraMessages"
              :read-only="claimLocked"
              :send-new-message="
                (messageText) => sendMessage(messageText, 'DEXTRA')
              "
              :reply="
                (item, messageText) =>
                  replyWithMessage(item, messageText, 'DEXTRA')
              "
              :on-unread-item-click="markAsRead"
              :show-status-flag="false"
            />
          </template>
          <template v-else>
            <div v-if="!messagesDextraLoading">
              {{
                Array.isArray(insuredPersonDextraMessages) &&
                insuredPersonDextraMessages.length > 0
                  ? $t('noUnreadMails')
                  : $t('noMails')
              }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="!hasErrors" class="content-bg-color my-md-5">
      <div class="content-wrap-medium py-md-5 px-0">
        <a id="documents" class="document-anchors" />
        <dx-loading-overlay v-if="loading" />
        <dx-document-upload
          v-if="!loading"
          :read-only="claimLocked"
          :docs-to-upload="claim.missingDocuments"
          :files-overview="sortedClaimDocuments"
          :on-upload-success="(fileId, doc) => addUploadedDocument(fileId, doc)"
          :claim-id="claimId"
        />
      </div>
    </div>

    <div v-if="!hasErrors" class="content-wrap-medium chronik-wrap mb-3">
      <dx-loading-overlay v-if="loading" />
      <dx-chronic v-if="!loading" :chronic-data="claim.chronicle" />
    </div>
  </div>
</template>

<script>
import { DxBackTextButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxMailbox from '~/partials/DX-Mailbox'
import DxNotifications from '~/partials/DX-Notifications'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import { formatCurrency } from '~/javascripts/utils'

import claimQuery from '~/domains/contract/claim/__gql__/queries/claim.gql'
import markCommentAsRead from '~/domains/contract/claim/__gql__/mutations/markCommentAsRead.gql'
import replyMessageMutation from '~/domains/contract/claim/__gql__/mutations/replyMessage.gql'
import uploadMissingDocumentsMutation from '~/domains/contract/claim/__gql__/mutations/uploadMissingDocuments.gql'
import uploadDocumentsMutation from '~/domains/contract/claim/__gql__/mutations/uploadDocuments.gql'
import externalLawyerInsuredPersonMessagesQuery from '~/domains/contract/claim/__gql__/queries/externalLawyerInsuredPersonMessages.gql'
import insuredPersonDextraMessagesQuery from '~/domains/contract/claim/__gql__/queries/insuredPersonDextraMessages.gql'
import createInsuredPersonDextraMessageMutation from '~/domains/contract/claim/__gql__/mutations/createInsuredPersonDextraMessage.gql'
import createExternalLawyerInsuredPersonMessageMutation from '~/domains/contract/claim/__gql__/mutations/createExternalLawyerInsuredPersonMessage.gql'

import loadingMixin from '~/javascripts/mixins/loadingMixin'
import claimShareMixin from '~/javascripts/mixins/claimShareMixin'
import DxClaimShareStatus from './DX-ClaimShareStatus'
import DxDocumentUpload from './DX-DocumentUpload'
import DxChronic from './DX-Chronic'

export default {
  name: 'DxClaim',
  components: {
    DxHeadBlue,
    DxBackTextButton,
    DxChronic,
    DxMailbox,
    DxDocumentUpload,
    DxNotifications,
    DxLoadingOverlay,
    DxClaimShareStatus,
  },
  mixins: [loadingMixin, claimShareMixin],
  props: {
    claimId: {
      type: Number,
      default: null,
    },
    contractId: {
      type: String,
      default: '',
    },
    insuranceCompany: {
      type: String,
      default: 'DEXTRA',
    },
  },
  data() {
    return {
      claim: {
        id: 0,
        contract: {
          id: '',
        },
        chronicle: [],
        lawyer: {
          firstName: '',
          lastName: '',
        },
        missingDocuments: [],
      },
      inboxDextraOpen: true,
      inboxInsuredPersonOpen: true,
      messagesDextraLoading: true,
      messagesIPLoading: true,
      loading: true,
      hasErrors: false,
      formatCurrency,
    }
  },
  computed: {
    claimNotifications() {
      const notifications = []
      const missingDocumentCount =
        this.claim.missingDocuments &&
        this.claim.missingDocuments.filter((d) => !d.autocreated).length
      if (missingDocumentCount > 0) {
        notifications.push({
          headline: this.$t('missingDocumentsHint'),
        })
      }

      const unreadCountLawyer =
        this.externalLawyerInsuredPersonMessages &&
        this.externalLawyerInsuredPersonMessages.filter(
          (msg) => msg.status === 'UNREAD',
        ).length
      const unreadCountDextra =
        this.insuredPersonDextraMessages &&
        this.insuredPersonDextraMessages.filter(
          (msg) => msg.status === 'UNREAD',
        ).length

      if (unreadCountLawyer + unreadCountDextra > 0) {
        notifications.push({
          headline: this.$t('unreadNotificationHint'),
        })
      }

      return notifications
    },
    claimLocked() {
      // claim should be read-only if its status is LOCKED or if any final, not rejected expense is present
      return this.claim && this.claim.status === 'LOCKED'
    },
    canSendMessagesToLawyer() {
      return (
        !this.claimLocked &&
        this.claim &&
        this.claim.externalLawyer &&
        this.claim.externalLawyer.login
      )
    },
    sortedClaimDocuments() {
      return this.claim.documents.slice(0)
    },
    claimShared() {
      const { currentShare } = this.claim || {}
      return currentShare && currentShare.status === 'ACTIVE'
    },
    brokerName() {
      const { broker } = this.$store.state.application.currentUser
      return broker ? broker.fullName : null
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash)
        if (el) {
          el.scrollIntoView()
        }
      }
    })
  },
  methods: {
    canReplyToLawyer(sender) {
      return (
        this.claim.externalLawyer &&
        this.claim.externalLawyer.id === sender.megadexId
      )
    },

    async markAsRead(comment) {
      try {
        await this.$apollo.mutate({
          mutation: markCommentAsRead,
          variables: {
            commentId: Number(comment.id),
            claimId: Number(comment.claimId),
          },
        })
      } catch (error) {
        console.error(error)
      }
    },

    async sendMessage(text, type) {
      const isDextraMessage = type === 'DEXTRA'
      await this.$apollo.mutate({
        mutation: isDextraMessage
          ? createInsuredPersonDextraMessageMutation
          : createExternalLawyerInsuredPersonMessageMutation,
        variables: {
          claimId: Number(this.claimId),
          message: {
            text,
          },
        },
      })

      if (isDextraMessage) {
        this.$apollo.queries.insuredPersonDextraMessages.refetch()
      } else {
        this.$apollo.queries.externalLawyerInsuredPersonMessages.refetch()
      }
    },

    async replyWithMessage(item, text, type) {
      await this.$apollo.mutate({
        mutation: replyMessageMutation,
        variables: {
          claimId: Number(item.claimId),
          messageId: item.id,
          message: {
            text,
          },
        },
      })

      if (type === 'DEXTRA') {
        this.$apollo.queries.insuredPersonDextraMessages.refetch()
      } else {
        this.$apollo.queries.externalLawyerInsuredPersonMessages.refetch()
      }
    },

    async addUploadedDocument(fileId, doc) {
      try {
        const queryVariables = {
          claimId: this.claimId,
          contract: {
            id: this.contractId,
            insuranceCompany: this.insuranceCompany,
          },
          withMissingDocuments: true,
          locale: this.$i18n.locale.toUpperCase(),
        }
        const isFurtherDocument = doc.id === -1
        const kind = isFurtherDocument ? 'misc' : doc.kind
        await this.$apollo.mutate({
          mutation: isFurtherDocument
            ? uploadDocumentsMutation
            : uploadMissingDocumentsMutation,
          variables: {
            claimId: this.claimId,
            missingDocumentId: isFurtherDocument ? null : doc.id,
            uploadIds: isFurtherDocument ? null : [fileId],
            attachment: isFurtherDocument
              ? {
                  kind: 'misc',
                  uploadIds: [fileId],
                }
              : null,
          },
          update: (
            store,
            { data: { uploadMissingDocuments, uploadDocuments } },
          ) => {
            const data = store.readQuery({
              query: claimQuery,
              variables: queryVariables,
            })
            if (!isFurtherDocument) {
              data.claim.missingDocuments = data.claim.missingDocuments.filter(
                (d) => d.id !== doc.id,
              )
              data.claim.documents = [
                ...data.claim.documents,
                {
                  ...uploadMissingDocuments[0],
                  kind,
                },
              ]
            } else {
              data.claim.documents = [
                {
                  ...uploadDocuments[0],
                  kind,
                },
                ...data.claim.documents,
              ]
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: claimQuery,
              variables: queryVariables,
              data,
            })
          },
        })
        this.$notification.success({ messageKey: 'dxUpload.success' })
      } catch (error) {
        this.$notification.error({ messageKey: 'dxUpload.error' })
      }
    },
    async onToggleShare() {
      const { currentBrokerShareRequest, currentShare } = this.claim || {}
      if (currentShare && currentShare.status === 'ACTIVE') {
        const share = await this.revokeShare(this.claim.id, currentShare.id)
        if (share) {
          this.claim.currentShare = share
        }
      } else if (currentBrokerShareRequest) {
        const shareRequest = await this.acceptShareRequest(
          currentBrokerShareRequest.token,
          this.claim.id,
        )
        if (shareRequest) {
          this.claim.currentBrokerShareRequest = shareRequest
          this.claim.currentShare = {
            id: shareRequest.id,
            status: 'ACTIVE',
          }
        }
      } else {
        const share = await this.createShare(this.claim.id)
        if (share) {
          this.claim.currentShare = share
        }
      }
    },
  },
  apollo: {
    claim: {
      query: claimQuery,
      variables() {
        return {
          claimId: Number(this.claimId),
          contract: {
            id: this.contractId,
            insuranceCompany: this.insuranceCompany,
          },
          locale: this.$i18n.locale.toUpperCase(),
          withMissingDocuments: true,
        }
      },
      result() {
        this.loading = false
      },
      error() {
        this.hasErrors = true
        this.loading = false
      },
    },

    externalLawyerInsuredPersonMessages: {
      query: externalLawyerInsuredPersonMessagesQuery,
      variables() {
        return {
          claimId: Number(this.claimId),
          withDetails: true,
        }
      },
      result() {
        this.messagesIPLoading = false
      },
      error() {
        this.hasErrors = true
        this.messagesIPLoading = false
      },
    },

    insuredPersonDextraMessages: {
      query: insuredPersonDextraMessagesQuery,
      variables() {
        return {
          claimId: Number(this.claimId),
        }
      },
      result() {
        this.messagesDextraLoading = false
      },
      error() {
        this.hasErrors = true
        this.messagesDextraLoading = false
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
