import format from 'date-fns/format'
import calculateBrokerPPrivatePremiaQuery from '~/domains/brokerOffer/__gql__/queries/calculateBrokerPPrivatePremia.gql'
import createBrokerPPrivatePolicyMutation from '~/domains/brokerOffer/__gql__/mutations/createBrokerPPrivatePolicy.gql'
import saveBrokerPPrivatePolicyMutation from '~/domains/brokerOffer/__gql__/mutations/saveBrokerPPrivatePolicy.gql'
import calculateBrokerPMovePremiaQuery from '~/domains/brokerOffer/__gql__/queries/calculateBrokerPMovePremia.gql'
import createBrokerPMovePolicyMutation from '~/domains/brokerOffer/__gql__/mutations/createBrokerPMovePolicy.gql'
import saveBrokerPMovePolicyMutation from '~/domains/brokerOffer/__gql__/mutations/saveBrokerPMovePolicy.gql'
import calculateBrokerPCombiPremiaQuery from '~/domains/brokerOffer/__gql__/queries/calculateBrokerPCombiPremia.gql'
import createBrokerPCombiPolicyMutation from '~/domains/brokerOffer/__gql__/mutations/createBrokerPCombiPolicy.gql'
import saveBrokerPCombiPolicyMutation from '~/domains/brokerOffer/__gql__/mutations/saveBrokerPCombiPolicy.gql'
import calculateBrokerBBusinessPremiaQuery from '~/domains/brokerOffer/__gql__/queries/calculateBrokerBBusinessPremia.gql'
import createBrokerBBusinessPolicyMutation from '~/domains/brokerOffer/__gql__/mutations/createBrokerBBusinessPolicy.gql'
import saveBrokerBBusinessPolicyMutation from '~/domains/brokerOffer/__gql__/mutations/saveBrokerBBusinessPolicy.gql'
import calculateBrokerSmallBusinessPremiaQuery from '~/domains/brokerOffer/__gql__/queries/calculateBrokerSBSmallBusinessPremia.gql'
import createBrokerSmallBusinessPolicyMutation from '~/domains/brokerOffer/__gql__/mutations/createBrokerSBSmallBusinessPolicy.gql'
import saveBrokerSmallBusinessPolicyMutation from '~/domains/brokerOffer/__gql__/mutations/saveBrokerSBSmallBusinessPolicy.gql'
import calculateBrokerBMovePremiaQuery from '~/domains/brokerOffer/__gql__/queries/calculateBrokerBMovePremia.gql'
import createBrokerBMovePolicyMutation from '~/domains/brokerOffer/__gql__/mutations/createBrokerBMovePolicy.gql'
import saveBrokerBMovePolicyMutation from '~/domains/brokerOffer/__gql__/mutations/saveBrokerBMovePolicy.gql'
import brokerOfferFullQuery from '~/domains/brokerOffer/__gql__/queries/brokerOfferFull.gql'

const setBasicQuestions = (context, tariffType, brokerOffer) => {
  let BASIC_QUESTIONS_KEY = ''
  let basicQuestions
  const startOfInsurance = format(brokerOffer.startOfInsurance, 'dd.MM.yyyy')

  const {
    apartmentsRentedInSwitzerland,
    numberOfApartmentsForRentInSwitzerland,
    familyOption,
    branchOfIndustry,
    secondaryEmployment,
    secondaryEmploymentDescription,
    estimatedAnnualPayroll,
    estimatedAnnualSales,
    countStreetVehicles,
    countWaterVehicles,
  } = brokerOffer

  switch (tariffType) {
    case 'private':
      BASIC_QUESTIONS_KEY = 'SET_BROKER_PRIVATE_BASIC_QUESTIONS'
      basicQuestions = {
        startOfInsurance,
        familyOption,
        apartmentsRentedInSwitzerland,
        numberOfApartmentsForRentInSwitzerland,
      }
      break
    case 'business':
      BASIC_QUESTIONS_KEY = 'SET_BROKER_BUSINESS_BASIC_QUESTIONS'
      basicQuestions = {
        startOfInsurance,
        branchOfIndustry,
        secondaryEmployment,
        secondaryEmploymentDescription: secondaryEmploymentDescription || '',
        estimatedAnnualPayroll: (estimatedAnnualPayroll || {}).value.toString(),
        estimatedAnnualSales: (estimatedAnnualSales || {}).value.toString(),
      }
      break
    default:
      // b-move
      BASIC_QUESTIONS_KEY = 'SET_BROKER_BUSINESS_MOVE_BASIC_QUESTIONS'
      basicQuestions = {
        startOfInsurance,
        branchOfIndustry,
        secondaryEmployment,
        secondaryEmploymentDescription: secondaryEmploymentDescription || '',
        countStreetVehicles,
        countWaterVehicles,
      }
      break
  }

  context.commit(BASIC_QUESTIONS_KEY, basicQuestions)
}

const createContactPerson = (contactPerson) => {
  const { salutation, firstName, lastName, email, language } = contactPerson
  const { street, number, zip, city } = contactPerson.address
  const { phone, mobile } = contactPerson.contactDetails
  return {
    salutation,
    firstName,
    lastName,
    address: {
      street,
      number,
      zip,
      city,
    },
    contactDetails: {
      phone,
      mobile,
    },
    email,
    emailConfirmation: email,
    locale: language,
  }
}

const setPolicyHolderData = (context, tariffType, policyholder) => {
  let POLICY_HOLDER_DATA_KEY = ''
  let policyHolderData
  const {
    salutation,
    firstName,
    lastName,
    email,
    dateOfBirth,
    documentDeliveryMethod,
    companyName,
    language,
  } = policyholder
  const { street, number, postOfficeBox, zip, city } = policyholder.address
  const { phone, mobile } = policyholder.contactDetails
  const address = {
    street,
    number,
    postOfficeBox,
    zip,
    city,
  }
  const contactPerson = policyholder.contactPerson
    ? createContactPerson(policyholder.contactPerson)
    : { address: {}, contactDetails: {} }
  const locations = policyholder.locations.map((location) => {
    const {
      // eslint-disable-next-line no-shadow
      city,
      country,
      // eslint-disable-next-line no-shadow
      number,
      // eslint-disable-next-line no-shadow
      street,
      // eslint-disable-next-line no-shadow
      zip,
    } = location
    return {
      city,
      country,
      number,
      street,
      zip,
    }
  })

  switch (tariffType) {
    case 'private':
      POLICY_HOLDER_DATA_KEY = 'SET_BROKER_POLICY_HOLDER_DATA'
      policyHolderData = {
        salutation,
        firstName,
        lastName,
        email,
        emailConfirmation: email,
        locale: language,
        address,
        contactDetails: {
          phone,
          mobile,
        },
        dateOfBirth: dateOfBirth ? format(dateOfBirth, 'dd.MM.yyyy') : null,
        documentDeliveryMethod,
      }
      break
    case 'business':
      POLICY_HOLDER_DATA_KEY = 'SET_BROKER_BUSINESS_POLICY_HOLDER_DATA'
      policyHolderData = {
        companyName,
        address,
        contactDetails: {
          phone,
        },
        documentDeliveryMethod,
        contactPerson,
        locations,
      }
      break
    default:
      // b-move
      POLICY_HOLDER_DATA_KEY = 'SET_BROKER_BUSINESS_POLICY_HOLDER_DATA'
      policyHolderData = {
        companyName,
        address,
        contactDetails: {
          phone,
        },
        documentDeliveryMethod,
        contactPerson,
        locations,
      }
      break
  }

  context.commit(POLICY_HOLDER_DATA_KEY, policyHolderData)
}

export default {
  // P-PRIVATE -----------------------------------------------------------------------
  async CALC_BROKER_PPRIVATE_PREMIA(context, payload) {
    const formData = {
      ...payload,
      // eslint-disable-next-line max-len
      numberOfApartmentsForRentInSwitzerland:
        parseInt(payload.numberOfApartmentsForRentInSwitzerland, 10) || 0,
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.query({
        query: calculateBrokerPPrivatePremiaQuery,
        variables: {
          formData,
          offerId: context.state.offerId,
        },
      })
      if (result.data && result.data.calculateBrokerPPrivatePremia) {
        const { calculateBrokerPPrivatePremia } = result.data

        if (!calculateBrokerPPrivatePremia.validationErrors.length) {
          const premia = {
            insurable: calculateBrokerPPrivatePremia.insurable,
            components: calculateBrokerPPrivatePremia.premia || [],
            totalPremium: calculateBrokerPPrivatePremia.totalPremium
              ? {
                  value: calculateBrokerPPrivatePremia.totalPremium.value,
                  currency: calculateBrokerPPrivatePremia.totalPremium.currency,
                }
              : {},
          }
          context.commit('SET_BROKER_PREMIA', premia)
        }

        return calculateBrokerPPrivatePremia
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async CREATE_BROKER_PPRIVATE_POLICY(context, payload) {
    const {
      privateBasicQuestions,
      policyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...privateBasicQuestions,
      // eslint-disable-next-line max-len
      numberOfApartmentsForRentInSwitzerland:
        parseInt(
          privateBasicQuestions.numberOfApartmentsForRentInSwitzerland,
          10,
        ) || 0,
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: createBrokerPPrivatePolicyMutation,
        variables: {
          questionnaire,
          policyholder: policyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.createBrokerPPrivatePolicy) {
        return result.data.createBrokerPPrivatePolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async SAVE_BROKER_PPRIVATE_POLICY(context, payload) {
    const {
      privateBasicQuestions,
      policyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...privateBasicQuestions,
      // eslint-disable-next-line max-len
      numberOfApartmentsForRentInSwitzerland:
        parseInt(
          privateBasicQuestions.numberOfApartmentsForRentInSwitzerland,
          10,
        ) || 0,
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: saveBrokerPPrivatePolicyMutation,
        variables: {
          questionnaire,
          policyholder: policyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.saveBrokerPPrivatePolicy) {
        return result.data.saveBrokerPPrivatePolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },

  // P-MOVE -----------------------------------------------------------------------
  async CALC_BROKER_PMOVE_PREMIA(context, payload) {
    const formData = {
      ...payload,
    }
    delete formData.apartmentsRentedInSwitzerland
    delete formData.numberOfApartmentsForRentInSwitzerland
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.query({
        query: calculateBrokerPMovePremiaQuery,
        variables: {
          formData,
          offerId: context.state.offerId,
        },
      })
      if (result.data && result.data.calculateBrokerPMovePremia) {
        const { calculateBrokerPMovePremia } = result.data

        if (!calculateBrokerPMovePremia.validationErrors.length) {
          const premia = {
            insurable: calculateBrokerPMovePremia.insurable,
            components: calculateBrokerPMovePremia.premia || [],
            totalPremium: calculateBrokerPMovePremia.totalPremium
              ? {
                  value: calculateBrokerPMovePremia.totalPremium.value,
                  currency: calculateBrokerPMovePremia.totalPremium.currency,
                }
              : {},
          }
          context.commit('SET_BROKER_PREMIA', premia)
        }

        return calculateBrokerPMovePremia
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async CREATE_BROKER_PMOVE_POLICY(context, payload) {
    const {
      privateBasicQuestions,
      policyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...privateBasicQuestions,
    }
    delete questionnaire.apartmentsRentedInSwitzerland
    delete questionnaire.numberOfApartmentsForRentInSwitzerland
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: createBrokerPMovePolicyMutation,
        variables: {
          questionnaire,
          policyholder: policyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.createBrokerPMovePolicy) {
        return result.data.createBrokerPMovePolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async SAVE_BROKER_PMOVE_POLICY(context, payload) {
    const {
      privateBasicQuestions,
      policyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...privateBasicQuestions,
    }
    delete questionnaire.apartmentsRentedInSwitzerland
    delete questionnaire.numberOfApartmentsForRentInSwitzerland
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: saveBrokerPMovePolicyMutation,
        variables: {
          questionnaire,
          policyholder: policyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.saveBrokerPMovePolicy) {
        return result.data.saveBrokerPMovePolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },

  // P-COMBI -----------------------------------------------------------------------
  async CALC_BROKER_PCOMBI_PREMIA(context, payload) {
    const formData = {
      ...payload,
      // eslint-disable-next-line max-len
      numberOfApartmentsForRentInSwitzerland:
        parseInt(payload.numberOfApartmentsForRentInSwitzerland, 10) || 0,
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.query({
        query: calculateBrokerPCombiPremiaQuery,
        variables: {
          formData,
          offerId: context.state.offerId,
        },
      })
      if (result.data && result.data.calculateBrokerPCombiPremia) {
        const { calculateBrokerPCombiPremia } = result.data

        if (!calculateBrokerPCombiPremia.validationErrors.length) {
          const premia = {
            insurable: calculateBrokerPCombiPremia.insurable,
            components: calculateBrokerPCombiPremia.premia || [],
            totalPremium: calculateBrokerPCombiPremia.totalPremium
              ? {
                  value: calculateBrokerPCombiPremia.totalPremium.value,
                  currency: calculateBrokerPCombiPremia.totalPremium.currency,
                }
              : {},
          }
          context.commit('SET_BROKER_PREMIA', premia)
        }

        return calculateBrokerPCombiPremia
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async CREATE_BROKER_PCOMBI_POLICY(context, payload) {
    const {
      privateBasicQuestions,
      policyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...privateBasicQuestions,
      // eslint-disable-next-line max-len
      numberOfApartmentsForRentInSwitzerland:
        parseInt(
          privateBasicQuestions.numberOfApartmentsForRentInSwitzerland,
          10,
        ) || 0,
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: createBrokerPCombiPolicyMutation,
        variables: {
          questionnaire,
          policyholder: policyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.createBrokerPCombiPolicy) {
        return result.data.createBrokerPCombiPolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async SAVE_BROKER_PCOMBI_POLICY(context, payload) {
    const {
      privateBasicQuestions,
      policyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...privateBasicQuestions,
      // eslint-disable-next-line max-len
      numberOfApartmentsForRentInSwitzerland:
        parseInt(
          privateBasicQuestions.numberOfApartmentsForRentInSwitzerland,
          10,
        ) || 0,
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: saveBrokerPCombiPolicyMutation,
        variables: {
          questionnaire,
          policyholder: policyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.saveBrokerPCombiPolicy) {
        return result.data.saveBrokerPCombiPolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },

  // B-BUSINESS -----------------------------------------------------------------------
  async CALC_BROKER_BBUSINESS_PREMIA(context, payload) {
    const formData = {
      ...payload,
      estimatedAnnualPayroll: {
        currency: 'CHF',
        value:
          parseFloat(payload.estimatedAnnualPayroll.replace(',', '.')) || 0,
      },
      estimatedAnnualSales: {
        currency: 'CHF',
        value: parseFloat(payload.estimatedAnnualSales.replace(',', '.')) || 0,
      },
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.query({
        query: calculateBrokerBBusinessPremiaQuery,
        variables: {
          formData,
          offerId: context.state.offerId,
        },
      })
      if (result.data && result.data.calculateBrokerBBusinessPremia) {
        const { calculateBrokerBBusinessPremia } = result.data

        if (!calculateBrokerBBusinessPremia.validationErrors.length) {
          const premia = {
            insurable: calculateBrokerBBusinessPremia.insurable,
            components: calculateBrokerBBusinessPremia.premia || [],
            totalPremium: calculateBrokerBBusinessPremia.totalPremium
              ? {
                  value: calculateBrokerBBusinessPremia.totalPremium.value,
                  currency:
                    calculateBrokerBBusinessPremia.totalPremium.currency,
                }
              : {},
          }
          context.commit('SET_BROKER_PREMIA', premia)
        }

        return calculateBrokerBBusinessPremia
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async CREATE_BROKER_BBUSINESS_POLICY(context, payload) {
    const {
      businessBasicQuestions,
      businessPolicyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...businessBasicQuestions,
      estimatedAnnualPayroll: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualPayroll.replace(',', '.'),
          ) || 0,
      },
      estimatedAnnualSales: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualSales.replace(',', '.'),
          ) || 0,
      },
    }
    delete businessPolicyHolderData.contactPerson.address
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: createBrokerBBusinessPolicyMutation,
        variables: {
          questionnaire,
          policyholder: businessPolicyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.createBrokerBBusinessPolicy) {
        return result.data.createBrokerBBusinessPolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async SAVE_BROKER_BBUSINESS_POLICY(context, payload) {
    const {
      businessBasicQuestions,
      businessPolicyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...businessBasicQuestions,
      estimatedAnnualPayroll: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualPayroll.replace(',', '.'),
          ) || 0,
      },
      estimatedAnnualSales: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualSales.replace(',', '.'),
          ) || 0,
      },
    }
    delete businessPolicyHolderData.contactPerson.address
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: saveBrokerBBusinessPolicyMutation,
        variables: {
          questionnaire,
          policyholder: businessPolicyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.saveBrokerBBusinessPolicy) {
        return result.data.saveBrokerBBusinessPolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },

  // B-MOVE -----------------------------------------------------------------------
  async CALC_BROKER_BMOVE_PREMIA(context, payload) {
    const formData = {
      ...payload,
      countStreetVehicles: parseInt(payload.countStreetVehicles, 10) || 0,
      countWaterVehicles: parseInt(payload.countWaterVehicles, 10) || 0,
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.query({
        query: calculateBrokerBMovePremiaQuery,
        variables: {
          formData,
          offerId: context.state.offerId,
        },
      })
      if (result.data && result.data.calculateBrokerBMovePremia) {
        const { calculateBrokerBMovePremia } = result.data

        if (!calculateBrokerBMovePremia.validationErrors.length) {
          const premia = {
            insurable: calculateBrokerBMovePremia.insurable,
            components: calculateBrokerBMovePremia.premia || [],
            totalPremium: calculateBrokerBMovePremia.totalPremium
              ? {
                  value: calculateBrokerBMovePremia.totalPremium.value,
                  currency: calculateBrokerBMovePremia.totalPremium.currency,
                }
              : {},
          }
          context.commit('SET_BROKER_PREMIA', premia)
        }

        return calculateBrokerBMovePremia
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async CREATE_BROKER_BMOVE_POLICY(context, payload) {
    const {
      businessMoveBasicQuestions,
      businessPolicyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...businessMoveBasicQuestions,
      countStreetVehicles:
        parseInt(businessMoveBasicQuestions.countStreetVehicles, 10) || 0,
      countWaterVehicles:
        parseInt(businessMoveBasicQuestions.countWaterVehicles, 10) || 0,
    }
    delete businessPolicyHolderData.contactPerson.address
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: createBrokerBMovePolicyMutation,
        variables: {
          questionnaire,
          policyholder: businessPolicyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.createBrokerBMovePolicy) {
        return result.data.createBrokerBMovePolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async SAVE_BROKER_BMOVE_POLICY(context, payload) {
    const {
      businessMoveBasicQuestions,
      businessPolicyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...businessMoveBasicQuestions,
      countStreetVehicles:
        parseInt(businessMoveBasicQuestions.countStreetVehicles, 10) || 0,
      countWaterVehicles:
        parseInt(businessMoveBasicQuestions.countWaterVehicles, 10) || 0,
    }
    delete businessPolicyHolderData.contactPerson.address
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: saveBrokerBMovePolicyMutation,
        variables: {
          questionnaire,
          policyholder: businessPolicyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.saveBrokerBMovePolicy) {
        return result.data.saveBrokerBMovePolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },

  // SMALL-BUSINESS -----------------------------------------------------------------------
  async CALC_BROKER_SMALLBUSINESS_PREMIA(context, payload) {
    const formData = {
      ...payload,
      estimatedAnnualPayroll: {
        currency: 'CHF',
        value:
          parseFloat(payload.estimatedAnnualPayroll.replace(',', '.')) || 0,
      },
      estimatedAnnualSales: {
        currency: 'CHF',
        value: parseFloat(payload.estimatedAnnualSales.replace(',', '.')) || 0,
      },
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.query({
        query: calculateBrokerSmallBusinessPremiaQuery,
        variables: {
          formData,
          offerId: context.state.offerId,
        },
      })
      if (result.data && result.data.calculateBrokerSBSmallBusinessPremia) {
        const {
          calculateBrokerSBSmallBusinessPremia: calculateSmallBusinessPremia,
        } = result.data

        if (!calculateSmallBusinessPremia.validationErrors.length) {
          const premia = {
            insurable: calculateSmallBusinessPremia.insurable,
            components: calculateSmallBusinessPremia.premia || [],
            totalPremium: calculateSmallBusinessPremia.totalPremium
              ? {
                  value: calculateSmallBusinessPremia.totalPremium.value,
                  currency: calculateSmallBusinessPremia.totalPremium.currency,
                }
              : {},
          }
          context.commit('SET_BROKER_PREMIA', premia)
        }

        return calculateSmallBusinessPremia
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async CREATE_BROKER_SMALLBUSINESS_POLICY(context, payload) {
    const {
      businessBasicQuestions,
      businessPolicyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...businessBasicQuestions,
      estimatedAnnualPayroll: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualPayroll.replace(',', '.'),
          ) || 0,
      },
      estimatedAnnualSales: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualSales.replace(',', '.'),
          ) || 0,
      },
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: createBrokerSmallBusinessPolicyMutation,
        variables: {
          questionnaire,
          policyholder: businessPolicyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.createBrokerSBSmallBusinessPolicy) {
        return result.data.createBrokerSBSmallBusinessPolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
  async SAVE_BROKER_SMALLBUSINESS_POLICY(context, payload) {
    const {
      businessBasicQuestions,
      businessPolicyHolderData,
      assessmentQuestions,
      brokerPolicyData,
    } = context.state
    const questionnaire = {
      ...businessBasicQuestions,
      estimatedAnnualPayroll: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualPayroll.replace(',', '.'),
          ) || 0,
      },
      estimatedAnnualSales: {
        currency: 'CHF',
        value:
          parseFloat(
            businessBasicQuestions.estimatedAnnualSales.replace(',', '.'),
          ) || 0,
      },
    }
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: saveBrokerSmallBusinessPolicyMutation,
        variables: {
          questionnaire,
          policyholder: businessPolicyHolderData,
          riskQuestions: assessmentQuestions,
          documents: payload.documents || [],
          ...brokerPolicyData,
          offerId: parseInt(context.state.offerId, 10),
        },
      })
      if (result.data && result.data.saveBrokerSBSmallBusinessPolicy) {
        return result.data.saveBrokerSBSmallBusinessPolicy
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },

  // EDIT-OFFER -----------------------------------------------------------------------
  async EDIT_OFFER(context, payload) {
    try {
      context.commit('LOADING', true)
      const result = await this.app.apolloProvider.defaultClient.query({
        query: brokerOfferFullQuery,
        variables: {
          id: parseInt(payload.id, 10),
        },
        fetchPolicy: 'no-cache',
      })
      if (result.data && result.data.brokerOffer) {
        const {
          policyholder,
          priceBrutto,
          recentExistingOrExpectedLawsuit,
          recentExistingOrExpectedLawsuitAdditional,
          impairedAbilityToWork,
          impairedAbilityToWorkAdditional,
          contractTerminatedByInsuranceCompany,
          contractTerminatedByInsuranceCompanyAdditional,
          individualAdditionalText,
          mainExpiry,
          brokerDocumentDeliveryMethod,
          policyholderDocumentDeliveryMethod,
          paymentInterval,
        } = result.data.brokerOffer

        const assessmentQuestions = {
          recentExistingOrExpectedLawsuit,
          recentExistingOrExpectedLawsuitAdditional,
          impairedAbilityToWork,
          impairedAbilityToWorkAdditional,
          contractTerminatedByInsuranceCompany,
          contractTerminatedByInsuranceCompanyAdditional,
          individualAdditionalText,
        }

        const brokerPolicyData = {
          mainExpiry,
          brokerDocumentDeliveryMethod,
          policyholderDocumentDeliveryMethod,
          paymentInterval,
        }

        context.commit('RESET_STORE_BROKER_OFFER')
        context.commit('SET_OFFER_ID', payload.id)
        context.commit('SET_BROKER_STEP', 2)
        context.commit('SET_ACTIVE_BROKER_TARIFF', payload.tariff)
        context.commit('SET_BROKER_PREMIA', {
          totalPremium: { ...priceBrutto },
        })
        context.commit('SET_BROKER_ASSESSMENT_QUESTIONS', assessmentQuestions)
        context.commit('SET_BROKER_POLICY_EXTRA_DATA', brokerPolicyData)

        const tariffType = context.getters.GET_ACTIVE_BROKER_TARIFF_TYPE

        setBasicQuestions(context, tariffType, result.data.brokerOffer)
        setPolicyHolderData(context, tariffType, policyholder)

        return result.data.brokerOffer
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    } finally {
      context.commit('LOADING', false)
    }
  },
}
