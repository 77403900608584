<template>
  <svg
    :height="height"
    viewBox="0 0 24 24"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="m0 0h24v24h-24z" />
      <path
        d="m7.02849212 19.5774869 13.41617348-13.41617349c.7196217-.71962174.7196217-1.88635728 0-2.605979s-1.8863573-.71962172-2.605979 0l-13.41617348 13.41617349-.97724212 3.5832211zm14.84143068-17.44740974c1.5067696 1.50676957 1.5067696 3.94972389 0 5.45649348l-13.60542226 13.60542226c-.12401658.1240166-.27825052.213522-.44745652.2596691l-5.54294982 1.5117136c-.75232837.2051805-1.44265029-.4851414-1.23746983-1.2374698l1.51171359-5.5429498c.04614709-.169206.13565252-.32344.2596691-.4474565l13.60542234-13.60542234c1.5067695-1.50676954 3.9497238-1.50676955 5.4564934 0z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',

  props: {
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
  },
}
</script>
