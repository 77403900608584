<template>
  <div :class="cssClasses()" class="mailbox-item" @click="onItemClick">
    <div class="row position-relative" @click="toggleShowMessage">
      <div
        :class="{
          'col-6 order-2 order-md-1': link,
          'col-10': !link,
        }"
        class="col-md-8"
      >
        <p class="mb-0 font-weight-bold">
          {{ item.sender.fullName }}
        </p>

        <div class="timestamp mb-2">
          <p class="mb-0 d-inline-block">
            {{ getFormattedDate(item.sent_at) }}
          </p>
          <p
            v-if="showCustomerReadStatus"
            class="read-from-customer ml-md-4 d-md-inline-block"
          >
            {{ $t('read') }}
          </p>
        </div>
      </div>
      <div class="side">
        <nuxt-link v-if="link" :to="link" class="link-to-damage secondary-link">
          {{ $t('lawyerPortal.goToDamage') }}: {{ item.claim.number }}
        </nuxt-link>
        <span
          v-if="hasMoreContent || !readOnly"
          :class="{ 'mailbox-arrow--active': showMessage }"
          class="mailbox-arrow"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <template v-if="showMessage">
          <p v-html="simpleFormat(item.text)"></p>
          <dx-loading-overlay v-if="sendInProgress" />
          <div
            v-if="!sendInProgress && !readOnly && canReply"
            class="pt-5 pb-4"
          >
            <h4>
              {{ replyHeading || $t('lawyerPortal.answer') }}
            </h4>
            <dx-textarea
              v-model="replyMessageText"
              v-validate.disable="'required'"
              :error="errors.first('missingMessageBody')"
              :label="$t('myMessage')"
              data-vv-name="missingMessageBody"
            />
            <div class="text-right">
              <button
                class="btn-blank secondary-link mr-3 d-inline-block"
                @click="toggleShowMessage"
              >
                {{ $t('abort') }}
              </button>
              <button
                class="btn-blank secondary-link pr-0 d-inline-block"
                @click="submit"
              >
                {{ $t('submit') }}
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <p v-html="truncateText(item.text)"></p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { DxTextarea } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'

import dateMixin from '~/javascripts/mixins/dateMixin'
import simpleFormat from '../../../javascripts/helper/simpleFormat.js'

export default {
  name: 'DxMailboxItem',
  components: {
    DxLoadingOverlay,
    DxTextarea,
  },
  mixins: [dateMixin],
  props: {
    item: {
      type: Object,
      default: () => ({
        status: 'READ',
        sender: {},
      }),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: null,
    },
    replyHeading: {
      type: String,
      default: null,
    },
    reply: {
      type: Function,
      default: () => false,
    },
    canReply: {
      type: Boolean,
      default: true,
    },
    onUnreadItemClick: {
      type: Function,
      default: () => null,
    },
    showStatusFlag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMessage: false,
      sendInProgress: false,
      replyMessageText: null,
    }
  },
  computed: {
    isRead() {
      return this.item.status === 'READ'
    },
    currentUserId() {
      if (this.$store.getters.isExternalLawyer) {
        return this.$store.state.application.currentLawyer.id
      }
      if (this.$store.getters.isBroker) {
        return this.$store.state.application.currentBroker.id
      }
      return this.$store.state.application.currentUser.id
    },
    isOwnMessage() {
      const userId = this.currentUserId
      return (
        this.item.sender.megadexId === userId || this.item.sender.id === userId
      )
    },
    showCustomerReadStatus() {
      return this.showStatusFlag && this.isRead && !this.isOwnMessage
    },
    hasMoreContent() {
      return this.item.text && this.item.text.length > 50
    },
  },
  methods: {
    onItemClick() {
      if (this.item.status === 'UNREAD') {
        this.onUnreadItemClick(this.item)
      }
    },
    toggleShowMessage() {
      this.showMessage = !this.showMessage
    },
    truncateText(text = '') {
      let mailText = ''
      if (text.length >= 50) {
        mailText = text.slice(0, 50).replace(/\s+\S*$/, '...')
      } else {
        mailText = text
      }
      return this.simpleFormat(mailText)
    },
    async submit() {
      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          this.sendInProgress = true
          await this.reply(this.item, this.replyMessageText)

          this.$notification.success({ messageKey: 'sendMessage.success' })
          this.replyMessageText = null
          this.toggleShowMessage()
        } catch (error) {
          this.$notification.error({ messageKey: 'sendMessage.error' })
          console.error(error)
        } finally {
          this.sendInProgress = false
        }
      }
    },
    cssClasses() {
      return {
        'read': this.isRead,
        'customer-read': this.showCustomerReadStatus,
        'has-damage-link': this.hasDamageLink,
      }
    },
    simpleFormat: simpleFormat.simpleFormat,
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
