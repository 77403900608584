<template>
  <div class="link-container">
    <!--
      This hurts my soul but it is w3c conform
      Full markup in an a-tag :-/
    -->
    <router-link :to="routeName" :class="cssClasses()" class="inner-container">
      <slot name="content" />

      <span v-if="showMoreLink" class="show-more-indicator secondary-link">
        {{ $t('lookAt') }}
      </span>

      <span v-else class="show-more-indicator arrow-right" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'DxLinkContainer',
  props: {
    isBig: {
      type: Boolean,
      default: false,
    },
    hasShadow: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: [String, Object],
      default: 'SelfService',
    },
    showMoreLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cssClasses() {
      return {
        'link-container--big': this.isBig,
        'link-container--shadow': this.hasShadow,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
