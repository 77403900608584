<template>
  <div class="container-fluid text-center">
    <div class="content-wrap-x-small content-pad content-pad--big-top">
      <div class="check-wrap" />
      <h2 class="mt-3 mb-4">
        {{ $t('brokerOffer.thanks.headline') }}
      </h2>
      <template v-if="!underwriting">
        <p>{{ $t('brokerOffer.thanks.text_01') }}</p>
        <p>{{ $t('brokerOffer.thanks.text_02') }}</p>
      </template>
      <p v-else>
        {{ $t('brokerOffer.thanks.withUnderwriting') }}
      </p>
      <dx-button
        :text="$t('toDashboard')"
        :route-name="localePath('PartnerPortal')"
      />
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'BrokerOfferThankYou',
  meta: {
    offerStep: 3,
  },
  components: {
    DxButton,
  },
  computed: {
    underwriting() {
      return this.$route.query.underwriting === 'true'
    },
  },
  mounted() {
    this.$store.commit('RESET_STORE_BROKER_OFFER')
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsschutz-antragsstrecke/willkommen-bei-dextra/',
      fr: '/portailpartenaires/protection-juridique-formulaire-de-demande/bienvenue-chez-dextra/',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
