import jwtTools from '~/javascripts/helper/jwtTools'

export default function ({store, redirect, route }) { // eslint-disable-line
  const { isGmLimited, isExternalLawyer, isBroker } = store.getters

  // Check for invitationTokens
  const { invitationToken, lawyerInvitationToken, logout } = route.query

  /*  this regexp below is valid for routes like this
      SelfService-ReportDamage-{******}___de
      but will fail on any other
  */
  const routeMatchesIndex = /index___[de|fr|it]/
  const routeMatchesReportDamage = /SelfService-ReportDamage-[\D]+___[de|fr|it]/
  const routeMatchesSelfService = /SelfService.*___[de|fr|it]/
  const routeMatchesLawyerPortal = /LawyerPortal.*___[de|fr|it]/
  const routeMatchesPartnerPortal = /PartnerPortal.*___[de|fr|it]/
  const routeMatchesLogin = /Login___[de|fr|it]/
  const routeMatchesBrokerOffer = /^BrokerOffer.*___[de|fr|it]/
  const routeMatchesTos = /LawyerPortal-TermsOfService___[de|fr|it]/
  const routeMatchesRequest = /Request.*___[de|fr|it]/
  const isIndexRoute = routeMatchesIndex.test(route.name)
  const isValidGmLimitedRoute = routeMatchesReportDamage.test(route.name)
  const isSelfServicePage = routeMatchesSelfService.test(route.name)
  const isLawyerPortalPage = routeMatchesLawyerPortal.test(route.name)
  const isPartnerPortalPage = routeMatchesPartnerPortal.test(route.name)
  const isBrokerOfferPage = routeMatchesBrokerOffer.test(route.name)
  const isLoginPage = routeMatchesLogin.test(route.name)
  const isTosRoute = routeMatchesTos.test(route.name)
  const isRequestRoute = routeMatchesRequest.test(route.name)
  const { currentPortal } = store.state.authentication
  const isLoggedIn =
    store.state.authentication.token.jwt &&
    jwtTools.isValid(store.state.authentication.token.jwt) &&
    !logout &&
    currentPortal
  const isPublic = route.meta.some((m) => m.isPublic)
  const loginToken = route.query.token

  // If login via Token
  if (!isLoginPage && !isRequestRoute && loginToken) {
    redirect({
      path: store.app.localePath('Login'),
      query: { token: loginToken },
    })
  }

  // Redirect Index route to login
  if (isIndexRoute && !loginToken) {
    redirect(store.app.localePath('Login'))
  }

  if (isLoggedIn && isLoginPage && !loginToken) {
    if (
      !isGmLimited &&
      !isExternalLawyer &&
      !isBroker &&
      !invitationToken &&
      !logout
    ) {
      redirect(store.app.localePath('SelfService'))
    }
    if (isExternalLawyer && !isBroker && !lawyerInvitationToken && !logout) {
      redirect(store.app.localePath('LawyerPortal'))
    }
    if (!isExternalLawyer && isBroker && !logout) {
      redirect(store.app.localePath('PartnerPortal'))
    }
  }

  // Redirect to their current portal
  if (isLoggedIn && !loginToken) {
    if (currentPortal === 'CUSTOMER_CENTER' && !isSelfServicePage) {
      redirect(store.app.localePath('SelfService'))
    } else if (currentPortal === 'LAWYER_PORTAL' && !isLawyerPortalPage) {
      redirect(store.app.localePath('LawyerPortal'))
    } else if (
      currentPortal === 'BROKER_PORTAL' &&
      !isPartnerPortalPage &&
      !isBrokerOfferPage
    ) {
      redirect(store.app.localePath('PartnerPortal'))
    }
  }

  // show "blocking" terms-of-service page if not yet accepted
  if (
    isLoggedIn &&
    isExternalLawyer &&
    !isTosRoute &&
    !isPublic &&
    !loginToken
  ) {
    if (!store.state.application.currentLawyer.termsOfServiceAccepted) {
      redirect(store.app.localePath('LawyerPortal-TermsOfService'))
    }
  }

  if (isGmLimited && !isValidGmLimitedRoute) {
    //  https://confluence.sumcumo.net/display/DEX/Dextra+Kundenkonto+Relaunch
    if (isSelfServicePage) {
      redirect(store.app.localePath('SelfService-ReportDamage-MyInfo'))
    }
  }
}
