<template>
  <div class="wrapper">
    <svg :height="height" :width="height" class="svg">
      <circle
        fill="transparent"
        class="baseCircle"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
      <circle
        fill="transparent"
        class="circle"
        :stroke-dasharray="circumference + ' ' + circumference"
        :stroke-dashoffset="strokeDashoffset"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>
    <div class="content">
      <span>{{ stepsCurrent }}/{{ stepsTotal }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DxScipSalesProgressIndicator',
  components: {},
  props: {
    stepsCurrent: {
      type: Number,
      default: 0,
    },
    stepsTotal: {
      type: Number,
      default: 42,
    },
  },

  data() {
    return {
      height: 44,
      radius: 22,
      stroke: 2,
    }
  },

  computed: {
    progress() {
      return this.stepsCurrent / this.stepsTotal
    },

    normalizedRadius() {
      return this.radius - this.stroke * 2
    },

    circumference() {
      return this.normalizedRadius * 2 * Math.PI
    },

    strokeDashoffset() {
      return this.circumference - this.progress * this.circumference
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;

  @include media-breakpoint-up(lg) {
    transform: scale(calc(50 / 44));
    transform-origin: center center;
  }
}
.svg,
.content {
  display: block;
  grid-row: 1;
  grid-column: 1;
}

.svg {
  z-index: 0;
}
.content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: $color-indigo;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: rem(16);
  line-height: 1;
  z-index: 1;
}

.baseCircle {
  stroke: $color-azureish;
}
.circle {
  display: block;
  fill: transparent;
  stroke: $color-turquoise-surf;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: var(--baseTransition);
}
</style>
