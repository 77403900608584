import * as Sentry from '@sentry/browser'
import { SET_TOKEN } from '~/domains/authentication/__store__/mutations'
import jwtTools from '~/javascripts/helper/jwtTools'

export const LOGIN_PATH = 'Login'

export default function (context) {
  const routeMatchesIndex = /index___[de|fr|it]/
  const routeMatchesLogin = /Login___[de|fr|it]/
  const isIndexRoute = routeMatchesIndex.test(context.route.name)
  const isLoginRoute = routeMatchesLogin.test(context.route.name)
  const { logout } = context.route.query

  const isPublic = context.route.meta.some((m) => m.isPublic)
  const { isGmLimited } = context.store.getters
  const { currentPortal, token } = context.store.state.authentication
  const { jwt } = token
  const { currentUser, currentLawyer, currentBroker } =
    context.store.state.application
  const userId =
    (currentUser && currentUser.id) ||
    (currentLawyer && currentLawyer.id) ||
    (currentBroker && currentBroker.id)

  Sentry.configureScope((scope) => {
    scope.setUser({ id: userId })
  })

  if (jwt) {
    if (!jwtTools.isValid(jwt) && !isPublic) {
      // removes token from store to be clear
      // eslint-disable-next-line no-shadow
      const token = {
        jwt: null,
      }
      context.store.commit(SET_TOKEN, { token })
      context.redirect({
        path: context.store.app.localePath(LOGIN_PATH),
        query: { logout: true, reason: 'sessionExpired' },
      })
    }
  }
  if (!jwt && !isPublic && !isIndexRoute) {
    const { fullPath } = context.route
    context.next({
      path: context.store.app.localePath(LOGIN_PATH),
      query:
        fullPath && fullPath.length > 1
          ? { redirect: context.route.fullPath }
          : {},
    })
  }
  if (
    jwt &&
    jwtTools.isValid(jwt) &&
    !currentPortal &&
    !logout &&
    !isGmLimited
  ) {
    if (isLoginRoute || isIndexRoute || !isPublic) {
      context.redirect({
        path: context.store.app.localePath('SelectPortal'),
      })
    }
  }
}
