<template>
  <dx-partner-registration />
</template>

<script>
import DxPartnerRegistration from '~/domains/partnerPortal/components/DX-PartnerRegistration'

export default {
  name: 'PartnerPortalRegistration',
  components: {
    DxPartnerRegistration,
  },
  meta: {
    isPublic: true,
  },
  layout: 'modulaStylePlain',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/registrieren',
      fr: '/portailpartenaires/enregistrer',
      it: '/portaledeipartner/registro',
    },
  },
}
</script>
