<template>
  <div class="container-fluid">
    <div class="content-wrap-small content-pad">
      <div class="logo mb-3" />

      <dx-lang-switcher :tabs="true" />

      <h3 class="text-center">
        {{ $t('groupeMutuel.headline') }}
      </h3>

      <p class="text-center mb-5">
        {{ $t('groupeMutuel.hintAlreadyRegistered') }}

        <a
          :href="$t('loginPage.customerLoginPath')"
          class="mb-3 mr-md-4 mb-md-0 d-inline-block secondary-link"
        >
          {{ $t('loginPage.customerLoginPath') }}
        </a>
      </p>

      <p class="text-center mb-5">
        {{ $t('groupeMutuel.subtext') }}
      </p>

      <dx-registration-gm />
    </div>
  </div>
</template>

<script>
import DxLangSwitcher from '~/components/DX-LangSwitcher'
import DxRegistrationGm from '~/domains/user/components/DX-RegistrationGm'

export default {
  name: 'SelfServiceGroupeMutuel',
  meta: {
    isPublic: true,
  },
  components: {
    DxLangSwitcher,
    DxRegistrationGm,
  },
  nuxtI18n: {
    paths: {
      de: '/groupemutuel',
      fr: '/groupemutuel',
      it: '/groupemutuel',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
