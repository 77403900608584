<template>
  <div>
    <form @submit.prevent="submit">
      <dx-input
        v-model="email"
        v-validate.disable="'required|email'"
        :error="errors.first('invalidEmail')"
        :label="$t('partnerPortal.storeEmail')"
        class="mb-3"
        data-vv-name="invalidEmail"
        autocomplete="email"
        @enter.prevent="submit"
      />
      <div class="text-right">
        <button
          tabindex="0"
          class="secondary-link btn-blank mr-3"
          type="button"
          @click="onCancel"
        >
          {{ $t('abort') }}
        </button>
        <button
          tabindex="0"
          class="secondary-link btn-blank"
          type="submit"
          @click.prevent="submit"
        >
          {{ $t('save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { DxInput } from '@sumcumo/dextra-frontend-component-lib-old'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import createCustomerEmailMutation from '~/domains/partnerPortal/__gql__/mutations/createCustomerEmailMutation.gql'

export default {
  name: 'DxAddEmail',
  components: {
    DxInput,
  },
  mixins: [validationErrorsMixin, loadingMixin],
  props: {
    onSuccess: {
      type: Function,
      default: () => null,
    },
    onCancel: {
      type: Function,
      default: () => null,
    },
    onError: {
      type: Function,
      default: () => null,
    },
    dexId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      email: null,
    }
  },
  methods: {
    async submit() {
      const isValid = await this.$validator.validateAll()
      let result = false

      if (isValid) {
        const variables = {
          email: this.email,
          dexId: this.dexId,
        }
        this.setGlobalLoading(true)
        try {
          const { data } = await this.$apollo.mutate({
            mutation: createCustomerEmailMutation,
            variables,
          })
          if (
            data &&
            data.createCustomerEmail &&
            data.createCustomerEmail.success
          ) {
            result = true
            this.$emit('email-saved')
            this.onSuccess()
          } else {
            this.$notification.error({
              messageKey: this.getValidationErrorMessageKey(
                result.data.createCustomerEmail.validationErrors[0],
              ),
            })
          }
        } catch (error) {
          console.error(error)
          result = false
        } finally {
          this.setGlobalLoading(false)
        }
      }
    },
  },
}
</script>
