<template>
  <div>
    <!-- Change email -->
    <div class="row user-data-block">
      <div class="col">
        <dx-edit-data-toggle
          :headline="$t('email')"
          tag-type="h4"
          :callback="changeEmail"
        >
          <template slot="data-dynamic" slot-scope="{ editMode }">
            <dx-email-form ref="changeEmailForm" :editable="editMode" />
          </template>
        </dx-edit-data-toggle>
      </div>
    </div>

    <!-- Change password -->
    <div class="row user-data-block">
      <div class="col">
        <dx-edit-data-toggle
          :headline="$t('password')"
          tag-type="h4"
          :callback="changePassword"
        >
          <template slot="data-dynamic" slot-scope="{ editMode }">
            <dx-password-form ref="changePasswordForm" :editable="editMode" />
          </template>
        </dx-edit-data-toggle>
      </div>
    </div>
  </div>
</template>

<script>
import DxEditDataToggle from '~/components/DX-EditDataToggle'
import DxPasswordForm from '~/domains/authentication/components/DX-PasswordForm'
import DxEmailForm from '~/domains/authentication/components/DX-EmailForm'

export default {
  name: 'DxChangeLoginDetails',
  components: {
    DxEditDataToggle,
    DxPasswordForm,
    DxEmailForm,
  },
  methods: {
    async changeEmail() {
      const result = await this.$refs.changeEmailForm.changeEmail()
      return result
    },
    async changePassword() {
      const result = await this.$refs.changePasswordForm.changePassword()
      return result
    },
  },
}
</script>
