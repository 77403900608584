<template>
  <div class="mb-3">
    <h4 class="icon mb-3">
      {{ $t('damageRequests') }}
    </h4>
    <div
      v-if="
        Array.isArray(externalLawyerInvitations) &&
        externalLawyerInvitations.length > 0
      "
    >
      <dx-link-container
        v-for="invitation in externalLawyerInvitations"
        :key="invitation.id"
        :show-more-link="true"
        :is-big="true"
        :has-shadow="true"
        :route-name="{
          path: localePath('LawyerPortal-NewDamageCase'),
          query: {
            lawyerInvitationToken: invitation.token,
          },
        }"
        class="mb-3"
      >
        <template slot="content">
          <div class="d-md-flex justify-content-start align-items-center">
            <div class="flex-item">
              {{ invitation.claim.insuredPerson.fullName }}
            </div>
            <div class="flex-item">
              {{ invitation.claim.number }}
            </div>
            <div class="flex-item">
              {{ $t('acceptUntil') }}
              <span class="font-weight-bold">
                {{ getFormattedDateTime(invitation.expiredAt) }}
              </span>
            </div>
          </div>
        </template>
      </dx-link-container>
    </div>
    <div v-if="!loading && !externalLawyerInvitations.length">
      {{ $t('lawyerPortal.noClaimInvitationsMessage') }}
    </div>
    <dx-loading-overlay v-if="loading" />
  </div>
</template>

<script>
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxLinkContainer from '~/components/DX-LinkContainer/index'
import externalLawyerInvitations from '~/domains/externalLawyer/__gql__/queries/externalLawyerInvitations.gql'

import dateMixin from '~/javascripts/mixins/dateMixin'

export default {
  name: 'DxDamageRequests',
  components: {
    DxLinkContainer,
    DxLoadingOverlay,
  },
  mixins: [dateMixin],
  data() {
    return {
      externalLawyerInvitations: [],
      loading: true,
    }
  },
  apollo: {
    externalLawyerInvitations: {
      query: externalLawyerInvitations,
      fetchPolicy: 'network-only',
      result() {
        this.loading = false
      },
      error(error) {
        this.loading = false
        this.$notification.error({ messageKey: 'notifications.error.message' })
        throw error
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
