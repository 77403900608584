<template>
  <div class="d-md-flex justify-content-center">
    <dx-icon-box
      class="mb-3 mb-md-0"
      icon-type="policies"
      animated
      @click="
        $router.push({ path: localePath('PartnerPortal-ClosedPolicies') })
      "
    >
      <div class="mt-3">
        {{ $t('partnerPortal.customerWith') }}
      </div>
      <h4>
        {{ $t('partnerPortal.closedPolicies') }}
      </h4>
      <div class="mt-4 text-right">
        <span class="secondary-link">
          {{ $t('partnerPortal.toPolicies') }}
        </span>
      </div>
    </dx-icon-box>

    <dx-icon-box
      icon-type="pending-offer-circle"
      animated
      @click="$router.push({ path: localePath('PartnerPortal-OpenOffers') })"
    >
      <div class="mt-3">
        {{ $t('partnerPortal.customerWith') }}
      </div>
      <h4>
        {{ $t('partnerPortal.pendingOffers') }}
      </h4>
      <div class="mt-4 text-right">
        <span class="secondary-link">
          {{ $t('partnerPortal.toOffers') }}
        </span>
      </div>
    </dx-icon-box>
  </div>
</template>

<script>
import DxIconBox from '~/components/DX-IconBox'

export default {
  name: 'TypeOfClient',
  components: {
    DxIconBox,
  },
}
</script>
