<template>
  <div class="scip-sales-navigation">
    <a
      v-if="!isFirstStep"
      key="previous"
      type="button"
      class="font-white font-small back-button"
      @click="$emit('previous')"
    >
      {{ t('back') }}
    </a>
    <dx-button
      v-if="
        channel !== 'employee' ||
        !dexId ||
        !(dexId.policyId || dexId.amendmentId)
      "
      key="save"
      type="button"
      theme="primary"
      :text="saveLabel"
      @click="$emit('save')"
    />

    <dx-button
      key="submit"
      type="submit"
      theme="primary"
      :disabled="isLastStep && isPriceLoading"
      :text="nextLabel"
    />
  </div>
</template>

<script>
import { defineComponent, toRefs, computed } from '@vue/composition-api'

import { DxButton } from '@sumcumo/dextra-frontend-component-lib'

import { useScipTranslationWithFallback } from '../utils'

// eslint-disable-next-line vue/require-direct-export
export default defineComponent({
  name: 'DxScipSalesNavigation',
  components: {
    DxButton,
  },
  props: {
    formContext: {
      type: Object,
      required: true,
    },
    channel: {
      type: String,
      required: true,
    },
    isFirstStep: {
      type: Boolean,
      required: true,
    },
    isLastStep: {
      type: Boolean,
      required: true,
    },
    isPriceLoading: {
      type: Boolean,
      required: true,
    },
    dexId: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    const { t, te } = translation
    const saveLabel = computed(() => {
      const legacyKey = `save.${props.channel}`
      return te(legacyKey) ? t(legacyKey) : t('save')
    })

    const nextLabel = computed(() => {
      const legacyKey = props.isLastStep
        ? `submit.${props.channel}`
        : `continue.${props.channel}`
      const formKey = props.isLastStep ? 'submit' : 'continue'
      return te(legacyKey) ? t(legacyKey) : t(formKey)
    })

    return {
      ...translation,
      saveLabel,
      nextLabel,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

$font-family-old: 'Roboto', sans-serif;

.scip-sales-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // stylelint-disable-next-line
  div:only-child {
    width: 100%;
    text-align: right;
  }

  ::v-deep .cmp-btn,
  ::v-deep .cmp-btn--inner {
    border-color: $color-crayola;
    min-width: rem(150);
    height: rem(40);
    background: $color-crayola;
    color: white;
    font-family: $font-family-old;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      height: rem(60);
    }
  }
}

.back-button {
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;
  }
}
</style>
