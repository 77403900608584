/* eslint-disable no-param-reassign, no-unused-vars */
import format from 'date-fns/format'
import getDefaultState from './initialState'

export default {
  RESET_STORE_BROKER_OFFER(state) {
    Object.assign(state, getDefaultState())
  },
  SET_OFFER_ID(state, payload) {
    state.offerId = payload
  },
  SET_ACTIVE_BROKER_TARIFF(state, payload) {
    const legalTariffs = [
      'p-private',
      'p-move',
      'p-combi',
      'b-business',
      'b-move',
      'small-business',
    ]
    state.activeTariff = legalTariffs.includes(payload.toLowerCase())
      ? payload.toLowerCase()
      : legalTariffs[0]
  },
  SET_BROKER_STEP(state, payload) {
    if (payload > 0 && payload <= 3) {
      state.step = payload
    }
  },
  SET_BROKER_PREMIA(state, payload) {
    state.premia.insurable = payload.insurable || true
    state.premia.components = payload.components || []
    state.premia.totalPremium = {
      value: payload.totalPremium.value,
      currency: payload.totalPremium.currency,
    }
  },
  SET_BROKER_PRIVATE_BASIC_QUESTIONS(state, payload) {
    Object.assign(state.privateBasicQuestions, payload)
    if (!state.brokerPolicyData.mainExpiry) {
      const startDate = payload.startOfInsurance.split('.')
      state.brokerPolicyData.mainExpiry = `${startDate[0]}.${startDate[1]}.`
    }
  },
  SET_BROKER_BUSINESS_BASIC_QUESTIONS(state, payload) {
    Object.assign(state.businessBasicQuestions, payload)
    if (!state.brokerPolicyData.mainExpiry) {
      const startDate = payload.startOfInsurance.split('.')
      state.brokerPolicyData.mainExpiry = `${startDate[0]}.${startDate[1]}.`
    }
  },
  SET_BROKER_BUSINESS_MOVE_BASIC_QUESTIONS(state, payload) {
    Object.assign(state.businessMoveBasicQuestions, payload)
    if (!state.brokerPolicyData.mainExpiry) {
      const startDate = payload.startOfInsurance.split('.')
      state.brokerPolicyData.mainExpiry = `${startDate[0]}.${startDate[1]}.`
    }
  },
  SET_BROKER_POLICY_HOLDER_DATA(state, payload) {
    Object.assign(state.policyHolderData, payload)
  },
  SET_BROKER_BUSINESS_POLICY_HOLDER_DATA(state, payload) {
    Object.assign(state.businessPolicyHolderData, payload)
  },
  SET_BROKER_ASSESSMENT_QUESTIONS(state, payload) {
    Object.assign(state.assessmentQuestions, payload)
  },
  SET_BROKER_POLICY_HOLDER_NO_EMAIL(state, payload) {
    state.noEmail = payload
    if (state.brokerPolicyData.policyholderDocumentDeliveryMethod === 'EMAIL') {
      state.brokerPolicyData.policyholderDocumentDeliveryMethod = null
    }
  },
  SET_BROKER_POLICY_EXTRA_DATA(state, payload) {
    Object.assign(state.brokerPolicyData, payload)
  },
}
