<template>
  <div class="container-fluid modulaStyles">
    <div class="content-wrap-small content-pad">
      <div class="mt-3 mt-md-1">
        <span class="arrow-left overview" @click="historyBack()">
          {{ $t('backToLogin') }}
        </span>
      </div>
    </div>
    <div class="content-wrap-small content-pad">
      <div class="mb-4 mb-md-5">
        <h3 class="headline">
          {{ $t('loginPage.noCredentialsHead') }}
        </h3>
        <p class="subheadline">
          {{ $t('loginPage.noCredentialsSub') }}
        </p>
      </div>

      <form @submit.prevent>
        <dx-radio-buttons
          id="salutation"
          v-model="offlineRegistrationData.salutation"
          v-validate.disable="'required'"
          :options="salutationData"
          :error="errors.first('salutation')"
          name="salutation"
          class="mb-4"
          inverted
          data-vv-name="salutation"
        />
        <dx-input
          v-if="isCompany"
          key="companyName-input"
          v-model="offlineRegistrationData.companyName"
          v-validate.disable="{
            required: isCompany ? true : false,
            min: 2,
          }"
          :error="errors.first('companyName')"
          :label="$t('company')"
          inverted
          class="mb-4"
          data-vv-name="companyName"
          autocomplete="organization"
        />
        <dx-input
          v-if="!isCompany"
          key="firstName-input"
          v-model="offlineRegistrationData.firstName"
          v-validate.disable="{
            required: isCompany ? false : true,
            min: 2,
          }"
          :error="errors.first('firstName')"
          :label="$t('firstName')"
          inverted
          class="mb-4"
          data-vv-name="firstName"
          autocomplete="given-name"
        />
        <dx-input
          v-if="!isCompany"
          key="lastName-input"
          v-model="offlineRegistrationData.lastName"
          v-validate.disable="{
            required: isCompany ? false : true,
            min: 2,
          }"
          :error="errors.first('lastName')"
          :label="$t('lastName')"
          inverted
          class="mb-4"
          data-vv-name="lastName"
          autocomplete="family-name"
        />
        <dx-input
          v-model="offlineRegistrationData.zip"
          v-validate.disable="'required|min:4'"
          :error="errors.first('zip')"
          :label="$t('plz')"
          data-vv-name="zip"
          class="mb-4"
          inverted
        />
        <dx-input
          v-model="offlineRegistrationData.policyNumber"
          v-validate.disable="'required|min:1'"
          :error="errors.first('policyNumber')"
          :label="$t('policyNumber')"
          data-vv-name="policyNumber"
          class="mb-4"
          inverted
        />
        <dx-input
          v-model="offlineRegistrationData.email"
          v-validate.disable="'required|email'"
          :label="$t('email')"
          :error="errors.first('email')"
          data-vv-name="email"
          name="email"
          class="mb-4"
          autocomplete="email"
          inverted
        />
        <div class="text-right">
          <dx-button
            :text="$t('submit')"
            class="secondary-link btn-blank"
            @click="sendFormData"
          >
            {{ $t('submit') }}
          </dx-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  DxButton,
  DxInput,
  DxRadioButtons,
} from '@sumcumo/dextra-frontend-component-lib'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import createAccountRequest from '~/domains/user/__gql__/mutations/createAccountRequest.gql'

export default {
  name: 'DxOfflineRegistrationForm',
  components: {
    DxButton,
    DxInput,
    DxRadioButtons,
  },
  mixins: [validationErrorsMixin],
  data() {
    return {
      isCompany: false,
      offlineRegistrationData: {
        email: '',
        salutation: 'MALE',
        companyName: '',
        firstName: '',
        lastName: '',
        policyNumber: '',
      },
      salutationData: [
        {
          name: this.$t('male'),
          value: 'MALE',
        },
        {
          name: this.$t('female'),
          value: 'FEMALE',
        },
        {
          name: this.$t('family'),
          value: 'FAMILY',
        },
        {
          name: this.$t('company'),
          value: 'COMPANY',
        },
      ],
    }
  },
  watch: {
    'offlineRegistrationData.salutation': function setIsCompany(val) {
      if (val === 'COMPANY') {
        this.isCompany = true
        this.offlineRegistrationData.firstName = ''
        this.offlineRegistrationData.lastName = ''
      } else {
        this.isCompany = false
        this.offlineRegistrationData.companyName = ''
      }
    },
  },
  methods: {
    historyBack() {
      window.history.back()
    },
    resetForm() {
      this.offlineRegistrationData = {
        email: '',
        salutation: 'MALE',
        companyName: '',
        firstName: '',
        lastName: '',
        policyNumber: '',
      }
      this.$validator.reset()
    },
    async sendFormData() {
      const collectValidation = await this.$validator.validateAll()
      if (collectValidation) {
        const variables = {
          accountRequest: {
            ...this.offlineRegistrationData,
          },
        }
        try {
          const result = await this.$apollo.mutate({
            mutation: createAccountRequest,
            variables,
          })

          if (result.data && result.data.createAccountRequest) {
            if (result.data.createAccountRequest.success) {
              this.$notification.success({
                messageKey: 'offlineRegistration.offlineRegistrationSuccess',
              })
              this.resetForm()
            } else if (result.data.createAccountRequest.validationErrors) {
              result.data.createAccountRequest.validationErrors.forEach(
                (err) => {
                  this.$validator.errors.add(this.getServerValidationError(err))
                },
              )
            } else {
              this.$notification.error('offlineRegistration.errorMessage')
            }
          } else {
            this.$notification.error('offlineRegistration.errorMessage')
          }
        } catch (error) {
          this.$notification.error('offlineRegistration.errorMessage')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/styles/newModulaTypoStyles.scss';
</style>
