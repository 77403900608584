<template>
  <div>
    <form @submit.prevent="login">
      <dx-input
        :id="'username'"
        v-model.trim="form.username"
        v-validate.disable="'required'"
        :error="errors.first('invalidEmail')"
        :label="$t('loginPage.userLabel')"
        inverted
        data-vv-name="invalidEmail"
        class="mb-4"
        autocomplete="username"
      />
      <dx-input
        :id="'password'"
        v-model.trim="form.password"
        v-validate.disable="'required'"
        :error="errors.first('password')"
        :label="$t('loginPage.pwLabel')"
        data-vv-name="password"
        inverted
        class="mb-4"
        type="password"
        autocomplete="current-password"
      />
      <div class="d-flex justify-content-between mt-4">
        <nuxt-link :to="localePath('PwReset')" class="pw-reset">
          {{ $t('loginPage.pwReset') }}
        </nuxt-link>

        <dx-button
          :text="$t('login')"
          class="d-md-inline-block"
          type="submit"
        />
      </div>
    </form>
    <h5 v-if="!isLegalCase" class="newRegistrationHeadline mt-6">
      {{ $t('loginPage.newRegistration.headline') }}
    </h5>
    <div v-if="!isLegalCase" class="registrationButtons">
      <dx-button
        inverted
        theme="secondary"
        :route-name="localePath('SelfService-OfflineRegistration')"
        :text="$t('loginPage.newRegistration.customer')"
      />
      <dx-button
        inverted
        theme="secondary"
        :route-name="localePath('PartnerPortal-Registration')"
        :text="$t('loginPage.newRegistration.partner')"
      />
      <dx-button
        inverted
        theme="secondary"
        :external-link="`${websiteUrl}${$t(
          'loginPage.newRegistration.lawyerUrl',
        )}`"
        :text="$t('loginPage.newRegistration.lawyer')"
      />
    </div>
  </div>
</template>

<script>
import isPast from 'date-fns/isPast'
import { DxButton, DxInput } from '@sumcumo/dextra-frontend-component-lib'
import jwtTools from '~/javascripts/helper/jwtTools'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'
import {
  LOGIN,
  GET_CONFLICT_DETAILS,
  REGISTER_AND_ACCEPT_CONTRACT_INVITATION,
} from '~/domains/authentication/__store__/actions'
import { RESET_STORE } from '~/domains/application/__store__/actions'
import invitationForTokenQuery from '~/domains/user/__gql__/queries/invitationForToken.gql'
import invitationExternalLawyerForTokenQuery from '~/domains/externalLawyer/__gql__/queries/invitationExternalLawyerForToken.gql'

export default {
  name: 'DxLogin',
  components: {
    DxButton,
    DxInput,
  },
  mixins: [loadingMixin, apolloErrorsMixin],
  props: {
    invitationToken: {
      type: String,
      default: null,
    },
    lawyerInvitationToken: {
      type: String,
      default: null,
    },
    remoteLoginToken: {
      type: String,
      default: null,
    },
    isLegalCase: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      websiteUrl: process.env.DEXTRA_WEBSITE_URL,
      form: {
        username: '',
        password: '',
      },
    }
  },
  mounted() {
    this.setGlobalLoading(false)
    this.checkInvitationToken()
    this.checkLawyerInvitationToken()
    this.checkRemoteLoginToken()
  },
  methods: {
    isTokenExpired(token) {
      return isPast(token)
    },
    async checkInvitationToken() {
      if (!this.invitationToken) {
        return
      }
      const variables = {
        token: this.invitationToken,
      }
      try {
        const result = await this.$apollo.query({
          query: invitationForTokenQuery,
          variables,
        })
        const { isUserKnown, expiredAt, status } =
          result.data.invitationForToken
        if (status === 'CANCELED') {
          this.$notification.error({
            messageKey: 'loginPage.invitationError.customer.canceled',
          })
        } else if (this.isTokenExpired(expiredAt) || status !== 'PENDING') {
          this.$notification.error({
            messageKey: 'loginPage.invitationError.customer.expired',
          })
        } else if (isUserKnown) {
          const { hasValidToken } = this.$store.getters
          if (hasValidToken) {
            // user is already logged in, update & redirect to selfservice and display notification
            this.acceptInvitationAndRedirect(this.invitationToken)
          } else {
            // user exists but is currently not logged in, prompt for login
            this.$notification.info({ messageKey: 'invitation.promptForLogin' })
          }
        } else {
          // Display registration form, unknown person
          // We have to use route name instead of path to support route params
          this.$store.$router.push({
            name: `Registration___${this.$i18n.locale}`,
            query: { invitationToken: this.invitationToken },
          })
        }
      } catch (err) {
        const sanitizedErrors = this.handleApolloError(err)
        // remove invalid invitationToken from Query
        const query = { ...this.$route.query }
        delete query.invitationToken
        this.$router.replace({ query })

        if (this.$store.state.authentication.token.jwt) {
          this.$router.push(this.localePath('SelfService'))
        }
        if (sanitizedErrors.graphQLError.message === '404 Not Found') {
          this.$notification.error({ messageKey: 'invitation.invalidToken' })
        } else {
          console.error(err)
        }
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async acceptInvitationAndRedirect(invitationToken) {
      const variables = {
        invitationToken,
      }
      try {
        await this.$store.dispatch(REGISTER_AND_ACCEPT_CONTRACT_INVITATION, {
          variables,
          apollo: this.$apollo.provider.defaultClient,
        })
        this.$router.push(this.localePath('SelfService'))
        this.$notification.success({ messageKey: 'invitation.acceptSuccess' })
      } catch (err) {
        this.setGlobalLoading(false)
        console.error(err)
        this.$notification.error({ messageKey: 'invitation.acceptError' })
      }
    },
    async checkLawyerInvitationToken() {
      if (!this.lawyerInvitationToken) {
        return
      }
      const { jwt } = this.$store.state.authentication.token
      if (jwt && !jwtTools.isValid(jwt)) {
        this.$store.dispatch(RESET_STORE)
      }
      const variables = {
        token: this.lawyerInvitationToken,
        withClaim: false,
      }
      try {
        this.setGlobalLoading(true)
        const result = await this.$apollo.query({
          query: invitationExternalLawyerForTokenQuery,
          variables,
        })
        const { isUserKnown, expiredAt, status } =
          result.data.invitationExternalLawyerForToken
        if (status === 'CANCELED') {
          this.$notification.error({
            messageKey: 'loginPage.invitationError.lawyer.canceled',
          })
        } else if (this.isTokenExpired(expiredAt) || status !== 'PENDING') {
          this.$notification.error({
            messageKey: 'loginPage.invitationError.lawyer.expired',
          })
        } else if (isUserKnown) {
          const { hasValidToken } = this.$store.getters
          if (hasValidToken) {
            // user is already logged in, update & redirect to selfservice and display notification
            this.$router.push(this.localePath('LawyerPortal'))
          } else {
            // user exists but is currently not logged in, prompt for login
            this.$notification.info({ messageKey: 'invitation.promptForLogin' })
          }
        } else {
          // Display registration form, unknown person
          // We have to use route name instead of path to support route params
          this.$store.$router.push({
            name: `LawyerPortal-Registration___${this.$i18n.locale}`,
            query: {
              invitationToken: this.lawyerInvitationToken,
            },
          })
        }
      } catch (err) {
        const sanitizedErrors = this.handleApolloError(err)

        if (sanitizedErrors.graphQLError.message === '404 Not Found') {
          this.$notification.error({ messageKey: 'invitation.invalidToken' })
        } else {
          console.error(err)
        }
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async checkRemoteLoginToken() {
      if (this.remoteLoginToken) {
        await this.remoteLogin()
      }
    },
    async login() {
      try {
        const validationResult = await this.$validator.validateAll()
        if (validationResult) {
          this.setGlobalLoading(true)
          const variables = {
            username: this.form.username,
            password: this.form.password,
          }
          await this.$store.dispatch(LOGIN, {
            variables,
            apollo: this.$apollo.provider.defaultClient,
          })
          // check again invitation token when logged in
          if (this.invitationToken) {
            this.checkInvitationToken()
            return
          }

          // check if we should redirect after login
          const { redirect } = this.$route.query
          if (redirect) {
            this.$router.push({ path: redirect })
            return
          }

          this.handleLoginRedirect()
        }
      } catch (err) {
        this.handleLoginError(err)
      } finally {
        this.setGlobalLoading(false)
      }
    },
    async remoteLogin() {
      this.setGlobalLoading(true)
      try {
        await this.$store.dispatch(LOGIN, {
          token: this.remoteLoginToken,
          apollo: this.$apollo.provider.defaultClient,
        })

        this.handleLoginRedirect()
      } catch (err) {
        this.handleLoginError(err)
      } finally {
        this.setGlobalLoading(false)
      }
    },
    handleLoginRedirect() {
      const { portals } = this.$store.state.authentication

      if (portals.length === 1) {
        // redirect to lawyer or partner or self service portal based on the user role
        if (this.$store.getters.isExternalLawyer) {
          this.$router.push({ path: this.localePath('LawyerPortal') })
        } else if (this.$store.getters.isBroker) {
          this.$router.push({ path: this.localePath('PartnerPortal') })
        } else {
          this.$router.push({ path: this.localePath('SelfService') })
        }
      } else {
        this.$router.push({ path: this.localePath('SelectPortal') })
      }
    },
    handleLoginError(err) {
      const sanitizedErrors = this.handleApolloError(err)

      if (sanitizedErrors.networkError.code === 10) {
        // conflict solver
        const conflictId = sanitizedErrors.networkError.conflict_id
        this.solveConflicts(conflictId)
      } else if (
        sanitizedErrors.graphQLError.code === 1 ||
        sanitizedErrors.networkError.code === 1
      ) {
        this.$notification.error({ messageKey: 'groupeMutuel.roleError' })
      } else if (
        sanitizedErrors.networkError.message === 'Authentication error'
      ) {
        this.$notification.error({ messageKey: 'loginPage.error' })
      }
    },
    async solveConflicts(conflictId) {
      try {
        const accounts = await this.$store.dispatch(GET_CONFLICT_DETAILS, {
          variables: {
            conflictId,
          },
          apollo: this.$apollo.provider.defaultClient,
        })
        if (accounts.length === 1) {
          this.$router.push({
            name: `ConflictSolver-SingleAccount___${this.$i18n.locale}`,
            params: { conflictId, accounts },
          })
        }
        if (accounts.length > 1) {
          this.$router.push({
            name: `ConflictSolver-MultiAccount___${this.$i18n.locale}`,
            params: { conflictId, accounts },
          })
        }
      } catch (error) {
        // eslint-disable-next-line no-shadow
        const sanitizedErrors = this.handleApolloError(error)

        if (sanitizedErrors.networkError.code === 11) {
          // conflict unsolvable
          this.$router.push({
            path: this.localePath('ConflictSolver-Unsolvable'),
          })
        } else if (sanitizedErrors.networkError.code === 12) {
          // conflict solved
          console.error('CONFLICT WAS SOLVED')
          this.$notification.error({ messageKey: 'loginPage.error' })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';

.pw-reset,
.newRegistrationHeadline {
  align-self: flex-start;
  color: $white-clr;
  font-size: rem(16);
}

.newRegistrationHeadline {
  margin-top: rem(64);
  @include media-breakpoint-up(lg) {
    font-size: rem(16);
  }
}

.registrationButtons {
  display: flex;
  margin-top: rem(16);
  flex-wrap: wrap;

  > div {
    margin-right: rem(14);
    margin-bottom: rem(14);

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 460px) {
      &:nth-of-type(even) {
        margin-right: 0;
      }
    }
  }
}
</style>
