<template>
  <div>
    <!-- READ ONLY -->
    <template v-if="!editable">
      <div class="row">
        <div class="col-12 col-md-10 mb-2 row">
          <p class="col-12 col-md-8 mb-0 mb-md-auto">
            {{ $t('offer.basic.insureDate') }}
          </p>
          <p class="col-12 col-md-4 font-weight-bold">
            {{ formData.startOfInsurance }}
          </p>
        </div>
        <div class="col-12 col-md-10 mb-2 row">
          <p class="col-12 col-md-8 mb-0 mb-md-auto">
            {{ $t('offer.basic.chooseBranch') }}
          </p>
          <p class="col-12 col-md-4 font-weight-bold">
            {{ translatedBranch }}
          </p>
        </div>
        <div class="col-12 col-md-10 mb-2 row">
          <p class="col-12 col-md-8 mb-0 mb-md-auto">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.basic.secondaryEmployment'
                  : 'offer.basic.secondaryEmployment',
              )
            }}
          </p>
          <p class="col-12 col-md-4 font-weight-bold">
            {{ formData.secondaryEmployment ? $t('yes') : $t('no') }}
          </p>
        </div>
        <div class="col-12 col-md-10 mb-2 row">
          <p class="col-12 col-md-8 mb-0 mb-md-auto">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.basic.estimatedAnnualPayroll'
                  : 'offer.basic.estimatedAnnualPayroll',
              )
            }}
          </p>
          <p class="col-12 col-md-4 font-weight-bold">
            {{ formData.estimatedAnnualPayroll + ' CHF' }}
          </p>
        </div>
        <div class="col-12 col-md-10 row">
          <p class="col-12 col-md-8 mb-0 mb-md-auto">
            {{
              $t(
                isBrokerOffer
                  ? 'brokerOffer.basic.estimatedAnnualSales'
                  : 'offer.basic.estimatedAnnualSales',
              )
            }}
          </p>
          <p class="col-12 col-md-4 font-weight-bold">
            {{ formData.estimatedAnnualSales + ' CHF' }}
          </p>
        </div>
      </div>
    </template>

    <!-- EDIT MODE -->
    <form v-else @change="change">
      <!-- ONE -->
      <div class="row mt-5">
        <div class="col-12 col-md-5">
          <dx-input
            v-model="formData.startOfInsurance"
            v-validate.disable="{
              required: true,
              atMostOneYearInFuture: true,
              atMostOneWeekInPast: isBrokerOffer,
            }"
            :label="$t('offer.basic.insureDate')"
            :error="errors.first('startOfInsurance')"
            :only-in-future="!isBrokerOffer"
            is-date-input
            type="date"
            data-vv-name="startOfInsurance"
            :min-date="isBrokerOffer ? new Date().fp_incr(-7) : null"
            :default-date="isBrokerOffer ? new Date().fp_incr(1) : null"
            @blur="change"
          />
        </div>
      </div>

      <!-- TWO -->
      <div class="row mt-5">
        <div class="col-12">
          {{ $t('offer.basic.chooseBranch') }}
        </div>
        <div class="col-12">
          <dx-select
            v-model="formData.branchOfIndustry"
            v-validate.disable="'required'"
            :error="errors.first('branchOfIndustry')"
            :options="getBranchOptions()"
            data-vv-name="branchOfIndustry"
          />
        </div>
      </div>

      <!-- THREE -->
      <div class="row mt-5">
        <div class="col-12">
          {{
            $t(
              isBrokerOffer
                ? 'brokerOffer.basic.secondaryEmployment'
                : 'offer.basic.secondaryEmployment',
            )
          }}
        </div>
        <div class="col-12">
          <dx-radio-list
            v-model="formData.secondaryEmployment"
            v-validate.disable="'required'"
            :options="secondaryEmploymentOptions"
            :error="errors.first('secondaryEmployment')"
            name="secondaryEmployment"
            data-vv-name="secondaryEmployment"
          />
        </div>
      </div>

      <!-- FOUR -->
      <div class="row mt-2">
        <div class="col-12">
          <dx-textarea
            v-if="formData.secondaryEmployment"
            v-model="formData.secondaryEmploymentDescription"
            v-validate.disable="{
              required: formData.secondaryEmployment,
            }"
            :error="errors.first('secondaryEmploymentDescription')"
            :label="$t('offer.basic.secondaryEmploymentDescription')"
            class="mt-2"
            data-vv-name="secondaryEmploymentDescription"
          />
        </div>
      </div>

      <!-- FIVE -->
      <div class="row mt-5">
        <div class="col-12">
          <dx-input
            v-model="formData.estimatedAnnualPayroll"
            v-validate.disable="'required|numeric'"
            :label="
              $t(
                isBrokerOffer
                  ? 'brokerOffer.basic.estimatedAnnualPayroll'
                  : 'offer.basic.estimatedAnnualPayroll',
              )
            "
            :error="errors.first('estimatedAnnualPayroll')"
            placeholder="CHF"
            data-vv-name="estimatedAnnualPayroll"
            @input="change"
          />
        </div>
      </div>

      <!-- SIX -->
      <div class="row mt-5">
        <div class="col-12">
          <dx-input
            v-model="formData.estimatedAnnualSales"
            v-validate.disable="'required|numeric'"
            :label="
              $t(
                isBrokerOffer
                  ? 'brokerOffer.basic.estimatedAnnualSales'
                  : 'offer.basic.estimatedAnnualSales',
              )
            "
            :error="errors.first('estimatedAnnualSales')"
            placeholder="CHF"
            data-vv-name="estimatedAnnualSales"
            @input="change"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
  DxSelect,
  DxTextarea,
} from '@sumcumo/dextra-frontend-component-lib-old'
import validBranchOptions from '../branchOptions'

export default {
  name: 'BusinessBasicQuestions',
  components: {
    DxInput,
    DxRadioList,
    DxSelect,
    DxTextarea,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    isBrokerOffer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formData: {
        ...JSON.parse(
          JSON.stringify(
            this.$store.state[this.isBrokerOffer ? 'brokerOffer' : 'offer']
              .businessBasicQuestions,
          ),
        ),
      },
      secondaryEmploymentOptions: [
        { text: this.$t('yes'), id: true },
        { text: this.$t('no'), id: false },
      ],
    }
  },
  computed: {
    storedOffer() {
      return this.isBrokerOffer
        ? this.$store.state.brokerOffer
        : this.$store.state.offer
    },
    activeTariff() {
      return this.storedOffer.activeTariff
    },
    translatedBranch() {
      return this.$t(
        `offer.basic.branchesOfIndustry.branch_${
          validBranchOptions.indexOf(this.formData.branchOfIndustry) + 1
        }`,
      )
    },
  },
  watch: {
    'formData.secondaryEmployment': function (value) {
      if (!value) {
        this.formData.secondaryEmploymentDescription = ''
      }
    },
    'formData.estimatedAnnualSales': function (value) {
      if (value) {
        this.formData.estimatedAnnualSales = value.replace(' ', '')
      }
    },
    'formData.estimatedAnnualPayroll': function (value) {
      if (value) {
        this.formData.estimatedAnnualPayroll = value.replace(' ', '')
      }
    },
  },
  methods: {
    getBranchOptions() {
      const branchOptions = []
      const optionKeys = validBranchOptions
      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
      }

      for (let i = 0; i < optionKeys.length; i += 1) {
        const optionName = this.$t(
          `offer.basic.branchesOfIndustry.branch_${i + 1}`,
        )
        const option = {
          value: optionKeys[i],
          label: optionName,
        }
        branchOptions.push(option)
      }
      return [defaultOption, ...branchOptions]
    },
    async validateForm() {
      let result = await this.$validator.validateAll()
      if (this.activeTariff === 'small-business') {
        if (this.formData.estimatedAnnualPayroll > 200000) {
          this.$validator.errors.add({
            field: 'estimatedAnnualPayroll',
            msg: this.$t('offer.basic.estimatedAnnualPayrollLimit'),
            rule: 'limits',
          })
          result = false
        }
        if (this.formData.estimatedAnnualSales > 400000) {
          this.$validator.errors.add({
            field: 'estimatedAnnualSales',
            msg: this.$t('offer.basic.estimatedAnnualSalesLimit'),
            rule: 'limits',
          })
          result = false
        }
      }
      return result
    },
    async saveForm() {
      this.$store.commit(
        this.isBrokerOffer
          ? 'SET_BROKER_BUSINESS_BASIC_QUESTIONS'
          : 'SET_BUSINESS_BASIC_QUESTIONS',
        this.formData,
      )
      return true
    },
    resetForm() {
      this.formData = {
        ...JSON.parse(JSON.stringify(this.storedOffer.businessBasicQuestions)),
      }
    },
    change() {
      this.$emit('change')
    },
  },
}
</script>
