<template>
  <div class="d-flex mb-4">
    <component
      :is="inputComponent"
      v-model="zip"
      v-validate.disable="validation"
      :has-icon="icon"
      :label="$t('plz')"
      :error="errorsForField('zip')"
      :disabled="disabled"
      :maxlength="maxLength"
      inverted
      class="mr-3 w-40"
      data-vv-name="zip"
      autocomplete="postal-code"
      :data-vv-scope="dataVvScope"
      @input="updateCities"
    />
    <component
      :is="inputComponent"
      v-if="citiesForZipCode.length === 1"
      v-model="city"
      v-validate.disable="'required|min:1'"
      :label="$t('place')"
      :error="errorsForField('city')"
      inverted
      class="w-60"
      data-vv-name="city"
      :data-vv-scope="dataVvScope"
      plain-text
      autocomplete="off"
    />
    <component
      :is="selectComponent"
      v-else-if="citiesForZipCode.length > 1"
      v-validate.disable="'required|min:1'"
      :value="city"
      :options="cityOptions"
      :label="$t('place')"
      :error="errorsForField('city')"
      inverted
      class="w-60"
      data-vv-name="city"
      :data-vv-scope="dataVvScope"
      @input="(value) => (city = value)"
    />
    <component
      :is="inputComponent"
      v-else
      v-model="city"
      v-validate.disable="'required|min:1'"
      :label="$t('place')"
      :error="errorsForField('city')"
      inverted
      class="w-60"
      data-vv-name="city"
      :data-vv-scope="dataVvScope"
      autocomplete="off"
    />
  </div>
</template>

<script>
import {
  DxInput as DxInputOld,
  DxSelect as DxSelectOld,
} from '@sumcumo/dextra-frontend-component-lib-old'
import { DxInput, DxSelect } from '@sumcumo/dextra-frontend-component-lib'
import getCitiesByZipQuery from './__gql__/queries/citiesByZip.gql'

export default {
  name: 'DxZipToCityInput',
  components: {
    DxInput,
    DxSelect,
    DxInputOld,
    DxSelectOld,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    zipInput: {
      type: String,
      default: null,
    },
    cityInput: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataVvScope: {
      type: String,
      default: null,
    },
    additionalDataVvScopes: {
      type: Array,
      default() {
        return []
      },
    },
    maxLength: {
      type: Number,
      default: 4,
    },
    validation: {
      type: String,
      default: 'required|digits:4|numeric|validate_ZIP',
    },
    withModulaStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      citiesForZipCode: [],
      zip: null,
      city: null,
    }
  },
  computed: {
    inputComponent() {
      return this.withModulaStyle ? 'DxInput' : 'DxInputOld'
    },
    selectComponent() {
      return this.withModulaStyle ? 'DxSelect' : 'DxSelectOld'
    },
    cityOptions() {
      const defaultOption = {
        value: '',
        label: this.$t('selectOption'),
        caption: true,
      }
      const apiCityOptions = this.citiesForZipCode.map((city) => ({
        value: city.name,
        label: city.name,
      }))
      return [defaultOption, ...apiCityOptions]
    },
  },
  watch: {
    zip: function emitZip(val) {
      this.$emit('update:zip', val)
    },
    city: function emitCity(val) {
      this.$emit('update:city', val)
    },
  },
  mounted() {
    this.zip = this.zipInput
    this.city = this.cityInput
    this.updateCities()
  },
  methods: {
    async updateCities() {
      this.$validator.errors.remove('zip')
      this.$validator.errors.remove('city')
      if (this.zip && this.zip.length >= 4 && !Number.isNaN(this.zip)) {
        try {
          const result = await this.$apollo.query({
            query: getCitiesByZipQuery,
            variables: {
              zips: [this.zip],
              locale: this.$i18n.locale.toUpperCase(),
            },
          })
          const { cities } = result.data
          if (cities.length === 1) {
            this.city = cities[0].name
          }
          this.citiesForZipCode = [...cities]
        } catch (e) {
          console.error(e)
        }
      } else {
        this.citiesForZipCode = []
        this.city = ''
      }
    },
    errorsForField(field) {
      const mainError = this.$validator.errors.first(field, this.dataVvScope)
      if (!mainError && this.additionalDataVvScopes.length) {
        return this.additionalDataVvScopes
          .map((scope) => this.$validator.errors.first(field, scope))
          .reduce(
            (previousResult, result) => previousResult || result,
            mainError,
          )
      }
      return mainError
    },
  },
}
</script>
