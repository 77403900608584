<template>
  <div class="container-fluid">
    <div class="content-wrap-small content-pad">
      <div class="d-flex justify-content-center">
        <dx-progressbar
          :steps="5"
          :active-step="5"
          class="mb-5 w-100 dx-w-md-75"
        />
      </div>

      <h3 class="mb-4">
        {{ $t('counterpartyHead') }}
      </h3>
      <dx-radio-list
        v-model="counterPartyInformation"
        :options="counterpartyRadioData"
        :label="$t('counterpartyRadios')"
        name="counterparty"
        class="mb-4"
      />

      <div v-if="counterPartyInformation">
        <!-- TODO: make salutation radio button here to fulfill createLegalClaim -->
        <dx-input
          v-model="report.counterparty.firstName"
          :label="$t('firstName')"
          class="mb-4"
        />
        <dx-input
          v-model="report.counterparty.lastName"
          :label="$t('lastName')"
          class="mb-4"
        />
        <dx-input
          v-model="report.counterparty.company"
          :label="$t('company')"
          class="mb-4"
        />
        <dx-input
          v-model="report.counterparty.street"
          :label="$t('street')"
          class="mb-4"
        />
        <dx-input
          v-model="report.counterparty.number"
          :label="$t('houseNumber')"
          class="mb-4"
        />
        <dx-input
          v-model="report.counterparty.zip"
          :label="$t('plz')"
          class="mb-4"
        />
        <dx-input
          v-model="report.counterparty.city"
          :label="$t('place')"
          class="mb-4"
        />
      </div>

      <p v-html="counterpartyClaim" />

      <dx-bottom-bar
        :previous="localePath(previousStepPath)"
        :show-previous-button="true"
        class="mt-5"
        @forward="sendStoreToServerAndRedirect"
      />
    </div>
    <dx-loading-overlay :global-loader="true" :disabled="!loading" />
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxProgressbar from '~/components/DX-Progressbar'
import DxBottomBar from '~/components/DX-BottomBar'
import {
  SET_REPORT,
  RESET_STORE_DAMAGEREPORT,
} from '~/domains/contract/claim/__store__/mutations'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'

export default {
  name: 'DxCounterpartyForm',
  components: {
    DxRadioList,
    DxInput,
    DxBottomBar,
    DxProgressbar,
    DxLoadingOverlay,
  },
  mixins: [loadingMixin],
  props: {
    isBroker: {
      type: Boolean,
      default: false,
    },
    nextStepPath: {
      type: String,
      default: '',
    },
    previousStepPath: {
      type: String,
      default: '',
    },
    createClaimAction: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      report: { ...this.$store.state.claim.report },

      counterPartyInformation: false,
      counterpartyRadioData: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
    }
  },
  computed: {
    counterpartyClaim() {
      return `${this.$t('counterpartyClaim.both')} ${this.$t(
        this.isBroker
          ? 'counterpartyClaim.broker'
          : 'counterpartyClaim.customer',
      )}`
    },
    loading() {
      return this.$store.state.application.loading
    },
  },
  methods: {
    async sendStoreToServerAndRedirect() {
      const emptyCounterparty = {
        salutation: 'MALE',
        firstName: '',
        lastName: '',
        company: '',
        street: '',
        number: '',
        zip: '',
        city: '',
      }
      const report = {
        ...this.report,
        // map attachments to backend compatible structure (map {id, name} of each upload to uploadIds-Array)
        attachments: this.report.attachments.map((a) => ({
          kind: a.kind,
          uploadIds: (a.upload || []).map((u) => u.id),
        })),
        counterparty: this.counterPartyInformation
          ? this.report.counterparty
          : emptyCounterparty,
        language: this.$i18n.locale.toUpperCase(),
      }
      this.$store.commit({
        type: SET_REPORT,
        report,
      })
      this.setGlobalLoading(true)
      try {
        const createLegalClaim = await this.$store.dispatch(
          this.createClaimAction,
        )
        if (createLegalClaim) {
          this.$store.commit({
            type: RESET_STORE_DAMAGEREPORT,
          })
          this.$router.push(this.localePath(this.nextStepPath))
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
      } catch (e) {
        this.$notification.error({ messageKey: 'notifications.error.message' })
        console.error(e)
      } finally {
        this.setGlobalLoading(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
