<template>
  <div class="wrapper">
    <div v-if="error" class="error-container">
      <alert-circle-icon class="spacing" />
      <span>{{ translatedError }}</span>
    </div>

    <template v-for="module in item.modules">
      <div :key="`${module.key}-selection`" class="moduleSelection">
        <dx-radio-check
          :id="module.key"
          :name="module.key"
          :value="!formContext.selectedModules.includes(module.key)"
          :checked="formContext.selectedModules.includes(module.key)"
          :label="translatedModuleLabel(module)"
          :disabled="module.readonly"
          type="checkbox"
          @input="update(module, $event)"
        />
        <button
          v-if="hasInfoText(module)"
          class="infoPopupButton"
          type="button"
          @click="openInfoTextModal(module)"
        >
          <info-icon :height="22" :width="22" />
        </button>

        <p
          v-if="translatedModuleRichText(module)"
          class="description"
          v-html="translatedModuleRichText(module)"
        ></p>
      </div>

      <div :key="`${module.key}-items`" class="moduleItems">
        <slot
          name="items"
          :item-ids="module.itemIds"
          :module-key="module.key"
        />
      </div>
    </template>

    <dx-popup-modal ref="popupModal" class="modal--info-popup">
      <dx-content-block-renderer :content-blocks="selectedContentBlocks" />
    </dx-popup-modal>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import {
  DxRadioCheck,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib'

import DxContentBlockRenderer from '@/components/DX-ContentBlockRenderer'
import AlertCircleIcon from '@/components/icons/AlertCircleIcon'
import InfoIcon from '@/components/icons/InfoIcon'

import infoModalModuleKeyQuery from '@/domains/application/__gql__/queries/infoModalModuleKey.gql'

import {
  useScipTranslationWithFallback,
  escapeHtml,
  richTextToContentBlocks,
} from '../../utils'

export default {
  name: 'DXModuleSelectionAdapter',
  components: {
    DxContentBlockRenderer,
    DxRadioCheck,
    DxPopupModal,
    AlertCircleIcon,
    InfoIcon,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    error: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  data() {
    return {
      infoTexts: Object.fromEntries(
        this.item.modules.map((module) => [module.key, null]),
      ),
      selectedContentBlocks: undefined,
    }
  },

  computed: {
    translatedError() {
      if (!this.error) {
        return null
      }

      let count
      if (this.error.params?.min) {
        count = this.error.params.min
      } else if (this.error.params?.max) {
        count = this.error.params.max
      }

      const key = this.te(`errors.module.${this.error.type}`)
        ? `errors.module.${this.error.type}`
        : `errors.${this.error.type}`

      return count !== undefined
        ? this.tc(key, count, this.error.params)
        : this.t(key, this.error.params)
    },
  },
  mounted() {
    this.item.modules.forEach((module) => this.fetchModalContent(module))
  },

  methods: {
    async fetchModalContent(module) {
      const { data } = await this.$apolloProvider.clients.cms.query({
        query: infoModalModuleKeyQuery,
        variables: {
          where: {
            moduleKey: module.key,
            productKey: this.formContext.schema.product.productKey,
            productVariantKey: this.formContext.schema.product.variantKey,
            productVersionNumbers_contains_some: [
              Number(this.formContext.schema.product.versionNumber) || 1,
            ],
          },
          locales: [this.$i18n.locale],
        },
      })

      if (data?.infoModalsConnection?.edges[0]?.node?.modalContent) {
        this.infoTexts[module.key] =
          data.infoModalsConnection.edges[0].node.modalContent
      }
    },

    translationKeyPrefixForModule(module) {
      return `modules.${module.key}`
    },

    translatedModuleLabel(module) {
      return this.te(`${this.translationKeyPrefixForModule(module)}.label`)
        ? this.t(`${this.translationKeyPrefixForModule(module)}.label`)
        : module.label ?? ''
    },

    translatedModuleRichText(module) {
      return this.te(`${this.translationKeyPrefixForModule(module)}.infoText`)
        ? escapeHtml(
            this.t(`${this.translationKeyPrefixForModule(module)}.infoText`),
          )
        : module.richText ?? ''
    },

    hasInfoText(module) {
      return Boolean(this.infoTexts[module.key]) || module.infoText
    },

    openInfoTextModal(module) {
      this.selectedContentBlocks = this.infoTexts[module.key]
      if (!this.selectedContentBlocks && module.infoText) {
        this.selectedContentBlocks = richTextToContentBlocks(
          module.infoText,
          this.item.id,
        )
      }
      this.$refs.popupModal.open()
    },

    update(module, selected) {
      this.$emit('update-module', module.key, selected)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/functions/px-to-rem';

.error-container {
  display: flex;
  margin-top: rem(8);
  align-items: center;
  color: $functional-clr-error-dk;
  font-family: $font-family-heavy;
  font-size: rem($font-size-small);
  line-height: rem($font-line-height-xtra-small);

  @include media-breakpoint-up(lg) {
    font-size: rem($font-size);
    line-height: rem($font-line-height-small);
  }
}

.spacing {
  margin-right: rem(8);
}

.wrapper {
  display: contents;

  .moduleSelection:not(:first-of-type) {
    border-top: 2px solid #b3b5bd;
    padding-top: rem(20);
  }
}

.moduleSelection {
  display: grid;
  margin-top: rem(8);
  grid-column-gap: rem(4);
  grid-template-columns: 1fr rem(32);

  ::v-deep .cmp-checkbox::after {
    border-color: $color-turquoise-surf !important;
  }

  ::v-deep .disabled .input:checked + .label {
    color: $neutral-clr-600 !important;
  }
}

.modulesHeadlineWrap {
  margin-top: 1rem;
  padding: 0;
  grid-column: 1 / -1;
  background: white;

  h4 {
    margin-bottom: 0;
    font-weight: 700;
  }
}

.moduleItems {
  display: contents;
  outline: 1px solid red;
  padding: 0 1rem 0 !important;
}

.moduleItems > * {
  margin: -1rem 4rem 0;

  ::v-deep label {
    margin-bottom: rem(0);
  }

  &:last-child {
    padding-bottom: rem(16);
  }
}

.moduleItems > *:first-child {
  margin-top: 0;
}

.infoPopupButton {
  margin-bottom: rem(7);
  border: none;
  padding: 0 rem(4);
  background: transparent;
  color: $functional-clr-info-dk;
}

.description {
  margin: 0;
  grid-column: 1 / -1;
}
</style>
