<template>
  <div :class="['portal-switch-wrap', { 'left-aligned': left }]">
    <span class="switch-btn">
      {{ $t('switchPortal') }}
    </span>
    <div class="d-flex text-center open">
      <div v-for="portal in availablePortals" :key="portal.name" class="mb-3">
        <span
          :class="[
            'portal-item',
            { active: currentPortal === portal.id },
            `portal-item--${portal.className}`,
          ]"
          @click="enterPortal(portal.id)"
        >
          {{ $t(portal.name) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import changePortalMixin from '~/javascripts/mixins/changePortalMixin'

export default {
  name: 'DxPortalSwitch',
  mixins: [loadingMixin, changePortalMixin],
  props: {
    left: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
