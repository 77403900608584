<template>
  <div class="container-fluid">
    <div class="content-wrap-small content-pad">
      <div class="d-flex justify-content-center">
        <dx-progressbar
          :steps="5"
          :active-step="4"
          class="mb-5 w-100 dx-w-md-75"
        />
      </div>

      <h3 class="mb-4">
        {{ $t('deadline') }}
      </h3>
      <dx-radio-list
        v-model="report.hasDeadline"
        :options="deadlineRadioData"
        :label="
          $t(isBroker ? 'deadlineLabel.broker' : 'deadlineLabel.customer')
        "
        name="deadline"
        class="mb-4"
      />
      <!-- TODO: research styling on datepicker click -->
      <dx-input
        v-if="report.hasDeadline"
        key="deadline-input"
        v-model="report.deadline"
        v-validate="'required|min:2'"
        :label="$t(isBroker ? 'deadlineDate.broker' : 'deadlineDate.customer')"
        :error="errors.first('date')"
        type="date"
        is-date-input
        class="mb-4"
        data-vv-name="date"
      />

      <dx-bottom-bar
        :previous="localePath(previousStepPath)"
        :show-previous-button="true"
        :is-last="true"
        class="mt-5"
        @forward="gotoNextStep"
      />
    </div>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxProgressbar from '~/components/DX-Progressbar'
import DxBottomBar from '~/components/DX-BottomBar'
import { SET_REPORT } from '~/domains/contract/claim/__store__/mutations'

export default {
  name: 'DXDeadlineForm',
  components: {
    DxInput,
    DxRadioList,
    DxProgressbar,
    DxBottomBar,
  },
  props: {
    isBroker: {
      type: Boolean,
      default: false,
    },
    nextStepPath: {
      type: String,
      default: '',
    },
    previousStepPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      report: { ...this.$store.state.claim.report },
      deadlineRadioData: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
    }
  },
  methods: {
    resetDeadline() {
      this.report.deadline = ''
    },
    async gotoNextStep() {
      const validated = await this.$validator.validateAll()
      if (validated) {
        const report = {
          ...this.report,
          deadline: this.report.hasDeadline ? this.report.deadline : '',
        }
        this.$store.commit({
          type: SET_REPORT,
          report,
        })
        this.$router.push({
          path: this.localePath(this.nextStepPath),
        })
      }
    },
  },
}
</script>
