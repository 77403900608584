<template>
  <div>
    <dx-description-label v-if="translatedDescription">
      {{ translatedDescription }}
    </dx-description-label>
    <div
      v-for="(location, i) in value"
      :key="`${location}_${i}`"
      :disabled="item.readonly"
      class="locationForm"
    >
      <div class="locationFormHeader">
        <dx-description-label>Standort {{ i + 1 }}</dx-description-label>
        <button
          type="button"
          class="deleteButton"
          :title="t('remove')"
          @click="removeLocation(i)"
        >
          <trash-icon :width="22" :height="22" />
        </button>
      </div>

      <dx-description-label v-if="item.infoText">
        {{ item.infoText }}
      </dx-description-label>

      <dx-input
        :id="`${item.id}-${i}-street`"
        v-model="location.street"
        :disabled="item.readonly"
        :label="getLocationLabel('street')"
        :error="getErrorMessage(error, 'street', i)"
        @blur="onBlur(i, 'street')"
      />

      <dx-input
        :id="`${item.id}-${i}-houseNumber`"
        v-model="location.houseNumber"
        :disabled="item.readonly"
        :label="getLocationLabel('houseNumber')"
        :error="getErrorMessage(error, 'houseNumber', i)"
        @blur="onBlur(i, 'houseNumber')"
      />

      <dx-input
        :id="`${item.id}-${i}-zip`"
        v-model="location.zip"
        :disabled="item.readonly"
        :label="getLocationLabel('zip')"
        :error="getErrorMessage(error, 'zip', i)"
        @blur="onBlur(i, 'zip')"
      />

      <dx-input
        :id="`${item.id}-${i}-city`"
        v-model="location.city"
        :disabled="item.readonly"
        :label="getLocationLabel('city')"
        :error="getErrorMessage(error, 'city', i)"
        @blur="onBlur(i, 'city')"
      />

      <dx-select
        :id="`${item.id}-${i}-country`"
        v-model="location.country"
        :label="getLocationLabel('country')"
        :options="countries"
        :error="getErrorMessage(error, 'country', i)"
        class="selection"
        @blur="onBlur(i, 'country')"
      />
    </div>

    <button type="button" class="addLocation" @click="addLocation">
      <map-pin-icon />
      <span>{{ getLocationLabel('addLocation') }}</span>
    </button>
  </div>
</template>

<script>
import { toRefs, watchEffect } from '@vue/composition-api'

import { DxInput, DxSelect } from '@sumcumo/dextra-frontend-component-lib'

import DxDescriptionLabel from '@/components/DX-DescriptionLabel/DxDescriptionLabel'
import TrashIcon from '@/components/icons/TrashIcon'
import MapPinIcon from '@/components/icons/MapPinIcon'
import { countryOptions } from '@/javascripts/utils'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DxLocationsFieldAdapter',
  components: {
    DxDescriptionLabel,
    DxInput,
    DxSelect,
    MapPinIcon,
    TrashIcon,
  },

  props: {
    formContext: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    error: {
      type: Array,
      default: null,
    },
  },

  setup(props, { emit }) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    const { value } = toRefs(props)

    watchEffect(() => {
      emit('input', value.value)
    })

    const getErrorMessage = (errors, propKey, index) => {
      const error = errors?.[index]?.[propKey]
      if (!error) {
        return null
      }

      const key = `errors.locations.${propKey}.${error.type}`
      return this.t(key, this.error.params)
    }

    const getLocationLabel = (prop) => {
      const legacyKey1 = `${translation.translationKeyPrefix}.${prop}.label`
      if (translation.te(legacyKey1)) {
        return translation.t(legacyKey1)
      }
      const legacyKey2 = `${translation.translationKeyPrefix}.${prop}`
      if (translation.te(legacyKey2)) {
        return translation.t(legacyKey2)
      }
      return translation.t(`locations.${prop}`)
    }

    const addLocation = () => {
      value.value.push({
        zip: '',
        city: '',
        country: '',
        houseNumber: '',
        street: '',
      })
    }

    const removeLocation = (index) => {
      value.value.splice(index, 1)
    }

    const onBlur = (index, propKey) => {
      emit('validate', { dataKey: props.item.dataKey, index, propKey })
    }

    return {
      ...translation,
      getLocationLabel,
      addLocation,
      removeLocation,
      getErrorMessage,
      onBlur,
    }
  },

  computed: {
    countries() {
      return countryOptions(this.t.bind(this))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';

.locationForm {
  display: grid;
  margin-bottom: rem(32);
  grid-row-gap: rem(16);
  grid-template-columns: 1fr;
}

.locationFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteButton,
.addLocation {
  border: none;
  background: transparent;
  color: $black-clr;
}

.addLocation {
  display: flex;
  align-items: center;

  svg {
    margin-right: rem(8);
    color: $functional-clr-info;
  }
  span {
    position: relative;
    font-family: $font-family-heavy;
    font-size: rem($font-size-small);
    line-height: rem($font-line-height-xtra-small);

    @include media-breakpoint-up(lg) {
      font-size: rem($font-size);
      line-height: rem($font-line-height-small);
    }

    &::after {
      position: absolute;
      content: '';
      bottom: rem(-1);
      left: 0;
      width: 100%;
      height: rem(1);
      background-color: $black-clr;
    }
  }
}
</style>
