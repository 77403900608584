<template>
  <div class="content-bg-color">
    <div class="container-fluid">
      <div class="mt-3 my-md-4">
        <dx-back-text-button
          :text="$t('damageBacklog.overview')"
          :route="localePath('LawyerPortal')"
        />
      </div>
      <dx-loading-overlay v-if="loading" />
      <div v-if="items.length > 0" class="content-wrap-medium content-pad">
        <h4>
          {{ $t('damageBacklog.casesClosed') }}
        </h4>
        <div class="search">
          <dx-input
            :placeholder="$t('damageBacklog.search')"
            has-icon="search"
            type="search"
            @input="searchItems"
          />
        </div>
        <dx-closed-damages :items="filteredItems" />
      </div>
      <div v-if="items.length === 0 && !loading" class="text-md-center mt-5">
        <h4>{{ $t('lawyerPortal.noClosedClaims') }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import {
  DxInput,
  DxBackTextButton,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxClosedDamages from '~/domains/externalLawyer/components/DX-ClosedDamages'

import closedLawyerClaimsQuery from '~/domains/externalLawyer/__gql__/queries/closedClaims.gql'

export default {
  name: 'LawyerPortalClosedDamageCases',
  components: {
    DxLoadingOverlay,
    DxClosedDamages,
    DxInput,
    DxBackTextButton,
  },
  data() {
    return {
      items: [],
      filteredItems: [],
      loading: true,
    }
  },
  methods: {
    searchItems(searchTerm) {
      if (searchTerm) {
        const options = {
          keys: ['number', 'insuredPerson.firstName', 'insuredPerson.lastName'],
          shouldSort: true,
          threshold: 0.1,
        }
        const fuse = new Fuse(this.items, options)
        this.filteredItems = fuse.search(searchTerm)
      } else {
        this.filteredItems = [...this.items]
      }
    },
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/geschlossene-rechtsfaelle',
      fr: '/portail-avocat/revendications-fermees',
      it: '/portale-procuratori/crediti-chiusi',
    },
  },
  apollo: {
    lawyerClaims: {
      query: closedLawyerClaimsQuery,
      result({ data }) {
        this.items = [...data.lawyerClaims]
        this.filteredItems = [...this.items]
        this.loading = false
      },
      error(error) {
        this.loading = false
        throw error
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
