<template>
  <div class="col cmp-navigation-side">
    <div class="d-flex justify-content-end align-items-end h-100">
      <dx-lang-switcher class="lang-switcher--pos" />
      <div class="d-flex align-items-center text-right mt-3 fixed-height">
        <dx-button
          v-if="reportDamageCustomer"
          :text="$t('reportDamage')"
          icon="lightning"
          theme="wine"
          class="mr-4"
          small
          :route-name="localePath('SelfService-ReportDamage-MyInfo')"
        />

        <dx-button
          v-else-if="reportDamageBroker"
          :text="$t('reportDamage')"
          icon="lightning"
          theme="wine"
          class="mr-4"
          small
          :route-name="localePath('PartnerPortal-ClosedPolicies')"
          @click.native="openDamagePopup()"
        />

        <a
          :href="`${websiteURL}${$t('nav.path1')}`"
          class="cmp-navigation-side-link mr-4 d-inline-block"
        >
          {{ $t('nav.item1') }}
        </a>

        <dx-portal-switch
          v-if="authenticated && allowPortalSwitch"
          class="d-inline-block mr-3"
        />

        <nuxt-link
          v-if="!authenticated"
          :to="localePath('Login')"
          class="text-right cmp-navigation-side-link d-inline-block"
        >
          {{ $t('login') }}
        </nuxt-link>
        <button
          v-if="authenticated && !currentPortal"
          class="cmp-navigation-side-link btn-blank p-0"
          @click="logout"
        >
          {{ $t('logout') }}
        </button>

        <div v-else-if="authenticated && !isGmLimited" class="d-inline-block">
          <ul class="kc-link-nav neutral d-inline-block">
            <li>
              <nuxt-link
                v-if="!$store.getters.isExternalLawyer"
                :to="localePath('SelfService')"
                class="cmp-navigation-side-link"
              >
                {{ accountSubnavData[0].name }}
              </nuxt-link>
              <nuxt-link
                v-else
                :to="localePath('LawyerPortal')"
                class="cmp-navigation-side-link"
              >
                {{ accountSubnavData[0].name }}
              </nuxt-link>
              <div class="kc-link-nav-subnav">
                <ul class="kc-link-nav-subnav-list neutral">
                  <li
                    v-for="item in accountSubnavData[0].subNav"
                    :key="item.name"
                  >
                    <nuxt-link
                      :to="{ path: item.path, hash: item.hash }"
                      class="cmp-navigation-side-link"
                    >
                      {{ item.name }}
                    </nuxt-link>
                  </li>
                  <li class="border pt-3">
                    <button
                      class="cmp-navigation-side-link btn-blank p-0"
                      @click="logout"
                    >
                      {{ $t('logout') }}
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import { mapGetters } from 'vuex'
import DxLangSwitcher from '~/components/DX-LangSwitcher'
import DxPortalSwitch from '~/components/DX-PortalSwitch'

export default {
  name: 'DxNavigation',
  components: {
    DxLangSwitcher,
    DxPortalSwitch,
    DxButton,
  },
  props: {
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  computed: {
    reportDamageBroker() {
      return (
        this.currentPortal === 'BROKER_PORTAL' &&
        this.authenticated &&
        !this.isDashboard &&
        !this.isReportDamage
      )
    },
    reportDamageCustomer() {
      return (
        this.currentPortal === 'CUSTOMER_CENTER' &&
        this.authenticated &&
        this.hasContracts &&
        !this.isDashboard &&
        !this.isReportDamage
      )
    },
    authenticated() {
      return !!this.$store.state.authentication.token.jwt
    },
    hasContracts() {
      return this.$store.state.application.hasContracts
    },
    allowPortalSwitch() {
      return (
        this.currentPortal &&
        this.$store.state.authentication.portals.length > 1
      )
    },
    currentPortal() {
      return this.$store.state.authentication.currentPortal
    },
    isDashboard() {
      const routeMatchesPartnerPortal = /PartnerPortal___[de|fr|it]/
      const routeMatchesSelfService = /SelfService___[de|fr|it]/
      return (
        routeMatchesPartnerPortal.test(this.$route.name) ||
        routeMatchesSelfService.test(this.$route.name)
      )
    },
    isReportDamage() {
      return this.$route.name.includes('ReportDamage')
    },
    ...mapGetters(['isGmLimited']),
  },
  methods: {
    logout() {
      this.$router.push({
        path: this.localePath('Login'),
        query: { logout: true },
      })
    },
    openDamagePopup() {
      this.$store.commit('SET_DAMAGEPOPUP', true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
