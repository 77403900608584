import { ApolloLink } from 'apollo-link'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'

export default ({ store }) => {
  const uri = `${process.env.DEXTRA_API_URL}/api`

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${store.state.authentication.token.jwt}`,
      },
    })
    return forward(operation)
  })

  const uploadLink = createUploadLink({
    uri,
  })

  const link = ApolloLink.from([middlewareLink, uploadLink])

  const cache = new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (
        object.__typename // eslint-disable-line no-undef
      ) {
        case 'Claim':
          return (
            `${store.state.authentication.currentPortal}_${object.id}` || null
          ) // cache only for currentPortal
        default:
          return defaultDataIdFromObject(object) // fall back to default handling
      }
    },
  })

  return {
    link,
    cache,
    defaultHttpLink: false,
  }
}
