import Vue from 'vue'
import * as Sentry from '@sentry/browser'

const install = () => {
  const dsn = process.env.DEXTRA_SENTRY_DSN
  const environment = process.env.DEXTRA_ENV
  const release = process.env.APP_VERSION

  if (dsn) {
    const options = {
      dsn,
      environment,
      release,
      integrations: [new Sentry.Integrations.Vue({ Vue })],
    }
    Sentry.init(options)
    Sentry.configureScope((scope) => {
      scope.setTag('environment', environment)
      scope.setTag('language', 'javascript')
      scope.setTag('application', 'frontend')
      scope.setTag('commit', process.env.COMMIT_HASH)
    })
  }
}

const logError = (error) => {
  const e = new Error(error)
  Sentry.captureException(e)
}

const logMessage = (message) => {
  Sentry.captureMessage(message)
}

export default install
export { logError, logMessage }
