<template>
  <div class="flex-tariffs">
    <dx-tariff
      v-for="item in products[clientType]"
      :key="item.id"
      :tariff-id="item.id"
      :tariff-name="item.name"
      :tariff-shorttext="item.shorttext"
      :tariff-price-from-translation="item.priceFrom"
      :tariff-price-integer="item.priceInteger"
      :tariff-price-decimal="item.priceDecimal"
      :tariff-price-currency-translation="item.currency"
      :tariff-button-text="$t('partnerPortal.tariff.insureNow')"
      :tariff-button-route-name="item.buttonRouteName"
      :tariff-button-route-query="buttonRouteQuery"
    >
      <template slot="content">
        <ul
          v-if="item.id != 'p-combi'"
          class="mb-3 neutral tariff-content bullet-list"
        >
          <li
            v-for="(bullet, index) in item.bulletList"
            :key="index"
            v-html="bullet.text"
          />
        </ul>

        <div v-if="item.id === 'p-combi'">
          <div class="mb-5 tariff-content-wrapper">
            <div class="tariff-content tariff-content--special">
              <p>
                {{ $t('partnerPortal.allServices') }}
              </p>
              <h2>{{ $t('partnerPortal.tariff.p-private.name') }}</h2>
              <p class="mb-0">
                {{ $t('partnerPortal.tariff.p-private.shorttext') }}
              </p>
              <span class="d-block my-3 plus">+</span>
              <h2>{{ $t('partnerPortal.tariff.p-move.name') }}</h2>
              <p>
                {{ $t('partnerPortal.tariff.p-move.shorttext') }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </dx-tariff>
  </div>
</template>

<script>
import { DxTariff } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'DxTariffList',
  components: {
    DxTariff,
  },
  props: {
    clientType: {
      type: String,
      default: 'private',
    },
    resetOffer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      products: {
        private: [
          {
            id: 'p-private',
            name: this.$t('partnerPortal.tariff.p-private.name'),
            shorttext: this.$t('partnerPortal.tariff.p-private.shorttext'),
            priceFrom: this.$t('partnerPortal.tariff.priceFromMonthly'),
            priceInteger: '21',
            priceDecimal: '50',
            currency: this.$t('partnerPortal.tariff.currencyMonthly'),
            buttonRouteName: this.localePath('BrokerOffer-Intro-P-Private'),
            bulletList: [
              { text: this.$t('partnerPortal.tariff.p-private.bulletList.1') },
              { text: this.$t('partnerPortal.tariff.p-private.bulletList.2') },
              { text: this.$t('partnerPortal.tariff.p-private.bulletList.3') },
              { text: this.$t('partnerPortal.tariff.p-private.bulletList.4') },
              { text: this.$t('partnerPortal.tariff.p-private.bulletList.5') },
              { text: this.$t('partnerPortal.tariff.p-private.bulletList.6') },
            ],
          },
          {
            id: 'p-move',
            name: this.$t('partnerPortal.tariff.p-move.name'),
            shorttext: this.$t('partnerPortal.tariff.p-move.shorttext'),
            priceFrom: this.$t('partnerPortal.tariff.priceFromMonthly'),
            priceInteger: '6',
            priceDecimal: '50',
            currency: this.$t('partnerPortal.tariff.currencyMonthly'),
            buttonRouteName: this.localePath('BrokerOffer-Intro-P-Move'),
            bulletList: [
              { text: this.$t('partnerPortal.tariff.p-move.bulletList.1') },
              { text: this.$t('partnerPortal.tariff.p-move.bulletList.2') },
              { text: this.$t('partnerPortal.tariff.p-move.bulletList.3') },
              { text: this.$t('partnerPortal.tariff.p-move.bulletList.4') },
              { text: this.$t('partnerPortal.tariff.p-move.bulletList.5') },
              { text: this.$t('partnerPortal.tariff.p-move.bulletList.6') },
            ],
          },
          {
            id: 'p-combi',
            name: this.$t('partnerPortal.tariff.p-combi.name'),
            shorttext: this.$t('partnerPortal.tariff.p-combi.shorttext'),
            priceFrom: this.$t('partnerPortal.tariff.priceFromMonthly'),
            priceInteger: '24',
            priceDecimal: '50',
            currency: this.$t('partnerPortal.tariff.currencyMonthly'),
            buttonRouteName: this.localePath('BrokerOffer-Intro-P-Combi'),
          },
        ],
        company: [
          {
            id: 'b-business',
            name: this.$t('partnerPortal.tariff.b-business.name'),
            shorttext: this.$t('partnerPortal.tariff.b-business.shorttext'),
            priceFrom: this.$t('partnerPortal.tariff.priceFromYearly'),
            priceInteger: '420',
            priceDecimal: '00',
            currency: this.$t('partnerPortal.tariff.currencyYearly'),
            buttonRouteName: this.localePath('BrokerOffer-Intro-B-Business'),
            bulletList: [
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.1') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.2') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.3') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.4') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.5') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.6') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.7') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.8') },
              { text: this.$t('partnerPortal.tariff.b-business.bulletList.9') },
              {
                text: this.$t('partnerPortal.tariff.b-business.bulletList.10'),
              },
            ],
          },
          {
            id: 'b-move',
            name: this.$t('partnerPortal.tariff.b-move.name'),
            shorttext: this.$t('partnerPortal.tariff.b-move.shorttext'),
            priceFrom: this.$t('partnerPortal.tariff.priceFromYearly'),
            priceInteger: '63',
            priceDecimal: '00',
            currency: this.$t('partnerPortal.tariff.currencyYearly'),
            buttonRouteName: this.localePath('BrokerOffer-Intro-B-Move'),
            bulletList: [
              { text: this.$t('partnerPortal.tariff.b-move.bulletList.1') },
              { text: this.$t('partnerPortal.tariff.b-move.bulletList.2') },
              { text: this.$t('partnerPortal.tariff.b-move.bulletList.3') },
              { text: this.$t('partnerPortal.tariff.b-move.bulletList.4') },
              { text: this.$t('partnerPortal.tariff.b-move.bulletList.5') },
              { text: this.$t('partnerPortal.tariff.b-move.bulletList.6') },
            ],
          },
          {
            id: 'small-business',
            name: this.$t('partnerPortal.tariff.small-business.name'),
            shorttext: this.$t('partnerPortal.tariff.small-business.shorttext'),
            priceFrom: this.$t('partnerPortal.tariff.priceFromYearly'),
            priceInteger: '764',
            priceDecimal: '00',
            currency: this.$t('partnerPortal.tariff.currencyYearly'),
            buttonRouteName: this.localePath(
              'BrokerOffer-Intro-Small-Business',
            ),
            bulletList: [
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.1',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.2',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.3',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.4',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.5',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.6',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.7',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.8',
                ),
              },
              {
                text: this.$t(
                  'partnerPortal.tariff.small-business.bulletList.9',
                ),
              },
            ],
          },
        ],
      },
    }
  },
  computed: {
    buttonRouteQuery() {
      return this.resetOffer ? { reset: true } : {}
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
