window.isUpdateNeeded = false
window.intervalId = null

const check = async () => {
  if (!window.isUpdateNeeded) {
    const response = await fetch('/appVersion.json')
    if (response.status === 200) {
      const data = await response.json()
      window.isUpdateNeeded = data.version !== process.env.APP_VERSION
      if (window.isUpdateNeeded && window.intervalId) {
        window.clearInterval(window.intervalId)
      }
    }
  }
}

check()
window.intervalId = setInterval(check, 1000 * 60 * 5) // once every 5 minutes

export default ({ app }) => {
  // Every time the route changes (fired on initialization too)
  app.router.beforeEach((to, from, next) => {
    if (window.isUpdateNeeded) {
      window.location.href = to.fullPath
    } else {
      next()
    }
  })
}
