<template>
  <nav class="cmp-navigation-placebo">
    <div :class="cssClasses()" class="cmp-navigation-wrap">
      <div class="container-fluid cmp-navigation">
        <div class="row">
          <a :href="websiteURL" class="cmp-navigation-logo mr-4">
            <span class="visually-hidden">Zur Startseite</span>
          </a>

          <nav-items :nav-data="navItems" />

          <nav-side :account-subnav-data="accountSubnavData" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import NavItems from './NavItems'
import NavSide from './NavSide'

export default {
  name: 'DxDesktopNavigation',
  components: {
    NavItems,
    NavSide,
  },
  props: {
    navItems: {
      type: Array,
      default() {
        return []
      },
    },
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrolled: false,
      outerLink: false,
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollNav)
  },
  methods: {
    scrollNav() {
      this.scrolled = window.pageYOffset > 0
    },
    cssClasses() {
      return {
        'active-nav': this.scrolled || this.isSticky,
      }
    },
  },
  destroy() {
    window.removeEventListener('scroll', this.scrollNav)
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
