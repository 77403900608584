<template>
  <footer class="footer d-print-none">
    <div class="ext-footer">
      <div class="container-fluid">
        <div class="row">
          <div
            v-for="navItem in filteredNavData"
            :key="navItem.title"
            class="col-lg linkblock"
          >
            <h4 class="footer-item-headline">
              {{ $t(navItem.title) }}
            </h4>
            <ul>
              <li
                v-for="subnavItem in getFilteredSubNav(navItem.items)"
                :key="subnavItem.title"
              >
                <a
                  v-if="subnavItem.isWebsiteLink"
                  rel="noopener"
                  :href="`${websiteURL}${$t(subnavItem.path)}`"
                >
                  {{ $t(subnavItem.title) }}
                </a>
                <a
                  v-else-if="subnavItem.isServiceLink"
                  rel="noopener"
                  target="_blank"
                  :href="`${serviceURL}${$t(subnavItem.path)}`"
                >
                  {{ $t(subnavItem.title) }}
                </a>
                <a
                  v-else-if="subnavItem.isExternalLink"
                  rel="noopener"
                  target="_blank"
                  :href="`${$t(subnavItem.path)}`"
                >
                  {{ $t(item.title) }}
                </a>

                <nuxt-link v-else :to="localePath(subnavItem.path)">
                  {{ $t(subnavItem.title) }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="basic-footer">
      <div class="container-fluid">
        <div class="row position-relative">
          <div class="col footer-content">
            <div>
              <a
                :href="`${websiteURL}${$t('dataProtectionUrl')}`"
                target="_blank"
                rel="noopener"
                class="mb-0"
              >
                {{ $t('dataProtection') }}
              </a>
            </div>
            <div>
              <a
                :href="`${websiteURL}${$t('imprintUrl')}`"
                target="_blank"
                rel="noopener"
              >
                {{ $t('imprint') }}
              </a>
            </div>
            <div class="copy-text">
              &copy; {{ $t('footerCopyright') }} - {{ getYear }}
            </div>
          </div>
          <div class="logo position-absolute" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterItems from './footerItems'

export default {
  name: 'DxFooter',
  props: {
    isLargeFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
      serviceURL: process.env.DEXTRA_SERVICE_URL,
      footerItems: FooterItems,
    }
  },
  computed: {
    getYear() {
      const year = new Date()
      return year.getFullYear()
    },
    filteredNavData() {
      return [...this.footerItems].filter(this.navFilter)
    },
  },
  methods: {
    getFilteredSubNav(subNav) {
      return [...subNav].filter(this.navFilter)
    },
    hasSubItems(item) {
      return Object.prototype.hasOwnProperty.call(item, 'subItems')
    },
    navFilter(data) {
      const hideForLocale = data.hideForLocale === this.$i18n.locale
      const hideForEnv =
        data.hideForEnv && data.hideForEnv.includes(process.env.DEXTRA_ENV)
      return !hideForLocale && !hideForEnv
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
