<template>
  <div class="d-print-none">
    <dx-desktop-navigation
      :nav-items="navItems.length ? navItems : defaultNavigation"
      :account-subnav-data="accountSubnavData"
      :is-sticky="isSticky"
      class="d-none d-lg-block"
    />

    <dx-mobile-navigation
      :nav-items="navItems.length ? navItems : defaultNavigation"
      :account-subnav-data="accountSubnavData"
      class="d-lg-none"
    >
      <template slot="MobileNavTop">
        <slot name="MobileNavTop" />
      </template>

      <template slot="MobileNavBottom">
        <slot name="MobileNavBottom" />
      </template>
    </dx-mobile-navigation>
  </div>
</template>

<script>
import DxDesktopNavigation from './DX-Desktop-Navigation'
import DxMobileNavigation from './DX-Mobile-Navigation'

export default {
  name: 'DxNavigation',
  components: {
    DxDesktopNavigation,
    DxMobileNavigation,
  },
  props: {
    navItems: {
      type: Array,
      default() {
        return []
      },
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    accountSubnavData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      websiteURL: process.env.DEXTRA_WEBSITE_URL,
      locale: this.$i18n.locale === 'fr' ? `/${this.$i18n.locale}` : '',
    }
  },
  computed: {
    // eslint-disable-next-line max-lines-per-function
    defaultNavigation() {
      return [
        {
          path: '',
          name: this.$t('legal'),
          subNav: [
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.privatLegal',
              )}`,
              name: this.$t('partnerPortal.privatLegalProtection'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t('navLinksTariffs.privatFaq')}`,
              name: this.$t('faq'),
              hash: '',
              class: 'sub-sub-nav-item',
            },
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.privatDownloads',
              )}`,
              name: this.$t('downloads'),
              hash: '',
              class: 'sub-sub-nav-item',
            },
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.companyProtection',
              )}`,
              name: this.$t('companyProtection'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.companyFaq',
              )}`,
              name: this.$t('faq'),
              hash: '',
              class: 'sub-sub-nav-item',
            },
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.companyDownloads',
              )}`,
              name: this.$t('downloads'),
              hash: '',
              class: 'sub-sub-nav-item',
            },
          ],
        },
        {
          path: '',
          name: this.$t('car'),
          subNav: [
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.carRequest',
              )}`,
              name: this.$t('offer.calculatePremium'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t('navLinksTariffs.carPolicy')}`,
              name: this.$t('carInsuranceValue'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t('navLinksTariffs.carFaq')}`,
              name: this.$t('faq'),
              class: 'sub-sub-nav-item',
            },
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.carDownloads',
              )}`,
              name: this.$t('downloads'),
              hash: '',
              class: 'sub-sub-nav-item',
            },
            {
              path: '',
              name: this.$t('partneroffer'),
              hash: '',
              hideForLocale: 'fr',
            },
            {
              path: 'https://www.oldtimer-versichern.ch/?utm_source=dextra&utm_medium=navigation&utm_campaign=oldtimer',
              name: this.$t('oldtimer'),
              isExternal: true,
              hash: '',
              hideForLocale: 'fr',
              class: 'sub-sub-nav-item',
            },
            {
              path: 'https://autosense.ch/flexdrive',
              name: this.$t('autosense'),
              isExternal: true,
              hash: '',
              hideForLocale: 'fr',
              class: 'sub-sub-nav-item',
            },
            {
              path: 'https://www.kasko2go.com',
              name: 'Kasko2Go',
              isExternal: true,
              hash: '',
              hideForLocale: 'fr',
              class: 'sub-sub-nav-item',
            },
          ],
        },
        {
          path: '',
          name: this.$t('moto'),
          subNav: [
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.motoRequest',
              )}`,
              name: this.$t('offer.calculatePremium'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.motoProduct',
              )}`,
              name: this.$t('motoInsuranceValue'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t('navLinksTariffs.motoFaq')}`,
              name: this.$t('faq'),
              class: 'sub-sub-nav-item',
            },
            {
              path: `${this.websiteURL}${this.$t(
                'navLinksTariffs.motoDownloads',
              )}`,
              name: this.$t('downloads'),
              hash: '',
              class: 'sub-sub-nav-item',
            },
          ],
        },
        {
          path: '',
          name: this.$t('about'),
          subNav: [
            {
              path: `${this.websiteURL}${this.$t('navLinksTariffs.company')}`,
              name: this.$t('companyNav'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t('navLinksTariffs.team')}`,
              name: this.$t('team'),
              hash: '',
            },
            {
              path: `${this.websiteURL}${this.$t('navLinksTariffs.jobs')}`,
              name: this.$t('jobs'),
              hash: '',
            },
          ],
        },
        {
          path: `${this.websiteURL}${this.$t('navLinksTariffs.contact')}`,
          name: this.$t('contact'),
          subNav: [],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
