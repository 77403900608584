<template>
  <dx-tariff-container
    ref="container"
    :is-sticky="true"
    :tariff="selectedTariff"
    theme="dotted"
    class="d-none d-md-block animation"
  >
    <template slot="content">
      <div v-if="price" class="text-center">
        CHF
        <span class="big-price">
          {{ formatCurrency(price) }}
        </span>
        <span class="d-block">
          {{ $t('offer.tax') }}
        </span>
      </div>
      <div class="mt-5 text-right">
        <button
          v-if="isBrokerOffer"
          class="btn-blank secondary-link"
          @click="onTariffChange"
        >
          {{ $t('offer.changeTariff') }}
        </button>
        <a v-else :href="tariffURL" class="secondary-link">
          {{ $t('offer.changeTariff') }}
        </a>
      </div>
    </template>
  </dx-tariff-container>
</template>

<script>
import DxTariffContainer from '~/components/DX-TariffContainer'
import { formatCurrency } from '~/javascripts/utils'

export default {
  name: 'DownloadCenter',
  components: {
    DxTariffContainer,
  },
  props: {
    selectedTariff: {
      type: String,
      default: '',
    },
    price: {
      type: [String, Number],
      default: '',
    },
    isBrokerOffer: {
      type: Boolean,
      default: false,
    },
    onTariffChange: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      tariffURL: `${process.env.DEXTRA_WEBSITE_URL}${this.$t(
        'navLinksTariffs.privatLegal',
      )}`,
      formatCurrency,
    }
  },
  computed: {
    loading() {
      return this.$store.state.application.loading
    },
  },
  watch: {
    price(newValue, oldValue) {
      if (oldValue && newValue !== oldValue) {
        const warningOrange = '#f5af2366'
        this.$refs.container.$el.style.backgroundColor = warningOrange
        setTimeout(() => {
          if (this.$refs.container) {
            this.$refs.container.$el.style.backgroundColor = ''
          }
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.animation {
  transition: background-color 0.8s ease;
}
</style>
