<template>
  <notifications v-show="showInfoBar" ref="notifications" @close="onClose" />
</template>

<script>
import { Notifications } from '@sumcumo/dextra-frontend-component-lib'
import Cookies from 'js-cookie'
import InfoBarQuery from '~/domains/application/__gql__/queries/infoBar.gql'
import {
  PORTALS_CUSTOMER,
  PORTALS_LAWYER,
  PORTALS_LOGIN,
  PORTALS_PARTNER,
} from '~/domains/application/cms/projectScopes'

// import ENV from '@/env'
import createMarkdownToTemplate from '~/javascripts/helper/markdownToTemplate'

const markdownToTemplate = createMarkdownToTemplate()

export default {
  name: 'DxInfoBar',
  components: {
    Notifications,
  },
  props: {
    modulaStyle: {
      type: Boolean,
      default: false,
      autoClose: true,
    },
  },
  data() {
    return {
      today: new Date().toISOString(),
      infoBars: [],
      infoBarColor: null,
      showInfoBar: false,
    }
  },

  computed: {
    currentPortal() {
      return this.$store.state.authentication.currentPortal
    },
    useRedTheme() {
      return !this.$nuxt?.$data?.layoutName?.includes('modula')
    },

    projectScope() {
      switch (this.currentPortal) {
        case 'BROKER_PORTAL':
          return PORTALS_PARTNER
        case 'LAWYER_PORTAL':
          return PORTALS_LAWYER
        case 'CUSTOMER_CENTER':
          return PORTALS_CUSTOMER
        default:
          return null
      }
    },
  },

  methods: {
    getInfoBarId(infoBar) {
      return `${infoBar.id}--${infoBar.locale}`
    },

    addNotifications() {
      const { notifications } = this.$refs

      if (!notifications) {
        return
      }

      this.infoBars.forEach((infoBar) => {
        if (Cookies.get(`infoBar_${this.getInfoBarId(infoBar)}`)) {
          return
        }

        const id = this.getInfoBarId(infoBar)

        // only add notification if not already shown
        if (!notifications.notifications.find((e) => e.id === id)) {
          let type = null

          type =
            (infoBar.color && infoBar.color.toLowerCase()) ||
            (this.useRedTheme && 'red') ||
            'info'

          this.showInfoBar = true
          this.autoClose = true
          notifications.closeNotification(infoBar.id)
          notifications.addNotification({
            id,
            type,
            html: markdownToTemplate(infoBar.content),
          })
          // we use this super advanced technique called timeout here to distinguish between
          // autoclose and close via user interaction. in case you got an even better solution
          // please let me know :D
          setTimeout(() => {
            this.autoClose = false
          }, 200)
        }
      })
    },

    cleanupNotifications() {
      const { notifications } = this.$refs

      if (!notifications) {
        return
      }

      notifications.notifications.forEach((notification) => {
        if (
          !this.infoBars
            .map((e) => this.getInfoBarId(e))
            .includes(notification.id)
        ) {
          notifications.closeNotification(notification.id)
        }
      })
    },

    // we want to check whether it is the cookie infobar we are about to close
    // we save closed cookie infos for 30d and closed normal infos for 1d
    onClose(id) {
      const infoBarById = this.infoBars.find(
        (infoBar) => this.getInfoBarId(infoBar) === id,
      )
      if (infoBarById && !this.autoClose) {
        const isCookieInfo = infoBarById?.content.includes('Cookies')

        Cookies.set(`infoBar_${id}`, id, {
          expires: isCookieInfo ? 30 : 1,
          domain: process.env.DEXTRA_COOKIE_DOMAIN,
        })
      }
    },
  },

  apollo: {
    infoBars: {
      client: 'cms',
      query: InfoBarQuery,
      fetchPolicy: 'network-only',
      variables() {
        return {
          today: this.today,
          additionalProjectScope: this.currentPortal
            ? this.projectScope
            : PORTALS_LOGIN,
        }
      },
      skip() {
        return !!process.env.DEXTRA_E2E
      },
      result({ data }) {
        if (data?.infoBars?.length) {
          this.addNotifications()

          this.cleanupNotifications()
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  bottom: rem(74);
  width: 100%;
  z-index: 100;

  @include media-breakpoint-up(lg) {
    bottom: 0;
  }
}
</style>
