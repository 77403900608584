<template>
  <div class="mt-4 d-flex align-items-center">
    <div class="col-3 col-md-5 col-lg-7 text-right">
      {{ $t('partnerPortal.policyFilterOptions.filterBy') }}
    </div>
    <dx-tabs
      v-model="currentValue"
      class="col-9 col-md-7 col-lg-5 small text-uppercase pr-0"
      :tabs-data="tabOptions"
    />
  </div>
</template>

<script>
import { DxTabs } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'DxClosedPoliciesFilter',
  components: {
    DxTabs,
  },
  props: {
    value: {
      type: String,
      default: 'active',
    },
  },
  data() {
    return {
      currentValue: this.value,
      tabOptions: [
        {
          value: 'all',
          text: this.$t('partnerPortal.policyFilterOptions.all'),
          id: 'all',
        },
        {
          value: 'active',
          text: this.$t('partnerPortal.policyFilterOptions.active'),
          id: 'active',
        },
        {
          value: 'terminated',
          text: this.$t('partnerPortal.policyFilterOptions.terminated'),
          id: 'terminated',
        },
      ],
    }
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      this.currentValue = newValue
    },
  },
}
</script>
