<template>
  <dx-facts-form
    next-step-path="PartnerPortal-ReportDamage-DocumentUpload"
    previous-step-path="PartnerPortal-ReportDamage-Info"
    :is-broker="true"
  />
</template>

<script>
import DxFactsForm from '~/components/reportDamage/DX-FactsForm'

export default {
  name: 'PartnerReportDamageFacts',
  components: {
    DxFactsForm,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsfall-melden/sachverhalt',
      fr: '/portailpartenaires/cas-juridique-rapport/realites',
      it: '/portaledeipartner/segnalare-caso-giuridico/fatti',
    },
  },
}
</script>
