<template>
  <div>
    <dx-select
      v-model="areaOfLawSkill"
      v-validate.disable="'required'"
      :options="
        makeDataMap(
          'areaOfLawSkill',
          AREA_OF_LAW_SKILL,
          localDetails.areaOfLawSkills,
        )
      "
      :label="$t('lawyerPortal.jurisdiction')"
      :error="
        errors.first('requiredSelection', 'areaOfLawSkills') ||
        errors.first('areaOfLawSkillsEmpty')
      "
      class="mt-4 mb-2"
      data-vv-scope="areaOfLawSkills"
      data-vv-name="requiredSelection"
      @input="validateAreaOfLawSkillAdd"
    />

    <dx-simple-delete-list
      :items="localDetails.areaOfLawSkills"
      :get-translated-label="
        (skill) => $t(`lawyerPortal.areaOfLawSkill.${skill}`)
      "
      :on-delete-click="
        (skill) =>
          updateEmploymentDetails({
            areaOfLawSkills: localDetails.areaOfLawSkills.filter(
              (aols) => aols !== skill,
            ),
          })
      "
    />

    <dx-select
      v-model="jobTitle"
      v-validate.disable="'required'"
      :options="makeDataMap('jobTitles', JOB_TITLE, localDetails.jobTitles)"
      :label="$t('lawyerPortal.jobTitle')"
      :error="
        errors.first('requiredSelection', 'jobTitles') ||
        errors.first('jobTitlesEmpty')
      "
      class="mt-4 mb-2"
      data-vv-scope="jobTitles"
      data-vv-name="requiredSelection"
      @input="validateJobTitlesAdd"
    />
    <dx-simple-delete-list
      :items="localDetails.jobTitles"
      :get-translated-label="(title) => $t(`lawyerPortal.jobTitles.${title}`)"
      :on-delete-click="
        (title) =>
          updateEmploymentDetails({
            jobTitles: [...localDetails.jobTitles.filter((jt) => jt !== title)],
          })
      "
    />

    <div class="row mt-4">
      <div class="col-12 col-md-7 my-4 my-md-0">
        <dx-select
          v-model="additionalSkill.skill"
          v-validate.disable="'required'"
          :options="
            makeDataMap(
              'additionalSkill',
              ADDITIONAL_SKILL,
              localDetails.additionalSkills.map((as) => as.skill),
            )
          "
          :label="`${$t('lawyerPortal.otherQualifications')} (optional)`"
          :error="errors.first('requiredSelection', 'additionalSkill')"
          data-vv-scope="additionalSkill"
          data-vv-name="requiredSelection"
        />
      </div>
      <div class="col-12 col-md-5">
        <dx-input
          v-model="additionalSkill.sinceYear"
          v-validate.disable="'required|inThePastGermanFormat'"
          :label="$t('lawyerPortal.sinceWhen')"
          :error="errors.first('invalidDate', 'additionalSkill')"
          is-date-input
          type="date"
          data-vv-scope="additionalSkill"
          data-vv-name="invalidDate"
        />
      </div>

      <div class="text-right col mt-2">
        <button
          type="button"
          class="secondary-link btn-blank"
          @click.prevent="validateAdditionalQualificationAdd"
        >
          {{ $t('lawyerPortal.addQualification') }}
        </button>
      </div>
    </div>
    <dx-simple-delete-list
      :items="localDetails.additionalSkills"
      :get-translated-label="
        (aSkill) =>
          $t(`lawyerPortal.additionalSkill.${aSkill.skill}`) +
          ' (' +
          aSkill.sinceYear +
          ')'
      "
      :on-delete-click="
        (aSkill) =>
          updateEmploymentDetails({
            additionalSkills: [
              ...localDetails.additionalSkills.filter(
                (as) => as.skill !== aSkill.skill,
              ),
            ],
          })
      "
      item-key="skill"
    />

    <div class="row mt-4">
      <div class="col-12 col-md-7 my-4 my-md-0">
        <dx-select
          v-model="advancedTraining.training"
          v-validate.disable="'required'"
          :options="
            makeDataMap(
              'advancedTraining',
              ADVANCED_TRAINING,
              localDetails.advancedTrainings.map((at) => at.training),
            )
          "
          :label="`${$t('lawyerPortal.advancedTrainings')} (optional)`"
          :error="errors.first('requiredSelection', 'advancedTraining')"
          data-vv-scope="advancedTraining"
          data-vv-name="requiredSelection"
        />
      </div>
      <div class="col-12 col-md-5">
        <dx-input
          v-model="advancedTraining.sinceYear"
          v-validate.disable="'required|inThePastGermanFormat'"
          :label="$t('lawyerPortal.sinceWhen')"
          :error="errors.first('invalidDate', 'advancedTraining')"
          is-date-input
          type="date"
          data-vv-scope="advancedTraining"
          data-vv-name="invalidDate"
        />
      </div>

      <div class="text-right col mt-2">
        <button
          type="button"
          class="secondary-link btn-blank"
          @click.prevent="validateAdvancedTrainingsAdd"
        >
          {{ $t('lawyerPortal.advancedTrainingsAdd') }}
        </button>
      </div>
    </div>
    <dx-simple-delete-list
      :items="localDetails.advancedTrainings"
      :get-translated-label="
        (aT) =>
          $t(`lawyerPortal.advancedTraining.${aT.training}`) +
          ' (' +
          aT.sinceYear +
          ')'
      "
      :on-delete-click="
        (aT) =>
          updateEmploymentDetails({
            advancedTrainings: [
              ...localDetails.advancedTrainings.filter(
                (at) => at.training !== aT.training,
              ),
            ],
          })
      "
      item-key="training"
    />
  </div>
</template>

<script>
import { DxInput, DxSelect } from '@sumcumo/dextra-frontend-component-lib-old'
import DxSimpleDeleteList from '~/components/DX-SimpleDeleteList'

import {
  LANGUAGE_SKILLS,
  AREA_OF_LAW_SKILL,
  JOB_TITLE,
  ADDITIONAL_SKILL,
  ADVANCED_TRAINING,
} from '~/domains/externalLawyer/constants'
import { dateToYear } from '~/javascripts/utils'

export default {
  name: 'DxEmploymentDetails',
  components: {
    DxInput,
    DxSimpleDeleteList,
    DxSelect,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    employmentDetails: {
      type: Object,
      default: () => ({
        areaOfLawSkills: [],
        jobTitles: [],
        additionalSkills: [],
        advancedTrainings: [],
      }),
    },
    updateEmploymentDetails: {
      type: Function,
      default: () => null,
    },
    clearErrors: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      areaOfLawSkill: null,
      jobTitle: null,
      additionalSkill: {
        skill: null,
        sinceYear: null,
      },
      advancedTraining: {
        training: null,
        sinceYear: null,
      },
    }
  },
  computed: {
    LANGUAGE_SKILLS: () => LANGUAGE_SKILLS,
    AREA_OF_LAW_SKILL: () => AREA_OF_LAW_SKILL,
    JOB_TITLE() {
      // 'INTERCEDER' should only be available in DE locale
      if (this.$i18n.locale !== 'de') {
        return JOB_TITLE.filter((title) => title !== 'INTERCEDER')
      }

      return JOB_TITLE
    },
    ADDITIONAL_SKILL: () => ADDITIONAL_SKILL,
    ADVANCED_TRAINING: () => ADVANCED_TRAINING,
    localDetails() {
      return {
        ...this.employmentDetails,
        areaOfLawSkills: [...this.employmentDetails.areaOfLawSkills],
        jobTitles: [...this.employmentDetails.jobTitles],
        additionalSkills: [...this.employmentDetails.additionalSkills],
        advancedTrainings: [...this.employmentDetails.advancedTrainings],
      }
    },
  },
  methods: {
    makeDataMap(translationKey, ITEMS, FILTER_ITEMS = []) {
      const defaultOption = {
        label: this.$t('selectOption'),
        value: null,
      }
      const itemMap = ITEMS.map((item) => ({
        label: this.$t(`lawyerPortal.${translationKey}.${item}`),
        value: item,
      })).filter((item) => !FILTER_ITEMS.some((fi) => fi === item.value))

      return [defaultOption, ...itemMap]
    },
    async validateAreaOfLawSkillAdd() {
      this.clearErrors()
      const isValid = await this.$validator.validateAll('areaOfLawSkills')
      if (isValid) {
        this.updateEmploymentDetails({
          areaOfLawSkills: [
            ...this.localDetails.areaOfLawSkills,
            this.areaOfLawSkill,
          ],
        })
        this.areaOfLawSkill = null
      }
    },
    async validateJobTitlesAdd() {
      this.clearErrors()
      const isValid = await this.$validator.validateAll('jobTitles')
      if (isValid) {
        this.updateEmploymentDetails({
          jobTitles: [...this.localDetails.jobTitles, this.jobTitle],
        })
        this.jobTitle = null
      }
    },
    async validateAdditionalQualificationAdd() {
      this.clearErrors()
      const isValid = await this.$validator.validateAll('additionalSkill')
      if (isValid) {
        const year = dateToYear(this.additionalSkill.sinceYear)
        const skill = {
          ...this.additionalSkill,
          sinceYear: parseInt(year, 10),
        }
        this.updateEmploymentDetails({
          additionalSkills: [...this.localDetails.additionalSkills, skill],
        })
        this.additionalSkill = {
          skill: null,
          sinceYear: null,
        }
      }
    },
    async validateAdvancedTrainingsAdd() {
      this.clearErrors()
      const isValid = await this.$validator.validateAll('advancedTraining')
      if (isValid) {
        const year = dateToYear(this.advancedTraining.sinceYear)
        const training = {
          ...this.advancedTraining,
          sinceYear: parseInt(year, 10),
        }
        this.updateEmploymentDetails({
          advancedTrainings: [...this.localDetails.advancedTrainings, training],
        })
        this.advancedTraining = {
          training: null,
          sinceYear: null,
        }
      }
    },
  },
}
</script>
