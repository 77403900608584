<template>
  <form @submit.prevent="save">
    <div :class="{ 'has-delete-icon': removeItemIcon }" class="icons-wrap">
      <component :is="tagType" v-if="headline" class="headline">
        {{ headline }}
      </component>
      <slot v-else name="headline-content" />

      <!-- nur policyholder darf adresse ändern -->
      <button
        v-if="!editMode && show"
        class="d-inline-block btn-blank icon icon-edit"
        @click.prevent="toggleEditMode"
      >
        <span class="visually-hidden">
          {{ $t('updateRecords') }}
        </span>
      </button>

      <button
        v-if="!editMode && removeItemIcon"
        class="d-inline-block btn-blank icon icon-delete"
        @click.prevent="deleteCallback"
      >
        <span class="visually-hidden">
          {{ $t('deletePerson') }}
        </span>
      </button>
    </div>

    <!-- SLOT DATA-DYNAMIC -->
    <div :class="{ 'personal-data-content': !editMode }">
      <slot name="data-dynamic" :edit-mode="editMode" />
    </div>

    <!-- SLOT NON-EDIT-MODE -->
    <div
      v-if="!editMode && $slots['data-non-edit-mode']"
      class="personal-data-content"
    >
      <slot name="data-non-edit-mode" />
    </div>

    <!-- SLOT EDIT-MODE -->
    <template v-if="editMode">
      <slot name="data-edit-mode" />
    </template>

    <!-- BUTTONS -->
    <div v-if="editMode && showButtons" class="d-flex justify-content-end mt-4">
      <button
        class="secondary-link btn-blank mr-3"
        tabindex="0"
        type="button"
        @click="cancel"
      >
        {{ abortText ? abortText : $t('abort') }}
      </button>
      <button
        class="secondary-link btn-blank"
        tabindex="0"
        type="submit"
        @click.prevent="save"
      >
        {{ $t('save') }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'DxEditDataToggle',
  inject: {
    $validator: '$validator',
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      default: () => null,
    },
    cancelCallback: {
      type: Function,
      default: () => null,
    },
    deleteCallback: {
      type: Function,
      default() {
        return null
      },
    },
    removeItemIcon: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    onOpenCallback: {
      type: Function,
      default: () => null,
    },
    onCloseCallback: {
      type: Function,
      default: () => null,
    },
    tagType: {
      type: String,
      default: 'h5',
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    abortText: {
      type: String,
      required: false,
      default: null,
    },
    initiallyEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: this.initiallyEditable,
    }
  },
  methods: {
    cancel() {
      this.toggleEditMode()
      this.cancelCallback()
    },
    toggleEditMode() {
      this.editMode = !this.editMode
      if (this.editMode) {
        this.onOpenCallback()
      } else {
        this.onCloseCallback()
      }
    },
    async save() {
      try {
        const result = await this.callback()
        if (result) {
          this.toggleEditMode()
        }
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
