<template>
  <li>
    <dl>
      <dt>{{ label }}</dt>
      <dd>{{ value }}</dd>
    </dl>
  </li>
</template>
<script>
export default {
  name: 'KeyValueEntry',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
}
</script>
