<template>
  <div class="container-fluid modulaStyles">
    <div class="content-wrap-small content-pad">
      <div class="mt-3 mt-md-1">
        <nuxt-link :to="localePath('Login')" class="arrow-left overview light">
          {{ $t('backToLogin') }}
        </nuxt-link>
      </div>
    </div>
    <div class="content-wrap-small content-pad">
      <div class="mb-4 mb-md-5">
        <h3 class="headline">
          {{ $t('loginPage.newPassword.headline') }}
        </h3>
        <p class="subheadline">
          {{ $t('loginPage.newPassword.subHeadline') }}
        </p>
      </div>

      <form @submit.prevent>
        <dx-input
          v-model="newPasswordData.passwordResetHash"
          class="d-none"
          disabled
        />
        <dx-input
          v-model="newPasswordData.policyNumber"
          v-validate.disable="'required|min:1'"
          :error="errors.first('policyNumber')"
          :label="$t('policyNumber')"
          data-vv-name="policyNumber"
          class="mb-4"
          inverted
        />
        <dx-input
          v-model="newPasswordData.password"
          v-validate.disable="`required|min:8`"
          :label="$t('passwordChange')"
          :error="errors.first('password')"
          data-vv-name="password"
          type="password"
          class="mb-4"
          autocomplete="password"
          inverted
        />

        <dx-input
          v-model="newPasswordData.passwordRepeat"
          v-validate.disable="
            `required|min:8|match_strings:${newPasswordData.password}`
          "
          :label="$t('passwordRepeat')"
          :error="errors.first('passwordRepeat')"
          data-vv-name="passwordRepeat"
          type="password"
          autocomplete="password"
          class="mb-4"
          inverted
        />
        <div class="text-right">
          <dx-button
            :text="$t('submit')"
            class="secondary-link btn-blank"
            @click="sendFormData"
          >
            {{ $t('submit') }}
          </dx-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { DxButton, DxInput } from '@sumcumo/dextra-frontend-component-lib'
import newPasswordMutation from '~/domains/authentication/__gql__/mutations/newPassword.gql'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import apolloErrorsMixin from '~/javascripts/mixins/apolloErrorsMixin'
import validationErrorsMixin from '~/javascripts/mixins/validationErrorsMixin'
import scrollFirstErrorIntoView from '~/javascripts/helper/scollIntoView'

export default {
  name: 'DxNewPasswordForm',
  components: {
    DxButton,
    DxInput,
  },
  mixins: [loadingMixin, apolloErrorsMixin, validationErrorsMixin],
  data() {
    return {
      newPasswordData: {
        password: '',
        passwordRepeat: '',
        policyNumber: '',
        passwordResetHash: this.$route.query.h,
      },
    }
  },
  methods: {
    historyBack() {
      window.history.back()
    },
    resetForm() {
      this.newPasswordData = {
        password: '',
        passwordRepeat: '',
        policyNumber: '',
        passwordResetHash: '',
      }
      this.$validator.reset()
    },
    async sendFormData() {
      let result = false
      const collectValidation = await this.$validator.validateAll()
      if (collectValidation) {
        const { password, passwordRepeat, policyNumber, passwordResetHash } =
          this.newPasswordData
        const variables = {
          newPasswordData: {
            password,
            passwordRepeat,
            policyNumber,
            passwordResetHash,
          },
        }
        this.setGlobalLoading(true)
        try {
          const { data } = await this.$apollo.mutate({
            mutation: newPasswordMutation,
            variables,
          })
          if (!data || !data.newPassword) {
            this.$notification.error({
              messageKey: 'notifications.error.message',
            })
            return result
          }
          const { success, validationErrors } = data.newPassword

          if (success) {
            this.$notification.success({ messageKey: 'passwordChangedSuccess' })
            this.resetForm()
            this.$router.push(this.localePath('LawyerPortal'))
            result = true
          } else {
            validationErrors.forEach((err) => {
              if (err.field === 'passwordResetHash') {
                this.$notification.error({
                  messageKey: `validationErrors.${err.key}`,
                })
              }
              this.$validator.errors.add(this.getServerValidationError(err))
            })

            scrollFirstErrorIntoView()
          }
        } catch (error) {
          const sanitizedErrors = this.handleApolloError(error)

          if (sanitizedErrors.networkError.code === 14) {
            // USERNAME_ALREADY_EXISTS
            this.$notification.error({
              messageKey: 'validationErrors.ERROR__EMAIL_NOT_AVAILABLE',
            })
          } else {
            this.$notification.error({
              messageKey: 'notifications.error.message',
            })
          }
        } finally {
          this.setGlobalLoading(false)
        }
      } else {
        scrollFirstErrorIntoView()
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/styles/newModulaTypoStyles.scss';
</style>
