<template>
  <div>
    <!-- NON EDIT MODE -->
    <div v-if="!editable" class="personal-data-form">
      <ul v-if="!loading" class="neutral">
        <li v-if="customerData.companyName">
          {{ `${$t('company')} ${customerData.companyName}` }}
        </li>
        <li v-if="formatedSalutation">
          {{ formatedSalutation }}
        </li>
        <li v-if="customerData.street">
          {{ `${customerData.street} ${customerData.number}` }}
        </li>
        <li v-if="customerData.zip">
          {{ `${customerData.zip} ${customerData.city}` }}
        </li>
        <li
          v-if="
            customerData.isAdressMutation && $store.getters.isDexPolicyholder
          "
          class="mt-3"
        >
          {{ $t('willChangedAt') }}:
          {{ getFormattedDate(customerData.validFrom) }}
        </li>
      </ul>
      <dx-loading-overlay v-if="loading" />
    </div>

    <!-- EDIT MODE -->
    <div v-else>
      <dx-radio-list
        v-if="isSinglePerson"
        v-model="customerData.salutation"
        :options="salutationOptions"
        disable-list
        :label="$t('salutation')"
        name="personal_data_gender"
        class="mb-4"
      />

      <dx-input
        v-if="isCompany"
        v-model="customerData.companyName"
        :label="$t('company')"
        disabled
        class="mb-4"
      />

      <dx-input
        v-if="isSinglePerson || isCompany"
        v-model="customerData.firstName"
        :label="$t('firstName')"
        disabled
        class="mb-4"
      />

      <dx-input
        v-if="isSinglePerson || isFamily || isCompany"
        v-model="customerData.lastName"
        :label="$t('lastName')"
        disabled
        class="mb-4"
      />

      <div class="d-flex mb-4">
        <dx-input
          v-model="customerData.street"
          v-validate.disable="'required|min:2'"
          :label="$t('street')"
          :error="errors.first('street')"
          class="mr-3 w-75"
          data-vv-name="street"
          autocomplete="address-line1"
        />

        <dx-input
          v-model="customerData.number"
          v-validate.disable="'required|min:1'"
          :error="errors.first('streetNumber')"
          :label="$t('houseNumber')"
          class="w-40"
          data-vv-name="streetNumber"
          autocomplete="address-line2"
        />
      </div>

      <dx-zip-to-city-input
        :zip-input="customerData.zip"
        :city-input="customerData.city"
        required
        @update:city="setCity"
        @update:zip="setZip"
      />

      <dx-input
        v-model="customerData.validFrom"
        v-validate.disable="'required|inTheFutureGermanFormat'"
        :error="errors.first('invalidDate')"
        :label="$t('changedAt')"
        only-in-future
        type="date"
        class="mb-4"
        data-vv-name="invalidDate"
      />

      <dx-radio-list
        v-if="!disableEditing"
        v-model="customerData.newPolicy"
        :options="policyOptions"
        :label="$t('newPolicy')"
        name="regenerate_policy"
        class="mb-4"
      />
    </div>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxZipToCityInput from '~/components/DX-ZipToCityInput'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import updateAddressMutation from '~/domains/user/__gql__/mutations/updateAddressMutation.gql'
import createAddressMutation from '~/domains/user/__gql__/mutations/createAddressMutation.gql'
import profileQuery from '~/domains/user/__gql__/queries/profile.gql'

import loadingMixin from '~/javascripts/mixins/loadingMixin'
import dateMixin from '~/javascripts/mixins/dateMixin'

export default {
  name: 'DxPersonalDataForm',
  components: {
    DxZipToCityInput,
    DxInput,
    DxRadioList,
    DxLoadingOverlay,
  },
  mixins: [loadingMixin, dateMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      salutationOptions: [
        {
          text: this.$t('male'),
          id: 'MALE',
        },
        {
          text: this.$t('female'),
          id: 'FEMALE',
        },
        {
          text: this.$t('family'),
          id: 'FAMILY',
        },
        {
          text: this.$t('company'),
          id: 'COMPANY',
        },
      ],
      policyOptions: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
      address: null,
      customerData: {
        companyName: '',
        salutation: '',
        firstName: '',
        lastName: '',
        street: '',
        number: '',
        zip: '',
        city: '',
        validFrom: null,
        isAdressMutation: false,
        hash: null,
        newPolicy: false,
      },
      loading: true,
    }
  },
  computed: {
    disableEditing() {
      return !this.$store.getters.isDexPolicyholder
    },
    isCompany() {
      const { salutation } = this.customerData
      return (
        salutation &&
        salutation.toUpperCase() === this.$config.salutations.company
      )
    },
    isFamily() {
      const { salutation } = this.customerData
      return (
        salutation &&
        salutation.toUpperCase() === this.$config.salutations.family
      )
    },
    isSinglePerson() {
      const { salutation } = this.customerData
      return (
        (salutation &&
          salutation.toUpperCase() === this.$config.salutations.male) ||
        this.$config.salutations.female
      )
    },
    formatedSalutation() {
      let salutationBlock = ''
      const { firstName, lastName, salutation } = this.customerData

      if (this.isCompany) {
        salutationBlock = `${firstName || ''} ${lastName || ''}`
      } else if (this.isFamily) {
        salutationBlock = `${this.$t('family')} ${lastName || ''}`
      } else {
        salutationBlock = `${
          salutation ? this.$t(salutation.toLowerCase()) : ''
        } ${firstName || ''} ${lastName || ''}`
      }
      return salutationBlock
    },
  },
  methods: {
    editAddressCallback() {
      return this.customerData.isAdressMutation
        ? this.updateAddressMutationFunction()
        : this.createAddressMutationFunction()
    },
    cancelCallback() {
      this.loading = true
      return this.$apollo.queries.profile.refetch()
    },
    setZip(val) {
      this.customerData.zip = val
    },
    setCity(val) {
      this.customerData.city = val
    },
    async updateAddressMutationFunction() {
      let result = false
      const isValid = await this.$validator.validateAll()
      if (isValid) {
        const variables = {
          address: {
            street: this.customerData.street,
            number: this.customerData.number,
            zip: this.customerData.zip,
            city: this.customerData.city,
            validFrom: this.customerData.validFrom,
            hash: this.customerData.hash,
            generateNewPolicy: this.customerData.newPolicy,
          },
        }
        try {
          this.setGlobalLoading(true)
          result = await this.$apollo.mutate({
            mutation: updateAddressMutation,
            variables,
          })
          if (result) {
            this.finishAddressMutation()
          }
        } catch (error) {
          this.$notification.error({
            messageKey: 'userData.addressMutationError',
          })
          result = false
        } finally {
          this.setGlobalLoading(false)
        }
      }
      return result
    },
    async createAddressMutationFunction() {
      let result = false
      const isValid = await this.$validator.validateAll()
      if (isValid) {
        const variables = {
          address: {
            street: this.customerData.street,
            number: this.customerData.number,
            zip: this.customerData.zip,
            city: this.customerData.city,
            validFrom: this.customerData.validFrom,
            generateNewPolicy: this.customerData.newPolicy,
          },
        }
        try {
          this.setGlobalLoading(true)
          result = await this.$apollo.mutate({
            mutation: createAddressMutation,
            variables,
          })
          if (result) {
            this.finishAddressMutation()
          }
        } catch (error) {
          console.error(error)
          this.$notification.error({
            messageKey: 'userData.addressMutationError',
          })
          result = false
        } finally {
          this.setGlobalLoading(false)
        }
      }
      return result
    },
    async finishAddressMutation() {
      const result = await this.$apollo.queries.profile.refetch()
      if (result) {
        this.setGlobalLoading(false)
        this.$notification.success({
          messageKey: 'userData.addressMutationSuccess',
        })
      }
    },
    migrateDataWithState(profile) {
      const { customer, username } = profile
      this.$store.commit('SET_CURRENT_USER', {
        currentUser: { ...customer, username },
      })

      Object.keys(this.customerData).forEach((key) => {
        if (key !== 'isAdressMutation' && key !== 'newPolicy') {
          if (customer.addressMutation && customer.addressMutation[key]) {
            this.customerData[key] = customer.addressMutation[key]
            this.customerData.isAdressMutation = true
          } else if (
            !customer.addressMutation &&
            customer.address &&
            customer.address[key]
          ) {
            this.customerData[key] = customer.address[key]
          } else if (key === 'salutation' && customer.salutation) {
            this.customerData[key] = customer[key].toUpperCase()
          } else {
            this.customerData[key] = customer[key]
          }
        }
      })
    },
  },
  apollo: {
    profile: {
      query: profileQuery,
      fetchPolicy: 'network-only',
      result({ data }) {
        if (data && data.profile) {
          this.migrateDataWithState(data.profile)
        } else {
          this.$notification.error({
            messageKey: 'notifications.error.message',
          })
        }
        this.setGlobalLoading(false)
        this.loading = false
      },
    },
  },
}
</script>
