var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:(
      ("" + (_vm.contactDetails.mobile && _vm.contactDetails.mobile.trim()
          ? ''
          : 'required|min:4'))
    ),expression:"\n      `${\n        contactDetails.mobile && contactDetails.mobile.trim()\n          ? ''\n          : 'required|min:4'\n      }`\n    ",modifiers:{"disable":true}}],staticClass:"mb-4",attrs:{"label":_vm.$t('telephone'),"error":_vm.errors.first('mobileOrPhoneNumber'),"data-vv-name":"mobileOrPhoneNumber","autocomplete":"tel"},model:{value:(_vm.contactDetails.phone),callback:function ($$v) {_vm.$set(_vm.contactDetails, "phone", $$v)},expression:"contactDetails.phone"}}),_vm._v(" "),_c('dx-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:(
      ("" + (_vm.contactDetails.phone && _vm.contactDetails.phone.trim()
          ? ''
          : 'required|min:4'))
    ),expression:"\n      `${\n        contactDetails.phone && contactDetails.phone.trim()\n          ? ''\n          : 'required|min:4'\n      }`\n    ",modifiers:{"disable":true}}],staticClass:"mb-4",attrs:{"label":_vm.$t('mobilePhone'),"error":_vm.errors.first('mobileOrPhoneNumber'),"data-vv-name":"mobileOrPhoneNumber","autocomplete":"tel"},model:{value:(_vm.contactDetails.mobile),callback:function ($$v) {_vm.$set(_vm.contactDetails, "mobile", $$v)},expression:"contactDetails.mobile"}}),_vm._v(" "),_c('dx-input',{staticClass:"mb-4",attrs:{"label":_vm.$t('url')},model:{value:(_vm.contactDetails.website),callback:function ($$v) {_vm.$set(_vm.contactDetails, "website", $$v)},expression:"contactDetails.website"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }