<template>
  <div
    :class="[
      'icon-box-wrapper',
      hasRouteData ? 'cursor-pointer' : '',
      { 'align-left': left, 'animated': animated },
    ]"
    @click="handleClick"
  >
    <div :class="`icon--${iconType}`" class="icon" />
    <div :class="{ 'text-left': left }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DxIconBox',
  props: {
    iconType: {
      type: String,
      default: 'customer',
    },
    left: {
      type: Boolean,
      default: false,
    },
    routeData: {
      type: Object,
      default() {
        return {}
      },
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasRouteData() {
      return Object.keys(this.routeData).some((key) =>
        Boolean(this.routeData[key]),
      )
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
      if (this.hasRouteData) {
        this.toRoute()
      }
    },
    toRoute() {
      this.$router.push({
        path: this.routeData.name,
        hash: this.routeData.hash,
        query: this.routeData.query,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
