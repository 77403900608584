<template>
  <div class="container-fluid text-center">
    <div class="content-wrap-x-small content-pad content-pad--big-top">
      <h2 class="mt-5 mb-4">
        {{ $t('conflictSolver.dexSolution.headline') }}
      </h2>
      <p v-html="$t('conflictSolver.dexSolution.copy', { contactUrl })" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Unsolvable',
  data() {
    return {
      websiteUrl: process.env.DEXTRA_WEBSITE_URL,
    }
  },
  computed: {
    contactUrl() {
      return `${this.websiteUrl}${this.$t('nav.path1')}`
    },
  },
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/ueberarbeiten-zugangsdaten-dextra',
      fr: '/reviser-donnees-d-acces-dextra',
      it: '/rivedere-dati-d-accesso-dextra',
    },
  },
}
</script>
