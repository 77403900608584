<template>
  <div>
    <div
      class="content-wrap-medium mb-3 mb-md-0 pt-3 text-right damage-communication"
      :class="`status-${shareStatus}`"
    >
      <button
        type="button"
        class="btn-blank text-underline"
        @click="openRequestOverlay"
      >
        {{ linkText }}
      </button>

      <!-- eslint-disable -->
      <svg
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="33"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)">
            <ellipse
              class="icon-background-color"
              cx="18"
              cy="16.5"
              fill="#D9E3EE"
              rx="16"
              ry="15.5"
            />
            <ellipse
              cx="15.5"
              cy="15"
              stroke="#263047"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              rx="15.5"
              ry="15"
            />
          </g>
          <g fill-rule="nonzero" transform="translate(10 8)">
            <path
              class="icon-color"
              stroke="#FFF"
              stroke-linecap="square"
              d="M5.12518477 13.2804841l6.64806792-3.71526498M5.00184649 4.35896342l6.60861166 3.78500347"
            />
            <circle class="icon-color" cx="3" cy="3" r="3" fill="#FFF" />
            <circle class="icon-color" cx="3" cy="14" r="3" fill="#FFF" />
            <circle class="icon-color" cx="12" cy="9" r="3" fill="#FFF" />
          </g>
          <g fill-rule="nonzero" transform="translate(10 7)">
            <path
              class="icon-color"
              stroke="#00AFCF"
              stroke-linecap="square"
              d="M5.15811119 13.33821356l6.58221507-3.8307239M4.79793433 4.36637918l6.47249119 4.013335"
            />
            <circle class="icon-color" cx="3" cy="3" r="3" fill="#00AFCF" />
            <circle class="icon-color" cx="3" cy="14" r="3" fill="#00AFCF" />
            <circle class="icon-color" cx="12" cy="9" r="3" fill="#00AFCF" />
          </g>
        </g>
      </svg>
      <!-- eslint-enable -->
    </div>
    <dx-popup-modal ref="requestOverlay">
      <p>{{ confirmationText }}</p>
      <p>{{ question }}</p>
      <div
        class="d-flex flex-wrap-reverse justify-content-center d-sm-block text-right"
      >
        <span
          class="abort-btn mt-2 mt-sm-0 secondary-link btn-blank"
          @click="closeRequestOverlay"
        >
          {{ $t('damageoverview.cancel') }}
        </span>

        <dx-button
          type="button"
          class="d-inline ml-sm-3"
          :text="submitLabel"
          small
          @click="toggleShare"
        />
      </div>
    </dx-popup-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxPopupModal,
} from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'DxClaimShareStatus',
  components: {
    DxButton,
    DxPopupModal,
  },
  props: {
    shared: {
      type: Boolean,
      default: false,
    },
    brokerName: {
      type: String,
      default: '',
    },
    onToggleShare: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    shareStatus() {
      return this.shared ? 'accepted' : 'none'
    },
    messageKeyPrefix() {
      return this.shared ? 'withdrawShare' : 'acceptShare'
    },
    linkText() {
      return this.$t(`damageoverview.${this.messageKeyPrefix}.linkText`)
    },
    confirmationText() {
      return this.$t(`damageoverview.${this.messageKeyPrefix}.confirmationText`)
    },
    question() {
      return this.$t(`damageoverview.${this.messageKeyPrefix}.question`, {
        broker: this.brokerName,
      })
    },
    submitLabel() {
      return this.$t(`damageoverview.${this.messageKeyPrefix}.submit`)
    },
  },
  methods: {
    openRequestOverlay() {
      this.$refs.requestOverlay.open()
    },
    closeRequestOverlay() {
      this.$refs.requestOverlay.close()
    },
    toggleShare() {
      this.onToggleShare()
      this.$refs.requestOverlay.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
