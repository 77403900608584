<template>
  <div>
    <!-- NON EDIT MODE -->
    <template v-if="!editable">
      <ul class="neutral mb-0">
        <li>
          <h5 class="mb-0">{{ $t('accountOwner') }}:</h5>
          <p>{{ form.accountHolder }}</p>
        </li>
        <li class="mt-3">
          <h5 class="mb-0">{{ $t('iban') }}:</h5>
          <p>{{ form.iban }}</p>
        </li>
      </ul>
    </template>

    <!-- EDIT MODE -->
    <template v-if="editable">
      <form>
        <dx-input
          v-model="form.accountHolder"
          v-validate.disable="'required'"
          :error="errors.first('accountHolder')"
          :label="$t('accountOwner')"
          class="mb-4"
          data-vv-name="accountHolder"
        />

        <dx-input
          v-model="form.iban"
          v-validate.disable="'required|validate_IBAN'"
          :error="errors.first('ibanInput')"
          :label="$t('iban')"
          class="mb-4"
          data-vv-name="ibanInput"
        />
      </form>
    </template>
  </div>
</template>

<script>
import { DxInput } from '@sumcumo/dextra-frontend-component-lib-old'
import loadingMixin from '~/javascripts/mixins/loadingMixin'

export default {
  name: 'DxBankDetailsForm',
  components: {
    DxInput,
  },
  mixins: [loadingMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        accountHolder: null,
        iban: null,
      },
    }
  },
}
</script>
