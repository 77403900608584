export default [
  'AGRICULTURE_FORESTRY',
  'FISHERY',
  'NATURAL_RESOURCES',
  'INDUSTRY_CREATION',
  'CHEMICAL_PRODUCTS',
  'MECHANICAL_ENGINIEEARING',
  'WORKSHOPS_SHIPY_YARDS',
  'ENERGY_WATER_SUPPLIES',
  'DISPOSAL_AND_RECYCLING',
  'CONSTRUCTION_TOTAL_GENERAL_CONTRACTING',
  'BUILDING_ANCILLARY_FINISHING_TRADE_BUILDING_SERVICES_GARDENERS',
  'CAR_GARAGES',
  'WHOLESSALE',
  'RETAIL',
  'TRANSPORTATION_INDUSTRY_WAREHOUSING',
  'MARITIME',
  'AVIATION',
  'POSTAL_COURIER_SERVICES',
  'HOSPITALITY',
  'PUBLISHING',
  'MUSIC_SALES',
  'RADIO_TV_PRINT_MEDIA_NEW_MEDIA',
  'TELECOMMUNICATIONS',
  'IT_COMPANIES',
  'ASSET_MANAGERS_BANKS_INSURANCE_COMPANIES',
  'INSURANCE_ADVICE',
  'REAL_ESTATE_BROKERAGE_RENTAL',
  'BUSINESS_CONSULTING_LEGAL_ADVICE_FIDUCIARY',
  'ARCHITECTURE_ENGINEERING_OFFICES',
  'ADVERTISING_MARKETING_SALES_CALL_CENTERS',
  'VETERINARY',
  'RENTAL_OF_MOVABLE_PROPERTY',
  'RECRUITMENT',
  'TRAVEL_INDUSTRY',
  'SECURITY_DETECTIVES',
  'PUBLIC_ADMINISTRATION_SOCIAL_SECURITY',
  'EDUCATION_AND_SCHOOL',
  'MEDICAL_CARE_HEALTH_CARE',
  'RESIDENTIAL_AND_NURSING_HOMES',
  'REATIVE_ARTISTIC_ACTIVITIES_DESIGN_ILLUSTRATIONS',
  'LIBRARIES_MUSEUMS_BOTANICAL_ZOOLOGICAL_GARDENS',
  'GAMBLING_BETTING_AND_LOTTERY',
  'SPORTS_ENTERTAINMENT_TOURISM',
  'CHURCH_ASSOCIATIONS',
  'HAIRDRESSING_BEAUTY_AND_MASSAGE_SALONS',
  'EXTRA_TERRITORIAL_ORGANIZATIONS_AND_CORPORATE_BODIES',
]
