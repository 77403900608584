/* eslint-disable no-param-reassign, no-unused-vars */
import getDefaultState from './initialState'

export const RESET_STORE_DAMAGEREPORT = 'RESET_STORE_DAMAGEREPORT'
export const SET_REPORT = 'SET_REPORT'
export const SET_ATTACHMENT_TYPES = 'SET_ATTACHMENT_TYPES'
export const SET_SELECTED_VALUES = 'SET_SELECTED_VALUES'
export const SET_PERSON_ID = 'SET_PERSON_ID'

export default {
  [RESET_STORE_DAMAGEREPORT](state) {
    Object.assign(state, getDefaultState())
  },
  [SET_REPORT](state, { report }) {
    state.report = { ...state.report, ...report }
  },
  [SET_ATTACHMENT_TYPES](state, { attachmentTypes }) {
    state.attachmentTypes = attachmentTypes
  },
  [SET_SELECTED_VALUES](state, { selectedValues }) {
    state.selectedValues = selectedValues
  },
  [SET_PERSON_ID](state, { personId }) {
    state.personId = personId
  },
}
