<script>
export default {
  methods: {
    getValidationErrorMessageKey(err) {
      const sanitizedKey = err.key.replace(/ /g, '_').replace(/\./g, '')
      const fullKey = `validationErrors.${sanitizedKey}`
      return this.$te(fullKey) ? fullKey : 'validationErrors.defaultMessage'
    },
    getServerValidationError(err) {
      return {
        field: err.field,
        msg: this.$t(this.getValidationErrorMessageKey(err)),
        rule: 'server',
      }
    },
  },
}
</script>
