import Vue from 'vue'

/* eslint-disable no-param-reassign */
const PubSubPlugin = {
  install() {
    const Bus = new Vue()

    Vue.prototype.$publish = function publish(eventName, payload) {
      Bus.$emit(eventName, payload)
    }

    Vue.prototype.$subscribe = function subscribe(eventName, func) {
      Bus.$on(eventName, func)
    }

    Vue.prototype.$unsubscribe = function unsubscribe(eventName, func) {
      Bus.$off(eventName, func)
    }

    Vue.prototype.$subscribeOnce = function subscribeOnce(eventName, callback) {
      // removes all listeners from this eventName!!!
      Vue.prototype.$subscribe(eventName, (payload) => {
        callback(payload)
        Bus.$off(eventName)
      })
    }
  },
}

Vue.use(PubSubPlugin)
