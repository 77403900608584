<template>
  <div>
    <h5 class="mb-4">
      {{ formatCoInsuredHeadline(contractData) }}
    </h5>
    <div class="row mb-1">
      <div
        v-for="(insuredPerson, index) in contractData.insuredPersons"
        :key="index"
        class="col-12 col-md-6"
      >
        <dx-delete-item
          :headline="`${insuredPerson.firstName} ${insuredPerson.lastName}`"
          :on-confirm-delete="
            () => deleteInsuredPerson(contractData, insuredPerson.megadexId)
          "
          :loading="loadingId === insuredPerson.id"
          class="mb-3"
        >
          <template slot="list-item">
            <li class="break-word">
              {{ insuredPerson.email }}
            </li>
          </template>
        </dx-delete-item>
      </div>
    </div>
  </div>
</template>

<script>
import DxDeleteItem from '~/components/DX-DeleteItem'
import deleteInsuredPersonMutation from '~/domains/contract/__gql__/mutations/deleteInsuredPersonMutation.gql'

export default {
  name: 'DxInsuredPerson',
  components: {
    DxDeleteItem,
  },
  props: {
    contractData: {
      type: Object,
      default: () => ({
        insuredPersons: [],
      }),
    },
    onSuccess: {
      type: Function,
      default() {
        return null
      },
    },
    onError: {
      type: Function,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      loadingId: null,
    }
  },
  methods: {
    async deleteInsuredPerson(currentContract, coInsuredPersonId) {
      const variables = {
        contract: {
          insuranceCompany: currentContract.insuranceCompany,
          id: currentContract.id,
        },
        insuredPersonId: coInsuredPersonId,
      }
      this.loadingId = coInsuredPersonId
      try {
        await this.$apollo.mutate({
          mutation: deleteInsuredPersonMutation,
          variables,
        })

        this.$notification.success({ messageKey: 'deleteInsuredPersonSuccess' })
        this.onSuccess()
      } catch (error) {
        this.$notification.error({ messageKey: 'deleteInsuredPersonError' })
        this.onError()
      } finally {
        this.loadingId = null
      }
    },
    formatCoInsuredHeadline(contract) {
      const translationKey =
        contract.insuredPersons.length > 1
          ? 'coInsuredPersons'
          : 'coInsuredPerson'
      return this.$t(`policies.${translationKey}`)
    },
  },
}
</script>
