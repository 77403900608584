import { getCurrentInstance, computed } from '@vue/composition-api'

export function useScipTranslationWithFallback(args) {
  const { formContext, item } = args
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error('useScipTranslationWithFallback must be called in setup')
  }

  const modulaOneProduct = ['private', 'business'].includes(
    formContext?.value?.schema?.product.productKey,
  )

  function t(key, values) {
    // FormContext uses the modula-backoffice translations
    if (formContext?.value?.te(key)) {
      return formContext.value.t(key, values)
    }
    // Instance uses $i18n which includes the CMS-translations
    if (modulaOneProduct && instance.$te(key)) {
      return instance.$t(key, values)
    }

    return `???${key}???`
  }

  function te(key) {
    return (
      formContext?.value?.te(key) || (modulaOneProduct && instance.$te(key))
    )
  }

  function tc(key, choice, values) {
    if (modulaOneProduct && instance.$te(key)) {
      return instance.$tc(key, choice, values)
    }

    if (formContext?.value?.te(key)) {
      return formContext.value.tc(key, choice, values)
    }

    return `???${key}???`
  }

  const translationKeyPrefix = computed(() => {
    if (item?.value?.source) {
      if (item.value.source.kind === 'attribute') {
        return `attributes.${item.value.source.moduleKey}.${item.value.source.attributeKey}`
      }

      return `${item.value.source.moduleKey}.${item.value.source.attributeKey}`
    }

    return ''
  })

  const translatedLabel = computed(() => {
    const key =
      item?.value?.__typename === 'SummaryItem'
        ? `summary.${item.value.id}`
        : `${translationKeyPrefix.value}.label`

    if (modulaOneProduct && instance.$te(key)) {
      return instance.$t(key)
    }

    if (item?.value) {
      return item.value.label
    }

    return ''
  })

  const translatedDescription = computed(() => {
    const key = `${translationKeyPrefix.value}.description`

    if (modulaOneProduct && instance.$te(key)) {
      return instance.$t(key)
    }

    if (item?.value) {
      return item.value.description
    }

    return ''
  })

  const mapOptions = () =>
    (item?.value?.options || []).map((option) => {
      const name = te(`${translationKeyPrefix.value}.options.${option.value}`)
        ? t(`${translationKeyPrefix.value}.options.${option.value}`)
        : option.name

      // DxSelect uses name, DxRadioButtons uses label
      return {
        value: option.value,
        name,
        label: name,
      }
    })

  return {
    t,
    te,
    tc,
    translationKeyPrefix,
    translatedLabel,
    translatedDescription,
    mapOptions,
  }
}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export function richTextToContentBlocks(richText, itemId) {
  let sequence = 0

  const result = []

  const createTeaserContext = (headline) => {
    sequence += 1
    return {
      __typename: 'TeaserContent',
      id: `${itemId}-${sequence}`,
      headline,
    }
  }

  const createProductDetail = (headline, subline) => {
    sequence += 1
    return {
      __typename: 'ProductDetail',
      id: `${itemId}-${sequence}`,
      headline,
      subline,
    }
  }

  const regex = /<(h2|h3|p)>(.*?)<\/(h2|h3|p)>/g
  let match
  let h3 = ''
  let p = ''
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(richText)) !== null) {
    // eslint-disable-next-line no-unused-vars
    const [_matchString, open, text] = match
    // eslint-disable-next-line default-case
    switch (open) {
      case 'h2':
        result.push(createTeaserContext(text))
        break

      case 'h3':
        if (h3) {
          result.push(createProductDetail(h3, p))
        }
        h3 = text
        p = ''
        break

      case 'p':
        p = `${p} ${text}`.trim()
        break
    }
  }

  if (h3) {
    result.push(createProductDetail(h3, p))
  }

  return result
}

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  )
}
