<template>
  <div
    :class="{
      'item1': !$slots['shadow-page-head-content2'],
      'item2': $slots['shadow-page-head-content2'],
      'theme-small': theme === 'small',
    }"
    class="shadow-page-head p-sm-0 p-md-3"
  >
    <div class="item">
      <component :is="checkTheme()" class="title">
        <span :class="iconClass" class="icon" />
        {{ title }}
      </component>
    </div>
    <div v-if="$slots['shadow-page-head-content']" class="item">
      <slot name="shadow-page-head-content" />
    </div>
    <div v-if="$slots['shadow-page-head-content2']" class="item">
      <slot name="shadow-page-head-content2" />
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'DxShadowPageHead',
  components: {
    DxButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headlineSize: 'h3',
    }
  },
  methods: {
    checkTheme() {
      if (this.theme === 'small') {
        this.headlineSize = 'h4'
      }
      return this.headlineSize
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
