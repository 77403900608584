<template>
  <div class="container-fluid">
    <div class="mt-3 my-md-4">
      <dx-back-text-button
        :text="$t('damageBacklog.overview')"
        :route="localePath('LawyerPortal')"
      />
    </div>

    <dx-unread-comments :source="$route.query.source" />
  </div>
</template>

<script>
import { DxBackTextButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxUnreadComments from '~/domains/externalLawyer/components/DX-UnreadComments'

export default {
  name: 'Mailbox',
  components: {
    DxUnreadComments,
    DxBackTextButton,
  },
  nuxtI18n: {
    paths: {
      de: '/anwaltsportal/postfach',
      fr: '/portail-avocat/boite-aux-lettres',
      it: '/portale-procuratori/scatola-po',
    },
  },
}
</script>
