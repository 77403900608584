<template>
  <div class="notifications">
    <dx-single-notification
      v-for="notification in notifications"
      :id="notification.id"
      :key="notification.id"
      :type="notification.type"
      :on-close="closeNotification"
    >
      <span slot="headline">
        {{ notification.headline }}
      </span>
      <div
        slot="message"
        class="text-center py-2"
        v-html="notification.message"
      />
    </dx-single-notification>
  </div>
</template>
<script>
import Events from '~/plugins/notification/events'
import DxSingleNotification from './SingleNotification'

// Use this.$notification with type, headline and message to add a new item to the container
export default {
  name: 'DxNotifications',
  components: {
    DxSingleNotification,
  },
  data() {
    return {
      index: 0,
      notifications: [],
    }
  },
  mounted() {
    Events.$on('notification', this.addNotification)
  },
  destroyed() {
    Events.$off('notification', this.addNotification)
  },
  methods: {
    addNotification(notification) {
      const index = this.index + 1

      const timer = notification.timer
        ? setTimeout(() => {
            this.closeNotification(index)
          }, notification.timer)
        : null
      const headline =
        notification.headlineKey && this.$t(notification.headlineKey)
      const message =
        notification.messageKey && this.$t(notification.messageKey)

      const newNotification = {
        id: index,
        type: notification.type,
        headline,
        message,
        timer,
      }
      this.index += 1
      this.notifications.push(newNotification)
    },
    closeNotification(notificationId) {
      const filteredNotifications = this.notifications.filter(
        (n) => n.id !== notificationId,
      )
      this.notifications = filteredNotifications
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
