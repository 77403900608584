<template>
  <table>
    <thead class="table-header">
      <tr>
        <th v-for="i in headerColumns.length" :key="headerColumns[i]">
          {{ headerColumns[i - 1] }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(dataRow, index) in dataRows" :key="dataRow[index]">
        <td
          v-for="i in columns"
          :key="dataRow[i]"
          :data-cat="headerColumns[i - 1]"
          class="terse"
        >
          {{ dataRow[i - 1] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DxTable',
  props: {
    dataRows: {
      type: Array,
      default() {
        return []
      },
    },
    headerColumns: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      columns: [],
    }
  },
  mounted() {
    this.columns = this.headerColumns.length
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
