<template>
  <div class="container">
    <div class="mt-3 my-md-4">
      <dx-back-text-button
        :text="$t('damageBacklog.overview')"
        :route="localePath('PartnerPortal')"
      />
    </div>
    <div class="content-wrap-medium content-pad">
      <h3 class="mb-5">
        {{ $t('userData.userDataHeading') }}
      </h3>
      <dx-user-data />
    </div>
  </div>
</template>

<script>
import { DxBackTextButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxUserData from '~/domains/partnerPortal/components/DX-UserData/'

export default {
  name: 'PartnerPortalUserData',
  components: {
    DxUserData,
    DxBackTextButton,
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/benutzerdaten',
      fr: '/portailpartenaires/donnees-utilisateur',
      it: '/portaledeipartner/informazioni-utente',
    },
  },
}
</script>
