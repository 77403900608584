<template>
  <div v-if="error" class="error-container">
    <alert-circle-icon class="spacing" />
    <span>{{ translatedError }}</span>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

import AlertCircleIcon from '@/components/icons/AlertCircleIcon'

import { useScipTranslationWithFallback } from '../../utils'

export default {
  name: 'DxGlobalErrorMessageAdapter',
  components: {
    AlertCircleIcon,
  },

  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const translation = useScipTranslationWithFallback(toRefs(props))
    return {
      ...translation,
    }
  },

  computed: {
    translatedError() {
      if (!this.error) {
        return null
      }

      if (this.error.params && this.error.params.count) {
        return this.tc(`errors.${this.error.type}`, this.error.params.count, {
          ...this.error.params,
        })
      }
      return this.t(`errors.${this.error.type}`, { ...this.error.params })
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/colors';
@import '~@sumcumo/dextra-frontend-component-lib/dist/assets/styles/variables/typo';

.error-container {
  display: flex;
  margin-top: rem(8);
  align-items: center;
  color: $functional-clr-error-dk;
  font-family: $font-family-heavy;
  font-size: rem($font-size-small);
  line-height: rem($font-line-height-xtra-small);

  @include media-breakpoint-up(lg) {
    font-size: rem($font-size);
    line-height: rem($font-line-height-small);
  }
}

.spacing {
  margin-right: rem(8);
}
</style>
