<template>
  <div class="content-wrap-medium content-pad">
    <h3 class="mb-4">
      {{ getHeadline() }}
    </h3>

    <dx-loading-overlay v-if="loading" />
    <dx-mailbox
      v-else
      :mail-data="messages"
      :read-only="true"
      :on-unread-item-click="markAsRead"
      :build-item-link="buildItemLink"
    />
  </div>
</template>

<script>
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import externalLawyerInsuredPersonMessagesQuery from '~/domains/externalLawyer/__gql__/queries/commentsInsuredPerson.gql'
import externalLawyerDextraMessagesQuery from '~/domains/externalLawyer/__gql__/queries/commentsDextra.gql'
import markCommentAsRead from '~/domains/contract/claim/__gql__/mutations/markCommentAsRead.gql'

import DxMailbox from '~/partials/DX-Mailbox'

export default {
  name: 'DxUnreadComments',
  components: {
    DxMailbox,
    DxLoadingOverlay,
  },
  props: {
    source: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      messages: [],
    }
  },
  methods: {
    getHeadline() {
      return this.source === 'DEXTRA'
        ? this.$t('lawyerPortal.unreadMessagesFromDextra')
        : this.$t('lawyerPortal.unreadMessagesFromCustomer')
    },
    buildItemLink(item) {
      return {
        path: this.localePath('LawyerPortal-Damage'),
        hash: 'customerMails',
        query: {
          claimId: item.claim_id,
        },
      }
    },
    async markAsRead(comment) {
      await this.$apollo.mutate({
        mutation: markCommentAsRead,
        variables: {
          commentId: Number(comment.id),
          claimId: Number(comment.claim_id),
        },
      })
    },
  },
  apollo: {
    externalLawyerInsuredPersonMessages: {
      query: externalLawyerInsuredPersonMessagesQuery,
      skip() {
        return this.source === 'DEXTRA'
      },
      variables() {
        return {
          filter: 'UNREAD',
          withDetails: true,
          receivedOnly: true,
        }
      },
      result({ data }) {
        this.messages = data.externalLawyerInsuredPersonMessages
        this.loading = false
      },
      error(error) {
        this.loading = false
        throw error
      },
    },
    externalLawyerDextraMessages: {
      query: externalLawyerDextraMessagesQuery,
      skip() {
        return this.source !== 'DEXTRA'
      },
      variables() {
        return {
          filter: 'UNREAD',
          withDetails: true,
          receivedOnly: true,
        }
      },
      result({ data }) {
        this.messages = data.externalLawyerDextraMessages
        this.loading = false
      },
      error(error) {
        this.loading = false
        throw error
      },
    },
  },
}
</script>
