<template>
  <div>
    <dx-table
      :header-columns="headerColumnsClosures"
      :data-rows="formattedRequests"
      class="mb-md-3"
    />
    <div v-if="addClosure">
      <div class="content-bg-color mb-3 p-3 p-md-5">
        <h4 class="mb-4">
          {{ $t('damage.cost') }}
        </h4>
        <div class="row">
          <div class="col-12 col-md-6">
            <dx-select
              v-model="closure.expensesType"
              v-validate.disable="'required'"
              :error="errors.first('requiredSelection')"
              :label="$t('damage.closureType')"
              :options="getTypeOptions()"
              data-vv-name="requiredSelection"
              class="mb-4"
            />
            <dx-select
              v-model="closure.invoiceType"
              v-validate.disable="'required'"
              :error="errors.first('invoiceType')"
              :label="$t('damage.bookingType')"
              :options="getInvoiceTypeOptions()"
              data-vv-name="invoiceType"
              class="mb-4"
            />
            <dx-input
              v-model="closure.amount.value"
              v-validate.disable="'required|decimal:2|min_value:1'"
              :error="errors.first('amount')"
              :label="$t('damage.closureAmount')"
              data-vv-name="amount"
              class="mb-4"
            />
            <dx-select
              v-if="showOutcomeSelection"
              v-model="closure.outcome"
              v-validate.disable="'required'"
              :error="errors.first('outcome')"
              :label="$t('damage.closureTypeOfCompletion')"
              :options="getOutcomeOptions()"
              data-vv-name="outcome"
              class="mb-4"
            />
          </div>
          <div class="col-12 col-md-6 mb-4">
            <dx-input
              v-validate.disable="'required'"
              :error="errors.first('accountHolder')"
              :label="$t('accountOwner')"
              :disabled="true"
              :value="closure.bankAccount.accountHolder"
              data-vv-name="accountHolder"
              class="mb-4"
            />
            <dx-input
              v-validate.disable="'required|validate_IBAN'"
              :error="errors.first('ibanInput')"
              :label="$t('iban')"
              :disabled="true"
              :value="closure.bankAccount.iban"
              data-vv-name="ibanInput"
              class="mb-4"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <dx-textarea
              v-model="closure.comment"
              v-validate.disable="'required'"
              :error="errors.first('missingMessageBody')"
              :active-autofocus="true"
              :label="$t('damageCheck.comments')"
              data-vv-name="missingMessageBody"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <dx-upload
              ref="upload"
              v-validate.disable="'required'"
              :error="errors.first('missingAttachments')"
              :label="$t('lawyerPortal.documentType')"
              :on-upload-success="(file) => onUploadSuccess(file)"
              data-vv-name="missingAttachments"
              data-vv-value-path="files"
              :remove-spacing="true"
              :remove-cancel-button="true"
              :show-upload-button="false"
            />
          </div>
        </div>
      </div>
      <div class="row py-3 pb-md-0 pt-md-1">
        <div class="col text-center text-md-right pt-2">
          <button
            class="btn-blank d-inline-block secondary-link mb-3 mr-md-4 mb-md-0"
            @click="openClosure"
          >
            {{ $t('abort') }}
          </button>
          <dx-button
            :small="true"
            :text="
              closure.invoiceType === 'FINAL'
                ? $t('damage.closuresSuperAction')
                : $t('damage.closureSave')
            "
            class="d-md-inline-block"
            type="submit"
            @click="save"
          />
        </div>
      </div>
    </div>
    <button
      v-if="!addClosure && !readOnly"
      type="button"
      class="show-more-link plus-icon btn-blank mb-4"
      @click="openClosure"
    >
      {{ $t('damage.closuresAction') }}
    </button>
  </div>
</template>

<script>
import {
  DxButton,
  DxSelect,
  DxInput,
  DxTextarea,
} from '@sumcumo/dextra-frontend-component-lib-old'
import DxTable from '~/components/DX-Table'
import DxUpload from '~/components/DX-Upload'

import dateMixin from '~/javascripts/mixins/dateMixin'

import {
  GM_EXPENSE_TYPE,
  EXPENSE_TYPE,
  EXPENSE_INVOICE_TYPE,
  EXPENSE_OUTCOME_TYPE,
} from '~/domains/externalLawyer/constants'

export default {
  name: 'DxLawyerClaimClosures',
  components: {
    DxButton,
    DxTable,
    DxSelect,
    DxInput,
    DxTextarea,
    DxUpload,
  },
  mixins: [dateMixin],
  props: {
    isGm: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    expensesInquiries: {
      type: Array,
      default: () => [],
    },
    rateLimit: {
      type: Number,
      default: 0,
    },
    saveClosure: {
      type: Function,
      default: () => null,
    },
    allowOnlyFinalClosure: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { bankAccount } = this.$store.state.application.currentLawyer
    return {
      addClosure: false,
      closure: {
        amount: {
          value: 0,
          currency: 'CHF',
        },
        expensesType: null,
        invoiceType: this.allowOnlyFinalClosure ? 'FINAL' : null,
        outcome: null,
        bankAccount: {
          iban: bankAccount ? bankAccount.iban : null,
          accountHolder: bankAccount ? bankAccount.accountHolder : null,
        },
        uploadIds: [],
      },
      headerColumnsClosures: [
        this.$t('damage.closureNumber'),
        this.$t('damage.status.label'),
        this.$t('damage.bookingType'),
        this.$t('damage.closureAmount'),
        this.$t('damage.closureDueDate'),
        this.$t('damage.reportTime'),
        this.$t('damage.closureType'),
      ],
    }
  },
  computed: {
    formattedRequests() {
      return this.expensesInquiries.map((expense) => [
        expense.id,
        this.$t(`lawyerPortal.expenseInquiryStatus.${expense.status}`),
        this.invoiceType(expense.invoiceType),
        `${expense.amount.value} CHF`,
        this.getFormattedDate(expense.valuta, true),
        this.getFormattedDate(expense.createdAt, true),
        this.$t(`lawyerPortal.expenseType.${expense.expensesType}`),
      ])
    },
    currentSum() {
      return Number.parseFloat(
        this.expensesInquiries
          .filter((i) => i.status !== 'REJECTED' && this.isFee(i.expensesType))
          .reduce((sum, x) => sum + x.amount.value, 0),
      )
    },

    showOutcomeSelection() {
      return this.closure.invoiceType === 'FINAL'
    },
  },
  methods: {
    isFee(expensesType) {
      return expensesType === 'FEE' || expensesType === 'GM_FEE'
    },
    invoiceType(type) {
      if (!type) {
        return ''
      }
      return this.$t(`lawyerPortal.expenseInvoiceType.${type}`)
    },
    openClosure(clearForm = false) {
      const { accountHolder, iban } = this.closure.bankAccount
      if (!accountHolder || !iban) {
        this.$notification.error({
          messageKey: 'lawyerPortal.bankAccountRequiredForClosure',
        })
        this.$router.push({
          path: this.localePath('LawyerPortal-UserData'),
          query: { edit: 'bankAccount' },
        })
        return
      }
      this.addClosure = !this.addClosure

      if (clearForm) {
        const { closure } = this.$options.data.apply(this)

        this.closure = {
          ...closure,
          comment: '',
        }
      }
    },
    getTypeOptions() {
      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
      }
      const formattedOptions = this.formattedExpenseTypes(this.isGm)
      return [defaultOption, ...formattedOptions]
    },
    formattedExpenseTypes(isGM = false) {
      if (isGM) {
        return GM_EXPENSE_TYPE.map((type) => ({
          value: type,
          label: this.$t(`lawyerPortal.expenseType.${type}`),
        }))
      }

      return EXPENSE_TYPE.map((type) => ({
        value: type,
        label: this.$t(`lawyerPortal.expenseType.${type}`),
      }))
    },
    getInvoiceTypeOptions() {
      if (this.allowOnlyFinalClosure) {
        return [
          {
            value: 'FINAL',
            label: this.$t('lawyerPortal.expenseInvoiceType.FINAL'),
          },
        ]
      }

      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
      }
      const formattedOptions = EXPENSE_INVOICE_TYPE.map((type) => ({
        value: type,
        label: this.$t(`lawyerPortal.expenseInvoiceType.${type}`),
      }))
      return [defaultOption, ...formattedOptions]
    },

    getOutcomeOptions() {
      const defaultOption = {
        value: null,
        label: this.$t('selectOption'),
      }
      const formattedOptions = EXPENSE_OUTCOME_TYPE.map((type) => ({
        value: type,
        label: this.$t(`lawyerPortal.expenseOutcomeType.${type}`),
      }))
      return [defaultOption, ...formattedOptions]
    },

    onUploadSuccess({ id }) {
      this.closure.uploadIds = [id, ...this.closure.uploadIds]
    },
    rateLimitBreached(newAmount) {
      return this.rateLimit > 0
        ? this.currentSum + Number.parseFloat(newAmount) > this.rateLimit
        : false
    },
    async save() {
      await this.$validator.reset()
      const valid = await this.$validator.validateAll()
      if (valid) {
        if (
          this.isFee(this.closure.expensesType) &&
          this.rateLimitBreached(this.closure.amount.value)
        ) {
          this.$notification.error({
            messageKey: 'lawyerPortal.paymentLimitBreached',
          })
          return
        }
        const uploaded = await this.$refs.upload.uploadFiles()
        if (uploaded) {
          // create copy and remove bank account data (not needed in mutation)
          const payload = { ...this.closure }
          delete payload.bankAccount
          if (!this.showOutcomeSelection) {
            payload.outcome = null
          }

          const result = await this.saveClosure(payload)
          if (result) {
            this.openClosure(true)
          }
        }
      }
    },
  },
}
</script>
