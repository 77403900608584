<template>
  <div>
    <h1 class="mb-5 text-center">{{ $t('partnerPortal.calculateOffer') }}</h1>
    <div class="tabs">
      <dx-tabs
        v-if="!forceProduct"
        :tabs-data="filteredTabsFromTariffCategories"
        :value="clientType"
        @input="switchTariffs"
      />
    </div>
    <dx-tariffs
      v-if="usedTariffs.length > 0"
      :tariffs="usedTariffs"
      theme="partner"
      class="tariffFontCorrection"
    />
    <div v-else class="d-lg-flex mt-5 p-3">
      <h1>These tariffs are coming soon!</h1>
    </div>
    <div
      v-if="clientType === 'special'"
      class="d-lg-flex mt-5 p-3 special-tariff"
    >
      <h3
        class="mr-3 h4 icon-left"
        v-html="$t('partnerPortal.tariff.special.headline')"
      />
      <div class="px-2 py-3 px-lg-5 py-lg-4 content-right">
        {{ $t('partnerPortal.tariff.special.text') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  Tariff as DxTariffs,
  DxTabs,
} from '@sumcumo/dextra-frontend-component-lib'
import { mapGetters } from 'vuex'

import tariffQuery from '~/domains/partnerPortal/__gql__/queries/tariffs.gql'

import createMarkdownToTemplate from '~/javascripts/helper/markdownToTemplate'

const markdownToTemplate = createMarkdownToTemplate()

export default {
  name: 'Products',
  components: {
    DxTabs,
    DxTariffs,
  },
  props: {
    isIframe: {
      type: Boolean,
      default: false,
    },
    forceProduct: {
      type: String,
      default: null,
    },
    productGeneration: {
      type: String,
      default: 'modula2',
    },
  },
  data() {
    return {
      clientType: 'private',
      tariffs: [],
    }
  },
  computed: {
    privateTariffs() {
      return this.tariffs
        .filter((tariff) => this.isPrivateTariff(tariff))
        .sort((a, _b) => {
          if (a.slug === 'privateIntroTariffL') {
            return -1
          }
          if (a.slug === 'privateIntroTariffFlex') {
            return 1
          }
          return 0
        })
    },
    businessTariffs() {
      return this.tariffs.filter((tariff) => this.isBusinessTariff(tariff))
    },
    specialTariffs() {
      return this.tariffs.filter((tariff) => this.isSpecialTariff(tariff))
    },
    usedTariffs() {
      if (this.clientType === 'special' || this.forceProduct === 'special') {
        return this.specialTariffs
      }
      if (this.clientType === 'business' || this.forceProduct === 'business') {
        return this.businessTariffs
      }
      return this.privateTariffs
    },
    filteredTabsFromTariffCategories() {
      const categories = this.tariffs
        .map((tariff) => tariff.tariffCategory)
        .filter((x) => !!x)

      return [
        categories.includes('Privatkunden') && {
          value: 'private',
          text: this.$t('partnerPortal.privateClient'),
          id: 'private',
        },
        categories.includes('Firmenkunden') && {
          value: 'business',
          text: this.$t('partnerPortal.company'),
          id: 'business',
        },
        categories.includes('Spezialprodukte') && {
          value: 'special',
          text: this.$t('partnerPortal.special'),
          id: 'special',
        },
      ].filter(Boolean)
    },
    ...mapGetters(['GET_CURRENT_BROKER']),
  },
  methods: {
    isBusinessTariff(tariff) {
      return tariff.slug.startsWith('business')
    },
    isPrivateTariff(tariff) {
      return tariff.slug.startsWith('private')
    },
    isGemeindeTariff(tariff) {
      return tariff.slug.startsWith('brokerIntroTariffGemeinde')
    },
    isSpecialTariff(tariff) {
      return !this.isBusinessTariff(tariff) && !this.isPrivateTariff(tariff)
    },
    getTariffBySlug(slug) {
      return this.tariffs.find((tariff) => tariff.slug === slug)
    },
    tariffMapper(tariff) {
      return {
        ...tariff,
        benefitHeadline: tariff.tariffBenefitList?.headline,
        benefits: tariff.tariffBenefitList?.listEntries,
        benefitCopy: tariff.tariffBenefitCopy,
        additionalItemsHeadline: tariff.tariffAdditionalList?.headline,
        additionalItems: tariff.tariffAdditionalList?.listEntries,
        tariffExtras: tariff.tariffExtrasList?.listEntries.map((entry) => ({
          ...entry,
          headline: markdownToTemplate(entry.headline),
        })),
      }
    },
    switchTariffs(value) {
      if (this.clientType === value) {
        return
      }
      this.clientType = value
    },
  },
  apollo: {
    page: {
      client: 'cms',
      query: tariffQuery,
      fetchPolicy: 'network-only',
      variables() {
        return {
          locales: [this.$i18n.locale],
          brokerIdFilter: [this.GET_CURRENT_BROKER.id],
        }
      },
      update(data) {
        if (data?.page?.edges) {
          this.tariffs = data.page.edges.map((item) =>
            this.tariffMapper(item.node),
          )
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
