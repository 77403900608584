<template>
  <div class="w-100">
    <dx-head-blue
      :headline="$t('partnerPortal.myPortal')"
      :textblock="$t('partnerPortal.headlineText')"
      hide-text-on-mobile
      class="mb-4 mb-md-5"
    />

    <div class="content-bg-color">
      <div class="container-fluid content-pad">
        <section class="product-container">
          <header class="headline-wrap">
            <h1 class="h2 headline">
              {{ $t('offer.changeTariff') }}
            </h1>
          </header>

          <div class="flex-tariffs">
            <dx-tariff-list :client-type="clientType" :reset-offer="false" />
          </div>
        </section>

        <div
          v-if="clientType === 'company'"
          class="d-lg-flex mt-5 p-3 special-tariff"
        >
          <h3
            class="mr-3 h4 icon-left"
            v-html="$t('partnerPortal.tariff.special.headline')"
          />
          <div class="px-2 py-3 px-lg-5 py-lg-4 content-right">
            {{ $t('partnerPortal.tariff.special.text') }}
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid content-wrap-medium content-pad">
      <dx-download-center />
    </div>
  </div>
</template>

<script>
import DxHeadBlue from '~/components/DX-HeadBlue'
import DxTariffList from '~/domains/partnerPortal/components/DX-TariffList'
import DxDownloadCenter from '~/domains/partnerPortal/components/DX-DownloadCenter'

export default {
  name: 'BrokerOfferChangeTariff',
  meta: {
    offerStep: 2,
  },
  components: {
    DxHeadBlue,
    DxTariffList,
    DxDownloadCenter,
  },
  computed: {
    clientType() {
      const { activeTariff } = this.$store.state.brokerOffer
      return activeTariff[0] === 'p' ? 'private' : 'company'
    },
  },
  nuxtI18n: {
    paths: {
      de: '/partnerportal/rechtsschutz-antragsstrecke/tarif-aendern/',
      fr: '/protection-juridique-formulaire-de-demande/modifier-le-tarif/',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
