import Vue from 'vue'
import ConfigPlugin from '~/javascripts/helper/config-install'

const config = {
  fileTypes: {
    invoice: 'INVOICE',
    something: 'SOMETHING',
    proposal: 'PROPOSAL',
    claim: 'CLAIM',
    policy: 'POLICY',
    avb: 'AVB',
    claim_payment: 'CLAIM_PAYMENT',
    reminder: 'REMINDER',
    portal_info: 'PORTAL_INFO',
  },
  paymentInterval: {
    yearly: 'YEARLY',
    half_yearly: 'HALF_YEARLY',
    quarterly: 'QUARTERLY',
    monthly: 'MONTHLY',
  },
  roles: {
    dex_policyholder: 'DEX_POLICYHOLDER',
    dex_insured: 'DEX_INSURED_PERSON',
    gm_customer: 'GM',
  },
  salutations: {
    company: 'COMPANY',
    family: 'FAMILY',
    male: 'MALE',
    female: 'FEMALE',
  },
}

Vue.use(ConfigPlugin, { config })
export default config
