<template>
  <div class="row">
    <div class="col-12 col-md-6 mb-3 mb-md-0">
      <dx-loading-overlay v-if="customerLoading" />
      <dx-mail-notifications-block
        v-else
        :message-from="$t('mailFromCustomer')"
        :mail-amount="getUnreadInsuredPersonMessages()"
        :route-data="{ name: localePath('LawyerPortal-Mailbox') }"
        icon-type="customer"
      />
    </div>
    <div class="col-12 col-md-6">
      <dx-loading-overlay v-if="dextraLoading" />
      <dx-mail-notifications-block
        v-else
        :message-from="$t('messagesFromDextra')"
        :mail-amount="getUnreadDextraMessages()"
        :route-data="{
          name: localePath('LawyerPortal-Mailbox'),
          query: { source: 'DEXTRA' },
        }"
        icon-type="home"
      />
    </div>
  </div>
</template>

<script>
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import DxMailNotificationsBlock from '~/domains/externalLawyer/components/DX-MailNotificationsBlock'
import externalLawyerInsuredPersonMessagesQuery from '~/domains/externalLawyer/__gql__/queries/commentsInsuredPerson.gql'
import externalLawyerDextraMessagesQuery from '~/domains/externalLawyer/__gql__/queries/commentsDextra.gql'

export default {
  name: 'DxUnreadCommentsIndicator',
  components: {
    DxLoadingOverlay,
    DxMailNotificationsBlock,
  },
  data() {
    return {
      customerLoading: true,
      dextraLoading: true,
    }
  },
  methods: {
    getUnreadInsuredPersonMessages() {
      return Array.isArray(this.externalLawyerInsuredPersonMessages)
        ? this.externalLawyerInsuredPersonMessages.filter(
            (m) =>
              m.sender.id !== this.$store.state.application.currentLawyer.id,
          ).length
        : 0
    },
    getUnreadDextraMessages() {
      return Array.isArray(this.externalLawyerDextraMessages)
        ? this.externalLawyerDextraMessages.filter(
            (m) =>
              m.sender.id !== this.$store.state.application.currentLawyer.id,
          ).length
        : 0
    },
  },
  apollo: {
    externalLawyerInsuredPersonMessages: {
      query: externalLawyerInsuredPersonMessagesQuery,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          filter: 'UNREAD',
          withDetails: false,
          receivedOnly: true,
        }
      },
      result() {
        this.customerLoading = false
      },
      error(error) {
        this.customerLoading = false
        throw error
      },
    },
    externalLawyerDextraMessages: {
      query: externalLawyerDextraMessagesQuery,
      variables() {
        return {
          filter: 'UNREAD',
          withDetails: false,
          receivedOnly: true,
        }
      },
      result() {
        this.dextraLoading = false
      },
      error(error) {
        this.dextraLoading = false
        throw error
      },
    },
  },
}
</script>
