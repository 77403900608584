<template>
  <div :class="['bar-wrap', { isSticky: isSticky }]">
    <div class="bar py-3">
      <div class="col" @click="$emit('back')">
        <nuxt-link
          v-if="showPreviousButton"
          :to="{ path: previous, query: routeQuery }"
          :class="{ 'd-inline': priceTag }"
          class="backlink"
        />
        <div v-if="priceTag" class="price-tag">
          CHF
          <span class="price-tag-big">
            {{ priceTag }}
          </span>
        </div>
      </div>
      <div :class="['col text-right', { 'pr-0': !isSticky }]">
        <dx-button
          :text="$t('continue')"
          :route-name="next"
          :route-query="routeQuery"
          button-class="cmp-btn--forward"
          @click="$emit('forward')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'

export default {
  name: 'DxBottomBar',
  components: {
    DxButton,
  },
  props: {
    next: {
      type: String,
      default: null,
    },
    previous: {
      type: String,
      default: null,
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
    priceTag: {
      type: String,
      default: '',
    },
    routeQuery: {
      type: Object,
      default: () => null,
    },
    isSticky: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>

<styleguide-states>
[{
  title: "Default",
  data: {
    on: {
      back(){console.log('Back Button of the Bottom Bar has been pressed.')},
      forward(){console.log('Forward Button of the Bottom Bar has been pressed.')}
    }
  }
}]
</styleguide-states>
