const generateTrackingData = (metaData, locale, tariffType) => {
  // Create object from array starting with index 1
  const contentGroupList = [undefined, 'service', locale].concat(
    metaData.trackingGroup || [
      'rechtsschutz',
      tariffType === 'private'
        ? 'privatrechtsschutz'
        : 'unternehmensrechtsschutz',
    ],
  )
  const contentGroup = { ...contentGroupList }
  delete contentGroup[0]
  return {
    contentId: metaData.trackingId,
    contentGroup,
  }
}

const trackData = (data) => {
  if (typeof window !== 'undefined') {
    window.wts = window.wts || []
    window.wts.push(['send', 'page', data])
  }
}

export const trackPageRoute = ({ metaData, locale, tariffType }) => {
  try {
    // Hack to ensure route was already changed until https://github.com/vuejs/vue-router/issues/2079 is fixed
    setTimeout(() => {
      const trackingData = generateTrackingData(metaData, locale, tariffType)
      trackData(trackingData)
    }, 0)
  } catch (error) {
    console.error(error)
  }
}

export const trackConversion = ({ price, locale, tariff }) => {
  try {
    trackData({
      contentId: 'dextra.rechtsschutz-antragsstrecke.zusammenfassung',
      contentGroup: {
        1: 'service',
        2: locale,
        3: 'rechtsschutz',
        4:
          tariff[0] === 'p' ? 'privatrechtsschutz' : 'unternehmensrechtsschutz',
      },
      product: encodeURIComponent(tariff.toUpperCase()),
      productQuantity: '1',
      productCost: price,
      productStatus: 'conf',
      orderValue: price,
      orderId: Date.now(),
    })
  } catch (error) {
    console.error(error)
  }
}
