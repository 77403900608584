<template>
  <div class="container-fluid text-center">
    <div class="content-wrap-x-small content-pad">
      <div class="check-wrap" />
      <h2 class="mt-3 mb-4">
        {{ $t('thanks') }}
      </h2>
      <p>
        {{ $t('weWillTakeCare') }}
      </p>
      <p>
        {{
          isGmLimited || hasNoRole
            ? $t('damagereportFeedbackGM_LIMITED')
            : damageReportFeedback
        }}
      </p>
      <dx-button
        v-if="!isGmLimited && !hasNoRole"
        :text="$t('toSelfService.customer')"
        :route-name="localePath('SelfService')"
      />
    </div>
  </div>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import { mapGetters } from 'vuex'
import { RESET_STORE } from '~/domains/application/__store__/actions'

export default {
  name: 'ReportDamageThankYou',
  meta: {
    isPublic: true,
  },
  components: {
    DxButton,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/kundencenter/rechtsfall-melden/danke',
      fr: '/centre-clients/cas-juridique-rapport/merci',
      it: '/cliente/segnalare-caso-giuridico/grazie',
    },
  },
  computed: {
    ...mapGetters(['isGmLimited', 'hasNoRole']),
    damageReportFeedback() {
      return `${this.$t('damagereportFeedback.customer')} ${this.$t(
        'damagereportFeedback.both',
      )}`
    },
  },
  mounted() {
    if (this.isGmLimited) {
      this.$store.dispatch(RESET_STORE)
      this.$apolloProvider.defaultClient.resetStore()
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
