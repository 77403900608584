<template>
  <li>
    <ol>
      <li v-for="location in locations" :key="`${location.id}`">
        {{ label }}:
        <dl>
          <dd>{{ location.street }} {{ location.houseNumber }}</dd>
          <dd>{{ location.zip }} {{ location.city }}</dd>
          <dd>{{ translation[location.country] }}</dd>
        </dl>
      </li>
    </ol>
  </li>
</template>
<script>
export default {
  name: 'LocationsFieldEntry',
  props: {
    label: {
      type: String,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    translation: {
      type: Object,
      required: true,
    },
  },
}
</script>
