<template>
  <dx-counterparty-form
    next-step-path="SelfService-ReportDamage-ThankYou"
    previous-step-path="SelfService-ReportDamage-Deadline"
    create-claim-action="createLegalClaim"
  />
</template>

<script>
import DxCounterpartyForm from '~/components/reportDamage/DX-CounterpartyForm'

export default {
  name: 'ReportDamageCounterparty',
  components: {
    DxCounterpartyForm,
  },
  layout: 'non-sticky-nav',
  nuxtI18n: {
    paths: {
      de: '/kundencenter/rechtsfall-melden/gegenpartei',
      fr: '/centre-clients/cas-juridique-rapport/contrepartie',
      it: '/cliente/segnalare-caso-giuridico/controparte',
    },
  },
}
</script>
