<template>
  <dx-icon-box :icon-type="iconType" :route-data="routeData">
    <span
      v-if="hasMails()"
      class="mail-amount text-center d-flex align-items-center justify-content-center"
    >
      {{ mailAmount }}
    </span>
    <h4 class="mt-2 mt-md-4">
      {{ messageFrom }}
    </h4>
    <p v-if="hasMails()">
      {{ $t('youGotMail') }}
    </p>
    <p v-else>
      {{ $t('noUnreadMails') }}
    </p>
    <dx-button
      :route-name="routeData.name"
      :route-query="routeData.query"
      :hash-name="routeData.hash"
      :small="true"
      :text="$t('toMessages')"
      class="d-md-inline-block my-md-4"
    />
  </dx-icon-box>
</template>

<script>
import { DxButton } from '@sumcumo/dextra-frontend-component-lib-old'
import DxIconBox from '~/components/DX-IconBox'

export default {
  name: 'MailNotifications',
  components: {
    DxIconBox,
    DxButton,
  },
  props: {
    mailAmount: {
      type: Number,
      default: 0,
    },
    iconType: {
      type: String,
      default: '',
    },
    routeData: {
      type: Object,
      default: () => {},
    },
    messageFrom: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasMails() {
      return this.mailAmount > 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
