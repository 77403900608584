import claim from '~/domains/contract/claim/__store__'
import authentication from '~/domains/authentication/__store__'
import application from '~/domains/application/__store__'
import brokerOffer from '~/domains/brokerOffer/__store__'
import partnerPortal from '~/domains/partnerPortal/__store__'

export default {
  claim,
  authentication,
  application,
  brokerOffer,
  partnerPortal,
}
