<script>
import { RESET_PENDING_UPLOADS } from '~/domains/application/__store__/mutations'

export default {
  beforeRouteLeave(to, from, next) {
    if (!this.$store.state.application.pendingUploads) {
      next()
    } else {
      this.showOverlay = true
      this.routeCallback = next
      this.$refs.overlay.open()
    }
  },
  data() {
    return {
      routeCallback: () => null,
      showOverlay: false,
    }
  },
  mounted() {
    window.onpopstate = this.preventNativeBack
  },
  methods: {
    leaveAnyways() {
      this.$store.commit(RESET_PENDING_UPLOADS)
      return this.routeCallback()
    },
    preventNativeBack() {
      if (this.showOverlay) {
        window.history.forward()
      }
    },
  },
}
</script>
