var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-block mb-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('damage.data'))+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('ul',{staticClass:"col-12 col-md-6 mb-0"},[_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damage.numberShort'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.number)+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damage.status.label'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t(("damage.status." + (_vm.claim.status.toLowerCase()))))+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damageCheck.instance'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.targetInstance
              ? _vm.$t(("lawyerPortal.targetInstance." + (_vm.claim.targetInstance)))
              : _vm.$t('noContent'))+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('hourly'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.hourlyRate && _vm.claim.hourlyRate.value)+" CHF\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damageCheck.rateLimit'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.paymentLimit
              ? ((_vm.claim.paymentLimit.value) + " CHF")
              : _vm.$t('noContent'))+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damage.reimbursement'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.targetInstance
              ? _vm.$t('lawyerPortal.committedFor', {
                  instance: _vm.$t(
                    ("lawyerPortal.targetInstance." + (_vm.claim.targetInstance))
                  ),
                })
              : _vm.$t('noContent'))+"\n        ")])])]),_vm._v(" "),_c('ul',{staticClass:"col-12 col-md-6 mb-0"},[_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('lawyerPortal.jurisdiction'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.areaOfLaw &&
            _vm.$t(("areaOfLaw." + (_vm.claim.areaOfLaw.toUpperCase()))))+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damageCheck.deadline'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.deadline
              ? _vm.getFormattedDate(_vm.claim.deadline)
              : _vm.$t('noContent'))+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damage.date'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.getFormattedDate(_vm.claim.eventDate))+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damage.reportTime'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.createdAt
              ? _vm.getFormattedDate(_vm.claim.createdAt)
              : _vm.$t('noContent'))+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damage.legalAdvisor'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.lawyer && _vm.claim.lawyer.fullName)+"\n        ")])]),_vm._v(" "),_c('li',{staticClass:"row mb-1"},[_c('h5',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.$t('damage.legalAccess'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n          "+_vm._s(_vm.claim.targetInstanceRequests.some(function (i) { return i.status === 'OPEN'; })
              ? _vm.$t('yes')
              : _vm.$t('no'))+"\n        ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }