<template>
  <dx-shadow-page-head
    :title="$t(title)"
    theme="small"
    icon-class="warning"
    class="p-3 p-sm-3"
  >
    <template slot="shadow-page-head-content">
      <ul class="neutral pt-md-3">
        <li
          v-for="notification in notifications"
          :key="notification.sub"
          class="mb-3"
        >
          <div class="row">
            <span class="col-12" v-html="notification.headline" />
            <nuxt-link
              v-if="showDetailLink"
              :to="localePath('SelfService')"
              class="col-5 col-md-3 text-right secondary-link pr-md-5"
            >
              {{ $t('lookAt') }}
            </nuxt-link>
          </div>
        </li>
      </ul>
    </template>
  </dx-shadow-page-head>
</template>

<script>
import DxShadowPageHead from '~/components/DX-ShadowPageHead'

export default {
  name: 'DxNotifications',
  components: {
    DxShadowPageHead,
  },
  props: {
    notifications: {
      type: Array,
      default() {
        return []
      },
    },
    showDetailLink: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'notification',
    },
  },
}
</script>
