<template>
  <div>
    <div class="row my-4 text-right">
      <div class="col-12">
        <button
          type="button"
          class="btn-blank secondary-link pr-0"
          @click="!showNewMessageDialog && toggleShowNewMessageDialog()"
        >
          {{ $t('newMessage') }}
        </button>
      </div>
    </div>

    <dx-loading-overlay v-if="sendInProgress" />
    <div v-if="showNewMessageDialog && !sendInProgress" class="row my-5">
      <div class="col-12">
        <dx-textarea
          v-model="newMessageText"
          v-validate.disable="'required'"
          :error="errors.first('missingMessageBody')"
          :label="$t('myMessage')"
          data-vv-name="missingMessageBody"
        />
        <div class="text-right">
          <button
            class="btn-blank secondary-link mr-3 d-inline-block"
            @click="toggleShowNewMessageDialog"
          >
            {{ $t('abort') }}
          </button>
          <button
            class="btn-blank secondary-link pr-0 d-inline-block"
            @click="submit"
          >
            {{ $t('submit') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxTextarea } from '@sumcumo/dextra-frontend-component-lib-old'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'

export default {
  name: 'DxMailboxCompose',
  components: {
    DxTextarea,
    DxLoadingOverlay,
  },
  props: {
    sendNewMessage: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      showNewMessageDialog: false,
      sendInProgress: false,
      newMessageText: null,
    }
  },
  methods: {
    toggleShowNewMessageDialog() {
      this.showNewMessageDialog = !this.showNewMessageDialog
    },
    async submit() {
      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          this.sendInProgress = true
          await this.sendNewMessage(this.newMessageText)

          this.$notification.success({ messageKey: 'sendMessage.success' })
          this.newMessageText = null
          this.toggleShowNewMessageDialog()
        } catch (error) {
          this.$notification.error({ messageKey: 'sendMessage.error' })
          console.error(error)
        } finally {
          this.sendInProgress = false
        }
      }
    },
  },
}
</script>
