<template>
  <div class="container-fluid content-pad">
    <div class="row mb-3 mb-md-5">
      <div class="col-12">
        <h2 class="text-center">
          {{ $t('selectPortalHead') }}
        </h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        v-for="portal in availablePortals"
        :id="portal.id"
        :key="portal.name"
        class="col-12 col-md-4 mb-3"
      >
        <dx-icon-box
          animated
          :icon-type="portal.className"
          @click="enterPortal(portal.id)"
        >
          <h4 class="mt-2 mt-md-3 mb-0">
            {{ $t(portal.name) }}
          </h4>
          <span class="d-block text-right mt-3 mt-md-5 secondary-link">
            {{ $t('jumpToPortal') }}
          </span>
        </dx-icon-box>
      </div>
      <h2 v-if="!availablePortals.length">¯\_(ツ)_/¯</h2>
    </div>
  </div>
</template>
<script>
import DxIconBox from '~/components/DX-IconBox'
import loadingMixin from '~/javascripts/mixins/loadingMixin'
import changePortalMixin from '~/javascripts/mixins/changePortalMixin'

export default {
  name: 'SelectPortal',
  components: {
    DxIconBox,
  },
  mixins: [loadingMixin, changePortalMixin],
  meta: {
    isPublic: true,
  },
  nuxtI18n: {
    paths: {
      de: '/portal-auswaehlen',
      fr: '/portail-sélectionner',
      it: '/portale -seleziona',
    },
  },
  mounted() {
    // show error if somehow someone comes here without available portals
    if (!this.availablePortals.length) {
      this.$router.push({
        path: this.localePath('Login'),
        query: { logout: true, reason: 'loginError' },
      })
    }
  },
}
</script>

<style lang="scss">
@import 'index';
</style>
