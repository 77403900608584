var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.editable)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-10 mb-4 row"},[_c('p',{staticClass:"col-12 mb-2"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.questions.q1'
                : 'offer.questions.q1'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.displayBooleanValue(_vm.formData.recentExistingOrExpectedLawsuit))+"\n          "),(_vm.formData.recentExistingOrExpectedLawsuitAdditional)?_c('span',[_vm._v("\n            "+_vm._s(("- " + (_vm.formData.recentExistingOrExpectedLawsuitAdditional)))+"\n          ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 mb-4 row"},[_c('p',{staticClass:"col-12 mb-2"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.questions.q2'
                : 'offer.questions.q2'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.displayBooleanValue(_vm.formData.impairedAbilityToWork))+"\n          "),(_vm.formData.impairedAbilityToWorkAdditional)?_c('span',[_vm._v("\n            "+_vm._s(("- " + (_vm.formData.impairedAbilityToWorkAdditional)))+"\n          ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 mb-4 row"},[_c('p',{staticClass:"col-12 mb-2"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.questions.q3'
                : 'offer.questions.q3'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.displayBooleanValue(_vm.formData.contractTerminatedByInsuranceCompany))+"\n          "),(_vm.formData.contractTerminatedByInsuranceCompanyAdditional)?_c('span',[_vm._v("\n            "+_vm._s(("- " + (_vm.formData.contractTerminatedByInsuranceCompanyAdditional)))+"\n          ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-10 row"},[_c('p',{staticClass:"col-12 mb-2"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.isBrokerOffer
                ? 'brokerOffer.questions.individualRequests'
                : 'offer.questions.individualRequests'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"col-12 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.formData.individualAdditionalText || '-')+"\n        ")])])])]:_c('form',{on:{"change":_vm.change}},[_c('dx-radio-list',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
        required: _vm.isRequired,
      }),expression:"{\n        required: isRequired,\n      }",modifiers:{"disable":true}}],staticClass:"mt-5 mb-3",attrs:{"options":_vm.radioOptions,"error":_vm.errors.first('recentExistingOrExpectedLawsuit'),"label":_vm.$t(_vm.isBrokerOffer ? 'brokerOffer.questions.q1' : 'offer.questions.q1'),"name":"recentExistingOrExpectedLawsuit"},model:{value:(_vm.formData.recentExistingOrExpectedLawsuit),callback:function ($$v) {_vm.$set(_vm.formData, "recentExistingOrExpectedLawsuit", $$v)},expression:"formData.recentExistingOrExpectedLawsuit"}}),_vm._v(" "),(_vm.formData.recentExistingOrExpectedLawsuit)?_c('dx-textarea',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
        required: _vm.formData.recentExistingOrExpectedLawsuit,
      }),expression:"{\n        required: formData.recentExistingOrExpectedLawsuit,\n      }",modifiers:{"disable":true}}],staticClass:"mt-2",attrs:{"error":_vm.errors.first('recentExistingOrExpectedLawsuitAdditional'),"label":_vm.$t('offer.questions.q1Additional'),"data-vv-name":"recentExistingOrExpectedLawsuitAdditional"},model:{value:(_vm.formData.recentExistingOrExpectedLawsuitAdditional),callback:function ($$v) {_vm.$set(_vm.formData, "recentExistingOrExpectedLawsuitAdditional", $$v)},expression:"formData.recentExistingOrExpectedLawsuitAdditional"}}):_vm._e(),_vm._v(" "),_c('dx-radio-list',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
        required: _vm.isRequired,
      }),expression:"{\n        required: isRequired,\n      }",modifiers:{"disable":true}}],staticClass:"mt-5 mb-3",attrs:{"options":_vm.radioOptions,"error":_vm.errors.first('impairedAbilityToWork'),"label":_vm.$t(_vm.isBrokerOffer ? 'brokerOffer.questions.q2' : 'offer.questions.q2'),"name":"impairedAbilityToWork"},model:{value:(_vm.formData.impairedAbilityToWork),callback:function ($$v) {_vm.$set(_vm.formData, "impairedAbilityToWork", $$v)},expression:"formData.impairedAbilityToWork"}}),_vm._v(" "),(_vm.formData.impairedAbilityToWork)?_c('dx-textarea',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
        required: _vm.formData.impairedAbilityToWork,
      }),expression:"{\n        required: formData.impairedAbilityToWork,\n      }",modifiers:{"disable":true}}],staticClass:"mt-2",attrs:{"error":_vm.errors.first('impairedAbilityToWorkAdditional'),"label":_vm.$t('offer.questions.q2Additional'),"data-vv-name":"impairedAbilityToWorkAdditional"},model:{value:(_vm.formData.impairedAbilityToWorkAdditional),callback:function ($$v) {_vm.$set(_vm.formData, "impairedAbilityToWorkAdditional", $$v)},expression:"formData.impairedAbilityToWorkAdditional"}}):_vm._e(),_vm._v(" "),_c('dx-radio-list',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
        required: _vm.isRequired,
      }),expression:"{\n        required: isRequired,\n      }",modifiers:{"disable":true}}],staticClass:"mt-5 mb-3",attrs:{"options":_vm.radioOptions,"error":_vm.errors.first('contractTerminatedByInsuranceCompany'),"label":_vm.$t(_vm.isBrokerOffer ? 'brokerOffer.questions.q3' : 'offer.questions.q3'),"name":"contractTerminatedByInsuranceCompany"},model:{value:(_vm.formData.contractTerminatedByInsuranceCompany),callback:function ($$v) {_vm.$set(_vm.formData, "contractTerminatedByInsuranceCompany", $$v)},expression:"formData.contractTerminatedByInsuranceCompany"}}),_vm._v(" "),(_vm.formData.contractTerminatedByInsuranceCompany)?_c('dx-textarea',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
        required: _vm.formData.contractTerminatedByInsuranceCompany,
      }),expression:"{\n        required: formData.contractTerminatedByInsuranceCompany,\n      }",modifiers:{"disable":true}}],staticClass:"mt-2",attrs:{"error":_vm.errors.first('contractTerminatedByInsuranceCompanyAdditional'),"label":_vm.$t('offer.questions.q3Additional'),"data-vv-name":"contractTerminatedByInsuranceCompanyAdditional"},model:{value:(_vm.formData.contractTerminatedByInsuranceCompanyAdditional),callback:function ($$v) {_vm.$set(_vm.formData, "contractTerminatedByInsuranceCompanyAdditional", $$v)},expression:"formData.contractTerminatedByInsuranceCompanyAdditional"}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"mt-5 mb-2"},[_vm._v("\n      "+_vm._s(_vm.$t(
          _vm.isBrokerOffer
            ? 'brokerOffer.questions.individualRequests'
            : 'offer.questions.individualRequests'
        ))+"\n    ")]),_vm._v(" "),_c('button',{staticClass:"btn-blank plus-icon text-uppercase show-more-link",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleTextarea($event)}}},[_vm._v("\n      "+_vm._s(_vm.$t('offer.questions.individualRequestsBtn'))+"\n    ")]),_vm._v(" "),(_vm.showTextarea)?_c('dx-textarea',{staticClass:"mt-2",model:{value:(_vm.formData.individualAdditionalText),callback:function ($$v) {_vm.$set(_vm.formData, "individualAdditionalText", $$v)},expression:"formData.individualAdditionalText"}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }