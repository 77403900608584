<template>
  <dx-offline-registration-form />
</template>

<script>
import DxOfflineRegistrationForm from '~/domains/user/components/DX-OfflineRegistrationForm'

export default {
  name: 'OfflineRegistration',
  meta: {
    isPublic: true,
  },
  components: {
    DxOfflineRegistrationForm,
  },
  layout: 'modulaStylePlain',
  nuxtI18n: {
    paths: {
      de: '/offline-registrierung',
      fr: '/offline-inscription',
      if: '/offline-registrazione',
    },
  },
}
</script>
