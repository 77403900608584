import showdown from 'showdown'
import xss from 'xss'

export const htmlCharMap = {
  '<': '&lt;',
  '>': '&gt;',
  // convert mustaches to save characters
  // html-code must also be converted
  // see https://github.com/vuejs/vue/issues/4223 for reasons
  '{': '[',
  '}': ']',
  '&#123;': '[',
  '&#125;': ']',
}

// siehe https://github.com/leizongmin/js-xss für options
const sanitizeInput = (text) =>
  xss(text, {
    onIgnoreTagAttr(tag, name, value) {
      if (name === 'id' && /^h\d$/.test(tag)) {
        return `id="${value
          // allow only whitelisted chars
          .replace(/[^a-z0-9äöüÄÖÜß]/gi, '-')
          // remove double '-'
          .replace(/(-)+/g, '-')}"`
      }
      return undefined // default behaviour
    },
  })

export default () => {
  const showdownExtensions = {
    // encode html tag brackets to prevent direct insertion of html
    sanitizeHtml: {
      type: 'lang',
      regex: /<(.*?)>/g,
      replace: '&lt;$1&gt;',
    },
    sanitizeOutputXSS: {
      type: 'output',
      filter: sanitizeInput,
    },
    // There is no markdown syntax for the <mark> tag, so we allow it explicitly
    replaceMarkTags: {
      type: 'output',
      regex: /&lt;(\/)?mark&gt;/g,
      replace: '<$1mark>',
    },
    // Special syntax to insert <cite> tags easily. Jens Oliver Meiert approves.
    insertCiteTag: {
      type: 'output',
      regex: /†(.*?)†/g,
      replace: '<cite>$1</cite>',
    },
    sanitizeOutputVue: {
      type: 'output',
      regex: /([{}]|&#123;|&#125;)/g,
      replace: (char) => htmlCharMap[char],
    },
    // This catches any Vue widget and replaces the invalid div within a
    // paragraph with just a div container.
    replaceOpeningP: {
      type: 'output',
      regex: /<p([^>]*)><div/gi,
      replace: '<div$1',
    },
    // and this then closes the formerly invalid paragraph>div html
    replaceClosingP: {
      type: 'output',
      regex: /<\/div><\/p>/gi,
      replace: '</div>',
    },
    replaceOpeningUnorderedList: {
      type: 'output',
      regex: /<ul([^>]*)>/gi,
      replace: '<ul class="std"$1>',
    },
    replaceOpeningOrderedList: {
      type: 'output',
      regex: /<ol([^>]*)>/gi,
      replace: '<ol class="std"$1>',
    },
    replaceNewLine: {
      type: 'output',
      regex: /(\\n)/gi,
      replace: '<br />',
    },
  }

  // register extensions
  Object.keys(showdownExtensions).forEach((key) =>
    showdown.extension(key, showdownExtensions[key]),
  )

  // create converter
  const converter = new showdown.Converter({
    tables: true,
    strikethrough: true,
    // Parses line breaks as like GitHub does, without needing 2 spaces at the end of the line
    simpleLineBreaks: true,
    // allow custom ids like `# myHeader {realId}`
    customizedHeaderId: true,
    rawHeaderId: true,
    // eslint-disable-next-line compat/compat
    extensions: Object.values(showdownExtensions),
  })

  return (markdown) => converter.makeHtml(markdown)
}
