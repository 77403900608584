<template>
  <!-- Valid note types: success, error, info, and warning (same styling as info): -->
  <div
    :class="`notification--${type}`"
    :data-integrationtest="`notification-${type}`"
    class="notification"
  >
    <button
      :title="$t('close')"
      type="button"
      class="btn-blank"
      @click="onClose(id)"
    >
      &times;
    </button>
    <h1 class="text-center">
      <!-- headline -->
      <slot name="headline" />
    </h1>
    <!-- No predefined markup (like <p>) to allow for complex messages: -->
    <slot name="message" />
  </div>
</template>

<script>
export default {
  name: 'DxSingleNotification',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'info',
      validator(value) {
        return ['success', 'info', 'warning', 'error'].indexOf(value) !== -1
      },
    },
    onClose: {
      type: Function,
      default: () => null,
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
