import { createHttpLink } from 'apollo-link-http'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

import introspectionQueryResultData from '~/apollo/generated/types.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

export default ({ app }) => {
  const httpLink = createHttpLink({
    uri: `${process.env.GRAPHCMS_API_URL}`,
  })

  const authLink = setContext((_, { headers }) => {
    const link = {
      headers: {
        ...headers,
        'gcms-locales': `${app.i18n.locale}, de`,
      },
    }

    if (process.env.DEXTRA_ENV === 'stage') {
      link.headers.Authorization = `Bearer ${process.env.DEXTRA_GRAPHCMS_TOKEN}`
    }

    return link
  })

  return {
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher }),
    defaultHttpLink: false,
  }
}
