var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_c('notifications',{ref:"notifications"}),_vm._v(" "),_c('div',{staticClass:"scip-sales"},[(_vm.channel !== 'employee')?_c('nuxt-link',{staticClass:"backlink",attrs:{"to":_vm.localePath('/partnerportal')}},[_c('chevron-left-icon',{attrs:{"width":20,"height":20}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('toDashboard')))])],1):_vm._e(),_vm._v(" "),_c('application-form',{attrs:{"current-step-id":_vm.state.stepId,"item-components":_vm.itemComponents,"product":_vm.product,"policy-id":_vm.dexId.policyId ? JSON.stringify(_vm.dexId) : null,"quote-id":_vm.dexId.contractId ? JSON.stringify(_vm.dexId) : null,"amendment-id":_vm.dexId.policyId && _vm.dexId.type === 'edit'
            ? JSON.stringify(Object.assign({}, _vm.dexId, {amendmentId: _vm.dexId.policyId}))
            : null,"language":_vm.$i18n.locale,"channel":_vm.channel,"form-key":_vm.formKey},on:{"ready":_vm.onFormReady,"goToStep":_vm.goToStep,"error":_vm.onError,"saved":_vm.onSaved,"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"loadingIndicator",fn:function(){return [_c('div',{staticClass:"loadingOverlay"},[_c('loading-spinner')],1)]},proxy:true},{key:"header",fn:function(ref){
            var formContext = ref.formContext;
            var steps = ref.steps;
            var current = ref.current;
return [_c('form-header',{attrs:{"form-context":formContext,"steps":steps,"current":current}})]}},{key:"formNavigation",fn:function(ref){
          var formContext = ref.formContext;
          var isFirstStep = ref.isFirstStep;
          var isLastStep = ref.isLastStep;
          var isPriceLoading = ref.isPriceLoading;
          var onPrevious = ref.onPrevious;
          var onSave = ref.onSave;
return [_c('form-navigation',{attrs:{"form-context":formContext,"channel":_vm.channel,"is-first-step":isFirstStep,"is-last-step":isLastStep,"is-price-loading":isPriceLoading,"dex-id":_vm.dexId},on:{"previous":onPrevious,"save":onSave}})]}},{key:"tariff",fn:function(formContext){return [_c('form-tariff-box',{ref:"tariffBox",attrs:{"form-context":formContext,"tariff":formContext.tariff,"payment-interval":formContext.data['misc.payment_interval']}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }