const getIntroPathForTariff = (activeTariff) => {
  const tariff = (activeTariff || 'p-private')
    .split('-')
    .map((part) => part[0].toUpperCase() + part.slice(1))
    .join('-')
  return `Offer-Intro-${tariff}`
}

const getBrokerPathForStep = (step, activeTariff) => {
  switch (step) {
    case 2:
      return 'BrokerOffer-Summary'
    default:
      return `Broker${getIntroPathForTariff(activeTariff)}`
  }
}

export default function ({ store, redirect, route }) {
  const offerRoute = route.meta.find((m) => m.offerStep)
  const { activeTariff, step } = store.state.brokerOffer

  if (offerRoute && offerRoute.offerStep > step) {
    const pathForStep = getBrokerPathForStep(step, activeTariff)
    redirect(store.app.localePath(pathForStep))
  }
}
