import contractsQuery from '~/domains/contract/__gql__/queries/contracts.gql'
import insuringContractsQuery from '~/domains/contract/__gql__/queries/insuringContracts.gql'
import createLegalClaimMutation from './__gql__/mutations/createLegalClaimMutation.gql'
import createBrokerLegalClaimMutation from './__gql__/mutations/createBrokerLegalClaimMutation.gql'
import { RESET_STORE_DAMAGEREPORT } from './mutations'

export default {
  async createLegalClaim({ context, state, rootState, getters }) {
    const damageReport = state.report
    delete damageReport.contract.productCode
    delete damageReport.accountHolder
    delete damageReport.attachmentTypes
    if (damageReport.bankAccount && !damageReport.bankAccount.iban) {
      delete damageReport.bankAccount
    }
    damageReport.areaOfLaw = damageReport.areaOfLaw.toUpperCase()
    damageReport.subareaOfLaw = damageReport.subareaOfLaw.toUpperCase()
    const result = await this.app.apolloProvider.defaultClient.mutate({
      mutation: createLegalClaimMutation,
      variables: {
        gm_limited_email: getters.isGmCustomer
          ? rootState.application.currentUser.email
          : null,
        legalClaim: damageReport,
        notGM: !getters.isGmCustomer,
      },
    })
    try {
      const variables = {
        withDocuments: true,
      }
      const contractsData = this.app.apolloProvider.defaultClient.readQuery({
        query: contractsQuery,
        variables,
      })
      const index = contractsData.contracts.findIndex(
        (c) => c.id === state.report.contract.id,
      )
      if (index !== -1) {
        contractsData.contracts[index].claims = [
          {
            ...result.data.createLegalClaim,
            contract: { ...state.report.contract, __typename: 'Contract' },
          },
          ...contractsData.contracts[index].claims,
        ]
        this.app.apolloProvider.defaultClient.writeQuery({
          query: contractsQuery,
          data: contractsData,
          variables,
        })
      } else {
        const insuringContractsData =
          this.app.apolloProvider.defaultClient.readQuery({
            query: insuringContractsQuery,
          })
        const insuringIndex = insuringContractsData.insuringContracts.findIndex(
          (c) => c.id === state.report.contract.id,
        )
        insuringContractsData.insuringContracts[insuringIndex].claims = [
          result.data.createLegalClaim,
          ...insuringContractsData.insuringContracts[insuringIndex].claims,
        ]
        this.app.apolloProvider.defaultClient.writeQuery({
          query: insuringContractsQuery,
          data: insuringContractsData,
        })
      }
      context.commit(RESET_STORE_DAMAGEREPORT)
      return result.data.createLegalClaim
    } catch (error) {
      // silent fail
    }
    return result.data.createLegalClaim
  },
  async createBrokerLegalClaim({ state }) {
    const damageReport = state.report
    delete damageReport.accountHolder
    delete damageReport.attachmentTypes
    if (damageReport.bankAccount && !damageReport.bankAccount.iban) {
      delete damageReport.bankAccount
    }
    damageReport.areaOfLaw = damageReport.areaOfLaw.toUpperCase()
    damageReport.subareaOfLaw = damageReport.subareaOfLaw.toUpperCase()
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: createBrokerLegalClaimMutation,
      variables: {
        legalClaim: damageReport,
        insuredPersonDexId: state.personId,
      },
    })
    return data && data.createBrokerLegalClaim
  },
}
