<script>
import { SET_TOKEN } from '~/domains/authentication/__store__/mutations'
import { FAKE_LOGIN } from '~/domains/authentication/__store__/actions'
import jwtTools from '~/javascripts/helper/jwtTools'

export default {
  methods: {
    setupLoginFaker() {
      window.document.addEventListener(
        'setToken',
        (event) => {
          this.setToken(event.detail)
        },
        false,
      )
    },
    async setToken(token) {
      this.$store.commit({
        type: SET_TOKEN,
        token: {
          jwt: token,
        },
      })
      const roles = jwtTools.getRoles(token)

      try {
        await this.$store.dispatch(FAKE_LOGIN, {
          variables: { roles },
          apollo: this.$apollo.provider.defaultClient,
        })

        if (this.$store.getters.isExternalLawyer) {
          this.$router.push({ path: this.localePath('LawyerPortal') })
        } else if (this.$store.getters.isBroker) {
          this.$router.push({ path: this.localePath('PartnerPortal') })
        } else {
          this.$router.push(this.localePath('SelfService'))
        }
      } catch (error) {
        console.error(error)
        this.$notification.error({ messageKey: 'loginPage.error' })
      }
      this.$store.commit('LOADING', false)
    },
  },
}
</script>
