const defaultTimer = 7000

export const NotificationTypes = {
  SUCCESS: {
    type: 'success',
    // headlineKey: 'notifications.success.headline',
    messageKey: 'notifications.success.message',
    timer: defaultTimer,
  },
  ERROR: {
    type: 'error',
    headlineKey: 'notifications.error.headline',
    messageKey: 'notifications.error.message',
    timer: 10000,
  },
  INFO: {
    type: 'info',
    // headlineKey: 'notifications.info.headline',
    messageKey: 'notifications.info.message',
    timer: defaultTimer,
  },
  WARNING: {
    type: 'warning',
    headlineKey: 'notifications.warning.headline',
    messageKey: 'notifications.warning.message',
    timer: defaultTimer,
  },
}

export default NotificationTypes
