<template>
  <div class="container-fluid">
    <div class="mt-3 my-md-4">
      <nuxt-link :to="back" class="arrow-left">
        {{ $t('back') }}
      </nuxt-link>
    </div>
    <div class="content-wrap-small content-pad">
      <div class="d-flex justify-content-center">
        <dx-progressbar
          :steps="5"
          :active-step="1"
          class="mb-5 w-100 dx-w-md-75"
        />
      </div>
      <h3>
        {{ $t(isBroker ? 'myData.broker' : 'myData.customer') }}
      </h3>

      <dx-loading-overlay v-if="loading" />
      <dx-input
        v-else-if="isBroker"
        v-model="formattedPolicy"
        :label="$t('policyNumber')"
        plain-text
      />
      <dx-select
        v-else
        v-model="selectedContract"
        v-validate.disable="'required'"
        :error="errors.first('policyNumber')"
        :options="formatPolicyOptions"
        :label="$t('policyNumber')"
        data-vv-name="policyNumber"
        class="mb-4"
      />

      <div v-if="person" class="row mb-4">
        <div class="col">
          <h3>
            {{ $t('insuredPerson') }}
          </h3>
          <ul class="neutral font-lc mb-4">
            <li v-if="person.companyName">
              {{ getCompanyOrNameWithSalutation(person) }}
            </li>
            <li>{{ getNameWithSalutation(person) }}</li>
            <li>{{ getStreetWithNumber(person) }}</li>
            <li>{{ getZipWithCity(person) }}</li>
          </ul>

          <div v-if="inviteAllowed">
            <div v-if="!showInvitePerson" class="alert alert-info">
              {{ invitePersonHint }}
            </div>

            <div>
              <button
                v-if="
                  !showInvitePerson && selectedContract && !isGm && !isGmLimited
                "
                class="btn-blank show-more-link plus-icon mt-3"
                @click="toggleShowInvitePerson"
              >
                {{ $t('invitePersonLink') }}
              </button>

              <dx-invite-person
                v-if="showInvitePerson && !isGm && !isGmLimited"
                :contract-id="selectedContract"
                :listed-mails="listedMails"
                :on-success="onSendInvitationSuccess"
                :on-cancel="toggleShowInvitePerson"
                :on-error="onSendInvitationError"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h3>
            {{ $t('furtherLegal') }}
          </h3>
          <dx-radio-list
            v-model="report.hasOtherInsurance"
            :options="otherContractData"
            :label="
              $t(
                isBroker
                  ? 'furtherContract.broker'
                  : 'furtherContract.customer',
              )
            "
            name="other_contract"
            class="mb-3"
          />

          <dx-input
            v-if="report.hasOtherInsurance"
            v-model="report.otherInsurance"
            v-validate.disable="'required'"
            :label="$t('legalInsurance')"
            :error="errors.first('otherInsurance')"
            data-vv-name="otherInsurance"
          />

          <dx-bottom-bar
            :previous="localePath('SelfService-ReportDamage-Facts')"
            :show-previous-button="false"
            class="mt-5"
            @forward="gotoNextStep"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxInput,
  DxRadioList,
  DxSelect,
} from '@sumcumo/dextra-frontend-component-lib-old'
import { mapGetters } from 'vuex'
import DxProgressbar from '~/components/DX-Progressbar'
import DxBottomBar from '~/components/DX-BottomBar'
import DxInvitePerson from '~/domains/contract/components/DX-Contract/DX-PolicyHolderOptions/DX-InvitePerson'
import DxLoadingOverlay from '~/components/DX-LoadingOverlay'
import personMixin from '~/javascripts/mixins/personMixin'
import {
  SET_REPORT,
  SET_PERSON_ID,
} from '~/domains/contract/claim/__store__/mutations'

export default {
  name: 'DxInfoForm',
  components: {
    DxProgressbar,
    DxInput,
    DxRadioList,
    DxBottomBar,
    DxSelect,
    DxInvitePerson,
    DxLoadingOverlay,
  },
  mixins: [personMixin],
  props: {
    contractsLoading: {
      type: Boolean,
      default: false,
    },
    inviteAllowedForContract: {
      type: Function,
      default: () => false,
    },
    contractId: {
      type: String,
      default: '',
    },
    contracts: {
      type: Array,
      default: () => [],
    },
    person: {
      type: Object,
      default: () => {},
    },
    nextStepPath: {
      type: String,
      default: '',
    },
    onSendInvitationSuccess: {
      type: Function,
      default: () => null,
    },
    isBroker: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      report: { ...this.$store.state.claim.report },
      showInvitePerson: false,
      otherContractData: [
        {
          text: this.$t('yes'),
          id: true,
        },
        {
          text: this.$t('no'),
          id: false,
        },
      ],
      selectedContract: this.contractId,
      listedMails: [],
    }
  },
  computed: {
    ...mapGetters(['isGm', 'isGmLimited']),
    loading() {
      return this.$store.state.application.loading || this.contractsLoading
    },
    invitePersonHint() {
      return `${this.$t(
        this.isBroker ? 'invitePersonHint.broker' : 'invitePersonHint.customer',
      )} ${this.$t('invitePersonHint.both')}`
    },
    formatPolicyOptions() {
      const options = [
        {
          value: null,
          label: this.$t('selectOption'),
          caption: true,
        },
      ]
      const formattedPolicies = this.contracts.map((contract) => ({
        value: contract.id,
        label: `# ${contract.id}  ${contract.productLabel}`,
      }))
      return options.concat(formattedPolicies)
    },
    formattedPolicy() {
      return `# ${this.contracts[0].id}  ${this.contracts[0].productLabel}`
    },
    isCompany() {
      const { salutation } = this.person
      return (
        salutation &&
        salutation.toUpperCase() === this.$config.salutations.company
      )
    },
    inviteAllowed() {
      if (this.selectedContract && this.contracts && !this.loading) {
        const currentContract = this.contracts.find(
          (c) => !!c && c.id === this.selectedContract,
        )
        return (
          !!currentContract && this.inviteAllowedForContract(currentContract)
        )
      }
      return false
    },
    back() {
      return this.isBroker
        ? this.localePath('PartnerPortal-ClosedPolicies')
        : this.localePath('SelfService')
    },
  },
  watch: {
    // if there is only one option we can select it
    formatPolicyOptions(val) {
      if (val.length === 2) {
        this.selectedContract = val[1].value
      }
    },
  },
  methods: {
    async toggleShowInvitePerson() {
      if (!this.selectedContract) {
        this.$notification.error({ messageKey: 'invitePersonButNoPolicySet' })
      } else {
        const policyNumberIsValid = await this.$validator.validate(
          'policyNumber',
        )
        if (policyNumberIsValid) {
          const currentContract = this.contracts.find(
            (c) => !!c && c.id === this.selectedContract,
          )
          // get already invited or insured emails from current contract
          const insuredMails = currentContract.insuredPersons.map(
            (c) => c.email,
          )
          const invitedMails = currentContract.invitations.map((c) => c.email)
          this.listedMails = Array.of(
            ...insuredMails,
            ...invitedMails,
            this.person.email,
          )
          this.showInvitePerson = !this.showInvitePerson
        }
      }
    },
    onSendInvitationError() {
      // TODO use error param to display network or email is already invited error
      this.$notification.error({ messageKey: 'invitePersonErrorMessage' })
    },
    async gotoNextStep() {
      const validated = await this.$validator.validateAll()
      if (validated) {
        // get current contract & person and build new report for current view
        const currentContract = this.contracts.find(
          (c) => !!c && c.id === this.selectedContract,
        )
        if (!currentContract) {
          return
        }
        const report = {
          ...this.report,
          contract: {
            id: currentContract.id,
            insuranceCompany: currentContract.insuranceCompany,
            productCode: currentContract.productCode,
          },
          otherInsurance: this.report.hasOtherInsurance
            ? this.report.otherInsurance
            : '',
        }
        this.$store.commit({
          type: SET_REPORT,
          report,
        })
        this.$store.commit({
          type: SET_PERSON_ID,
          personId: this.person.dexId,
        })
        this.$router.push({ path: this.localePath(this.nextStepPath) })
      }
    },
  },
}
</script>
